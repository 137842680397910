import React from 'react';

import Spacer from 'common/components/Spacer/Spacer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { useViewport } from 'use-viewport';

import Banner from '../assets/FAQ.jpg';

import {
  BottomContainer,
  CustomSpacer,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography,
  StrapLineTypographyContainer,
  InlineTypography,
  LinkButton,
  ContentButton,
  ButtonLink
} from './styled/ErrorPage.styled';

const ErrorPage = () => {

  const viewport = useViewport();

  return (
    <PageContainer>
      <ImageContainer>
        <Image src={Banner} />
        <ImageGradient banner={Banner} />
      </ImageContainer>
      <HeadContainer>
        <CustomSpacer height="67" />
        <Spacer height={viewport.width >= 576 ? 200 : 90} />
        <HeadingSection>
          <StrapLineTypographyContainer>
            <div style={{ display: 'flex' }}>
              <StrapLineTypography
                text="404"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.03em"
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                letterSpacing="0.035em"
              />
            </div>
          </StrapLineTypographyContainer>
          <Spacer height={viewport.width >= 576 ? 65 : 45} />
            <InlineTypography
              text="Oh no! We couldn't find that."
              fontSize="fz24"
              fontWeight="regular"
              fontColor={theme.colors.white}
              letterSpacing="-0.03em"
            />
          <Spacer height={viewport.width >= 576 ? 45 : 45} />

          <ButtonLink to="/">
            <LinkButton
              height={45}
              width={200}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton text="head back" fontSize="fz16" fontWeight="bold" />
                  &nbsp;
                  <ContentButton
                    text="home"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => {}}
            />
          </ButtonLink>

        </HeadingSection>
      </HeadContainer>
      <Spacer height={100} />
      <BottomContainer></BottomContainer>
      <Spacer height={34} />
    </PageContainer>
  );
};

export default WithRouteAnimation(ErrorPage);

import React, { FC, memo } from 'react';
import styled from 'styled-components';

import WorldMap from '../../ArtistAnalytics/components/layout/WorldMap/WorldMap';

import { ILocationsData } from 'services/types';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';

interface UserLocationsProps {
  locationsData: ILocationsData[];
  currentDrop?: {
    value: string | number;
    label: string;
  };
  onLocationClick?: (id: string) => void;
}

const UserLocations: FC<UserLocationsProps> = ({
  locationsData,
  currentDrop,
  onLocationClick = () => {},
}) => {
  return (
    <Wrapper>
      <Spacer height={10} />
      <Typography
        text="users location"
        fontWeight="bold"
        fontSize="fz24"
        letterSpacing="-0.03em"
      />
      <MapContainer>
        <WorldMap
          locationsData={locationsData}
          onLocationClick={onLocationClick}
          currentDrop={currentDrop}
        />
      </MapContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;

  @media (min-width: 576px) {
    margin-bottom: 34px;
  }
`;

export const MapContainer = styled.div`
  margin-top: -15px;
  transform: scale(0.72) translateX(-65px);

  @media (min-width: 576px) {
    margin-top: 20px;
    transform: none;
  }
`;

export default memo(UserLocations);

import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import theme from 'theme/theme';

export const PageContent = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const DataFieldContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 28px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 33%;
  order: -1;

  color: ${theme.colors.white};

  padding: 0 15px 30px 10px;
  margin-left: -15px;

  @media (min-width: 576px) {
    flex-basis: 30%;
  }

  @media (min-width: 992px) {
    order: 0;
    flex-basis: auto;
    align-self: flex-start;
    padding: 0 30px 30px 10px;
  }
`;

export const Intro = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  line-height: 24px;
  margin-bottom: 15px;
`;

export const Image = styled.img`
  border-radius: 10px;
  width: 114px;
  height: 114px;

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

export const NoImage = styled(Typography)`
  width: 114px;
  height: 114px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid ${theme.colors.yellow};

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

export const MarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 3px solid ${theme.colors.white};
  background: ${theme.colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WidthSpacer = styled.div<{ width: number }>`
  display: none;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};

  @media (min-width: 576px) {
    display: block;
  }
`;

export const ArtistName = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 30px;
  line-height: 39px;
  display: flex;
  width: 80%;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    width: 100%;
    justify-content: start;
  }

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

export const MemeberSince = styled.div`
  width: auto;
  display: flex;

  @media (min-width: 576px) {
    width: 100%;
    justify-content: start;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  height: min-content;
  max-width: 1280px;
  margin-top: 105px;
  flex-wrap: wrap;
  padding: 0 40px;

  @media (min-width: 768px) {
    padding-left: 45px;
  }

  @media (min-width: 1200px) {
    padding-left: 135px;
  }

  @media (min-width: 1400px) {
    padding-left: 350px;
  }
`;

export const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 768px) {
    display: flex;
    padding-left: 5px;
    padding-top: 5px;
  }
`;

export const TitleRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;

  width: 100%;
  flex-basis: 55%;

  @media (min-width: 392px) {
    flex-basis: 60%;
  }

  @media (min-width: 576px) {
    flex-basis: 50%;
  }

  @media (min-width: 768px) {
    flex-basis: 60%;
  }

  @media (min-width: 992px) {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
`;

export const Details = styled.div`
  min-width: 335px;
  height: min-content;
  width: auto;

  @media (max-width: 576px) {
    min-width: 90%;
    max-width: 90%;
  }

  @media (min-width: 992px) {
    max-width: 335px;
  }
`;

export const BottomLine = styled.div`
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  margin-left: -5px;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export const BoldText = styled(Typography)<{ size?: number }>`
  font-family: 'HKGrotesk-Black';
  font-size: ${(props) => (props.size ? props.size + 'px' : '30px')};
  @media (min-width: 576px) {
    font-size: ${(props) => (props.size ? props.size + 'px' : '48px')};
    letter-spacing: -0.05em;
  }
`;

export const LightText = styled(Typography)`
  font-family: 'HKGrotesk-Light';
`;

export const SocialShareContainer = styled.div<{ left?: number }>`
  display: flex;
  align-self: flex-start;
  align-items: center;
  transform: scale(0.8);
  gap: 10px;

  position: absolute;
  top: 5.5em;
  right: 10px;

  @media (min-width: 768px) {
    right: 40px;
  }

  @media (min-width: 992px) {
    top: 6em;
  }

  @media (min-width: 1280px) {
    left: ${(props) => (props.left ? props.left : '')}px;
  }

  @media (min-width: 1400px) {
    left: ${(props) => (props.left ? props.left + 10 : '')}px;
  }
`;

export const SocialButton = styled(Button)`
  padding: 0;
  text-decoration: inherit;
  transform: scale(0.8);
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  @media (min-width: 576px) {
    transform: none;
  }
`;

export const EllipsisWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const Ellipsis = styled(Button)`
  font-family: auto;
  font-size: 44px;
  letter-spacing: -2px;
  height: max-content;
`;

export const DetailsContainer = styled.div`
  display: block;
  transform: translateX(-3px);
  @media (min-width: 992px) {
    display: flex;
    margin: 0;
    transform: none;
  }
`;

export const CustomSpacer = styled.div<{
  height: number;
  hideMobile?: boolean;
}>`
  height: ${(props) => props.height / 2}px;
  display: ${(props) => (props.hideMobile ? 'none' : 'block')};

  @media (min-width: 576px) {
    display: block;
    height: ${(props) => props.height}px;
  }
`;

export const PreviewButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreviewButton = styled.button<{
  btnType: 'greyBorder' | 'whiteBorder' | 'redBackground';
}>`
  font-family: HKGrotesk-Black;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: ${theme.colors.white};

  border: 3px solid;
  border-radius: 50px;

  margin-top: 27px;

  cursor: pointer;

  background-color: ${theme.colors.black};

  ${(props) =>
    props.btnType === 'greyBorder' && `border-color: rgba(255, 255, 255, 0.5);`}

  ${(props) =>
    props.btnType === 'whiteBorder' && `border-color: ${theme.colors.white};`}

  ${(props) =>
    props.btnType === 'redBackground' &&
    `
      border-color: ${theme.colors.red};
      background-color: ${theme.colors.red};
    `}
`;

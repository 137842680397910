import React from 'react';

import theme from 'theme/theme';

import { IconProps } from '../common.types';

const ProfileIcon = ({
  width = 25,
  height = 25,
  color = theme.colors.white,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 0C5.597 0 0 5.597 0 12.5S5.597 25 12.5 25 25 19.403 25 12.5 19.403 0 12.5 0ZM5.208 19.939c1.216-3.646 4.862-2.223 5.47-4.198v-1.317c-.274-.147-1.056-1.155-1.138-1.943-.216-.018-.554-.232-.654-1.077-.053-.453.16-.708.288-.788 0 0-.32-.732-.32-1.458 0-2.158 1.069-3.95 3.646-3.95 1.392 0 1.823.987 1.823.987 1.244 0 1.823 1.363 1.823 2.962 0 .797-.321 1.458-.321 1.458.13.08.342.335.288.788-.1.845-.437 1.058-.653 1.077-.082.788-.863 1.796-1.137 1.943v1.317c.607 1.976 4.253.553 5.469 4.199 0 0-2.727 2.978-7.292 2.978-4.59 0-7.292-2.979-7.292-2.979Z"
      fill={color}
    />
  </svg>
);

export default ProfileIcon;

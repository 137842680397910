import { CommonListTypes } from "common/common.types";

const MOCK_COMMON_QUESTIONS_LIST: CommonListTypes[] = [
    {
        id: 1,
        title: 'Is this for real?',
        description:
            'Yes, you can actually own rights to a particular song and therefore also share in the streaming revenue the song generates.',
    },
    {
        id: 2,
        title: 'How can I buy a song bit?',
        description:
            "Go to Hot Drops and find a song then go to the song profile to buy song bits. If buying a bit, select the desired amount or value and hit buy. That's it!",
    },
    {
        id: 3,
        title: 'Can I meet the Artist?',
        description:
            'Maybe! It all depends on what additional benefits the artist wishes to include for SongBit holders. Check the specific drop for more details',
    },
    {
        id: 4,
        title: 'How do I receive my song bit?',
        description: 'It will be added to your wallet in your SongBits account.',
    },
    {
        id: 5,
        title: 'How long does it take to receive my bits?',
        description:
            'Your song or bits are added to your wallet immediately after purchase.',
    },
    {
        id: 6,
        title: 'Do my bits expire?',
        description:
            'No, they are assigned for the lifetime of the song providing it is still published on the major streaming platforms.',
    },
    {
        id: 7,
        title: 'What rights exactly are being sold?',
        description: 'Streaming rights.',
    },
    {
        id: 8,
        title: 'Do I have to have a cypto wallet?',
        description: 'No, your bits can be stored within your SongBits account.',
    },
    {
        id: 9,
        title: 'Do I have to own Crypto Currency?',
        description:
            'No, unless you wish to pay for your bits with crypto instea dof a credit or debit card',
    },
    {
        id: 10,
        title: 'What is a NFT?',
        description:
            'It stands for Non Fungeble Token and represents a way of holding an asset on the blockchain',
    },
    {
        id: 11,
        title: 'What is the difference between a song bit and an NFT?',
        description:
            'Your bits will be converted into an NFT if you export them to your external wallet',
    },
    {
        id: 12,
        title: 'Are transaction anonymous?',
        description:
            'If you purchase your bits using crypto currency, the only publicly available information will be the transaction hash on etherscan. We do not publish or disclosure member information and you can choose to have a public profile or not.',
    },
    {
        id: 13,
        title: 'How can I play my song bits?',
        description:
            'You can play the song wither within SongBits.com or via any streaming platform',
    },
    {
        id: 14,
        title: 'How can I show my friends my songbits safely?',
        description:
            'You can share the song via your SongBits public profile, your external wallet if you have one, or simply play it on one of the major streaming platforms.',
    },
    {
        id: 15,
        title: 'How can I prove I own the bits I have purchased?',
        description:
            'They are available to view in your SongBits wallet or if you have exported them, on the blockchain under your wallet address',
    },
    {
        id: 16,
        title:
            'Do I earn royalty revenue from my own plays of a song I own bits for?',
        description:
            'Yes you do. Thats another value added benefit to owning a bit of song you play.',
    },
    {
        id: 17,
        title: 'Can I sell the rights back to the Artist?',
        description:
            'Unlikely, but you may be able to sell them to a third party via the marketplace',
    },
    {
        id: 18,
        title: 'Can I export my bits as an NFT?',
        description:
            'Yes, this option is currently available for MetaMask wallet holders.',
    },
    {
        id: 19,
        title: 'Can I sell or transfer my song bits?',
        description:
            'Yes, both options are available after purchase right from your SongBits account under purchase history',
    },
    {
        id: 20,
        title: 'Can I store my song bits offline in a wallet?',
        description:
            'Yes. You can export your bits to a cold storage wallet for offline storage',
    },
];

export default MOCK_COMMON_QUESTIONS_LIST;
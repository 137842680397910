import React, { useMemo, useEffect } from 'react';
import { useViewport } from 'use-viewport';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
//import Typography from 'common/components/Typography/Typography';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksMobile from 'common/components/QuickLinksMobile/QuickLinksMobile';
//import Spacer from 'common/components/Spacer/Spacer';

import PageLayout from 'common/components/PageLayout/PageLayout';



const CookiePolicy = () => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const quickLinks = useMemo(
    () => [
      {
        title: 'Terms & Conditions',
        link: '/legal/terms-conditions',
      },
      {
        title: 'Bits Sales Terms',
        link: '/legal/bits-sales-terms',
      },
      {
        title: 'Privacy Policy',
        link: '/legal/privacy-policy',
      },
      /*{
        title: 'Risk Summary',
        link: '/legal/risk-summary',
      },*/
      /*{
        title: 'Risk Factors',
        link: '/legal/risks',
      },*/
      /*{
        title: 'Community Guidelines',
        link: '/legal/community-guidelines',
      },*/
      {
        title: 'Company',
        link: '/company',
      },
    ],
    [],
  );

  const contentSection = (
    <>
    {viewport.width <= 767 ? (
      <QuickLinksMobile links={quickLinks} loading={false}></QuickLinksMobile>
    ) : (
      <></>
    )}
      <div id="cookie-declaration"></div>


      {viewport.width <= 767 ? (
        <>
          <div style={{'marginTop': '30px', 'display': 'flex', 'flexDirection': 'column'}}>
          <Typography text={'To read our other terms you can use the following links:'} lineHeight='20px' fontSize={'fz16'} />
          <QuickLinkButton onClick={() => {
            navigate('/legal/terms-conditions')
          }}>Terms & Conditions</QuickLinkButton>
          <QuickLinkButton onClick={() => {
            navigate('/legal/bits-sales-terms')
          }}>Bits Sales Terms</QuickLinkButton>
          <QuickLinkButton onClick={() => {
            navigate('/legal/privacy-policy')
          }}>Privacy Policy</QuickLinkButton> 
          </div>       
        </>
      ) : (
        <></>
      )}
    </>
  );


useEffect(() => {
  const script = document.createElement('script');

  script.src =
    'https://consent.cookiebot.com/2e8742dc-9cb9-4d64-875d-b8b9d8dcf2e2/cd.js';
  script.async = true;
  script.id = 'CookieDeclaration';

  const cookieDiv = document.getElementById('cookie-declaration');

  if (cookieDiv) {
    cookieDiv.appendChild(script);
  }

  return () => {
    const cookieDiv = document.getElementById('cookie-declaration');

    if (cookieDiv) {
      cookieDiv.removeChild(script);
    }
  };
}, []);

  return (
    <PageContainer  pageTitle='Cookie Policy | SongBits'>
      <PageLayout
        title="Cookie Policy"
        is2ColumnLayout
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <>
                {viewport.width <= 767 ? (
                  <></>
                ) : (
                  <QuickLinks links={quickLinks}></QuickLinks>
                )}
              </>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};


const QuickLinkButton = styled.button`
    font-family: 'HKGrotesk-Regular';
    font-size: 18px;
    color: #FFF;
    background #000;
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 16px;
    text-decoration: underline;

    &hover: {
      text-decoration: none;
    }
`;


export default CookiePolicy;

import React, { FC, memo, useState } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';

import SBModal from 'common/components/Modal/SBModal';
import theme from 'theme/theme';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';

import restService from 'services/rest.service';
//import PayPal from 'common/icons/PayPal.icon';

//import Cryptocurrency from 'common/icons/Cryptocurrency.icon';

export enum PayoutFlowStates {
  Initial,
  WireTransfer,
  WireTransferSuccess,
  WireTransferFailed,
  CryptoWallet,
  CryptoWalletSuccess,
  CryptoWalletFailed,
}

interface PaymentModalProps {
  isOpen: boolean;
  details: any;
  refresh: () => void;
  onClose: () => void;
}

const PayoutFlow: FC<PaymentModalProps> = ({ isOpen, details, onClose, refresh }) => {
  const viewport = useViewport();
  const { showSpace } = useIntercom();

  const contactSupportHandler = () => {
    showSpace('messages');
  };

  const [payoutFlowState, setPayoutFlowState] = useState<PayoutFlowStates>(
    PayoutFlowStates.Initial,
  );
  const [loading, setLoading] = useState<boolean>(false);
  

  function confirmPayout() {

    if (payoutFlowState === PayoutFlowStates.WireTransfer || payoutFlowState === PayoutFlowStates.Initial) {

      restService.createPayPalPayout().then((res) => {
        setLoading(false)
        refresh()
        if (payoutFlowState === PayoutFlowStates.WireTransfer|| payoutFlowState === PayoutFlowStates.Initial) {
          setPayoutFlowState(PayoutFlowStates.WireTransferSuccess);
        }
        res
      }).catch((error) => {
        console.error(error)
        setLoading(false)
        if (payoutFlowState === PayoutFlowStates.WireTransfer || payoutFlowState === PayoutFlowStates.Initial) {
          setPayoutFlowState(PayoutFlowStates.WireTransferFailed);
        }
      });

    }

    if (payoutFlowState === PayoutFlowStates.CryptoWallet) {
      const destinationType = 'crypto'
      const destinationId = details.crypto.address_id

      restService.createPayout({
        payoutType: 'royalties',
        destinationType: destinationType,
        destinationId: destinationId
      }).then((res) => {
        setLoading(false)
        refresh()
        if (payoutFlowState === PayoutFlowStates.CryptoWallet) {
          setPayoutFlowState(PayoutFlowStates.CryptoWalletSuccess);
        }
        res
      }).catch((error) => {
        console.error(error)
        setLoading(false)
        if (payoutFlowState === PayoutFlowStates.CryptoWallet) {
          setPayoutFlowState(PayoutFlowStates.CryptoWalletFailed);
        }
      });

    }

  }

  function handleClose() {
    if (payoutFlowState === PayoutFlowStates.Initial) {
      onClose();
    }
    if (payoutFlowState === PayoutFlowStates.WireTransferFailed) {
      onClose();
      setPayoutFlowState(PayoutFlowStates.Initial);
    }
    if (payoutFlowState === PayoutFlowStates.WireTransfer) {
      if (details.paypal && details.crypto) {
        setPayoutFlowState(PayoutFlowStates.Initial);
      } else {
        onClose();
      }
    }
    if (payoutFlowState === PayoutFlowStates.WireTransferSuccess) {
      onClose();
      setPayoutFlowState(PayoutFlowStates.Initial);
    }
    if (payoutFlowState === PayoutFlowStates.CryptoWalletFailed) {
      onClose();
      setPayoutFlowState(PayoutFlowStates.Initial);
    }
    if (payoutFlowState === PayoutFlowStates.CryptoWallet) {
      if (details.paypal && details.crypto) {
        setPayoutFlowState(PayoutFlowStates.Initial);
      } else {
        onClose();
      }
    }
    if (payoutFlowState === PayoutFlowStates.CryptoWalletSuccess) {
      onClose();
      setPayoutFlowState(PayoutFlowStates.Initial);
    }
  }

  /*
  function payout_options() {
    return (
      <>
        <BoldText
          text={'payout funds'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <TextContent
          text="Choose how to payout your funds"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
          fontWeight="bold"
        />
        <Spacer height={70} />

        <PaymentOptionsContainer>
          <PaymentOption
            disabled={
              details.paypal && parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
            }
            onClick={() => {
              if (
                details.paypal &&
                parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
              ) {
                setPayoutFlowState(PayoutFlowStates.WireTransfer);
              }
            }}>
            <IconContainer>
              <PayPal
                height={50}
                width={50}
                fill={
                  details.paypal &&
                  parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
                    ? theme.colors.yellow
                    : theme.colors.gray
                }
                opacity={
                  details.paypal &&
                  parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
                    ? 1
                    : 0.5
                }
              />
            </IconContainer>

            {details.paypal && parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00") ? (
              <>
                <TextContent
                  fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
                  fontColor={theme.colors.white}
                  text="Payout via PayPal"
                />
                {/*<Typography
                  text="fees may apply"
                  fontWeight="light"
                  fontColor={theme.colors.yellow}
                  fontSize={'fz14'}
            />}
              </>
            ) : (
              <>
                <TextContent
                  fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
                  fontColor={theme.colors.yellow}
                  text="Insufficient Funds"
                />
              </>
            )}
          </PaymentOption>
          <PaymentOption
            style={{ width: '125px' }}
            disabled={
              true details.crypto && parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
            }
            onClick={() => {
              if (
                details.crypto &&
                parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
              ) {
                setPayoutFlowState(PayoutFlowStates.CryptoWallet);
              }
            }}>
            <IconContainer>
              <Cryptocurrency height={50} width={50} fill={theme.colors.gray 
                  details.crypto &&
                  parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00")
                    ? theme.colors.yellow
                    : theme.colors.gray
                } />
            </IconContainer>

            {details.crypto && parseFloat(details.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00") ? (
              <>
                <TextContent
                  fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
                  fontColor={theme.colors.white50}
                  text="Payout via crypto wallet"
                />
                 <Typography
                  text="coming soon"
                  fontWeight="light"
                  fontColor={theme.colors.gray}
                  fontSize={'fz14'}
                />
              </>
            ) : (
              <>
                <TextContent
                  fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
                  fontColor={theme.colors.yellow}
                  text="Insufficient Funds"
                />
              </>
            )}
          </PaymentOption>
        </PaymentOptionsContainer>

        <Spacer height={50} />
        <PayoutAmountContainer>
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz24'}
            fontColor={theme.colors.white}
            text={'Payout Amount | '}
          />
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz24'}
            fontColor={theme.colors.yellow}
            text={' $ ' + details.balance}
          />
        </PayoutAmountContainer>
      </>
    );
  }
  */

  function wire_transfer() {
    return (
      <>
        <BoldText
          text={'confirm payout'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <TextContent
          text="Payout via PayPal"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
          fontWeight="bold"
        />
        <Spacer height={30} />

        {/*<Typography
          text={`Account Name: ` + details.paypal.account_name}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={10} />*/}
        <Typography
          text={`Email: ` + details.paypal?.email_address}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="regular"
        />
        <Spacer height={30} />
        <BottomLine />
        <Spacer height={30} />
        <PayoutAmountLeftContainer>
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz24'}
            fontColor={theme.colors.white}
            text={'Payout Amount | '}
          />
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz24'}
            fontColor={theme.colors.yellow}
            text={'USD $ ' + details.balance}
          />
        </PayoutAmountLeftContainer>
        {/*<Spacer height={10} />
        <TextContent
          fontSize={'fz14'}
          fontColor={theme.colors.yellow}
          text={'Fees may apply'}
        />*/}
        <Spacer height={30} />
        <WhiteButton
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            labelColor={theme.colors.black}
            isLoading={loading}
              label="Confirm Payout"
              onClick={() => {
                confirmPayout()
              }}
              borderRadius={50}
              height={45}
            />
          
        {/*details.paypal && details.crypto ? (
          <>
            <Spacer height={20} />
            <WhiteButton
              label="change payout method"
              onClick={() => {
                setPayoutFlowState(PayoutFlowStates.Initial);
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          </>
        ) : (
          <></>
        )*/}
        <Spacer height={20} />
        <TextContent
          fontSize={'fz14'}
          fontColor={theme.colors.yellow}
          text={
            'WARNING: Using an incorrect PayPal email address could result in a delay to funds.'
          }
        />
      </>
    );
  }

  function wire_transfer_success() {
    return (
      <>
        <BoldText
          text={'payout funds'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <BoldText
          text="Payout via PayPal"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />
        <Spacer height={30} />

        <Typography
          text={`Email: ` + details.paypal?.email_address}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="regular"
        />
        <Spacer height={15} />
        <Typography
          text={`Amount: $` + details.balance}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="regular"
        />

        <Spacer height={30} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Payout Request Successful"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            fontSize={"fz20"}
          />
          <Typography
            text="Please allow 1 - 3 business days"
            fontWeight="light"
            fontColor={theme.colors.white}
            fontSize={"fz16"}
          />
          <WhiteButton
            label="Close"
            onClick={() => {
              onClose();
              setPayoutFlowState(PayoutFlowStates.Initial);
            }}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
        </div>
      </>
    );
  }

  function wire_transfer_failed() {
    return (
      <>
        <BoldText
          text={'payout funds'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <BoldText
          text="Payout via PayPal"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />
        <Spacer height={30} />
        <Typography
          text={`Email: ` + details.paypal?.email_address}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="regular"
        />

        <Spacer height={50} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Payout Request Failed"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            fontSize={"fz20"}
          />
          <WhiteButton
            label="Try Again"
            onClick={() => {
              setPayoutFlowState(PayoutFlowStates.WireTransfer);
            }}
            labelColor={theme.colors.black}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
          <WhiteButton
              label="Contact Support"
              onClick={() => {
                contactSupportHandler()
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          {/*details.paypal && details.crypto ? (
            <WhiteButton
              label="change payout method"
              onClick={() => {
                setPayoutFlowState(PayoutFlowStates.Initial);
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          ) : (
            <></>
          )*/}
        </div>
      </>
    );
  }

  function crypto_wallet() {
    return (
      <>
        <BoldText
          text={'confirm payout'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <TextContent
          text="Payout via crypto wallet"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
          fontWeight="bold"
        />
        <Spacer height={30} />

        <WalletAddressText
          text={
            details.crypto?.address
              ? `Wallet Address: `
              : ''
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="regular"
        />
        <Spacer height={8} />
        <WalletAddressText
          text={
            details.crypto?.address
              ? details.crypto?.address
              : ''
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={15} />
        <Typography
          text={
            details.crypto?.chain
              ? `Chain: ` + details.crypto?.chain
              : ''
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={30} />
        <BottomLine />
        <Spacer height={30} />
        <PayoutAmountLeftContainer>
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz24'}
            fontColor={theme.colors.white}
            text={'Payout Amount | '}
          />
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz24'}
            fontColor={theme.colors.yellow}
            text={'USDC $ ' + details.balance}
          />
        </PayoutAmountLeftContainer>
        <Spacer height={30} />

          <WhiteButton
            height={45}
            borderRadius={50}
            isLoading={loading}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            labelColor={theme.colors.black}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="Confirm Payout"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              </div>
            }
            onClick={() => {
              confirmPayout()
            }}
          />

        {/*details.paypal && details.crypto ? (
          <>
            <Spacer height={20} />
            <WhiteButton
              label="change payout method"
              onClick={() => {
                setPayoutFlowState(PayoutFlowStates.Initial);
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          </>
        ) : (
          <></>
        )*/}
        <Spacer height={20} />
        <TextContent
          fontSize={'fz14'}
          fontColor={theme.colors.yellow}
          text={
            'WARNING: Using Incorrect details could result in complete loss of funds. You accept full responsibility for ther above information.'
          }
        />
      </>
    );
  }

  function crypto_wallet_success() {
    return (
      <>
        <BoldText
          text={'payout funds'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <BoldText
          text="Payout via crypto wallet"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={130} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Payout Request Successful"
            fontWeight="light"
            fontColor={theme.colors.white}
            fontSize={"fz20"}
          />
          <WhiteButton
            label="Close"
            onClick={() => {
              onClose();
              setPayoutFlowState(PayoutFlowStates.Initial);
            }}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
        </div>
      </>
    );
  }

  function crypto_wallet_failed() {
    return (
      <>
        <BoldText
          text={'payout funds'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <BoldText
          text="Payout via crypto wallet"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />
        <Spacer height={30} />

        <WalletAddressText
          text={
            details.crypto?.address
              ? `Wallet Address: `
              : ''
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="regular"
        />
        <Spacer height={8} />
        <WalletAddressText
          text={
            details.crypto?.address
              ? details.crypto?.address
              : ''
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={15} />
        <Typography
          text={
            details.crypto?.chain
              ? `Chain: ` + details.crypto?.chain
              : ''
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={30} />


        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Payout Request Failed"
            fontWeight="light"
            fontColor={theme.colors.white}
            fontSize={"fz20"}
          />
          <WhiteButton
            label="Try Again"
            onClick={() => {
              setPayoutFlowState(PayoutFlowStates.CryptoWallet);
            }}
            labelColor={theme.colors.black}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
          {/*details.paypal && details.crypto ? (
            <WhiteButton
              label="change payout method"
              onClick={() => {
                setPayoutFlowState(PayoutFlowStates.Initial);
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          ) : (
            <></>
          )*/}
        </div>
      </>
    );
  }

  function getContent() {
    if (payoutFlowState === PayoutFlowStates.Initial) {
      
      return wire_transfer();
      //return payout_options();
      /*
      if (details.paypal && details.crypto) {
        return payout_options();
      } else if (details.paypal && !details.crypto) {
        setPayoutFlowState(PayoutFlowStates.WireTransfer);
        return wire_transfer();
      } else if (!details.paypal && details.crypto) {
        setPayoutFlowState(PayoutFlowStates.CryptoWallet);
        return crypto_wallet();
      }
      */
    }
    if (payoutFlowState === PayoutFlowStates.WireTransfer) {
      return wire_transfer();
    }
    if (payoutFlowState === PayoutFlowStates.WireTransferSuccess) {
      return wire_transfer_success();
    }
    if (payoutFlowState === PayoutFlowStates.WireTransferFailed) {
      return wire_transfer_failed();
    }
    if (payoutFlowState === PayoutFlowStates.CryptoWallet) {
      return crypto_wallet();
    }
    if (payoutFlowState === PayoutFlowStates.CryptoWalletSuccess) {
      return crypto_wallet_success();
    }
    if (payoutFlowState === PayoutFlowStates.CryptoWalletFailed) {
      return crypto_wallet_failed();
    }
  }

  return (
    <SBModal
    className={'payout-flow'}
      isOpen={isOpen}
      width={'434px'}
      height="544px"
      withSettings={false}
      withProceedingText={true}
      content={
        <>
          <CloseButton
            onClick={() => {
              handleClose();
            }}>
            X
          </CloseButton>

          {getContent()}
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;
/*
const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
`;

const PayoutAmountContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 20px;
`;
*/
const PayoutAmountLeftContainer = styled.div`
  display: flex;
  justify-content: start;
  padding-right: 20px;
`;
/*
const PaymentOption = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  text-align: center;
  max-width: 130px;
  line-height: 30px;
  ${(props) => props.disabled && 'cursor: not-allowed'};
  ${(props) => !props.disabled && 'cursor: pointer'};
`;

const IconContainer = styled.div`
  width: 63px;
  display: flex;
  justify-content: center;
  transform: scale(0.7);

  @media (min-width: 576px) {
    transform: none;
  }
`;
*/
export const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  font-size: 18px;
  width: 280px;

  @media (min-width: 768px) {
    margin: initial;
    width: 357px;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const WalletAddressText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default memo(PayoutFlow);

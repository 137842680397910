import React, { useMemo, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useStoreActions, useStoreState } from 'store/store';
import { useViewport } from 'use-viewport';
import { Link } from 'react-router-dom';
import { AuthResponse } from 'store/models/authModel/authModel.types';
import * as Yup from 'yup';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Eye from 'common/icons/Eye.icon';
import EyeClose from 'common/icons/EyeClose.icon';
import Lock from 'common/icons/Lock.icon';
import UserProfile from 'common/icons/UserProfile.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import './styles/Login.css';

import {
  ErrorMessage,
  ContentButton,
  FirstButton,
  ForgotPasswordButton,
  Form,
  Wrapper,
  HeadContent,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  PasswordVisibility,
  StrapLineTypography,
  LoginContainer,
  LoginLink,
  StrapLineTypographyContainer,
  TextContent,
} from './styled/Login.styled';

import AccountBanner from '../assets/login.jpg';
import { xs } from 'utils/constants';
import ErrorModal from 'common/components/Error/ErrorModal';


interface LocationState {
  from: {
    pathname: string;
  };
  banner: boolean;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  userName: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const Login = () => {
  //const store = useStore();
  const navigate = useNavigate();
  const { showSpace } = useIntercom();
  const location = useLocation();
  const routerState = useLocation().state as LocationState;
  const viewport = useViewport();
  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);

  const user_id = useStoreState((state) => state.authentication.auth.tokenData?.user_id || '');

  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      userName: '',
      password: '',
    }),
    [],
  );

  const authenticate = useStoreActions(
    (actions) => actions.authentication.authenticate,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const handleSupport = () => {
    setShowErrorModal(false)
    showSpace('messages')
  };

  useEffect(() => {
    if (isAuth) {
      navigate('/library/releases');
    }
  }, [isAuth]);

  /* Display unauth banner after redirect to login
   * Logout stores 'user_logout' in local storage. This
   * key/value is used to prevent unauth banner showing
   * on logout */
  useEffect(() => {
    if (
      routerState &&
      routerState.banner &&
      window.localStorage.getItem('user_logout') != 'logout'
    ) {
      window.localStorage.removeItem('user_logout');
      setGlobalBanner({
        title: 'Login required.',
        text: 'Please login below.',
      });
    } else {
      window.localStorage.removeItem('user_logout');
    }
  }, []);

  const hideGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.hideGlobalBanner,
  );


  const setPreviousUrl = useStoreActions(
    (actions) => actions.location.setPreviousUrl,
  );


  const setUserSlug = useStoreActions(
    (actions) => actions.authentication.setSlug,
  );

  const setOnboarding = useStoreActions(
    (actions) => actions.authentication.setOnboarding,
  );

  const { previousUrl } = useStoreState((state) => state.location);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  function forgotPasswordHandler() {
    navigate('/remind-password');
  }

  async function submitHandler() {

    const isEmail = validateEmail(values.userName);
    setLoading(true);
    await authenticate({
      username: values.userName,
      password: values.password
    }).then((response: AuthResponse) => {
      setLoading(false);
      setUserSlug(values.userName);
      (window as any).gtag("event", "login", {
        method: (isEmail ? "email" : "username")
      });

      hideGlobalBanner();

      if (response.isEmailConfirmed !== true) {
        navigate('/confirm-email', {
          state: {
            id: user_id
          },
        });
      } else {
        
        if (!response.tokenData.is_artist) {
          setOnboarding(response.fcaStep || '');
        }
        if (previousUrl) {
          setPreviousUrl('');
          navigate(previousUrl);
        } else if (location.state) {
          if (response.tokenData.is_artist) {
            navigate('/artist/dashboard');
          } else {
            navigate((location.state as LocationState).from.pathname);
          }
        } else {
          if (response.tokenData.is_artist) {
            navigate('/artist/dashboard');
          } else {
            navigate('/library/hot-releases');
          }
        }
      }

    }).catch((e: any) => {
      e
      setShowErrorModal(true);
      setLoading(false);
    });
  }

  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer pageTitle="Login | SongBits">
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <div style={{ display: 'flex' }}>
              <HeadContent
                text={viewport.width < xs ? 'Welcome' : 'welcome'}
                fontSize="fz100"
                fontWeight="bold"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>
        </HeadingSection>

        <Form>
          <TextContent
            text="Login"
            fontSize="fz24"
            fontWeight="bold"
            letterSpacing="-0.03em"
            fontColor={theme.colors.yellow}
          />

          <InputContainer>
            <Icon>
              <UserProfile />
            </Icon>
            <InputWithIcon
              height={viewport.width < xs ? 65 : 72}
              type="text"
              withBottomLine
              label="Username"
              value={values.userName}
              inputName={'username'}
              autoComplete="username email"
              error={
                Boolean(errors.userName && touched.userName)
                  ? errors.userName
                  : undefined
              }
              onChange={handleChange('userName')}
            />
          </InputContainer>

          <InputContainer>
            <Icon>
              <Lock />
            </Icon>
            <InputWithIcon
              height={viewport.width < xs ? 65 : 72}
              withBottomLine
              label="Password"
              value={values.password}
              inputName={'password'}
              autoComplete="current-password"
              type={showPassword ? 'password' : 'text'}
              error={
                Boolean(errors.password && touched.password)
                  ? errors.password
                  : undefined
              }
              onChange={handleChange('password')}
            />
            <PasswordVisibility className='password-toggle' onClick={passwordVisibilityHandler}>
              {showPassword ? <Eye /> : <EyeClose />}
            </PasswordVisibility>
          </InputContainer>

          <ForgotPasswordButton
            label="Forgot Password?"
            onClick={forgotPasswordHandler}
            isTextBold={false}
            className="forgot-password-button"
          />

          <FirstButton
            className="login-button"
            height={45}
            width={173}
            borderRadius={50}
            isLoading={loading}
            disabled={loading}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="log" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <ContentButton
                  text="in"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={handleSubmit}
          />

          <>
            <LoginContainer>
              Not got a SongBits account?{'  '}
              <LoginLink className={'create-account-link'} to="/create-account">Create Account</LoginLink>
            </LoginContainer>
          </>  
            
          <ErrorModal show={showErrorModal} 
            onClose={() => {
              setShowErrorModal(false)
            }} 
            content={<><ErrorMessage>
              Invalid username or password.{'\n\n'}Please try again or use{' '}
              <Link
                to="/remind-password"
                onClick={() => setShowErrorModal(false)}>
                forgot password
              </Link>
              {'\n\n'}Alternatively contact{' '}
              <Link to="#" onClick={() => handleSupport()}>
                support
              </Link>
            </ErrorMessage></>} />

        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(Login, false);

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import AddSong from 'modules/artist/pages/components/AddSong/AddSong';
import theme from 'theme/theme';

import arrow from '../../../assets/arrow-down-right.png';

import { DropsDataType } from 'modules/account/types';
import { useViewport } from 'use-viewport';
import { ArrowImg, SongImg } from '../../styled/ArtistSettings.styled';
import { DropsType } from 'typings/types';
import {
  ButtonWrapper,
  Drop,
  DropButton,
  DropDetails,
  DropDetailsContainer,
  DropsContainer,
  DropTypeContainer,
  Pipe,
} from './Drops.styled';

type DropsProps = {
  drops: DropsDataType[];
  isArtist?: boolean;
  showLinkToAllDrops?: boolean;
  isArtistArea?: boolean;
  showQuickLinks?: boolean;
};

const Drops = ({
  drops,
  isArtist = false,
  showLinkToAllDrops = true,
  isArtistArea = false,
  showQuickLinks = false,
}: DropsProps) => {
  const navigate = useNavigate();
  const viewport = useViewport();

  const getDropsByStatus = (filterStatus: string) =>
    drops.filter(({ status }) => status === filterStatus);

  const dropsByStatus = [
    {
      title: 'current releases',
      array: getDropsByStatus(DropsType.CURRENT),
      fontColor: theme.colors.yellow,
    },
    {
      title: 'completed releases',
      array: getDropsByStatus(DropsType.COMPLETED),
      fontColor: theme.colors.white,
    },
    {
      title: 'incomplete',
      array: getDropsByStatus('incomplete'),
      fontColor: theme.colors.white,
    },
  ];

  const filteredDropsByStatus = useMemo(() => {
    return dropsByStatus.filter((d) =>
      !isArtist
        ? d.title !== 'incomplete' && d.title !== 'releases in progress'
        : d.title !== 'current releases',
    );
  }, [dropsByStatus, isArtist]);

  // Capitalise first word, lowercase the rest
  function formatTitle(title: string) {
    return (
      title.toLowerCase().charAt(0).toUpperCase() + title.slice(1).toLowerCase()
    );
  }

  return (
    <DropsContainer>
      {filteredDropsByStatus?.map(({ title, array, fontColor }, index) => (
        <DropTypeContainer
          key={`Key for DropsContainer is - ${title} - ${index}`}>
          {(array.length || title === 'releases in progress') && (
            <>
              <Typography
                text={title}
                fontSize={'fz24'}
                fontWeight="bold"
                fontColor={fontColor}
              />
              <Spacer height={10} />
            </>
          )}
          {!array.length && title === 'releases in progress' ? (
            <AddSong />
          ) : (
            array.map((d, i) => (
              <DropButton
                width={viewport.width < 576 ? viewport.width * 0.9 : 450}
                key={`Key for DropButton is - ${title} - ${i}`}
                label={
                  <Drop>
                    <ArrowImg src={arrow} />
                    <SongImg src={d.cover} />
                    <DropDetailsContainer>
                      <Typography
                        text={formatTitle(d.name)}
                        fontSize={d.name.length > 30 ? 'fz14' : 'fz18'}
                        fontWeight="regular500"
                        className="songName"
                        fontColor={theme.colors.white}
                      />
                      <Spacer height={5} />
                      <DropDetails>
                        {d.songType && d.songType === 'COMING_SOON' ? (
                          <></>
                        ) : (
                          <>
                            <Typography
                              text={d.date}
                              fontSize="fz14"
                              fontWeight="light"
                              fontColor={theme.colors.white}
                            />

                          </>
                        )}
                        {isArtistArea ? <>
                        
                        <Pipe>|</Pipe>
                        <Typography
                          fontSize="fz14"
                          fontWeight="light"
                          text={`${d.sold} Sold`}
                          fontColor={theme.colors.white}
                        /></> : <></>}
                      </DropDetails>
                    </DropDetailsContainer>
                  </Drop>
                }
                onClick={() => {
                  navigate(isArtistArea ? d.dropSummaryLink || '/' : d.link);
                }}
              />
            ))
          )}
          {!!array.length && <Spacer height={20} />}
        </DropTypeContainer>
      ))}
      {isArtist && getDropsByStatus('inProgress').length && <AddSong />}
      {(drops.length > 0 && showLinkToAllDrops) && (
        <ButtonWrapper onClick={() => navigate('/artist/releases')}>
          <Typography
            text="View All Releases"
            fontColor={theme.colors.yellow}
          />
        </ButtonWrapper>
      )}
      {drops.length > 0 && (<>
      <Spacer height={30} />
      </>) }
      {isArtist && showQuickLinks && (
        <QuickLinks
          links={[
            { title: 'Edit Artist Profile', link: '/artist/dashboard' },
            { title: 'Transaction History', link: '/library/my-collection' },
            { title: 'Account Settings', link: '/account/settings' },
            { title: 'A&R Support', link: '/support' },
          ]}
        />
      )}
    </DropsContainer>
  );
};

export default Drops;
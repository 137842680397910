import React, { FC } from 'react';
import styled from 'styled-components';

import Spacer from '../Spacer/Spacer';

type ImagePickerPlaceholderProps = {
  width?: number;
  height?: number;
  withTextPlaceholder?: boolean;
};

const ImagePickerPlaceholder: FC<ImagePickerPlaceholderProps> = ({
  width,
  height,
  withTextPlaceholder = true,
}) => {
  return (
    <>
      <ImageContainer>
        <Image
          className={'placeholder-wave placeholder bg-secondary '}
          width={width}
          height={height}
        />
        {withTextPlaceholder && (
          <>
            <Spacer height={10} />
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-12'
              }>
              &nbsp;
            </span>
          </>
        )}
      </ImageContainer>
    </>
  );
};

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img<{ width?: number; height?: number }>`
  width: ${(props) => (props.width ? props.width : 126)}px;
  height: ${(props) => (props.height ? props.height : 126)}px;

  border-radius: 10px;

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

export default ImagePickerPlaceholder;

import * as React from 'react';

const SetupIncomplete = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="10"
      cy="10"
      r="9"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
    />
  </svg>
);

export default SetupIncomplete;

import * as React from 'react';

import { IconProps } from '../common.types';

const DollarCircleIcon = ({ width = 24, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="white" />
    <path
      d="M15.4916 13.4566C15.4916 11.6416 14.6116 10.7249 11.8616 9.66161C10.0283 8.94661 9.53328 8.5066 9.53328 7.88327C9.53328 7.27827 10.0833 6.8566 10.7433 6.8566C11.4766 6.8566 12.0999 7.33327 12.4849 8.26827L14.8683 6.78327C14.2449 5.55494 13.2916 4.7666 12.0999 4.45494V2.60327H10.0833V4.36327C7.99328 4.61994 6.65495 6.0316 6.65495 7.93827C6.65495 9.89994 7.64495 10.9083 10.3766 12.0083C12.0449 12.6866 12.6316 13.1266 12.6316 13.7133C12.6316 14.6849 11.8616 15.1433 10.9266 15.1433C9.64328 15.1433 8.72661 14.4466 8.59828 12.9433L5.73828 13.6583C6.08661 15.8583 7.80995 17.3616 10.0833 17.6366V19.5799L12.0999 19.5616V17.5633C14.0799 17.1049 15.4916 15.5649 15.4916 13.4566Z"
      fill="black"
    />
  </svg>
);

export default DollarCircleIcon;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { md, sm } from 'utils/constants';
import { getWindowDimensions } from 'utils/functions';
import defaultImage from '../../assets/third-artist.jpg';
import defaultImageMobile from '../../assets/third-artist-mobile.jpg';
import InviteBadge from './InviteBadge/InviteBadge';
import SoldOutBadge from './SoldOutBadge/SoldOutBadge';
// import LiveRelease from 'common/icons/LiveRelease.icon';
import ExplicitLyrics from 'common/icons/ExplicitLyrics.icon';
import ComingSoonBadge from './ComingSoonBadge/ComingSoonBadge';

type ArtistCardProps = {
  header: string;
  date: string;
  image: string;
  songType: string;
  soldBits: number;
  live: boolean;
  explicit: boolean;
  remainingBits: number;
  smallImage: string;
  index: number;
  bannerFocalPoint: string;
};

const ArtistCard = ({
  header,
  date,
  image,
  songType,
  // soldBits,
  live,
  explicit,
  soldBits,
  smallImage,
  index,
  bannerFocalPoint,
}: ArtistCardProps) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setDefaultImage = (e: any) => {
    if (windowDimensions.width >= sm) {
      return (e.currentTarget.src = defaultImage);
    }
    e.currentTarget.src = defaultImageMobile;
  };

  const generateHeading = (header: string) => {
    if (header.length <= 24) {
      return (
        <Background>
          <Header
            text={header}
            fontColor={theme.colors.white}
            index={index}
            header={header}
            fontWeight="bold"
          />
        </Background>
      );
    } else {
      return (
        <MultiLineHeading>
          {header
            .replace(' ', ' ')
            .split('|')
            .map((t, key) => {
              return (
                <Background key={key} index={index}>
                  <Header
                    text={t}
                    fontColor={theme.colors.white}
                    index={index}
                    header={header}
                    fontWeight="bold"
                    display="block"
                  />
                </Background>
              );
            })}
        </MultiLineHeading>
      );
    }
  };

  return (
    <CardContainer className="card-container">
      {/* {live && (
        <DropWrapper index={index}>
          {windowDimensions.width >= sm ? (
            <LiveRelease />
          ) : (
            <LiveRelease width={147} height={147} />
          )}
        </DropWrapper>
      )} */}
      <ContentContainer index={index}>
        <BadgeContainer>
          {soldBits === 0 && <SoldOutBadge />}
          {songType === 'INVITE_ONLY' && <InviteBadge />}
        </BadgeContainer>

        {songType === 'COMING_SOON' ? (
          <>
            <ComingSoonContainer>
              <ComingSoonBadge
                index={index}
                heading={generateHeading(header)}
              />
            </ComingSoonContainer>
          </>
        ) : (
          <>
            {generateHeading(header)}
            {!live ? <>
              <Date
              text={date}
              fontSize="fz200"
              fontWeight="bold"
              fontColor={
                soldBits === 0 ? theme.colors.white : theme.colors.yellow
              }
              index={index}
            />
            </> : <></>}
            
          </>
        )}
      </ContentContainer>
      <Image
        src={windowDimensions.width >= sm ? image : smallImage}
        onError={(e) => setDefaultImage(e)}
        index={index}
        focalPoint={bannerFocalPoint}
      />
      {explicit && (
        <ExplicitContainer index={index} focalPoint={bannerFocalPoint}>
          <ExplicitLyrics />
        </ExplicitContainer>
      )}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 330px;
  overflow: hidden;
  background: black;
`;

// const DropWrapper = styled.div<{ index: number }>`
//   position: absolute;
//   top: 20px;

//   ${(props) => (props.index % 2 !== 0 ? 'right: 50px' : 'left: 50px')};

//   @media (max-width: ${md}px) {
//     left: 45px;
//   }
// `;

const ContentContainer = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.index % 2 !== 0 ? 'start' : 'end')};
  ${(props) => (props.index % 2 !== 0 ? 'left: 0' : 'right: 0')};
  bottom: 0;
  position: absolute;
  z-index: 2;
`;

const Background = styled.div<{ index?: number }>`
  display: flex;
  align-items: center;
  z-index: 1;
  height: 60px;
  width: min-content;
  background-color: black;
  align-self: ${(props) =>
    props.index && props.index === 1 ? 'flex-start' : 'inherit'};

  padding: 0 10px;

  @media (max-width: ${md}px) {
    height: 32px;
  }
`;

const Header = styled(Typography)<{
  index: number;
  header: string;
  display?: string;
}>`
  z-index: 1;
  font-size: ${(props) => (props.header.length < 46 ? '56px' : '30px')};
  line-height: 60px;
  font-family: Roboto-Black, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${(props) => (props.display ? props.display : 'inline')};
  ${(props) => (props.index % 2 === 0 ? 'right: 0' : 'left: 0')};
  @media (max-width: ${md}px) {
    max-width: 357px;
    font-size: ${theme.fontSizes.fz24}px;
  }
`;


const Date = styled(Typography)<{ index: number }>`
  font-family: Roboto-Black, sans-serif;
  line-height: 78%;
  font-weight: 900;
  opacity: 0.5;
  font-style: normal;
  letter-spacing: -10px;
  text-align: center;
  overflow-y: hidden;
  @media (max-width: ${md}px) {
    font-size: ${theme.fontSizes.fz100}px;
    letter-spacing: -5px;
  }
`;


const Image = styled.img<{ index: number; focalPoint: string }>`
  width: 100%;
  object-fit: cover;
  max-width: 100vw;
  min-height: 250px;
  max-height: auto;
  overflow: hidden;

  // Even Number - explicit icon on left (which means text is on right and focal point should be left)
  // Odd number - explicit icon on right (which means text is on left and focal point should be right)

  ${(props) => {
    // Specific to hardcoded Bay Street Records card
    if (props.focalPoint === 'BAY_STREET') {
      return props.index % 2 === 0
        ? `
        object-fit: contain;
        @media (min-width: 576px) {
          object-fit: cover;
          transform: translateX(-12vw);
        }
        `
        : `
         
        object-fit: contain;
        transform: translateX(28vw);
        @media (min-width: 576px) {
          object-fit: cover;
          transform: translateX(12vw);
        }
         `;
    } else if (props.index % 2 === 0) {
      // Image flipping
      return props.focalPoint === 'RIGHT' ? 'transform: scaleX(-1)' : '';
    } else {
      return props.focalPoint === 'LEFT' ? 'transform: scaleX(-1)' : '';
    }
  }};

  @media (min-width: 992px) {
    min-height: 330px;
    max-height: 330px;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  align-self: start;
  margin-left: 17%;
`;

const MultiLineHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExplicitContainer = styled.div<{ index: number; focalPoint: string }>`
  position: absolute;

  top: 20px;

  ${(props) => (props.index % 2 == 0 ? 'left: 20px' : 'right: 20px')};

  @media (min-width: 768px) {
    top: unset;
    bottom: 30px;
  }
`;

const ComingSoonContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2px;
`;

export default ArtistCard;

import DefaultButton from 'common/components/DefautButton/DefaultButton';
import Typography from 'common/components/Typography/Typography';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 15px;
`;

export const HeadContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Title = styled(Typography)<{ withLeftMargin: boolean }>`
  font-family: 'HKGrotesk-Black';
  font-size: 38px;
  letter-spacing: -0.055em;

  @media (min-width: 768px) {
    font-size: 38px;
    margin-left: ${(props) => (props.withLeftMargin ? '100px' : 'initial')};
  }

  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

export const QuickLinksContainer = styled.div`
  
`;

export const CoverButton = styled(DefaultButton)`
  height: fit-content;
`;

import axios from "axios";
import store from "store/store";

const instance = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_REST_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export async function handleErrors(error: any) {
  if (axios.isAxiosError(error)) {
    console.error('error message: ', error.message);
    //throw new Error(error.message);
  } else {
    console.error('unexpected error: ', error);
    //throw new Error('An unexpected error occurred');
  }
}

instance.interceptors.request.use(
  async (config) => {
    const token = await store.getActions().authentication.getAuthToken();
    if (token) {
      if (!("Authorization" in config.headers)) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      await store.getActions().authentication.logout();
      return Promise.reject(error);
    }
  }
)

export default instance;

import React, { memo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'store/store';

import theme from 'theme/theme';
//import { useAppDispatch } from 'hooks/reduxToolkit';
//import { resetPersist } from 'store-persist/reducers/modalSlice';
import Spacer from 'common/components/Spacer/Spacer';
import HamburgerClose from 'common/icons/HamburgerClose.icon';

import {
  BorderContainer,
  Container,
  Item,
  NavButton,
  ProfileIconContainer,
  TypographyMenu,
  NavButtonClose,
} from './styled/QuickLinksMenu.styled';
import { useIntercom } from 'react-use-intercom';

type Link = { title: string; link: string };

type navLinksType = { title: string; link: string }[];

type QuickLinksMenuProps = {
  isQuickLinksOpen: boolean;
  isLogout?: boolean;
  setQuickLinksOpen: (quicklinksOpen: boolean) => void;
  links: Link[];
};

const QuickLinksMenu = ({
  isQuickLinksOpen = false,
  isLogout = false,
  setQuickLinksOpen,
  links,
}: QuickLinksMenuProps) => {
  const navigate = useNavigate();
  //const dispatch = useAppDispatch();
  const logout = useStoreActions((state) => state.authentication.logout);
  const { shutdown } = useIntercom();
  const [navLinksQuickLinks, setNavLinksQuickLinks] = useState<navLinksType>(
    [],
  );

  const navigateTo = (link: string) => () => {
    setQuickLinksOpen(false);

    if (link === '/logout') {
      //store.persist.clear().then(() => {
        //dispatch(resetPersist());
        window.localStorage.removeItem('purchaseId');
        // Used to prevent unauth banner showing on logout
        window.localStorage.setItem('user_logout', 'logout');
        shutdown();
        logout();
        //navigate(link, { replace: true });
        navigate(link);
      //});
    } else {
      navigate(link, { replace: true });
    }
  };

  useEffect(() => {
    const tempNavLinks = [...links];

    if (isLogout) {
      tempNavLinks.push({ title: 'Log out', link: '/logout' });
    }

    setNavLinksQuickLinks(tempNavLinks);
  }, []);

  return (
    <BorderContainer isQuickLinksOpen={isQuickLinksOpen}>
      <Container>
        <div
          style={{
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <TypographyMenu
              fontSize="fz48"
              fontWeight="bold"
              text={'quick links'}
              fontColor={theme.colors.yellow}
            />
            <ProfileIconContainer>
              <NavButtonClose
                label={<HamburgerClose />}
                onClick={() => {
                  setQuickLinksOpen(false);
                }}
              />
            </ProfileIconContainer>
          </div>
          <Spacer height={30} />

          {navLinksQuickLinks.map(({ title, link }, index) => (
            <NavButton
              key={`Nav key for hamburger menu is - ${title} - ${index}`}
              label={
                <Item
                  text={title}
                  fontSize="fz18"
                  isLastElement={index === navLinksQuickLinks.length - 1}
                  isFirstElement={false}
                />
              }
              onClick={navigateTo(link)}
            />
          ))}
          <Spacer height={15} />
        </div>
      </Container>
    </BorderContainer>
  );
};

export default memo(QuickLinksMenu);

import * as React from 'react';

import { IconProps } from '../common.types';

const SoundCloud = ({ width = 48, height = 24, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M28.7998 0C25.3424 0 22.0799 1.71749 20.1599 4.49998V7.85996L20.4599 8.06996L20.8199 7.10996C22.1624 3.94123 25.3499 1.91999 28.7098 1.91999C33.5098 1.91999 37.3498 5.75997 37.3498 10.5599V12.4799L38.7898 11.9099C39.461 11.6212 40.1285 11.5199 40.7998 11.5199C43.6798 11.5199 46.0797 13.9199 46.0797 16.7999C46.0797 19.6799 43.6798 22.0799 40.7998 22.0799H20.1599V23.9999H40.7998C44.7373 23.9999 47.9997 20.7374 47.9997 16.7999C47.9997 12.8624 44.7373 9.59995 40.7998 9.59995C40.3198 9.59995 39.8398 9.68245 39.3598 9.77995C38.8798 4.30873 34.3686 0 28.7998 0ZM16.3199 4.79997C15.6487 4.79997 14.9774 4.88247 14.3999 4.97997V23.9999H16.3199V4.79997ZM17.2799 4.79997V23.9999H19.1999V5.27997C18.6224 5.08872 17.9512 4.89747 17.2799 4.79997ZM13.4399 5.27997C12.7687 5.56872 12.0974 5.85747 11.5199 6.23997V23.9999H13.4399V5.27997ZM10.5599 7.01996C9.7912 7.69121 9.11995 8.54995 8.63995 9.50995V23.9999H10.5599V7.01996ZM6.23997 10.5599C6.07122 10.5712 5.90247 10.6012 5.75997 10.6499V23.9099C6.04872 24.0074 6.43121 23.9999 6.71996 23.9999H7.67996V10.6499C7.39121 10.5524 7.00871 10.5599 6.71996 10.5599C6.57746 10.5599 6.40871 10.5487 6.23997 10.5599ZM4.79997 10.8599C4.12873 11.0512 3.45748 11.3399 2.87998 11.8199V22.7399C3.45748 23.1224 4.12873 23.5086 4.79997 23.6999V10.8599ZM1.91999 12.5699C0.768746 13.8187 0 15.4574 0 17.2799C0 19.1024 0.768746 20.7411 1.91999 21.9899V12.5699Z" fill="white" 
      fillOpacity={opacity}/>
  </svg>
);

export default SoundCloud;

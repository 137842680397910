import * as React from 'react';

import { IconProps } from '../common.types';

const ExcelIcon = ({ width = 52, height = 52 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0215 52.0431C40.3928 52.0431 52.0431 40.3928 52.0431 26.0215C52.0431 11.6502 40.3928 0 26.0215 0C11.6502 0 0 11.6502 0 26.0215C0 40.3928 11.6502 52.0431 26.0215 52.0431Z" fill="#FFD600"/>
<path d="M28.25 19.4375V12H17.3125C16.5852 12 16 12.5852 16 13.3125V38.6875C16 39.4148 16.5852 40 17.3125 40H35.6875C36.4148 40 37 39.4148 37 38.6875V20.75H29.5625C28.8406 20.75 28.25 20.1594 28.25 19.4375ZM31.5367 25.2617L28.25 30.375L31.5367 35.4883C31.8156 35.9258 31.5039 36.5 30.9844 36.5H29.0758C28.8352 36.5 28.6109 36.3688 28.4961 36.1555C27.4242 34.1758 26.5 32.3984 26.5 32.3984C26.15 33.2078 25.9531 33.4922 24.4984 36.1609C24.3836 36.3742 24.1648 36.5055 23.9242 36.5055H22.0156C21.4961 36.5055 21.1844 35.9313 21.4633 35.4938L24.7609 30.3805L21.4633 25.2672C21.1789 24.8297 21.4961 24.2555 22.0156 24.2555H23.9188C24.1594 24.2555 24.3836 24.3867 24.4984 24.6C25.9258 27.2687 25.5922 26.4375 26.5 28.3461C26.5 28.3461 26.8336 27.7062 28.5016 24.6C28.6164 24.3867 28.8406 24.2555 29.0812 24.2555H30.9844C31.5039 24.25 31.8156 24.8242 31.5367 25.2617ZM37 18.6664V19H30V12H30.3336C30.6836 12 31.0172 12.1367 31.2633 12.3828L36.6172 17.7422C36.8633 17.9883 37 18.3219 37 18.6664Z" fill="black"/>
  </svg>
);

export default ExcelIcon;

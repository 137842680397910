import { detect } from 'detect-browser';

export function isBrowserSupported() {
  const browser = detect();
  if (browser) {
    switch (browser.name as string) {
      case 'chrome':
        return true;
      //case 'crios':
      //  return true;
      case 'ios-webview':
        return true;
      case 'chrome':
        return true;
      case 'firefox':
        return true;
      case 'brave':
        return true;
      case 'edge':
        return true;
      case 'safari':
        return true;
      //case 'ios':
        //return true;
      default:
        return false;
    }
  }
}

export function browserName() {
  const browser = detect();
  if (browser) {
    return browser.name;
  }
  return null;
}
import * as React from 'react';

import { IconProps } from '../common.types';

const CopyFilledIcon = ({ width = 18, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6 0C4.716 0 4 0.716 4 1.6V14.4C4 15.284 4.716 16 5.6 16H16C16.884 16 17.6 15.284 17.6 14.4V5.2C17.6 4.988 17.516 4.78477 17.3656 4.63438L12.9656 0.234375C12.8152 0.0839749 12.612 0 12.4 0H5.6ZM12 1.52344L16.0766 5.6H12.8C12.3584 5.6 12 5.2416 12 4.8V1.52344ZM1.6 4C0.716 4 0 4.716 0 5.6V18.4C0 19.284 0.716 20 1.6 20H12C12.884 20 13.6 19.284 13.6 18.4V17.6H5.6C3.8352 17.6 2.4 16.1648 2.4 14.4V4H1.6Z"
      fill="white"
    />
  </svg>
);

export default CopyFilledIcon;

import { action } from 'easy-peasy';
import * as R from 'ramda';

import { WalletModel } from 'store/models/walletModel/walletModel.types';

export const wallet: WalletModel = {
  walletAddress: null,
  isWalletConnected: false,
  setWalletAddress: action((state, payload) => {
    state.walletAddress = payload;
  }),
  setIsConnected: action((state, payload) => {
    state.isWalletConnected = payload;
  }),
  setWalletData: action((state, payload) => {
    state.walletAddress = R.defaultTo(null, payload.walletAddress);
    state.isWalletConnected = payload.isWalletConnected;
  }),
};

import React from 'react';
import TablePlaceholder from './Table';
import Spacer from '../Spacer/Spacer';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

const UserDetailsPlaceholder = ({
  isTableExpanded,
}: {
  isTableExpanded: boolean;
}) => {
  const viewport = useViewport();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 'inherit' }}>
      <HeaderRow>
        {/* Dropdown */}
        <span
          style={{
            borderRadius: '10px',
            marginBottom: '15px',
            width: isTableExpanded ? '30%' : '',
          }}
          className={`placeholder-wave placeholder fs-18 lh-44 bg-secondary ${
            viewport.width < 576 ? 'col-8' : isTableExpanded ? 'col-4' : 'col-7'
          }`}>
          &nbsp;
        </span>

        <span
          style={{ marginBottom: '15px' }}
          className={`placeholder-wave placeholder fs-18 lh-44 bg-secondary ${
            !isTableExpanded ? 'col-2' : 'col-1'
          }`}>
          &nbsp;
        </span>
      </HeaderRow>
      <Spacer height={20} />

      <TablePlaceholder
        withTitle
        withUserTableHeader
        isUserList
        isTableExpanded={isTableExpanded}
        numRows={10}
      />
    </div>
  );
};

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default UserDetailsPlaceholder;

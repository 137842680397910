import { action } from 'easy-peasy';
import { PreviewModel } from './previewModel.types';

export const preview: PreviewModel = {
  user: '',
  previewData: {},
  isEditingPreview: false,
  reloadAfterPreview: false,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setPreviewData: action((state, payload) => {
    state.previewData = payload;
  }),
  setIsEditingPreview: action((state, payload) => {
    state.isEditingPreview = payload;
  }),
  setReloadAfterPreview: action((state, payload) => {
    state.reloadAfterPreview = payload;
  }),
};

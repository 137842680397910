import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import { TransactionType } from '../../Transactions';
import { escToClose } from 'utils/functions';

interface ModalProps {
  onClose: () => void;
  transaction: TransactionType;
}

const TransactionDetailsModal: FC<ModalProps> = ({ onClose, transaction }) => {
  const viewport = useViewport();

  useEffect(() => {
    escToClose(onClose);
  }, []);

  function parseDate(date: string) {
    const d = new Date(date).toDateString().split(' ');
    const timeString = new Date(date).toLocaleTimeString('en', {
      timeZone: 'GMT',
    });
    const components = timeString.split(' ');
    const time = components[0].split(':');

    return `${d[1]} ${d[2]} ${d[3]} ${time[0]}:${time[1]} GMT`;
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={onClose}>X</CloseButton>
      <BoldText
        text="transaction detail"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      <StatusRow>
        <TextContent text="Status: " />
        <TextContent
          text={
            transaction.status[0].toUpperCase() +
            transaction.status.substring(1)
          }
          fontColor={theme.colors.yellow}
        />
      </StatusRow>
      <Spacer height={10} />
      <StatusRow>
        <TextContent text="Transaction Type: " />
        <TextContent
          text={
            transaction.transactiontype[0].toUpperCase() +
            transaction.transactiontype.substring(1)
          }
        />
      </StatusRow>
      <Spacer height={60} />
      <Details>
        <RowTitle>
          <TextContent text="Trans ID" />
        </RowTitle>
        <RowValue>
          <TextContent text={transaction.transactionid} />
        </RowValue>

        <RowTitle>
          <TextContent text="Date Stamp" />
        </RowTitle>
        <RowValue>
          {/* TODO: Format */}
          <TextContent text={parseDate(transaction.timestamp)} />
        </RowValue>

        <RowTitle>
          <TextContent text="Sub Total" />
        </RowTitle>
        <RowValue>
          <TextContent
            text={`${Number(transaction.currency_amount).toLocaleString('en', {
              style: 'currency',
              currency: 'USD',
            })}`}
          />
        </RowValue>

        {/*<RowTitle>
          <TextContent text="Service Fee" />
        </RowTitle>
        <RowValue>
          <TextContent
            text={
              transaction.transactiontype === 'royalties'
                ? `$${+transaction.currency_amount}`
                : `$${(
                    +transaction.currency_amount 
                  ).toFixed(2)}`
            }
          />
          </RowValue>*/}

        <RowTitle>
          <TextContent text="Total" />
        </RowTitle>
        <RowValue>
          <TextContent
            text={`$${(
              +transaction.currency_amount + +transaction.fees_amount
            ).toFixed(2)}`}
          />
        </RowValue>
      </Details>
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const StatusRow = styled.div`
  display: flex;
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const RowTitle = styled.div`
  width: 30%;
  margin-bottom: 30px;
`;

const RowValue = styled.div`
  width: 70%;
  margin-bottom: 30px;
`;

export default TransactionDetailsModal;

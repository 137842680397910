import React, { useMemo, useEffect, useState } from 'react';
import { useViewport } from 'use-viewport';
import { useIntercom } from 'react-use-intercom';

import PageContainer from 'common/layout/PageContainer';
import Typography from 'common/components/Typography/Typography';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Spacer from 'common/components/Spacer/Spacer';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

import restService from 'services/rest.service';
import PageLayout from 'common/components/PageLayout/PageLayout';

import EmailSupportIcon from 'common/icons/EmailSupport.icon';
import LiveChatIcon from 'common/icons/LiveChat.icon';
import SocialMediaIcon from 'common/icons/SocialMedia.icon';
//import Telegram from 'common/icons/TwitterSmallWhite.icon';
import Twitter from 'common/icons/Telegram.icon';
import Discord from 'common/icons/Discord.icon';
import TikTok from 'common/icons/TikTokFooter.icon';
import InstagramSmallWhite from 'common/icons/InstagramSmallWhite.icon';
import { getUserIdFromJWT } from 'utils/functions';

import {
  Row,
  Column,
  IconsContainer,
  IconButton,
  EmailLink,
  EmailSupportText,
  LiveChatButton,
} from './styled/Support.styled';


type AccountSettingsResponseTypes = {
  image?: string;
  avatar_id?: string;
  username: string;
  email: string;
};


const Support = () => {
  const { show, update } = useIntercom();
  const viewport = useViewport();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<AccountSettingsResponseTypes>();

  const userId = getUserIdFromJWT();

  async function getAccountSettings() {
    const transactions = await restService
      .getAccountSettings()
      .catch((error) => {
        error
      });
    return transactions;
  }

  useEffect(() => {
    getAccountSettings()
      .then((res) => {
        const data = {
          username: res.username,
          email: res.email,
        };
        setAccountData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const quickLinks = useMemo(
    () => [
      {
        title: 'Terms & Conditions',
        link: '/legal/terms-conditions',
      },
      {
        title: 'Privacy Policy',
        link: '/legal/privacy-policy',
      },
      {
        title: 'Cookie Policy',
        link: '/legal/cookie-policy',
      },
      /*{
        title: 'Community Guidelines',
        link: '/legal/community-guidelines',
      },*/
      {
        title: 'Company',
        link: '/company',
      },
    ],
    [],
  );

  const contentSection = (
    <>
      {!loading ? (
        <>
          <Typography
            text="We are here to help with any technical, payment or other questions you may have. "
            fontWeight="regular"
            fontSize="fz18"
            lineHeight="24px"
          />
          <Row>
            <LiveChatButton
              onClick={() => {
                update({
                  userId: userId,
                  name: accountData?.username,
                  email: accountData?.email,
                });
                show();
              }}
              label={<LiveChatIcon></LiveChatIcon>}
            />

            <Column>
              <LiveChatButton
                onClick={() => {
                  update({
                    userId: userId,
                    name: accountData?.username,
                    email: accountData?.email,
                  });
                  show();
                }}
                label={
                  <Typography
                    text="Live Chat "
                    fontWeight="bold"
                    fontSize="fz18"
                    lineHeight="24px"
                  />
                }
              />
              <Typography
                text="(Monday - Friday 9am to 6pm)"
                fontWeight="regular"
                fontSize="fz18"
                lineHeight="24px"
              />
            </Column>
          </Row>
          <Row>
            <EmailSupportIcon></EmailSupportIcon>
            <Column>
              <Typography
                text="Support Email "
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />

              <EmailSupportText>
                <EmailLink href="mailto:support@songbits.com?subject=Help">
                support@songbits.com
                </EmailLink>{' '}
                (24 hour response)
              </EmailSupportText>
            </Column>
          </Row>
          <Row>
            <SocialMediaIcon></SocialMediaIcon>
            <Column>
              <Typography
                text="Social Media "
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />

              <IconsContainer>
                <a
                  href="https://twitter.com/SongBitsfans"
                  target="_blank"
                  rel="noreferrer">
                  <IconButton label={<Twitter />} onClick={() => {}} />
                </a>
                {/*<a href="" target="_blank" rel="noreferrer">
                  <IconButton
                    label={<Telegram />}
                    onClick={() => {}}
                  />
                </a>*/}
                <a
                  href="https://discord.gg/GHBH9YhjDU"
                  target="_blank"
                  rel="noreferrer">
                  <IconButton label={<Discord />} onClick={() => {}} />
                </a>
                <a
                  href="https://www.tiktok.com/@songbits.fans"
                  target="_blank"
                  rel="noreferrer">
                  <IconButton label={<TikTok />} onClick={() => {}} />
                </a>
                <a
                  href="https://www.instagram.com/songbits.fans/"
                  target="_blank"
                  rel="noreferrer">
                  <IconButton
                    label={<InstagramSmallWhite />}
                    onClick={() => {}}
                  />
                </a>
              </IconsContainer>
            </Column>
          </Row>
        </>
      ) : (
        <>
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
        </>
      )}
    </>
  );

  return (
    <PageContainer pageTitle='Support | SongBits'>
      <PageLayout
        title="Support"
        is2ColumnLayout
        loading={loading}
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                }}>
                {loading && viewport.width >= 767 ? (
                  <QuickLinksPlaceholder></QuickLinksPlaceholder>
                ) : (
                  <>
                    {viewport.width <= 767 ? (
                      <></>
                    ) : (
                      <QuickLinks links={quickLinks} isLogout />
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};
export default Support;

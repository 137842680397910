import * as React from 'react';

import { IconProps } from '../common.types';

const StockUpIcon = ({ width = 23, height = 23 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.067 0.5V2.45652H17.1341C16.9821 2.45646 16.8323 2.49182 16.6964 2.55981C16.5605 2.6278 16.4423 2.72654 16.3512 2.84821L13.7123 6.36957H11.2681C11.0089 6.36962 10.7602 6.47271 10.5769 6.65617L7.93036 9.30435H5.4022C5.20919 9.30436 5.0205 9.36154 4.85991 9.46867L2.17327 11.2609H1.49158C1.36203 11.259 1.23341 11.283 1.11318 11.3313C0.992964 11.3797 0.883544 11.4514 0.791283 11.5425C0.699022 11.6335 0.62576 11.7419 0.575755 11.8615C0.525751 11.9811 0.5 12.1095 0.5 12.2391C0.5 12.3688 0.525751 12.4971 0.575755 12.6167C0.62576 12.7363 0.699022 12.8448 0.791283 12.9358C0.883544 13.0268 0.992964 13.0986 1.11318 13.1469C1.23341 13.1953 1.36203 13.2192 1.49158 13.2174H2.46924C2.66225 13.2174 2.85093 13.1602 3.01153 13.0531L5.69817 11.2609H8.33517C8.59444 11.2608 8.84308 11.1577 9.0264 10.9743L11.6729 8.32609H14.2011C14.353 8.32615 14.5029 8.29079 14.6388 8.2228C14.7747 8.15481 14.8929 8.05606 14.984 7.9344L17.6229 4.41304H20.067V6.36957L23 3.43478L20.067 0.5ZM19.0894 8.32609C18.5497 8.32609 18.1117 8.76435 18.1117 9.30435V23H22.0223V9.30435C22.0223 8.76435 21.5844 8.32609 21.0447 8.32609H19.0894ZM13.2234 11.2609C12.6838 11.2609 12.2458 11.6991 12.2458 12.2391V23H16.1564V12.2391C16.1564 11.6991 15.7184 11.2609 15.1788 11.2609H13.2234ZM7.35751 14.1957C6.81785 14.1957 6.37986 14.6339 6.37986 15.1739V23H10.2905V15.1739C10.2905 14.6339 9.85249 14.1957 9.31282 14.1957H7.35751ZM1.49158 17.1304C0.951914 17.1304 0.513924 17.5687 0.513924 18.1087V23H4.42455V18.1087C4.42455 17.5687 3.98656 17.1304 3.44689 17.1304H1.49158Z"
      fill="white"
    />
  </svg>
);

export default StockUpIcon;

import * as React from 'react';

import { IconProps } from '../common.types';

const ProfileRectangleOutlinedIcon = ({
  width = 27,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 22V22.5H5.5H20.5H21V22C21 20.7019 20.6504 19.7278 20.0876 18.9785C19.5319 18.2387 18.7957 17.755 18.0855 17.3866C17.7303 17.2024 17.3724 17.0425 17.043 16.8957L17.0304 16.8901C16.7005 16.7431 16.4042 16.611 16.1411 16.4726C15.6307 16.2041 15.3607 15.9679 15.2531 15.707V14.6737C15.3077 14.6276 15.3616 14.5784 15.4129 14.5295C15.5618 14.3874 15.7291 14.2073 15.8894 14.0061C16.1311 13.7026 16.4008 13.2967 16.529 12.8702C16.8984 12.6622 17.1667 12.1915 17.2513 11.4802C17.299 11.0819 17.2003 10.7602 17.0479 10.5299C17.0663 10.4776 17.0855 10.42 17.1047 10.3575C17.1946 10.0641 17.2878 9.65089 17.2878 9.19001C17.2878 8.34573 17.1349 7.50024 16.7804 6.84628C16.4558 6.24747 15.9384 5.77864 15.2187 5.68662C15.1332 5.58912 15.0139 5.47358 14.8543 5.3602C14.486 5.09852 13.9268 4.86538 13.125 4.86538C11.7153 4.86538 10.6488 5.32759 9.94345 6.14761C9.24899 6.95495 8.96221 8.04234 8.96221 9.1909C8.96221 9.61915 9.0566 10.0328 9.144 10.3276C9.16525 10.3992 9.18652 10.4652 9.20662 10.5244C9.05213 10.7544 8.95045 11.0778 8.99867 11.481C9.08334 12.1933 9.35171 12.6635 9.72108 12.8712C9.8493 13.2976 10.119 13.7033 10.3607 14.0067C10.5209 14.2079 10.6882 14.3879 10.837 14.5299C10.8884 14.579 10.9424 14.6282 10.9969 14.6743V15.7073C10.89 15.9618 10.6131 16.1986 10.0772 16.4692C9.80085 16.6088 9.48875 16.7419 9.14209 16.8898L9.13522 16.8927C8.79133 17.0395 8.41807 17.1989 8.04763 17.3826C7.30784 17.7494 6.53943 18.2313 5.95834 18.9696C5.3686 19.7189 5 20.6962 5 22ZM1.8314 0.5H24.4186C25.1656 0.5 25.75 1.08759 25.75 1.78846V21.4615C25.75 22.1624 25.1656 22.75 24.4186 22.75H1.8314C1.08444 22.75 0.5 22.1624 0.5 21.4615V1.78846C0.5 1.08759 1.08444 0.5 1.8314 0.5Z"
      stroke="white"
    />
  </svg>
);

export default ProfileRectangleOutlinedIcon;

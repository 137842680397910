import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import BottomLine from 'common/components/BottomLine/BottomLine';
import { ILocationsData } from 'services/types';
import { useViewport } from 'use-viewport';

interface RegionSectionProps {
  name: string;
  countriesData: ILocationsData[];
  contryOnClick: (location: string) => void;
}

const RegionSection: React.FC<RegionSectionProps> = ({
  name,
  countriesData,
  contryOnClick,
}) => {
  const viewport = useViewport();

  const pairedData = React.useMemo(() => {
    const tempPairedData = [];

    let pair = [];
    for (const data of countriesData) {
      pair.push(data);

      if (pair.length == 2) {
        tempPairedData.push(pair);
        pair = [];
      }
    }

    if (pair.length > 0) {
      tempPairedData.push(pair);
    }
    return tempPairedData;
  }, [countriesData]);

  return (
    <Wrapper>
      <Typography
        text={name}
        fontColor={theme.colors.yellow}
        fontWeight="light"
      />

      {pairedData.map((data, index) => (
        <>
          <CountriesRow
            key={`${name}_${data[0].name}_${data[1]?.name}_${index}`}>
            <ButtonWrapper onClick={() => contryOnClick(data[0].name)}>
              <CountryContainer>
                <img src={data[0].flag} alt="" height={20} width={30} />
                &nbsp;&nbsp;&nbsp;
                <Typography text={data[0].name} fontWeight="light" />
                &nbsp;
                <Typography text={`(${data[0].user_count})`} fontSize="fz14" />
              </CountryContainer>
            </ButtonWrapper>

            {viewport.width < 576 && <BottomLine />}

            {data.length > 1 && (
              <ButtonWrapper onClick={() => contryOnClick(data[1].name)}>
                <CountryContainer>
                  <img src={data[1].flag} alt="" height={20} width={30} />
                  &nbsp;&nbsp;&nbsp;
                  <Typography text={data[1].name} fontWeight="light" />
                  &nbsp;
                  <ButtonWrapper>
                    <Typography
                      text={`(${data[1].user_count})`}
                      fontSize="fz10"
                    />
                  </ButtonWrapper>
                </CountryContainer>
              </ButtonWrapper>
            )}
          </CountriesRow>

          <BottomLine />
        </>
      ))}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  margin: 20px 0 15px 0;
`;

export const CountryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  margin: 20px 0;
`;

export const CountriesRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ButtonWrapper = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

export default RegionSection;

import * as React from 'react';

import { IconProps } from '../common.types';

const MetaMask = ({ width = 50, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m21.49 0-9.44 7.078 1.755-4.171L21.49 0Z" fill="#E17726" />
    <path
      d="m.532.008 7.664 2.9 1.667 4.225L.532.008ZM17.691 15.053l4.172.08-1.458 5.02-5.09-1.42 2.376-3.68ZM4.309 15.053l2.368 3.68-5.083 1.42-1.449-5.02 4.164-.08Z"
      fill="#E27625"
    />
    <path
      d="m9.636 6.057.17 5.58-5.101-.235 1.45-2.219.02-.02 3.461-3.106ZM12.31 5.995l3.516 3.167.018.022 1.451 2.218-5.1.235.116-5.642ZM6.826 15.07l2.786 2.199-3.236 1.583.45-3.783ZM15.174 15.069l.441 3.783-3.227-1.583 2.786-2.2Z"
      fill="#E27625"
    />
    <path
      d="m12.46 17.061 3.274 1.607-3.046 1.467.031-.97-.26-2.104ZM9.54 17.062l-.25 2.088.02.984-3.053-1.466 3.283-1.606Z"
      fill="#D5BFB2"
    />
    <path
      d="m8.594 12.366.856 1.822-2.913-.865 2.057-.957ZM13.405 12.366l2.068.957-2.923.865.855-1.822Z"
      fill="#233447"
    />
    <path
      d="m7.05 15.05-.472 3.923-2.524-3.837 2.995-.085ZM14.951 15.05l2.995.086-2.533 3.837-.462-3.922ZM17.369 11.18l-2.18 2.252-1.68-.779-.805 1.714-.528-2.947 5.193-.24ZM4.63 11.18l5.194.24-.528 2.947-.805-1.714-1.672.779-2.19-2.252Z"
      fill="#CC6228"
    />
    <path
      d="m4.483 10.718 2.466 2.536.086 2.504-2.552-5.04ZM17.52 10.714l-2.557 5.048.096-2.508 2.46-2.54ZM9.703 10.873l.1.633.244 1.577-.157 4.844-.746-3.891v-.04l.559-3.123ZM12.296 10.864l.56 3.132v.04l-.748 3.9-.03-.975-.116-3.907.334-2.19Z"
      fill="#E27525"
    />
    <path
      d="m15.279 13.154-.084 2.175-2.601 2.054-.526-.377.59-3.077 2.62-.775ZM6.73 13.154l2.613.775.59 3.077-.527.377-2.601-2.054-.075-2.175Z"
      fill="#F5841F"
    />
    <path
      d="m5.76 18.188 3.328 1.598-.014-.682.278-.248h3.295l.288.247-.021.682 3.307-1.593-1.609 1.348-1.946 1.354h-3.34l-1.945-1.36-1.622-1.346Z"
      fill="#C0AC9D"
    />
    <path
      d="m12.22 16.849.471.337.276 2.23-.4-.342H9.434l-.391.348.267-2.236.47-.337h2.442Z"
      fill="#161616"
    />
    <path
      d="M20.867.196 22 3.64l-.708 3.483.504.394-.682.527.513.4-.679.627.417.306-1.106 1.308-4.535-1.338-.04-.021-3.267-2.793 8.45-6.338ZM1.133.196l8.45 6.338-3.268 2.793-.04.021-4.534 1.338L.635 9.378l.416-.306-.678-.626.512-.4-.693-.53.523-.394L0 3.641 1.133.196Z"
      fill="#763E1A"
    />
    <path
      d="m15.502 9.056 4.805 1.418 1.562 4.875H17.75l-2.838.036 2.064-4.076-1.474-2.253ZM6.498 9.056l-1.474 2.253 2.064 4.076-2.837-.036H.141l1.551-4.876 4.806-1.417ZM14.04 2.884l-1.343 3.678-.285 4.969-.11 1.557-.008 3.979H9.706l-.008-3.971-.11-1.566-.285-4.968-1.344-3.679h6.082Z"
      fill="#F5841F"
    />
  </svg>
);

export default MetaMask;

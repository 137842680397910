import React from 'react';
import styled from 'styled-components';

import { CommonListTypes } from 'common/common.types';

import AccordionItem from './components/AccordionItem';

type AccordionProps = {
  data: CommonListTypes[];
};

const Accordion = ({ data }: AccordionProps) => {
  return (
    <AccordionContainer>
      {data.map(({ id, title, description }: CommonListTypes) => (
        <div key={`Key for accordion single item is - ${id}`}>
          <AccordionItem title={title} description={description} />
        </div>
      ))}
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export default Accordion;

import React, { ChangeEvent, KeyboardEvent, memo } from 'react';
import * as R from 'ramda';
import {
  ReactGoogleAutocompleteProps,
  usePlacesWidget,
} from 'react-google-autocomplete';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { TextInputTypes, TextInputFormatTypes } from 'common/common.types';

import {
  BottomLine,
  Container,
  ErrorContainer,
  Input,
  Label,
  ResetPasswordButton,
  ResetPasswordContainer,
  ResetPasswordText,
} from './TextInput.styled';

export type TextInputProps = {
  id?: string;
  error?: string;
  width?: number;
  height?: number;
  isInvalidPassword?: boolean;
  onResetClick?: () => void;
  className?: string;
  placeholder?: string;
  withBorder?: boolean;
  borderColor?: string;
  value: string | number;
  withBottomLine?: boolean;
  label?: string | JSX.Element;
  type?: keyof typeof TextInputTypes;
  fontSize?: number;
  tabIndex?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  small?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  googleAutoComplete?: ReactGoogleAutocompleteProps;
  autoFocus?: boolean;
  alignText?: string;
  borderRadius?: string;
  maxLength?: number;
  errorPoistionLeft?: string;
  errorPoistionBottom?: string;
  inputName?: string;
  inputMode?: keyof typeof TextInputFormatTypes;
};

const TextInput = ({
  type,
  error,
  errorPoistionLeft,
  errorPoistionBottom,
  value,
  className,
  placeholder,
  label,
  width,
  height,
  tabIndex,
  isInvalidPassword = false,
  onResetClick,
  withBottomLine,
  onKeyDown,
  onKeyUp,
  withBorder,
  borderColor,
  onChange,
  onInput,
  onFocus,
  googleAutoComplete,
  small = false,
  autoComplete = 'off',
  readOnly = false,
  autoFocus = false,
  borderRadius = '10px',
  alignText = '',
  maxLength = undefined,
  inputName,
  inputMode = 'text',
  id = 'text-input'
}: TextInputProps) => {
  const { ref } = usePlacesWidget<HTMLInputElement>(
    googleAutoComplete
      ? googleAutoComplete
      : {
          apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        },
  );

  return (
    <Container
      width={width}
      height={height}
      className={className}
      withBorder={withBorder}
      borderRadius={borderRadius}
      borderColor={borderColor}>
      <Input
        ref={googleAutoComplete ? ref : null}
        type={type}
        value={value}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onInput={onInput}
        onFocus={onFocus}
        small={small}
        autoComplete={autoComplete}
        readOnly={readOnly}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        alignText={alignText}
        maxLength={maxLength}
        name={inputName}
        inputMode={inputMode}
      />
      {!R.isNil(label) && <Label htmlFor={id}>{label}</Label>}
      {withBottomLine && <BottomLine />}
      {!R.isNil(error) && (
        <ErrorContainer
          withBorder={withBorder}
          positionLeft={errorPoistionLeft}
          positionBottom={errorPoistionBottom}>
          <Typography
            className={`validation-error-${inputName?.replace(' ', '')}`}
            text={error}
            fontSize="fz12"
            fontColor={theme.colors.yellow}
          />
        </ErrorContainer>
      )}
      {autoComplete === 'cc-number' &&
        value &&
        (value.toString().startsWith('1') ||
          value.toString().startsWith('2') ||
          value.toString().startsWith('3') ||
          value.toString().startsWith('6') ||
          value.toString().startsWith('7') ||
          value.toString().startsWith('8') ||
          value.toString().startsWith('9') ||
          value.toString().startsWith('0')) &&
        !error && (
          <ErrorContainer
            withBorder={withBorder}
            positionLeft={errorPoistionLeft}
            positionBottom={errorPoistionBottom}>
            <Typography
              className={`validation-error-${inputName?.replace(' ', '')}`}
              text={'Only Visa, Mastercard & Amercian Express are supported.'}
              fontSize="fz12"
              fontColor={theme.colors.yellow}
            />
          </ErrorContainer>
        )}
      {isInvalidPassword && onResetClick && (
        <ErrorContainer withBorder={withBorder}>
          <Typography
            text={'Incorrect current password.'}
            fontSize="fz12"
            fontColor={theme.colors.yellow}
          />
          <ResetPasswordContainer>
            <ResetPasswordButton
              label={
                <ResetPasswordText
                  text="Reset Password"
                  fontColor={theme.colors.yellow}
                  fontSize="fz12"
                />
              }
              onClick={onResetClick}
            />
          </ResetPasswordContainer>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default memo(TextInput);

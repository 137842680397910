import React, { FC } from 'react';
//import { useLocation } from 'react-router-dom';


const ThreeDSecureFail: FC = () => {
  //const location = useLocation();

  //const paymentId = location.search.replace("?paymentId=", "");

  (window as any).top.finalise3DS();


  return (
    <div>Please wait...</div>
  );
};



export default ThreeDSecureFail;

import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'store/store';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { LogoButton } from 'common/layout/Header/Header.styled';

import ImportOutlinedIcon from 'common/icons/ImportOutlined.icon';
import ShareOutlinedIcon from 'common/icons/ShareOutlined.icon';
import LogoSmall from 'common/icons/LogoSmall.icon';

import DefaultButton from 'common/components/DefautButton/DefaultButton';
import CloseCircleIcon from 'common/icons/CloseCircle.icon';
import ChainRectangleOutlinedIcon from 'common/icons/MyCollection/ChainRectangleOutlined.icon';

interface NFTModalProps {
  imageLink: string;
  show: boolean;
  onClose: () => void;
  onChainClick: () => void;
  onImportClick: () => void;
  onShareClick: () => void;
}

const NFTFullViewModal: FC<NFTModalProps> = ({
  show,
  imageLink,
  onClose,
  onChainClick,
  onImportClick,
  onShareClick,
}) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useStoreState(
    (state) => state.authentication,
  );
  const isArtist = useStoreState(
    (state) => state.authentication.auth.tokenData?.is_artist || false
  );

  if (!show) return null;

  return createPortal(
    <Wrapper className="image-nft-full-screen-modal">
      <Background>
        <TransformWrapper
          doubleClick={{
            disabled: true,
          }}
          wheel={{
            disabled: true,
          }}
          disablePadding
          centerOnInit>
          <TransformComponent
            wrapperStyle={{ maxWidth: '100%', maxHeight: '100%' }}>
            <NFTImage src={imageLink} alt="" />
          </TransformComponent>
        </TransformWrapper>
      </Background>

      <BackgroundShadow />

      <HeaderContainer>
        <LogoCustomButton
          label={<LogoSmall />}
          onClick={() => {
            if (isAuthenticated && isArtist) {
              navigate('/artist/dashboard');
            } else {
              navigate('/');
            }
          }}
        />

        <DefaultButton
          className="image-nft-fullscreen-close-button"
          onClick={onClose}>
          <CloseCircleIcon />
        </DefaultButton>
      </HeaderContainer>

      <ActionsContainer>
        <DefaultButton
          className="image-nft-fullscreen-share-button"
          onClick={onShareClick}>
          <ShareOutlinedIcon />
        </DefaultButton>

        <DefaultButton
          className="image-nft-fullscreen-download-button"
          onClick={onImportClick}>
          <ImportOutlinedIcon />
        </DefaultButton>

        <DefaultButton
          className="image-nft-fullscreen-options-button"
          onClick={onChainClick}>
          <ChainRectangleOutlinedIcon width={27} height={27} />
        </DefaultButton>
      </ActionsContainer>
    </Wrapper>,
    document.body,
  );
};

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 10;
`;

const Background = styled.div`
  height: 100vh;
  width: 100vw;

  position: absolute;
`;

const NFTImage = styled.img`
  height: 100vh;
  max-height: 100vh;
  object-fit: cover;
`;

const BackgroundShadow = styled.div`
  width: 100%;
  height: 100px;

  position: absolute;
  bottom: 0;
  left: 0;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.73) 67.71%,
    #000 100%
  );
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  position: relative;
  z-index: 1;

  padding: 45px 32px;
`;

const LogoCustomButton = styled(LogoButton)`
  position: absolute;
  top: 45px;
  left: 50%;

  transform: translateX(-50%);
`;

const ActionsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 40px;

  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export default NFTFullViewModal;

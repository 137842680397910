import * as React from 'react';

import { IconProps } from '../common.types';

const ArrowCircleYellowIcon = ({ width = 25, height = 25 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-5.46392e-07 12.5C-2.44893e-07 19.3975 5.6025 25 12.5 25C19.3975 25 25 19.3975 25 12.5C25 5.6025 19.3975 2.96681e-06 12.5 3.2683e-06C5.6025 3.5698e-06 -8.47892e-07 5.6025 -5.46392e-07 12.5ZM1.08696 12.5C1.08696 6.19056 6.19056 1.08696 12.5 1.08696C18.8094 1.08696 23.913 6.19056 23.913 12.5C23.913 18.8094 18.8094 23.913 12.5 23.913C6.19056 23.913 1.08696 18.8094 1.08696 12.5ZM5.4178 12.551C5.42204 12.5743 5.42841 12.5977 5.43478 12.6189C5.4921 12.8715 5.71926 13.0498 5.97826 13.0435L17.697 13.0435L14.2833 16.4572C14.1474 16.5888 14.0901 16.782 14.1304 16.9667C14.1665 17.1705 14.3151 17.334 14.5126 17.3913C14.7121 17.4486 14.9244 17.3892 15.0645 17.2385L19.3274 12.9586C19.3444 12.9479 19.3614 12.9373 19.3784 12.9246L19.4124 12.8906C19.4251 12.88 19.4357 12.8694 19.4463 12.8567C19.4463 12.8503 19.4463 12.846 19.4463 12.8397C19.4803 12.8036 19.5079 12.7632 19.5312 12.7208C19.5312 12.7144 19.5312 12.7102 19.5312 12.7038C19.5376 12.6932 19.544 12.6805 19.5482 12.6698C19.5482 12.6635 19.5482 12.6592 19.5482 12.6529C19.5546 12.6359 19.561 12.6189 19.5652 12.6019C19.5737 12.5446 19.5737 12.4894 19.5652 12.4321C19.5652 12.4215 19.5652 12.4087 19.5652 12.3981C19.5546 12.3514 19.5376 12.3047 19.5143 12.2622C19.4782 12.19 19.4251 12.1264 19.3614 12.0754L15.0645 7.76155C14.848 7.54501 14.4998 7.54501 14.2833 7.76155C14.0667 7.97809 14.0667 8.32626 14.2833 8.5428L17.697 11.9565L5.97826 11.9565C5.82116 11.9502 5.67043 12.0138 5.56428 12.1264C5.45601 12.241 5.40294 12.396 5.4178 12.551Z"
      fill="#FFD600"
    />
  </svg>
);

export default ArrowCircleYellowIcon;

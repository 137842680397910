import * as React from 'react';

import { IconProps } from '../common.types';

const QuickLink = ({ width = 19, height = 15, fill = '#FFD600' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9235 7.87316C19.7255 6.07116 19.7255 3.15287 17.9235 1.35087C16.3288 -0.243823 13.8156 -0.451133 11.9817 0.859704L11.9307 0.894787C11.4714 1.22329 11.3662 1.86117 11.6947 2.31725C12.0232 2.77333 12.6611 2.88177 13.1171 2.55327L13.1682 2.51818C14.192 1.78781 15.5921 1.90263 16.4787 2.79247C17.4834 3.79713 17.4834 5.42371 16.4787 6.42837L12.9003 10.0132C11.8956 11.0179 10.269 11.0179 9.26436 10.0132C8.37452 9.1234 8.2597 7.72326 8.99007 6.70265L9.02516 6.65162C9.35366 6.19235 9.24522 5.55448 8.78914 5.22916C8.33306 4.90384 7.69199 5.00909 7.36668 5.46517L7.33159 5.5162C6.01757 7.34691 6.22488 9.86014 7.81957 11.4548C9.62157 13.2568 12.5399 13.2568 14.3419 11.4548L17.9235 7.87316ZM1.3515 7.12684C-0.4505 8.92884 -0.4505 11.8471 1.3515 13.6491C2.94619 15.2438 5.45943 15.4511 7.29332 14.1403L7.34435 14.1052C7.80362 13.7767 7.90887 13.1388 7.58037 12.6827C7.25186 12.2267 6.61398 12.1182 6.1579 12.4467L6.10687 12.4818C5.08308 13.2122 3.68294 13.0974 2.79629 12.2075C1.79164 11.1997 1.79164 9.5731 2.79629 8.56844L6.37478 4.98677C7.37944 3.98211 9.00602 3.98211 10.0107 4.98677C10.9005 5.8766 11.0153 7.27674 10.285 8.30053L10.2499 8.35157C9.92137 8.81084 10.0298 9.44871 10.4859 9.77403C10.942 10.0993 11.583 9.9941 11.9084 9.53802L11.9434 9.48698C13.2575 7.65309 13.0502 5.13986 11.4555 3.54516C9.65346 1.74316 6.73518 1.74316 4.93318 3.54516L1.3515 7.12684Z"
      fill={fill}
    />
  </svg>
);

export default QuickLink;

import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import restService from 'services/rest.service';
import { useStoreActions } from 'store/store';

interface ModalProps {
  addressId: string;
  onClose: () => void;
  onDelete: () => void;
}

const RemoveCryptoModal: FC<ModalProps> = ({ addressId, onClose, onDelete }) => {
  
  const viewport = useViewport();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  function deleteCryptoWallet() {
    restService.deleteCryptoWallet(addressId).then((res: any) => {
      res
      onDelete();
    }).catch((error: any) => {
      setGlobalBanner({
        title: 'Failed to delete crypto wallet: ',
        text: error.message,
      });
    });
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={onClose}>X</CloseButton>
      <BoldText
        text="confirm"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <RegularText
        fontSize={viewport.width >= 576 ? 'fz18' : 'fz14'}
        text="Are you sure you want to remove the crypto wallet from your account?"
      />
      <Spacer height={viewport.width >= 576 ? 30 : 10} />

      <ButtonRow>
        <SubmitButton label="cancel" textColor={theme.colors.white} onClick={onClose}/>
        <SubmitButton label="confirm" textColor={theme.colors.yellow} onClick={deleteCryptoWallet} />
      </ButtonRow>

    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const RegularText = styled(Typography)`
  letter-spacing: -0.03em;
  line-height: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: -4px;
`;

const SubmitButton = styled(Button)<{ textColor: string; }>`
  padding-left: 59px;
  padding-right: 59px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 15px;

  font-family: HKGrotesk-Black;
  font-size: 16px;
  color: ${(props) => props.textColor};

  border: 3px solid white;
  border-radius: 50px;

  @media (max-width: 575.98px) { 
    padding-left: 35px;
    padding-right: 35px;
   }

`;

export default RemoveCryptoModal;

import React, { FC, memo, useMemo } from 'react';

import UsersTableLayout from '../../ArtistAnalytics/components/layout/UsersTableLayout/UsersTableLayout';
import Table from 'common/components/Table/Table';

import { IUserByValue } from 'services/types';
import ProfileIcon from 'common/icons/Profile.icon';

interface UsersByValueProps {
  data: IUserByValue[];
}

const UsersByValue: FC<UsersByValueProps> = ({ data }) => {
  const formatUsernameAndIcon = (
    username: string,
    slug: string,
    avatar: string | null,
  ) => {
    return (
      <div
        style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}
        onClick={() => {
          window.open(`${window.location.origin}/${slug}`, '_blank');
        }}>
        {avatar ? (
          <img
            src={avatar}
            width={25}
            height={25}
            style={{ borderRadius: '50%' }}
          />
        ) : (
          <ProfileIcon width={25} />
        )}
        <span style={{ cursor: 'pointer' }}>{username}</span>
      </div>
    );
  };

  const usersByValue = useMemo(
    () =>
      data
        .sort(
          (a, b) =>
            Number(b.total_bits_purchased.replaceAll(',', '')) -
            Number(a.total_bits_purchased.replaceAll(',', '')),
        )
        .slice(0, 3)
        .map((user) => [
          formatUsernameAndIcon(user.username, user.slug, user.avatar),
          user.country_name,
          user.total_bits_purchased,
        ]),
    [data],
  );

  return (
    <UsersTableLayout title="top users by value">
      <Table
        columns={['users', 'location', 'total amount']}
        data={usersByValue}
        style={{
          tableLayout: 'fixed',
        }}
      />
    </UsersTableLayout>
  );
};

export default memo(UsersByValue);

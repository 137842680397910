import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import styled from 'styled-components';

const HomeRouter = () => {
  return (
    <LibraryRouteContainer>
      <Switch>
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Switch>
    </LibraryRouteContainer>
  );
};

const LibraryRouteContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
`;

export default HomeRouter;

import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

export const CreateButton = styled(Button)`
  padding: 15px;
  margin: 25px 0;

  color: ${theme.colors.black};
  background-color: ${theme.colors.yellow};
  width: 100%;

  &:hover {
    background-color: ${theme.colors.yellow};
  }
`;

export const LoginButton = styled(Button)`
  &:hover {
    background-color: ${theme.colors.black};
  }
`;

export const CreateButtonContent = styled(Typography)`
  font-size: 16px;

  @media (min-width: 992px) {
    font-size: 18px;
  }
`;

export const LoginNow = styled(Typography)`

 text-decoration: underline !important;

  &:hover {
    text-decoration: none;
  }
`

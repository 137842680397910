export const OrderDirectionsList_dropdown = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

export const OrdersList_dropdown = [
  {
    label: 'Location',
    value: 'location',
  },
  {
    label: 'Registration Date',
    value: 'registration_date',
  },
  {
    label: 'Last Activity Date',
    value: 'last_activity_date',
  },
  /*{
    label: 'Total bits purchased',
    value: 'total_bits_purchased',
  },*/
  {
    label: 'Total Purchase Amount',
    value: 'total_purchase_costs',
  },
];

export const LimitsList = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '250',
    value: 250,
  },
  {
    label: '500',
    value: 500,
  },
];

export const FiltersList_Dropdown = [
  /*{
    label: 'Continent',
    value: 'continent',
  },*/
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Last Activity',
    value: 'last_activity',
  },
  {
    label: 'Total Purchase Amount',
    value: 'total_purchase_amount',
  },
  /*{
    label: 'Opt In',
    value: 'opt_in',
  },
  {
    label: 'Opt Out',
    value: 'opt_out',
  },
  {
    label: 'Favorite',
    value: 'favorite',
  },*/
];

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions } from 'store/store';
import { useNavigate } from 'react-router-dom';

import Typography from 'common/components/Typography/Typography';

import Banner from '../assets/free-bits_bg.jpg';
import PageContainer from 'common/layout/PageContainer';
import { footerHeight, footerMobileHeight, md, sm, xlg } from 'utils/constants';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import Button from 'common/components/Button/Button';
import { ImageContainer } from './styled/HomePage.styled';

const FreeBits = () => {
  const navigate = useNavigate();

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  useEffect(() => {
    setBreadcrumbs([
      { value: 'Home', label: 'Home' },
      { value: 'giveaway', label: 'Giveaway' },
      { value: 'sxsw', label: 'SXSW' },
    ]);

    return () => {
      setBreadcrumbs([]);
    };
  }, []);

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        <Image src={Banner} />
        <ImageGradient banner={Banner} />
      </ImageContainer>
      <Wrapper>
        <ContentContainer>
          <Title
            text={'free bits giveaway!'}
            fontWeight="bold"
            letterSpacing="-0.035em"
          />
          <BlackBg>
            <SubTitle
              text={'claim now!'}
              fontWeight="light"
              letterSpacing="-0.025em"
            />
          </BlackBg>

          <Content>
            <Typography
              text="Anyone say free SongBits?"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
              fontSize="fz24"
              letterSpacing="-0.045em"
            />

            <Spacer height={30} />

            <ContentText
              text="SongBits is the music platform redefining the artist-fan relationship forever. If you’re at SXSW 2023, this is your golden opportunity to claim free SongBits, and start earning royalties from one of our founding artists."
              letterSpacing="-0.03em"
            />
            <Spacer height={25} />
            <ContentText
              fontWeight="bold"
              text={
                <>
                  <span style={{ color: theme.colors.yellow }}>How? </span>
                  Claim your free SongBits below, and they’ll be credited to
                  your account on creation.
                </>
              }
              letterSpacing="-0.05em"
            />

            <Spacer height={50} />

            <ClaimButton
              label={
                <Row>
                  <ButtonContent text="Give me free music " fontWeight="bold" />
                  <ButtonContent
                    text="royalties for life"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </Row>
              }
              onClick={() => {
                navigate('/giveaway/sxsw/waitlist');
              }}
            />
          </Content>
        </ContentContainer>
      </Wrapper>
    </PageContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  padding: 130px 20px 0 30px;

  z-index: 5;

  @media (min-width: ${sm}px) {
    padding: 130px 20px 0 60px;
  }

  @media (min-width: ${md}px) {
    align-items: center;
    margin-left: -160px;
  }

  @media (min-width: ${xlg}px) {
    margin: auto;
  }
`;

const ContentContainer = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Typography)`
  font-size: 80px;
  line-height: 60px;
  max-width: 324px;

  @media (min-width: ${sm}px) {
    font-size: 100px;
    line-height: 75px;
    max-width: 405px;
  }

  @media (min-width: ${md}px) {
    font-size: 100px;
    line-height: 75px;
    max-width: initial;
  }
`;

const SubTitle = styled(Typography)`
  font-size: 40px;
  line-height: 17px;

  @media (min-width: ${sm}px) {
    font-size: 50px;
    line-height: 25px;
  }
`;

const BlackBg = styled.div`
  width: fit-content;

  background-color: black;

  padding: 12px 10px 13px 10px;
  margin-left: 40px;

  @media (min-width: ${sm}px) {
    margin-left: 50px;
    padding: 5px 20px 15px 10px;
  }
`;

const Content = styled.div`
  width: 333px;

  margin: 24px 0 0 0;
  padding-left: 5px;

  @media (min-width: ${sm}px) {
    width: 403px;
  }

  @media (min-width: ${md}px) {
    width: 403px;
  }
`;

const ContentText = styled(Typography)`
  font-size: 18px;
  line-height: 22px;

  @media (min-width: ${sm}px) {
    font-size: 20px;
  }
`;

const ClaimButton = styled(Button)`
  background-color: black;

  padding: 10px 30px;

  border: 3px solid ${theme.colors.yellow};
  border-radius: 50px;
`;

const ButtonContent = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.025em;
`;

const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: ${sm}px) {
    background: linear-gradient(
        90deg,
        #000000,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      ),
      ${(props) => props.banner && `url(${props.banner})`};
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }

  @media (min-width: ${md}px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
    width: 700px;
  }
`;

const Image = styled.img`
  left: 0%;
  position: relative;
  height: 100%;

  @media (min-width: ${sm}px) {
    height: 100%;
    left: 0;
  }
`;

export default FreeBits;

import { action } from 'easy-peasy';

import { ModalModel } from './modalModel.types';

export const modals: ModalModel = {
  currentZIndex: 2,
  openModals: [],
  
  setCurrentZIndex: action((state, payload) => {
    state.currentZIndex = payload;
  }),

  addOpenModal: action((state, payload) => {
    state.openModals.push(payload);
    state.currentZIndex++;
  }),

  removeOpenModal: action((state, payload) => {
    const index = state.openModals.indexOf(payload);
    state.openModals.splice(index, 1);
    state.currentZIndex--;
  }),

};

import { useFormik } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Yup from 'yup-extended';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

import Wallet from 'common/icons/Wallet.icon';

import TextInput from 'common/components/TextInput/TextInput';
import { escToClose } from 'utils/functions';

const VALIDATION_SCHEMA = Yup.object().shape({
  address: Yup.string()
    .isWalletAddress('Wallet address appears to be incorrect. Please re-check')
    .required('Wallet Address is required'),
});

interface GiftBitsModalProps {
  onChange: () => void;
  onExport: () => void;
}

const ConfirmBitTransferModal: FC<GiftBitsModalProps> = ({ onChange, onExport }) => {
  const viewport = useViewport();

  const initialValues = useMemo<{
    address: string;
  }>(
    () => ({
      address: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  function submitHandler() {
    onExport();
  }

  function handleClose() {
    onChange();
  }

  useEffect(escToClose(handleClose), []);

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="confirmation"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      <InfoContainer>
        <CustomText
          paddingRight={50}
          text="Entering the incorrect wallet address could result in the bits being unrecoverable."
        />

        <InputContainer>
          <Typography text="Confirm wallet address" />
          <Icon>
            <Wallet />
          </Icon>
          <InputWithIcon
            height={viewport.width >= 576 ? 63 : 50}
            type="email"
            withBottomLine
            value={values.address}
            placeholder="wallet address"
            onChange={handleChange('address')}
            small={true}
            error={
              Boolean(errors.address && touched.address)
                ? errors.address
                : undefined
            }
          />
        </InputContainer>

        <SubInfo
          fontSize="fz12"
          text="By transferring the bits to an external wallet you agree to SongBits Terms and Conditions of transfer"
        />

        <SubmitButton
          width={145}
          borderRadius={50}
          borderColor={theme.colors.white}
          label={
            <SubmitButtonContent>
              <BoldText
                text="confirm"
                fontSize="fz16"
                fontWeight="bold"
                fontColor={theme.colors.white}
              />
            </SubmitButtonContent>
          }
          onClick={handleSubmit}
        />
      </InfoContainer>
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 35px;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const SubInfo = styled(Typography)`
  line-height: 24px;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const SubmitButton = styled(Button)`
  padding: 8px 43px;
  margin-left: 60px;

  @media (min-width: 576px) {
    margin-left: 0;
  }
`;

const SubmitButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const CustomText = styled(Typography)<{ paddingRight?: number }>`
  line-height: 24px;
  width: 392px;

  &:before {
    color: red;
    content: 'WARNING! ';
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 7px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: 18px;
  left: 0px;

  @media (min-width: 576px) {
    bottom: 22px;
  }
`;

const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
    margin-top: -7px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

export default ConfirmBitTransferModal;

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'store/store';

import Typography from 'common/components/Typography/Typography';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import Email from 'common/icons/Email.icon';
import theme from 'theme/theme';

import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';

import Slider1 from '../assets/confirm-email.jpg';

import {
  FirstButton,
  HeadContainer,
  HeadContent,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContent,
  TextContentConfirm,
  BackButton,
} from './styled/ChangeEmail.styled';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
});

const ChangeEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const changeEmail = useStoreActions((state) => state.authentication.changeEmail);

  const id = location.state ? (location.state as any).id : '';

  const initialValues = {
    email: '',
  };

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  /*const [changeEmail] = useMutation<
    CHANGE_EMAIL_MUTATION,
    CHANGE_EMAIL_VARIABLES
  >(CHANGE_EMAIL, {
    variables: {
      userId: id,
      email: values.email,
    },
  });*/

  async function submitHandler() {
    const { error } = await changeEmail({
      userId: id,
      newEmail: values.email,
    });
    if (error) {
      errors.email = error.message;
    } else {
      errors.email = 'Sent!';
    }
    values.email = '';
  }

  return (
    <PageContainer pageTitle='Change Email | SongBits'>
      <ImageContainer>
        <Image src={Slider1} />
        <ImageGradient banner={Slider1} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <HeadContent
              text="almost"
              fontSize="fz100"
              fontWeight="bold"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: 'flex',
              }}>
              <HeadContent
                text="there"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="!"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentConfirm
            text="Change Email Address"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
          />
          <InputContainer>
            <Icon>
              <Email />
            </Icon>
            <InputWithIcon
              value={values.email}
              height={80}
              type="email"
              onChange={handleChange('email')}
              placeholder="Your Email"
              error={
                Boolean(errors.email && touched.email)
                  ? errors.email
                  : undefined
              }
            />
          </InputContainer>

          <FirstButton
            height={45}
            width={172}
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <Typography text="modify" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <Typography
                  text="email"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={handleSubmit}
          />

          <BackButton
            isTextBold={false}
            label={<TextContent withCursorPointer text="Go Back" />}
            onClick={() => navigate(-1)}
          />
        </HeadingSection>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(ChangeEmail);

import React, { memo } from 'react';

import { IconProps } from '../common.types';

const LogoFooter = ({ width = 43, height = 22 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 43 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5003 18.4946C21.8833 18.4946 22.1938 18.0803 22.1938 17.5691L22.1938 0.925507C22.1938 0.414366 21.8833 3.82023e-06 21.5003 3.82705e-06C21.1173 3.83387e-06 20.8068 0.414366 20.8068 0.925507L20.8068 17.5691C20.8068 18.0803 21.1173 18.4946 21.5003 18.4946Z"
      fill="#FFD600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9678 18.4946C25.4786 18.4946 25.8926 18.0806 25.8926 17.5699L25.8926 6.47312C25.8926 5.96241 25.4786 5.54839 24.9678 5.54839C24.4571 5.54839 24.0431 5.96241 24.0431 6.47312L24.0431 17.5699C24.0431 18.0806 24.4571 18.4946 24.9678 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0326 18.4946C17.5219 18.4946 17.1079 18.0803 17.1079 17.5691L17.1079 0.925507C17.1079 0.414366 17.5219 3.82207e-06 18.0326 3.83117e-06C18.5434 3.84026e-06 18.9574 0.414366 18.9574 0.925507L18.9574 17.5691C18.9574 18.0803 18.5434 18.4946 18.0326 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6671 18.4946C29.1778 18.4946 29.5918 18.0793 29.5918 17.567L29.5918 4.62656C29.5918 4.11424 29.1778 3.69893 28.6671 3.69893C28.1564 3.69893 27.7423 4.11424 27.7423 4.62656L27.7423 17.567C27.7423 18.0793 28.1564 18.4946 28.6671 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3334 18.4946C13.8227 18.4946 13.4087 18.0599 13.4087 17.5237L13.4087 9.75592C13.4087 9.21967 13.8227 8.78495 14.3334 8.78495C14.8441 8.78495 15.2582 9.21967 15.2582 9.75592L15.2582 17.5237C15.2582 18.0599 14.8441 18.4946 14.3334 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3658 18.4946C32.8765 18.4946 33.2905 18.0806 33.2905 17.5699L33.2905 11.0968C33.2905 10.5861 32.8765 10.172 32.3658 10.172C31.8551 10.172 31.4411 10.5861 31.4411 11.0968L31.4411 17.5699C31.4411 18.0806 31.8551 18.4946 32.3658 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6347 18.4946C10.124 18.4946 9.70996 18.0786 9.70996 17.5655L9.70996 6.47755C9.70996 5.96439 10.124 5.54839 10.6347 5.54839C11.1454 5.54839 11.5594 5.96439 11.5594 6.47755L11.5594 17.5655C11.5594 18.0786 11.1454 18.4946 10.6347 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.0645 18.4946C36.5752 18.4946 36.9893 18.0634 36.9893 17.5314L36.9893 7.89875C36.9893 7.36676 36.5752 6.93549 36.0645 6.93549C35.5538 6.93549 35.1398 7.36676 35.1398 7.89875L35.1398 17.5314C35.1398 18.0634 35.5538 18.4946 36.0645 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93547 18.4946C6.42476 18.4946 6.01074 18.0806 6.01074 17.5699L6.01074 15.7204C6.01074 15.2097 6.42476 14.7957 6.93547 14.7957C7.44619 14.7957 7.8602 15.2097 7.8602 15.7204L7.8602 17.5699C7.8602 18.0806 7.44619 18.4946 6.93547 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.7637 18.4946C40.2745 18.4946 40.6885 18.1151 40.6885 17.647L40.6885 14.2563C40.6885 13.7881 40.2745 13.4086 39.7637 13.4086C39.253 13.4086 38.839 13.7881 38.839 14.2563L38.839 17.647C38.839 18.1151 39.253 18.4946 39.7637 18.4946Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.23674 18.4946C2.72603 18.4946 2.31201 18.0806 2.31201 17.5699L2.31201 11.0968C2.31201 10.5861 2.72603 10.172 3.23674 10.172C3.74746 10.172 4.16147 10.5861 4.16147 11.0968L4.16147 17.5699C4.16147 18.0806 3.74746 18.4946 3.23674 18.4946Z"
      fill="white"
    />
    <path
      d="M2.66127 28.176C4.01092 28.176 5.05642 27.2984 5.05642 25.9963C5.05642 25.0621 4.6002 24.5903 3.17452 24.043C2.22407 23.675 1.96744 23.4485 1.96744 23.1277C1.96744 22.8163 2.25258 22.5993 2.59474 22.5993C2.97493 22.5993 3.29808 22.8446 3.49767 23.3259L4.73327 22.5615C4.30556 21.7123 3.56421 21.2877 2.64227 21.2877C1.34014 21.2877 0.475228 22.0614 0.475228 23.156C0.475228 24.1657 0.988473 24.6846 2.40465 25.2508C3.26957 25.5999 3.57371 25.8264 3.57371 26.1284C3.57371 26.6285 3.17452 26.8644 2.68979 26.8644C2.02447 26.8644 1.54924 26.5058 1.48271 25.732L0 26.1C0.199596 27.355 1.28311 28.176 2.66127 28.176Z"
      fill="white"
    />
    <path
      d="M8.29695 28.2043C10.2169 28.2043 11.5475 26.8266 11.5475 24.7507C11.5475 22.6653 10.2359 21.2877 8.29695 21.2877C6.37703 21.2877 5.0559 22.6748 5.0559 24.7507C5.0559 26.8172 6.37703 28.2043 8.29695 28.2043ZM8.29695 26.7889C7.23244 26.7889 6.54812 26.0246 6.54812 24.7507C6.54812 23.4674 7.23244 22.7031 8.29695 22.7031C9.37097 22.7031 10.0458 23.4863 10.0458 24.7507C10.0458 26.0057 9.37097 26.7889 8.29695 26.7889Z"
      fill="white"
    />
    <path
      d="M16.1535 21.4575V25.4678L13.6538 21.4575H12.1331V28.0344H13.6253V24.0052L16.1155 28.0344H17.6457V21.4575H16.1535Z"
      fill="white"
    />
    <path
      d="M21.6821 24.3827V25.7037H23.0983V25.7981C23.0983 26.3171 22.6136 26.7889 21.6251 26.7889C20.513 26.7889 19.7147 26.185 19.7147 24.7601C19.7147 23.4013 20.4465 22.6842 21.53 22.6842C22.2904 22.6842 22.9462 23.0522 23.2123 23.8071L24.5905 23.2692C24.0773 21.9576 22.8702 21.2688 21.549 21.2688C19.6006 21.2688 18.2225 22.7597 18.2225 24.8073C18.2225 26.77 19.4866 28.2043 21.5015 28.2043C22.3189 28.2043 22.9937 27.9684 23.4024 27.4022L23.5735 28.0344H24.6V24.3827H21.6821Z"
      fill="white"
    />
    <path
      d="M25.4391 28.0344H28.2619C29.6116 28.0344 30.448 27.2796 30.448 26.0623C30.448 25.298 30.1343 24.7601 29.4405 24.4582C29.8492 24.1751 30.0868 23.7033 30.0868 23.1277C30.0868 22.0897 29.2694 21.4575 27.9103 21.4575H25.4391V28.0344ZM26.9313 23.9203V22.8163H27.9103C28.3285 22.8163 28.5946 22.9673 28.5946 23.3353C28.5946 23.741 28.3285 23.9203 27.9103 23.9203H26.9313ZM26.9313 26.6756V25.3074H28.1384C28.3665 25.3074 28.9558 25.3074 28.9558 25.9963C28.9558 26.4114 28.7372 26.6756 28.1384 26.6756H26.9313Z"
      fill="white"
    />
    <path d="M31.0386 28.0344H32.5308V21.4575H31.0386V28.0344Z" fill="white" />
    <path
      d="M34.8103 28.0344H36.3215V22.8163H38.2509V21.4575H32.8713V22.8163H34.8103V28.0344Z"
      fill="white"
    />
    <path
      d="M40.6049 28.176C41.9545 28.176 43 27.2984 43 25.9963C43 25.0621 42.5438 24.5903 41.1181 24.043C40.1676 23.675 39.911 23.4485 39.911 23.1277C39.911 22.8163 40.1962 22.5993 40.5383 22.5993C40.9185 22.5993 41.2417 22.8446 41.4413 23.3259L42.6768 22.5615C42.2491 21.7123 41.5078 21.2877 40.5858 21.2877C39.2837 21.2877 38.4188 22.0614 38.4188 23.156C38.4188 24.1657 38.9321 24.6846 40.3482 25.2508C41.2131 25.5999 41.5173 25.8264 41.5173 26.1284C41.5173 26.6285 41.1181 26.8644 40.6334 26.8644C39.968 26.8644 39.4928 26.5058 39.4263 25.732L37.9436 26.1C38.1432 27.355 39.2267 28.176 40.6049 28.176Z"
      fill="white"
    />
  </svg>
);

export default memo(LogoFooter);

import * as React from 'react';

import { IconProps } from '../common.types';

const EyeClose = ({ width = 19, height = 19 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.045 0 .179.865 17.314 18l.865-.865-3.687-3.688c2.506-1.697 3.744-4.07 3.76-4.102a.612.612 0 0 0-.026-.725c-.064-.103-3.59-5.74-9.047-5.74-1.548 0-2.938.46-4.14 1.114L1.045 0Zm8.134 4.716A4.284 4.284 0 0 1 13.463 9c0 .962-.324 1.844-.86 2.558l-1.319-1.319c.216-.363.343-.785.343-1.239A2.448 2.448 0 0 0 9.18 6.552c-.454 0-.876.127-1.24.343L6.623 5.577a4.242 4.242 0 0 1 2.557-.86Zm-6.15.659C1.212 6.905.17 8.564.135 8.619a.612.612 0 0 0-.032.718c.01.022 2.97 5.783 9.077 5.783 1.132 0 2.155-.2 3.072-.523l-1.584-1.585a4.261 4.261 0 0 1-1.488.272A4.284 4.284 0 0 1 4.896 9c0-.524.098-1.024.27-1.488L3.03 5.375Zm3.71 3.693a2.44 2.44 0 0 0 2.371 2.373L6.739 9.068Z"
      fill="#FFD600"
    />
  </svg>
);

export default EyeClose;

import * as React from 'react';

import { IconProps } from '../common.types';

const HamburgerClose = ({ width = 21, height = 26 }: IconProps) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.656 26H2.888L10.736 14.696L18.692 26H20.888L11.996 13.328L20.672 0.907999H18.476L10.772 12.032L3.068 0.907999H0.872L9.512 13.328L0.656 26Z" fill="white"
    />
  </svg>
);

export default HamburgerClose;

import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';


const Answer = ({
  option,
  label,
  value,
  setValue,
}: {
  option: number | string | boolean;
  label: string;
  value: number | string | boolean;
  setValue: (value: number | string | boolean) => void;
}) => {
  
  return (
    <div>
    <Container className={`answer-${option.toString()}`}>
      <AnswerButton
        label={
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                justifyItems: 'start',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
                paddingLeft: '13px',
                paddingRight: '18px',
              }}>
              <LabelText text={label} bold={value === option ? true : false} />
            </div>
          </>
        }
        onClick={() => {
          setValue(option);
        }}
        borderColor={value === option ? theme.colors.yellow : theme.colors.white50}
      />
    </Container>
    </div>
  );
};

const Container = styled.div`
  width: 92%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-direction: column;
`;

const AnswerButton = styled(Button)<{ fontWeight?: string }>`
  border-radius: 50px;
  width: 100%;
  height: 48px;
  border-width: 1px;
  align-items: normal;
`;

export const ErrorContainer = styled.div`
  position: relative;
  bottom: -10px;
  left: 10px;
`;

const LabelText = styled(Typography)<{ bold?: boolean }>`
  font-family: ${(props) => (props.bold ? 'HKGrotesk-Bold' : 'HKGrotesk-Light')};
  font-size: 16px;
  line-height: 20px;
`;


export default Answer;

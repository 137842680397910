import React from 'react';

import theme from 'theme/theme';

import { IconProps } from '../common.types';

const UserIcon = ({
  width = 16,
  height = 18,
  color = theme.colors.white,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 16.75V15a3.5 3.5 0 0 0-3.5-3.5h-7A3.5 3.5 0 0 0 1 15v1.75"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M8 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserIcon;

import React, { useRef, useEffect, useState, FC, useMemo } from 'react';
import styled from 'styled-components';

import WorldMapIcon from 'common/icons/WorldMapIcon';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { ILocationsData } from 'services/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface WorldMapProps {
  locationsData: ILocationsData[];
  currentDrop?: {
    value: string | number;
    label: string;
  };
  onLocationClick?: (id: string) => void;
}

const WorldMap: FC<WorldMapProps> = ({
  locationsData,
  currentDrop,
  onLocationClick = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [currentRegionName, setCurrentRegionName] = useState<string>('US');

  const mapRef = useRef<SVGSVGElement>(null);
  const statisticsRef = useRef<HTMLDivElement>(null);

  const [initialHighlightedNode, setInitialHighlightedNode] = useState<
    Element | null | 'completed'
  >(null);
  const [currentRegionNode, setCurrentRegionNode] = useState<Element>();
  const [countryNodes, setCountryNodes] = useState<
    {
      node: Element;
      enterCallback: any;
      leaveCallback: any;
      clickCallback: any;
    }[]
  >([]);

  const amountByRegions = useMemo(
    () =>
      locationsData.reduce((acc: { [x: string]: number }, item) => {
        if (!acc[item.continent_name]) {
          acc[item.continent_name] = Number(item.amount);
        } else {
          acc[item.continent_name] += Number(item.amount);
        }

        return acc;
      }, {}),
    [locationsData],
  );

  useEffect(() => {
    if (initialHighlightedNode === 'completed') return;

    const regionName = searchParams.get('name');
    if (regionName && mapRef.current) {
      const nodes = Array.from(Array.from(mapRef.current.children)[0].children);

      const targetNode = nodes.find((node) => node.id === regionName);

      if (targetNode) {
        targetNode.setAttribute('fill-opacity', '1');
        setInitialHighlightedNode(targetNode);
      }
    }
  }, [mapRef.current]);

  useEffect(() => {
    const regionLeaveHandler = (countryPath: Element) => (e: any) => {
      const target = e.relatedTarget as HTMLElement;
      const element = target || e.target;
      const id = element.id;

      if (
        id !== 'map-statistics' ||
        element.parentElement?.id === 'map-statistics'
      ) {
        countryPath.setAttribute('fill-opacity', '0.2');

        if (statisticsRef.current) statisticsRef.current.style.display = 'none';
      }
    };

    const regionEnterHandler = (countryPath: Element) => () => {
      if (initialHighlightedNode && initialHighlightedNode !== 'completed') {
        initialHighlightedNode.setAttribute('fill-opacity', '0.2');
        setInitialHighlightedNode('completed');
      }

      setCurrentRegionName(countryPath.id);
      countryPath.setAttribute('fill-opacity', '1');
      setCurrentRegionNode(countryPath);

      const element = document.getElementById(countryPath.id);
      const rectElement = element?.getBoundingClientRect();
      const rectMap = mapRef.current?.getBoundingClientRect();

      if (statisticsRef.current) {
        statisticsRef.current.style.display = 'flex';

        if (rectElement && rectMap) {
          if (countryPath.id === 'Europe') {
            statisticsRef.current.style.left =
              rectElement.left -
              rectMap.left -
              5 +
              rectElement.width / 2 +
              'px';

            statisticsRef.current.style.top =
              rectElement.top -
              rectMap.top +
              rectElement.height / 2 -
              70 +
              'px';
          } else if (countryPath.id === 'Oceania') {
            statisticsRef.current.style.left =
              rectElement.left -
              rectMap.left -
              5 +
              rectElement.width / 2 +
              40 +
              'px';

            statisticsRef.current.style.top =
              rectElement.top -
              rectMap.top +
              rectElement.height / 2 +
              10 +
              'px';
          } else {
            statisticsRef.current.style.left =
              rectElement.left -
              rectMap.left -
              5 +
              rectElement.width / 2 +
              'px';

            statisticsRef.current.style.top =
              rectElement.top - rectMap.top + rectElement.height / 2 + 'px';
          }
        }
      }
    };

    const handleLocationClick = (countryPath: Element) => () => {
      onLocationClick(countryPath.id);
    };

    if (mapRef.current) {
      const nodes = Array.from(
        Array.from(mapRef.current?.children)[0].children,
      );

      const currentCountryNodes = nodes.map((node) => ({
        node,
        enterCallback: regionEnterHandler(node),
        leaveCallback: regionLeaveHandler(node),
        clickCallback: handleLocationClick(node),
      }));

      setCountryNodes(currentCountryNodes);

      currentCountryNodes.forEach(
        ({ node, enterCallback, leaveCallback, clickCallback }) => {
          node.addEventListener('mouseover', enterCallback);
          node.addEventListener('mouseout', leaveCallback);
          node.addEventListener('click', clickCallback);
        },
      );
    }

    return () => {
      countryNodes.forEach(
        ({ node, enterCallback, leaveCallback, clickCallback }) => {
          node.removeEventListener('mouseover', enterCallback);
          node.removeEventListener('mouseout', leaveCallback);
          node.removeEventListener('click', clickCallback);
        },
      );
    };
  }, [mapRef, statisticsRef, initialHighlightedNode]);

  const handleStatisticsClick = () => {
    if (location.pathname === '/artist/insights/region') {
      onLocationClick(currentRegionName);
    } else {
      const queryParams: any = {
        region: currentRegionName,
      };

      if (currentDrop && currentDrop?.value !== 'all') {
        queryParams.release = currentDrop.value;
      }

      navigate(`/artist/insights/region`, {
        state: queryParams,
      });
    }
  };

  return (
    <Wrapper>
      <Statistics
        ref={statisticsRef}
        id="map-statistics"
        onMouseLeave={() => {
          currentRegionNode?.setAttribute('fill-opacity', '0.2');

          if (statisticsRef.current)
            statisticsRef.current.style.display = 'none';
        }}>
        <Typography
          text={'▲'}
          fontColor={theme.colors.black}
          fontWeight="bold"
          fontSize="fz10"
        />
        &nbsp;
        <ViewButton
          onTouchEnd={handleStatisticsClick}
          onClick={handleStatisticsClick}>
          <ViewButtonText
            text={`${currentRegionName} ${
              amountByRegions[currentRegionName] ?? 0
            }`}
            fontColor={theme.colors.black}
            fontWeight="bold"
            fontSize="fz14"
          />
          <ViewButtonText
            text={' | View'}
            fontColor={theme.colors.black}
            fontWeight="light"
            fontSize="fz14"
          />
        </ViewButton>
      </Statistics>
      <svg
        ref={mapRef}
        width={481}
        height={317}
        viewBox="0 0 481 317"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <WorldMapIcon />
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Statistics = styled.div`
  padding: 5px;
  background-color: ${theme.colors.yellow};
  width: max-content;
  position: absolute;

  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ViewButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
`;

const ViewButtonText = styled(Typography)`
  cursor: pointer;
`;

export default React.memo(WorldMap);

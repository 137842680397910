import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { useAppSelector } from 'hooks/reduxToolkit';

type PurchaseTimerProps = {
  onZero: () => void;
};

const PurchaseTimer: React.FC<PurchaseTimerProps> = ({ onZero }) => {
  const { expiryTime } = useAppSelector((state) => state.modalSlice);

  const [remainingTime, setRemainingTime] = useState<string>('15:00');

  useEffect(() => {
    const timer = setInterval(() => {
      const delta = (expiryTime - Date.now()) / 1000;

      if (delta < 0) {
        setRemainingTime('00:00');
        return;
      }

      const minutes = Math.floor(delta / 60) % 60;
      const seconds = Math.floor(delta % 60);

      setRemainingTime(
        `${minutes < 10 ? '0' + minutes : minutes}:${
          seconds < 10 ? '0' + seconds : seconds
        }`,
      );

      if (minutes === 0 && seconds === 0) {

        onZero();
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (expiryTime === 0) {

    return null;
  }

  return (
    <Typography
      text={
        <>
          You must complete your{'\n'}purchase within{' '}
          <InlineText>{remainingTime}</InlineText>
        </>
      }
      fontSize={'fz24'}
      fontWeight="bold"
    />
  );
};

const InlineText = styled.span`
  color: ${theme.colors.yellow};
`;

export default PurchaseTimer;

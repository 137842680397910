import React, { FC } from 'react';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import MetaMask from 'common/icons/MetaMask.icon';
import theme from 'theme/theme';

const ButtonLabel = () => (
  <ButtonLabelContainer>
    <IconContainer>
      <MetaMask width={21} height={20} />
    </IconContainer>
    <Typography fontSize="fz14" fontWeight="bold" text="Connect Wallet" />
  </ButtonLabelContainer>
);

interface PaymentModalProps {
  onClickHandler?: () => void;
}

const ConnectWalletButton: FC<PaymentModalProps> = ({ onClickHandler }) => {
  return (
    <WalletButton
      borderColor={theme.colors.white}
      borderRadius={20}
      label={<ButtonLabel />}
      onClick={() => {
        if (onClickHandler) {
          onClickHandler();
        }
      }}
    />
  );
};

const WalletButton = styled(Button)`
  font-family: 'HKGrotesk-Black', sans-serif;
  padding: 5px 0;
  border-width: 1px;
  cursor: pointer;
  font-size: ${theme.fontSizes.fz9}px;
  width: 170px !important;
  height: 30px !important;
`;

const ButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconContainer = styled.div`
  transform: scale(0.95);
`;

export default ConnectWalletButton;

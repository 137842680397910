import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import ArtistSettings from './pages/ArtistSettings';
import ArtistDashboard from './pages/Dashboard/ArtistDashboard';
import ArtistProfilePreview from './pages/ArtistProfilePreview';
import ArtistAnalytics from './pages/ArtistAnalytics/ArtistAnalytics';
import UserDetail from './pages/UserDetails/UserDetails';
import DropSummary from './pages/DropSummary/DropSummary';
import ArtistReleases from './pages/ArtistReleases/ArtistReleases';
import CountryList from './pages/CountryList/CountryList';
import InsightLocation from './pages/InsightLocation/InsightLocation';
import WaitlistStats from './pages/WaitlistStats/WaitlistStats';
import MarketingTools from './pages/MarketingTools/MarketingTools';

import MessagePreview from './pages/MessagePreview';
import SendMessage from './pages/SendMessage';
import MessageList from './pages/MessageList';

const ArtistRouter = () => {
  return (
    <ArtistRouterContainer>
      <Switch>
        <Route path="" element={<Navigate to={'dashboard'} />} />
        <Route path="artists" element={<Navigate to={'dashboard'} />} />
        <Route path="marketing-tools" element={<MarketingTools />} />
        <Route path="settings" element={<ArtistSettings />} />
        <Route path="marketing-tools" element={<MarketingTools />} />
        <Route path="dashboard" element={<ArtistDashboard />} />
        {/*<Route path="create-drop" element={<CreateDrop />} />*/}
        <Route path="preview" element={<ArtistProfilePreview />} />
        <Route path="insights" element={<ArtistAnalytics />} />
        <Route path="insights/region" element={<InsightLocation />} />
        <Route path="insights/users" element={<UserDetail />} />
        <Route path="insights/countries" element={<CountryList />} />
        <Route
          path="insights/users/send-message/preview"
          element={<MessagePreview />}
        />
        <Route
          path="insights/users/send-message/:id"
          element={<SendMessage />}
        />
        <Route path="insights/users/send-message" element={<SendMessage />} />
        <Route path="insights/users/message-list" element={<MessageList />} />

        <Route path="releases" element={<ArtistReleases />} />
        <Route path="releases/summary" element={<DropSummary />} />
        <Route path="waitlist-stats" element={<WaitlistStats />} />
      </Switch>
    </ArtistRouterContainer>
  );
};

const ArtistRouterContainer = styled.div`
  width: 100vw;
  height: fit-content;
  overflow-x: hidden;
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
`;

export default ArtistRouter;

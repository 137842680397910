import React, { FC, memo, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreState } from 'store/store';
import styled from 'styled-components';
import theme from 'theme/theme';
import { useViewport } from 'use-viewport';
import Button from '../Button/Button';
import Spacer from '../Spacer/Spacer';
import Typography from '../Typography/Typography';

import { SBModalProps } from 'typings/modal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setLocation } from 'store-persist/reducers/modalSlice';

const SBModal: FC<SBModalProps> = ({
  isOpen,
  content,
  height = '556px',
  width = '519px',
  top = '15%',
  bottom,
  left = '50%',
  right,
  mobileWidth,
  mobileHeight,
  mobileLeft = '50%',
  mobileRight,
  mobileBottom,
  mobileTop,
  overflow,
  withProceedingText,
  withSettings,
  withFeeText,
  feeIncludedInPrice,
  feeBottomPos,
  className
}) => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const zIndex = useStoreState((state) => state.modals.currentZIndex);
  let commission = useStoreState((state) => state.purchase.commission);

  if (!commission) {
    commission = { songbits_sale: 10, songbits_asset_royalty: 2.5 };
  }

  const getBottomPosition = () => {
    if (viewport.width >= 576) {
      return bottom ? bottom : '50%';
    } else {
      return mobileBottom ? mobileBottom : '50%';
    }
  };

  feeIncludedInPrice;
  feeBottomPos;

  /*const getFeeText = () => {
    if (withFeeText && commission) {
      return (
        <FeeText
          fontSize="fz10"
          fontColor={theme.colors.yellow}
          text={`* ${String(
            commission.songbits_sale,
          )}% sales and payment processing fee included`}
        />
      );
    }  if (!withFeeText && commission) {
      return (
        <FeeText
          fontSize="fz10"
          fontColor={theme.colors.yellow}
          text={`* ${String(
            commission.songbits_sale,
          )}% sales and payment processing fee to be included`}
        />
      );
    }  else {
      return (
        <FeeText
          fontSize="fz10"
          fontColor={theme.colors.yellow}
          text={`* 10% sales and payment processing fee to be included`}
        />
      );
    }
  };*/

  useEffect(() => {
    if (isOpen) {
      dispatch(setLocation(location.pathname));
    } else {
      dispatch(setLocation(''));
    }
  }, [isOpen]);

  const getBottomTextPosition = () => {
    if (viewport.width <= 375 && viewport.height <= 667) {
      return 100;
    } else if (viewport.width < 576) {
      return 10;
    } else {
      return 10;
    }
  };

  return (
    <ReactModal
      id="SBModal"
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          background: 'rgba(0,0,0,0.975)',
          zIndex: zIndex,
        },
        content: {
          fontFamily: 'HKGrotesk-Regular',
          background: 'transparent',
          border: `none`,
          width:
            viewport.width >= 576 ? width : mobileWidth ? mobileWidth : '346px',
          height:
            viewport.width >= 576
              ? height
              : mobileHeight
              ? mobileHeight
              : height,
          left:
            viewport.width >= 576
              ? !right
                ? left
                : ''
              : !mobileRight
              ? mobileLeft
              : '',
          right: right,
          top:
            viewport.width >= 576
              ? !bottom
                ? top
                : ''
              : mobileTop
              ? mobileTop
              : top,
          bottom: getBottomPosition(),
          padding:
            viewport.width >= 576
              ? '14px 20px 20px 30px'
              : '14px 20px 20px 20px',
          transform: 'translateX(-50%)',
          overflow: overflow ? overflow : 'hidden',
        },
      }}>
      <div className={className + '-modal'}>
      {content}
      <Footer>
        {withSettings && (
          <>
            <Spacer height={30} />
            <SettingsLink
              label="View account settings"
              onClick={() => {
                navigate('/account/settings');
              }}
            />
          </>
        )}
      </Footer>
      {withProceedingText && withFeeText && (
        <>
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: getBottomTextPosition(),
              paddingLeft: viewport.width < 576 ? 0 : '10px',
              paddingRight: viewport.width < 576 ? '15px' : '30px',
            }}>
            <FeeText
              fontSize="fz10"
              fontColor={theme.colors.yellow}
              text={`* ${String(
                commission.songbits_sale,
              )}% sales and payment processing fee included`}
            />
            <SmallText
              fontSize="fz10"
              text="By proceeding, I agree to SongBits Terms of Service and acknowledge that this transaction is irreversible and nonrefundable."
            />
          </div>
        </>
      )}
      {withProceedingText && !withFeeText && (
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 0,
            paddingLeft: '20px',
            paddingRight: '50px',
          }}>
          <SmallText
            fontSize="fz10"
            text="By proceeding, I agree to SongBits Terms of Service and acknowledge that this transaction is irreversible and nonrefundable."
          />
        </div>
      )}
      </div>
    </ReactModal>
  );
};

const SmallText = styled(Typography)<{ fontSize: string }>`
  color: ${theme.colors.white};
  text-align: center;
  font-size: 10px;
  font-family: 'HKGrotesk-Regular';
  line-height: 14px;
`;

const FeeText = styled(Typography)<{ fontSize: string }>`
  color: ${theme.colors.yellow};
  width: auto;
  text-align: center;
  font-size: 12px;
  font-family: 'HKGrotesk-Regular';
  line-height: 14px;
  margin-bottom: 20px;
`;

const SettingsLink = styled(Button)`
  font-family: 'HKGrotesk-Regular';
  font-size: 18px;
  color: ${theme.colors.white};
  text-decoration: none;
  font-weight: 500;
  line-height: 24px;
  bottom: -10px;
  position: relative;

  &:hover {
    text-decoration: none;
    background: none;
  }
`;

const Footer = styled.div`
  bottom: 15%;
  display: flex;
  -webkit-box-pack: start;
  flex-direction: column;
  justify-content: center;
`;

export default memo(SBModal);

import * as React from 'react';

import { IconProps } from '../common.types';

const AddNewSong = ({ width = 15, height = 15 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M13 13L1 1" stroke="white" width="2" />
    <path d="M3 13H13V3" stroke="white" width="2" />
  </svg>
);

export default AddNewSong;

/*import {
  loadStripe
} from '@stripe/stripe-js';

type ArtistTerritories = Record<string, any>

const artist_territories: ArtistTerritories = {
  GB: process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_UK,
  US: process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_US,
};*/

const promises = Object.create({});
/*Object.keys(artist_territories).forEach(country => {
  promises[country] = loadStripe(artist_territories[country] || "");
})*/
export const getStripePromise = (country: string) => {
  return promises[country];
}

export const StripeElementsStyles = {
  style: {
    base: {
        iconColor: 'white',
        fontWeight: 300,
        fontFamily: "'HKGrotesk-Light', sans-serif",
        fontSize: '16px',
        border: 'none',
        borderWidth: '0',
        transition: 'none',
        backgroundColor: '#000',
        ':-webkit-autofill': {
          color: '#FFF',
          backgroundColor: '#000',
          background: '#000',
          transition: 'none',
        },
        ':autofill': {
          color: '#FFF',
          backgroundColor: '#000',
          background: '#000',
          transition: 'none',
        },
        '::placeholder': {
            color: '#999999',
        },
        background: '#000',
        color: '#FFF'
    },
    invalid: {
        iconColor: 'white',
        color: 'white',
    },
    '.ElementsApp input:-webkit-autofill': {
      color: '#FFF',
      backgroundColor: '#000',
      background: '#000',
      transition: 'none',
    },
    'input:autofill': {
      color: '#FFF',
      backgroundColor: '#000',
      background: '#000',
      transition: 'none',
    },
    'input:-webkit-autofill': {
      color: '#FFF',
      backgroundColor: '#000',
      background: '#000',
      transition: 'none',
    },
  },
};

export interface StripeCVVResponse {
  token: {
    id: string;
    object: "token";
    client_ip: string;
    created: number;
    livemode: boolean;
    type: "cvc_update";
    used: boolean;
  }
}
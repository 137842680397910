import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import { Link } from "react-router-dom";

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: 992px) {
    min-width: 800px;
  }

  @media (min-width: 992px) {
    margin-left: 130px;
  }
`;

export const HeadContainer = styled.div`
  flex: 1;
  z-index: 1;
  width: 100%;
  flex-direction: column;
  padding: 48px 36px 0 36px;

  @media (min-width: 768px) {
    display: flex;
    padding: 48px 48px 0 48px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const BottomContainer = styled(HeadContainer)`
  padding: 0;
  align-items: center;
  justify-content: end;
  text-align: center;
  display: flex;
`;

export const LoadMoreButton = styled(Button)`
  z-index: 5;
  align-self: start;

  @media (min-width: 992px) {
    margin-left: 137px;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 60px;
  line-height: 40px;

  @media (min-width: 992px) {
    font-size: 100px;
  }
`;

export const InlineTypography = styled(Typography)`
`;

export const StrapLineTypographyContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const CustomSpacer = styled.div<{ height: string }>`
  ${(props) => props.height && `height: ${props.height}px;`};
  width: 100%;

  @media (min-width: 992px) {
    height: 100px;
  }
`;


export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;


export const LinkButton = styled(Button)`
  width: 100%;
  padding: 0 20px;
  font-family: 'HKGrotesk-Black';
  margin-bottom: 20px;
  border-color: #FFF;
  padding-left: 35px;
  padding-right: 35px;
  max-width: 200px;
  background: #000;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    margin-left: 0;
    margin-bottom: 0;
  max-width: 200px;
  }
`;


export const ButtonLink = styled(Link)`
  text-decoration: inherit;
  font-family: 'HKGrotesk-Black';
  font-weight: 900;
  display: block;
  width: 200px;

`;

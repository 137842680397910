import TablePlaceholder from 'common/components/Placeholder/Table';
import Spacer from 'common/components/Spacer/Spacer';
import React from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

const ArtistAnalyticsPlaceholder = () => {
  const viewport = useViewport();
  return (
    <Container>
      {/* Dropdown */}
      <span
        style={{ borderRadius: '10px', marginBottom: '15px', width: '64%' }}
        className={
          'placeholder-wave placeholder fs-18 lh-44 bg-secondary col-8'
        }>
        &nbsp;
      </span>
      {/* map heading */}
      <span
        className={
          'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-4'
        }>
        &nbsp;
      </span>

      {/* Map */}
      <MapPlaceholder
        className={
          'placeholder-wave placeholder fs-18 lh-80 bg-secondary col-12'
        }>
        &nbsp;
      </MapPlaceholder>
      <Spacer height={10} />

      <>
        {/* top locations header */}
        <span
          style={{ width: '31%', marginBottom: '0px' }}
          className={
            'placeholder-wave placeholder fs-18 lh-26 bg-secondary col-4'
          }>
          &nbsp;
        </span>
        {/* top locations table */}
        <LocationsTablePlaceholder>
          {[...Array(3)].map((_, index) => (
            <div
              key={`location-placeholder-${index}`}
              style={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'row',
                width: '100%',
              }}>
              <span
                key={`location-placeholder-${index}`}
                style={{ width: '20%' }}
                className={
                  'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-2'
                }>
                &nbsp;
              </span>

              <span
                key={`location-placeholder-${index}`}
                style={{
                  width: viewport.width < 576 ? '60%' : '31%',
                  flexGrow: '1',
                  marginRight: '10px',
                }}
                className={
                  'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-4'
                }>
                &nbsp;
              </span>
            </div>
          ))}
        </LocationsTablePlaceholder>
        {/* view all countries */}
        <span
          style={{ width: '31%' }}
          className={
            'placeholder-wave placeholder fs-18 lh-20 bg-secondary col-4'
          }>
          &nbsp;
        </span>
      </>

      <Spacer height={10} />

      {/* user tables */}
      {[...Array(2)].map((_, index) => (
        <>
          <TablePlaceholder
            key={`user-table-placeholder-${index}`}
            withTitle
            withHeadings
            numHeadings={3}
            numRows={3}
          />
          <span
            style={{ width: '25%' }}
            className={
              'placeholder-wave placeholder fs-18 lh-20 bg-secondary col-4'
            }>
            &nbsp;
          </span>
          <Spacer height={5} />
        </>
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;
`;

const MapPlaceholder = styled.span`
  height: 228px;

  @media (min-width: 576px) {
    height: 300px;
  }
`;

export const LocationsTablePlaceholder = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 5px;

  @media (min-width: 576px) {
    flex-direction: row;
    gap: initial;
  }
`;

export default ArtistAnalyticsPlaceholder;

import * as React from 'react';

import { IconProps } from '../common.types';

const PlusWideIcon = ({ width = 19, height = 19 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.334 6.9248V12.0049H0.791016V6.9248H18.334ZM12.3574 0.245117V18.8779H6.82031V0.245117H12.3574Z"
      fill="white"
    />
  </svg>
);

export default PlusWideIcon;

export const termsData = `

<div class=WordSection1>
<p>
<strong>Last Updated: September 23, 2024</strong>
</p>

<p class=Body style='text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Please
read these Terms of Service (the &quot;</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Terms</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;)
carefully because they govern your use of the website located at
https://songbits.com (the &quot;</span><span lang=DE style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>Site</span><span lang=EN-US style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>&quot;), the platform on which users
may purchase Bits (the &quot;</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>SongBits Platform</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;) and the
artist support platform accessible via the Site and corresponding mobile
application (&quot;</span><span lang=DA style='font-size:11.0pt;font-family:
"HKGrotesk-Black"'>App</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>&quot;) offered by SongBits Limited, a UK
registered Limited Company, (&quot;</span><span lang=DA style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>SongBits</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;). To make
these Terms easier to read, the Site, our services, the SongBits Platform, and
the App are collectively called the &quot;</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Services</span><span
lang=RU style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.&quot;</span></p>

<p class=Body style='text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>WHEN YOU
AGREE TO THESE TERMS, YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY
DISPUTE BETWEEN YOU AND SONGBITS THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER
THAN IN COURT. PLEASE REVIEW CAREFULLY THE SECTION HEADED &quot;DISPUTE
RESOLUTION&quot; BELOW FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU ARE A
RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS ARBITRATION OF
DISPUTES, THE AGREEMENT TO ARBITRATE WILL NOT APPLY TO YOU BUT THE PROVISIONS
OF GOVERNING LAW WILL APPLY INSTEAD.</span><span lang=EN-US style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'> </span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>BITS PURCHASED ON THE
SONGBITS PLATFORM ARE NON-RETURNABLE.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Agreement to
Terms. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>By
using our Services, you agree to be bound by these Terms. If you disagree to be
bound by these Terms, do not use the Services. Notwithstanding the foregoing,
the laws of some jurisdictions may limit or not permit certain provisions of
this agreement, such as indemnification, the exclusion of certain warranties or
the limitation of liability. In such a case, such provisions will apply only to
the maximum extent permitted by the laws of such jurisdictions. Also, you may
have additional legal rights in your jurisdiction, and nothing in these terms
will prejudice your rights as a consumer of the Services under such applicable
law.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Additional
Terms and Policies. </span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>Your use of the Services is subject to additional terms
and policies as may be posted on the Services from time to time, which may
include a privacy policy, cookie policy and an acceptable use policy (as
applicable).</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Privacy Policy. </span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Please
review our Privacy Policy, which also governs your use of the Services, for
information on how we collect, use and share your information.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Artist Terms
and Fan Terms</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
If you are an Artist or a Fan (as each is defined below), you will be subject
to the </span><i><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Terms
for Artists</span></i><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'> or </span><i><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>Terms for Fans</span></i><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, as defined as such
and set out below under the applicable headings respectively. There are also
terms which apply to both Artists and Fans and those terms are also set out below
under the applicable heading.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits
Programs</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
Your participation in certain SongBits programs (e.g., promotions, contests,
etc.) will be subject to additional terms and conditions specific to such
programs.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Changes to
these Terms or the Services. </span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>We may update the Terms from time to time at
our sole discretion. If we do, we</span><span dir=RTL></span><span lang=AR-SA
dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>ll let you know by posting the updated Terms on the Site,
to the App, and/or may also send other communications. It</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>s important that you review the Terms whenever we update
them, or you use the Services. If you continue to use the Services after we
have posted updated Terms or the time period following which the changes are
stated to come into effect (usually 14 or 30 days) has elapsed (whichever is
the earlier), you accept and agree to the changes. If you disagree to be bound
by the changes, you may not use the Services anymore. Because our Services are
evolving over time, we may change or discontinue all or any part of the
Services, at any time and without notice, at our sole discretion.</span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Who May Use
the Services? </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>The
Services are only available to users in those jurisdictions who can use the
Services as permitted under applicable law and you as a Fan or Artist (as
applicable) are responsible for ensuring that your purchase or sale of Bits
(and any associated rights and revenue derived from such purchase or sale)
complies with the laws of the applicable jurisdiction which applies to the
applicable Bits transaction. </span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>Please note</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>: all purchases or
sales of Bits (and any associated rights and revenue derived from such purchase
or sale) between Fans and Artists are concluded upon the Bits Sale Terms which
are subject to the exclusive jurisdiction of England and Wales. The Services
are not authorised for use in certain other jurisdictions where the United
Kingdom has embargoed goods or has otherwise applied economic sanctions.
Without limitation, you must be at least 18 years of age to use the Services.
You may not attempt to access or use the Services if you are not permitted to
do so.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Account
Creation</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
For certain features of the Services, you</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>ll need an account. To register an account, you must
first successfully complete the sign-up process. It</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>s important that you provide us with accurate, complete
and current account information and keep it up to date. We might have to
suspend or terminate your account if you don't. You must keep the account
details and password confidential to protect your account, and notify us
immediately of any unauthorised use. You</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>re responsible for all activities that occur under your
account. You are only allowed to create one account and agree you won</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>t share your account
with anyone. You also agree that you will not: (i) create another account if we</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>ve disabled one you
previously established unless you have our prior written consent; (ii) buy,
sell, rent or lease access to your account or username, unless you have our
prior written consent; (iii) share your account password with anyone; or (iv)
log in or try to log in to access the Service through unauthorised third-party
applications or clients.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Additional
Information. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We
may require you to provide additional information and documents at the request
of any competent authority or in case of application of any applicable law or
regulation, including laws related to financial services, securities,
cryptoasset, sanctions and/or anti-money laundering laws and regulations,
including, without limitation, the legalisation of incomes obtained by criminal
means or for counteracting financing of terrorism. We may also require you to
provide additional information and documents in cases where we have reasons to
believe that: (i) your account is being used for money laundering or any other
illegal or unauthorised activity; (ii) you have concealed or reported false
identification information and other details; or (iii) transactions effected
via your account were effected in breach of these Terms. In each such case, we,
at our sole discretion, may disable (or terminate) your account until we review
such additional information and documents and accept them as satisfying the
requirements of applicable law. If you do not provide complete and accurate
information and documents in response to such a request, we may refuse to
provide the Services. We reserve the right to report any activity using the
Services to relevant tax authorities as required under applicable law. You are
solely responsible for maintaining all relevant Tax (as defined below) records
and complying with any reporting requirements you may have related to our
Services. You are further solely responsible for independently maintaining the
accuracy of any record submitted to any tax authority, including any
information derived from the Services. &quot;</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Taxes</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;
means the taxes, duties, levies, tariffs, and other governmental charges you
may require by law to collect and remit to governmental agencies or other
withholding and personal or corporate income taxes.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Digital Rewards. </span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You may
receive Bits or other digital assets in connection with your use of the
Services. To receive digital rewards, you may have to connect to a third-party
service or sign up for a solution provided by SongBits. Such digital assets may
be subject to additional third-party terms and conditions, which you will be
deemed to accept upon your receipt of such digital assets, and any contract
relating to the provision of third-party services shall be between you and the
applicable third-party service provider.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Right to
Block Access. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We
reserve the right to block access to the Services from certain IP addresses and
unique device identifiers at any time in our sole discretion.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Equipment and
Software. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You
must provide all equipment, connectivity, and software necessary to connect to
the Service. You are solely responsible for any fees incurred when accessing
the Service, including Internet connection or mobile fees.</span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>About the
Services.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=FR
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Parties</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Our
Services also allow musical talent artists (&quot;</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Artists</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;) to
sell certain fractions of a song (each, a </span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>“</span><span lang=DE style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Bit</span><span
lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>”</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>) to
third-party entities or persons (&quot;</span><span lang=DE style='font-size:
11.0pt;font-family:"HKGrotesk-Black"'>Fans</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;) via the SongBits
Platform. We act as a disclosed agent for the Artist but we are not a party in
our own right to any transaction between Artists and any Fans in connection
with the sale of rights in Bits according to the Bits Sale Terms. We do not
control or vet content provided by Artists nor assume any responsibility for
the accuracy or reliability of any information provided by Artists.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Our Role</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. You
acknowledge and agree that the Bit and any associated streaming royalty
payments shall be programmed to enable you to take certain actions to claim
royalty payments. We have no control or ability to direct such funds or
obligation to collect or distribute such streaming royalties to Artists or
Fans.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Our
Relationship. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>WE
ARE A PLATFORM PROVIDER. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, OR
CREDITOR. THE SERVICE IS AN ADMINISTRATIVE PLATFORM ONLY. WE FACILITATE
TRANSACTIONS BETWEEN A BUYER AND SELLER BUT ARE NOT A PARTY TO ANY AGREEMENT
BETWEEN THE BUYER AND SELLER OF BITS OR BETWEEN ANY USERS. WE ACT AS A DISCLOSED
AGENT FOR THE ARTIST IN THE SALE OF BITS, AND OUR ACTIVITIES IN RELATION TO THE
CREATION AND SALE OF BITS ARE CARRIED ON BY US FOR THE ARTIST.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Terms for
Both Artists and Fans</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Costs and
Taxes</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
You are solely responsible for all costs incurred in using the Services and
determining, collecting, reporting and paying all applicable Taxes. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Regulatory
and Compliance Suspensions or Terminations. </span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We may suspend or
terminate your access to the Services at any time in connection with any
transaction as required by applicable law or any governmental authority or if
we, in our sole and reasonable discretion, determine you are violating these
Terms or the terms of any third-party service provider. Such suspension or
termination shall not constitute a breach of these Terms by us. Under our
anti-money laundering, anti-terrorism, anti-fraud, and other compliance
policies and practices, we may impose reasonable limitations and controls on
the ability of you or any beneficiary to utilise the Services. Such limitations
may include where good cause exists, rejecting transaction requests, freezing
funds, or restricting you from using the Services.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Your Content;
Bits.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Posting
Content</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
Our Services may allow you to store or share content such as text (in posts or
communications with others), files, documents, graphics, images, music,
software, audio and video. Anything (other than Feedback) you post or otherwise
make available through the Services is called &quot;</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>User Content</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;. We
do not claim any ownership rights in any User Content and nothing in these
Terms will be deemed to restrict any rights you may have to your User Content.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Permissions
to Your User Content</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>. By making any User Content available through the
Services, you hereby grant us a non-exclusive, transferable, worldwide,
royalty-free license, with the right to sublicense, use, copy, modify, create
derivative works based upon, distribute, publicly display, and publicly perform
your User Content in connection with operating and providing the Services.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Authority. </span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You
represent and warrant that you have, or have obtained, all rights, licenses,
consents, permissions, power and/or authority necessary to grant the rights
granted herein for any User Content that you submit, post or display on or
through the Service, including but not limited to SongBits Content. You agree
that such User Content will not contain material subject to copyright or other
proprietary rights unless you have necessary permission or are otherwise
legally entitled to post the material and to grant us the licence described
above.</span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Your
Responsibility for User Content</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>. You are solely responsible for all your
User Content. You represent and warrant that you have (and will have) all
rights necessary to grant us the license rights in your User Content under
these Terms. You represent and warrant that neither your User Content, nor your
use and provision of your User Content to be made available through the
Services, nor any use of your User Content by us on or through the Services
will infringe, misappropriate or violate a third party</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>s intellectual property rights, or rights of publicity or
privacy, or result in the violation of any applicable law or regulation.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Removal of
User Content</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
You should know that in certain instances, some of your User Content (such as
posts or comments you make) may not be completely removed, and copies of your
User Content may continue to exist on the Service. To the maximum extent
permitted by law, we are not responsible or liable for the removal or deletion
of (or the failure to remove or delete) any User Content.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’ </span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Intellectual Property</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. We may
make available through the Services content subject to intellectual property
rights. We retain all rights to that content.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Feedback. </span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We
appreciate feedback, comments, ideas, proposals, and suggestions for improving
the Services (&quot;</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Black"'>Feedback</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>&quot;). If you choose to submit Feedback,
you agree that we are free to use it (and permit others to use it) without any
restriction or compensation to you.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>General
Prohibitions</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
You agree not to do any of the following:</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Post,
upload, publish, submit or transmit any User Content that (i) infringes,
misappropriates or violates a third party</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>s patent, copyright, trademark, trade secret, moral
rights or other intellectual property rights, or rights of publicity or
privacy; (ii) violates, or encourages any conduct that would violate, any
applicable law or regulation or would give rise to civil liability; (iii) is
fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene,
pornographic, vulgar or offensive; (v) promotes discrimination, bigotry,
racism, hatred, harassment or harm against any individual or group; (vi) is
violent or threatening or promotes violence or actions that are threatening to
any person or entity; or (vii) promotes illegal or harmful activities or
substances;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Use,
display, mirror or frame the Services or any individual element within the
Services, SongBits</span><span dir=RTL></span><span lang=AR-SA dir=RTL
style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’ </span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>name, any SongBits trademark, logo or other proprietary
information, or the layout and design of any page or form contained on a page,
without our express written consent;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Attempt
to access or search the Services or download content from the Services using
any engine, software, tool, agent, device or mechanism (including spiders,
robots, crawlers, data mining tools or the like) other than the software and/or
search agents provided by us or other generally available third-party web
browsers;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Use
the Services, or any portion thereof, for any commercial purpose or the benefit
of any third party or in any manner not permitted by these Terms;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Attempt
to decipher, decompile, disassemble or reverse engineer any of the software
used to provide the Services;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Collect
or store any personally identifiable information from the Services from other
users of the Services without their express permission;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Impersonate
or misrepresent your affiliation with any person or entity;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Violate
any applicable law or regulation; or</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Encourage
or enable any other individual to do any of the foregoing.</span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>General
Enforcement Rights</span><span lang=DA style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Unlawful Content</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Assets,
listings, smart contracts, collections, and content (including User Content)
that we deem inappropriate, disruptive, or illegal are prohibited on our
Services. We reserve the right to determine the appropriateness of any content
and listings on its site and remove any such content or listings at any time.
If you create or offer a listing, collection, or User Content in violation of
these policies, we will take corrective actions, as appropriate, including but
not limited to removing the listing, collection, or User Content, deleting your
SongBits Account, and permanently withholding any applicable referral payments
or developer revenue sharing fees that you may be eligible for. We cannot
destroy or impound your assets or contract, but we reserve the right to destroy
inappropriate metadata stored on our servers.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=IT
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Illegal Content</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Assets,
listings, contracts, collections, and User Content that include metadata, or
which have a primary or substantial purpose in a game or application, that
violates international intellectual property laws, promotes suicide or
self-harm, incites hate or violence against others, degrades or doxes another
individual, depicts minors in sexually suggestive situations, or is otherwise
illegal are prohibited on our Services and will be removed.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Content Used
for Unlawful Purposes</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>. Our Services prohibit assets, listings,
contracts, collections, and User Content created or used to raise funds for
known terrorist organisations and will remove them.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Stolen
Content</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
The sale of stolen assets, assets taken without authorisation, and otherwise
illegally obtained assets on our Services is prohibited. If you have reason to
believe that an asset listed on our Services was illegally obtained, please
contact us immediately. Listing illegally obtained assets may result in your
listings being cancelled, your assets being hidden, or your Account being
suspended.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Reporting
Content</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
If you become aware of the creation, listing, or buying of assets violating any
of the terms specified in this Section, please contact us at
notices@songbits.com to report it. User Content creators bear special
responsibility for informing us of the existence of their collections,
contracts, and assets that violate these terms.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>No Obligation</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. We are
not obligated to monitor access to or use the Services or review or edit any
content. However, we have the right to do so to operate the Services, ensure
compliance with these Terms, and comply with applicable law or other legal
requirements. We reserve the right but are not obligated to remove or disable
access to any content, including User Content, at any time and without notice,
including, but not limited to, if we, at our sole discretion, consider it
objectionable or violating these Terms. We have the right to investigate
violations of these Terms or conduct that affects the Services. We may also
consult and cooperate with law enforcement authorities to prosecute users who
violate the law.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Copyright
Policy. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We
respect copyright law and expect our users to do the same. In appropriate
circumstances, we will terminate account holders who repeatedly infringe or are
believed to be repeatedly infringing the rights of copyright holders.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’ </span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Intellectual Property</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Unless
otherwise indicated in writing by us, the Service and all content and other
materials contained therein, including, without limitation, the SongBits logo
and all designs, text, graphics, pictures, information, data, software, sound
files, other files and the selection and arrangement thereof (collectively,
&quot;</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits
Content</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;)
are the proprietary property of SongBits or our affiliates, licensors or users,
as applicable. You may not use any SongBits Content or link to the Service
without our express written permission. Without our written consent, you may
not use framing techniques to enclose any SongBits Content.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We and our
licensors exclusively own all rights, titles and interests in and to the
Service, including all associated intellectual property rights therein. You
acknowledge that the Service is protected by copyright, trademark, and other
laws of the United Kingdom. You agree not to remove, alter or obscure any
copyright, trademark, service mark or other proprietary rights notices
incorporated in or accompanying the Service.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>References
to any other products, services, processes or other information by name,
trademark, manufacturer, supplier or otherwise on the Service does not
constitute or imply our endorsement, sponsorship or recommendation.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Links to
Third-Party Websites or Resources. </span><span lang=EN-US style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>The Services may allow you to access
third-party websites or other resources. We provide access only as a
convenience and are not responsible for the content, products or services on or
available from those resources or links on such websites. You acknowledge sole
responsibility for and assume all risk from using any third-party resources.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=IT
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Termination. </span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We may
suspend or terminate your access to and use of the Services, including
suspending access to or terminating your account, at our sole discretion, at
any time and without notice to you. You may cancel your account at any time by
sending us an email at goodbye@songbits.com. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Disclaimers</span><span
lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>EXCEPT
AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY US, THE SERVICE, SONGBITS
CONTENT CONTAINED THEREIN, AND ANY OTHER ASSETS LISTED THEREIN ARE PROVIDED ON
AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT WARRANTIES OR
CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE (AND OUR SUPPLIERS) MAKE
NO WARRANTY THAT THE SERVICE: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL BE
AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (III)
WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE. WE DISCLAIM ALL OTHER
WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
AS TO THE SERVICE, SONGBITS CONTENT CONTAINED THEREIN AND ANY SONGBITS OR OTHER
ASSETS LISTED THEREIN.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>WE
DO NOT REPRESENT OR WARRANT THAT SONGBITS CONTENT ON THE SERVICE IS ACCURATE,
COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE WILL NOT BE LIABLE FOR ANY KIND
OF LOSS FOR ANY ACTION TAKEN IN RELIANCE ON MATERIAL OR INFORMATION CONTAINED
IN THE SERVICE. WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF THE SERVICE
AND SONGBITS CONTENT SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE
SERVICE, SONGBITS CONTENT, ANY SONGBITS OR OTHER ASSETS LISTED THEREIN, OR OUR
SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE
THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT
SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET
AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO
OUR GROSS NEGLIGENCE.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>WE
WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF OUR SERVICES,
INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I)
USER ERROR SUCH AS FORGOTTEN PASSWORDS OR MISTYPED ADDRESSES; (II) SERVER
FAILURE OR DATA LOSS; (III) CORRUPTED FILES; (IV) UNAUTHORISED ACCESS TO
APPLICATIONS; OR (V) ANY UNAUTHORISED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT
LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK
AGAINST THE SERVICE OR ASSETS.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=DE style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=DE style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>NOTHING
IN THESE TERMS SHALL EXCLUDE OR LIMIT EITHER PARTY'S LIABILITY FOR FRAUD,
VIOLATION OF LAWS, OR ANY OTHER ACTIVITY THAT CANNOT BE LIMITED OR EXCLUDED BY
LEGITIMATE MEANS.</span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Assumption
of Risk. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You
accept and acknowledge:</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You
are solely responsible for determining what, if any, taxes apply to your
transactions through the Service. Neither we nor any other SongBits Party is
responsible for determining the taxes that apply to such transactions.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>The
Service may rely on third-party platforms to perform transactions with respect
to any assets. If we are unable to maintain a good relationship with such
platform providers; if the terms and conditions or pricing of such platform
providers change; if we violate or cannot comply with the terms and conditions
of such platforms; or if any of such platforms loses market share or falls out
of favour or is unavailable for a prolonged period of time, access to and use
of the Service will suffer.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>There
are risks associated with purchasing user-generated content. We reserve the
right to hide collections and assets affected by these or other issues. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Indemnity. </span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>To the
fullest extent permitted by applicable law, you agree to indemnify, defend and hold
harmless us, and our respective past, present and future employees, officers,
directors, contractors, consultants, equity holders, suppliers, vendors,
service providers, parent companies, subsidiaries, affiliates, agents,
representatives, predecessors, successors and assigns (individually and
collectively, the &quot;</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>SongBits Parties</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;), from and
against all actual or alleged third party claims, damages, awards, judgments,
losses, liabilities, obligations, penalties, interest, fees, expenses
(including, without limitation, attorneys</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’ </span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>fees and expenses) and costs (including, without
limitation, court costs, costs of settlement and costs of pursuing
indemnification and insurance), of every kind and nature whatsoever, whether
known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or
unsuspected, in law or equity, whether in tort, contract or otherwise
(collectively, &quot;</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>Claims</span><span lang=EN-US style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>&quot;), including, but not limited
to, damages to property or personal injury, that are caused by, arise out of or
are related to: (i) your use or misuse of the Service or SongBits Content or
User Content; (ii) your User Content or Feedback; (iii) your violation of these
Terms and/or, as applicable, the Bits Sales Terms and/or Artist Agency
Agreement; and/or (iv) your violation of the rights of a third party or of
applicable law. You agree to promptly notify SongBits of any Claims and
cooperate with the SongBits Parties in defending such Claims. You further agree
that the SongBits Parties shall have control of the defence or settlement of
any third-party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT INSTEAD OF,
ANY OTHER INDEMNITIES OUTLINED IN A WRITTEN AGREEMENT BETWEEN YOU AND SONGBITS.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Limitation of
Liability.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>TO THE
MAXIMUM EXTENT PERMITTED BY LAW, NEITHER US NOR OUR SERVICE PROVIDERS INVOLVED
IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY
INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST
PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA
OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE
COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH
THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED
ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY
OTHER LEGAL THEORY, AND WHETHER OR NOT WE OR OUR SERVICE PROVIDERS HAVE BEEN
INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>TO THE
MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL OUR TOTAL LIABILITY ARISING
OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE
THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO US FOR
USE OF THE SERVICES OR ONE HUNDRED POUNDS (</span><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>£</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>100), IF
YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO SONGBITS, AS APPLICABLE.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DE
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>NOTHING IN THIS
AGREEMENT WILL LIMIT OUR LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED OUR
NEGLIGENCE, OUR FRAUD (OR FRAUDULENT MISREPRESENTATION) OR FOR ANY OTHER
LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED BY LAW. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>THE
EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Governing Law
and Forum Choice. </span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>These Terms and any action related thereto will be
governed by the laws of the United Kingdom. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>The
exclusive jurisdiction for all Disputes (defined below) that you are not
required to arbitrate, will be in the United Kingdom, and you and us each waive
any objection to jurisdiction and venue in such courts.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=PT style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Dispute
Resolution.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Mandatory
Arbitration of Disputes</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>. We each agree that any dispute, claim or
controversy arising out of or relating to these Terms or the breach,
termination, enforcement, interpretation or validity thereof or the use of the
Services (collectively, &quot;</span><span lang=DA style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>Disputes</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;) will be
resolved </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>solely
by binding, individual arbitration and not in a class, representative or
consolidated action or proceeding</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>. You and we agree that UK law governs the
interpretation and enforcement of these terms and that you and we each waive
the right to a trial by jury or to participate in a class action. This
arbitration provision shall survive termination of these Terms.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Exceptions</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. As
limited exceptions to Section 1 above: (i) we both may seek to resolve a
Dispute in small claims court if it qualifies; and (ii) we each retain the
right to seek injunctive or other equitable relief from a court to prevent (or
enjoin) the infringement or misappropriation of our intellectual property
rights.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Conducting
Arbitration and Arbitration Rules</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>. The arbitration will be conducted by JAMS
under its JAMS Comprehensive Arbitration Rules and Procedures (the &quot;</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>JAMS Rules</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;)
then in effect, except as modified by these Terms. The JAMS Rules are available
at https://www.jamsadr.com/. A party who wishes to start arbitration must
submit a written Demand for Arbitration to JAMS and give notice to the other
party as specified in the JAMS Rules. JAMS provides a form Demand for
Arbitration at https://www.jamsadr.com/. Any arbitration hearings will take
place where you live unless we agree to a different location but will be
conducted remotely to the extent permitted by the JAMS Rules. The parties agree
that the arbitrator shall have exclusive authority to decide all issues
relating to the interpretation, applicability, enforceability and scope of this
arbitration agreement.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Arbitration
Costs</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
Payment of all filing, administration and arbitrator fees will be governed by
the JAMS Rules, and we won</span><span dir=RTL></span><span lang=AR-SA dir=RTL
style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>t seek to recover the administration and arbitrator fees
we are responsible for paying unless the arbitrator finds your Dispute
frivolous. If we prevail in arbitration, we</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>ll pay all our legal fees and costs and won</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>t seek to recover
them from you. If you prevail in arbitration, you will be entitled to legal
fees and expenses award to the extent provided under applicable law.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Injunctive
and Declaratory Relief</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>. Except as provided in Section 2 above, the
arbitrator shall determine all issues of liability on the merits of any claim
asserted by either party and may award declaratory or injunctive relief only in
favour of the individual party seeking relief and only to the extent necessary
to provide relief warranted by that party</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>s individual claim. To the extent that you or we prevail
on a claim and seek public injunctive relief (that is, injunctive relief that
has the primary purpose and effect of prohibiting unlawful acts that threaten
future injury to the public), the entitlement to and extent of such relief must
be litigated in a civil court of competent jurisdiction and not in arbitration.
The parties agree that litigation of any issues of public injunctive relief
shall be stayed pending the outcome of the merits of any individual claims in
arbitration.</span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Class Action
Waiver</span><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>YOU AND US
AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
CLASS OR REPRESENTATIVE PROCEEDING. </span><span lang=EN-US style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>Further, if the parties</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’ </span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Dispute is resolved
through arbitration, the arbitrator may not consolidate another person</span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>’</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>s claims with your
claims and may not otherwise preside over any form of a representative or class
proceeding. If this specific provision is unenforceable, then the entirety of
this Dispute Resolution section shall be null and void.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Severability</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Except
for any of the provisions of these Terms (&quot;</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Class Action Waiver</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;),
if an arbitrator or court of competent jurisdiction decides that any part of
these Terms is invalid or unenforceable, the other parts will still apply.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>General Terms.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Entire
Agreement</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
These Terms constitute the entire and exclusive understanding and agreement
between you and us regarding the Services, and these Terms supersede and
replace all prior oral or written understandings or agreements between you and
us regarding the Services. If any provision of these Terms is held invalid or
unenforceable by an arbitrator or a court of competent jurisdiction, that
provision will be enforced to the maximum extent permissible, and the other
provisions will remain in full force and effect. You may not assign or transfer
these Terms, by operation of law or otherwise, without our prior written
consent. Any attempt by you to assign or transfer these Terms without such
consent will be null. We may freely assign or transfer these Terms without
restriction. Subject to the foregoing, these Terms will bind and inure to the
benefit of the parties, their successors and permitted assigns.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Notices</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Any
notices or other communications provided by us under these Terms will be given
(i) via email or (ii) by posting to the Services. For notices made by email,
the date of receipt will be deemed the date on which such notice is
transmitted.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Waiver of
Rights</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
Our failure to enforce any right or provision of these Terms will not be
considered a waiver of such right or provision. The waiver of any such right or
provision will be effective only if in writing and signed by a duly authorised
representative of us. Except as expressly outlined in these Terms, the exercise
by either party of any of its remedies will be without prejudice to its other
remedies under these Terms or otherwise.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Contact
Information. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>If
you have any questions about these Terms or the Services, please contact us at
notices@songbits.com</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Terms
for Artists.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits
Fee</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
We may require a fee for using certain features of the Services (&quot;</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits Fee</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;).
We reserve the right to change the SongBits Fee at our sole discretion. Any
increase in the SongBits Fee will be communicated to you at least 14 days
before it comes into effect.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Additional
Obligations. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>By
creating an account and selling Bits, it shall be a condition that each Artist
has entered into an artist agency agreement with SongBits setting out (a) the
terms and conditions upon which Artists contract with and appoint, SongBits as
agent (</span><span dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:
11.0pt;font-family:"Arial Unicode MS",sans-serif'><span dir=RTL></span>“</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Artist Agency
Agreement</span><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>”</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>); and (b)
an appendix which contains the terms which are offered to Fans and which each
Artists and their respective Fans will sign up to and enter when concluding the
purchase of Bits (defined as &quot;</span><span lang=EN-US style='font-size:
11.0pt;font-family:"HKGrotesk-Black"'>Bits Sales Terms</span><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;). </span></p>

<span lang=DA style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=Body><span lang=DA>&nbsp;</span></p>

<p class=Body><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>&nbsp;</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Terms for
Fans</span><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits Fee</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Each
Artist may require a fee to purchase one of its Bits (&quot;</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits Fee</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;).
As a Fan, you are responsible for paying the SongBits Fee according to any
additional terms provided by such Artist in the Bits Sales Terms (defined
above).</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Management of
Streaming Rights. </span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>SongBits does not manage any streaming rights for Fans.</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'> </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Purchasing
Bits. </span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Our
Services may permit you to purchase Bits from Artists. Any sale and purchase of
Bits is conducted directly between you and the relevant Artist under those
terms and conditions which apply to the sale and purchase of Bits (i.e. </span><span
dir=RTL></span><span lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:
"Arial Unicode MS",sans-serif'><span dir=RTL></span>“</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Bits Sale Terms</span><span
lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>”</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>). We are
never a principal contractual party in the relationship regarding the sale or
purchase of any Bits save that we may act on the Artist's behalf to enter into
an agreement with you as the Artist's disclosed agent to facilitate the
entering into by you, a Fan, and the Artist of the Bits Sales Terms. The Artist
sets the price for any Bits and is responsible for making Royalty Payments to
you. To make and receive payments, you must set up and maintain a digital
wallet with SongBits</span><span dir=RTL></span><span lang=AR-SA dir=RTL
style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’ </span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>selected third-party payment processing partner (&quot;</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Payment
Processor</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;).
Further details are set out below in the Payment and Refund Processing section.
Royalty Payments are calculated in accordance with the number of Bits purchased
by you from the Artist in respect of the relevant content and are as set out in
the Bits Sales Terms. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Bits Sales
Terms</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
Any Bits you purchase are governed by this contract and any Bits Sales Terms
which govern terms, rights or benefits associated with a song release and any
associated digital assets. SongBits is not a party to any Bits Sales Terms,
which are solely between the Artist and the Fan, and SongBits acts as the
Artist's disclosed agent in respect of the Bits Sales Terms. The Artist and Fan
are responsible for communicating, promulgating, agreeing to, and enforcing the
Bits Sales Terms. Any disputes or claims in respect of rights relating to Bits
(and any associated digital asset(s) connected with the applicable Bits) shall
be resolved solely between the Fan and applicable Artist, though SongBits may
as agent seek, wherever possible, to assist with any such dispute or claim,
subject to these Terms. A copy of the Bits Sales Terms is available on [</span><span
lang=DA style='font-size:11.0pt;font-family:"Times New Roman",serif'>●</span><span
lang=PT style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>].</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Bits Rights</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. When you
purchase a Bit through the Service, you are granted certain rights to that Bit
in accordance with the Bits Sales Terms (by way of example only, the right to
receive music streaming income relating to the applicable track to which the
Bit relates, depending on the fractional percentage of rights in question, or,
if permitted under the Bits Sales Terms, the right to sell, transfer or
otherwise dispose of that Bit). You may only sell, transfer or otherwise
dispose of that Bit when such functionality is available through the Services.
Such rights, however, do not include the ownership of the intellectual property
rights in the User Content comprising the Bits (&quot;</span><span lang=DA
style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>SongBits</span><span
lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> </span><span
lang=IT style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Content</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;).
Unless specified otherwise in the Bits Sales Terms, the Fan will have a
worldwide, non-exclusive, non-transferable, royalty-free license to use, copy,
and display the SongBits Content associated with the Bit), solely for the
following purposes: (i) for your own personal, non-commercial use; and (ii) as
part of a third party digital asset service associated with the purchased Bits
upon and subject to the applicable terms and conditions relating to its use and
the digital asset in question. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>If you are
permitted to sell your Bits according to the Bits Sales Terms, and the Services
permit such functionality, then any secondary sale of your Bits must be
conducted through the Services to another Fan who is subject to these terms. 
We solely facilitate and administer any such secondary sale of a Bit and you
are responsible for the transfer of your rights in the relevant Bit to the
buyer. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Payment
Transactions</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.
Other than as expressly permitted and stated under the applicable third-party
Payment Processor terms of service, you may purchase Bits using a credit card,
digital wallet or the applicable means of payment as specified by the
applicable Payment Processor. It is your responsibility as a Fan to claim any
streaming royalties associated with your Bit by following the applicable
instructions on the Site. You expressly authorise the Artist (and any
applicable Payment Processor) to charge your payment card, the applicable
digital wallet or other authorised means of payment for all purchases you make.
For credit card payments, you authorise the Artist to charge your credit card
when you purchase a Bit. The bank issuing your credit or payment card may
control when to release funds in the case of an order cancellation or refund.
We may ask you to supply additional information relevant to your transaction,
including your credit card number, the expiration date of your credit card and
your email and postal addresses for billing and notification (such information,
&quot;</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Payment
Information</span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;).
You represent, warrant and undertake that you have the legal right to use all
payment method(s) represented by any such Payment Information. You may need to
provide additional information to verify your identity before completing your
transaction (such information is included in the Payment Information
definition). We reserve the right to use the Payment Information you provide us
in connection with any payments to provide better service to you should you
wish to use our service again in the future and to protect us from fraud and
other losses. Completion of a payment transaction is contingent upon:</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>you
providing complete personal, account, transaction and any other information
needed,</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>authorisation
of the payment by your credit or debit card company, and</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:1.0cm;text-align:justify;text-justify:inter-ideograph;text-indent:
0cm'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>acceptance
of your payment.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Payment and
Refund Processing</span><span lang=DA style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.</span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> Payments
and refunds facilitated by the Services will be processed by the designated
Payment Processor. You will be required to create an account with such a
Payment Processor, and by doing so, you are agreeing to comply with the
applicable third-party Payment Processor</span><span dir=RTL></span><span
lang=AR-SA dir=RTL style='font-size:11.0pt;font-family:"Arial Unicode MS",sans-serif'><span
dir=RTL></span>’</span><span lang=EN-US style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>s terms of service. You may need to provide additional
information to verify your identity, business and tax information, and bank
information when you register with the Payment Processor. </span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Chargebacks
and Payment Holdback</span><span lang=DA style='font-size:11.0pt;font-family:
"HKGrotesk-Black"'>. </span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>We reserve the right to offset future
payments to you (&quot;</span><span lang=EN-US style='font-size:11.0pt;
font-family:"HKGrotesk-Black"'>Payment Holdbacks</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;) in some
instances, including without limitation: (i) where your SongBits Account is
subject to excessive chargebacks resulting in additional costs or fees from the
Payment Processor; (ii) where the Payment Processor withholds a percentage of a
payment as a reserve, for example, as a result of actual or suspected
fraudulent activity; or (iii) in some instances where we decide, in our sole
discretion, that it is prudent or necessary to reverse a transaction. We
further reserve the right to implement Payment Holdbacks to you if payments are
deemed suspicious or fraudulent at our sole discretion. We will notify you that
you are subject to a Payment Holdback and may require you to provide supporting
documentation or information related to the relevant payment(s) and
corresponding purchase(s). If you do not provide such supporting evidence
within the requested timeframe, we reserve the right to refund the payment and
transfer any available funds from your wallet to your identified bank account
or otherwise take any action we feel is reasonably necessary to comply with our
compliance standards, including those imposed by any relevant financial
institution, our Payment Processor, or the credit card network rules, as well
as any applicable law.</span></p>

<p class=Body style='margin-top:5.0pt;margin-right:0cm;margin-bottom:5.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Black"'>Failure to
Process Payments, Refunds or Chargebacks</span><span lang=DA style='font-size:
11.0pt;font-family:"HKGrotesk-Black"'>.</span><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> You are solely
responsible for the information you provide concerning payments and refunds
processed via the Services. We will not be liable for failure to complete any
payment or refund from or to your SongBits Account if you provide inaccurate or
incomplete information regarding the payment or refund. Subject to applicable
law, we reserve the right to send any delinquent, past due, or any other
SongBits Account that is in default to collections agencies to recover such
past due amount</span></p>

<p class=Body style='text-align:justify;text-justify:inter-ideograph;'><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>SongBits
Limited. Level 3, 207 Regent Street, London W1B 3HH</span></p>

</div>
`
import React, { useEffect, useState } from 'react';
import { useViewport } from 'use-viewport';
import { useStoreActions } from 'store/store';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { Oval } from 'react-loader-spinner';

import { xs } from 'utils/constants';
import {
  ContentButton,
  FirstButton,
  Form,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  TextContent,
  Wrapper,
  HeaderTitleExtraContainer,
  TextInfo,
} from './styled/GiveawayWaitlistThanks.styled';

import Spacer from 'common/components/Spacer/Spacer';
import Banner from '../assets/giveaway-background-faded.jpg';

import restService from 'services/rest.service';
import GiveawayError from '../components/Giveaway/Error';
import { useNavigate } from 'react-router-dom';

const Giveaway = () => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const updateHeaderTransparence = useStoreActions(
    (actions) => actions.style.setHeaderTransparent,
  );
  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [claimBitsError, setClaimedBitsError] = useState<boolean>(false);

  useEffect(() => {
    updateHeaderTransparence(true);

    return () => {
      updateHeaderTransparence(false);
    };
  }, []);

  function claimGiveaway() {
    setLoading(true);
    
    restService
      .claimGiveway()
      .then((res) => {
        res;
        setLoading(false);
        navigate('/library/my-collection');
      })
      .catch((error) => {
        if (
          error.message === 'Too many bits selected for reservation' ||
          error.message === 'Song sold out'
        ) {
          setClaimedBitsError(true);
          setLoading(false);
        }
        if (error.message === 'Not registered for giveaway') {
          setGlobalBanner({
            title: 'Sorry',
            text: 'you need to join the giveaway.',
          });
          setLoading(false);
          navigate('/giveaway/sxsw/waitlist');
        }
        if (error.message === 'Already claimed giveaway') {
          setGlobalBanner({
            title: 'Sorry',
            text: 'you have already claimed.',
          });
          setLoading(false);
          navigate('/');
        }

        setLoading(false);
      });

  }

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  useEffect(() => {
    setBreadcrumbs([
      { value: 'Home', label: 'Home' },
      { value: 'giveaway', label: 'Giveaway' },
      { value: 'sxsw', label: 'SXSW' },
      { value: 'claim', label: 'Claim' },
    ]);

    return () => {
      setBreadcrumbs([]);
    };
  }, []);

  return (
    <PageContainer  pageTitle='Claim Giveaway | SongBits'>
      <ImageContainer>
        <Image src={Banner} />
        <ImageGradient banner={Banner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={'they’re all '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="yours"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <HeaderTitle
                  text="!"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          <TextContent
            text="Congratulations, you’re about to claim your free SongBits!"
            fontSize="fz24"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />
          <Spacer height={10} />
          <TextInfo
            text="Use the button below to claim your free bits and they will instantly be added to your collection."
            fontSize="fz18"
            lineHeight="24px"
            fontWeight="regular"
            fontColor={theme.colors.white}
          />

          <Spacer height={10} />
          <FirstButton
            height={45}
            width={170}
            isLoading={loading}
            disabled={loading}
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                {loading ? (
                  <>
                    <Oval
                      color={theme.colors.yellow}
                      height={45 && 170 * 0.5}
                      secondaryColor={theme.colors.yellow50}
                    />
                  </>
                ) : (
                  <>
                    <ContentButton
                      text="claim"
                      fontSize="fz16"
                      fontWeight="bold"
                    />
                    &nbsp;
                    <ContentButton
                      text="now"
                      fontSize="fz16"
                      fontColor={theme.colors.yellow}
                      fontWeight="bold"
                    />
                  </>
                )}
              </div>
            }
            onClick={() => {
              claimGiveaway();
            }}
          />
        </Form>
      </Wrapper>

      <GiveawayError
        isOpen={claimBitsError}
        onClose={() => {
          navigate('/');
        }}
      />
    </PageContainer>
  );
};

export default WithRouteAnimation(Giveaway, true);

import Typography from 'common/components/Typography/Typography';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PurchaseDetailsProps {
  label: string;
  value: string;
}

const PurchaseDetails: FC<PurchaseDetailsProps> = ({ label, value }) => {
  return (
    <Container>
      <Typography text={label} fontWeight="bold" lineHeight="25px" />
      <Typography text={value} fontWeight="light" />
    </Container>
  );
};

const Container = styled.div``;

export default PurchaseDetails;

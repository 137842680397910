import React from 'react';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import styled from 'styled-components';

import Banner from '../assets/artist-waitlist.png';

import {
  ArtistApplicationWrapper,
  ArtistApplicationContent,
  HeadContainer,
  HeadingSection,
  StrapLineTypographyContainer,
  StrapLineTypography,
} from '../../artist/pages/styled/ArtistApplication.styled';

import {
  FirstTitilePart,
  TextContainer,
  TextContent,
  TextContentHeader,
} from 'modules/prereg/pages/styled/LabelsIntro.styled';
import { md } from 'utils/constants';

const ArtistApplicationWaitlist = () => {
  return (
    <PageContainer  pageTitle='Artist Application | SongBits' reduceFooter={`24`}>
      <ImageContainer>
        <ImageGradient banner={Banner}>
          <Image src={Banner} />
        </ImageGradient>
      </ImageContainer>
      <ArtistApplicationWrapper>
        <ArtistApplicationContent>
          <HeadContainer>
            <HeadingSection>
              <StrapLineTypographyContainer>
                <FirstTitilePart>
                  <StrapLineTypography
                    text={<span>hang in </span>}
                    fontSize="fz100"
                    fontWeight="bold"
                    letterSpacing="-0.03em"
                  />
                </FirstTitilePart>
                <div style={{ display: 'flex' }}>
                  <StrapLineTypography
                    text="there!"
                    fontSize="fz100"
                    fontWeight="bold"
                    fontColor={theme.colors.yellow}
                    letterSpacing="-0.03em"
                  />
                  <StrapLineTypography
                    text="."
                    fontSize="fz100"
                    fontWeight="bold"
                    fontColor={theme.colors.yellow}
                  />
                  <StrapLineTypography
                    text="."
                    fontSize="fz100"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                </div>
              </StrapLineTypographyContainer>
              <Spacer height={45} />
              <TextContainer>
                <TextContentHeader>
                  <Typography
                    fontSize="fz24"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                    letterSpacing="-1px"
                    text="We'll be right back at you"
                  />
                </TextContentHeader>
                <Spacer height={40} />
                <TextContentMain>
                  <TextContent>
                    Thanks for your Artist or Label application, one of our
                    specialist A&R crew will be in touch very soon. More
                    questions? Reach us in the chat below. See you backstage!
                  </TextContent>
                </TextContentMain>
                <Spacer height={30} />
              </TextContainer>
            </HeadingSection>
          </HeadContainer>
        </ArtistApplicationContent>
      </ArtistApplicationWrapper>
    </PageContainer>
  );
};

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  right: 40;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.1)
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 90%;
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  @media (min-width: ${md}px) {
    visibility: visible;
  }
`;

export const TextContentMain = styled.div`
  font-size: 14px;
  width: 550px;

  @media (min-width: ${md}px) {
    font-size: 18px;
  }
`;

export default ArtistApplicationWaitlist;

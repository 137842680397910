import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';

import theme from 'theme/theme';

export interface StepButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  leftIcon?: JSX.Element;
}

const StepButton: FC<StepButtonProps> = ({ text, leftIcon, ...rest }) => {
  return (
    <Button {...rest}>
      <div>{leftIcon}</div>
      <ButtonText text={text} fontSize="fz16" fontWeight="light" />
    </Button>
  );
};

const Button = styled.button`
  //Reset default syles
  background: transparent;
  cursor: pointer;

  //Main styles
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  gap: 10px;

  padding: 13px 17px;

  border: 3px solid ${theme.colors.yellow};
  border-radius: 20px;

  text-align: left;
`;

const ButtonText = styled(Typography)`
  overflow: hidden;
`;

export default StepButton;

import { gql } from '@apollo/client';

export const FCA_TRACKING = gql`
  mutation CreateFCATrackingRecord($status: FcaTrackingStatus!, $userId: UUID!) {
    createFcaTracking(
      input: {
        fcaTracking: {
          type: PERSONAL_RISK_WARNING, 
          status: $status,
          userId: $userId
        }
      }
    ) {
      fcaTracking {
        id
        status
        time
        type
      }
    }
  }
`;

export const KYC_DATA = gql`
  mutation MyMutation {
    veriffKycUserData(input: {}) {
      json
    }
  }
`;

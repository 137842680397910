import { MusicPlatformsTypes, PurchasePerksType } from 'modules/artist/types';

// Models
export type CREATE_USER_MODEL = {
  clientMutationId: string;
  user: any;
};

export type LOGIN_MODEL = {
  clientMutationId: null;
  auth: {
    accessToken: string;
    refreshToken: string;
    isEmailConfirmed: boolean;
  };
};
export type CHECK_WAITLIST_MODEL = {
  boolean: boolean;
};

export type SongDataType = {
  id: string;
  artistName: string;
  artistIntro: string;
  artistId: string;
  description: string;
  title: string;
  audioLengthMs: number;
  actualBitPrice: number;
  editions: number;
  remainingBits: number;
  soldBits: number;
  soldBitsInverted: number;
  soldPercent: string | number;
  artwork: string;
  songId: string;
  releaseAt: string;
  songUrl: string;
  musicLinks: MusicPlatformsTypes;
  songSlug: string;
  artistSlug?: string;
  status: string;
  songType?: string;
  background?: string;
  live: boolean;
  explicitContent: boolean;
  perks: PurchasePerksType[];
};

type RegisterResponseType = {
  tokenId: string;
  auser: {
    id: string;
  };
};

// Mutations and Variables
export type CREATE_USER_MUTATION = {
  registerUser: {
    results: RegisterResponseType[];
  };
};

export type CREATE_USER_MUTATION_WAITLIST = {
  registerUserWaitlist: CREATE_USER_MODEL;
};

export type CREATE_USER_VARIABLES = {
  email: string;
  username: string;
  password: string;
  country: number;
  county?: number;
  waitlist_token_id?: string;
  redirectAfterAuth?: string;
  artist?: string;
  song?: string;
  utm?: string;
};

export type CHECK_WAITLIST_MUTATION = {
  isWaitlistTokenValid: CHECK_WAITLIST_MODEL;
};

export type CHECK_WAITLIST_MUTATION_VARIABLES = {
  emailAddress: string;
  tokenId: string;
};

export type CREATE_USER_VARIABLES_WAITLIST = {
  email: string;
  username: string;
  password: string;
  country: number;
  tokenId: string;
  artist?: string;
  song?: string;
};

export type AUTHENTICATE_MUTATION = {
  authenticate: LOGIN_MODEL;
};

export type AUTHENTICATE_EMAIL_MUTATION = {
  authenticateEmail: LOGIN_MODEL;
};

export type AUTHENTICATE_VARIABLES = {
  username: string;
  password: string;
};

export type AUTHENTICATE_EMAIL_VARIABLES = {
  email: string;
  password: string;
};

export type REFRESH_MUTATION = {
  refreshToken: string;
};

export type REFRESH_VARIABLES = {
  refreshToken: string;
};

export type SEND_VERIFICATION_EMAIL_MUTATION = {
  userSendConfirmationEmail: CREATE_USER_MODEL;
};

export type SEND_VERIFICATION_EMAIL_VARIABLES = {
  email: string;
};

export type RESEND_VERIFICATION_EMAIL_MUTATION = {
  responseType: {
    message: string;
    status: string;
  };
};

export type RESEND_VERIFICATION_EMAIL_VARIABLES = {
  expiredTokenId: string | null | undefined;
  email: string | null;
};

export type VERIFICATION_EMAIL_CONFIRM_MUTATION = {
  userConfirmationEmail: CREATE_USER_MODEL;
};

export type CONFIRM_EMAIL_DATA = {
  userConfirmationEmail: {
    auth: {
      accessToken: string;
      refreshToken: string;
      isEmailConfirmed: boolean;
      isInternal: boolean;
      redirectAfterAuth: string;
      redirectToGiveaway: boolean;
      kycStatus: string | null;
      fcaStep: string | null;
    };
  };
};

export type CONFIRM_EMAIL_VARIABLES = {
  token: string;
};

export type CHANGE_EMAIL_MUTATION = {
  userSendConfirmationEmail: CREATE_USER_MODEL;
};

export type CHANGE_EMAIL_VARIABLES = {
  userId: string;
  email: string;
};

export type CHANGE_USERNAME_MODEL = {
  clientMutationId: string;
};

export type CHANGE_USERNAME_MUTATION = {
  userUpdateUsername: CHANGE_USERNAME_MODEL;
};

export type CHANGE_USERNAME_VARIABLES = {
  userId: string;
  username: string;
};

export type FORGOT_PASSWORD_MUTATION = {
  userSendResetPassword: CREATE_USER_MODEL;
};

export type FORGOT_PASSWORD_VARIABLES = {
  email: string;
};

export type RESET_PASSWORD_MUTATION = {
  userUpdatePasswordByToken: CREATE_USER_MODEL;
};

export type RESET_PASSWORD_VARIABLES = {
  tokenValue: string;
  password: string;
};

export enum ErrorsResponses {
  name = 'users_username_key',
  email = 'users_email_key',
}

import * as React from 'react';

import { IconProps } from '../common.types';

const ImportOutlinedIcon = ({ width = 27, height = 27 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M16.375 10.125V10.625H16.875H21.4998C21.9922 10.625 22.2398 11.2196 21.8898 11.5697L21.8897 11.5698L13.8689 19.5959C13.6687 19.7961 13.3366 19.7961 13.1364 19.5959L13.1362 19.5957L5.10492 11.5697C5.10488 11.5697 5.10484 11.5696 5.1048 11.5696C4.75493 11.2195 5.00257 10.625 5.49492 10.625H10.125H10.625V10.125V1.26562C10.625 0.8404 10.9654 0.5 11.3906 0.5H15.6094C16.0346 0.5 16.375 0.8404 16.375 1.26562V10.125ZM26.5 19.8281V25.7344C26.5 26.1596 26.1596 26.5 25.7344 26.5H1.26562C0.8404 26.5 0.5 26.1596 0.5 25.7344V19.8281C0.5 19.4029 0.8404 19.0625 1.26562 19.0625H8.79465L11.2322 21.5C12.4874 22.7553 14.5126 22.7553 15.7678 21.5L18.2053 19.0625H25.7344C26.1596 19.0625 26.5 19.4029 26.5 19.8281ZM20.9609 24.4688C20.9609 23.6125 20.2625 22.9141 19.4062 22.9141C18.55 22.9141 17.8516 23.6125 17.8516 24.4688C17.8516 25.325 18.55 26.0234 19.4062 26.0234C20.2625 26.0234 20.9609 25.325 20.9609 24.4688ZM24.3359 24.4688C24.3359 23.6125 23.6375 22.9141 22.7812 22.9141C21.925 22.9141 21.2266 23.6125 21.2266 24.4688C21.2266 25.325 21.925 26.0234 22.7812 26.0234C23.6375 26.0234 24.3359 25.325 24.3359 24.4688Z"
      stroke="white"
    />
  </svg>
);

export default ImportOutlinedIcon;

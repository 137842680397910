import React, { FC } from 'react';
import styled from 'styled-components';
import ArtistStat from '../../components/StatContainer/StatContainer';

import { WaitlistDashboardData } from 'services/types';

interface ArtistStatSectionProps {
  data: WaitlistDashboardData | undefined;
}

const WaitlistStatSection: FC<ArtistStatSectionProps> = ({data}) => {
  

  return (
    <StatContainer>
      <ArtistStat value={data?.total.toString() ?? '0'} desc="total signups" analytic={``} />
      <BottomLine />
      <ArtistStat value={data?.fan.toString() ?? '0'} desc="fan signups" analytic={``} />
      <BottomLine />
      <ArtistStat value={data?.artist.toString() ?? '0'} desc="artist signups" analytic={``} />
    </StatContainer>
  );
};

const StatContainer = styled.div``;

const BottomLine = styled.div`
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export default WaitlistStatSection;

import styled from 'styled-components';

import TextInput from 'common/components/TextInput/TextInput';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Media from 'common/components/Media/Media';
import theme from 'theme/theme';

import { lg, md, sm, xlg } from 'utils/constants';

export const ArtistApplicationWrapper = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 100px;

  @media (min-width: ${md}px) {
    padding-top: 144px;
  }

  @media (min-width: ${lg}px) {
    align-items: flex-start;
    padding-left: 165px;
  }

  @media (min-width: ${xlg}px) {
    justify-content: center;
    align-items: center;
  }
`;

export const ArtistApplicationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${sm}px) {
    width: initial;
  }
`;

export const HeadingSection = styled.div`
  @media (min-width: ${md}px) {
    min-width: 400px;
  }
`;

export const HeadContainer = styled.div`
  flex: 1;
  flex-direction: column;

  padding: 0 34px 0 34px;
  z-index: 1;

  @mdeia (min-width: ${sm}px) {
    display: flex;
    padding: 48px 48px 0 48px;
  }
`;

export const Form = styled.div`
  padding: 0 34px;
  z-index: 1;

  @media (min-width: 768px) {
    width: 445px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 60%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const TextContentHeading = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

export const FirstButton = styled(Button)`
  padding: 0 40px;
  font-family: 'HKGrotesk-Black';
  display: block;
  margin: 38px auto 0;

  @media (min-width: 768px) {
    margin: 52px 0;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const FieldLabel = styled.div`
  margin-top: 10px;
  margin-left: 5px;
`;

export const AreaCode = styled(TextInput)`
  width: 68px;
  height: 45px;
  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px;
  display: inline-block;
  margin-top: 20px;
  margin-right: 10px;
  & > input {
    width: 68px;
    height: 45px;
    padding-left: 20px;
  }
`;

export const PhoneInputContainer = styled(InputContainer)`
  padding-bottom: 25px;
`;

export const PhoneInput = styled(TextInput)`
  display: contents;
  padding-bottom: 25px;
`;

export const FieldEdit = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 25px;

  @media (min-width: 768px) {
    right: 30px;
  }
`;

export const MediaContainer = styled.div`
  position: relative;

  @media (min-width: 768px) {
    width: 80%;
  }
`;

export const InfoButton = styled.div`
  position: absolute;
  right: 21px;
  top: 5px;
`;

export const MediaSection = styled(Media)`
  width: 50%;
`;

export const StrapLineTypography = styled(Typography)`
  flex-shrink: 0;
  font-family: 'HKGrotesk-Black';
  font-size: 38px;

  @media (min-width: 992px) {
    font-size: 100px;
    line-height: 80px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const HeadContent = styled(StrapLineTypography)`
  flex-shrink: 0;
  font-family: 'HKGrotesk-Black';
`;

export const CustomSpacer = styled.div`
  height: 15px;

  @media (min-width: 768px) {
    height: 45px;
  }
`;

export const LabelSpacer = styled.div`
  height: 20px;

  @media (min-width: 768px) {
    height: 30px;
  }
`;

export const CustomTextInput = styled(TextInput)`
  height: 54px;

  @media (min-width: 768px) {
    height: 70px;
  }
`;

import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import UserProfile from 'common/icons/UserProfile.icon';


const InvestorType = ({
  option,
  label,
  text,
  value,
  setValue,
}: {
  option: string | number | boolean | any;
  label: string;
  text: string | null | undefined;
  value: string | number | boolean;
  setValue: (value: any) => void;
}) => {

  return (
    <div>
    <Container>
      <InvestorTypeButton
        label={
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                justifyItems: 'start',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
                paddingLeft: '13px',
                paddingRight: '18px',
              }}>
              <UserProfile color={theme.colors.yellow} />
              <LabelText text={label} bold={value === option ? true : false} />
              <div
                style={{
                  display: 'flex',
                  justifyItems: 'end',
                  justifyContent: 'end',
                  marginLeft: 'auto',
                }}>
                {value === option ? <ActionText text={'Selected'} fontColor={theme.colors.yellow} /> : value === '' ? <ActionText text={'View'} fontColor={theme.colors.yellow} /> : <ActionText text={'Select'} fontColor={theme.colors.yellow} /> }
              </div>
            </div>
          </>
        }
        onClick={() => {
          setValue(option);
        }}
        borderColor={value === option ? theme.colors.yellow : theme.colors.white}
      />
      {value === option ? (
        <div style={{paddingLeft: '10px', paddingRight: '10px', paddingTop: '12px'}}>
          {text ? <>
          <Typography
            text={text}
            fontColor={theme.colors.white}
            fontSize="fz14"
            lineHeight="18px"
            fontWeight="light"
          />
          </> : <></>}
        </div>
      ) : (
        <></>
      )}
    </Container>
    </div>
  );
};

const Container = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-direction: column;
`;

const InvestorTypeButton = styled(Button)<{ fontWeight?: string }>`
  border-radius: 50px;
  width: 100%;
  height: 48px;
  border-width: 1px;
  align-items: normal;
`;

export const ErrorContainer = styled.div`
  position: relative;
  bottom: -10px;
  left: 10px;
`;

const LabelText = styled(Typography)<{ bold?: boolean }>`
  font-family: ${(props) => (props.bold ? 'HKGrotesk-Bold' : 'HKGrotesk-Light')};
  font-size: 17px;
  line-height: 30px;
  letter-spacing: -0.03em;
`;

const ActionText = styled(Typography)`
  font-family: HKGrotesk-Bold;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.03em;
`;

export default InvestorType;

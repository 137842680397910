import * as React from 'react';

import { IconProps } from '../common.types';

const LiveChat = ({ width = 52, height = 52 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0215 52.0431C40.3928 52.0431 52.0431 40.3928 52.0431 26.0215C52.0431 11.6502 40.3928 0 26.0215 0C11.6502 0 0 11.6502 0 26.0215C0 40.3928 11.6502 52.0431 26.0215 52.0431Z"
      fill="#FFD600"
    />
    <path
      d="M34.4672 14.3803H17.576C15.8111 14.3803 14.3804 15.8111 14.3804 17.576V34.4671C14.3804 36.232 15.8111 37.6628 17.576 37.6628H34.4672C36.2321 37.6628 37.6628 36.232 37.6628 34.4671V17.576C37.6628 15.8111 36.2321 14.3803 34.4672 14.3803Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.6956 29.0495V40.9814L30.1303 37.5486L37.6956 29.0495Z"
      fill="black"
    />
    <path
      d="M19.1738 32.1845C23.739 34.6193 28.3041 34.6193 32.8693 32.1845"
      stroke="#FFD600"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LiveChat;

import * as React from 'react';

import { IconProps } from '../common.types';

const NextSongIcon = ({ width = 25, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4326 11.631C9.30934 10.8337 9.30934 9.16626 10.4326 8.36904L17.1789 3.58083C18.5033 2.64088 20.3365 3.5878 20.3365 5.21179L20.3365 14.7882C20.3365 16.4122 18.5032 17.3591 17.1789 16.4192L10.4326 11.631Z"
      fill="white"
    />
    <path
      d="M2.29793 11.631C1.1747 10.8337 1.1747 9.16626 2.29794 8.36904L9.04428 3.58083C10.3686 2.64088 12.2018 3.5878 12.2018 5.21179L12.2018 14.7882C12.2018 16.4122 10.3686 17.3591 9.04427 16.4192L2.29793 11.631Z"
      fill="white"
    />
  </svg>
);

export default NextSongIcon;

import { UnsupportedChainIdError } from '@web3-react/core';
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import {
  NETWORK_CHAIN_ID,
  NETWORK_CHAIN_NAME,
  INFURA_PROJECT_ID,
} from './constants';

export const injected = new InjectedConnector({ supportedChainIds: [NETWORK_CHAIN_ID] });

export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: `https://${NETWORK_CHAIN_NAME}.infura.io/v3/${INFURA_PROJECT_ID}` },
  qrcode: true
});

export const walletlink = new WalletLinkConnector({
  url: `https://${NETWORK_CHAIN_NAME}.infura.io/v3/${INFURA_PROJECT_ID}`,
  appName: 'RuggedRebellion',
  supportedChainIds: [NETWORK_CHAIN_ID]
});

export const getWalletErrorMessage = (err: any) => {
    if (err instanceof NoEthereumProviderError) {
        return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
    } else if (err instanceof UnsupportedChainIdError) {
        return `Change Wallet Network to ETHEREUM ${NETWORK_CHAIN_NAME.toUpperCase()}.`;
    } else if (err instanceof UserRejectedRequestErrorInjected ||
        err instanceof UserRejectedRequestErrorWalletConnect ||
        err.toString().includes("denied account authorization")) {
        return 'Please authorize this website to access your Ethereum account.';
    } else if (err) {
        return 'An unknown error occurred. Check the console for more details.';
    } else {
        return false;
    }
}
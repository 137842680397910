import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

import { escToClose } from 'utils/functions';

import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout'
import RiskSummaryText from './RiskSummaryText';


type ModalProps = {
  onClose: () => void;
};

const RiskSummaryModal = ({ onClose }: ModalProps) => {

  useEffect(escToClose(onClose), []);

  return (
    <ModalLayout onClose={onClose} title={'risk summary'}>
      <ContentContainer className={'fca-risk-summary-modal'}>
        <RiskSummaryText />
      </ContentContainer>
    </ModalLayout>
  );
};

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: inherit;
    overflow-y: scroll !important;
    height: 400px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      height: 40px;
      background-color: ${theme.colors.yellow50};
    }
`;

export default RiskSummaryModal;

import React from 'react';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import AccountBanner from '../assets/create-account.jpg';

import {
  ContentButton,
  FirstButton,
  Form,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  TextContent,
  Wrapper,
  HeaderTitleExtraContainer,
  TextInfo
} from './styled/JoinWaitlistThanks.styled';

import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';
import Spacer from 'common/components/Spacer/Spacer';

const JoinWaitListThanks = () => {
  const viewport = useViewport();

  return (
    <PageContainer pageTitle='Joined Waitlist | SongBits'>
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={'you’re on the '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="list"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <HeaderTitle
                  text="!"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          <TextContent
            text="Woop Woop! You're now officially on the waitlist!"
            fontSize="fz24"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />
          <Spacer height={10} />
          <TextInfo
            text="We'll be in touch via email shortly with more instructions. In the meantime please join the Discord community and social media below."
            fontSize="fz18"
            lineHeight='24px'
            fontWeight="regular"
            fontColor={theme.colors.white}
          />

          <FirstButton
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="join"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="discord"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {}}
          />
        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(JoinWaitListThanks);

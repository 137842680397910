import React from 'react';

import {
  Container,
  ContentContainer,
  DescriptionContainer,
} from './BenefitItem';
import styled from 'styled-components';

const BenefitItemPlaceholder = () => {
  return (
    <Container>
      <TextPlaceholder
        className="placeholder-wave placeholder bg-secondary"
        style={{
          width: 25,
          height: 25,
        }}
      />

      <ContentContainer>
        <DescriptionContainer>
          <TextPlaceholder
            className="placeholder-wave placeholder bg-secondary"
            style={{
              width: 100,
            }}
          />
          <TextPlaceholder
            className="placeholder-wave placeholder bg-secondary"
            style={{
              width: 300,
              height: 70,
            }}
          />
        </DescriptionContainer>

        <ButtonContainer>
          <TextPlaceholder
            className="placeholder-wave placeholder bg-secondary"
            style={{
              width: 25,
              height: 25,
            }}
          />
          <TextPlaceholder
            className="placeholder-wave placeholder bg-secondary"
            style={{
              width: 120,
              height: 25,
            }}
          />
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

const TextPlaceholder = styled.span`
  font-size: 12px;
  line-height: 12px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 19px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

const ButtonContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default BenefitItemPlaceholder;

import React, { useEffect } from 'react';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import AccountBanner from '../assets/giveaway-background-faded.jpg';

import Twitter from 'common/icons/Telegram.icon';
import Discord from 'common/icons/Discord.icon';
import TikTok from 'common/icons/TikTokFooter.icon';
import InstagramSmallWhite from 'common/icons/InstagramSmallWhite.icon';

import {
  Form,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  TextContent,
  Wrapper,
  HeaderTitleExtraContainer,
  TextInfo,
  IconButton,
  IconsContainer
} from './styled/GiveawayWaitlistThanks.styled';

import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';
import Spacer from 'common/components/Spacer/Spacer';

import { useStoreActions } from 'store/store';

const GiveawayWaitlistThanks = () => {
  const viewport = useViewport();

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  useEffect(() => {
    setBreadcrumbs([
      { value: 'Home', label: 'Home' },
      { value: 'giveaway', label: 'Giveaway' },
      { value: 'sxsw', label: 'SXSW' },
      { value: 'thanks', label: 'Thanks' },
    ]);

    return () => {
      setBreadcrumbs([]);
    };
  }, []);
  
  return (
    <PageContainer>
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={'bring on the '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="freebies"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <HeaderTitle
                  text="!"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          <TextContent
            text="OK, this is going great..."
            fontSize="fz24"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />
          <Spacer height={10} />
          <TextInfo
            text="We'll be in touch via email shortly with more instructions. In the meantime please join the Discord community or follow us on social media below."
            fontSize="fz18"
            lineHeight="24px"
            fontWeight="regular"
            fontColor={theme.colors.white}
          />
          <Spacer height={10} />
          <IconsContainer>
            <a
              href="https://twitter.com/SongBitsfans"
              target="_blank"
              rel="noreferrer">
              <IconButton label={<Twitter />} onClick={() => {}} />
            </a>
            {/*<a href="" target="_blank" rel="noreferrer">
                  <IconButton
                    label={<Telegram />}
                    onClick={() => {}}
                  />
                </a>*/}
            <a
              href="https://discord.gg/GHBH9YhjDU"
              target="_blank"
              rel="noreferrer">
              <IconButton label={<Discord />} onClick={() => {}} />
            </a>
            <a
              href="https://www.tiktok.com/@songbits.fans"
              target="_blank"
              rel="noreferrer">
              <IconButton label={<TikTok />} onClick={() => {}} />
            </a>
            <a
              href="https://www.instagram.com/songbits.fans/"
              target="_blank"
              rel="noreferrer">
              <IconButton label={<InstagramSmallWhite />} onClick={() => {}} />
            </a>
          </IconsContainer>
        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(GiveawayWaitlistThanks);

import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';

import { lg, md, sm, xlg } from 'utils/constants';

export const ShadowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;

  flex-grow: 1;

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  );

  padding: 80px 20px 0 53px;

  @media (min-width: ${sm}px) {
    padding: 135px 20px 0 100px;
  }

  @media (min-width: ${md}px) {
    padding: 145px 20px 0 190px;
  }

  @media (min-width: ${xlg}px) {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 30%,
      rgba(255, 255, 255, 0) 80%
    );

    padding-top: 0;
    justify-content: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 6px 14px 0 5px;

  @media (min-width: ${sm}px) {
    padding: 35px 20px 0 15px;
  }

  @media (min-width: ${md}px) {
    padding: 45px 20px 0 15px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  dlex-direction: row;
  justify-items: flex-start;
`;

export const Title = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-style: normal;
  font-size: 46px;
  font-weight: 900;
  letter-spacing: -0.045em;

  @media (min-width: ${sm}px) {
    font-size: 100px;
    line-height: 80px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const TextContent = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;

  margin-bottom: 18px;

  @media (min-width: ${sm}px) {
    font-size: 18px;
    line-height: 24px;

    margin-bottom: 24px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;
  gap: 15px;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    justify-content: start;

    position: initial;
    gap: 0;
  }

  @media (min-width: ${md}px) {
    margin-top: 10px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: ${md}px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: ${md}px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: -55px;
  left: 0px;
  width: 135%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: ${(props) => props.banner && `url(${props.banner})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;

  @media (min-width: ${md}px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 0.8) 20%,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(
      0deg,
      #000000 10%,
      rgba(0, 0, 0, 0.5) 30%,
      rgba(0, 0, 0, 0) 42%
    );
    width: 100%;
    background-size: cover;
  }
`;

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  padding: 70px 20px 15px 57px;

  @media (min-width: ${sm}px) {
    min-width: 700px;
    letter-spacing: -0.05em;
  }

  @media (min-width: ${md}px) {
    margin-left: 130px;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 65px;
  line-height: 55px;
  letter-spacing: -0.03em;

  @media (min-width: ${md}px) {
    font-size: 100px;
    line-height: 80px;
    letter-spacing: -0.05em;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  position: relative;
  // display: flex;
  left: -5px;

  margin-bottom: 320px;

  @media (min-width: ${md}px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  padding: 10px 0 0 0;

  z-index: 1;

  @media (min-width: ${sm}px) {
    padding: 48px 48px 0 48px;
  }

  @media (min-width: ${md}px) {
    padding-top: 135px;
    padding-left: 70px;
  }

  @media (min-width: ${lg}px) {
    padding-top: 75px;
    padding-left: 13px;
  }

  @media (min-width: ${xlg}px) {
    padding-top: 0;
    padding-left: 13px;

    justify-content: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 0px;
  margin-bottom: 10px;
  gap: 15px;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    justify-content: start;

    position: initial;
    gap: 0;
    padding-left: 48px;
  }

  @media (min-width: ${md}px) {
    margin-top: 10px;
    padding-left: 185px;
  }
`;


export const TextLinkContainer = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #fff;

  @media (min-width: ${sm}px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: ${md}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const TextLink = styled.a`
padding-left: 5px;
padding-right: 0;
display: in
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
import React from 'react';
import styled from 'styled-components';

import { TypographyFontSizes, TypographyFontWeight } from 'common/common.types';
import theme from 'theme/theme';

type IconCaptionProps = {
  icon: JSX.Element;
  text: string;
  fontColor?: string;
  className?: string;
  fontSize?: keyof typeof TypographyFontSizes;
  fontWeight?: keyof typeof TypographyFontWeight;
  letterSpacing?: string;
};

const IconCaption = ({
  icon,
  text,
  className,
  fontColor = theme.colors.white,
  fontSize = 'fz18',
  fontWeight = 'regular',
  letterSpacing = 'auto',
}: IconCaptionProps) => {
  return (
    <Container
      fontSize={fontSize}
      fontColor={fontColor}
      fontWeight={fontWeight}
      className={className}
      letterSpacing={letterSpacing}>
      {icon}
      {text}
    </Container>
  );
};

const Container = styled.div<{
  fontColor: string;
  fontSize: keyof typeof TypographyFontSizes;
  fontWeight: keyof typeof TypographyFontWeight;
  letterSpacing: string;
}>`
  color: ${(props) => props.fontColor};
  font-weight: 500;
  letter-spacing: ${(props) => props.letterSpacing};
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${(props) => props.fontSize.replace('fz', '')}px;
`;

export default IconCaption;

import React from 'react';
import styled from 'styled-components';

import MyCollectionOptionsPlaceholder from './MyCollectionsOptions';
import Spacer from '../Spacer/Spacer';

const MyCollectionCardPlaceholder = () => {
  const TextPlaceHolder = (
    <TextPlaceholder
      className={'placeholder-wave placeholder bg-secondary col-11'}>
      &nbsp;
    </TextPlaceholder>
  );

  return (
    <> 
      <Spacer height={25}></Spacer>
      <CardContainer className="card-placeholder-container">
        <Image className="placeholder-wave placeholder fs-18 lh-24 bg-secondary col-11" />
        <InfoWrapper>
          <FirstColumn style={{ width: '100%', paddingLeft: '10px' }}>
            <SongProfileButtonPlaceholder
              className={'placeholder-wave placeholder bg-secondary col-11'}>
              &nbsp;
            </SongProfileButtonPlaceholder>
            <SongProfileButtonPlaceholder
              className={'placeholder-wave placeholder bg-secondary col-11'}>
              &nbsp;
            </SongProfileButtonPlaceholder>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              {TextPlaceHolder}
              {TextPlaceHolder}
            </div>
          </FirstColumn>
          <MyCollectionOptionsPlaceholder />
        </InfoWrapper>
      </CardContainer>
    </>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 992px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;

`;

const Image = styled.span`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media (min-width: 992px) {
    width: 150px;
    height: 150px;
  }
`;

const SongProfileButtonPlaceholder = styled.span`
  font-size: 11px;
  line-height: 13.2px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 21.2px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 25.2px;
  }
`;

const TextPlaceholder = styled.span`
  font-size: 12px;
  line-height: 12px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 19px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export default MyCollectionCardPlaceholder;

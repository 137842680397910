import * as React from 'react';

import { IconProps } from '../common.types';

const PoundSign = ({ width = 10, height = 13 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.122 10.732H9.72V13H0.864V10.786C1.8 10.282 2.898 9.4 2.898 8.482V7.996H0.882V5.728H2.43C2.304 5.206 2.088 4.432 2.088 3.766C2.088 1.75 3.438 0.0939999 5.886 0.0939999C7.92 0.0939999 9.378 1.21 9.666 2.902L7.578 3.694C7.488 2.776 6.714 2.362 5.94 2.362C4.95 2.362 4.356 2.92 4.356 3.82C4.356 4.432 4.554 5.296 4.77 5.728H8.136V7.996H5.13V8.5C5.13 9.472 4.878 10.264 4.122 10.732Z"
      fill="white"
    />
  </svg>
);

export default PoundSign;

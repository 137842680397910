import * as React from 'react';

import { IconProps } from '../common.types';

const ArrowDownSmall = ({
  width = 10,
  height = 5,
  fill = '#FFD600',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99953 0.579648C10.0055 0.460058 9.95331 0.342383 9.85064 0.244215C9.74797 0.146047 9.60038 0.0726582 9.42993 0.0350204C9.25948 -0.00261734 9.07533 -0.00248269 8.905 0.0354044C8.73467 0.0732914 8.58732 0.146896 8.48498 0.245214L5.00353 3.49322L1.52208 0.245214C1.46006 0.18368 1.38015 0.131061 1.28708 0.0904726C1.194 0.0498838 1.08965 0.0221493 0.980207 0.00891055C0.870761 -0.00432818 0.758442 -0.00280211 0.649896 0.0133984C0.541351 0.029599 0.438785 0.0601447 0.34827 0.103228C0.257755 0.146311 0.18113 0.201057 0.122928 0.264224C0.064727 0.327391 0.0261317 0.397696 0.00942872 0.470979C-0.00727424 0.544262 -0.00174628 0.619033 0.025685 0.690867C0.0531164 0.762701 0.101894 0.830138 0.169131 0.889188L4.32705 4.76819C4.40401 4.83989 4.50553 4.89833 4.62319 4.93865C4.74084 4.97897 4.87123 5 5.00353 5C5.13583 5 5.26622 4.97897 5.38387 4.93865C5.50152 4.89833 5.60304 4.83989 5.68 4.76819L9.83793 0.889188C9.93739 0.799225 9.99373 0.691325 9.99953 0.579648Z"
      fill={fill}
    />
  </svg>
);

export default ArrowDownSmall;

import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import MinusButton from 'common/icons/MinusButton.icon';
import PlusButton from 'common/icons/PlusButton.icon';
import theme from 'theme/theme';

import Tip from 'common/components/Tip/Tip';

import Data from 'modules/library/assets/MOCK_API_DATA';
import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';
import ArrowDownSmall from 'common/icons/ArrowDownSmall';

type NFTStatsProps = {
  spacing: string;
};

const NFTStats = ({spacing}: NFTStatsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const viewport = useViewport();

  const modalHandle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) (window as any).gtag('event', 'buy_nft_stats', {});
  }, [isOpen]);

  return (
    <NftStatisticContainer spacing={spacing}>
      <StatsButtonWrapper>
        <StatsButton
          label={
            !isOpen ? (
              <ButtonContainer>
                {viewport.width >= xs && <PlusButton />}

                <TextContent
                  text={
                    viewport.width < xs
                      ? 'blockchain stats for nerds'
                      : 'Stats for Nerds'
                  }
                  fontSize="fz14"
                  lineHeight={'24px'}
                  fontWeight={viewport.width < xs ? 'light' : 'bold'}
                  letterSpacing="-0.03"
                  fontColor={theme.colors.yellow}
                />

                {viewport.width < xs && (
                  <ArrowDownContainer open={isOpen}>
                    <ArrowDownSmall />
                  </ArrowDownContainer>
                )}
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                {viewport.width >= xs && <MinusButton />}

                <TextContent
                  text={
                    viewport.width < xs
                      ? 'blockchain stats for nerds'
                      : 'Stats for Nerds'
                  }
                  fontSize="fz14"
                  lineHeight={'24px'}
                  fontWeight={viewport.width < xs ? 'light' : 'bold'}
                  letterSpacing="-0.03"
                  fontColor={theme.colors.yellow}
                />

                {viewport.width < xs && (
                  <ArrowDownContainer open={isOpen}>
                    <ArrowDownSmall />
                  </ArrowDownContainer>
                )}
              </ButtonContainer>
            )
          }
          onClick={modalHandle}
        />
        <Tip
          text={
            <div>
              <Typography
                text={
                  "We use blockchain technology to register your purchase. These stats contain the data regarding the song's smart contract"
                }
                fontSize={'fz14'}
                lineHeight={'18px'}
              />
            </div>
          }
          width={210}
          marginBottom={viewport.width < 576 ? 5 : 3}
          top={20}
          left={10}
          position="right"
          gtag_event="buy_help_text_nft_stats"
        />
      </StatsButtonWrapper>
      <NftStatisticModal isVisible={isOpen}>
        <StatsItemRow>
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text="Crypto Chain"
          />
          <StatsItemText fontSize="fz14" fontWeight="light" text={`ETH`} />
        </StatsItemRow>
        <StatsItemRow>
          <StatsItemText fontSize="fz14" fontWeight="light" text="Token ID" />
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text={`${Data.tokenID}`}
          />
        </StatsItemRow>
        <StatsItemRow>
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text="Contract Address"
          />
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text={`${Data.contractAddress}`}
          />
        </StatsItemRow>
        <StatsItemRow>
          <StatsItemText fontSize="fz14" fontWeight="light" text="Token" />
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text={`${Data.token}`}
          />
        </StatsItemRow>
        <StatsItemRow>
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text="Token Standard"
          />
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text={`${Data.tokenStandard}`}
          />
        </StatsItemRow>
        {/*<StatsItemRow>
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text="Transaction history"
          />
          <Link to="">
            <StatsItemText fontSize="fz14" fontWeight="light" text="VIEW" />
          </Link>
        </StatsItemRow>*/}
        <StatsItemRow>
          <StatsItemText
            fontSize="fz14"
            fontWeight="light"
            text="Creator Fee On Resales"
          />
          <StatsItemText fontSize="fz14" fontWeight="light" text="5%" />
        </StatsItemRow>
      </NftStatisticModal>
    </NftStatisticContainer>
  );
};

const NftStatisticContainer = styled.div<{
  spacing: string;
}>`
  margin-top: 30px;
  padding-left: 17px;

  @media (min-width: 992px) {
    display: block;
    position: absolute;
    top: -70px;
    left: 40px;
    width: 260px;
    margin-top: 0px;
  }

  @media (min-width: 1200px) {
    top: ${(props) => props.spacing}px;
    margin-top: 0px;
  }
`;

const StatsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media (min-width: ${xs}px) {
    justify-content: flex-start;
  }
`;

const StatsButton = styled(Button)`
  align-items: center;
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const NftStatisticModal = styled.div<{
  isVisible: boolean;
}>`
  ${(props) => !props.isVisible && 'visibility: hidden'};
  min-width: 230px;
  min-height: 105px;
  padding-left: 25px;
  padding-right: 25px;
`;

const StatsItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`;

const StatsItemText = styled(Typography)`
  color: ${theme.colors.yellow};
  ${(props) => (props.fontSize = 'fz14')};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ArrowDownContainer = styled.div<{ open: boolean }>`
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export default memo(NFTStats);

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import TextInput from 'common/components/TextInput/TextInput';
import { useStoreActions } from 'store/store';

import { sm } from 'utils/constants';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../../modules/library/pages/components/ModalLayout/ModalLayout';
import RiskSummaryModal from 'common/components/FCA/RiskSummaryModal';


type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: (nextStep?: string) => void;
};

const FailedModal = ({ show, onClose, onContinue }: ModalProps) => {

  const { setFcaStep } = useStoreActions((state) => state.authentication);
  
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFCARiskSummaryModal, setShowFCARiskSummaryModal] = useState<boolean>(false);

  return (
    <>
      <SBModal
        className={'quiz-failed'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout titleBottomMargin='15px' title={'quiz'} onClose={onClose}>
            <ContentContainer className={'quiz-failed-modal'}>
              <BoldYellowText>
                Sorry, you did not pass.
              </BoldYellowText>

              <NormalText>
                {'Don’t worry you can re-take the quiz. If you\nwant to brush up on the risks you can use\nthe link below.'}
              </NormalText>

              <NormalText>
                {'Once you are ready, you can retake the quiz.'}
              </NormalText>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                  <FirstButton
                  className={'retake-quiz-risks-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.black}
                  borderColor={theme.colors.yellow}
                  label={
                    <ButtonText
                      text="read risk warnings"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={() => {
                    setShowFCARiskSummaryModal(true)
                  }}
                />
                <FirstButton
                  className={'retake-quiz-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="retake quiz"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    setFcaStep('appropriateness')
                    onContinue('appropriateness')
                  }}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />


      <SBModal
        className={'risk-summary'}
        isOpen={showFCARiskSummaryModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <RiskSummaryModal
            onClose={() => {
              setShowFCARiskSummaryModal(false);
            }}
          />
        }
      />

    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: -0.03em;
  white-space: pre-line;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default FailedModal;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

import { sm } from 'utils/constants';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';

import BottomLine from 'common/components/BottomLine/BottomLine';

import CheckboxEmpty from 'common/icons/CheckboxEmpty.icon';
import CheckboxChecked from 'common/icons/CheckboxChecked.icon';

type ModalProps = {
  show: boolean;
};

const ReadyToBuyModal = ({ show }: ModalProps) => {
  
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleSubmit() {
    setLoading(false)
  }
  return (
    <>
      <SBModal
        className={'ready-to-buy'}
        isOpen={showModal}
        width={'390px'}
        height='646px'
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout titleBottomMargin={'15px'} title={'ready to buy'} onClose={() => {}}>
            <ContentContainer className={'ready-to-buy-modal'}>
              <BoldYellowText>
                Financial Conduct Authority Regulation
              </BoldYellowText>

              <NormalText>
                The UK Financial Conduct Authority (FCA) requires us to ensure
                all new customers complete two simple steps to ensure you are
                able to participate in purchasing SongBits.
              </NormalText>

              <BoldText>
                You are welcome to look around but both steps below are required
                to be completed before you will be allowed to purchase any bits
              </BoldText>

              <div style={{display: 'flex', gap: '15px', paddingBottom: '5px', marginTop: '10px'}}>
                <div style={{display: 'flex', justifyContent: 'start',}}>
                    <CheckboxChecked />
                </div>
                <div style={{display: 'flex', justifyContent: 'start',}}>
                  <NormalText>Investment Readiness Questionnaire</NormalText>
                </div>
              </div>

              <div style={{paddingBottom: '20px'}}>
                <BottomLine />
              </div>

              <div style={{display: 'flex', gap: '15px', paddingBottom: '15px'}}>
                <div style={{display: 'flex', justifyContent: 'start',}}>
                    <CheckboxEmpty />
                </div>
                <div style={{display: 'flex', justifyContent: 'start',}}>
                  <NormalText>Investor Registration (KYC)</NormalText>
                </div>
              </div>

              <LightText>
                {'Should you have any questions regarding this \n process please contact the FCA at'}
              </LightText>

              <FCALink target='_blank' rel='noopener noreferrer' href="https://www.fca.org.uk/contact">https://www.fca.org.uk/contact</FCALink>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  isLoading={loading}
                  className={'ready-to-buy-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={handleSubmit}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: ${sm}px) {
    height: 600px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
`;

const BoldText = styled.p`
  font-family: HKGrotesk-Bold;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: -0.03em;
`;

const LightText = styled.p`
  font-family: HKGrotesk-Light;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  white-space: pre-line;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export const FCALink = styled.a`
font-family: HKGrotesk-Bold;
color: #fff;
font-size: 16px;
line-height: 20px;
letter-spacing: -0.03em;
text-decoration: none;

display: in

  &:hover {
    text-decoration: none;
  }
`;

export default ReadyToBuyModal;

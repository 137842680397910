import * as React from 'react';

import { IconProps } from '../common.types';

const Lock = ({ width = 18, height = 17 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x={1}
      y={8}
      width={14}
      height={8.556}
      rx={2}
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.111 8V4.889a3.889 3.889 0 1 1 7.778 0V8"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Lock;

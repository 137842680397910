import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

interface ModalProps {
  onClose: () => void;
  onCancel: () => void;
  onResume: () => void;
  onChangePaymentMethod?: () => void;
  header: JSX.Element;
}

const CancelPurchaseModal: FC<ModalProps> = ({
  onClose,
  onCancel,
  onResume,
  onChangePaymentMethod,
  header,
}) => {
  const viewport = useViewport();

  if (onChangePaymentMethod) {}
  
  return (
    <>
      <CloseButton onClick={onClose}>X</CloseButton>
      <BoldText
        text="are you sure?"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      {header}
      <Spacer height={10} />
      <Terminate text="Are you sure you wish to cancel your reservation?" />
      <Spacer height={viewport.width < 576 ? 80 : 100} />
      <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
        <FirstButton
          height={45}
          borderRadius={50}
          borderColor={theme.colors.white}
          labelColor={theme.colors.yellow}
          label={
            <ButtonText
              text="Change payment method"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.white}
            />
          }
          onClick={() => {
            if (onChangePaymentMethod) {onChangePaymentMethod()};
          }} />
        <PaymentRow
          style={{
            display: 'flex',
            maxWidth: viewport.width < 576 ? '300px' : '360px',
          }}>
          <FirstButton
            height={45}
            width={170}
            borderRadius={50}
            bgColor={theme.colors.white}
            labelColor={theme.colors.white}
            label={
              <ButtonText
                text="Yes, Cancel"
                fontSize="fz16"
                fontWeight="bold"
                fontColor={theme.colors.black}
              />
            }
            onClick={() => {
              onCancel();
            }}
          />
          <FirstButton
            height={45}
            width={170}
            borderRadius={50}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            label={
              <ButtonText
                text="No, Continue"
                fontSize="fz16"
                fontWeight="bold"
                fontColor={theme.colors.black}
              />
            }
            onClick={onResume}
          />
        </PaymentRow>
      </PaymentRow>
    </>
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  width: 300px;
  margin: 0 auto;
  border-width: 1px;

  left: -5px;
  position: relative;

  @media (min-width: 576px) {
    width: ${(props) => (props.width ? props.width : '357')}px;
  }
`;

const PaymentRow = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Terminate = styled(TextContent)`
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  padding-right: 80px;

  @media (min-width: 576px) {
    padding-right: 100px;
  }
`;

export default CancelPurchaseModal;

import React from 'react';
import styled from 'styled-components';

const MessageListPlaceholder = () => {
  return (
    <>
      {[...Array(4)].map((_, index) => (
        <Container key={index}>
          <Image className={'placeholder-wave placeholder bg-secondary '} />

          <InfoContainer>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-12'
              }>
              &nbsp;
            </span>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-12'
              }>
              &nbsp;
            </span>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-12'
              }>
              &nbsp;
            </span>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-12'
              }>
              &nbsp;
            </span>
          </InfoContainer>
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-bottom: 25px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 18px;
`;

const Image = styled.img`
  width: 126px;
  height: 126px;
  border-radius: 10px;

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

export default MessageListPlaceholder;

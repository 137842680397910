import React from 'react';
import styled from 'styled-components';

import { md, sm } from 'utils/constants';

import Button from 'common/components/Button/Button';
import SongRoyaltyCardPlaceholder from './SongRoyaltyCard';

const SongRoyaltyOverviewPlaceholder = () => {
  return (
    <SongBitsContainer>
      {[...Array(3)].map((_, index) => (
        <SongRoyaltyCardPlaceholder key={index} />
      ))}
    </SongBitsContainer>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 488px;

  @media (max-width: ${md}px) {
    width: 100%;
  }
`;

export const MoneyContainer = styled.div`
  width: 100%;

  @media (max-width: ${sm}px) {
    padding-left: 24px;
  }
`;

export const PeriodTitleContainer = styled.div`
  width: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${sm}px) {
    width: 123px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SongBitsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
`;

export const Tooltip = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 17px;
`;

export const WithdrawButton = styled(Button)`
  border: 3px solid gray;
  border-radius: 50px;
`;

export default SongRoyaltyOverviewPlaceholder;

import React from 'react';
import styled from 'styled-components';

import Tip from 'common/components/Tip/Tip';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';

import { sm } from 'utils/constants';

import { useViewport } from 'use-viewport';

const Question = ({
  text,
  tip,
  fontWeight,
  radio,
  error,
  setRadio,
  question
}: {
  question: string;
  radio?: string;
  text: string;
  tip?: string;
  error?: string | undefined;
  fontWeight?: 'bold' | 'regular';
  setRadio: (value: string) => void;
}) => {
  const viewport = useViewport();
  return (
    <div>
      <Container>
        <Typography
          text={text}
          fontSize={viewport.width >= sm ? 'fz16' : 'fz14'}
          fontWeight={fontWeight ?? 'regular'}
          lineHeight="20px"
          fontColor={theme.colors.white}
          letterSpacing='-0.03em'
        />
        {tip && (
          <div style={{ marginTop: '3px' }}>
            <Tip
              width={200}
              position="left"
              vertical='bottom'
              text={
                <div>
                  <Typography
                    text={tip}
                    fontSize={'fz14'}
                    lineHeight={'18px'}
                  />
                </div>
              }
            />
          </div>
        )}
      </Container>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          paddingTop: '15px',
        }}>
        <OptionButton
          fontSize={16}
          borderColor={
            radio === 'yes' ? theme.colors.yellow : theme.colors.white
          }
          isTextBold={radio === 'yes'}
          onClick={() => {
            setRadio('yes');
          }}
          label={'yes'}></OptionButton>

        <OptionButton
          fontSize={16}
          borderColor={
            radio === 'no' ? theme.colors.yellow : theme.colors.white
          }
          isTextBold={radio === 'no'}
          onClick={() => {
            setRadio('no');
          }}
          label={'no'}></OptionButton>
      </div>
      {error ? (
        <ErrorContainer>
          <ErrorText
            className={`validation-error-${question?.replace(' ', '')}`}
            text={error}
            fontSize="fz12"
            fontColor={theme.colors.yellow}
          />
        </ErrorContainer>
      ) : (
        <></>
      )}
    </div>
  );
};

const Container = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
`;

const OptionButton = styled(Button)`
  border-radius: 50px;
  width: 85px;
  height: 28px;
  border-width: 1px;
  align-items: normal;
`;

export const ErrorContainer = styled.div`
  position: relative;
  bottom: -10px;
`;

const ErrorText = styled(Typography)`
`;

export default Question;

import React, { FC } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';

import {
  CloseButton,
  ModalContainer,
  ModalContentContainer,
  Title,
} from './index.styled';

import CopyFilledIcon from 'common/icons/CopyFilled.icon';
import theme from 'theme/theme';

interface ClaimNowModalProps {
  onClose: () => void;
}

const ClaimNowModal: FC<ClaimNowModalProps> = ({ onClose }) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <ModalContainer>
      <CloseButton onClick={() => onClose()}>X</CloseButton>
      <Title text="claim now" fontWeight="bold" fontSize={'fz36'} />

      <ModalContentContainer>
        <DescriptionContainer>
          <Typography
            text="In order to claim your benefit please send an email to the following address"
            fontWeight="light"
          />
          <Typography
            text="Tap or click the address below to copy:"
            fontWeight="bold"
          />
        </DescriptionContainer>
        <CopyButton onClick={() => copyToClipboard('whatever@whatrever.com')}>
          <div>
            <CopyFilledIcon />
          </div>
          <Typography text={'whatever@whatrever.com'} fontWeight="light" />
        </CopyButton>
      </ModalContentContainer>
    </ModalContainer>
  );
};

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 20px;
`;

const CopyButton = styled.button`
  width: 100%;
  height: 58px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8.5px;

  border: 3px solid ${theme.colors.yellow};
  border-radius: 20px;

  padding: 0px 20px;

  background-color: rgb(0, 0, 0, 0);

  cursor: pointer;
`;

export default ClaimNowModal;

import * as React from 'react';

import { IconProps } from '../../common.types';

const BitPurchase = ({ width = 30, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 0L14.998 13C14.998 14.243 13.697 16 11.25 16C10.007 16 9 15.347 9 14.125C9 12.536 10.445 11.575 12 11.575C12.432 11.575 12.754 11.634 13 11.698V4.364L6 5.637V15H5.998C5.998 16.243 4.697 18 2.25 18C1.007 18 0 17.347 0 16.125C0 14.536 1.445 13.575 3 13.575C3.432 13.575 3.754 13.634 4 13.698V2L15 0Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);

export default BitPurchase;

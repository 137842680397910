import React from 'react';
import styled from 'styled-components';

import TicketIcon from 'common/icons/Ticket.icon';
import VideoMessageIcon from 'common/icons/VideoMessage.icon';
import SignedIcon from 'common/icons/Signed.icon';
import ContentIcon from 'common/icons/Content.icon';
import DiskArrowDownIcon from 'common/icons/DiskArrowDown.icon';
import ProfileRectangleIcon from 'common/icons/ProfileRectangle.icon';
import StockUpIcon from 'common/icons/StockUp.icon';
import { PurchasePerksType } from 'modules/artist/types';
import PlusWideIcon from 'common/icons/PlusWide.icon';

type PerkIconsProps = {
  perks: PurchasePerksType[];
  onClick: () => void;
};

export const getPerkIcon = (perk: PurchasePerksType) => {
  if (perk.type === 'ROYALTY'.toLowerCase()) {
    return <StockUpIcon />;
  } else if (perk.type === 'MP3_DOWNLOAD'.toLowerCase()) {
    return <DiskArrowDownIcon />;
  } else if (perk.type === 'NFT_IMAGE'.toLowerCase()) {
    return <ProfileRectangleIcon />;
  } else if (perk.type === 'CUSTOM'.toLowerCase()) {
    if (perk.imageLogo === 'tickets') {
      return <TicketIcon />
    } else if (perk.imageLogo === 'signed') {
      return <SignedIcon />
    } else if (perk.imageLogo === 'video') {
      return <VideoMessageIcon />
    } else if (perk.imageLogo === 'content') {
      return <ContentIcon />
    } else {
      return <></>
    };
  } else {
    return <></>;
  }
};

const SongProfileIconsSection = ({ perks, onClick }: PerkIconsProps) => {
  return (
    <IconsWrapper>
      <IconButton onClick={onClick}>
        <PlusWideIcon />
      </IconButton>
      {perks
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((p, key) => {
          return (
            <IconButton key={key} onClick={onClick}>
              {getPerkIcon(p)}
            </IconButton>
          );
        })}
    </IconsWrapper>
  );
};

const IconsWrapper = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: black;

  padding: 6px 18px;
  margin-left: 15px;
  margin-top: -10px;
  transform: scale(0.7);

  @media (min-width: 576px) {
    transform: none;
    margin-top: 0;
  }
`;

const IconButton = styled.button<{ last?: boolean }>`
  margin-right: ${(props) => (props.last ? 0 : '20px')};

  border: none;
  background-color: transparent;

  :hover {
    cursor: pointer;
  }
`;

export default SongProfileIconsSection;

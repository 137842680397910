import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';

import { md, sm, xlg } from 'utils/constants';

export const HeadingSection = styled.div`
  @media (min-width: 992px) {
    margin-left: 130px;
    width: 700px;
  }
`;

export const HeadContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 100px 36px 0 36px;

  display: flex;
  flex-direction: column;
  flex: 1;

  z-index: 10;

  @media (min-width: ${sm}px) {
    padding-left: 50px;
  }

  @media (min-width: ${md}px) {
    padding: 135px 70px 0;
  }

  @media (min-width: ${xlg}px) {
    justify-content: center;
    padding-top: 0;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const FirstButton = styled(Button)`
  font-family: 'HKGrotesk-Black';
  line-height: 100%;

  padding: 0 50px;
  margin: 36px auto 0 auto;

  position: relative;
  right: 5px;

  @media (min-width: ${sm}px) {
    margin: 25px 0 0 0;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 75%;
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
}>`
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};

  font-size: 10px;
  line-height: 24px;
  letter-spacing: -0.03em;

  @media (min-width: ${md}px) {
    font-size: 12px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  bottom: 24px;
  left: 6px;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
    margin-top: 0px;
  }
  & > label {
    font-size: 12px;
    letter-spacing: -0.02em;

    padding-left: 20px;
    margin-top: 0px;
  }

  & > input:focus + label {
    font-size: 0px;
  }
  & > input:valid + label {
    font-size: 0px;
  }
  & > div:nth-child(4) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > label {
      font-size: 18px;
    }
  }
`;

export const TextContentForgot = styled(TextContent)`
  font-family: 'HKGrotesk-Black';
  font-size: 18px;

  margin-top: 32px;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const Form = styled.div`
  width: 350px;
`;

export const ContactsLinkContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 20px;
  margin-left: 30px;

  @media (min-width: ${md}px) {
    margin-top: 25px;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 60px;
  letter-spacing: -0.03em;

  @media (min-width: ${md}px) {
    font-size: 100px;
    letter-spacing: -0.055em;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const HeadContent = styled(StrapLineTypography)`
  font-family: 'HKGrotesk-Black';
  flex-shrink: 0;
`;

import * as React from 'react';

import { IconProps } from '../common.types';

const TicketIcon = ({ width = 31, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.75 8.3514V2.325C30.75 1.04044 29.6915 0 28.3846 0H2.36538C1.05851 0 0 1.04044 0 2.325V8.3514C1.37547 8.83151 2.36538 10.1091 2.36538 11.625C2.36538 13.1409 1.37547 14.4185 0 14.8986V20.925C0 22.2096 1.05851 23.25 2.36538 23.25H28.3846C29.6915 23.25 30.75 22.2096 30.75 20.925V14.8986C29.3745 14.4185 28.3846 13.1409 28.3846 11.625C28.3846 10.1091 29.3745 8.83151 30.75 8.3514ZM21.9413 10.4172L18.5091 12.9282L19.7841 16.9423C19.9556 17.4805 19.3335 17.9246 18.8651 17.5979L15.375 15.166L11.8849 17.5979C11.4165 17.9246 10.7956 17.4805 10.9659 16.9423L12.2409 12.9282L8.80869 10.4172C8.34863 10.0812 8.58635 9.36278 9.15995 9.3558L13.4378 9.30698L14.8073 5.32309C14.9906 4.7895 15.7594 4.7895 15.9427 5.32309L17.3123 9.30698L21.59 9.3558C22.1637 9.36161 22.4014 10.08 21.9413 10.4172Z"
      fill="white"
    />
  </svg>
);

export default TicketIcon;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import { useWeb3React } from '@web3-react/core';
import theme from 'theme/theme';

import Spacer from 'common/components/Spacer/Spacer';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import SBModal from 'common/components/Modal/SBModal';
import MetaMask from 'common/icons/MetaMask.icon';
import WalletConnect from 'common/icons/WalletConnect.icon';
import CoinbaseWallet from 'common/icons/CoinbaseWallet.icon';
import ProfileRectangleOutlinedIcon from 'common/icons/ProfileRectangleOutlined.icon';
import ShareIcon from 'common/icons/ShareOutlined.icon';
import EyeWhiteIcon from 'common/icons/MyCollection/EyeWhite.icon';
import ChainRectangleOutlinedIcon from 'common/icons/MyCollection/ChainRectangleOutlined.icon';

import {
  injected,
  walletconnect,
  walletlink,
} from '../../../../../utils/wallet';
import SongProfileShareModal from '../../../../library/pages/components/SongProfileShareModal/SongProfileShareModal';
import GiftBitsModal from '../MyCollection/GiftBitsModal';
import ExportBitsModal from '../MyCollection/ExportBitsModal';
import ConfirmBitTransferModal from '../MyCollection/ConfirmBitTransferModal';
import NFTOptionsModal from '../MyCollection/NFTOptionsModal';
import ImagePlayer from './ImagePlayer/ImagePlayer';
import DefaultButton from 'common/components/DefautButton/DefaultButton';
import PurchaseInfoModal from '../Modals/PurchaseInfoModal/PurchaseInfoModal';

import { TransactionType } from 'modules/library/types';
import { ExportModalType, NFTOptionsModalType } from 'modules/account/types';
import { sm } from 'utils/constants';
import ExportDetailsModal from '../MyCollection/ExportDetailsModal';
import NFTModal from 'modules/library/pages/components/Modals/NFTModal/NFTModal';
//import { useStoreActions } from 'store/store';



type ArtistCardProps = {
  songData: TransactionType;
  isPlaying: boolean;
  onPlay: () => void;
  onPause: () => void;
  reloadAPI: () => void;
};

const MetamaskButtonLabel = () => (
  <ButtonLabelContainer>
    <MetaMask width={22} height={20} />
    <br />
    <TextButtonModal text="MetaMask" fontWeight="bold" fontSize="fz18" />
  </ButtonLabelContainer>
);

const WalletConnectLabel = () => (
  <ButtonLabelContainer>
    <WalletConnect width={22} height={20} />
    <br />
    <TextButtonModal text="Wallet Connect" fontWeight="bold" fontSize="fz18" />
  </ButtonLabelContainer>
);

const WalletLinkLabel = () => (
  <ButtonLabelContainer>
    <CoinbaseWallet width={22} height={20} />
    <br />
    <TextButtonModal text="Coinbase Wallet" fontWeight="bold" fontSize="fz18" />
  </ButtonLabelContainer>
);

const MyCollectionCard = ({
  songData,
  isPlaying,
  reloadAPI,
  onPause,
  onPlay,
}: ArtistCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const viewport = useViewport();

  //const setNavigateState = useStoreActions((actions) => actions.location.setNavigateState);

  const [showPurchaseInfo, setShowPurchaseInfo] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showGiftModal, setShowGiftModal] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<{
    show: boolean;
    type: ExportModalType;
  }>({
    show: false,
    type: 'royalty',
  });
  const [showExportDetailsModal, setShowExportDetailsModal] = useState<{
    show: boolean;
    type: ExportModalType;
  }>({ show: false, type: 'royalty' });
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [show2FARequiredModal, setShow2FARequiredModal] =
    useState<boolean>(false);
  const [showNFTModal, setShowNFTModal] = useState<boolean>(false);

  const [showNFTOptionsModal, setShowNFTOptionsModal] = useState<{
    show: boolean;
    type: NFTOptionsModalType;
  }>({
    show: false,
    type: 'export',
  });

  const [socialTopPosition, setSocialTopPosition] = useState<string>('40%');

  const { account, activate, connector } = useWeb3React();
  const [activatingConnector, setActivatingConnector] =
    useState<typeof connector>();
  const [showWalletConnectModal, setShowWalletConnectModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (location.search.indexOf('?nft=') !== -1) {
      const nft = location.search.replace('?nft=', '');
      if (nft === songData.nftid) {
        setShowExportModal({
          show: true,
          type: 'royalty',
        });
      }
    }
    if (connector) {
      setActivatingConnector(connector);
      activate(connector);
    }
  }, [activatingConnector, connector, account]);

  const handleConnectWallet = (_connetor: any) => {
    setActivatingConnector(_connetor);
    activate(_connetor);
    setShowWalletConnectModal(false);
  };

  return (
    <>
      <CardContainer 
        className="cardd"
        id={`collection-item-${songData.originaltransactionid}`}
      >
        <ImagePlayer
          imageSrc={songData.file_url}
          isPlaying={isPlaying}
          onPause={onPause}
          onPlay={onPlay}
        />

        <SongDetails>
          <SongDescriptionContainer>
            <TextTruncated
              text={songData.title}
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              fontSize={viewport.width < sm ? 'fz18' : 'fz24'}
            />
            <TextTruncated
              text={songData.artist_name}
              fontWeight="light"
              fontSize={viewport.width < sm ? 'fz14' : 'fz18'}
            />
          </SongDescriptionContainer>

          <TextButton
            className="purchase-info-button view-transaction-details-button"
            onClick={() => setShowPurchaseInfo(true)}>
            <EyeIconContainer>
              <EyeWhiteIcon
                width={viewport.width < sm ? 14 : 28}
                height={viewport.width < sm ? 10 : 20}
                fill={viewport.width < sm ? 'white' : '#ffffff80'}
              />
            </EyeIconContainer>
            <Typography
              text={
                (viewport.width < sm ? 'reveal' : 'view') +
                ' transaction details'
              }
              fontSize={viewport.width < sm ? 'fz12' : 'fz16'}
            />
          </TextButton>

          <TextButton
            className="view-inclusive-benefits-button"
            onClick={() => {
              /*setNavigateState({
                id: songData.originaltransactionid,
              });*/
              navigate(`/library/my-collection/${songData.artistslug}/${songData.songslug}/benefits`,
                {
                  state: {
                    id: songData.originaltransactionid,
                  }
                }
              );
            }}>
            <Typography
              text={'view inclusive benefits'}
              fontSize="fz16"
              fontWeight="bold"
            />
          </TextButton>
        </SongDetails>

        <IconsContainer>
          <DefaultButton
            className="show-nft-options-modal-button"
            onClick={() =>
              setShowNFTOptionsModal({
                show: true,
                type: 'export',
              })
            }>
            <ChainRectangleOutlinedIcon
              width={viewport.width < sm ? 20 : 25}
              height={viewport.width < sm ? 20 : 25}
            />
          </DefaultButton>
          <DefaultButton
            className="share-button show-share-modal-button"
            onClick={() => setShowShareModal(true)}>
            <ShareIcon
              width={viewport.width < sm ? 20 : 25}
              height={viewport.width < sm ? 20 : 25}
            />
          </DefaultButton>
          {songData.nft_image_available ? (
            <>
              <DefaultButton
                onClick={() => {
                  setShowNFTModal(true);
                }} 
                className="show-nft-image-options-modal-button">
                <ProfileRectangleOutlinedIcon
                  width={viewport.width < sm ? 27 : 28}
                  height={viewport.width < sm ? 24 : 25}
                />
              </DefaultButton>
            </>
          ) : (
            <>
              {' '}
              <DefaultButton onClick={() => {}}></DefaultButton>
            </>
          )}
        </IconsContainer>
      </CardContainer>

      <NFTModal
        show={showNFTModal}
        songData={songData}
        onClose={() => setShowNFTModal(false)}
        onChainClick={() => {
          setShowNFTOptionsModal({
            show: true,
            type: 'image',
          });
        }}
      />

      <SBModal
        className={'purchase-info'}
        isOpen={showPurchaseInfo}
        height={'430px'}
        width="360px"
        top="30%"
        content={
          <PurchaseInfoModal
            songData={songData}
            onClose={() => setShowPurchaseInfo(false)}
          />
        }
      />

      <SBModal
        className={'share'}
        isOpen={showShareModal}
        height={'430px'}
        width="434px"
        top={socialTopPosition}
        content={
          <SongProfileShareModal
            shareUrl={`/${songData.artistslug}/${songData.songslug}`}
            imageLink={songData.file_url}
            onChange={() => {
              setShowShareModal(false);
            }}
            changeModalPosition={(topPos) => {
              setSocialTopPosition(topPos);
            }}
          />
        }
      />
      <SBModal
        className={'gift'}
        isOpen={showGiftModal}
        height="560px"
        width="527px"
        content={
          <GiftBitsModal
            onChange={(showConfirmModal) => {
              setShowGiftModal(false);
              if (showConfirmModal) {
                setShowConfirmModal(true);
              }
            }}
          />
        }
      />
      <SBModal
        className={'export-nft'}
        isOpen={showExportModal.show}
        height="553px"
        width="460px"
        content={
          <ExportBitsModal
            image={songData.file_url}
            songName={songData.title}
            artistName={songData.artist_name}
            bits={Number(songData.data.number_of_bits).toLocaleString()}
            type={showExportModal.type}
            purchaseId={songData.originaltransactionid}
            reloadAPI={() => {
              reloadAPI();
            }}
            onChange={(showConfirmModal) => {
              reloadAPI();
              setShowExportModal({ show: false, type: 'royalty' });
              if (showConfirmModal) {
                setShowConfirmModal(true);
              }
            }}
            nftId={songData.nftid}
          />
        }
      />
      <SBModal
        className={'nft-options'}
        isOpen={showNFTOptionsModal.show}
        height="553px"
        width="460px"
        top="30%"
        content={
          <NFTOptionsModal
            type={showNFTOptionsModal.type}
            onChange={() => {
              setShowNFTOptionsModal({
                show: false,
                type: 'export',
              });
            }}
            onExport={(type: ExportModalType) => {
              setShowNFTOptionsModal({
                show: false,
                type: 'export',
              });

              if (songData.mfa_enabled) {
                setShowExportModal({
                  show: true,
                  type: type,
                });
              } else {
                setShow2FARequiredModal(true);
              }
            }}
            onViewDetails={(type: ExportModalType) => {
              setShowNFTOptionsModal({
                show: false,
                type: 'export',
              });
              setShowExportDetailsModal({
                show: true,
                type: type,
              });
            }}
            nft_link={songData.nft_link}
            metadata_link={songData.metadata_link}
            image_nft_link={songData.nft_image_link}
            image_metadata_link={songData.metadata_image_link}
            purchaseId={songData.originaltransactionid}
            fileName={songData.title + ' - ' + songData.artist_name}
            nftTokenId={songData.nfttokenid}
            nftImageTokenId={songData.nftimagetokenid}
            exportable={songData.exportable}
            nft_image_exportable={songData.image_exportable}
            nft_image_available={songData.nft_image_available}
          />
        }
      />
      <SBModal
        className={'mfa-required'}
        isOpen={show2FARequiredModal}
        height="429px"
        width="519px"
        content={
          <>
            <ModalCloseButton
              onClick={() => {
                setShow2FARequiredModal(false);
              }}>
              X
            </ModalCloseButton>
            <ModalHeadingText
              text="two factor authentication"
              fontWeight="bold"
              fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
            />
            <Spacer height={20} />

            <Text
              paddingRight={40}
              text="In order to protect your account, two factor authentication is required to export your bits to an external wallet. "
            />
            <Spacer height={25} />
            <Text
              paddingRight={20}
              text="Please visit your account settings to setup two factor authentication."
            />
            <Spacer height={40} />
            <SubmitButton
              width={172}
              borderRadius={50}
              height={45}
              fontSize={16}
              borderColor={theme.colors.white}
              label={
                <SubmitButtonContent justifyContent="center">
                  <BoldButtonText
                    text="account "
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                  <BoldButtonText
                    text="settings"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.yellow}
                  />
                </SubmitButtonContent>
              }
              onClick={() => {
                navigate('/account/settings');
              }}
            />
          </>
        }
      />
      <SBModal
        className={'connect-wallet'}
        isOpen={showWalletConnectModal}
        height="245px"
        top="30%"
        content={
          <>
            <CloseButton
              className="wallet-connect-modal-close"
              label="X"
              onClick={() => {
                setShowWalletConnectModal(false);
              }}
            />
            <div>
              <WalletButton
                label={<MetamaskButtonLabel />}
                onClick={() => {
                  handleConnectWallet(injected);
                }}
              />
            </div>
            <div>
              <WalletButton
                label={<WalletConnectLabel />}
                onClick={() => {
                  handleConnectWallet(walletconnect);
                }}
              />
            </div>
            <div>
              <WalletButton
                label={<WalletLinkLabel />}
                onClick={() => {
                  handleConnectWallet(walletlink);
                }}
              />
            </div>
          </>
        }
      />
      <SBModal
        className={'confirm'}
        isOpen={showConfirmModal}
        height="460px"
        width="527px"
        content={
          <ConfirmBitTransferModal
            onChange={() => {
              setShowConfirmModal(false);
            }}
            onExport={() => {
              setShowConfirmModal(false);
            }}
          />
        }
      />
      <SBModal
        className={'export-details'}
        isOpen={showExportDetailsModal.show}
        height="553px"
        width="460px"
        content={
          <ExportDetailsModal
            contractAddress={
              (showExportDetailsModal.type === 'image'
                ? songData.nft_image_collection
                : songData.nft_collection) || ''
            }
            nftTokenId={
              (showExportDetailsModal.type === 'image'
                ? String(songData.nftimagetokenid)
                : String(songData.nfttokenid)) || ''
            }
            type={showExportDetailsModal.type}
            artistName={songData.artist_name}
            songName={songData.title}
            bits={Number(songData.data.number_of_bits).toLocaleString()}
            image={songData.file_url}
            handleClose={() => {
              setShowExportDetailsModal({
                show: false,
                type: 'royalty',
              });
            }}
          />
        }
      />
    </>
  );
};

const CardContainer = styled.div<{id?: string}>`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SongDetails = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  margin-right: 20px;

  @media (min-width: ${sm}px) {
    min-height: 150px;
    justify-content: initial;
    gap: 10px;
  }
`;

const SongDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: ${sm}px) {
    gap: 10px;

    margin-bottom: auto;
  }
`;

const EyeIconContainer = styled.div`
  padding-top: 2px;
  @media (min-width: ${sm}px) {
    margin-right: 5px;
    padding-top: 4px;
  }
`;

const TextTruncated = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextButton = styled(DefaultButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const IconsContainer = styled.div`
  height: 100%;
  min-height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${sm}px) {
    min-height: 150px;
  }
`;

//Modals
const CloseButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 20px;
`;

const WalletButton = styled(Button)`
  padding: 18px;
  border-width: 1px;
  cursor: pointer;
  width: 100%;
  font-size: ${theme.fontSizes.fz9};
`;

const TextButtonModal = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  line-height: 23px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-left: 15px;
  display: none;

  @media (min-width: 1200px) {
    display: inline;
  }
`;

const ButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px;
  transform: scale(0.7);

  @media (min-width: 768px) {
    padding: 3px 4px;
    transform: none;
  }
`;

export const ModalHeadingText = styled(Typography)``;

export const Text = styled(Typography)<{
  paddingRight?: number;
  red?: boolean;
  inline?: boolean;
}>`
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : 0)}px;
  line-height: 24px;
`;

export const ModalCloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const SubmitButton = styled(Button)`
  margin: 0 auto;
`;

export const BoldButtonText = styled(Typography)``;

export const SubmitButtonContent = styled.div<{ justifyContent?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

export default MyCollectionCard;


import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import store from 'store/store';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

async function getAccessToken() {
  return await store.getActions().authentication.getAuthToken();
}

const tokenLink = setContext(async (request, { headers }) => {
  if (store.getState().authentication.isAuthenticated) {
    if (request.operationName === "ResendVerificationEmail" || request.operationName === "AUTHENTICATE" || request.operationName === "REGISTER_USER") {
      return { headers: { ...headers } }
    }
    else if (request.operationName === "RefreshToken") {
      const accessToken = store.getState().authentication.auth.accessToken;

      if (accessToken) return { headers: { ...headers } };
      //else console.log('Unable to retrieve Access Token');
    } else {
      const accessToken = await getAccessToken();

      if (accessToken) return { headers: { ...headers, Authorization: `Bearer ${accessToken}` } };
      //else console.log('Unable to retrieve Access Token');
    }

  }/* else {
    //console.log('User is not authenticated');
  }*/
});

const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([tokenLink, httpLink]),
});

export default graphqlClient;


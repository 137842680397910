import * as React from 'react';

import { IconProps } from '../common.types';

const QuestionMark = ({ width = 15, height = 15 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm0 13.929a6.43 6.43 0 1 1 0-12.859 6.43 6.43 0 0 1 0 12.859Z"
      fill="#fff"
    />
    <path
      d="M7.5 12.321a.804.804 0 1 0 0-1.607.804.804 0 0 0 0 1.607ZM8.036 3.214h-.804a2.405 2.405 0 0 0-2.41 2.411v.268h1.071v-.268a1.34 1.34 0 0 1 1.34-1.34h.803a1.34 1.34 0 1 1 0 2.68H6.964v2.41h1.072v-1.34a2.41 2.41 0 0 0 0-4.82Z"
      fill="#fff"
    />
  </svg>
);

export default React.memo(QuestionMark);

import React from 'react';

import styled from 'styled-components';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

type ToasterModalProps = {
  title: string | JSX.Element;
  content: string | JSX.Element;
};

const ToasterModal = ({ content, title }: ToasterModalProps) => {
  return (
    <div>
      <TypographyTitle fontSize="fz48" fontWeight="bold" text={title} />

      <Spacer height={20} />

      <ToastContent text={content} />
    </div>
  );
};

const TypographyTitle = styled(Typography)`
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const ToastContent = styled(Typography)`
  line-height: 25px;
  padding-bottom: 20px;
`;

export default ToasterModal;

import React from 'react';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import styled from 'styled-components';

import '../assets/editorSkins/songbitsskin/skin/skin.css';
import '../assets/editorSkins/songbitsskin/skin/content.css';
import Button from 'common/components/Button/Button';
import parse from 'html-react-parser';

interface PreviewMessageProps {
  content: string;
  onBack: () => void;
}

export default function PreviewMessage({
  content,
  onBack,
}: PreviewMessageProps) {
  return (
    <>
      <MessagePreview>{parse(content)}</MessagePreview>
      <Spacer height={30} />
      <BackButton
        label={<Typography text="back" fontWeight="bold" fontSize="fz16" />}
        onClick={onBack}
      />
    </>
  );
}

const BackButton = styled(Button)`
  width: 143px;
  height: 45px;
  border: 2px solid white;
  border-radius: 50px;
`;

const MessagePreview = styled.div`
  scrollbar-color: ${theme.colors.yellow} transparent;
  overflow: auto;

  width: auto;
  height: auto;
  border-radius: 10px;

  table {
    border-spacing: 0px !important;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.colors.yellow};
    opacity: 0.5;
  }
`;

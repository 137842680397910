import { createStore, createTypedHooks, useStoreRehydrated } from 'easy-peasy';

import model from './models/mainModel';
import { StoreModel } from './models/mainModel.types';
import { RestInterface } from './models/dataModel/dataModel.types';

const { useStoreActions, useStoreState, useStoreDispatch } =
  createTypedHooks<StoreModel>();

export { useStoreActions, useStoreDispatch, useStoreState, useStoreRehydrated };
export type { RestInterface }

const store = createStore(model);

export default store;

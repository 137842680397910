import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import abiContract from 'contracts/contract.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ADDRESS;

class Web3Service {
  web3: Web3;

  constructor() {
    this.web3 = new Web3(Web3.givenProvider);
  }

  getAccounts = async () => {
    return await this.web3.eth.getAccounts();
  };

  getContract = (abi = abiContract as AbiItem | AbiItem[]) => {
    return new this.web3.eth.Contract(abi, CONTRACT_ADDRESS);
  };

  sendPermissionsRequest = () => {
    return (window as any).ethereum.request({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  };
}

const web3Service = new Web3Service();

export default web3Service;
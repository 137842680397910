import React, { useMemo } from 'react';
import { useViewport } from 'use-viewport';

import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksMobile from 'common/components/QuickLinksMobile/QuickLinksMobile';

import PageLayout from 'common/components/PageLayout/PageLayout';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Typography from 'common/components/Typography/Typography';

import { termsData } from '../../../utils/bitsSalesTerms';
import '../../../theme/bitSalesTermsStyle.scss';

const Legal = () => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const quickLinks = useMemo(
    () => [
      {
        title: 'Terms & Conditions',
        link: '/legal/terms-conditions',
      },
      {
        title: 'Privacy Policy',
        link: '/legal/privacy-policy',
      },
      {
        title: 'Cookie Policy',
        link: '/legal/cookie-policy',
      },
      /*{
        title: 'Risk Summary',
        link: '/legal/risk-summary',
      },*/
      /*{
        title: 'Risk Factors',
        link: '/legal/risks',
      },*/
      /*{
        title: 'Community Guidelines',
        link: '/legal/community-guidelines',
      },*/
      {
        title: 'Company',
        link: '/company',
      },
    ],
    [],
  );

  const contentSection = (
    <>
      {viewport.width <= 767 ? (
        <QuickLinksMobile links={quickLinks} loading={false}></QuickLinksMobile>
      ) : (
        <></>
      )}

      <div
        className="terms"
        style={{ color: 'white' }}
        dangerouslySetInnerHTML={{ __html: termsData }}></div>


      {viewport.width <= 767 ? (
        <>
          <div style={{'marginTop': '30px', 'display': 'flex', 'flexDirection': 'column'}}>
          <Typography text={'To read our other terms you can use the following links:'} lineHeight='20px' fontSize={'fz16'} />
          <QuickLinkButton onClick={() => {
            navigate('/legal/terms-conditions')
          }}>Terms & Conditions</QuickLinkButton>
          <QuickLinkButton onClick={() => {
            navigate('/legal/privacy-policy')
          }}>Privacy Policy</QuickLinkButton>
          <QuickLinkButton onClick={() => {
            navigate('/legal/cookie-policy')
          }}>Cookie Policy</QuickLinkButton>   
          </div>       
        </>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <PageContainer pageTitle='Bits Sales Terms | SongBits'>
      <PageLayout
        title="Bits Sales Terms"
        is2ColumnLayout
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <>
                {viewport.width <= 767 ? (
                  <></>
                ) : (
                  <QuickLinks links={quickLinks}></QuickLinks>
                )}
              </>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};


const QuickLinkButton = styled.button`
    font-family: 'HKGrotesk-Regular';
    font-size: 18px;
    color: #FFF;
    background #000;
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 16px;
    text-decoration: underline;

    &hover: {
      text-decoration: none;
    }
`;

export default Legal;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { escToClose } from 'utils/functions';

import ModalLayout from '../ModalLayout/ModalLayout';


import {
  IconContainer,
  RowContainer,
  Text,
} from './index.styled';

import { PurchasePerksType } from 'modules/artist/types';
import { getPerkIcon } from '../SongProfileIconsSection/SongProfileIconsSection';

type BenefitsModalProps = {
  onClose: () => void;
  perks: PurchasePerksType[];
};

const InfoBenefitsModal = ({ onClose, perks }: BenefitsModalProps) => {

  useEffect(escToClose(onClose), []);

  return (
    <ModalLayout padding={true} scroll={true} onClose={onClose} title={'info & benefits'}>
      <ContentContainer>
        {perks
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((p, key) => {
            return (
              <RowContainer key={key}>
                <IconContainer>{getPerkIcon(p)}</IconContainer>
                <Text text={p.description.replaceAll('|', '')} />
              </RowContainer>
            );
          })}
      </ContentContainer>
    </ModalLayout>
  );
};


export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  overflow-y: scroll;
  max-height: 450px;
`;

export default InfoBenefitsModal;

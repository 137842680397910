import React from 'react';
import styled from 'styled-components';

import Spacer from 'common/components/Spacer/Spacer';

const SongRoyaltyCardPlaceholder = () => {
  return (
    <Container>
      <ContentContainer>
        <SongInfoContainer>
          <span
            style={{ width: '150px', height: '150px', borderRadius: '20px' }}
            className={'placeholder-wave placeholder fs-1 bg-secondary col-12'}>
            &nbsp;
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '600px',
            }}>
            <span
              className={
                'placeholder-wave placeholder fs-36 lh-28 bg-secondary col-8'
              }>
              &nbsp;
            </span>
            <Spacer height={20} />
            <span
              className={
                'placeholder-wave placeholder fs-36 lh-28 bg-secondary col-6'
              }>
              &nbsp;
            </span>
            <Spacer height={20} />
            <RoyaltyContainer show={true}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-7'
                  }>
                  &nbsp;
                </span>
                <Spacer height={10} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-9'
                  }>
                  &nbsp;
                </span>
                <Spacer height={30} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-7'
                  }>
                  &nbsp;
                </span>
                <Spacer height={10} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-9'
                  }>
                  &nbsp;
                </span>

                <Spacer height={20} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-7'
                  }>
                  &nbsp;
                </span>
                <Spacer height={10} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-9'
                  }>
                  &nbsp;
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-7'
                  }>
                  &nbsp;
                </span>
                <Spacer height={10} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-9'
                  }>
                  &nbsp;
                </span>
                <Spacer height={30} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-7'
                  }>
                  &nbsp;
                </span>
                <Spacer height={10} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-9'
                  }>
                  &nbsp;
                </span>

                <Spacer height={20} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-7'
                  }>
                  &nbsp;
                </span>
                <Spacer height={10} />
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-9'
                  }>
                  &nbsp;
                </span>
              </div>
            </RoyaltyContainer>
            <Spacer height={25} />
            <span
              className={
                'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-7'
              }>
              &nbsp;
            </span>
          </div>
        </SongInfoContainer>
      </ContentContainer>
      <Spacer height={25} />
      <span
        style={{ minHeight: '2px', maxHeight: '2px' }}
        className={'placeholder-wave placeholder fs-1 bg-secondary col-12'}>
        &nbsp;
      </span>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  max-width: 550px;
`;

const SongInfoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 17px;
`;

const RoyaltyContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: flex-start;
  gap: 50px;
`;

export default SongRoyaltyCardPlaceholder;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { useNavigate } from 'react-router-dom';
//import { useAppDispatch } from 'hooks/reduxToolkit';
//import { resetPersist } from 'store-persist/reducers/modalSlice';
import { useIntercom } from 'react-use-intercom';
import { useStoreActions } from 'store/store';

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';


type ModalProps = {
  show: boolean;
  onClose: () => void;
};

const KYCDeclinedModal = ({ show, onClose }: ModalProps) => {

  const navigate = useNavigate();
  //const dispatch = useAppDispatch();
  const { shutdown, showSpace } = useIntercom();

  const logout = useStoreActions((state) => state.authentication.logout);
  /*const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );*/

  useEffect(() => {
      setShowKYCModal(show);
  }, [show]);

  const [showKYCModal, setShowKYCModal,] = useState<boolean>(false);
  
  const onLogout = () => {
    //store.persist.clear().then(() => {
      onClose()
      //dispatch(resetPersist());
      window.localStorage.removeItem('purchaseId');
      // Used to prevent unauth banner showing on logout
      window.localStorage.setItem('user_logout', 'logout');
      // Shutdown Intercom
      shutdown();
      logout();
      window.localStorage.removeItem('@veriff-session-url')
      window.localStorage.removeItem('@veriff-session-id')
      setShowKYCModal(false);
      navigate('/');
    //});
  };

  return (
    <>
      <SBModal
        className={'kyc-declined-modal'}
        isOpen={showKYCModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout title={'verification'}>
            <ContentContainer className={'kyc-declined-modal'}>

              <BoldYellowText>Looks like we need to check something</BoldYellowText>

              <NormalText>
                Please contact support below and we will assist you.
              </NormalText>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'kyc-modal-cancel-button'}
                  label={
                    <ButtonText
                      text="cancel"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={() => {
                    showSpace('messages')
                  }}
                />
                <FirstButton
                  className={'kyc-declined-modal-logout-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="logout"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    onLogout()
                  }}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
      
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 400px;
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: -0.03em;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;


`;

export default KYCDeclinedModal;

import React, { FC, useState } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import TwoFaStageSelection from './TwoFaStageSelection';
import { TwoFaStages } from './types';

interface TwoFaModalProps {
  onSuccess: () => void;
  onHide: () => void;
}

const TwoFaModal: FC<TwoFaModalProps> = ({ onSuccess, onHide }) => {
  const [twoFaStage, setTwoFaStage] = useState<TwoFaStages>(TwoFaStages.Method);

  function onClose() {

    if (twoFaStage === TwoFaStages.ManualEntry || twoFaStage === TwoFaStages.PcHelp || twoFaStage === TwoFaStages.MobileHelp) {
      setTwoFaStage(TwoFaStages.Setup)
    } else {
      onHide()
    }

  }

  return (
    <TwoFaContainer>
      <CloseButton onClick={onClose}>X</CloseButton>

      <Typography
        text={
          twoFaStage === TwoFaStages.PcHelp ||
          twoFaStage === TwoFaStages.MobileHelp
            ? 'two factor authentication help'
            : 'two factor authentication'
        }
        fontSize="fz48"
        fontWeight="bold"
      />

      <TwoFaStageSelection
        stage={twoFaStage}
        onStageChange={(newStage: TwoFaStages) => setTwoFaStage(newStage)}
        onHide={onHide}
        onSuccess={onSuccess}
      />
    </TwoFaContainer>
  );
};

const TwoFaContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export default TwoFaModal;

import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';

import { sm, xlg } from 'utils/constants';

/**
 * @param customPosition full postion style
 */
export const ContactButton = styled(Button)<{ customPosition?: string }>`
  font-family: 'HKGrotesk-Black';

  width: 172.23px;
  min-width: 172.23px;

  background: #000;
  border-color: #ffd600;

  margin-bottom: 15px;
  padding: 0 35px;

  ${(props) => (props.customPosition ? props.customPosition : '')}

  @media (min-width: ${sm}px) {
    margin-left: 0;
    margin-bottom: 0;
  }

  @media (min-width: ${xlg}px) {
    width: 220px;
    min-width: 220px;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';

  @media (min-width: ${xlg}px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

import store from "store/store";
import jwtDecode from "jwt-decode";
import graphService from "./graph.service";
import websocketClient from "./websocket.service"

import { 
  AuthJWTContents, 
  AuthPayload, 
  AuthEmailPayload, 
  RefreshTokenPayload, 
  ConfirmEmailPayload, 
  ChangeEmailPayload, 
  RegisterUserPayload,
} from "store/models/authModel/authModel.types";

class AuthService {
  auth: string | null;
  initialized: boolean;
  ready: boolean;
  constructor() {
    this.auth = null;
    this.initialized = false;
    this.ready = false;
  }
  init = async () => {
    if (this.initialized) return;
    this.initialized = true;
    this.auth = await store.getActions().authentication.getAuthToken();
    const websocketURL = process.env.REACT_APP_REST_URL?.replace('https', 'wss') + '/ws';
    await websocketClient.init(websocketURL);
  }
  register = async (variables: RegisterUserPayload) => {
    return await graphService.mutation(`registerUser`, variables);
  }
  authenticate = async (username: string, password: string) => {
    const email_regex = /^[A-Za-z0-9._-]+(\+[A-Za-z0-9._-]+)?@[A-Za-z0-9.-]+[.][A-Za-z]{2,5}$/;
    const is_email_login = email_regex.test(username);
    const mutation_name = (is_email_login ? `authenticateEmail` : `authenticate`);
    const variables = (is_email_login ? { email: username, password } : { username, password });
    return this.authenticateMutation(mutation_name, variables);
  }
  confirmEmail = async (tokenValue: string) => {
    return this.authenticateMutation(`userConfirmationEmail`, { tokenValue });
  }
  changeEmail = async (variables: ChangeEmailPayload) => {
    return await graphService.mutation(`userChangeEmailRequest`, variables);
  }
  refreshToken = async (token: string) => {
    return this.authenticateMutation(`refreshToken`, { refreshToken: token });
  }
  authenticateMutation = async (
    mutation_name: "authenticate" | "authenticateEmail" | "refreshToken" | "userConfirmationEmail", 
    variables: ConfirmEmailPayload | RefreshTokenPayload | AuthPayload | AuthEmailPayload
  ) => {
    const { data, error } = await graphService.mutation(mutation_name, variables);
    if (data) {
      data.auth.tokenData = this.parseToken(data.auth.accessToken);
    }
    return { data, error }
  }
  parseToken = (token: string) => {
    let decoded;
    try {
      decoded = (jwtDecode(token) as AuthJWTContents);
    } catch(e) {
      throw new Error("Invalid JWT");
    }
    const is_artist = (decoded.role === "app_artist" ? true : false);
    return Object.assign(decoded, {is_artist});
  }
}



const authService = new AuthService;

export default authService;


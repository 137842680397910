import * as React from 'react';

import { IconProps } from '../common.types';

const Eye = ({ width = 19, height = 19 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.054 0 .214 6.14.145 6.253a.667.667 0 0 0-.034.78c.011.025 3.235 6.3 9.889 6.3 6.626 0 9.845-6.214 9.884-6.29a.667.667 0 0 0-.029-.79C19.787 6.14 15.945 0 10 0Zm0 2a4.667 4.667 0 1 1 0 9.333A4.667 4.667 0 0 1 10 2Zm0 2.667a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      fill="#FFD600"
    />
  </svg>
);

export default Eye;

import { action, thunkOn } from 'easy-peasy';

import { GlobalModel } from 'store/models/globalModel/globalModel.types';

export const globalbanner: GlobalModel = {
  show_global_banner: false,
  banner: {
    text: '',
    title: ''
  },
  setGlobalBanner: action((state, payload) => {
    state.banner.title = payload.title;
    state.banner.text = payload.text;
    state.show_global_banner = true
  }),
  hideGlobalBanner: action((state) => {
    state.show_global_banner = false
    state.banner.title = '';
    state.banner.text = '';
  }),
  onWebsocketDoReload: thunkOn(
    (_, storeActions) => storeActions.websocket.doReload,
    (actions) => {
      actions.setGlobalBanner({
        title: 'System Update:',
        text: 'Reloading',
      });
    }
  ),
  /*onWebsocketSetReloadAfterUnblock: thunkOn(
    (_, storeActions) => storeActions.websocket.setReloadAfterUnblock,
    (actions, target) => {
      if (target.payload === true) {
        actions.setGlobalBanner({
          title: 'SongBits Update Available:',
          text: 'Reloading after critical process',
        });
      }
    }
  )*/
};

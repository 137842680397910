import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';

import { lg, md, sm, xlg } from 'utils/constants';

export const HeadContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  position: relative;

  width: 100%;
  padding: 10px 0 0 0;

  z-index: 1;

  @media (min-width: ${sm}px) {
    padding: 48px 48px 0 48px;
  }

  @media (min-width: ${md}px) {
    padding-top: 135px;
    padding-left: 70px;
  }

  @media (min-width: ${lg}px) {
    padding-top: 75px;
    padding-left: 13px;
  }

  @media (min-width: ${xlg}px) {
    padding-top: 0;
    padding-left: 13px;

    justify-content: center;
  }
`;

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  padding: 70px 20px 15px 57px;

  @media (min-width: ${sm}px) {
    min-width: 700px;
    letter-spacing: -0.05em;
  }

  @media (min-width: ${md}px) {
    margin-left: 130px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 0px;
  margin-bottom: 10px;
  gap: 15px;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    justify-content: start;

    position: initial;
    gap: 0;
    padding-left: 48px;
  }

  @media (min-width: ${md}px) {
    margin-top: 10px;
    padding-left: 185px;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 65px;
  line-height: 55px;
  letter-spacing: -0.03em;

  @media (min-width: ${md}px) {
    font-size: 100px;
    line-height: 80px;
    letter-spacing: -0.05em;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  position: relative;
  left: -5px;

  margin-bottom: 320px;

  @media (min-width: ${md}px) {
    display: flex;
    margin-bottom: 45px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: ${md}px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: ${md}px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: -20px;
  width: 140%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: ${(props) => props.banner && `url(${props.banner})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;

  @media (min-width: ${md}px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
    background-size: cover;
  }
`;

export const FirstButton = styled(Button)<{
  opacity?: number;
}>`
  width: 172.2px;
  min-width: 172.2px;

  border-radius: 50px;

  padding: 10px 30px;
  ${(props) => props.opacity && `opacity: ${props.opacity}`};

  @media (min-width: ${sm}px) {
    min-width: 168px;
  }

  @media (min-width: ${xlg}px) {
    width: 220px;
    min-width: 220px;

    padding: 15px 30px;
  }
`;

export const LeftMarginButton = styled(Button)<{
  opacity?: number;
}>`
  width: 182.2px;
  min-width: 172.2px;
  padding: 10px 30px;

  ${(props) => props.opacity && `opacity: ${props.opacity}`};

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${sm}px) {
    min-width: 168px;
    margin-left: 19px;
  }

  @media (min-width: ${xlg}px) {
    width: 220px;
    min-width: 220px;

    padding: 15px 30px;
  }
`;


export const ContentButton = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  line-height: 95%;

  @media (min-width: ${xlg}px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.023em;
  margin-bottom: 20px;

  @media (min-width: ${sm}px) {
    font-size: 18px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const TextContentBold = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.03em;
  font-weight: bold;

  @media (min-width: ${sm}px) {
    font-size: 18px;
  }
`;

export const IconsContainer = styled.div`
  gap: 20px;
  z-index: 3;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: end;
  justify-content: center;
  margin: 64px 0 15px;
  position: absolute;
  right: 0;
  bottom: 130px;

  @media (min-width: ${sm}px) {
    gap: 13px;
    margin: initial;
    right: 50px;
    justify-content: initial;
    width: 400px;
  }
`;

export const Icon = styled.div`
  width: 70px;
  padding: 8px;
  border-radius: 10px;
  background-color: #ffffff;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const TextContentInnerContainer = styled.div``;


export const TextLinkContainer = styled.p`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #fff;
  display: flex;

  @media (min-width: ${sm}px) {
    font-size: 14px;
  }

  @media (min-width: ${md}px) {
    font-size: 18px;
  }
`;

export const TextLink = styled(Link)`
padding-left: 5px;
padding-right: 0;
display: in
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { escToClose } from 'utils/functions';

import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout'


type ModalProps = {
  show: boolean;
  onClose: () => void;
  content: JSX.Element
};

const ErrorModal = ({ onClose, content, show }: ModalProps) => {

  useEffect(escToClose(onClose), []);

  return (
    <SBModal
      className={'error'}
        isOpen={show}
        width={'440px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
            <ModalLayout onClose={onClose} title={'oops!'}>
                <ContentContainer className={'error-modal'}>
                {content}
                </ContentContainer>
            </ModalLayout>
        }
      />
  );
};

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 400px;
`;

export default ErrorModal;

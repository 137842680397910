import { useFormik } from 'formik';
import React, { useMemo, FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';

import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import restService from 'services/rest.service';
import { BeatLoader } from 'react-spinners';
import { useStoreActions } from 'store/store';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
});

interface PaymentDetails {
  paymentMethod: string;
  totalAmount: string;
  availableAmount: string;
  currency: string;
  status: string;
}

interface ModalProps {
  onChange: () => void;
  uuid: string | undefined;
}

const CardPaymentResultModal: FC<ModalProps> = ({ onChange, uuid }) => {
  const viewport = useViewport();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>();

  useEffect(() => {
    restService.getPayment(uuid).then((res) => { 

      setPaymentDetails({
        paymentMethod: res.source,
        totalAmount: res.amount,
        availableAmount: String((parseFloat(res.data.amount.amount) - parseFloat(res.fees_amount)).toFixed(2)),
        currency: res.currency,
        status: res.status,
      });
    }).catch((error) => {
      setGlobalBanner({
        title: 'Failed to retrieve payment details:',
        text: error.message,
      });
    });
  }, []);

  const initialValues = useMemo(
    () => ({
      paymentMethod: '',
      totalAmount: '',
      availableAmount: '',
      currency: 'USD',
      status: '',
    }),
    [],
  );

  const {handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {}

  function handleClose() {
    onChange();
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="card payment result"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      {!paymentDetails ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: 'inherit',
            marginTop: '180px',
          }}>
          <BeatLoader loading={!paymentDetails} />
        </div>
      ) : (
        <>
          <InputContainer>
            <InputWithIcon
              value={`Payment Method: ${paymentDetails.paymentMethod}`}
              height={70}
              type="text"
              onChange={handleChange('paymentMethod')}
              placeholder={paymentDetails.paymentMethod}
              withBottomLine
              error={
                Boolean(errors.paymentMethod && touched.paymentMethod)
                  ? errors.paymentMethod
                  : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <InputWithIcon
              value={`Total Amount: ${paymentDetails.totalAmount}`}
              height={70}
              type="text"
              onChange={handleChange('amount')}
              placeholder={paymentDetails.totalAmount}
              withBottomLine
              error={
                Boolean(errors.totalAmount && touched.totalAmount)
                  ? errors.totalAmount
                  : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <InputWithIcon
              value={`Available Amount: ${paymentDetails.availableAmount}`}
              height={70}
              type="text"
              onChange={handleChange('amount')}
              placeholder={paymentDetails.availableAmount}
              withBottomLine
              error={
                Boolean(errors.availableAmount && touched.availableAmount)
                  ? errors.availableAmount
                  : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <InputWithIcon
              value={`Currency: ${paymentDetails.currency}`}
              height={70}
              type="text"
              onChange={handleChange('currency')}
              placeholder={paymentDetails.currency}
              withBottomLine
              error={
                Boolean(errors.currency && touched.currency)
                  ? errors.currency
                  : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <InputWithIcon
              value={`Status: ${paymentDetails.status}`}
              height={70}
              type="text"
              onChange={handleChange('status')}
              placeholder={paymentDetails.status}
              withBottomLine
              error={
                Boolean(errors.status && touched.status)
                  ? errors.status
                  : undefined
              }
            />
          </InputContainer>
        </>
      )}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const InputWithIcon = styled(TextInput)`
  & > input {
    margin-top: -7px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export default CardPaymentResultModal;

import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';
import Button from 'common/components/Button/Button';

import { md, sm, xlg } from 'utils/constants';

export const HeadingSection = styled.div`
  @media (min-width: 768px) {
    min-width: 700px;
  }

  @media (min-width: 992px) {
    margin-left: 130px;
  }
`;

export const HeadContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 99px 34px 0 34px;
  z-index: 1;

  @media (min-width: ${sm}px) {
    padding: 99px 50px 0 50px;
  }

  @media (min-width: ${md}px) {
    padding: 135px 67px 0 67px;
  }

  @media (min-width: ${xlg}px) {
    padding-top: 0;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const FirstButton = styled(Button)`
  font-family: 'HKGrotesk-Black';
  line-height: 95%;

  padding: 0 50px;
  margin: 40px auto 0 auto;

  &:hover {
    background-color: initial;
  }

  @media (min-width: ${sm}px) {
    margin: 40px 0 0 0;
  }

  @media (min-width: ${md}px) {
    margin-top: 80px;
  }
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
}>`
  line-height: 24px;
  letter-spacing: -0.03em;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export const Icon = styled.div`
  position: absolute;
  bottom: 22px;
  left: 9px;

  @media (min-width: ${sm}px) {
    bottom: 25px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
  }
  & > label {
    font-size: 12px;
    letter-spacing: -0.05em;

    padding-left: 23px;
    margin-top: -1px;
  }
  & > input:focus + label {
    font-size: 0px;
  }
  & > input:valid + label {
    font-size: 0px;
  }
  & > div:nth-child(4) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${md}px) {
    & > label {
      font-size: 18px;
    }
  }
`;

export const TextContentNew = styled(TextContent)`
  font-family: 'HKGrotesk-Black';
  margin-top: 31px;

  @media (min-width: ${sm}px) {
    font-size: 24px;
  }
`;

export const Form = styled.div`
  width: 350px;

  padding-top: 20px;
`;

export const PasswordVisibility = styled.div`
  position: absolute;
  right: 30px;
  bottom: 23px;

  cursor: pointer;
  z-index: 5;
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 60px;
  letter-spacing: -0.035em;

  @media (min-width: 992px) {
    font-size: 100px;
    letter-spacing: -0.054em;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const HeadContent = styled(StrapLineTypography)`
  flex-shrink: 0;
  font-family: 'HKGrotesk-Black';
`;

export const HiddenContainer = styled.div`
  display: none;
`;

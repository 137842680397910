import * as React from 'react';

import { IconProps } from '../common.types';

const EmailSupport = ({ width = 52, height = 52 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0215 52.0431C40.3928 52.0431 52.0431 40.3928 52.0431 26.0215C52.0431 11.6502 40.3928 0 26.0215 0C11.6502 0 0 11.6502 0 26.0215C0 40.3928 11.6502 52.0431 26.0215 52.0431Z"
      fill="#FFD600"
    />
    <path
      d="M35.6 21.75L26 27.6875L16.4 21.75V19.375L26 25.3125L35.6 19.375M35.6 17H16.4C15.068 17 14 18.0569 14 19.375V33.625C14 34.2549 14.2529 34.859 14.7029 35.3044C15.153 35.7498 15.7635 36 16.4 36H35.6C36.2365 36 36.847 35.7498 37.2971 35.3044C37.7471 34.859 38 34.2549 38 33.625V19.375C38 18.7451 37.7471 18.141 37.2971 17.6956C36.847 17.2502 36.2365 17 35.6 17Z"
      fill="black"
    />
  </svg>
);

export default EmailSupport;

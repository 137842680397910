import axios from 'axios';

import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import restService from 'services/rest.service';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';
import { notify } from 'common/components/Toaster/Toaster';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Email from 'common/icons/Email.icon';
import UserProfile from 'common/icons/UserProfile.icon';
import PageContainer from 'common/layout/PageContainer';
import Radio from 'modules/auth/components/waitlist/Radio';
import theme from 'theme/theme';
import Caption from 'common/components/Caption/Caption';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';

import AccountBanner from '../assets/giveaway-background-faded.jpg';

import { useStoreActions, useStoreState } from 'store/store';
import {
  ContentButton,
  FirstButton,
  Form,
  HeadContainer,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  Wrapper,
  HeaderTitleExtraContainer,
  UserTypeContainer,
  ErrorTypography,
  UserTypeTitleContainer,
  TermsContainer,
  LoginLink,
} from './styled/GiveawayWaitlist.styled';

import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  userType: Yup.string().required('User type is required'),
  walletAddress: Yup.string()
    .min(26, 'code is too short. must be 26 - 36 chars')
    .max(36, 'code is too long. must be 26 - 36 chars'),
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REST_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const GiveawayWaitlist = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const location = useLocation();

  const setPreviousUrl = useStoreActions(
    (actions) => actions.location.setPreviousUrl,
  );
  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  const [toastId, setToastId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      userType: '',
      walletAddress: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitAuthHandler() {
    toast.dismiss(toastId);
    setLoading(true);

    restService
      .sxswGiveaway()
      .then((res) => {
        res;
        navigate('/giveaway/sxsw/claim');
        setLoading(false);
      })
      .catch((error) => {
        if (error.message === 'Already joined giveaway' || error.message === 'User Already Exists' || error.message === 'Already In Waitlist') {
          navigate('/giveaway/sxsw/claim')

          setLoading(false);
        } else {
          const toastId = notify({
            autoClose: false,
            customComponent: (
              <ToasterModal
                title="oops!"
                content="Something went wrong. Please try again."
              />
            ),
          });
          setToastId(toastId as string);
        }
        setLoading(false);
      });
  }

  async function submitHandler() {
    toast.dismiss(toastId);
    setLoading(true);

    const data = Object.assign({}, values);

    if (values.userType === 'Artist') {
      data.userType = 'artist';
    }
    if (values.userType === 'Fan/Collector') {
      data.userType = 'fan';
    }

    axiosInstance
      .post('/account/giveaway', data)
      .then((res) => {
        res;
        navigate('/giveaway/sxsw/thanks');
        setLoading(false);
      })
      .catch((error) => {
        if (
          (error.response.data &&
            error.response.data.error === 'Already joined giveaway') ||
          (error.response.data &&
            error.response.data.error === 'User Already Exists') ||
          (error.response.data &&
            error.response.data.error === 'Already In Waitlist')
        ) {
          errors.email = 'Looks like that email is already in use.';
        } else {
          const toastId = notify({
            autoClose: false,
            customComponent: (
              <ToasterModal
                title="oops!"
                content="Something went wrong. Please try again."
              />
            ),
          });
          setToastId(toastId as string);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    setBreadcrumbs([
      { value: 'Home', label: 'Home' },
      { value: 'giveaway', label: 'Giveaway' },
      { value: 'sxsw', label: 'SXSW' },
    ]);

    return () => {
      setBreadcrumbs([]);
    };
  }, []);

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer>
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={'count me '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="in"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                &nbsp;&nbsp;
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          {!isAuth ? (
            <>
              <HeaderTitleExtraContainer>
                <Typography text={'Already a SongBits user? '}></Typography>
                <LoginLink
                  to="/login"
                  onClick={() => {
                    setPreviousUrl(location.pathname + '/claim');
                    navigate('/login');
                  }}>
                  Login now
                </LoginLink>
              </HeaderTitleExtraContainer>

              <InputContainer>
                <Icon>
                  <UserProfile />
                </Icon>
                <InputWithIcon
                  value={values.name}
                  inputName={'name'}
                  height={viewport.width < xs ? 65 : 70}
                  type="text"
                  onChange={handleChange('name')}
                  placeholder="Name"
                  autoComplete="name"
                  withBottomLine
                  error={
                    Boolean(errors.name && touched.name)
                      ? errors.name
                      : undefined
                  }
                />
              </InputContainer>

              <InputContainer>
                <Icon>
                  <Email />
                </Icon>
                <InputWithIcon
                  value={values.email}
                  height={viewport.width < xs ? 65 : 70}
                  type="email"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Your Email"
                  autoComplete="email"
                  inputName={'email'}
                  withBottomLine
                  error={
                    Boolean(errors.email && touched.email)
                      ? errors.email
                      : undefined
                  }
                />
              </InputContainer>

              <UserTypeContainer>
                <Icon top="20">
                  <UserProfile />
                </Icon>

                <UserTypeTitleContainer>
                  <Caption text="I'm a" />
                </UserTypeTitleContainer>

                <Radio
                  radio={values.userType}
                  setRadio={handleChange('userType')}
                />
                {errors.userType && touched.userType && (
                  <ErrorTypography
                    text={'User type is required'}
                    fontSize="fz10"
                    fontColor={theme.colors.yellow}
                  />
                )}
              </UserTypeContainer>
              <TermsContainer>
                I agree to receiving information on the forthcoming releases by
                email and other promotional material.
              </TermsContainer>
            </>
          ) : (
            <>
              <UserTypeContainer>
                <Typography
                  text={
                    'I hear you are looking for some freebies?'
                  }></Typography>
              </UserTypeContainer>
              <Spacer height={10}></Spacer>
            </>
          )}

          <FirstButton
            height={45}
            width={270}
            borderRadius={50}
            borderColor={theme.colors.white}
            isLoading={loading}
            disabled={loading}
            label={
              <div style={{ display: 'flex' }}>
                {isAuth ? (
                  <>
                    <ContentButton
                      text="yes, give me the"
                      fontSize="fz16"
                      fontWeight="bold"
                    />
                    &nbsp;
                    <ContentButton
                      text="freebies!"
                      fontSize="fz16"
                      fontColor={theme.colors.yellow}
                      fontWeight="bold"
                    />
                  </>
                ) : (
                  <>
                    <ContentButton
                      text="join"
                      fontSize="fz16"
                      fontWeight="bold"
                    />
                    &nbsp;
                    <ContentButton
                      text="in"
                      fontSize="fz16"
                      fontColor={theme.colors.yellow}
                      fontWeight="bold"
                    />
                  </>
                )}
              </div>
            }
            onClick={() => {
              if (isAuth) {
                submitAuthHandler();
              } else {
                handleSubmit();
              }
            }}
          />
        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(GiveawayWaitlist);

import * as React from 'react';

import { IconProps } from '../common.types';

const TikTok = ({ width = 15, height = 15, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9641 5.62963C18.7499 5.3665 17.6516 4.73109 16.8266 3.81457C16.0016 2.89806 15.4925 1.74755 15.3722 0.528153V0H11.1701V16.4087C11.171 17.1368 10.9394 17.8467 10.5081 18.4379C10.0768 19.029 9.46772 19.4714 8.76711 19.7025C8.0665 19.9335 7.30989 19.9414 6.60446 19.7252C5.89902 19.5089 5.28052 19.0793 4.83657 18.4974C4.43883 17.9822 4.19528 17.3677 4.13345 16.7232C4.07163 16.0788 4.194 15.4302 4.48673 14.8507C4.77946 14.2712 5.23087 13.784 5.78991 13.4442C6.34894 13.1044 6.99331 12.9255 7.65013 12.9277C8.01339 12.9257 8.37483 12.9783 8.72197 13.0838V8.88257C8.318 8.83327 7.91091 8.81321 7.50397 8.82255C6.005 8.86202 4.55024 9.33142 3.31783 10.1733C2.08542 11.0151 1.12886 12.1929 0.565274 13.5623C0.00169146 14.9317 -0.144448 16.4334 0.14475 17.8834C0.433949 19.3335 1.14593 20.6689 2.19352 21.7263C3.26716 22.802 4.64107 23.539 6.14067 23.8435C7.64026 24.1479 9.19785 24.0062 10.6155 23.4363C12.0332 22.8664 13.247 21.894 14.1026 20.6427C14.9582 19.3914 15.4171 17.9177 15.4209 16.4087V8.00632C17.1157 9.1994 19.148 9.83762 21.2308 9.83084V5.74966C20.8216 5.75133 20.4135 5.7111 20.0128 5.62963H19.9641Z"
      fill="white"
      fillOpacity={opacity}
    />
  </svg>
);

export default TikTok;

import React, { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Caption from 'common/components/Caption/Caption';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import AppleMusic from 'common/icons/AppleMusic.icon';
import Facebook from 'common/icons/Facebook.icon';
import Instagram from 'common/icons/Instagram.icon';
import SpotifyGrey from 'common/icons/SpotifyGrey.icon';
import TikTok from 'common/icons/TikTok.icon';
//import TrackdGrey from 'common/icons/TrackdGrey.icon';
import Twitter from 'common/icons/Twitter.icon';
import YouTube from 'common/icons/YouTube.icon';
import SoundCloud from 'common/icons/SoundCloud.icon';
import { MusicPlatformsTypes, SocialMediaTypes } from 'modules/artist/types';
import theme from 'theme/theme';

import { humanizeNumber } from 'utils/humanize';

type MediaTypes = SocialMediaTypes & MusicPlatformsTypes;

type MediaProps = {
  values: MediaTypes;
  type: 'socialMedia' | 'musicPlatforms';
  caption?: string;
  tip?: string;
  isFollowersCount?: boolean;
  handleSetState?: (field: string, value: string) => void;
  buttonSize?: number;
  followerCount?: string;
  hideBottomLine?: boolean;
  artistIcons?: boolean;
  updateSocials?: () => void;
};

const Media: React.FC<MediaProps> = ({
  type = 'socialMedia',
  handleSetState,
  values,
  caption,
  tip,
  isFollowersCount = false,
  followerCount,
  buttonSize = 25,
  hideBottomLine,
  artistIcons,
  // updateSocials,
}) => {
  const [isMediaOpen, openMedia] = useState<boolean>(false);

  const isSocialMedia = useMemo(() => type === 'socialMedia', [type]);

  const viewport = useViewport();

  const media = {
    socialMedia: {
      title: 'Social Media',
      array: [
        {
          title: 'Facebook',
          image: (
            <Facebook
              width={24}
              height={24}
              opacity={values.facebook && values.facebook !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'facebook',
          link: values.facebook && values.facebook !== 'null'
            ? `https://www.facebook.com/${values.facebook}`
            : '',
        },
        {
          title: 'Instagram',
          image: (
            <Instagram
              width={24}
              height={24}
              opacity={values.instagram && values.instagram !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'instagram',
          link: values.instagram && values.instagram !== 'null'
            ? `https://www.instagram.com/${values.instagram}`
            : '',
        },
        {
          title: 'Twitter',
          image: (
            <Twitter
              width={24}
              height={24}
              opacity={values.twitter && values.twitter !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'twitter',
          link: values.twitter && values.twitter !== 'null'
            ? `https://www.twitter.com/${values.twitter}`
            : '',
        },
        {
          title: 'YouTube',
          image: (
            <YouTube
              width={24}
              height={24}
              opacity={values.youtube && values.youtube !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'youtube',
          link: values.youtube && values.youtube !== 'null'
            ? `https://www.youtube.com/${values.youtube}`
            : '',
        },
        {
          title: 'TikTok',
          image: (
            <TikTok width={24} height={24} opacity={values.tiktok && values.tiktok !== 'null' ? 1 : 0.5} />
          ),
          field: 'tiktok',
          link: values.tiktok && values.tiktok !== 'null' ? `https://www.tiktok.com/${values.tiktok}` : '',
        },
      ],
    },
    musicPlatforms: {
      title: 'Music platform links',
      array: [
        /*{
          title: 'Trackd',
          image: (
            <TrackdGrey
              width={32}
              height={24}
              opacity={values.trackd ? 1 : 0.5}
            />
          ),
          field: 'trackd',
          link: values.trackd ? `${values.trackd}` : '',
        },*/
        {
          title: 'Spotify',
          image: (
            <SpotifyGrey
              width={24}
              height={24}
              opacity={values.spotify && values.spotify !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'spotify',
          link: values.spotify && values.spotify !== 'null' ? `${values.spotify}` : '',
        },
        {
          title: 'Apple Music',
          image: (
            <AppleMusic
              width={24}
              height={24}
              opacity={values.applemusic && values.applemusic !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'applemusic',
          link: values.applemusic && values.applemusic !== 'null' ? `${values.applemusic}` : '',
        },
        {
          title: 'YouTube',
          image: (
            <YouTube
              width={24}
              height={24}
              opacity={values.youtube && values.youtube !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'youtube',
          link: values.youtube && values.youtube !== 'null' ? `${values.youtube}` : '',
        },{
          title: 'SoundCloud',
          image: (
            <SoundCloud
              width={48}
              height={24}
              opacity={values.soundcloud && values.soundcloud !== 'null' ? 1 : 0.5}
            />
          ),
          field: 'soundcloud',
          link: values.soundcloud && values.soundcloud !== 'null' ? `${values.soundcloud}` : '',
        },
      ],
    },
  };

  const { title, array } = media[type];

  return (
    <>
      <Caption text={caption || title} tip={tip} />
      <Spacer height={20} />

      {isMediaOpen ? (
        <SocialMediaCloseContainer>
          <Button
            label="-"
            className='media-close'
            fontSize={viewport.width >= 576 ? buttonSize : 20}
            width={buttonSize}
            height={buttonSize}
            onClick={() => openMedia(false)}
          />
        </SocialMediaCloseContainer>
      ) : (
        <></>
      )}

      <SocialMediaContainer>
        {isMediaOpen ? (
          <>
            <SocialMediaInputs>
              {array.map(({ title, field }, i) => (
                <div key={`Key for Media TextInput item is - ${field} - ${i}`}>
                  <TextInput
                    withBorder
                    key={`Key for MediaInput is - ${title} - ${i}`}
                    width={300}
                    height={50}
                    type="text"
                    inputName={field}
                    value={values?.[field as keyof MediaTypes] || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSetState &&
                      handleSetState(`${type}.${field}`, e.currentTarget.value)
                    }
                    placeholder={`${title} ${isSocialMedia ? 'username' : ''}`}
                  />
                  <Spacer height={20} />
                </div>
              ))}
            </SocialMediaInputs>
          </>
        ) : (
          <>
            <SocialMedia small={!artistIcons}>
              {array.map(({ image, title, link, field }, i) => (
                <React.Fragment key={`social-media-${title}-${i}`}>
                  {link ? (
                    <>
                      <MediaImage key={`Key for media image is - ${link} - ${i}`}>
                        <a
                          href={link ? link : '#'}
                          className={field + '-link'}
                          target={link ? 'new_blank' : ''}
                          key={`Key for media link is - ${title} - ${i}`}>
                          {image}
                        </a>
                      </MediaImage>
                    </>
                  ) : (
                    <>
                      <Unavailable>
                        <MediaImage>{image}</MediaImage>
                      </Unavailable>
                    </>
                  )}
                </React.Fragment>
              ))}
            </SocialMedia>
            {!caption && (
              <Button
                label="+"
                className='media-open'
                fontSize={viewport.width >= 576 ? buttonSize : 20}
                width={buttonSize}
                height={buttonSize}
                onClick={() => openMedia(true)}
              />
            )}
          </>
        )}
      </SocialMediaContainer>
      {isFollowersCount && followerCount && (
        <>
          <Spacer height={20} />
          <LightText
            text={
              'Combined Followers: ' + humanizeNumber(parseInt(followerCount))
            }
            fontColor={theme.colors.white}
            fontWeight="regular"
            fontSize="fz18"
          />
        </>
      )}
      <Spacer height={viewport.width >= 576 ? 30 : 15} />
      {!hideBottomLine && <BottomLine />}
    </>
  );
};

const SocialMediaContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SocialMediaCloseContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: end;
  justify-content: end;
`;

const Unavailable = styled.span`
  cursor: no-drop;
`;

const SocialMedia = styled.div<{ small?: boolean }>`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: ${(props) => (props.small ? 'scale(0.8)' : 'none')};
  margin-left: ${(props) => (props.small ? '-20px' : '0')};

  @media (min-width: 576px) {
    transform: none;
    margin-left: 0;
  }
`;

const SocialMediaInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MediaImage = styled.div`
  padding-right: 20px;
`;

const BottomLine = styled.div`
  height: 1px;
  width: 105%;
  opacity: 0.5;
  margin-left: -5px;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

const LightText = styled(Typography)`
  font-family: 'HKGrotesk-Light';
`;

export default Media;

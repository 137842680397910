import * as React from 'react';

import { IconProps } from '../common.types';

const AppleMusic = ({ width = 15, height = 15, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3859 8.49932C19.2788 8.56873 16.7284 10.0335 16.7284 13.2813C16.8486 16.9851 19.9468 18.284 20 18.284C19.9468 18.3535 19.5322 20.0535 18.3041 21.8356C17.3294 23.3707 16.2476 24.9184 14.6049 24.9184C13.0423 24.9184 12.4814 23.8953 10.6783 23.8953C8.74208 23.8953 8.19421 24.9184 6.71174 24.9184C5.069 24.9184 3.90707 23.2877 2.87927 21.7671C1.544 19.7768 0.409068 16.6534 0.369001 13.6544C0.342 12.0653 0.636403 10.5031 1.38373 9.17628C2.43854 7.32389 4.32167 6.06644 6.37814 6.02497C7.95381 5.96999 9.35615 7.14452 10.3177 7.14452C11.2393 7.14452 12.9622 6.02497 14.9115 6.02497C15.7529 6.02588 17.9966 6.28818 19.3859 8.49932ZM10.1845 5.70768C9.90401 4.25642 10.6783 2.80516 11.3995 1.87942C12.3211 0.759882 13.7766 0 15.0317 0C15.1118 1.45126 14.604 2.87457 13.6964 3.91119C12.882 5.03073 11.4797 5.87354 10.1845 5.70768Z"
      fill="white"
      fillOpacity={opacity}
    />
  </svg>
);

export default AppleMusic;

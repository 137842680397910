import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalTypes } from './modals.types';

interface ModalState {
  timestamp: number;
  expiryTime: number;
  location: string;
  modalType: ModalTypes;
  modalState: string;
  showState: any;
  dataState: any;
  isReloaded: boolean;
}

type modalStateTypes = 'none' | 'MetaMaskRequired' | 'ConnectionSuccessful';

interface ModalStateType {
  type: ModalTypes;
  state: modalStateTypes;
}

const initialState: ModalState = {
  timestamp: 0,
  expiryTime: 0,
  location: '',
  modalType: 'none',
  modalState: '',
  showState: false,
  dataState: undefined,
  isReloaded: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLocation(state, action: PayloadAction<string>) {
      state.location = action.payload;
      state.timestamp = Date.now();
    },
    setModal(state, action: PayloadAction<ModalStateType>) {
      state.modalType = action.payload.type;
      state.modalState = action.payload.state;
      state.timestamp = Date.now();
    },
    setModalType(state, action: PayloadAction<ModalTypes>) {
      state.modalType = action.payload;
      state.timestamp = Date.now();
    },
    setPersistModalState(state, action: PayloadAction<modalStateTypes>) {
      state.modalState = action.payload;
      state.timestamp = Date.now();
    },
    setShowState(state, action: PayloadAction<any>) {
      state.showState = action.payload;
      state.timestamp = Date.now();
    },
    setDataState(state, action: PayloadAction<any>) {
      state.dataState = action.payload;
      state.timestamp = Date.now();
    },
    setIsReloaded(state, action: PayloadAction<boolean>) {
      state.isReloaded = action.payload;
      state.timestamp = Date.now();
    },
    setExpiryTime(state, action: PayloadAction<number>) {
      state.expiryTime = action.payload;
    },
    resetPersist(state) {
      state.location = '';
      state.modalType = 'none';
      state.modalState = '';
      state.showState = false;
      state.dataState = undefined;
      state.isReloaded = false;
      state.timestamp = 0;
      state.expiryTime = 0;
    },
  },
});

export const {
  setLocation,
  setModalType,
  resetPersist,
  setShowState,
  setDataState,
  setIsReloaded,
  setExpiryTime,
  setPersistModalState,
} = modalSlice.actions;

export default modalSlice.reducer;

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SBModal from 'common/components/Modal/SBModal';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void
}

const GiveawayError: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
}) => {
  const viewport = useViewport();
  const navigate = useNavigate();

  return (
    <SBModal
      className={'error'}
      isOpen={Boolean(isOpen)}
      withProceedingText={false}
      withFeeText={false}
      feeBottomPos="60px"
      withSettings={false}
      width="440px"
      mobileWidth="100%"
      height="464px"
      top="33%"
      mobileTop="10%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose()
            }}>
            X
          </CloseButton>
          <BoldText
            text="oh no!"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          <Column>
            <Spacer height={2} />

            <Typography text={'All free bits have been claimed!'} fontSize={'fz24'} fontWeight="bold" fontColor={theme.colors.yellow} />
            <Typography text={'We’ll let you know when more become available in the future.'} fontSize={'fz24'} fontWeight="bold" fontColor={theme.colors.white} />

            <FirstButton
              height={45}
              borderRadius={50}
              bgColor={theme.colors.black}
              labelColor={theme.colors.yellow}
              borderColor={theme.colors.yellow}
              label={
                <TextContent
                  text="close"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={() => {
                navigate('/')
              }}
            />
            <Spacer height={viewport.width < 576 ? 0 : 10} />
          </Column>
        </>
      }
    />
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 40px;
  min-width: 300px;
  margin-left: -5px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 350px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export default GiveawayError;

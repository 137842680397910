import React, { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'store/store';
import styled from 'styled-components';
import * as R from 'ramda';

import useBreadcrumbs from 'hooks/useBreadcrumbs';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import { LightText } from 'modules/account/pages/styled/MyWallet.styled';
import { BreadcrumbDiv } from 'common/layout/styled/Breadcrumbs.styled';

import theme from 'theme/theme';

const customBreadcrumbsNames = {
  FAQ: 'Common Questions',
  labels: 'Artist + Labels',
  settings: 'Account Settings',
};

const MobileBreadcrumbs: FC = () => {
  const navigate = useNavigate();
  const [breadcrumbs] = useBreadcrumbs();

  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  const customPageBreadcrumbs = useStoreState(
    (state) => state.location.breadcrumbs,
  );

  const navigateToHandler = (route: string) => {
    navigate(route.replace('Home', ''));
  };

  return (
    <BreadcrumbsContainer>
      {(customPageBreadcrumbs.length === 0
        ? breadcrumbs
        : customPageBreadcrumbs
      ).map((el, index, array) => {
        const targetArr = array.map((pathPart) =>
          typeof pathPart === 'object' ? pathPart.value : pathPart,
        );

        let routeTo = R.dropLast(targetArr.length - 1 - index, targetArr).join(
          '/',
        );

        const isHeadItem =
          R.head(targetArr) === (typeof el === 'object' ? el.value : el);
        const isLastItem =
          R.last(targetArr) === (typeof el === 'object' ? el.value : el);

        let lastName;
        if (typeof el === 'object') {
          lastName = el.label;
        } else {
          if (Object.keys(customBreadcrumbsNames).includes(el)) {
            lastName = (customBreadcrumbsNames as any)[el];
          } else {
            lastName = el.replaceAll('-', ' ');
          }
        }

        switch (el) {
          case 'Home':
            routeTo = '/';
            break;

          case 'My Account':
            routeTo = '/account/settings';
            break;

          case 'library':
            routeTo = '/library/hot-drops';
            break;

          default:
            break;
        }

        return (
          el && (
            <React.Fragment
              key={`Single key to header route is: ${el} - ${index}`}>
              {isHeadItem ? (
                <>
                  <RouteButton
                    label={lastName}
                    onClick={() => navigateToHandler(routeTo)}
                    bold={true}
                  />
                  <BreadcrumbDiv isArtistPage={true} isAuth={isAuth}>
                    <MobileDivider text="|" />
                  </BreadcrumbDiv>
                </>
              ) : isLastItem ? (
                <>
                  <RouteButton
                    label={
                      <LightText
                        text={(typeof el === 'object'
                          ? el.label
                          : el
                        ).replaceAll('-', ' ')}
                        fontWeight="light"
                        fontSize="fz12"
                        fontColor={theme.colors.white}
                      />
                    }
                    disabled={isLastItem}
                    onClick={() => navigateToHandler(routeTo)}
                  />
                </>
              ) : (
                <>
                  <RouteButton
                    label={
                      <LightText
                        text={(typeof el === 'object'
                          ? el.label
                          : el
                        ).replaceAll('-', ' ')}
                        fontWeight="light"
                        fontSize="fz12"
                        fontColor={theme.colors.white}
                      />
                    }
                    disabled={isHeadItem}
                    onClick={() => navigateToHandler(routeTo)}
                  />
                  <BreadcrumbDiv isArtistPage={true} isAuth={isAuth}>
                    <MobileDivider text="|" />
                  </BreadcrumbDiv>
                </>
              )}
            </React.Fragment>
          )
        );
      })}
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const RouteButton = styled(Button)<{ bold?: boolean }>`
  font-family: ${(props) =>
    props.bold ? 'HKGrotesk-Black' : 'HKGrotesk-Light'};
  text-transform: capitalize;
  font-size: 12px;
  cursor: pointer;
  z-index: 5;
  padding-left: 3px;
  padding-right: 3px;

  &:disabled {
    color: white !important;
    opacity: 1;
  }

  @media (min-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const MobileDivider = styled(Typography)`
  font-size: 12px;
  margin-top: 1px;
`;

export default memo(MobileBreadcrumbs);

import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { ethers } from "ethers";
//import "../typings/common";
//import { BigNumber } from "@ethersproject/bignumber";

//import abiContract from 'contracts/contract.json';
//import { Exception } from 'sass';
import restService  from "./rest.service";
//import { TransactionDescription } from 'ethers/lib/utils';

//const CONTRACT_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ADDRESS;

/*
class Web3Service {
  web3: Web3;

  constructor() {
    this.web3 = new Web3(Web3.givenProvider);
  }

  getAccounts = async () => {
    return await this.web3.eth.getAccounts();
  };

  getContract = (abi = abiContract as AbiItem | AbiItem[]) => {
    return new this.web3.eth.Contract(abi, CONTRACT_ADDRESS);
  };

  sendPermissionsRequest = () => {
    return (window as any).ethereum.request({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  };
}
*/

const chains = {
  1: { name: "Ethereum Mainnet", currency: "ETH", rpc: "https://mainnet.infura.io/v3/d54bee202dc4438aa804196be6e27890" },
  11155111: { name: "Sepolia", currency: "ETH", rpc: "https://sepolia.infura.io/v3/d54bee202dc4438aa804196be6e27890" },
  11297108109: { name: "Palm", currency: "PALM", rpc: "https://palm-mainnet.infura.io/v3/d54bee202dc4438aa804196be6e27890" },
  11297108099: { name: "Palm Testnet", currency: "PALM", rpc: "https://palm-testnet.infura.io/v3/e504875614714d3aac7061d4a197b190" },
};

class MetamaskClient {
  web3: Web3;
  connected_wallet: string;
  chain: string;
  target_chain: string;
  redirect_to: string;
  balance_ETH: string;
  balance_USDC: string;
  constructor(target_chain="", redirect_to="") {
    this.connected_wallet = "";
    this.chain = "";
    this.redirect_to = redirect_to;
    this.balance_ETH = "";
    this.balance_USDC = "";
    this.target_chain = target_chain;
    this.web3 = new Web3((window as any).ethereum);
    //console.log("WEB3", this.web3.eth)
    //console.log("WINDOW ETHEREUM", (window as any).ethereum);
  }
  async connect(nickname?: string) {
    try {
      const data = await (window as any).ethereum.request({
        method: "eth_requestAccounts",
      });

      this.connected_wallet = data[0]; // TODO : handle mutiple accounts? - only brings one

      this.chain = (window as any).ethereum.networkVersion;  // Get Current Chain

      if (!nickname) {
        nickname = 'My Wallet'
      }

      if (nickname) {
        await this.saveConnectedWallet(nickname);
      } else {
        console.error("No nickname provided for wallet");
      }

      if (this.target_chain !== "" && this.chain !== this.target_chain) {
        await this.switchChain(this.target_chain).then(() => {
          if (this.redirect_to === ""){
            // (window as any).location.reload();
          } else {
            // (window as any).location.href = this.redirect_to;
          }
        });
      }
    } catch (error) {
      throw new Error("Error connecting DApp to your wallet");
    }
  }
  async switchChain(target: string) {
    try {
      type ObjectKey = keyof typeof chains;
      const myVar = parseInt(target) as ObjectKey;
      const chain = chains[myVar];
      
      if (chain.name === "Palm" || chain.name === "Palm Testnet") {
        const create_options = {
          method: "wallet_addEthereumChain",
          params: [{
            chainId: "0x" + parseInt(target).toString(16),
            chainName: chain.name,
            nativeCurrency: {
              name: chain.currency,
              symbol: chain.currency,
              decimals: 18,
            },
            rpcUrls: [chain.rpc],
            blockExplorerUrls: [
              "https://www.ondora.xyz/",
              "https://testnet.palm.chainlens.com/",
            ]
          }],
        }
        
        await (window as any).ethereum.request(create_options);

      }

      await (window as any).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + parseInt(target).toString(16) }],
      });

      this.chain = (window as any).ethereum.networkVersion;

    } catch (error) {
      console.error("Error changing your wallet chain");
      console.error(error);
      throw new Error("Error changing your wallet chain");
    }
  }
  async getBalanceETH() {
    try {

      const data = await this.web3.eth.getBalance(this.connected_wallet);

      const eth_balance = this.web3.utils.fromWei(data, "ether");
      this.balance_ETH = String(parseFloat(eth_balance).toFixed(4));

    } catch (error) {
      console.error("Error getting your wallet balance (ETH)");
      console.error(error);
      throw new Error("Error getting your wallet balance (ETH)");
    }
  }
  async getBalanceUSDC() {
    const minABI: AbiItem[] = [
      // balanceOf
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        type: "function",
      },
    ];
    const contractAddress = process.env.REACT_APP_USDC_PROXY_CONTRACT_ADDRESS || '';

    const contract = new this.web3.eth.Contract(minABI, contractAddress);

    try {
      const usdc_balance_response = await contract.methods
        .balanceOf(this.connected_wallet)
        .call();

      const usdc_balance = usdc_balance_response; // new BigNumber("", usdc_balance_response).div(10 ** 6);
      this.balance_USDC = String((parseInt(usdc_balance)/1000000).toFixed(2));

    } catch (error) {
      console.error("Error getting your USDC wallet balance");
      console.error(error);
      throw new Error("Error getting your USDC wallet balance");
    }
  }
  async transferUSDC(to: string, amount: number) {
    let transaction = null;
    try {
      const provider = new ethers.providers.Web3Provider((window as any).ethereum);
      const signer = provider.getSigner();

      const contractAddress = process.env.REACT_APP_USDC_PROXY_CONTRACT_ADDRESS || '';

      const USDCABI = [
        "function transfer(address to, uint amount)",
        "function name() view returns (string)",
      ];

      const usdcContract = new ethers.Contract(
        contractAddress,
        USDCABI,
        provider
      );

      transaction = await usdcContract
        .connect(signer)
        .transfer(to, String(amount*1000000));

    } catch (error) {
      console.error(error);
      throw error;
    }
    return transaction;
  }
  async checkUSDCToken() {
    try {

      const create_options = {
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: `${process.env.REACT_APP_USDC_PROXY_CONTRACT_ADDRESS}`,
            symbol: "USDC",
            decimals: 6,
            image: "https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=023",
          },
        },
      }

      const check_token = await (window as any).ethereum.request(create_options);

      return check_token

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async saveConnectedWallet(nickname: string) {
    return await restService.addOutgoingCryptoAddress({
      nickname: nickname,
      address: this.connected_wallet,
      chain: "ETH",
    });
  }
  hexToNumber(log: string) {
    return Web3.utils.hexToNumber(log);
  }
  async getWalletAddress() {
    try {
      const data = await (window as any).ethereum.request({
        method: "eth_requestAccounts",
      });

      this.connected_wallet = data[0]; // TODO : handle mutiple accounts? - only brings one

    } catch (error) {
      throw new Error("Error connecting DApp to your wallet");
    }
  }
}

export default MetamaskClient;

export const countryCodeById = [
  {
    value: 79,
    label: 'Afghanistan',
  },
  {
    value: 53,
    label: 'Åland',
  },
  {
    value: 36,
    label: 'Albania',
  },
  {
    value: 153,
    label: 'Algeria',
  },
  {
    value: 248,
    label: 'American Samoa',
  },
  {
    value: 170,
    label: 'Andorra',
  },
  {
    value: 9,
    label: 'Angola',
  },
  {
    value: 207,
    label: 'Anguilla',
  },
  {
    value: 251,
    label: 'Antarctica',
  },
  {
    value: 211,
    label: 'Antigua and Barbuda',
  },
  {
    value: 192,
    label: 'Argentina',
  },
  {
    value: 15,
    label: 'Armenia',
  },
  {
    value: 214,
    label: 'Aruba',
  },
  {
    value: 113,
    label: 'Australia',
  },
  {
    value: 157,
    label: 'Austria',
  },
  {
    value: 10,
    label: 'Azerbaijan',
  },
  {
    value: 205,
    label: 'Bahamas',
  },
  {
    value: 30,
    label: 'Bahrain',
  },
  {
    value: 81,
    label: 'Bangladesh',
  },
  {
    value: 182,
    label: 'Barbados',
  },
  {
    value: 51,
    label: 'Belarus',
  },
  {
    value: 165,
    label: 'Belgium',
  },
  {
    value: 223,
    label: 'Belize',
  },
  {
    value: 140,
    label: 'Benin',
  },
  {
    value: 206,
    label: 'Bermuda',
  },
  {
    value: 86,
    label: 'Bhutan',
  },
  {
    value: 195,
    label: 'Bolivia',
  },
  {
    value: 201,
    label: 'Bonaire, Sint Eustatius, and Saba',
  },
  {
    value: 178,
    label: 'Bosnia and Herzegovina',
  },
  {
    value: 71,
    label: 'Botswana',
  },
  {
    value: 181,
    label: 'Bouvet Island',
  },
  {
    value: 187,
    label: 'Brazil',
  },
  {
    value: 90,
    label: 'British Indian Ocean Territory',
  },
  {
    value: 215,
    label: 'British Virgin Islands',
  },
  {
    value: 105,
    label: 'Brunei',
  },
  {
    value: 59,
    label: 'Bulgaria',
  },
  {
    value: 136,
    label: 'Burkina Faso',
  },
  {
    value: 39,
    label: 'Burundi',
  },
  {
    value: 183,
    label: 'Cabo Verde',
  },
  {
    value: 107,
    label: 'Cambodia',
  },
  {
    value: 127,
    label: 'Cameroon',
  },
  {
    value: 249,
    label: 'Canada',
  },
  {
    value: 222,
    label: 'Cayman Islands',
  },
  {
    value: 20,
    label: 'Central African Republic',
  },
  {
    value: 22,
    label: 'Chad',
  },
  {
    value: 234,
    label: 'Chile',
  },
  {
    value: 88,
    label: 'China',
  },
  {
    value: 114,
    label: 'Christmas Island',
  },
  {
    value: 97,
    label: 'Cocos (Keeling) Islands',
  },
  {
    value: 230,
    label: 'Colombia',
  },
  {
    value: 67,
    label: 'Comoros',
  },
  {
    value: 129,
    label: 'Congo Republic',
  },
  {
    value: 112,
    label: 'Cook Islands',
  },
  {
    value: 228,
    label: 'Costa Rica',
  },
  {
    value: 180,
    label: 'Croatia',
  },
  {
    value: 203,
    label: 'Cuba',
  },
  {
    value: 200,
    label: 'Curaçao',
  },
  {
    value: 2,
    label: 'Cyprus',
  },
  {
    value: 175,
    label: 'Czechia',
  },
  {
    value: 158,
    label: 'Denmark',
  },
  {
    value: 18,
    label: 'Djibouti',
  },
  {
    value: 210,
    label: 'Dominica',
  },
  {
    value: 199,
    label: 'Dominican Republic',
  },
  {
    value: 17,
    label: 'DR Congo',
  },
  {
    value: 229,
    label: 'Ecuador',
  },
  {
    value: 35,
    label: 'Egypt',
  },
  {
    value: 224,
    label: 'El Salvador',
  },
  {
    value: 134,
    label: 'Equatorial Guinea',
  },
  {
    value: 34,
    label: 'Eritrea',
  },
  {
    value: 41,
    label: 'Estonia',
  },
  {
    value: 73,
    label: 'Eswatini',
  },
  {
    value: 33,
    label: 'Ethiopia',
  },
  {
    value: 191,
    label: 'Falkland Islands',
  },
  {
    value: 159,
    label: 'Faroe Islands',
  },
  {
    value: 116,
    label: 'Federated States of Micronesia',
  },
  {
    value: 126,
    label: 'Fiji',
  },
  {
    value: 52,
    label: 'Finland',
  },
  {
    value: 168,
    label: 'France',
  },
  {
    value: 185,
    label: 'French Guiana',
  },
  {
    value: 235,
    label: 'French Polynesia',
  },
  {
    value: 95,
    label: 'French Southern Territories',
  },
  {
    value: 141,
    label: 'Gabon',
  },
  {
    value: 146,
    label: 'Gambia',
  },
  {
    value: 48,
    label: 'Georgia',
  },
  {
    value: 166,
    label: 'Germany',
  },
  {
    value: 133,
    label: 'Ghana',
  },
  {
    value: 145,
    label: 'Gibraltar',
  },
  {
    value: 24,
    label: 'Greece',
  },
  {
    value: 188,
    label: 'Greenland',
  },
  {
    value: 221,
    label: 'Grenada',
  },
  {
    value: 218,
    label: 'Guadeloupe',
  },
  {
    value: 242,
    label: 'Guam',
  },
  {
    value: 225,
    label: 'Guatemala',
  },
  {
    value: 174,
    label: 'Guernsey',
  },
  {
    value: 147,
    label: 'Guinea',
  },
  {
    value: 138,
    label: 'Guinea-Bissau',
  },
  {
    value: 184,
    label: 'Guyana',
  },
  {
    value: 233,
    label: 'Haiti',
  },
  {
    value: 96,
    label: 'Heard and McDonald Islands',
  },
  {
    value: 226,
    label: 'Honduras',
  },
  {
    value: 104,
    label: 'Hong Kong',
  },
  {
    value: 56,
    label: 'Hungary',
  },
  {
    value: 160,
    label: 'Iceland',
  },
  {
    value: 87,
    label: 'India',
  },
  {
    value: 82,
    label: 'Indonesia',
  },
  {
    value: 1,
    label: 'Iran',
  },
  {
    value: 7,
    label: 'Iraq',
  },
  {
    value: 162,
    label: 'Ireland',
  },
  {
    value: 173,
    label: 'Isle of Man',
  },
  {
    value: 14,
    label: 'Israel',
  },
  {
    value: 155,
    label: 'Italy',
  },
  {
    value: 132,
    label: 'Ivory Coast',
  },
  {
    value: 198,
    label: 'Jamaica',
  },
  {
    value: 109,
    label: 'Japan',
  },
  {
    value: 172,
    label: 'Jersey',
  },
  {
    value: 23,
    label: 'Jordan',
  },
  {
    value: 47,
    label: 'Kazakhstan',
  },
  {
    value: 16,
    label: 'Kenya',
  },
  {
    value: 119,
    label: 'Kiribati',
  },
  {
    value: 62,
    label: 'Kosovo',
  },
  {
    value: 27,
    label: 'Kuwait',
  },
  {
    value: 94,
    label: 'Kyrgyzstan',
  },
  {
    value: 101,
    label: 'Laos',
  },
  {
    value: 42,
    label: 'Latvia',
  },
  {
    value: 25,
    label: 'Lebanon',
  },
  {
    value: 70,
    label: 'Lesotho',
  },
  {
    value: 131,
    label: 'Liberia',
  },
  {
    value: 6,
    label: 'Libya',
  },
  {
    value: 171,
    label: 'Liechtenstein',
  },
  {
    value: 43,
    label: 'Lithuania',
  },
  {
    value: 167,
    label: 'Luxembourg',
  },
  {
    value: 106,
    label: 'Macao',
  },
  {
    value: 77,
    label: 'Madagascar',
  },
  {
    value: 69,
    label: 'Malawi',
  },
  {
    value: 84,
    label: 'Malaysia',
  },
  {
    value: 89,
    label: 'Maldives',
  },
  {
    value: 149,
    label: 'Mali',
  },
  {
    value: 156,
    label: 'Malta',
  },
  {
    value: 115,
    label: 'Marshall Islands',
  },
  {
    value: 204,
    label: 'Martinique',
  },
  {
    value: 139,
    label: 'Mauritania',
  },
  {
    value: 72,
    label: 'Mauritius',
  },
  {
    value: 68,
    label: 'Mayotte',
  },
  {
    value: 197,
    label: 'Mexico',
  },
  {
    value: 50,
    label: 'Moldova',
  },
  {
    value: 169,
    label: 'Monaco',
  },
  {
    value: 93,
    label: 'Mongolia',
  },
  {
    value: 63,
    label: 'Montenegro',
  },
  {
    value: 217,
    label: 'Montserrat',
  },
  {
    value: 151,
    label: 'Morocco',
  },
  {
    value: 76,
    label: 'Mozambique',
  },
  {
    value: 92,
    label: 'Myanmar',
  },
  {
    value: 64,
    label: 'Namibia',
  },
  {
    value: 121,
    label: 'Nauru',
  },
  {
    value: 91,
    label: 'Nepal',
  },
  {
    value: 164,
    label: 'Netherlands',
  },
  {
    value: 123,
    label: 'New Caledonia',
  },
  {
    value: 125,
    label: 'New Zealand',
  },
  {
    value: 227,
    label: 'Nicaragua',
  },
  {
    value: 148,
    label: 'Niger',
  },
  {
    value: 135,
    label: 'Nigeria',
  },
  {
    value: 241,
    label: 'Niue',
  },
  {
    value: 124,
    label: 'Norfolk Island',
  },
  {
    value: 243,
    label: 'Northern Mariana Islands',
  },
  {
    value: 110,
    label: 'North Korea',
  },
  {
    value: 57,
    label: 'North Macedonia',
  },
  {
    value: 60,
    label: 'Norway',
  },
  {
    value: 28,
    label: 'Oman',
  },
  {
    value: 80,
    label: 'Pakistan',
  },
  {
    value: 98,
    label: 'Palau',
  },
  {
    value: 26,
    label: 'Palestine',
  },
  {
    value: 232,
    label: 'Panama',
  },
  {
    value: 117,
    label: 'Papua New Guinea',
  },
  {
    value: 193,
    label: 'Paraguay',
  },
  {
    value: 231,
    label: 'Peru',
  },
  {
    value: 103,
    label: 'Philippines',
  },
  {
    value: 236,
    label: 'Pitcairn Islands',
  },
  {
    value: 55,
    label: 'Poland',
  },
  {
    value: 130,
    label: 'Portugal',
  },
  {
    value: 245,
    label: 'Puerto Rico',
  },
  {
    value: 29,
    label: 'Qatar',
  },
  {
    value: 74,
    label: 'Réunion',
  },
  {
    value: 54,
    label: 'Romania',
  },
  {
    value: 40,
    label: 'Russia',
  },
  {
    value: 3,
    label: 'Rwanda',
  },
  {
    value: 220,
    label: 'Saint Barthélemy',
  },
  {
    value: 144,
    label: 'Saint Helena',
  },
  {
    value: 212,
    label: 'Saint Lucia',
  },
  {
    value: 219,
    label: 'Saint Martin',
  },
  {
    value: 189,
    label: 'Saint Pierre and Miquelon',
  },
  {
    value: 240,
    label: 'Samoa',
  },
  {
    value: 177,
    label: 'San Marino',
  },
  {
    value: 143,
    label: 'São Tomé and Príncipe',
  },
  {
    value: 8,
    label: 'Saudi Arabia',
  },
  {
    value: 128,
    label: 'Senegal',
  },
  {
    value: 61,
    label: 'Serbia',
  },
  {
    value: 21,
    label: 'Seychelles',
  },
  {
    value: 142,
    label: 'Sierra Leone',
  },
  {
    value: 111,
    label: 'Singapore',
  },
  {
    value: 202,
    label: 'Sint Maarten',
  },
  {
    value: 58,
    label: 'Slovakia',
  },
  {
    value: 179,
    label: 'Slovenia',
  },
  {
    value: 118,
    label: 'Solomon Islands',
  },
  {
    value: 4,
    label: 'Somalia',
  },
  {
    value: 75,
    label: 'South Africa',
  },
  {
    value: 190,
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: 108,
    label: 'South Korea',
  },
  {
    value: 38,
    label: 'South Sudan',
  },
  {
    value: 154,
    label: 'Spain',
  },
  {
    value: 85,
    label: 'Sri Lanka',
  },
  {
    value: 209,
    label: 'St Kitts and Nevis',
  },
  {
    value: 216,
    label: 'St Vincent and Grenadines',
  },
  {
    value: 37,
    label: 'Sudan',
  },
  {
    value: 186,
    label: 'Suriname',
  },
  {
    value: 46,
    label: 'Svalbard and Jan Mayen',
  },
  {
    value: 45,
    label: 'Sweden',
  },
  {
    value: 163,
    label: 'Switzerland',
  },
  {
    value: 13,
    label: 'Syria',
  },
  {
    value: 102,
    label: 'Taiwan',
  },
  {
    value: 83,
    label: 'Tajikistan',
  },
  {
    value: 11,
    label: 'Tanzania',
  },
  {
    value: 78,
    label: 'Thailand',
  },
  {
    value: 100,
    label: 'Timor-Leste',
  },
  {
    value: 137,
    label: 'Togo',
  },
  {
    value: 237,
    label: 'Tokelau',
  },
  {
    value: 238,
    label: 'Tonga',
  },
  {
    value: 208,
    label: 'Trinidad and Tobago',
  },
  {
    value: 152,
    label: 'Tunisia',
  },
  {
    value: 32,
    label: 'Turkey',
  },
  {
    value: 12,
    label: 'Turkmenistan',
  },
  {
    value: 213,
    label: 'Turks and Caicos Islands',
  },
  {
    value: 120,
    label: 'Tuvalu',
  },
  {
    value: 19,
    label: 'Uganda',
  },
  {
    value: 49,
    label: 'Ukraine',
  },
  {
    value: 31,
    label: 'United Arab Emirates',
  },
  {
    value: 161,
    label: 'United Kingdom (UK)',
  },
  {
    value: 244,
    label: 'United States (USA)',
  },
  {
    value: 194,
    label: 'Uruguay',
  },
  {
    value: 247,
    label: 'U.S. Outlying Islands',
  },
  {
    value: 246,
    label: 'U.S. Virgin Islands',
  },
  {
    value: 44,
    label: 'Uzbekistan',
  },
  {
    value: 122,
    label: 'Vanuatu',
  },
  {
    value: 176,
    label: 'Vatican City',
  },
  {
    value: 196,
    label: 'Venezuela',
  },
  {
    value: 99,
    label: 'Vietnam',
  },
  {
    value: 239,
    label: 'Wallis and Futuna',
  },
  {
    value: 150,
    label: 'Western Sahara',
  },
  {
    value: 5,
    label: 'Yemen',
  },
  {
    value: 66,
    label: 'Zambia',
  },
  {
    value: 65,
    label: 'Zimbabwe',
  },
];

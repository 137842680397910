import React, { FC } from 'react';
import styled from 'styled-components';

import Typography from '../Typography/Typography';
import theme from 'theme/theme';

import { sm, xs } from 'utils/constants';

interface PageLayoutProps {
  sections: { content: React.ReactNode }[];
  title: string | JSX.Element;
  customHeader?: React.ReactNode;
  padding?: string | undefined;
  is1ColumnLayout?: boolean;
  is2ColumnLayout?: boolean;
  width?: string;
  secondColumnWidth?: string;
  secondColumnMargin?: string;
  loading?: boolean;
  gap?: number;
  titleAlign?: string;
}

const PageLayout: FC<PageLayoutProps> = ({
  sections,
  title,
  is1ColumnLayout = false,
  is2ColumnLayout = false,
  customHeader,
  padding,
  loading,
  width,
  secondColumnWidth = '312px',
  secondColumnMargin = '40px',
  gap,
  titleAlign,
}) => {
  return (
    <Wrapper padding={padding}>
      <Container width={width}>
        {customHeader ? (
          customHeader
        ) : loading ? (
          <>
            <TitleContainer>
              <span
                className={
                  'placeholder-wave placeholder fs-48 lh-80 bg-secondary col-6'
                }>
                &nbsp;
              </span>
            </TitleContainer>
          </>
        ) : (
          <>
            <TitleContainer titleAlign={titleAlign}>
              {typeof title === 'string' ? (
                <Title
                  className={`page-title-${title.replace(' ', '')}`}
                  fontSize="fz60"
                  fontWeight="bold"
                  text={title.toLowerCase()}
                  fontColor={theme.colors.yellow}
                  withLeftMargin={is1ColumnLayout}
                />
              ) : (
                <>{title}</>
              )}
            </TitleContainer>
          </>
        )}
        <Content gap={gap}>
          {sections.map((section, index) => {
            if (is1ColumnLayout) {
              return (
                <ContentSection
                  key={`content-1col-${index}`}
                  width="100%"
                  marginRight="100px"
                  marginLeft="100px">
                  {section.content}
                </ContentSection>
              );
            }
            if (is2ColumnLayout) {
              return (
                <React.Fragment key={`content-2col-${index}`}>
                  {index == 0 ? (
                    <ContentSection
                      width="490px"
                      marginRight="46px"
                      marginLeft="0">
                      {section.content}
                    </ContentSection>
                  ) : (
                    section.content
                  )}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={`content-${index}`}>
                  {index == 1 ? (
                    <ContentSection
                      width={secondColumnWidth}
                      marginRight={secondColumnMargin}
                      marginLeft={secondColumnMargin}>
                      {section.content}
                    </ContentSection>
                  ) : (
                    section.content
                  )}
                </React.Fragment>
              );
            }
          })}
        </Content>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '100px 20px 0 20px')};
`;

const Container = styled.div<{ width?: string }>`
  width: 100%;

  @media (min-width: ${xs}px) {
    width: ${(props) => (props.width ? props.width : 'initial')};
  }
`;

const Content = styled.div<{ gap?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    ${(props) => (props.gap ? `gap: ${props.gap}px` : '')};
  }
`;

const ContentSection = styled.div<{
  marginRight?: string;
  marginLeft?: string;
  width: string;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  @media (min-width: ${xs}px) {
    margin-right: ${(props) =>
      props.marginRight ? props.marginRight : '25px'};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '25px')};
    width: 370px;
  }

  @media (min-width: ${sm}px) {
    margin-bottom: 110px;
    width: ${(props) => props.width};
  }
`;

const TitleContainer = styled.div<{ titleAlign?: string }>`
  width: 100%;
  justify-content: flex-start;
  text-align: ${(props) =>
    props.titleAlign ? props.titleAlign : 'flex-start'};

  margin-bottom: 15px;
  margin-top: 0px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
    text-align: unset;
  }

  @media (min-width: 1280px) {
    display: flex;
    margin-top: initial;
  }
`;

const Title = styled(Typography)<{ withLeftMargin: boolean }>`
  font-family: 'HKGrotesk-Black';
  font-size: 38px;
  letter-spacing: -0.055em;

  @media (min-width: 768px) {
    font-size: 38px;
    margin-left: ${(props) => (props.withLeftMargin ? '100px' : 'initial')};
  }

  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 80px;
  }
`;

export default PageLayout;

import React, { useEffect, useState, useMemo } from 'react';
import { useViewport } from 'use-viewport';
import { useStoreActions } from 'store/store';

import restService from 'services/rest.service';

import PageLayout from 'common/components/PageLayout/PageLayout';
import Spacer from 'common/components/Spacer/Spacer';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';

import CardDeposit from 'common/icons/Transactions/CardDeposit.icon';
import PendingDeposit from 'common/icons/Transactions/PendingDeposit.icon';
import BitPurchase from 'common/icons/Transactions/BitPurchase.icon';
import CryptoIn from 'common/icons/Transactions/CryptoIn.icon';
import SBModal from 'common/components/Modal/SBModal';
import TransactionDetailsModal from './components/Modals/TransactionDetailsModal';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import {
  Details,
  TransactionDetails,
  YellowLine,
} from './styled/Transactions.styled';

import { md } from 'utils/constants';

import './styles/Transactions.css';

import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';

export interface TransactionType {
  transactionid: string;
  date: string;
  timestamp: string;
  detail: string;
  amount: string;
  currency_amount: string;
  fees_amount: string;
  total_amount: string;
  transactiontype: string;
  sourcetype: string;
  status: string;
  data: {
    bits: string;
  };
}

type DropdownItem = {
  value: string | number;
  label: string;
};

const Transaction_Filters = [
  {
    label: 'All Transactions',
    value: '',
  },
  {
    label: 'Purchase',
    value: 'purchase',
  },
  {
    label: 'Royalty',
    value: 'royalties',
  },
  /*{
    label: 'Add',
    value: 'add',
  },*/
  // {
  //   label: 'Refund',
  //   value: 'refund',
  // },
  // {
  //   label: 'Sale',
  //   value: 'sale',
  // },
  // {
  //   label: 'Profit',
  //   value: 'profit',
  // },
  // {
  //   label: 'Chargeback',
  //   value: 'chargeback',
  // },
  // {
  //   label: 'Payout',
  //   value: 'withdraw',
  // },
];

const Transactions = () => {
  const viewport = useViewport();

  const [loading, setLoading] = useState<boolean>(true);
  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [currentFilter, setCurrentFilter] = useState<DropdownItem>(
    Transaction_Filters[0],
  );

  const [transactionData, setTransactionData] = useState<TransactionType[]>();
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState<boolean>(false);

  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionType>({
      transactionid: '',
      date: '',
      timestamp: '',
      detail: '',
      amount: '',
      fees_amount: '',
      currency_amount: '',
      total_amount: '',
      transactiontype: '',
      sourcetype: '',
      status: '',
      data: { bits: '' },
    });

  const quickLinks = useMemo(
    () => [
      {
        title: 'My Wallet',
        link: '/account/wallet',
      },
      {
        title: 'My Collection',
        link: '/library/my-collection',
      },
      {
        title: 'Account Settings',
        link: '/account/settings',
      },
      {
        title: 'My Royalties',
        link: '/account/royalty',
      },
      /*{
        title: 'Payouts',
        link: '/account/payouts',
      },*/
    ],
    [],
  );

  useEffect(() => {
    getTransactions(`${currentFilter.value}`).then((res) => {
      const data: TransactionType[] = [];
      res.forEach((t: any) => {
        data.push({
          transactionid: t.transactionid,
          date: parseDate(t.created),
          timestamp: t.created,
          detail: getTransactionDetails(t),
          amount:
            t.transactiontype === 'purchase' ? formatBits(t) : `$${t.amount}`,
          fees_amount: getFeesAmount(t),
          total_amount:
            t.transactiontype === 'purchase' || t.transactiontype === 'add'
              ? t.data.amount
              : t.amount,
          currency_amount: getTransactionAmount(t),
          transactiontype: t.transactiontype,
          sourcetype: t.sourcetype,
          status: t.status,
          data: {
            bits: formatBits(t),
          },
        });
      });
      setTransactionData(data);
      setLoading(false);
    });
  }, [currentFilter]);

  async function getTransactions(type?: string) {
    const transactions = await restService
      .listTransactions(type)
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve transactions: ',
          text: error.message,
        });
      });
    return transactions;
  }

  function parseDate(date: string) {
    const d = new Date(date).toDateString().split(' ');
    return `${d[1]} ${d[2]} ${d[3]}`;
  }

  function getFeesAmount(t: any) {
    let fees = '0';

    try {
      fees = t.data.data.fees.amount;
    } catch {}

    return fees;
  }

  // function getCurrencySymbol(currency: string) {
  //   if (currency === 'USD') {
  //     return '$';
  //   } else {
  //     return '';
  //   }
  // }

  function getTransactionDetails(transaction: any) {
    if (
      transaction.transactiontype === 'add' &&
      transaction.sourcetype === 'card'
    ) {
      return 'Card Deposit';
    } else if (transaction.transactiontype === 'royalties') {
      return 'Royalty Income';
    } else if (
      transaction.transactiontype === 'add' &&
      transaction.sourcetype === 'transfer'
    ) {
      return 'Crypto Incoming';
    } else if (transaction.transactiontype === 'purchase') {
      return formatBits(transaction);
    } else if (transaction.transactiontype === 'refund') {
      return 'Refund';
    } else {
      return transaction.transactiontype;
    }
  }

  function getTransactionAmount(transaction: any) {
    return transaction.amount;
  }

  function formatBits(transaction: any) {
    const numBits = transaction.data.number_of_bits;
    return `${numBits} bit${numBits > 1 ? 's' : ''}`;
  }

  function getTransactionIcon(transaction: any) {
    if (
      (transaction.transactiontype === 'add' &&
        (transaction.status === 'paid' || transaction.status === 'complete')) ||
      transaction.transactiontype === 'royalties'
    ) {
      return <CardDeposit />;
    } else if (
      transaction.transactiontype === 'add' &&
      transaction.sourcetype === 'transfer'
    ) {
      return <CryptoIn />;
    } else if (
      transaction.transactiontype === 'add' &&
      transaction.status === 'pending'
    ) {
      return <PendingDeposit />;
    } else if (transaction.transactiontype === 'purchase') {
      return <BitPurchase />;
    }
  }

  const firstColumn = (
    <>
      {loading ? (
        <>
          <table className="Transations">
            <thead>
              <tr>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-4'
                    }>
                    &nbsp;
                  </span>
                </th>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-4'
                    }>
                    &nbsp;
                  </span>
                </th>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-4'
                    }>
                    &nbsp;
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </>
      ) : (
        <Details>
          <Dropdown
            data={Transaction_Filters}
            currentItem={currentFilter}
            onSelect={(item) => setCurrentFilter(item)}
          />
          <table className="Transations">
            <thead>
              <tr>
                <th>Date</th>
                <th>Detail</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactionData &&
                transactionData.map((t, index) => (
                  <React.Fragment key={index}>
                    <tr
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedTransaction(t);
                        setShowTransactionDetailsModal(true);
                      }}>
                      <td>{t.date}</td>
                      <td>
                        <TransactionDetails>
                          {getTransactionIcon(t)}
                          {t.detail}
                        </TransactionDetails>
                      </td>
                      <td>{t.amount}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: '0px' }}>
                        <YellowLine />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>

          <Spacer height={viewport.width >= md ? 80 : 10} />
        </Details>
      )}
    </>
  );

  return (
    <PageContainer pageTitle={'Transaction History | SongBits'}>
      <BreadcrumbsContainer>
        {viewport.width < 576 && <MobileBreadcrumbs />}
      </BreadcrumbsContainer>
      <PageLayout
        loading={loading}
        padding={
          viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
        }
        title="Transaction History"
        is2ColumnLayout
        sections={[
          {
            content: firstColumn,
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                  marginTop: viewport.width < md ? '0px' : '15px',
                }}>
                {loading && viewport.width >= 767 ? (
                  <QuickLinksPlaceholder></QuickLinksPlaceholder>
                ) : (
                  <>
                    {viewport.width <= 767 ? (
                      <></>
                    ) : (
                      <QuickLinks links={quickLinks} />
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />

      <SBModal
        className={'transaction-details'}
        isOpen={showTransactionDetailsModal}
        content={
          <TransactionDetailsModal
            transaction={selectedTransaction}
            onClose={() => {
              setShowTransactionDetailsModal(false);
            }}
          />
        }
      />
    </PageContainer>
  );
};

export default Transactions;

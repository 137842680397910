import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';
import Button from 'common/components/Button/Button';

import { md, sm, xlg, xs } from 'utils/constants';

export const HeadingSection = styled.div`
  height: 100%;

  padding: 64px 0 0 0;

  @media (min-width: ${xs}px) {
    padding: 70px 0 0 20px;
  }

  @media (min-width: ${sm}px) {
    min-width: 700px;
    padding: 80px 0 0 70px;
  }

  @media (min-width: ${md}px) {
    padding: 55px 0 0 150px;
  }

  @media (min-width: ${xlg}px) {
    padding: 0 0 0 150px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const HeadContainer = styled.div`
  flex: 1;
  z-index: 1;
  width: 100%;
  flex-direction: column;
  padding: 48px 34px 0 34px;

  @media (min-width: 768px) {
    display: flex;
    padding: 48px 48px 0 48px;
  }

  @media (min-width: 992px) {
    padding-top: 95px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 60%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 55%;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const FirstButton = styled(Button)`
  font-family: 'HKGrotesk-Black';

  padding: 0px 40px;
  margin: 10px auto 68px auto;

  @media (min-width: ${sm}px) {
    margin: 16px 0 72px 0;
  }
`;

export const TextContent = styled(Typography)<{
  withCursorPointer?: boolean;
}>`
  line-height: 24px;
  letter-spacing: -0.03em;
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export const InputContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const TextContentConfirm = styled(TextContent)`
  font-family: 'HKGrotesk-Black';

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;

  @media (min-width: ${sm}px) {
    left: 7px;
  }
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 26px;
  }

  & > div:nth-child(2) {
    padding: 0 0 5px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      padding-left: 30px;
    }
  }
`;

export const StrapLineTypography = styled(Typography)`
  flex-shrink: 0;
  font-family: 'HKGrotesk-Black';
  font-size: 60px;
  line-height: 40px;

  @media (min-width: 992px) {
    font-size: 100px;
    line-height: 80px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  margin-bottom: 42px;

  @media (min-width: ${xs}px) {
    display: flex;
  }
`;

export const HeadContent = styled(StrapLineTypography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.05em;

  flex-shrink: 0;
`;

export const BackButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
`;

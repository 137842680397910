import * as React from 'react';

import { IconProps } from '../common.types';

const ExplicitLuricsIcon = ({ width = 70, height = 12 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 70 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M3.87012 8.87327H7.71519V7.69017H5.16002V6.50707H7.59195V5.31575H5.16002V4.32984H7.71519V3.14674H3.87012V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M8.10788 8.87327H9.65249L10.8602 6.95895L12.0844 8.87327H13.629L11.7558 6.03054L13.629 3.14674H12.0926L10.8684 5.135L9.64427 3.14674H8.09967L9.97291 6.03054L8.10788 8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M14.1561 8.87327H15.4378V6.5728H16.169C17.4754 6.5728 18.1573 5.97303 18.1573 4.83101C18.1573 3.69721 17.4918 3.14674 16.169 3.14674H14.1561V8.87327ZM15.4378 5.3897V4.32984H16.0376C16.6045 4.32984 16.8674 4.412 16.8674 4.84745C16.8674 5.30754 16.5634 5.3897 16.0376 5.3897H15.4378Z"
        fill="#FFD600"
      />
      <path
        d="M18.8659 8.87327H22.5055V7.69017H20.1558V3.14674H18.8659V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M23.046 8.87327H24.3359V3.14674H23.046V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M27.9742 9.02115C29.2477 9.02115 30.2089 8.38852 30.6115 7.24651L29.4038 6.78641C29.2066 7.4519 28.689 7.78876 27.9742 7.78876C26.9801 7.78876 26.3803 7.1397 26.3803 5.99768C26.3803 4.85566 26.9636 4.21482 27.9496 4.21482C28.6479 4.21482 29.182 4.54345 29.4038 5.19252L30.5951 4.72421C30.1761 3.6397 29.2312 2.98242 27.9496 2.98242C26.2324 2.98242 25.0904 4.18195 25.0904 6.01411C25.0904 7.82984 26.2406 9.02115 27.9742 9.02115Z"
        fill="#FFD600"
      />
      <path
        d="M31.2299 8.87327H32.5198V3.14674H31.2299V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M34.7367 8.87327H36.0431V4.32984H37.7109V3.14674H33.0607V4.32984H34.7367V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M40.2482 8.87327H43.8879V7.69017H41.5381V3.14674H40.2482V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M45.2478 8.87327H46.5377V6.61388L48.6821 3.14674H47.1621L45.864 5.37327L44.5988 3.14674H43.0788L45.2478 6.61388V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M53.6415 8.87327L52.1051 6.44134C52.7459 6.18665 53.0992 5.65261 53.0992 4.83923C53.0992 3.82045 52.5569 3.14674 51.1109 3.14674H49.098V8.87327H50.3797V6.59744H50.503H50.8234L52.1461 8.87327H53.6415ZM50.3797 5.41435V4.32984H50.9795C51.3821 4.32984 51.8093 4.32984 51.8093 4.85566C51.8093 5.3897 51.3903 5.41435 50.9795 5.41435H50.3797Z"
        fill="#FFD600"
      />
      <path
        d="M54.1447 8.87327H55.4347V3.14674H54.1447V8.87327Z"
        fill="#FFD600"
      />
      <path
        d="M59.0729 9.02115C60.3464 9.02115 61.3076 8.38852 61.7102 7.24651L60.5025 6.78641C60.3053 7.4519 59.7877 7.78876 59.0729 7.78876C58.0788 7.78876 57.479 7.1397 57.479 5.99768C57.479 4.85566 58.0623 4.21482 59.0483 4.21482C59.7466 4.21482 60.2807 4.54345 60.5025 5.19252L61.6938 4.72421C61.2748 3.6397 60.33 2.98242 59.0483 2.98242C57.3311 2.98242 56.1891 4.18195 56.1891 6.01411C56.1891 7.82984 57.3393 9.02115 59.0729 9.02115Z"
        fill="#FFD600"
      />
      <path
        d="M64.0704 8.99651C65.2371 8.99651 66.1408 8.23242 66.1408 7.09862C66.1408 6.28524 65.7465 5.87444 64.5141 5.39791C63.6925 5.07749 63.4706 4.88031 63.4706 4.60097C63.4706 4.32984 63.7171 4.14087 64.0129 4.14087C64.3415 4.14087 64.6209 4.35449 64.7934 4.7735L65.8615 4.10801C65.4918 3.36857 64.8509 2.99885 64.054 2.99885C62.9284 2.99885 62.1807 3.67256 62.1807 4.62561C62.1807 5.50472 62.6244 5.9566 63.8486 6.44956C64.5962 6.75355 64.8591 6.95073 64.8591 7.21364C64.8591 7.64909 64.5141 7.85449 64.095 7.85449C63.5199 7.85449 63.1091 7.54228 63.0516 6.86857L61.7699 7.18899C61.9425 8.28172 62.8791 8.99651 64.0704 8.99651Z"
        fill="#FFD600"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0141 0.985915H0.985915V10.8451H69.0141V0.985915ZM0 0V11.831H70V0H0Z"
        fill="#FFD600"
      />
    </g>
  </svg>
);

export default ExplicitLuricsIcon;

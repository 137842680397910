import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { sm } from 'utils/constants';

export const BorderContainer = styled.div<{ isQuickLinksOpen: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 100vh;

  background: rgba(0, 0, 0, 0.9);
  border-radius: 0px;
  padding: 10px 10px 0 0;
  box-sizing: content-box;

  overflow: hidden;

  position: fixed;
  top: 0;
  left: calc(-100% - 20px);

  transform: ${(props) => props.isQuickLinksOpen && `translate(${100 + '%'})`};
  transition: all 0.3s linear;

  z-index: 100 !important;

  @media (min-width: ${sm}px) {
    width: 516px;
    height: calc(100% - 20px);

    left: -526px;

    transform: ${(props) => props.isQuickLinksOpen && `translate(526px)`};
  }
`;

export const Container = styled.div<{ isQuickLinksOpen?: boolean }>`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 20px 5px 50px 47px;

  overflow: ${(props) => (props.isQuickLinksOpen ? 'hidden' : 'auto')};

  scrollbar-color: ${theme.colors.yellow} transparent;
  background: transparent;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.colors.yellow};
    opacity: 0.5;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      border: 1px solid #ffffff;
    }
  }
`;

export const ProfileIconContainer = styled.div`
  margin-top: 90px;
  cursor: pointer;
  margin-right: 2px;
`;

export const NavButtonClose = styled(Button)`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;

  :hover {
    background: transparent;
  }
`;

export const NavButton = styled(Button)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  :hover {
    background: transparent;
  }
`;

export const TypographyMenu = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 38px;
  letter-spacing: -0.055em;
  margin-top: 93px;

  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 90px;
  }
`;

export const Item = styled(Typography)<{
  isFirstElement: boolean;
  isLastElement: boolean;
}>`
  cursor: pointer;
  width: 75%;
  text-align: left;
  text-transform: capitalize;
  line-height: 48px;
  border-style: solid;
  padding-left: 5px;
  border-image: linear-gradient(
      to right,
      ${theme.colors.yellow},
      ${theme.colors.yellow},
      ${theme.colors.transparent}
    )
    0 0 1 0;
  border-width: ${(props) => (props.isLastElement ? 0 : '0.5px')};

  ${(props) =>
    props.isFirstElement
      ? `color: ${theme.colors.yellow};`
      : `color: ${theme.colors.white};`}

  :hover {
    ${(props) =>
      props.isFirstElement
        ? `color: ${theme.colors.white};`
        : `color: ${theme.colors.yellow};`}
  }
`;

import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import TextInput from 'common/components/TextInput/TextInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useStoreActions } from 'store/store';
import restService from 'services/rest.service';

import { xs, sm } from 'utils/constants';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../../modules/library/pages/components/ModalLayout/ModalLayout';
import Question from './Question';
import QuestionInput from './QuestionInput';

type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: (nextStep?: string) => void;
};

type FCAQuestionsAnswersTypes = {
  label: string;
  value: boolean | string | number;
}

type FCAQuestionsTypes = {
  id: number;
  multiple_choice: boolean;
  question: string;
  description: string | null;
  answers: FCAQuestionsAnswersTypes[];
}

type FCAQuestionsResponseTypes = {
  step: string;
  questions: FCAQuestionsTypes[];
}

const VALIDATION_SCHEMA = Yup.object().shape({
  questionOne: Yup.mixed()
    .oneOf(['yes', 'no'])
    .required('This question is required.'),
  questionTwo: Yup.mixed()
    .oneOf(['yes', 'no'])
    .required('This question is required.'),
  questionThree: Yup.mixed()
    .oneOf(['yes', 'no'])
    .required('This question is required.'),
  questionFour: Yup.string().required('Current salary is required.'),
});

const QuestionsModal = ({ show, onClose, onContinue }: ModalProps) => {
  const { setFcaStep } = useStoreActions((state) => state.authentication);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<FCAQuestionsResponseTypes>();

  useEffect(() => {
    if (!questions) {
      if (show) {
        restService.fcaQuestions().then((res) => {
          //console.log(JSON.stringify(res))
          setQuestions(res)
        })
      }
    }

    setShowModal(show);
  }, [show, questions]);

  const initialValues = useMemo(
    () => ({
      questionOne: '',
      questionTwo: '',
      questionThree: '',
      questionFour: ''
    }),
    [],
  );

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {

    setLoading(true)

    const info = {
      answers: [
        {
          id: 1,
          value: true
        },
        {
          id: 2,
          value: true
        },
        {
          id: 3,
          value: true
        },
        {
          id: 4,
          value: 4
        },
      ]
    }

    restService.submitFcaQuestions(info).then(res => {
      setFcaStep(res.next)
      onContinue(res.next)
      setLoading(false)
    })

  }

  return (
    <>
      <SBModal
        className={'categosiration'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        top={'10%'}
        height="644px"
        mobileHeight='100%'
        content={
          <ModalLayout
            title={'classification'}
            titleBottomMargin="10px"
            onClose={onClose}>
            <ContentContainer className={'categorisation-modal'}>
              <NormalText>Please answer the following questions</NormalText>

              <div style={{ marginBottom: '30px', marginTop: '10px' }}>
                <Question
                  text={'Do you consider yourself a retail investor?'}
                  tip="A retail investor is neither a professional investor nor an eligible counterparty."
                  setRadio={handleChange('questionOne')}
                  radio={values.questionOne}
                  error={
                    Boolean(errors.questionOne && touched.questionOne)
                      ? errors.questionOne
                      : undefined
                  }
                  question="questionOne"
                />
              </div>

              <div style={{ marginBottom: '30px' }}>
                <Question
                  text={
                    'Have you previously made any type\nof investments in listed or unlisted\ncompanies?'
                  }
                  tip="a"
                  setRadio={handleChange('questionTwo')}
                  radio={values.questionTwo}
                  error={
                    Boolean(errors.questionTwo && touched.questionTwo)
                      ? errors.questionTwo
                      : undefined
                  }
                  question="questionTwo"
                />
              </div>

              <div style={{ marginBottom: '30px' }}>
                <Question
                  text={
                    'Have you invested 10% or more of your\ncurrent salary in other investments?'
                  }
                  tip="a"
                  setRadio={handleChange('questionThree')}
                  radio={values.questionThree}
                  error={
                    Boolean(errors.questionThree && touched.questionThree)
                      ? errors.questionThree
                      : undefined
                  }
                  question="questionThree"
                />
              </div>

              <QuestionInput
                text={'What is your current salary?'}
                tip={'a'}
                setValue={handleChange('questionFour')}
                value={values.questionFour}
                errors={errors}
                touched={touched}
              />

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                }}>
                <FirstButton
                  isLoading={loading}
                  className={'categorisation-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={handleSubmit}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: ${xs}px) {
    height: auto;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 10px;
  white-space: pre-wrap;
  letter-spacing: -0.03em;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default QuestionsModal;

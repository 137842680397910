import React from 'react';
import Spacer from '../Spacer/Spacer';
import {
  CountriesRow,
  CountryContainer,
  Wrapper,
} from 'modules/artist/pages/CountryList/components/RegionSection/RegionSection';

const CountryListPlaceholder = () => {
  const regionSection = (
    <Wrapper>
      <span
        className={
          'placeholder-wave placeholder fs-18 lh-20 bg-secondary col-2'
        }>
        &nbsp;
      </span>

      <CountriesRow>
        {[...Array(2)].map((_, i) => (
          <CountryContainer
            key={`country-placeholder-${i}`}
            style={{ marginBottom: '0px' }}>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-26 bg-secondary col-10'
              }>
              &nbsp;
            </span>
          </CountryContainer>
        ))}
      </CountriesRow>

      <Spacer height={6} />
      <span
        style={{ color: 'red' }}
        className={`placeholder-wave placeholder fs-02 bg-secondary col-12 lh-2`}>
        &nbsp;
      </span>
    </Wrapper>
  );

  return (
    <>
      {/* Dropdown */}
      <span
        style={{ borderRadius: '10px', marginBottom: '20px', width: '62%' }}
        className={
          'placeholder-wave placeholder fs-18 lh-44 bg-secondary col-8'
        }>
        &nbsp;
      </span>

      <Spacer height={25} />
      {/* Heading */}
      <span
        className={
          'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-4'
        }>
        &nbsp;
      </span>

      {/* {regionSection} */}

      {[...Array(3)].map((_, index) => (
        <div key={index}>{regionSection}</div>
      ))}

      <Spacer height={40} />
    </>
  );
};

export default CountryListPlaceholder;

import Button from 'common/components/Button/Button';
import RowCheckbox from 'common/components/Table/components/RowCheckbox/RowCheckbox';
import Typography from 'common/components/Typography/Typography';
import MinusCircleIcon from 'common/icons/MinusCircle.icon';
import PlusCircleIcon from 'common/icons/PlusCircle.icon';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

interface UserTableRowProps {
  data: (string | JSX.Element)[];
  checked: boolean;
  expandAll: boolean;
  onRowsSelect?: (checked: boolean) => void;
}

const UserTableRow: FC<UserTableRowProps> = ({
  data,
  expandAll,
  checked,
  onRowsSelect,
}) => {
  const [rowExpanded, setRowExpanded] = useState<boolean>(expandAll);

  useEffect(() => {
    setRowExpanded(expandAll);
  }, [expandAll]);

  function truncateLongText(text: string) {
    if (text.length > 12) {
      return text.substring(0, 10) + '...';
    } else {
      return text;
    }
  }

  return (
    <>
      <TableRow>
        <TableData>
          <RowCheckbox checked={checked} onChange={onRowsSelect} />
        </TableData>

        <TableData
          onClick={() => {
            window.open(`${window.location.origin}/${data[data.length - 1]}`);
          }}>
          {truncateLongText(data[0] as string)}
        </TableData>
        <TableData>{truncateLongText(data[1] as string)}</TableData>
        {/* Country Name */}
        <TableData>
          <div style={{ marginRight: '5px' }}>
            <ExpandButton
              onClick={() => {
                setRowExpanded(!rowExpanded);
              }}
              label={
                !rowExpanded ? (
                  <PlusCircleIcon
                    width={15}
                    height={15}
                    fill={theme.colors.white}
                  />
                ) : (
                  <MinusCircleIcon
                    width={15}
                    height={15}
                    fill={theme.colors.white}
                  />
                )
              }></ExpandButton>
          </div>
        </TableData>
      </TableRow>
      {rowExpanded ? (
        <TableRow>
          <TableData></TableData>
          <ExpandedDataContainer colSpan={4}>
            <Row>
              <Typography
                text={`Location: `}
                fontColor={theme.colors.white}
                fontSize="fz14"
                fontWeight="bold"
              />
              {/* Country Name */}
              <Typography
                text={`${data[1]}`}
                fontColor={theme.colors.white}
                fontSize="fz14"
              />
            </Row>

            <Row>
              <Typography
                text={`Registration Date: `}
                fontColor={theme.colors.white}
                fontSize="fz14"
                fontWeight="bold"
              />
              {/* Registration Date */}
              <Typography
                text={`${data[2]}`}
                fontColor={theme.colors.white}
                fontSize="fz14"
              />
            </Row>
            <Row>
              <Typography
                text={`Last Activity: `}
                fontColor={theme.colors.white}
                fontSize="fz14"
                fontWeight="bold"
              />
              {/* Last Activity Date */}
              <Typography
                text={`${data[3]}`}
                fontColor={theme.colors.white}
                fontSize="fz14"
              />
            </Row>
            <Row>
              <Typography
                text={`Total Purchase: `}
                fontColor={theme.colors.white}
                fontSize="fz14"
                fontWeight="bold"
              />
              {/* Total purchase cost */}
              <Typography
                text={`${data[4]}`}
                fontColor={theme.colors.white}
                fontSize="fz14"
              />
            </Row>
          </ExpandedDataContainer>
        </TableRow>
      ) : null}
      <Line />
    </>
  );
};

const TableRow = styled.tr<{ height?: string }>`
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  height: ${(props) => (props.height ? props.height : '30px')};
  max-height: 30px;
`;

const TableData = styled.td`
  vertical-align: middle;
`;

const ExpandedDataContainer = styled.td`
  display: flex;
  flex-direction: column;
  display: table-cell;
`;

const ExpandButton = styled(Button)`
  margin: unset;
  padding: unset;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;

  gap: 0px;
`;

const Line = styled.div`
  height: 1px;
  width: 800%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;

  opacity: 0.5;
  background-color: ${theme.colors.yellow};
  background: linear-gradient(
    to right,
    ${theme.colors.yellow} 80%,
    ${theme.colors.yellow},
    ${theme.colors.transparent}
  );
`;

export default UserTableRow;

import React from 'react';
import styled from 'styled-components';

const MyCollectionOptionsPlaceholder = () => {
  return (
    <>
      <OptionsContainer style={{ paddingTop: '0px' }}>
        {/* Ellipsis */}
        <RowContainer>
          <IconLink className="placeholder-wave placeholder bg-secondary col-5">
            &nbsp;
          </IconLink>
        </RowContainer>

        {/* Streaming Platforms */}
        <RowContainer>
          <IconLink className="placeholder-wave placeholder bg-secondary col-5">
            &nbsp;
          </IconLink>
        </RowContainer>

        {/* Options */}
        <RowContainer>
          <IconLink className="placeholder-wave placeholder bg-secondary col-5">
            &nbsp;
          </IconLink>
        </RowContainer>
      </OptionsContainer>
    </>
  );
};

const IconLink = styled.span`
  width: 15px;
  height: 15px;

  @media (min-width: 768px) {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 992px) {
    width: 30px;
    height: 30px;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-height: 80px;

  @media (min-width: 768px) {
    max-height: 160px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;

  @media (min-width: 768px) {
    gap: 15px;
  }
`;

export default MyCollectionOptionsPlaceholder;

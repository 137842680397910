import BottomLine from 'common/components/BottomLine/BottomLine';
import RoyaltyBreakdownPlaceholder from 'common/components/Placeholder/Royalty/RoyaltyBreakdown';
import Spacer from 'common/components/Spacer/Spacer';
import React from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

const RoyaltyEarningsPlaceholder = () => {
  const viewport = useViewport();
  return (
    <Column style={{ width: 'inherit' }}>
      <Row>
        <Column textAlign="left">
          <Row style={{ gap: '12px' }}>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-6'
              }>
              &nbsp;
            </span>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-1'
              }>
              &nbsp;
            </span>
          </Row>
          <span
            className={
              'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-3'
            }>
            &nbsp;
          </span>
        </Column>
        <Column textAlign="right">
          <span
            style={{ alignSelf: 'end' }}
            className={`placeholder-wave placeholder fs-48 lh-48 bg-secondary ${
              viewport.width < 576 ? 'col-4' : 'col-6'
            }`}>
            &nbsp;
          </span>
        </Column>
      </Row>
      <Spacer height={10} />
      <div style={{ textAlign: 'end' }}>
        <span
          style={{ alignSelf: 'end' }}
          className={
            'placeholder-wave placeholder fs-30 lh-30 bg-secondary col-1'
          }>
          &nbsp;
        </span>
      </div>
      <Spacer height={10} />
      <Row>
        <Column textAlign="left">
          <Row style={{ gap: '25px' }}>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-5'
              }>
              &nbsp;
            </span>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-1'
              }>
              &nbsp;
            </span>
          </Row>
          <span
            className={`placeholder-wave placeholder fs-14 lh-14 bg-secondary ${
              viewport.width < 576 ? 'col-6' : 'col-8'
            }`}>
            &nbsp;
          </span>
        </Column>
        <Column textAlign="right">
          <span
            style={{ alignSelf: 'end' }}
            className={`placeholder-wave placeholder fs-48 lh-48 bg-secondary ${
              viewport.width < 576 ? 'col-8' : 'col-10'
            }`}>
            &nbsp;
          </span>
        </Column>
      </Row>
      <Spacer height={10} />
      <Row>
        <div style={{ width: '60%' }}></div>
        <div
          style={{
            width: '45%',
            borderBottom: '1px solid white',
            opacity: '0.5',
          }}></div>
      </Row>
      <Spacer height={10} />
      <Row>
        <Column textAlign="left" style={{ justifyContent: 'center' }}>
          <span
            className={`placeholder-wave placeholder fs-18 lh-18 bg-secondary ${
              viewport.width < 576 ? 'col-5' : 'col-7'
            }`}>
            &nbsp;
          </span>
        </Column>
        <Column textAlign="right">
          <span
            style={{ alignSelf: 'end' }}
            className={
              'placeholder-wave placeholder fs-48 lh-48 bg-secondary col-8'
            }>
            &nbsp;
          </span>
        </Column>
      </Row>
      <Spacer height={25} />
      <BottomLine />
      <Spacer height={20} />

      <RoyaltyBreakdownPlaceholder />
    </Column>
  );
};

const Column = styled.div<{ textAlign?: string; paddingLeft?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px` : ''};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

export default RoyaltyEarningsPlaceholder;

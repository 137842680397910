import React, { FC, useState, useEffect } from 'react';
import { useViewport } from 'use-viewport';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import UserTableRow from 'modules/artist/pages/UserDetails/UserTableRow';
import BottomLine from 'common/components/BottomLine/BottomLine';
import RowCheckbox from './components/RowCheckbox/RowCheckbox';

import MailIcon from 'common/icons/Mail.icon';
import Heart from 'common/icons/Heart.icon';
import PlusCircleIcon from 'common/icons/PlusCircle.icon';
import MinusCircleIcon from 'common/icons/MinusCircle.icon';

import theme from 'theme/theme';

interface TableStyle {
  rowHeight?: string;
  tableLayout?: string;
}

export type TableDataType = (string | JSX.Element)[][];

/**
 * data: input data
 * columns: table columns
 * useRowDividers: Using dividers between lines
 * useTableHeaderDivider: Usings divider line between table header and rows
 */
interface UsersTableProps {
  data: TableDataType;
  columns: string[];
  useRowDividers?: boolean;
  useTableHeaderDivider?: boolean;
  useActionsColumn?: boolean;
  useRowsSelection?: boolean;
  style?: TableStyle;
  onRowsSelect?: (rows: TableDataType) => void;
}

const Table: FC<UsersTableProps> = ({
  data,
  columns,
  useRowDividers = true,
  useTableHeaderDivider = false,
  useActionsColumn = false,
  useRowsSelection = false,
  style,
  onRowsSelect = () => {},
}) => {
  const viewport = useViewport();
  const location = useLocation();

  const [selectedRows, setSelectedRows] = useState<TableDataType>([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState<boolean>(false);

  const [useMobileTableRow, setUseMobileTableRow] = useState<boolean>(
    viewport.width < 576 && location.pathname !== '/artist/insights',
  );

  const [expandAllMobileRows, setExpandAllMobileRows] =
    useState<boolean>(false);

  useEffect(() => {
    if (viewport.width < 576 && location.pathname !== '/artist/insights') {
      setUseMobileTableRow(true);
    } else {
      setUseMobileTableRow(false);
    }
  }, [location.pathname]);

  const handleAllRowsSelect = (checked: boolean) => {
    if (checked) {
      setIsAllRowsSelected(true);
      setSelectedRows(data);
    } else {
      setIsAllRowsSelected(false);
      setSelectedRows([]);
    }
  };

  const handleRowSelectChange = (
    checked: boolean,
    rowData: (string | JSX.Element)[],
  ) => {
    if (isAllRowsSelected) return;
    if (checked) {
      setSelectedRows((prev) => [rowData, ...prev]);
    } else {
      setSelectedRows(
        selectedRows.filter(
          (row) => row[row.length - 1] !== rowData[row.length - 1],
        ),
      );
    }
  };

  useEffect(() => {
    onRowsSelect(selectedRows);
  }, [selectedRows]);

  function renderTableBody(data: TableDataType) {
    if (viewport.width < 768 && useMobileTableRow) {
      return (
        <>
          {data.map((rowData, rowIndex) => (
            <UserTableRow
              key={`mobile-table-row-${rowIndex}`}
              data={rowData}
              checked={isAllRowsSelected}
              onRowsSelect={(checked) =>
                handleRowSelectChange(checked, rowData)
              }
              expandAll={expandAllMobileRows}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          {data.map((rowData, rowIndex, rowsArr) => (
            <>
              <TableRow key={`Users row ${rowIndex}`} height={style?.rowHeight}>
                {useRowsSelection ? (
                  <TableData>
                    <RowCheckbox
                      checked={isAllRowsSelected}
                      onChange={(checked) =>
                        handleRowSelectChange(checked, rowData)
                      }
                    />
                  </TableData>
                ) : null}
                {rowData
                  .filter((_, dataIndex) => dataIndex <= columns.length - 1)
                  .map((cellData, dataIndex) => (
                    <TableData key={`Row data ${rowIndex}-${dataIndex}`}>
                      {cellData}
                    </TableData>
                  ))}
                {viewport.width < 576 && useMobileTableRow ? (
                  <TableData key={`Row data plus ${rowIndex}`} width={50}>
                    <PlusCircleIcon
                      width={15}
                      height={15}
                      fill={theme.colors.white}
                    />
                  </TableData>
                ) : null}
                {useActionsColumn ? (
                  <TableData key={`Row data actions ${rowIndex}`}>
                    <ActionsColumn>
                      <MailIcon width={25} height={15} fill="#fff" />
                      <Heart width={22} height={20} />
                    </ActionsColumn>
                  </TableData>
                ) : null}
              </TableRow>
              {useRowDividers && rowsArr.length - 1 !== rowIndex ? (
                <RowDivider key={`Divider ${rowIndex}`}>
                  {rowData.map((_, dividerIndex, arr) => (
                    <TableData key={`Divider ${rowIndex}-${dividerIndex}`}>
                      {arr.length - 1 === dividerIndex ? (
                        <BottomLine />
                      ) : (
                        <YellowLine />
                      )}
                    </TableData>
                  ))}
                </RowDivider>
              ) : null}
            </>
          ))}
        </>
      );
    }
  }

  return (
    <TableWrapper>
      <TableLayout>
        <TableHead>
          <tr>
            {useRowsSelection ? (
              <th>
                <RowCheckbox onChange={handleAllRowsSelect} />
              </th>
            ) : null}
            {columns.map((column, index) => (
              <TableHeading key={`${column} ${index}`}>{column}</TableHeading>
            ))}
            {viewport.width < 576 && useMobileTableRow ? (
              <th>
                <div
                  onClick={() =>
                    setExpandAllMobileRows((prevState) => !prevState)
                  }>
                  {!expandAllMobileRows ? (
                    <PlusCircleIcon
                      width={15}
                      height={15}
                      fill={theme.colors.white}
                    />
                  ) : (
                    <MinusCircleIcon
                      width={15}
                      height={15}
                      fill={theme.colors.white}
                    />
                  )}
                </div>
              </th>
            ) : null}
            {useActionsColumn ? <th>Actions</th> : null}
          </tr>
          {useTableHeaderDivider ? (
            <tr>
              {columns.map((column, index, arr) => (
                <th key={`${column} ${index} divider`}>
                  {arr.length - 1 === index ? <BottomLine /> : <YellowLine />}
                </th>
              ))}
            </tr>
          ) : null}
        </TableHead>
        <tbody>{data.length > 0 ? renderTableBody(data) : null}</tbody>
      </TableLayout>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableLayout = styled.table<{ tableLayout?: string }>`
  border-collapse: collapse;
  width: 100%;

  ${(props) => props.tableLayout && `table-layout: ${props.tableLayout}`}
`;

const TableHead = styled.thead`
  height: 35px;

  font-family: 'HKGrotesk-Black';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
`;

const TableRow = styled.tr<{ height?: string }>`
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  height: ${(props) => (props.height ? props.height : '30px')};
  max-height: 30px;
`;

const TableData = styled.td`
  vertical-align: middle;
`;

const RowDivider = styled.tr`
  height: 5px;
`;

const YellowLine = styled.div`
  height: 1px;
  width: 100%;

  opacity: 0.5;
  background-color: ${theme.colors.yellow};
  background: ${theme.colors.yellow};
`;

const ActionsColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;
`;

const TableHeading = styled.th`
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export default Table;

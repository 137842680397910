import * as React from 'react';

import { IconProps } from '../common.types';

const Triangle = ({ width = 13, height = 12 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0L12.9952 11.25H0.00480938L6.5 0Z" fill="#00FF00" />
  </svg>
);

export default Triangle;

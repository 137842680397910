export const stateCodeById = [
  {
    value: 3181,
    label: "Alabama"
  },
  {
    value: 3226,
    label: "Alaska"
  },
  {
    value: 3220,
    label: "Arizona"
  },
  {
    value: 3183,
    label: "Arkansas"
  },
  {
    value: 3221,
    label: "California"
  },
  {
    value: 3224,
    label: "Colorado"
  },
  {
    value: 3206,
    label: "Connecticut"
  },
  {
    value: 3184,
    label: "Delaware"
  },
  {
    value: 3197,
    label: "District of Columbia"
  },
  {
    value: 3185,
    label: "Georgia"
  },
  {
    value: 3231,
    label: "Hawaii"
  },
  {
    value: 3227,
    label: "Idaho"
  },
  {
    value: 3198,
    label: "Illinois"
  },
  {
    value: 3186,
    label: "Indiana"
  },
  {
    value: 3207,
    label: "Iowa"
  },
  {
    value: 3188,
    label: "Kansas"
  },
  {
    value: 3189,
    label: "Kentucky"
  },
  {
    value: 3199,
    label: "Louisiana"
  },
  {
    value: 3209,
    label: "Maine"
  },
  {
    value: 3187,
    label: "Maryland"
  },
  {
    value: 3208,
    label: "Massachusetts"
  },
  {
    value: 3210,
    label: "Michigan"
  },
  {
    value: 3211,
    label: "Minnesota"
  },
  {
    value: 3191,
    label: "Mississippi"
  },
  {
    value: 3190,
    label: "Missouri"
  },
  {
    value: 3212,
    label: "Nebraska"
  },
  {
    value: 3225,
    label: "Nevada"
  },
  {
    value: 3217,
    label: "New Hampshire"
  },
  {
    value: 3200,
    label: "New Jersey"
  },
  {
    value: 3222,
    label: "New Mexico"
  },
  {
    value: 3213,
    label: "New York"
  },
  {
    value: 3192,
    label: "North Carolina"
  },
  {
    value: 3216,
    label: "North Dakota"
  },
  {
    value: 3193,
    label: "Ohio"
  },
  {
    value: 3194,
    label: "Oklahoma"
  },
  {
    value: 3228,
    label: "Oregon"
  },
  {
    value: 3201,
    label: "Pennsylvania"
  },
  {
    value: 3218,
    label: "Rhode Island"
  },
  {
    value: 3195,
    label: "South Carolina"
  },
  {
    value: 3214,
    label: "South Dakota"
  },
  {
    value: 3196,
    label: "Tennessee"
  },
  {
    value: 3180,
    label: "Texas"
  },
  {
    value: 3223,
    label: "Utah"
  },
  {
    value: 3219,
    label: "Vermont"
  },
  {
    value: 3182,
    label: "Virginia"
  },
  {
    value: 3229,
    label: "Washington"
  },
  {
    value: 3205,
    label: "West Virginia"
  },
  {
    value: 3215,
    label: "Wisconsin"
  },
  {
    value: 3230,
    label: "Wyoming"
  }
]

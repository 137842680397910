import { gql } from '@apollo/client';

export const UPDATE_ARTIST_PROFILE_BY_USER_ID = gql`
  mutation UPDATE_ARTIST_PROFILE_BY_USER_ID(
    $userId: UUID!
    $artistName: Artistname!
    $intro: String!
    $avatarId: UUID
    $location: String!
  ) {
    updateArtistProfileByUserId(
      input: {
        artistProfilePatch: {
          intro: $intro
          artistName: $artistName
          userToUserId: {
            updateById: {
              userPatch: { avatarId: $avatarId, location: $location }
              id: $userId
            }
          }
        }
        userId: $userId
      }
    ) {
      artistProfile {
        artistName
        userId
      }
    }
  }
`;

export const CREATE_ARTIST_APPLICATION = gql`
  mutation ArtistApplication(
    $artistName: String!
    $managementName: String!
    $phoneNumber: String!
    $areaCode: String!
    $emailAddress: Email!
    $musicPlatforms: JSON!
    $socialMediaPlatforms: JSON!
    $intro: String!
    $userId: UUID!
  ) {
    createArtistApplication(
      input: {
        artistApplication: {
          artistName: $artistName
          managementName: $managementName
          phoneNumber: $phoneNumber
          areaCode: $areaCode
          emailAddress: $emailAddress
          musicPlatforms: $musicPlatforms
          socialMediaPlatforms: $socialMediaPlatforms
          intro: $intro
          userId: $userId
        }
      }
    ) {
      artistApplication {
        status
      }
    }
  }
`;

export const CREATE_ARTIST_APPLICATION_PUBLIC = gql`
  mutation ArtistApplicationPublic(
    $artistName: String!
    $managementName: String!
    $phoneNumber: String!
    $areaCode: String!
    $emailAddress: Email!
    $musicPlatforms: JSON!
    $socialMediaPlatforms: JSON!
    $intro: String!
  ) {
    createArtistApplicationsPublic(
      input: {
        artistApplicationsPublic: {
          artistName: $artistName
          managementName: $managementName
          phoneNumber: $phoneNumber
          areaCode: $areaCode
          emailAddress: $emailAddress
          musicPlatforms: $musicPlatforms
          socialMediaPlatforms: $socialMediaPlatforms
          intro: $intro
        }
      }
    ) {
      artistApplicationsPublic {
        status
      }
    }
  }
`;

import React, { FC, memo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { NETWORK_CHAINS } from 'utils/constants';

import SBModal from 'common/components/Modal/SBModal';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';

import { isBrowserSupported } from 'utils/browser-check';
import { useViewport } from 'use-viewport';

import MetamaskClient from 'services/web3.client';

type AccountSettingsTypes = {
  walletAddress: string;
  network: string;
  currency: string;
  usdcBalance: string;
};

export enum ConnectWalletPayoutFlowStates {
  ConnectWallet,
  ConnectionSuccessful,
  ConnectionSuccessfulNoUSDCToken,
  ConnectionFailed,
  WhatIsUSDCToken,
}

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const PayoutConnectCryptoFlow: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onSuccess
}) => {
  const viewport = useViewport();
  const [connectWalletPayoutFlowState, setConnectWalletPayoutFlowStates] =
    useState<ConnectWalletPayoutFlowStates>(
      ConnectWalletPayoutFlowStates.ConnectWallet,
    );
  const [walletDetails, setwalletDetails] = useState<AccountSettingsTypes>({
    walletAddress: '',
    network: '',
    currency: '',
    usdcBalance: '',
  });

  async function connectWallet() {
    const metamaskClient = new MetamaskClient(
      process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
    );
    metamaskClient.connect().then(() => {
      type ObjectKey = keyof typeof NETWORK_CHAINS;
      const myVar = parseInt(metamaskClient.chain) as ObjectKey;
      const network = NETWORK_CHAINS[myVar];

      try {
        metamaskClient.getBalanceUSDC().then(() => {
          const usdc_bal = parseFloat(metamaskClient.balance_USDC);

          setwalletDetails({
            walletAddress: metamaskClient.connected_wallet,
            network: network.name,
            currency: 'US Dollars (USDC)',
            usdcBalance: usdc_bal.toString(),
          });

          setConnectWalletPayoutFlowStates(
            ConnectWalletPayoutFlowStates.ConnectionSuccessful,
          );
        });
      } catch {
        setwalletDetails({
          walletAddress: metamaskClient.connected_wallet,
          network: network.name,
          currency: 'US Dollars (USDC)',
          usdcBalance: '',
        });

        setConnectWalletPayoutFlowStates(
          ConnectWalletPayoutFlowStates.ConnectionSuccessfulNoUSDCToken,
        );
      }
    });
  }

  async function handleUSDCTokenImport() {
    const metamaskClient = new MetamaskClient(
      process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
    );
    metamaskClient.checkUSDCToken().then(() => {
      setConnectWalletPayoutFlowStates(
        ConnectWalletPayoutFlowStates.ConnectionSuccessful,
      );
    });
  }

  function handleClose() {
    if (
      connectWalletPayoutFlowState ===
      ConnectWalletPayoutFlowStates.WhatIsUSDCToken
    ) {
      setConnectWalletPayoutFlowStates(
        ConnectWalletPayoutFlowStates.ConnectionSuccessfulNoUSDCToken,
      );
    } else {
      onClose();
      setConnectWalletPayoutFlowStates(
        ConnectWalletPayoutFlowStates.ConnectWallet,
      );
    }
  }

  function connect_wallet() {
    return (
      <>
        <BoldText
          text={'connect wallet'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <Spacer height={130} />
        {isBrowserSupported() ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}>
            <WhiteButton
              height={45}
              width={170}
              borderRadius={50}
              borderColor={theme.colors.white}
              bgColor={theme.colors.white}
              labelColor={theme.colors.black}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="Connect to MetaMask"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.black}
                  />
                </div>
              }
              onClick={() => {
                connectWallet();
              }}
            />
          </div>
        ) : (
          <>
            <ConnectionContainer>
              <Typography text="Non-Supported Browser" fontWeight="bold" />

              <Spacer height={25} />
              <Typography
                text={
                  <>
                    <span
                      style={{
                        lineHeight: '24px',
                        fontFamily: 'HKGrotesk-Light',
                      }}>
                      Please use
                      <UnderlineText
                        target={'_blank'}
                        href="https://www.google.com/chrome/">
                        Chrome
                      </UnderlineText>
                      or
                      <UnderlineText
                        target={'_blank'}
                        href="https://brave.com/">
                        Brave
                      </UnderlineText>
                      or visit songbits.com on a mobile with the MetaMask app
                      installed.
                    </span>
                  </>
                }
              />
            </ConnectionContainer>
          </>
        )}
      </>
    );
  }

  function connection_success() {
    return (
      <>
        <BoldText
          text={'connect wallet'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />

        <Spacer height={30} />
        <WalletAddressText
          text={
            'Wallet Address'
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="bold"
        />
        <Spacer height={10} />
        <WrapText
          text={
            walletDetails.walletAddress
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={20} />
        
        <div style={{'display': 'flex', 'gap': '5px'}}>
          <Typography
            text={
              walletDetails.network
                ? `Network:`
                : ''
            }
            fontSize="fz18"
            fontColor={theme.colors.white}
            fontWeight="bold"
          />
          <Typography
            text={
              walletDetails.network
                ? `${walletDetails.network}`
                : ''
            }
            fontSize="fz18"
            fontColor={theme.colors.white}
            fontWeight="light"
          />
        </div>
        <Spacer height={20} />

        <div style={{'display': 'flex', 'gap': '5px'}}>
        <Typography
          text={
            'Currency: '
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="bold"
        />
        <Typography
          text={
            walletDetails.currency
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        </div>
        <Spacer height={20} />

        <div style={{'display': 'flex', 'gap': '5px'}}>
        <Typography
          text={
            'USDC Balance:'
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="bold"
        />
        <Typography
          text={
            walletDetails.usdcBalance
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        </div>
        <Spacer height={40} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Connection Successful"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          <WhiteButton
            label="close"
            onClick={() => {
              onSuccess()
              handleClose();
            }}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
        </div>
      </>
    );
  }

  function connection_no_usdc_success() {
    return (
      <>
        <BoldText
          text={'connect wallet'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />

        <Spacer height={30} />
        <WalletAddressText
          text={
            'Wallet Address'
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="bold"
        />
        <Spacer height={10} />
        <WrapText
          text={
            walletDetails.walletAddress
          }
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={20} />
        
        <div style={{'display': 'flex', 'gap': '5px'}}>
          <Typography
            text={
              walletDetails.network
                ? `Network:`
                : ''
            }
            fontSize="fz18"
            fontColor={theme.colors.white}
            fontWeight="bold"
          />
          <Typography
            text={
              walletDetails.network
                ? `${walletDetails.network}`
                : ''
            }
            fontSize="fz18"
            fontColor={theme.colors.white}
            fontWeight="light"
          />
        </div>
        <Spacer height={40} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Connection Successful"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          <WhiteButton
            label="Import USDC Token"
            onClick={() => {
              handleUSDCTokenImport();
            }}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            bgColor={theme.colors.black}
            borderRadius={50}
            height={45}
          />
          <WhiteButton
            label="close"
            onClick={() => {
              onSuccess()
              handleClose();
            }}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
        </div>
      </>
    );
  }
  function connection_failed() {
    return (
      <>
        <BoldText
          text={'connect wallet'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />
        <Spacer height={80} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Connection Failed"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          <WhiteButton
            label="Try Again"
            onClick={() => {
              connectWallet();
            }}
            labelColor={theme.colors.black}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
        </div>
      </>
    );
  }

  function getContent() {
    if (
      connectWalletPayoutFlowState ===
      ConnectWalletPayoutFlowStates.ConnectWallet
    ) {
      return connect_wallet();
    }
    if (
      connectWalletPayoutFlowState ===
      ConnectWalletPayoutFlowStates.ConnectionSuccessfulNoUSDCToken
    ) {
      return connection_no_usdc_success();
    } 
    if (
      connectWalletPayoutFlowState ===
      ConnectWalletPayoutFlowStates.ConnectionSuccessful
    ) {
      return connection_success();
    }
    if (
      connectWalletPayoutFlowState ===
      ConnectWalletPayoutFlowStates.ConnectionFailed
    ) {
      return connection_failed();
    }
    
  }

  return (
    <SBModal
    className={'connect-wallet'}
      isOpen={isOpen}
      width={'434px'}
      height="514px"
      withSettings={false}
      withProceedingText={true}
      content={
        <>
          <CloseButton
            onClick={() => {
              handleClose();
            }}>
            X
          </CloseButton>

          {getContent()}
        </>
      }
    />
  );
};

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const WrapText = styled(Typography)`
    line-break: anywhere;
    line-height: 20px;
`;

export const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  font-size: 18px;

  @media (min-width: 768px) {
    margin: initial;
    width: 357px;
  }
`;

const WalletAddressText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConnectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const UnderlineText = styled.a`
  text-decoration: underline;
  margin-left: 5px;
  margin-right: 5px;
  font-family: HKGrotesk-Light;
`;

export default memo(PayoutConnectCryptoFlow);

import React from 'react';
import styled from 'styled-components';

import Spacer from 'common/components/Spacer/Spacer';

const UserLocationsPlaceholder = () => {
  return (
    <Wrapper>
      <Spacer height={10} />
      {/* map heading */}
      <span
        className={
          'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-4'
        }>
        &nbsp;
      </span>

      {/* Map */}
      <MapPlaceholder
        className={
          'placeholder-wave placeholder fs-18 lh-80 bg-secondary col-12'
        }>
        &nbsp;
      </MapPlaceholder>
      <Spacer height={20} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 576px) {
    margin-bottom: 10px;
  }
`;

const MapPlaceholder = styled.span`
  height: 228px;

  @media (min-width: 576px) {
    height: 300px;
  }
`;

export default UserLocationsPlaceholder;

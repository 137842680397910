type VerificationType = {
  avs?: string;
  cvv?: string;
  eci?: string;
  threeDSecure?: string;
};

export function checkCircleError(
  error_code?: string,
  verification_type?: VerificationType,
  source?: string,
) {
  let error_string = '';

  let error_type = '';
  if (verification_type) {
    error_type = getErrorType(verification_type);
  }
  if (source === 'card') {
    error_type = 'circle';
  }

  if (error_code && error_type) {
    // Card Errors
    if (error_type === 'circle') {
      if (error_code === 'payment_failed') {
        error_string = 'Payment failed please try again.';
      }

      if (error_code === 'card_not_honored') {
        error_string = 'Contact card issuer to query why payment failed';
      }

      if (error_code === 'payment_not_supported_by_issuer') {
        error_string = 'Issuer did not support the payment';
      }

      if (error_code === 'payment_not_funded') {
        error_string = 'Insufficient funds in account to fund payment';
      }

      if (error_code === 'card_invalid') {
        error_string = 'Invalid card number';
      }

      if (error_code === 'card_limit_violated') {
        error_string = 'Exceeded amount or frequency limits';
      }

      if (error_code === 'payment_denied') {
        error_string =
          'Payment denied by Circle Risk Service or card processor risk controls';
      }

      if (error_code === 'payment_fraud_detected') {
        error_string = 'Payment suspected of being associated with fraud';
      }

      if (error_code === 'credit_card_not_allowed') {
        error_string = 'Issuer did not support using a credit card for payment';
      }

      if (error_code === 'payment_stopped_by_issuer') {
        error_string = 'A stop has been placed on the payment or card';
      }

      if (error_code === 'card_account_ineligible') {
        error_string = 'Ineligible account associated with card';
      }
    }

    if ((error_type = '3ds')) {
      // 3DS Error

      if (error_code === 'verification_failed') {
        error_string = 'Verification failed due to an unknown reason';
      }

      if (error_code === 'verification_fraud_detected') {
        error_string = 'Card suspected to be used for fraud';
      }

      if (error_code === 'risk_denied') {
        error_string = 'Card denied by Risk Service';
      }

      if (error_code === 'verification_not_supported_by_issuer') {
        error_string = 'Issuer bank was unable to process the transaction';
      }

      if (error_code === 'verification_stopped_by_issuer') {
        error_string = 'A stop has been placed on the card';
      }

      if (error_code === 'card_failed') {
        error_string =
          'Verification failed due to a problem with the card such as the card number does not exist';
      }

      if (error_code === 'card_invalid') {
        error_string = 'The card number was invalid';
      }

      if (error_code === 'card_address_mismatch') {
        error_string =
          'The billing address provided in the card creation request did not match the one recorded by the issuer bank';
      }

      if (error_code === 'card_zip_mismatch') {
        error_string =
          'The postal code provided in the card creation request did not match the one recorded by the issuer bank';
      }

      if (error_code === 'card_cvv_invalid') {
        error_string = 'Incorrect CVV value provided. Please delete card and re-add.';
      }

      if (error_code === 'card_expired') {
        error_string = 'Card expired';
      }

      if (error_code === 'card_not_honored') {
        error_string = 'The issuing bank did not authorize the card';
      }

      if (error_code === 'card_account_ineligible') {
        error_string = 'The card is not linked to an eligible bank account';
      }

      if (error_code === 'card_limit_violated') {
        error_string =
          'The amount or frequency of payments exceeded card limits';
      }

      if (error_code === 'card_cvv_required') {
        error_string = 'The cvv is either incorrect or missing';
      }

      if (error_code === 'three_d_secure_not_supported') {
        error_string = '3DS transactions are not supported by the issuing bank';
      }

      if (error_code === 'three_d_secure_required') {
        error_string = '3DS is required by the issuing bank';
      }

      if (error_code === 'three_d_secure_failure') {
        error_string = 'Whoops those 3DS details look incorrect';
      }

      if (error_code === 'three_d_secure_action_expired') {
        error_string = 'Looks like you took too long to finish the 3DS process'; // 'The customer took too long to finish the 3DS process. This typically expires after 15 minutes';
      }

      if (error_code === 'three_d_secure_invalid_request') {
        error_string = 'Whoops those 3DS details look incorrect';
      }
    }
  }

  return error_string;
}

function getErrorType(verificationType: VerificationType) {
  return verificationType.cvv ? 'circle' : '3ds';
}

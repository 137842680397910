import * as React from 'react';

import { IconProps } from 'common/common.types';

const Wallet = ({ width = 25, height = 25 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.718 7.85711H20.0037V3.57141C20.0037 3.38197 19.9284 3.20029 19.7945 3.06634C19.6605 2.93239 19.4788 2.85713 19.2894 2.85713H2.14661C1.97747 2.85989 1.81285 2.80253 1.68205 2.69528C1.55124 2.58802 1.46275 2.43782 1.43233 2.27142V2.01428C1.46275 1.84788 1.55124 1.69768 1.68205 1.59042C1.81285 1.48316 1.97747 1.42581 2.14661 1.42857H18.9894C19.1788 1.42857 19.3605 1.35331 19.4945 1.21936C19.6284 1.0854 19.7037 0.903722 19.7037 0.714283C19.7037 0.524843 19.6284 0.343163 19.4945 0.209209C19.3605 0.0752547 19.1788 0 18.9894 0H2.14661C1.57829 0 1.03325 0.225764 0.631386 0.627626C0.229524 1.02949 0.00376016 1.57453 0.00376016 2.14285C-0.00125339 2.22849 -0.00125339 2.31435 0.00376016 2.39999V17.0928C0.00656391 17.4774 0.0850929 17.8576 0.234863 18.2119C0.384633 18.5661 0.602711 18.8874 0.876645 19.1573C1.15058 19.4273 1.475 19.6406 1.83139 19.7852C2.18778 19.9298 2.56916 20.0027 2.95375 19.9999H19.2894C19.4788 19.9999 19.6605 19.9247 19.7945 19.7907C19.9284 19.6568 20.0037 19.4751 20.0037 19.2856V14.9999H20.718C20.9074 14.9999 21.0891 14.9247 21.223 14.7907C21.357 14.6568 21.4322 14.4751 21.4322 14.2857V8.57139C21.4322 8.38196 21.357 8.20027 21.223 8.06632C21.0891 7.93237 20.9074 7.85711 20.718 7.85711ZM18.5751 18.5714H2.95375C2.55647 18.5752 2.17374 18.422 1.88882 18.1451C1.6039 17.8682 1.43984 17.49 1.43233 17.0928V4.19998C1.66418 4.26717 1.90544 4.29613 2.14661 4.2857H18.5751V7.85711H13.5751C12.6279 7.85711 11.7195 8.23338 11.0498 8.90315C10.38 9.57292 10.0037 10.4813 10.0037 11.4285C10.0037 12.3757 10.38 13.2841 11.0498 13.9539C11.7195 14.6237 12.6279 14.9999 13.5751 14.9999H18.5751V18.5714ZM20.0037 13.5714H13.5751C13.0068 13.5714 12.4618 13.3456 12.0599 12.9437C11.658 12.5419 11.4323 11.9968 11.4323 11.4285C11.4323 10.8602 11.658 10.3152 12.0599 9.9133C12.4618 9.51144 13.0068 9.28568 13.5751 9.28568H20.0037V13.5714Z"
      fill="white"
    />
  </svg>
);

export default Wallet;

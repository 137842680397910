import styled from "styled-components";

import Typography from "common/components/Typography/Typography";

export const IconContainer = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
`;

export const PlatformName = styled(Typography)`
    margin-top: 15px;
`;
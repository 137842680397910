import { LocationsType } from 'modules/artist/pages/ArtistAnalytics/types';
import { ILocationsData } from 'services/types';

export function getUniqueInsightLocations(locations: ILocationsData[]) {
  const currentLocations: ILocationsData[] = [];
  for (const location of locations) {
    const locationMatchingIndex = currentLocations.findIndex(
      (item) => item.iso_code === location.iso_code,
    );

    if (locationMatchingIndex > -1) {
      currentLocations[locationMatchingIndex] = {
        ...currentLocations[locationMatchingIndex],
        amount: (
          Number(currentLocations[locationMatchingIndex].amount) +
          Number(location.amount)
        ).toString(),
      };
    } else {
      currentLocations.push(location);
    }
  }

  return currentLocations;
}

export function convertToIsoLocations(
  locations: ILocationsData[],
): LocationsType {
  const currentLocations: LocationsType = {};
  for (const location of locations) {
    if (Object.keys(currentLocations).includes(location.iso_code)) {
      currentLocations[location.iso_code] = {
        ...currentLocations[location.iso_code],
        amount: (
          Number(currentLocations[location.iso_code].amount) +
          Number(location.amount)
        ).toString(),
      };
    } else {
      currentLocations[location.iso_code] = location;
    }
  }

  return currentLocations;
}

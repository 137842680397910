import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { useNavigate } from 'react-router-dom';
//import { useAppDispatch } from 'hooks/reduxToolkit';
//import { resetPersist } from 'store-persist/reducers/modalSlice';
import { useIntercom } from 'react-use-intercom';
import { useStoreActions } from 'store/store';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';
import restService from 'services/rest.service';


type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const KYCModal = ({ show, onClose, onContinue }: ModalProps) => {
  const navigate = useNavigate();
  //const dispatch = useAppDispatch();
  const { shutdown } = useIntercom();

  const logout = useStoreActions((state) => state.authentication.logout);
  /*const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );*/

  useEffect(() => {
      setShowKYCModal(show);
  }, [show]);

  const [showKYCModal, setShowKYCModal,] = useState<boolean>(false);
  const [showKYCancelModal, setShowKYCancelModal] = useState<boolean>(false);
  const [showKYCLoading, setShowKYCLoading,] = useState<boolean>(false);
  
  const setVeriffCancelled = useStoreActions(
    (actions) => actions.websocket.setVeriffCancelled,
  );

  const onLogout = () => {
    //store.persist.clear().then(() => {
      onClose()
      //dispatch(resetPersist());
      window.localStorage.removeItem('purchaseId');
      // Used to prevent unauth banner showing on logout
      window.localStorage.setItem('user_logout', 'logout');
      // Shutdown Intercom
      shutdown();
      logout();
      window.localStorage.removeItem('@veriff-session-url')
      window.localStorage.removeItem('@veriff-session-id')
      setShowKYCancelModal(false)
      setShowKYCModal(false);
      navigate('/');
    //});
  };

  async function LaunchVeriff() {
    setShowKYCLoading(true)

    let url = ''

    if (!window.localStorage.getItem('@veriff-session-url')) {

      try {
        const response = await restService.veriff();
        
        if (!response.errors) {
          
          url = response.verification_data.url
          const session_id = response.session_id
          window.localStorage.setItem('@veriff-session-url', url);
          window.localStorage.setItem('@veriff-session-id', session_id);

        }
      } catch (e: any) {
        e
        setShowKYCLoading(false)
      }
    } else {
      url = window.localStorage.getItem('@veriff-session-url') || ''
    }
        
    createVeriffFrame({
      url,
      onEvent: function(msg) {
        switch(msg) {
          case MESSAGES.CANCELED:
            setVeriffCancelled({
              session_id: window.localStorage.getItem('@veriff-session-id') || '',
            })
            setShowKYCancelModal(true)
            break;
          case MESSAGES.FINISHED:
            localStorage.removeItem('@veriff-session-url')
            setShowKYCancelModal(false)
            setShowKYCModal(false);
            onContinue()
            break;
        }
      },
      onReload: () => {
        // Logic to re-open Veriff SDK after page reload
        // e.g. sessionStorage.setItem('@veriff-session-url', url);
        window.location.reload();
      },
    })
    setShowKYCLoading(false)

  }

  return (
    <>
      <SBModal
        className={'kyc-modal'}
        isOpen={showKYCModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout title={'verification'}>
            <ContentContainer className={'kyc-modal'}>

              <BoldYellowText>To keep all artists and fans safe at SongBits, we are required by law to check your ID an ensure you are over 18.</BoldYellowText>

              <NormalText>
                We have teamed up with our partner Veriff who with a few simple steps will get you verified and get you back stage in a flash. 
              </NormalText>

              <div id='veriff-root'></div>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'kyc-modal-cancel-button'}
                  label={
                    <ButtonText
                      text="cancel"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={() => {
                    setShowKYCancelModal(true);
                  }}
                />
                <FirstButton
                  isLoading={showKYCLoading}
                  className={'kyc-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="ok, lets go"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    LaunchVeriff()
                    //setShowFCAPersonalisedRiskWarningModal(false);
                  }}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />

      <SBModal  
        className={'kyc-modal'}
        isOpen={showKYCancelModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout onClose={() => {}} title={'cancel'}>
            <ContentContainer className={'kyc-modal'}>

              <BoldYellowText>No time to verify?</BoldYellowText>

              <NormalText>
                OK, we appreciate this might not be a good time, so well hold your pass for a while.
              </NormalText>

              <NormalText>When you login next time you’ll be asked to continue before we give you back stage access!</NormalText>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'kyc-modal-cancel-button'}
                  label={
                    <ButtonText
                      text="cancel"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={() => {
                    // Send Cancel WS message for veriff
                    onLogout()
                  }}
                />
                <FirstButton
                  isLoading={showKYCLoading}
                  className={'kyc-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    setShowKYCModal(true)
                    setShowKYCancelModal(false);
                    LaunchVeriff()
                  }}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
      
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 400px;
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: -0.03em;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;


`;

export default KYCModal;

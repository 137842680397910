import * as React from 'react';

import { IconProps } from '../../common.types';

const GooglePay = ({ width = 39, height = 15 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 39 15"
    xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6801 7.41172V11.6991H17.3206V1.11125H20.9276C21.7978 1.09341 22.6387 1.42341 23.2631 2.02734C23.895 2.59686 24.2518 3.41102 24.239 4.26212C24.2569 5.11705 23.9014 5.93885 23.2631 6.50965C22.6324 7.11103 21.8539 7.41172 20.9276 7.41045L18.6801 7.41172ZM18.6801 2.41467V6.11086H20.9607C21.4666 6.12615 21.9546 5.92738 22.3062 5.56299C23.021 4.8686 23.0363 3.72573 22.3419 3.01095C22.3304 2.99821 22.3177 2.98674 22.3062 2.97527C21.9584 2.60323 21.4691 2.39938 20.9607 2.41339L18.6801 2.41467Z" fill="white"/>
<path d="M27.3733 4.21753C28.3786 4.21753 29.1724 4.48637 29.7534 5.02404C30.3344 5.56172 30.6261 6.29815 30.6249 7.23335V11.6978H29.324V10.6926H29.2654C28.7022 11.5207 27.9531 11.9348 27.0179 11.9348C26.2203 11.9348 25.5526 11.6978 25.015 11.2251C24.4926 10.7856 24.197 10.1332 24.2085 9.45157C24.2085 8.70239 24.4913 8.10611 25.0583 7.66272C25.6253 7.22061 26.3808 6.99764 27.3275 6.99764C28.1353 6.99764 28.8003 7.14543 29.3227 7.44103V7.13014C29.3253 6.66509 29.1201 6.22298 28.7608 5.92611C28.4015 5.60121 27.9327 5.42411 27.4485 5.43048C26.6891 5.43048 26.0878 5.75156 25.6444 6.39243L24.4467 5.63816C25.1067 4.6915 26.0814 4.21753 27.3733 4.21753ZM25.6138 9.48088C25.6125 9.83253 25.7794 10.1625 26.0648 10.3677C26.3655 10.6046 26.7388 10.7295 27.1211 10.7219C27.6944 10.7206 28.2448 10.4925 28.6513 10.0861C29.1023 9.6618 29.3265 9.1649 29.3265 8.59282C28.9023 8.25518 28.3111 8.08573 27.553 8.08573C27 8.08573 26.5401 8.21951 26.1706 8.4858C25.7985 8.75718 25.6138 9.0859 25.6138 9.48088Z" fill="white"/>
<path d="M38.0924 4.4545L33.5515 14.892H32.1474L33.8331 11.2404L30.8466 4.45577H32.3245L34.4829 9.65923H34.5122L36.6119 4.45577L38.0924 4.4545Z" fill="white"/>
<path d="M12.7211 6.4867C12.7211 6.07262 12.6867 5.65853 12.6166 5.24954H6.88055V7.59263H10.1652C10.0289 8.34817 9.59058 9.01581 8.95098 9.44136V10.9614H10.9118C12.0598 9.90259 12.7211 8.33798 12.7211 6.4867Z" fill="#4285F4"/>
<path d="M6.88055 12.4304C8.5216 12.4304 9.90401 11.8915 10.9118 10.9627L8.95098 9.44264C8.40566 9.81213 7.70235 10.0236 6.88055 10.0236C5.29428 10.0236 3.94755 8.95466 3.46594 7.51364H1.44647V9.0808C2.4785 11.1334 4.58206 12.4304 6.88055 12.4304Z" fill="#34A853"/>
<path d="M3.46593 7.51238C3.21111 6.75684 3.21111 5.93886 3.46593 5.18459V3.61743H1.44647C0.582622 5.33621 0.582622 7.36076 1.44647 9.07954L3.46593 7.51238Z" fill="#FBBC04"/>
<path d="M6.88054 2.67331C7.74821 2.6593 8.58657 2.98674 9.21344 3.58558L10.9488 1.85024C9.84794 0.816936 8.38909 0.248683 6.87927 0.266521C4.5795 0.266521 2.47722 1.56357 1.44519 3.61743L3.46465 5.18458C3.94754 3.74357 5.29427 2.67331 6.88054 2.67331Z" fill="#EA4335"/>
  </svg>
);

export default React.memo(GooglePay);

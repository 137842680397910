import styled from 'styled-components';

import { md, sm } from 'utils/constants';

export const BottomLine = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export const ProfileImageContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-bottom: 70px;

  @media (min-width: ${md}px) {
    width: initial;
  }
`;

export const AltContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 0;

  @media (min-width: ${sm}px) {
    padding-bottom: 10px;
  }
`;

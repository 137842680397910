import { action } from 'easy-peasy';
import { InstractionalStages } from 'typings/types';
import { InstructionalModel } from './instructionalModel.types';

export const instructionalModel: InstructionalModel = {
  stage: InstractionalStages.initial,
  setStage: action((state, payload) => {
    state.stage = payload;
  }),
};

import React, { useEffect, useState } from 'react';

import { useStoreState, useStoreActions } from 'store/store';


import ReadyToBuyModal from '../FCA/ReadyToBuyModal';
// Personal Information
import PersonalInformationModal from '../FCA/PersonalInformation/PersonalInformationModal';
import PersonalInformationAddressModal from '../FCA/PersonalInformation/PersonalInformationAddressModal';
// Client Categorisation
import QuestionsModal from '../FCA/ClientClassification/QuestionsModal';
import InvestorTypeModal from '../FCA/ClientClassification/InvestorTypeModal';
import InformationRequiredModal from '../FCA/InformationRequired';
// Personal Risk Warning
import PersonalisedRiskWarningModal from '../FCA/PersonalisedRiskWarningModal';
// Appropriateness Assessment
import AppropriatenessAssessment from '../FCA/AppropriatenessAssessment/AppropriatenessAssessment';

// KYC
import KYCModal from '../FCA/KYCModal';
import KYCResubmissionModal from '../FCA/KYCResubmissionModal';
import KYCDeclinedModal from '../FCA/KYCDeclinedModal';
import KYCAddressModal from '../FCA/KYCAddressModal';

import KYCLoadingModal from './KYCLoading';
import LoadingModal from '../FCA/Loading';
import CoolingOffModal from '../FCA/CoolingOff';
//import restService from 'services/rest.service';


const OnboardingFlow = ({  }) => {

  const fcaStep = useStoreState(
    (state) => state.authentication.auth.fcaStep,
  );
  const kycStatus = useStoreState(
    (state) => state.authentication.auth.kycStatus,
  );
  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );
  //const visitorLocation = useStoreState((state) => state.authentication.location);
  const { setFcaStep } = useStoreActions((state) => state.authentication);

  const [showInformationModal, setShowInformationModal] = useState<boolean>(false);
  const [showKYCLoadingModal, setShowKYCLoadingModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  loading
  const [showStep, setShowStep] = useState<any>('');
  
  // Loads on component load - Information Required modal (Fork modal)
  useEffect(() => {
    //console.log('fcaStep: ', fcaStep)
    if (fcaStep === 'cool_off' || fcaStep === 'pending') {
      setShowStep(fcaStep)
      //setShowModal(true)
    } else {
      setShowModal(true)
      //setShowInformationModal(true)
    }
    onClose();
  }, [fcaStep]);

  // Loads on based on WS response load - Information Required modal (Fork modal)
  useEffect(() => {
    //console.log('fcaStep: ', fcaStep)
    //console.log('showStep: ', showStep)
    //console.log('kycStatus: ', kycStatus)
    
    if (fcaStep === 'cool_off') {
      if (showStep === 'kyc_pending') {
        setShowStep(fcaStep)
      }
    } else if (fcaStep === 'kyc') {
      if (kycStatus === 'retry') {
        setShowStep('kyc_retry')
      }
      else if (kycStatus === 'blocked') {
        setShowStep('kyc_declined')
      } else if (kycStatus === 'poa_required') {
        setShowStep('poa_required')
      } else if (kycStatus === 'poa_retry') {
        setShowStep('poa_retry')
      }

    }

  }, [fcaStep, showStep, kycStatus])


  function onClose() {
    setShowStep('')
    setLoading(false)
    setShowInformationModal(false);
    setShowModal(false)
  }
  
  return (isAuthenticated ?
    <>

      <LoadingModal show={showModal} />
      <InformationRequiredModal
        show={showInformationModal}
        onClose={() => {
          onClose()
        }}
        onContinue={(nextStep) => {
          setShowInformationModal(false);
          setLoading(true);
          setShowModal(true)
          //console.log('nextStep: ', nextStep)
          //console.log('showStep: ', showStep)
          setShowStep(nextStep);
          //console.log('showStep: ', showStep)
          if (nextStep) {
            setFcaStep(nextStep)
          }
        }}
      />

      {showStep === 'contact_info' ? (
        <PersonalInformationModal
          show={showModal}
          onClose={() => {
            onClose()
          }}
          onContinue={(nextStep) => {
            setShowInformationModal(false);
            setShowStep(nextStep);
            if (nextStep) {
              setFcaStep(nextStep)
            }
          }}
        />
      ) : (
        <></>
      )}

      {showStep === 'contact_info_address' ? (
        <PersonalInformationAddressModal
          show={showModal}
          onClose={() => {
            onClose()
          }}
          onContinue={(nextStep) => {
            setShowInformationModal(false);
            setShowStep(nextStep);
            if (nextStep) {
              setFcaStep(nextStep)
            }
          }}
        />
      ) : (
        <></>
      )}

      {showStep === 'categorisation' ? (
        <QuestionsModal
          show={showModal}
          onClose={() => {
            onClose()
          }}
          onContinue={(nextStep) => {
            setShowInformationModal(false);
            setShowStep(nextStep);
            if (nextStep) {
              setFcaStep(nextStep)
            }
          }}
        />
      ) : (
        <></>
      )}

      {showStep === 'investor_type' ? (
        <InvestorTypeModal
          show={showModal}
          onClose={() => {
            onClose()
          }}
          onContinue={(nextStep) => {
            setShowInformationModal(false);
            //console.log('fcaStep: ', fcaStep);
            //console.log('nextStep: ', nextStep);
            setShowStep(nextStep);
            if (nextStep) {
              setFcaStep(nextStep)
            }
          }}
        />
      ) : (
        <></>
      )}

      {showStep === 'personal_risk_warning' ? (
        <PersonalisedRiskWarningModal
          show={showModal}
          onClose={() => {
            onClose()
          }}
          onContinue={(nextStep) => {
            setShowInformationModal(false);
            //console.log('fcaStep: ', fcaStep);
            //console.log('nextStep: ', nextStep);
            setShowStep(nextStep);
            if (nextStep) {
              setFcaStep(nextStep)
            }
          }}
        />
      ) : (
        <></>
      )}

      {showStep === 'appropriateness' ||
      showStep === 'appropriateness_retry' ? (
        <AppropriatenessAssessment
          show={showModal}
          onClose={onClose}
          onContinue={(nextStep) => {
            setShowInformationModal(false);
            //console.log('fcaStep: ', fcaStep);
            //console.log('nextStep: ', nextStep);
            setShowStep(nextStep);
            if (nextStep) {
              setFcaStep(nextStep)
            }
          }}
        />
      ) : (
        <></>
      )}

      {showStep === 'kyc' ? <KYCModal show={showModal} onContinue={() => {
        setShowInformationModal(false);
        setShowKYCLoadingModal(true)
        setShowStep('kyc_pending')
      }} onClose={() => {onClose()}} /> : <></>}

      {showStep === 'kyc_retry' ? <KYCResubmissionModal show={showModal} onContinue={() => {
        setShowInformationModal(false);
        setShowKYCLoadingModal(true)
        setShowStep('kyc_pending')
      }} onClose={() => {onClose()}} /> : <></>}

      {showStep === 'kyc_declined' ? <KYCDeclinedModal show={showModal} onClose={() => {onClose()}} /> : <></>}

      {showStep === 'poa_required' ? <KYCAddressModal show={showModal} onContinue={() => {
        setShowInformationModal(false);
        setShowKYCLoadingModal(true)
        setShowStep('poa_pending')
      }} onClose={() => {onClose()}} /> : <></>}

      {showStep === 'poa_retry' ? <KYCResubmissionModal show={showModal} onContinue={() => {
        setShowKYCLoadingModal(true)
        setShowStep('poa_pending')
      }} onClose={() => {onClose()}} /> : <></>}

      {showStep === 'poa_declined' ? <KYCDeclinedModal show={showModal} onClose={() => {onClose()}} /> : <></>}

      {(showStep === 'kyc_pending' || showStep === 'poa_pending') ? <KYCLoadingModal show={showKYCLoadingModal} /> : <></>}

      {showStep === 'cool_off' ? <CoolingOffModal show={showModal} onClose={() => {
            onClose()
          }} /> : <></>}

      <ReadyToBuyModal show={false} />


    </>
  : <></> );
};


export default OnboardingFlow;

import { IconProps } from 'common/common.types';
import * as React from 'react';

const LinkedIn = ({ width = 45, height = 45 }: IconProps) => (
  <svg 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    height={height}
    width={width}
    viewBox="0 0 45 45" 
    fill="none"
  >
<path d="M45 38.75C45 42.2025 42.2025 45 38.75 45H6.25C2.79875 45 0 42.2025 0 38.75V6.25C0 2.7975 2.79875 0 6.25 0H38.75C42.2025 0 45 2.7975 45 6.25V38.75Z" fill="#0288D1"/>
<path d="M7.5 16.25H13.75V37.5H7.5V16.25ZM10.6062 13.75H10.5712C8.70625 13.75 7.5 12.36 7.5 10.6237C7.5 8.85 8.74375 7.5 10.6425 7.5C12.5437 7.5 13.715 8.85 13.75 10.6237C13.75 12.3587 12.5437 13.75 10.6062 13.75ZM37.5 37.5H31.25V26.1262C31.25 23.3787 29.7187 21.5037 27.26 21.5037C25.3837 21.5037 24.3687 22.7687 23.8762 23.9912C23.6962 24.4287 23.75 25.6387 23.75 26.25V37.5H17.5V16.25H23.75V19.52C24.6512 18.125 26.0625 16.25 29.6725 16.25C34.145 16.25 37.4987 19.0625 37.4987 25.3425L37.5 37.5Z" fill="white"/>
</svg>
);

export default LinkedIn;

import * as React from 'react';

import { IconProps } from '../common.types';

const Heart = ({ width = 22, height = 20, fill = '#FFD600' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.30846 0C2.8341 0 0 2.82464 0 6.28741C0 9.40259 2.42521 12.5705 4.73257 15.0992C7.03994 17.6279 9.34103 19.4742 9.34103 19.4742C9.34802 19.4797 9.3551 19.4852 9.36226 19.4905C9.73478 19.7709 10.2022 19.9531 10.7095 19.9869C10.7623 19.9963 10.8159 20.0007 10.8696 19.9999C10.9227 20.0006 10.9757 19.9962 11.028 19.9869C11.0307 19.9864 11.0334 19.9858 11.0361 19.9853C11.5409 19.9503 12.006 19.7696 12.3769 19.4905C12.384 19.4852 12.3911 19.4797 12.3981 19.4742C12.3981 19.4742 14.6992 17.6279 17.0066 15.0992C19.3139 12.5705 21.7391 9.40259 21.7391 6.28741C21.7391 2.82464 18.905 0 15.4307 0C13.245 0 11.6973 1.19879 10.8696 2.04752C10.0419 1.19879 8.49411 0 6.30846 0ZM6.30846 1.66666C8.59208 1.66666 9.98146 3.53536 10.12 3.72882C10.1908 3.86462 10.2977 3.97838 10.4291 4.05762C10.5604 4.13686 10.7111 4.17852 10.8647 4.17804C10.8712 4.17812 10.8777 4.17812 10.8843 4.17804C10.8908 4.17758 10.8973 4.17703 10.9039 4.17641C10.9251 4.17559 10.9464 4.17397 10.9675 4.17153C11.1034 4.15667 11.2336 4.10884 11.3466 4.03223C11.4596 3.95562 11.5521 3.85256 11.6159 3.73208C11.7474 3.5479 13.1406 1.66666 15.4307 1.66666C18.0015 1.66666 20.0669 3.7252 20.0669 6.28741C20.0669 8.45637 17.9764 11.5584 15.7687 13.9778C13.5685 16.389 11.3777 18.1503 11.3627 18.1624C11.2159 18.2713 11.0528 18.3333 10.8696 18.3333C10.6864 18.3333 10.5232 18.2713 10.3764 18.1624C10.3614 18.1503 8.1706 16.389 5.97042 13.9778C3.76274 11.5584 1.67224 8.45637 1.67224 6.28741C1.67224 3.7252 3.73768 1.66666 6.30846 1.66666Z"
      fill={fill}
    />
  </svg>
);

export default Heart;

import * as React from 'react';

import { IconProps } from '../../common.types';

const CardDeposit = ({ width = 27, height = 18 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 11.25V13.75H18.75V17.5H21.25V13.75H25V11.25H21.25V7.5H18.75V11.25H15ZM14.9625 7.0125C14.8125 5.35 13.2375 3.7125 11.25 3.75C9.125 3.7875 7.5 5.375 7.5 7.5C7.5 9.625 9.125 11.175 11.25 11.25C11.725 11.25 12.2125 11.15 12.675 10.9625C13.0125 9.5875 13.575 8.2875 14.9625 7.0125ZM12.5 12.5H5C5 11.837 4.73661 11.2011 4.26777 10.7322C3.79893 10.2634 3.16304 10 2.5 10V5C3.8875 5 5 3.8875 5 2.5H17.5C17.5 3.16304 17.7634 3.79893 18.2322 4.26777C18.7011 4.73661 19.337 5 20 5V5.075C20.8375 5.075 21.675 5.225 22.5 5.5V0H0V15H12.9C12.6344 14.1933 12.4994 13.3493 12.5 12.5Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);

export default CardDeposit;

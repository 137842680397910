import { useFormik } from 'formik';
import React, { useMemo, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';
import { useStoreActions } from 'store/store';
import Select from 'react-select';

import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import restService from 'services/rest.service';
import {
  CardSchema,
  OptionType,
  PaymentModalType,
  BrowserDetails,
} from 'modules/payments/types';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import { BeatLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import SBModal from 'common/components/Modal/SBModal';
import CardPaymentResultModal from './CardPaymentResultModal';
import Clock from 'common/icons/Clock.icon';
import Visa from 'common/icons/Visa.icon';
import Edit from 'common/icons/Edit.icon';
import AddCard from 'modules/payments/PaymentModals/AddCard';
import CVVModal from './CVVModal';
import { checkCircleError } from 'utils/circle-errors';
import { StripeCVVResponse } from "utils/stripe";

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  amount: Yup.string()
    .matches(/^[0-9]+(\.[0-9]{1,2})?$/, 'Enter amount to 2dp')
    .required('Amount is required'),
});

const cardOptions = [{ value: '', label: '' }];

interface ModalProps {
  onPaymentComplete: (paymentId: string) => void;
  onPaymentFail: () => void;
  onAddCard: () => void;
  onClose: () => void;
  type: PaymentModalType;
  card?: CardSchema;
  cost?: string;
}

export const getBrowserDetails = (): BrowserDetails => {
  const depth = screen.colorDepth;
  let colorDepth = 1;
  if (depth < 3) {
    colorDepth = 1;
  } else if (depth >= 3 && depth <= 6) {
    colorDepth = 4;
  } else if (depth >= 7 && depth <= 12) {
    colorDepth = 8;
  } else if (depth >= 13 && depth <= 15) {
    colorDepth = 15;
  } else if (depth >= 16 && depth <= 20) {
    colorDepth = 16;
  } else if (depth >= 21 && depth <= 28) {
    colorDepth = 24;
  } else if (depth >= 29 && depth <= 40) {
    colorDepth = 32;
  } else if (depth > 40) {
    colorDepth = 48;
  }
  const d = new Date();
  const timeZoneOffset = String(d.getTimezoneOffset());
  return {
    javaEnabled: false, //navigator.javaEnabled() // deprecated, cannot use
    language: navigator.language,
    colorDepth,
    screenHeight: screen.height,
    screenWidth: screen.width,
    timeZoneOffset,
  }
}

const CardPaymentModal: FC<ModalProps> = ({
  onPaymentComplete,
  onClose,
  type,
  card,
}) => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const [isAwaitingPaymentConfirmation, setIsAwaitingPaymentConfirmation] =
    useState<boolean>(false);
  const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
  const [paymentFailedText, setPaymentFailedText] = useState<string>('');
  const [show3dsFrame, setShow3dsFrame] = useState<boolean>(false);
  const [threeDSLink, set3DSLink] = useState<string>();
  const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);
  const [showCardPaymentResultModal, setShowCardPaymentResultModal] =
    useState<boolean>(false);
  const [showCvvModal, setShowCvvModal] = useState<boolean>(false);
  const [cardData, setCardData] = useState<CardSchema[]>([]);

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  type AmountError = {
    show: boolean;
    msg: string;
  };

  const [showAmountError, setShowAmountError] = useState<AmountError>({
    show: false,
    msg: '',
  });

  async function getCards() {
    const cardData = restService.getCardsList().then((response) => {
      cardOptions.length = 0;

      const cards: CardSchema[] = [];
      setCardData([]);

      response.forEach((element: any) => {
        cardOptions.push({
          value: element.cardid,
          label: element.nickname + ' / ' + element.last4,
        });

        cards.push({
          cardId: element.cardid,
          nickname: element.nickname,
          last4: element.last4,
          verification_type: element.verification_type,
        });
      });
      setCardData(cards);
    });
    return cardData;
  }

  useEffect(() => {
    getCards();
  }, []);

  //  Bind global window function to redirect from 3DS response
  useEffect(() => {
    (window as any).finalise3DS = function (id: string) {
      // set loader

      // poll here based on payment ID

      setShow3dsFrame(false);
      setIsAwaitingPaymentConfirmation(true);

      let poll: NodeJS.Timer;
      // First couple of api pokes will fail without the delay
      setTimeout(() => {
        // Poll payment endpoint
        poll = setInterval(() => {
          
          restService
            .getPayment(id)
            .then((res) => {

              if (res.status === 'confirmed') {
                clearInterval(poll);
                setIsAwaitingPaymentConfirmation(false);
                onPaymentComplete(id);
              }
              if (res.status === 'failed') {
                clearInterval(poll);

                let error_string = '';

                error_string = checkCircleError(res.data.errorCode, res.data.verification);
                
                setPaymentFailedText(error_string);
                setIsAwaitingPaymentConfirmation(false);
                setPaymentFailed(true);
              }
            })
            .catch((error) => {
              setIsAwaitingPaymentConfirmation(false);
              setPaymentFailed(true);

              setGlobalBanner({
                title: 'Card Payment Failed:',
                text: error.message,
              });
              clearInterval(poll);
            });
        }, 5000);
      }, 2000);
    };
  }, []);

  const initialValues = useMemo(
    () => ({
      paymentMethod: '',
      amount: '',
      currency: 'USD',
      calculatedAmount: '',
      cvv: '',
    }),
    [],
  );

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {
    // Make card payment

    const paymentDetails: any = {
      purchaseId: window.localStorage.getItem('purchaseId') || "",
      card: {
        cardId: card ? card.cardId : values.paymentMethod,
        cvv: values.cvv
      },
      browser: getBrowserDetails(),
     }

    restService
      .makeCardPayment(paymentDetails)
      .then((res) => {
        setIsAwaitingPaymentConfirmation(true);

        let poll: NodeJS.Timer;
        // First couple of api pokes will fail without the delay
        setTimeout(() => {
          // Poll payment endpoint
          poll = setInterval(() => {

            restService
              .getPayment(res.paymentid)
              .then((res) => {
                if (res.status === 'action_required') {

                  // There will be a 3d secure link in the data element
                  setIsAwaitingPaymentConfirmation(false);
                  clearInterval(poll);

                  // Send iframe to 3d secure link
                  // 3d secure completion will redirect parent
                  set3DSLink(res.data.requiredAction.redirectUrl);
                  setShow3dsFrame(true);
                }
                if (res.status === 'failed') {
                  clearInterval(poll);
                  setGlobalBanner({
                    title: 'Payment Error:',
                    text: 'Card Payment Failed',
                  });
                  setIsAwaitingPaymentConfirmation(false);
                  setShow3dsFrame(false);
                  setPaymentFailed(true);
                }
              })
              .catch((error) => {
                setIsAwaitingPaymentConfirmation(false);
                setShow3dsFrame(false);
                setPaymentFailed(true);
                setGlobalBanner({
                  title: 'Card Payment Failed:',
                  text: error.message,
                });
                clearInterval(poll);
              });
          }, 5000);
        }, 2000);

      })
      .catch((error) => {
        console.error('Error processing payment', error);
        setGlobalBanner({
          title: 'Payment Error:',
          text: 'Card Payment Failed',
        });
      });
  }

  function three_d_secure_window() {
    return (
      <div style={{ background: 'white', height: '400px', width: '400px'}}>
        <iframe
          id="3ds-frame"
          src={threeDSLink}
          width={'100%'}
          height={400}></iframe>
      </div>
    );
  }

  function payment_declined() {
    
    return (
      <div>
        {fund_account_content()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            className='card-payment-failed-text'
            text="Payment Failed"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          {paymentFailedText ? (
            <Typography
              text={paymentFailedText}
              fontWeight="regular"
              fontSize="fz16"
              fontColor={theme.colors.white}
            />
          ) : (
            <></>
          )}
          <WhiteButton
            label="Try Again"
            onClick={() => {}}
            bgColor={theme.colors.white}
            labelColor={theme.colors.black}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
          {/*<WhiteButton
            label="Contact Support"
            onClick={() => {}}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />*/}
        </div>
      </div>
    );
  }

  function loading_screen() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: 'inherit',
          marginTop: '180px',
        }}>
        <BeatLoader color="white" loading={isAwaitingPaymentConfirmation} />
      </div>
    );
  }

  function getCardIcon() {
    // if (values.cardNumber.startsWith('4')) {
    //   return (
    //     <VisaContainer>
    //       <Visa width={40} height={25} />
    //     </VisaContainer>
    //   );
    // } else if (values.cardNumber.startsWith('5')) {
    //   return (
    //     <MastercardContainer>
    //       <Mastercard width={40} height={35} />
    //     </MastercardContainer>
    //   );
    // } else {
    //   return <></>;
    // }
    return (
      <VisaContainer>
        <Visa width={40} height={25} />
      </VisaContainer>
    );
  }

  function fund_account_content() {
    return (
      <>
        <BoldText
          text="Fund your account with a card"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={30} />

        <CardInfo>
          <Typography
            text="Debit & Credit Cards"
            fontColor={theme.colors.white}
            fontSize="fz18"
          />
          <CardInfoRow>
            <ClockContainer>
              <Clock />
            </ClockContainer>
            <Typography text="Instant | 5% + 30¢ service fee |" />
          </CardInfoRow>
          <UnderlineText
            text="See Limits"
            fontColor={theme.colors.white}
            fontSize="fz18"
          />
        </CardInfo>
        <DropdownContainer>
          {type === PaymentModalType.Quickflow && card ? (
            <InputContainer>
              {getCardIcon()}
              <InputWithIcon
                height={70}
                type="text"
                placeholder="Card"
                value={`          •••••••••• ${
                  cardData.length > 0 ? cardData[0].last4 : ''
                }`}
                tabIndex={0}
                onChange={() => {}}
              />
              <EditContainer
                onClick={() => {
                  setShowAddCardModal(true);
                }}>
                <Edit width={20} height={20} />
              </EditContainer>
            </InputContainer>
          ) : (
            <DropDown
              options={cardOptions}
              placeholder="Payment Method"
              onChange={(option) => {
                setFieldValue('paymentMethod', (option as OptionType).value);
              }}
            />
          )}
        </DropdownContainer>
      </>
    );
  }

  function card_payment_form() {
    return (
      <>
        {fund_account_content()}
        <InputContainer>
          <AmountFieldText>
            <Typography
              text="USD $ |"
              fontSize="fz24"
              fontColor={theme.colors.white}
            />
          </AmountFieldText>
          <InputWithPadding
            height={70}
            type="text"
            placeholder="enter amount"
            value={values.amount}
            tabIndex={0}
            withBottomLine
            onChange={handleChange('amount')}
          />
        </InputContainer>
        <Spacer height={10} />
        {showAmountError.show && (
          <Typography
            text={showAmountError.msg}
            fontColor={theme.colors.yellow}
            fontSize="fz12"
            fontWeight="light"
          />
        )}
        <Spacer height={40} />
        <ButtonContainer>
          <WhiteButton
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            labelColor={theme.colors.black}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="Add Funds"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              </div>
            }
            // onClick={submitHandler}
            onClick={() => {
              if (values.amount.length === 0) {
                setShowAmountError({
                  show: true,
                  msg: 'Amount cannot be empty.',
                });
              } else if (!Number(values.amount)) {
                setShowAmountError({
                  show: true,
                  msg: 'Amount must be a number.',
                });
              } else {
                setShowAmountError({ show: false, msg: '' });
                setShowCvvModal(true);
              }
            }}
          />
        </ButtonContainer>
      </>
    );
  }

  function getModalContent() {
    if (show3dsFrame) {
      return three_d_secure_window();
    } else if (isAwaitingPaymentConfirmation) {
      return loading_screen();
    } else if (paymentFailed) {
      return payment_declined();
    } else {
      return card_payment_form();
    }
  }

  return (
    <>
      {cardData.length === 0 ? (
        <></>
      ) : (
        <CustomComponentContainer>
          {!show3dsFrame ? (
            <CloseButton onClick={onClose}>X</CloseButton>
          ) : (
            <></>
          )}
          <BoldText
            text="add funds"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />

          {getModalContent()}

          <SBModal
            className={'card-payment-result'}
            isOpen={showCardPaymentResultModal}
            width="434px"
            height="515px"
            content={
              <CardPaymentResultModal
                uuid={'123'}
                onChange={() => {
                  setShowCardPaymentResultModal(false);
                  navigate('/account/settings');
                }}
              />
            }
          />

          <AddCard
            isOpen={showAddCardModal}
            type={PaymentModalType.AddFunds}
            fromMyWallet={true}
            onClose={() => {
              setShowAddCardModal(false);
            }}
            onCardAdded={() => {
              setShowAddCardModal(false);
              getCards();
            }}
          />

          <SBModal
            className={'cvv'}
            isOpen={showCvvModal}
            width="434px"
            height="515px"
            withProceedingText={true}
            content={
              <>
                <BoldText
                  text="add funds"
                  fontWeight="bold"
                  fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
                />
                <Spacer height={20} />

              <BoldText
                text="Fund your account with a card"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                fontSize="fz24"
              />
                <Spacer height={20} />
                <CVVModal
                  type="addfunds"
                  last4={cardData.length > 0 ? cardData[0].last4 : ''}
                  onClose={() => {
                    setShowCvvModal(false);
                  }}
                  onEncryptedCvv={(encryptedCvv: StripeCVVResponse) => {
                    setFieldValue('cvv', encryptedCvv);
                    setShowCvvModal(false);
                    submitHandler();
                    setIsAwaitingPaymentConfirmation(true);
                  }}
                  stripe={null}
                  elements={null}
                />
              </>
            }
          />
        </CustomComponentContainer>
      )}
    </>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;
  gap: 20px;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 357px;
`;

const DropDown = styled(Select)`
  width: 100%;
`;

const InputWithIcon = styled(TextInput)`
  & > input {
    margin-top: -7px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
    width: 357px;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'HKGrotesk-Light';
`;

const CardInfoRow = styled.div`
  display: flex;
  gap: 10px;
`;

const ClockContainer = styled.div`
  transform: translateY(2px);
`;

const UnderlineText = styled(Typography)`
  text-decoration: underline;
`;

const VisaContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 28%;
`;

// const MastercardContainer = styled.div`
//   position: absolute;
//   right: 0px;
//   top: 28%;
// `;

const EditContainer = styled.div`
  position: absolute;
  z-index: 4;
  right: 45%;
  top: 32%;

  &:hover {
    cursor: pointer;
  }
`;

const AmountFieldText = styled.div`
  position: absolute;
  left: 0px;
  top: 24px;
`;

const InputWithPadding = styled(InputWithIcon)`
  & > input {
    padding-left: 90px;
    padding-top: 10px;
    font-size: 24px;
  }
`;

export default CardPaymentModal;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';

import arrow from '../../../assets/arrow-down-right.png';

const AddSong = () => {
  const navigate = useNavigate();

  const navigateToSongUpload = () => navigate('/library/song-upload');

  return (
    <>
      <Spacer height={10} />
      <AddSongContainer>
        <ArrowImg src={arrow} />
        <AddSongButton
          label="+"
          width={40}
          height={40}
          fontSize={24}
          className="button"
          borderColor={theme.colors.yellow}
          onClick={navigateToSongUpload}
        />
        <AddSongTextContainer>
          <Typography
            fontSize="fz18"
            className="text"
            fontWeight={'light'}
            text=" Add New Song"
            fontColor={theme.colors.white}
          />
        </AddSongTextContainer>
      </AddSongContainer>
    </>
  );
};

const AddSongContainer = styled.div`
  height: 55px;
  width: 415px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:hover .text {
    color: ${theme.colors.yellow};
  }

  &:hover .button {
    background: ${theme.colors.yellow};
    color: ${theme.colors.black};
  }
`;

export const ArrowImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 12px;
`;

const AddSongButton = styled(Button)`
  border-width: 1px;
  cursor: pointer;
  width: 40px;
  padding-bottom: 5px;
  font-size: ${theme.fontSizes.fz24};
`;

const AddSongTextContainer = styled.div`
  width: 350px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
`;

export default AddSong;

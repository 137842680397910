import { action } from 'easy-peasy';

import { PurchaseModel } from './purchaseModel.types';

/*const threeDSecureMethod = {
  type: null,
  status: null,
  redirect_url: null,
}*/

export const purchase: PurchaseModel = {
  numberOfBits: null,
  costOfSelected: null,
  commission: null,
  threeDSecure: null,
  threeDSecureMethod: null,
  googlePayLibraryLoaded: false,

  setNumberOfBits: action((state, payload) => {
    state.numberOfBits = payload;
  }),

  setCostOfSelected: action((state, payload) => {
    state.costOfSelected = payload;
  }),

  setCommission: action((state, payload) => {
    state.commission = payload;
  }),

  resetPurchaseData: action((state) => {
    state.numberOfBits = null;
    state.costOfSelected = null;
    state.commission = null;
  }),

  setThreeDSecure: action((state, payload) => {
    state.threeDSecure = payload;
  }),
  resetThreeDSecure: action((state) => {
    state.threeDSecure = null;
  }),

  setThreeDSecureMethod: action((state, payload) => {
    state.threeDSecureMethod = payload;
  }),
  resetThreeDSecureMethod: action((state) => {
    state.threeDSecureMethod = null;
  }),

  setGooglePayLibraryLoaded: action((state, payload) => {
    state.googlePayLibraryLoaded = payload;
  }),
};

import React, { FC, useState } from 'react';
import styled from 'styled-components';

import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router-dom';
import restService from 'services/rest.service';
import { useStoreActions } from 'store/store';

interface ModalProps {
  artistId: string;
  onClose: () => void;
  onRegisterInterest: () => void;
}

const RegisterInterestModal: FC<ModalProps> = ({
  artistId,
  onClose,
  onRegisterInterest,
}) => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [hasRegisteredInterest, setHasRegisteredInterest] =
    useState<boolean>(false);

  async function registerInterestHandler() {
    restService
      .registerArtistInterest(artistId)
      .then((res) => {
        res
        onRegisterInterest();
        setHasRegisteredInterest(true);
      })
      .catch(() => {
        setGlobalBanner({
          title: 'Error: ',
          text: 'Already registered interest',
        });
      });
  }

  const getModalContent = () => {
    if (hasRegisteredInterest) {
      return (
        <>
          <BoldText
            text="added!"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />

          <Typography
            text="Thanks, we'll email you closer to the release date with further information."
            fontWeight="regular"
            fontSize="fz18"
            letterSpacing="-0.03em"
            lineHeight='24px'
            fontColor={theme.colors.white}
          />

          <Spacer height={40} />

          <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              labelColor={theme.colors.yellow}
              label={
                <ButtonText
                  text="account settings"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={() => {
                setHasRegisteredInterest(false);
                onClose();
                navigate('/account/settings');
              }}
            />
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.yellow}
              label={
                <ButtonText
                  text="close"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={onClose}
            />
          </PaymentRow>
        </>
      );
    } else {
      return (
        <>
          <BoldText
            text="stay tuned"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />

          <Typography
            text="Would you like us to add you to the waitlist and keep you informed on the release date?"
            fontWeight="regular"
            fontSize="fz18"
            letterSpacing="-0.03em"
            lineHeight='24px'
            fontColor={theme.colors.white}
          />

          <Spacer height={40} />

          <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              labelColor={theme.colors.yellow}
              label={
                <ButtonText
                  text="cancel"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={onClose}
            />
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.yellow}
              label={
                <ButtonText
                  text="yes, count me in!"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={registerInterestHandler}
            />
          </PaymentRow>
        </>
      );
    }
  };

  return (
    <CustomComponentContainer>
      <CloseButton
        onClick={() => {
          onClose();
        }}>
        X
      </CloseButton>

      <Spacer height={10} />

      {getModalContent()}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 32px;
  right: 18px;
  font-size: 20px;
  cursor: pointer;
`;

const PaymentRow = styled.div`
  gap: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FirstButton = styled(Button)<{ width?: number }>`
  width: 300px;
  margin: 0 auto;
  border-width: 1px;

  left: -5px;
  position: relative;

  @media (min-width: 576px) {
    width: ${(props) => (props.width ? props.width : '357')}px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

export default RegisterInterestModal;

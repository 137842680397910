import React, { useMemo } from 'react';
import { useViewport } from 'use-viewport';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import { footerHeight, footerMobileHeight, xlg } from 'utils/constants';

// import Slider1 from '../assets/slider-1.jpg';

import {
  ButtonContainer,
  ContentButton,
  FirstButton,
  HeadContainer,
  HeadingSection,
  // Image,
  ImageContainer,
  ImageGradient,
  LeftMarginButton,
  ShowMeLink,
  StrapLineTypography,
  StrapLineTypographyAlt,
  StrapLineTypographyAltDot,
  VideoBackground,
  SubText,
} from './styled/HomePage.styled';

const HomePage = () => {
  const viewport = useViewport();

  const buttonsHeight = useMemo(() => viewport.width < xlg ? 45 : 55, [viewport.width]);

  return (
    <PageContainer reduceFooter={`${footerHeight}px`} reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        {/* <Image src={Slider1} /> */}
        <VideoBackground
          playsInline={true}
          autoPlay={true}
          loop={true}
          muted={true}>
          <source src={'/homepage-02.mp4'} type="video/mp4" />
        </VideoBackground>
        <ImageGradient />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypography
            text={
              <span>
                don’t just listen to music -
              </span>
            }
            fontSize="fz100"
            fontWeight="bold"
            letterSpacing="-0.044em"
          />
          <div
            style={{
              display: 'flex',
            }}>
            <StrapLineTypographyAlt
              text="own a bit"
              fontSize="fz100"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
              letterSpacing="-0.05em"
            />
            <StrapLineTypographyAltDot
              text="."
              fontSize="fz100"
              fontWeight="bold"
            />
          </div>

          <SubText text="Own a share in songs from world class music artists and share in the song’s success." />
        </HeadingSection>

        <ButtonContainer>
          <ShowMeLink to="/library/hot-releases">
            <FirstButton
              height={buttonsHeight}
              width={172}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex' }}>
                    <ContentButton
                      text="show me"
                      fontSize="fz16"
                      fontWeight="bold"
                    />
                    &nbsp;
                    <ContentButton
                      text="songs"
                      fontSize="fz16"
                      fontColor={theme.colors.yellow}
                      fontWeight="bold"
                    />
                  </div>
                </div>
              }
              onClick={() => {}}
            />
          </ShowMeLink>
          <ShowMeLink to="/how">
            <LeftMarginButton
              height={buttonsHeight}
              width={172}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="show me how"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => {}}
            />
          </ShowMeLink>
          <ShowMeLink to="/why">
            <LeftMarginButton
              height={buttonsHeight}
              width={172}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="show me why"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => {}}
            />
          </ShowMeLink>
          <ShowMeLink to="/who">
            <LeftMarginButton
              height={buttonsHeight}
              width={172}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="show me who"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => {}}
            />
            </ShowMeLink>
          
        </ButtonContainer>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(HomePage, true);

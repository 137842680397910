import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
//import { useAppDispatch } from 'hooks/reduxToolkit';
//import { resetPersist } from 'store-persist/reducers/modalSlice';
import { useIntercom } from 'react-use-intercom';
import { useStoreState, useStoreActions } from 'store/store';

import restService from 'services/rest.service';

import { FCAQuestionsResponseTypes } from './types'

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';
import RiskSummaryModal from 'common/components/FCA/RiskSummaryModal';
import { FCA_TRACKING } from './graphql/Mutations.graphql';
import { FCA_TRACKING_VARIABLES, FCA_TRACKING_MUTATION } from './graphql/types';


type ModalProps = {
  show: boolean;
  onContinue: (nextStep?: string) => void;
  onClose: () => void;
};

const PersonalisedRiskWarningModal = ({ show, onContinue, onClose }: ModalProps) => {
  const navigate = useNavigate();
  //const dispatch = useAppDispatch();
  const { shutdown } = useIntercom();
  const { setFcaStep } = useStoreActions((state) => state.authentication);

  const logout = useStoreActions((state) => state.authentication.logout);
  /*const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );*/
  const user_id = useStoreState((state) => state.authentication.auth.tokenData?.user_id || '');
  
  const [showFCAPersonalisedRiskWarningModal, setShowFCAPersonalisedRiskWarningModal] = useState<boolean>(false);
  const [data, setData] = useState<FCAQuestionsResponseTypes>();
  const [showFCARiskSummaryModal, setShowFCARiskSummaryModal] = useState<boolean>(false);
  const [showFCACancelModal, setShowFCACancelModal] = useState<boolean>(false);
  
  const [fcaTracking] = useMutation<
    FCA_TRACKING_MUTATION,
    FCA_TRACKING_VARIABLES
  >(FCA_TRACKING, {});

  useEffect(() => {
      
      if (show) {
        // track FCA Personalised modal loading
        restService.fcaQuestions().then((res: FCAQuestionsResponseTypes) => {
          //console.log(res)
          setData(res)
          fcaTracking({ variables: { status: 'LOADED', userId: user_id } })
        })
      } 
      setShowFCAPersonalisedRiskWarningModal(show)

  }, [show]);


  const onLogout = () => {
    //store.persist.clear().then(() => {
      onClose()
      //dispatch(resetPersist());
      window.localStorage.removeItem('purchaseId');
      // Used to prevent unauth banner showing on logout
      window.localStorage.setItem('user_logout', 'logout');
      window.localStorage.removeItem('@veriff-session-url')
      window.localStorage.removeItem('@veriff-session-id')
      // Shutdown Intercom
      shutdown();
      logout();
      setShowFCAPersonalisedRiskWarningModal(false);
      setShowFCACancelModal(false)
      navigate('/');
    //});
    
  };


  async function submitHandler() {

    const info = {
      answers: [
        {
          id: data?.questions[0].id,
          value: true
        },
      ]
    }

    restService.submitFcaQuestions(info).then((res) => {
      res
      setFcaStep(res.next)
      onContinue(res.next)
    }).catch((error: any) => {
      error
      //console.log(error)
    })
    
  }

  return (
    <>
      {data ? <>
      <SBModal
        className={'fca-personalised-risk-warning'}
        isOpen={showFCAPersonalisedRiskWarningModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout title={'risk warning'}>
            <ContentContainer 
              className={'fca-personalised-risk-warning-modal'}>
              <BoldText>UK Government FCA Risk Warning</BoldText>

              <NormalText>

              <span style={{'float': 'left'}}>
                {data?.questions[0].question}&nbsp;
                <LearnMoreButton
                className={'fca-risk-summary-button'}
                onClick={() => {
                  setShowFCARiskSummaryModal(true);
                  setShowFCAPersonalisedRiskWarningModal(false);
                }}>
                Take 2 mins to learn more.
              </LearnMoreButton></span>

              </NormalText>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'fca-personalised-risk-warning-leave-button'}
                  label={
                    <ButtonText
                      text="Leave"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={() => {
                    setShowFCACancelModal(true)
                  }}
                />
                <FirstButton
                  className={'fca-personalised-risk-warning-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="Continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    // track FCA Personalised modal confirm
                    fcaTracking({ variables: { status: 'CONFIRM', userId: user_id } })

                    submitHandler()
                    
                  }}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />

      <SBModal
        className={'fca-risk-summary'}
        isOpen={showFCARiskSummaryModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <RiskSummaryModal
            onClose={() => {
              setShowFCAPersonalisedRiskWarningModal(true);
              setShowFCARiskSummaryModal(false);
            }}
          />
        }
      />

      <SBModal
        className={'fca-personalised-risk-warning-cancel'}
        isOpen={showFCACancelModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          
          <ModalLayout title={'are you sure?'}>
            <ContentContainer
              className={'fca-personalised-risk-warning-cancel-modal'}>

              <NormalText>
                Leaving now will terminate the process and log you out. You can return at any time and continue should you wish.
              </NormalText>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'fca-personalised-risk-warning-cancel-button'}
                  label={
                    <ButtonText
                      text="Leave"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={() => {
                    // track FCA Personalised modal confirm
                    fcaTracking({ variables: { status: 'DENY', userId: user_id } })
                    onLogout();
                  }}
                />
                <FirstButton
                  className={'fca-personalised-risk-warning-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="Go Back"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    setShowFCACancelModal(false)
                  }}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
      </> : <></>}
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 400px;
`;

const BoldText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  display: inline;
  letter-spacing: -0.03em;
`;

const LearnMoreButton = styled.button`
  text-decoration: underline;
  font-family: 'HKGrotesk-Regular';
  font-size: 18px;
  line-height: 24px;
  background: none;
  border: none;
  color: #fff;
  display: inline;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  width: 100%;
  margin: 0 auto;
  border-width: 1px;
  position: relative;
`;

export default PersonalisedRiskWarningModal;

import React, { FC } from 'react';
import styled from 'styled-components';
import SBModal from 'common/components/Modal/SBModal';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
// import RemainingBits from '../RemainingBits';

interface PaymentModalProps {
  isOpen: boolean;
  artist: string;
  song: string;
  image?: string;
  onRefresh?: () => void;
  onClose: () => void;
}

const QueueFull: FC<PaymentModalProps> = ({
  isOpen,
  artist,
  song,
  image,
  onClose,
  onRefresh,
}) => {
  const viewport = useViewport();

  return (
    <SBModal
    className={'queue-full'}
      isOpen={isOpen}
      withProceedingText={true}
      withFeeText={true}
      feeBottomPos="60px"
      withSettings={false}
      width="440px"
      mobileWidth="100%"
      height="630px"
      top="10%"
      mobileTop="2%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>
          <BoldText
            text="reservation"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={image} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
            </SongDetails>
          </HeadContainer>
          <Column>
            <Spacer height={2} />
            <Typography
              text="All bits are currently reserved..."
              fontSize={'fz24'}
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />

            {/*<Typography text="You will be redirected automatically if a position becomes available" fontSize={'fz24'} fontWeight="light" lineHeight='30px' fontColor={theme.colors.white} />*/}
            {onRefresh ? (
              <>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.yellow}
                  borderColor={theme.colors.yellow}
                  label={
                    <TextContent
                      text="try again now"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    onRefresh();
                  }}
                />
              </>
            ) : (
              <></>
            )}
            <FirstButton
              height={45}
              borderRadius={50}
              bgColor={theme.colors.black}
              labelColor={theme.colors.yellow}
              borderColor={theme.colors.yellow}
              label={
                <TextContent
                  text="try again later"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={() => {
                onClose();
              }}
            />
            <Spacer height={viewport.width < 576 ? 0 : 10} />
            {/* <RemainingBits /> */}
          </Column>
        </>
      }
    />
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 40px;
  min-width: 300px;
  margin-left: -5px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 350px;
  }
`;

const HeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  text-decoration: inherit;
`;

const SongPosterContainer = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

const SongDetails = styled.div`
  width: 280px;
  height: 110px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'HKGrotesk-Black';
`;

// const TextContentThin = styled(Typography)<{
//   withUnderline?: boolean;
//   withCursorPointer?: boolean;
//   gap?: number;
// }>`
//   letter-spacing: -0.03em;
//   flex-shrink: 0;
//   ${(props) => props.withUnderline && 'text-decoration: underline'};
//   ${(props) => props.withCursorPointer && 'cursor: pointer;'};
//   font-weight: 400;
//   font-family: 'HKGrotesk-Regular';
// `;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export default QueueFull;

import styled from 'styled-components';

import Button from 'common/components/Button/Button';

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 15px;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: auto;
`;

export const IconsContainer = styled.div`
  color: white;
  display: flex;
  margin-top: 10px;
  gap: 8px;
  margin-left: -3px;
`;

export const IconButton = styled(Button)`
  display: inline;
`;

export const EmailSupportText = styled.p`
  color: #fff;
  font-size: 18px;
  line-height: 24px;
`;

export const EmailLink = styled.a`
display: in
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;


export const LiveChatButton = styled(Button)`
  padding: 0;
  margin: 0;
  text-align: left;
  justify-content: left;
  align-items: start;


`;
import * as React from 'react';

import { IconProps } from '../common.types';

const XIcon = ({ width = 12, height = 13 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.540039 12.546H2.48404L5.94004 7.416L9.45004 12.546H11.376L7.05604 6.246L11.304 0H9.39604L5.95804 5.13L2.53804 0H0.594039L4.84204 6.246L0.540039 12.546Z"
      fill="#FFD600"
    />
  </svg>
);

export default XIcon;

import * as React from 'react';

import { IconProps } from '../common.types';

const YouTube = ({ width = 15, height = 15, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0488 0C9.52507 0 3.65361 1.14407 3.65361 1.14407L3.63884 1.16112C1.5785 1.49372 0 3.28075 0 5.45406V11.9989V12.0011V18.5438V18.5459C0.00201055 19.5839 0.370531 20.5871 1.03939 21.3755C1.70825 22.1639 2.63364 22.6857 3.64939 22.8474L3.65361 22.8538C3.65361 22.8538 9.52507 24 14.0488 24C18.5725 24 24.444 22.8538 24.444 22.8538L24.4461 22.8517C25.4629 22.6903 26.3894 22.168 27.0587 21.3787C27.7281 20.5894 28.0965 19.5849 28.0976 18.5459V18.5438V12.0011V11.9989V5.45406C28.0961 4.41575 27.7278 3.41201 27.0589 2.62319C26.39 1.83438 25.4643 1.31219 24.4482 1.15047L24.444 1.14407C24.444 1.14407 18.5725 0 14.0488 0ZM10.8068 6.97949L19.4522 11.9989L10.8068 17.0184V6.97949Z"
      fill="white"
      fillOpacity={opacity}
    />
  </svg>
);

export default YouTube;

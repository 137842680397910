import React, { useMemo } from 'react';
import { useViewport } from 'use-viewport';

import Spacer from 'common/components/Spacer/Spacer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { useIntercom } from 'react-use-intercom';

import Banner from '../assets/labels-intro-2.png';
import MobileBanner from '../assets/labels-mobile.jpg';

import {
  ButtonsContainer,
  ContentContainer,
  FirstTitilePart,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  SpacerContainerMobile,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContainer,
  TextContent,
  TextContentHeader,
  TextContentMain,
  Wrapper,
  ContactButton,
  ContentButton,
} from './styled/LabelsIntro.styled';

import { footerHeight, footerMobileHeight, xlg, xs } from 'utils/constants';
import Typography from 'common/components/Typography/Typography';
import { useNavigate } from 'react-router-dom';

const LabelsIntro = () => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const buttonsHeight = useMemo(
    () => (viewport.width < xlg ? 45 : 55),
    [viewport.width],
  );

  const { show, boot } = useIntercom();

  function toggleIntercom() {
    boot();
    show();
  }

  return (
    <PageContainer
      pageTitle="Labels & Artists | SongBits"
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
      <ImageGradient banner={viewport.width < xs ? MobileBanner : Banner }>
          {viewport.width < xs ? <Image src={MobileBanner} /> : <Image src={Banner} />}
        </ImageGradient>
      </ImageContainer>
      <Wrapper>
        <ContentContainer>
          <HeadingSection>
          { viewport.width < xs ? <><Spacer height={30} /></> : <><Spacer height={100} /></>}
            <StrapLineTypographyContainer>
              <FirstTitilePart>
              {viewport.width < xs ? <>
                <StrapLineTypography
                  text={<span>Changing {'\n'}the artist-fan relationship </span>}
                  fontSize="fz100"
                  fontWeight="bold"
                  letterSpacing="-0.03em"
                /></> : <><StrapLineTypography
                text={<span>Changing the artist-fan relationship </span>}
                fontSize="fz100"
                fontWeight="bold"
                letterSpacing="-0.03em"
              /></>}
                
              </FirstTitilePart>
              <div style={{ display: 'flex' }}>
                <StrapLineTypography
                  text="community"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                  letterSpacing="-0.03em"
                />
                <StrapLineTypography
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <StrapLineTypography
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <StrapLineTypography
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
              </div>
            </StrapLineTypographyContainer>
              { viewport.width < xs ? <>
              <Spacer height={240} />
              </> : <><Spacer height={60} /></> }
            <TextContainer>
              <TextContentHeader>
                <Typography
                  fontSize={viewport.width < xs ? "fz18" : "fz24"}
                  lineHeight={viewport.width < xs ? "24px" : "28px"}
                  fontColor={theme.colors.yellow}
                  letterSpacing="-1px"
                  text={viewport.width < xs ? "We empower artists, labels, and" + '\n' + "rights-holders to change the way you monetize your music and build fan communities." : "We empower artists, labels, and rights-holders to change the way you monetize your music and build fan communities."}
                />
              </TextContentHeader>
              <Spacer height={25} />
              
              <TextContentMain>
                <TextContent>
                  Give your fans something they’ve never had before — a share in the success of your next release, or catalog. 
                </TextContent>
                <Spacer height={24} />
                <TextContent>
                  We’ve developed a new way for you to grow the emotional connection you treasure with your fan base, bringing your inner circle with you whilst connecting with them directly through our artist dashboard. 
                </TextContent>
                <Spacer height={24} />
                <TextContent>
                  You control how much you sell, what perks and other incentives you offer your fans, and what songs you list.
                </TextContent>
                <Spacer height={24} />
                <TextContent>
                  You’ll gain access to our Artist Dashboard and access to all the data you need to engage with  your SongBits holders creating a valuable new contact database for further sales . 
                </TextContent>
                <Spacer height={24} />
                <TextContent>
                   It’s a true partnership between you, your fans, and SongBits. 
                </TextContent>
                <Spacer height={24} />
                <TextContent>
                  Apply now for a SongBits Artist or Label account and let’s {viewport.width > xs ? <><TextContent>{'\n'}</TextContent></> : <></>}  ‘play it forward™’ together.
                </TextContent>
              </TextContentMain>
              <Spacer height={30} />
            </TextContainer>

            <ButtonsContainer>
              <ContactButton
                height={buttonsHeight}
                width={172}
                borderRadius={50}
                borderColor={theme.colors.white}
                label={
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex' }}>
                      <ContentButton
                        text="apply"
                        fontSize="fz16"
                        fontWeight="bold"
                      />
                      &nbsp;
                      <ContentButton
                        text="now"
                        fontSize="fz16"
                        fontColor={theme.colors.yellow}
                        fontWeight="bold"
                      />
                    </div>
                  </div>
                }
                onClick={() => {
                  navigate('/labels-artists/signup');
                }}
              />
              <ContactButton
                height={buttonsHeight}
                width={172}
                borderRadius={50}
                borderColor={theme.colors.gray}
                // disabled={true}
                label={
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex' }}>
                      <ContentButton
                        text="contact"
                        fontSize="fz16"
                        fontWeight="bold"
                        fontColor={theme.colors.gray}
                      />
                      &nbsp;
                      <ContentButton
                        text="us"
                        fontSize="fz16"
                        fontColor={theme.colors.gray}
                        fontWeight="bold"
                      />
                    </div>
                  </div>
                }
                onClick={() => {
                  toggleIntercom();
                }}
              />
            </ButtonsContainer>

            <SpacerContainerMobile>
              <Spacer height={45} />
            </SpacerContainerMobile>
          </HeadingSection>
        </ContentContainer>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(LabelsIntro, true);

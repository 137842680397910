import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import { md } from 'utils/constants';


export const WalletAddressDescription = styled(Typography)`
  line-height: 12px;
  padding: 10px 20px 0 5px;
`;

export const WidthSpacer = styled.div<{ width: number; mobileHide?: boolean }>`
  display: ${(props) => props.mobileHide && 'none'};
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};

  @media (min-width: 1280px) {
    display: block;
  }
`;

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
`;

export const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Details = styled.div`
  width: 100%;
  position: relative;
  bottom: 20px;
`;

export const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export const BottomContainer = styled.div`
  padding: 60px 0 30px;
  align-items: center;
  justify-content: end;
  text-align: center;

  @media (min-width: 768px) {
    padding: 80px 0;
  }
`;

export const CustomBottomLinerContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;

export const InProgressContainer = styled.div``;

export const SongImg = styled.img`
  height: 40px;
  width: 40px;
  margin: 0 10px;
  cursor: pointer;
`;

export const SongInfoContent = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  line-height: 14px;
`;

export const CompletedContainer = styled.div``;

export const IncompleteContainer = styled.div`
  padding-bottom: 20px;
`;

export const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropsContent = styled(Typography)<{ lineHeight?: string }>`
  font-size: 16px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '40px')};

  @media (min-width: ${md}px) {
    font-size: 18px;
  }
`;

export const AddButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  border: 1px solid #ffd600;
  background-color: rgb(0, 0, 0, 1);
  color: #fff;
  padding: 12px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ArrowImg = styled.img`
  width: 14px;
  height: 14px;
`;

export const SongContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const DropsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 30px;
`;

export const QuickLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinksList = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 23px;
`;

export const Link = styled(Typography)`
  margin-bottom: 30px;
  cursor: pointer;
`;

export const SubTitle = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 20px;
  letter-spacing: -0.03em;

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

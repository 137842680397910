import * as React from 'react';

import { IconProps } from '../common.types';

const CheckboxChecked = ({ width = 24, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="none" />
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      stroke="white"
      strokeOpacity="0.5"
    />
    <path
      d="M19.3829 7.00026C19.2271 7.0049 19.0793 7.06998 18.9708 7.18172L9.80398 16.3485L6.0319 12.5764C5.97667 12.5189 5.91051 12.473 5.8373 12.4413C5.7641 12.4097 5.68532 12.393 5.60557 12.3922C5.52582 12.3914 5.44672 12.4065 5.37288 12.4366C5.29905 12.4668 5.23197 12.5113 5.17558 12.5677C5.11919 12.6241 5.07462 12.6912 5.04447 12.765C5.01433 12.8389 4.99922 12.918 5.00003 12.9977C5.00084 13.0775 5.01756 13.1562 5.0492 13.2294C5.08084 13.3026 5.12677 13.3688 5.18429 13.424L9.38018 17.6199C9.49259 17.7323 9.64503 17.7954 9.80398 17.7954C9.96292 17.7954 10.1154 17.7323 10.2278 17.6199L19.8184 8.02933C19.905 7.94513 19.9641 7.83674 19.9881 7.71834C20.012 7.59994 19.9997 7.47707 19.9526 7.36583C19.9055 7.25459 19.8259 7.16016 19.7243 7.09493C19.6226 7.0297 19.5036 6.9967 19.3829 7.00026Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export default CheckboxChecked;

import React from 'react';

import { IconProps } from '../common.types';

const LogoSmall = ({ width = 46, height = 22 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 22c.552 0 1-.486 1-1.085V1.085C24 .486 23.552 0 23 0s-1 .486-1 1.085v19.83c0 .599.448 1.085 1 1.085Z"
      fill="#FFD600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 22c.552 0 1-.473 1-1.056V8.056C28 7.473 27.552 7 27 7s-1 .473-1 1.056v12.888c0 .583.448 1.056 1 1.056ZM19 22c-.552 0-1-.486-1-1.085V1.085C18 .486 18.448 0 19 0s1 .486 1 1.085v19.83c0 .599-.448 1.085-1 1.085ZM31 22c.552 0 1-.498 1-1.113V5.113C32 4.498 31.552 4 31 4s-1 .498-1 1.113v15.774c0 .615.448 1.113 1 1.113ZM14 22c-.552 0-1-.486-1-1.084v-8.832c0-.598.448-1.084 1-1.084s1 .486 1 1.085v8.83c0 .6-.448 1.085-1 1.085ZM35 22c.552 0 1-.49 1-1.096v-7.809c0-.604-.448-1.095-1-1.095s-1 .49-1 1.095v7.81c0 .604.448 1.095 1 1.095ZM10 22c-.552 0-1-.475-1-1.061V8.06C9 7.475 9.448 7 10 7s1 .475 1 1.061V20.94c0 .586-.448 1.061-1 1.061ZM40 22c.552 0 1-.478 1-1.068V10.068C41 9.478 40.552 9 40 9s-1 .478-1 1.068v10.864c0 .59.448 1.068 1 1.068ZM6 22c-.552 0-1-.441-1-.986v-2.028A.993.993 0 0 1 6 18c.552 0 1 .441 1 .986v2.028A.993.993 0 0 1 6 22ZM44 22c.552 0 1-.515 1-1.15v-4.7c0-.635-.448-1.15-1-1.15s-1 .515-1 1.15v4.7c0 .635.448 1.15 1 1.15ZM1 22c-.552 0-1-.49-1-1.096v-7.809C0 12.492.448 12 1 12s1 .49 1 1.095v7.81C2 21.508 1.552 22 1 22Z"
      fill="#fff"
    />
  </svg>
);

export default LogoSmall;

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { useViewport } from 'use-viewport';

import Caption from 'common/components/Caption/Caption';
import Media from 'common/components/Media/Media';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
import Earth from 'common/icons/Earth.Icon';
import Drops from 'modules/artist/pages/components/Drops/Drops';
import theme from 'theme/theme';
import { getSoldPercent } from 'utils/calculation';

import { UserDataType, DropsDataType } from 'modules/account/types';

import {
  GET_USER_PROFILE,
  GET_ARTIST_PROFILE,
} from '../graphql/Queries.graphql';
import HeartLiked from 'common/icons/HeartLiked.icon';
import Heart from 'common/icons/Heart.icon';
import SBModal from 'common/components/Modal/SBModal';
import SongProfileShareModal from 'modules/library/pages/components/SongProfileShareModal/SongProfileShareModal';

import {
  BoldText,
  BottomLine,
  Container,
  CustomSpacer,
  DataFieldContainer,
  Details,
  DetailsContainer,
  Ellipsis,
  EllipsisWrapper,
  Image,
  ImageContainer,
  Intro,
  LightText,
  NoImage,
  SocialButton,
  SocialShareContainer,
  WidthSpacer,
  PageContent,
  TitleRowContainer,
  ColumnContainer,
  ArtistName,
  MemeberSince,
  PreviewButton,
  PreviewButtonsContainer,
} from '../../library/pages/styled/ArtistProfile.styled';

import {
  LIKE_USER,
  UNLIKE_USER,
  VIEW_TRACKING,
} from 'modules/library/graphql/Mutations.graphql';
import {
  VIEW_TRACKING_MUTATION,
  VIEW_TRACKING_VARIABLES,
} from 'modules/library/types';
import { getUserIdFromJWT } from 'utils/functions';
import { useStoreActions, useStoreState } from 'store/store';
import ErrorPage from 'modules/prereg/pages/ErrorPage';
import {
  UPDATE_ARTIST_PROFILE_BY_USER_ID_MUTATION,
  UPDATE_ARTIST_PROFILE_BY_USER_ID_VARIABLES,
} from '../types';
import { UPDATE_ARTIST_PROFILE_BY_USER_ID } from '../graphql/Mutations.graphql';
import { footerHeight, footerMobileHeight } from 'utils/constants';

const ArtistProfilePreview = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserDataType>();
  const [isLiked, setIsLiked] = useState<string>('');
  const [showSocialModal, setShowSocialModal] = useState<boolean>(false);

  const [socialTopPosition, setSocialTopPosition] = useState<string>('40%');

  const viewport = useViewport();
  const userId = getUserIdFromJWT();

  const user = useStoreState((state) => state.preview.user);
  const previewData = useStoreState((state) => state.preview.previewData);

  const updateIsEditingPreviewStore = useStoreActions(
    (state) => state.preview.setIsEditingPreview,
  );

  // Get user profile from slug
  const { data: userProfile } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { slug: user, userid: userId },
  });

  const { data: artistProfile } = useQuery(GET_ARTIST_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { slug: user, userid: userId },
  });

  const [likeUser] = useMutation(LIKE_USER, {
    variables: { likedBy: userId, likedUser: userData ? userData.id : '' },
  });

  const [unlikeUser] = useMutation(UNLIKE_USER, {
    variables: { likeid: isLiked },
  });

  const [createViewTracking] = useMutation<
    VIEW_TRACKING_MUTATION,
    VIEW_TRACKING_VARIABLES
  >(VIEW_TRACKING);

  function formatDate(date: string) {
    const values = date.split('T')[0].split('-');

    const new_date = new Date(
      parseInt(values[0].substring(0)),
      parseInt(values[1]) - 1,
      parseInt(values[2]),
    );

    return (
      values[2] +
      ' ' +
      new_date.toLocaleString('default', { month: 'short' }) +
      ' ' +
      values[0].substring(0)
    );
  }
  function parseDate(date: string) {
    const d = new Date(date).toDateString().split(' ');
    return `${d[1]} ${d[3]}`;
  }

  useEffect(() => {
    if (artistProfile && artistProfile?.artistProfileBySlug) {
      const data = previewData;
      const artistData = artistProfile.artistProfileBySlug;

      let createdAt = '';

      if (artistData.userByUserId.createdAt) {
        createdAt = parseDate(artistData.userByUserId.createdAt);
      }

      const Drops: DropsDataType[] = [];

      artistData.currentDrops.nodes.forEach((t: any) => {
        let date = '';

        if (t.releaseAt) {
          date = formatDate(t.releaseAt);
        }

        let sold_percent = '';

        if (t) {
          sold_percent =
            getSoldPercent(
              t.audioLengthMs,
              t.editionsCount,
              t.remainingBits,
            ).toString() + '%';
        } else {
          sold_percent = '0%';
        }

        Drops.push({
          name: t.title + ' - ' + data.artistName,
          date: date,
          editions: t.editionsCount,
          sold: sold_percent,
          cover: t.fileByCoverId.url,
          status: 'current',
          link: '/' + data.slug + '/' + t.slug,
        });
      });

      artistData.completedDrops.nodes.forEach((t: any) => {
        let date = '';

        if (t.releaseAt) {
          date = formatDate(t.releaseAt);
        }

        let sold_percent = '';

        if (t) {
          sold_percent =
            getSoldPercent(
              t.audioLengthMs,
              t.editionsCount,
              t.remainingBits,
            ).toString() + '%';
        } else {
          sold_percent = '0%';
        }

        Drops.push({
          name: t.title + ' - ' + data.artistName,
          date: date,
          editions: t.editionsCount,
          sold: sold_percent,
          cover: t.fileByCoverId.url,
          status: 'completed',
          link: '/' + data.slug + '/' + t.slug,
        });
      });

      let userLiked = '';

      try {
        userLiked = artistData.userByUserId.userlikesByLikedUser.nodes[0].id;
      } catch {}

      const userData = {
        artistName: data.artistName,
        id: data.userId,
        avatar: data.avatar,
        intro: data.intro,
        location: data.location,
        slug: data.slug,
        isArtist: true,
        createdAt: createdAt,
        socialMedia: {
          facebook: data.facebook,
          instagram: data.instagram,
          twitter: data.twitter,
          youtube: data.youtube,
          tiktok: data.tiktok,
        },
        musicLinks: {
          spotify: data.spotify,
          applemusic: data.apple,
          youtube: data.youtube,
          trackd: data.trackd,
        },
        musicPlatforms: data.musicPlatforms,
        socialMediaPlatforms: data.socialMediaPlatforms,
        Drops: Drops,
        MyCollection: [],
        myCollectionPublic: data.myCollectionPublic,
        userLiked: userLiked,
      };
      setUserData(userData);

      setIsLiked(userLiked);

      createViewTracking({
        variables: {
          type: 'ARTIST',
          viewedItem: data.userId,
          userId: userId,
        },
      });
    }
  }, [artistProfile]);

  const likingHandle = () => {
    if (!isLiked) {
      likeUser().then((data) => {
        setIsLiked(data.data.createUserlike.userlike.id);
      });
    } else {
      unlikeUser().then(() => {
        setIsLiked('');
      });
    }
  };

  const [updateArtistProfileByUserId] = useMutation<
    UPDATE_ARTIST_PROFILE_BY_USER_ID_MUTATION,
    UPDATE_ARTIST_PROFILE_BY_USER_ID_VARIABLES
  >(UPDATE_ARTIST_PROFILE_BY_USER_ID, {
    variables: {
      artistName: userData?.artistName ? userData.artistName : '',
      intro: userData?.intro ? userData?.intro : '',
      location: userData?.location ? userData?.location : '',
      avatarId: artistProfile?.artistProfileBySlug?.avatarId || null,
      userId,
    },
  });

  const confirmHandler = () => {
    updateArtistProfileByUserId()
      .then((response) => {
        if (!response.errors) {
          if (response.data) {
            updateIsEditingPreviewStore(true);
            navigate(`/${user}`);
          }
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {userData ? (
        <PageContainer
          reduceFooter={`${footerHeight}px`}
          reduceFooterMobile={`${footerMobileHeight}px`}>
          <PageContent>
            <Container>
              <TitleRowContainer>
                <ColumnContainer>
                  <CustomSpacer height={10} />
                  <ArtistName
                    text={userData.artistName}
                    fontColor={theme.colors.yellow}
                  />
                  <MemeberSince>
                    <Typography
                      fontSize="fz9"
                      fontWeight="regular"
                      text={'Member since ' + userData.createdAt}
                      fontColor={theme.colors.gray}
                    />
                  </MemeberSince>
                </ColumnContainer>
                <SocialShareContainer>
                  {userData.isArtist && (
                    <SocialButton
                      label={isLiked ? <HeartLiked /> : <Heart />}
                      onClick={likingHandle}
                    />
                  )}
                  <EllipsisWrapper>
                    <Ellipsis
                      label="..."
                      onClick={() => {
                        setShowSocialModal(true);
                      }}
                      fontSize={44}
                    />
                  </EllipsisWrapper>
                </SocialShareContainer>
              </TitleRowContainer>
              <ImageContainer className="artist-image">
                {userData.avatar ? (
                  <>
                    <Image src={userData.avatar} alt="image" />
                  </>
                ) : (
                  <NoImage
                    fontSize="fz18"
                    text="no photo"
                    fontColor={theme.colors.yellow}
                  />
                )}

                <PreviewButtonsContainer>
                  <PreviewButton btnType="redBackground">
                    Preview Only
                  </PreviewButton>
                  <PreviewButton
                    btnType="greyBorder"
                    onClick={() => {
                      updateIsEditingPreviewStore(true);
                      navigate('/artist/settings');
                    }}>
                    edit
                  </PreviewButton>
                  <PreviewButton btnType="whiteBorder" onClick={confirmHandler}>
                    confirm
                  </PreviewButton>
                </PreviewButtonsContainer>
              </ImageContainer>
              <DetailsContainer>
                <Details>
                  <BoldText
                    size={24}
                    fontWeight="bold"
                    text={`${userData.isArtist ? 'Artist Profile' : 'Profile'}`}
                    fontColor={theme.colors.white}
                  />
                  <CustomSpacer height={20} hideMobile={true} />
                  <DataFieldContainer>
                    {viewport.width >= 576 && <Earth height={20} width={20} />}
                    <WidthSpacer width={10} />
                    <LightText
                      fontSize="fz18"
                      fontWeight="regular"
                      text={userData.location}
                      fontColor={theme.colors.white}
                    />
                    <BottomLine />
                  </DataFieldContainer>
                  {userData.isArtist && (
                    <>
                      <CustomSpacer height={30} />

                      <WidthSpacer width={10} />
                      <Caption text="Introduction" />

                      <DataFieldContainer>
                        <Intro
                          fontSize="fz18"
                          text={userData.intro}
                          fontWeight="regular"
                          fontColor={theme.colors.white}
                        />
                        <BottomLine />
                      </DataFieldContainer>
                    </>
                  )}
                  <CustomSpacer height={20} />
                  <Media
                    type="socialMedia"
                    caption="Socials"
                    values={userData.socialMedia}
                    isFollowersCount={userData.isArtist}
                    hideBottomLine={!userData.isArtist}
                    artistIcons={userData.isArtist}
                  />
                  <CustomSpacer height={20} />
                  {userData.isArtist && (
                    <Media
                      values={userData.musicLinks || {}}
                      type="musicPlatforms"
                      caption="Listen"
                      hideBottomLine={true}
                      artistIcons={userData.isArtist}
                    />
                  )}
                </Details>
                <WidthSpacer width={30} />
                <CustomSpacer height={30} />
                {userData.isArtist && <Drops drops={userData.Drops} />}
              </DetailsContainer>
              )
            </Container>
          </PageContent>
          {/* Social Share Modal */}
          <SBModal
            className={'share'}
            isOpen={showSocialModal}
            height={viewport.width < 576 ? '168px' : '245px'}
            width={'430px'}
            top={socialTopPosition}
            content={
              <SongProfileShareModal
                imageLink={userData.avatar}
                onChange={() => {
                  setShowSocialModal(false);
                }}
                changeModalPosition={(topPos) => {
                  setSocialTopPosition(topPos);
                }}
              />
            }
          />
        </PageContainer>
      ) : (
        <>
          {(!userData && userProfile) || artistProfile ? <ErrorPage /> : <></>}
        </>
      )}
    </>
  );
};

export default ArtistProfilePreview;

import React from 'react';
import styled from 'styled-components';

import Tip from 'common/components/Tip/Tip';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { sm } from 'utils/constants';

import { useViewport } from 'use-viewport';
const Caption = ({
  text,
  tip,
  fontWeight,
}: {
  text: string;
  tip?: string;
  fontWeight?: 'bold' | 'regular';
}) => {
  const viewport = useViewport();
  return (
    <Container>
      <Typography
        text={text}
        fontSize={viewport.width >= sm ? 'fz18' : 'fz14'}
        fontWeight={fontWeight ?? 'regular'}
        fontColor={theme.colors.white}
      />
      {tip && (
        <Tip
          width={210}
          text={
            <div>
              <Typography text={tip} fontSize={'fz14'} lineHeight={'18px'} />
            </div>
          }
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 95%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Caption;

import React from 'react';
import Typography from 'common/components/Typography/Typography';
import styled from 'styled-components';
import theme from 'theme/theme';

import { md } from 'utils/constants';

const InviteBadge = () => {
  return (
    <BadgeContainer>
      <BadgeText
        text={'Invite only Exclusive'}
        fontColor={theme.colors.black}
        fontWeight="bold"
        fontSize="fz24"
      />
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const BadgeText = styled(Typography)`
  font-size: 14px;
  background: #ffd600;
  padding: 5px 10px;

  @media (min-width: ${md}px) {
    font-size: 24px;
    padding: 5px 15px;
  }
`;

export default InviteBadge;

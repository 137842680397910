import * as React from 'react';

import { IconProps } from '../common.types';

const Mastercard = ({ width = 31, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 107 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="107" height="74" fill="black" />
    <path
      d="M44.5333 15C32.6684 15 23 24.6534 23 36.5C23 48.3466 32.6684 58 44.5333 58C49.8467 58 54.7085 56.0523 58.4698 52.8504C61.8628 55.3644 66.0497 56.8684 70.6 56.8684C81.8676 56.8684 91 47.7502 91 36.5C91 25.2498 81.8676 16.1316 70.6 16.1316C66.0497 16.1316 61.8628 17.6356 58.4698 20.1496C54.7085 16.9477 49.8467 15 44.5333 15ZM44.5333 19.5263C48.5542 19.5263 52.2355 20.9152 55.1406 23.2305C52.0674 26.7962 50.2 31.4261 50.2 36.5C50.2 41.5739 52.0674 46.2038 55.1406 49.7695C52.2355 52.0848 48.5542 53.4737 44.5333 53.4737C35.1188 53.4737 27.5333 45.8999 27.5333 36.5C27.5333 27.1001 35.1188 19.5263 44.5333 19.5263Z"
      fill="white"
    />
  </svg>
);

export default Mastercard;

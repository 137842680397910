import { action } from 'easy-peasy';

import { BalancesModel } from 'store/models/balancesModel/balancesModel.types';

export const balances: BalancesModel = {
  pendingBalance: "0.00",
  availableBalance: "0.00",
  settledBalance: "0.00",
  setPendingBalance: action((state, payload) => {
    state.pendingBalance = payload;
  }),
  setAvailableBalance: action((state, payload) => {
    state.availableBalance = payload;
  }),
  setSettledBalance: action((state, payload) => {
    state.settledBalance = payload;
  }),
  setBalancesData: action((state, payload) => {
    state.pendingBalance = payload.pendingBalance;
    state.availableBalance = payload.availableBalance;
    state.settledBalance = payload.settledBalance;
  }),
};

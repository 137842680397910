import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import TextInput from 'common/components/TextInput/TextInput';

import { sm } from 'utils/constants';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../../modules/library/pages/components/ModalLayout/ModalLayout';
import Answer from './Answer';

import restService from 'services/rest.service';
import { FCAQuestionsTypes, FCAAnswersTypes } from '../types'


type ModalProps = {
  show: boolean;
  question: FCAQuestionsTypes;
  questionNo: number;
  totalQuestions: number;
  answers: FCAAnswersTypes[];
  onContinue: (answer: FCAAnswersTypes) => void;
  onSuccess: () => void;
  onClose: () => void;
  onFailed: (mutiple?: boolean) => void;
};

const QuestionModal = ({ show, question, questionNo, totalQuestions, onContinue, onSuccess, onFailed, answers, onClose}: ModalProps) => {

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [questionAnswer, setQuestionAnswer] = useState<string | boolean | number>('');
  const [error, setError] = useState<boolean>(false);

  async function submitHandler() {

    setLoading(true)

    if (typeof questionAnswer == "boolean") {

    } else if (!questionAnswer) {
      setError(true)
      setLoading(false)
      return;
    }

    let answer: any = {}

    if (!question.multiple_choice) {

      answer = {
        id: question.id,
        value: questionAnswer
      }

    } else {

      answer = {
        id: question.id,
        value: [questionAnswer]
      }
      
    }

    if (parseInt(questionNo.toString()) !== parseInt(totalQuestions.toString())) {

      onContinue(answer)
      setLoading(false)
      setQuestionAnswer('')
      setError(false)

    } else {

      answers.push(answer)

      let info: any = {}
      info = {
        answers: answers
      }

      restService.submitFcaQuestions(info).then((res) => {
        //console.log(res)
        
        if (res.next === 'appropriateness_retry') {
          setQuestionAnswer('')
          onFailed()
        } else if (res.next === 'appropriateness_cool_off') {
          setQuestionAnswer('')
          onFailed(true)
        }
        else if (res.next === 'kyc' || res.next === 'cool_off') {
          setQuestionAnswer('')
          onSuccess()
        }
        
        setLoading(false)
        setError(false)
      }).catch((error: any) => {
        error
        //console.log(error)
        setError(true)
        setLoading(false)
      })

  }
    
  }

  return (
    <>
      <SBModal
        className={'question'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout
            titleBottomMargin="15px"
            title={'quiz'}
            onClose={onClose}>
            <ContentContainer id={'question-modal'} className={`question-modal-${question.id}`}>
              
              <div className='question-id' style={{'display': 'none'}}>{question.id}</div>
              <div className='question-no' style={{'display': 'none'}}>{questionNo}</div>
              <div className='questions' style={{'display': 'none'}}>{totalQuestions}</div>

              <BoldYellowText>Question {questionNo} of {totalQuestions}</BoldYellowText>

              <NormalText className='question-label'>{question.question}</NormalText>
              {question.answers.map((answer, key) => (
                <Answer
                  key={key}
                  option={answer.value}
                  label={answer.label}
                  value={questionAnswer}
                  setValue={(val) => {
                    setQuestionAnswer(val);
                  }}
                />
              ))}

              {error ? <ErrorText>Please select one of the options above</ErrorText> : <></>}
              
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  isLoading={loading}
                  className={'next-question-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="next question"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={submitHandler}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

const ErrorText = styled.p`
  font-family: HKGrotesk-Regular;
  color: ${theme.colors.yellow};
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
  text-align: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: -0.03em;
  white-space: pre-line;
  margin-bottom: 40px;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default QuestionModal;

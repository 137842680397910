import * as React from 'react';

import { IconProps } from '../common.types';

const PDFIcon = ({ width = 52, height = 52 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0215 52.0431C40.3928 52.0431 52.0431 40.3928 52.0431 26.0215C52.0431 11.6502 40.3928 0 26.0215 0C11.6502 0 0 11.6502 0 26.0215C0 40.3928 11.6502 52.0431 26.0215 52.0431Z" fill="#FFD600"/>
<path d="M19.1738 32.1846C23.739 34.6193 28.3042 34.6193 32.8694 32.1846" stroke="#FFD600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.9477 26.0055C25.6742 25.1305 25.6797 23.4406 25.8383 23.4406C26.2977 23.4406 26.2539 25.4586 25.9477 26.0055ZM25.8547 28.5867C25.4336 29.6914 24.9086 30.9547 24.3016 32.0156C25.3023 31.6328 26.4344 31.075 27.7414 30.818C27.0469 30.293 26.3797 29.5383 25.8547 28.5867ZM20.7086 35.4117C20.7086 35.4555 21.4305 35.1164 22.6172 33.2133C22.2508 33.5578 21.0258 34.5531 20.7086 35.4117ZM29.5625 20.75H37V38.6875C37 39.4148 36.4148 40 35.6875 40H17.3125C16.5852 40 16 39.4148 16 38.6875V13.3125C16 12.5852 16.5852 12 17.3125 12H28.25V19.4375C28.25 20.1594 28.8406 20.75 29.5625 20.75ZM29.125 30.1453C28.0312 29.4781 27.3039 28.5594 26.7898 27.2031C27.0359 26.1914 27.4242 24.6547 27.1289 23.6922C26.8719 22.0844 24.8102 22.243 24.5148 23.3203C24.2414 24.3211 24.493 25.732 24.9578 27.5312C24.3234 29.0406 23.3883 31.0641 22.7266 32.2234C22.7211 32.2234 22.7211 32.2289 22.7156 32.2289C21.2336 32.9891 18.6906 34.6625 19.7352 35.9477C20.0414 36.325 20.6102 36.4945 20.9109 36.4945C21.8898 36.4945 22.8633 35.5102 24.2523 33.1148C25.6633 32.65 27.2109 32.0703 28.5727 31.8461C29.7594 32.4914 31.1484 32.9125 32.0727 32.9125C33.6695 32.9125 33.7789 31.1625 33.15 30.5391C32.3898 29.7953 30.1805 30.0086 29.125 30.1453ZM36.6172 17.7422L31.2578 12.3828C31.0117 12.1367 30.6781 12 30.3281 12H30V19H37V18.6664C37 18.3219 36.8633 17.9883 36.6172 17.7422ZM32.5648 31.7039C32.7891 31.5562 32.4281 31.0531 30.2242 31.2117C32.2531 32.0758 32.5648 31.7039 32.5648 31.7039Z" fill="black"/>
  </svg>
);

export default PDFIcon;

import * as React from 'react';

import { IconProps } from '../common.types';

const StopIcon = ({ width = 19, height = 31 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6085 3L15.6085 28"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <path
      d="M3 3L3 28"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);

export default StopIcon;

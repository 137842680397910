import * as React from 'react';

import { IconProps } from '../common.types';

const Spotify = ({ width = 15, height = 15, fill = "#FFD600" }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0C3.375 0 0 3.375 0 7.5S3.375 15 7.5 15 15 11.625 15 7.5 11.625 0 7.5 0Zm2.957 11.25c-.138 0-.206-.069-.344-.137-1.031-.62-2.337-.963-3.712-.963-.757 0-1.582.137-2.27.275-.137 0-.274.069-.343.069a.47.47 0 0 1-.481-.482c0-.274.137-.48.412-.48.894-.207 1.789-.345 2.751-.345a8.73 8.73 0 0 1 4.263 1.1c.137.138.274.207.274.482-.138.344-.345.481-.55.481Zm.755-1.924c-.137 0-.274-.07-.412-.138C9.631 8.432 7.98 8.02 6.194 8.02a10.9 10.9 0 0 0-2.338.275c-.137.07-.206.07-.343.07a.543.543 0 0 1-.551-.552c0-.343.137-.48.481-.619.825-.206 1.65-.412 2.82-.412 1.925 0 3.712.48 5.225 1.375.206.137.344.343.344.55-.07.344-.275.62-.62.62Zm.894-2.201c-.137 0-.206-.069-.412-.138C10.319 6.231 8.325 5.75 6.4 5.75c-.963 0-1.994.138-2.888.344-.138 0-.206.068-.413.068-.412.07-.687-.274-.687-.686 0-.412.206-.62.481-.688 1.1-.276 2.27-.413 3.507-.413 2.131 0 4.331.481 6.05 1.375.207.138.413.344.413.688-.069.412-.344.687-.757.687Z"
      fill={fill}
    />
  </svg>
);

export default Spotify;

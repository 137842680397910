import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import { md } from 'utils/constants';


export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 15px;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: auto;
`;

export const IconButton = styled(Button)`
  display: inline;
`;

export const LiveChatButton = styled(Button)`
  padding: 0;
  margin: 0;
  text-align: left;
  justify-content: left;
  align-items: start;
`;

export const LinkButton = styled.div`
  cursor: pointer;
  margin-top: 5px;
`;

export const FileText = styled(Typography)`
  white-space: pre-line;

  @media (min-width: ${md}px) {
    white-space: normal;
  }

`;

import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { notify } from 'common/components/Toaster/Toaster';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import { useStoreState } from 'store/store';

import ToasterModal from 'common/components/Toaster/components/ToasterModal';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Email from 'common/icons/Email.icon';
import Eye from 'common/icons/Eye.icon';
import EyeClose from 'common/icons/EyeClose.icon';
import Lock from 'common/icons/Lock.icon';
import World from 'common/icons/World.icon';
import UserProfile from 'common/icons/UserProfile.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { useLocation } from 'react-router-dom';

import { countryCodeById } from 'utils/country-ids';
import {
  EMAIL_VALIDATION_PATTERN,
  SPECIAL_CHARACTER_VALIDATION_PATTERN,
} from 'utils/validators';

import AccountBanner from '../assets/create-account.jpg';

import { menuStyleFullWidthSignUp } from 'common/styles/DropdownStylingFullWidthSignUp';

import {
  ContentButton,
  FirstButton,
  Form,
  //GeneratePassword,
  HeadContainer,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  PasswordVisibility,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  TextContent,
  TermsLink,
  TermsContainer,
  LoginContainer,
  LoginLink,
  Wrapper,
  HeaderTitleExtraContainer,
  DropDown,
  DropdownContainer,
} from './styled/CreateAccount.styled';

import { GET_COUNTRY_CODE } from '../graphql/Queries.graphql';
import {
  ErrorsResponses,
} from '../types';
import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';
import Typography from 'common/components/Typography/Typography';
import { useStoreActions } from 'store/store';
import { stateCodeById } from 'utils/state-ids';

const VALIDATION_SCHEMA = Yup.object().shape({
  userName: Yup.string()
    .min(6, 'Username too short. must be 6 chars')
    .required('Username is required')
    .matches(
      /^[a-zA-Z0-9]+$/,
      'Username cannot contain white space or special characters.',
    ),
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must contain 6 characters, including 1 special character')
    .matches(
      SPECIAL_CHARACTER_VALIDATION_PATTERN,
      'Password must contain 6 characters, including 1 special character',
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords don’t match')
    .required('Password confirmation is required'),
  country: Yup.string().required('Country is required'),
});

const CreateAccount = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [showCountrySelection, setShowCountrySelection] =
    useState<boolean>(false);

  const userLocation = useStoreState((state) => state.authentication.location);

  const artistID = searchParams.get('artist') || '';
  const songID = searchParams.get('song') || '';

  const utm = {
    utm_source: searchParams.get('utm_source') || '',
    utm_medium: searchParams.get('utm_medium') || '',
    utm_campaign: searchParams.get('utm_campaign') || '',
  };

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const registerNewUser = useStoreActions(
    (actions) => actions.authentication.registerUser,
  );

  const loadingRegister = useStoreState(
    (actions) => actions.authentication.loadingRegister,
  );

  const userId = useStoreState(
    (actions) => actions.authentication.register.results[0].auser.id,
  );

  const tokenId = useStoreState(
    (actions) => actions.authentication.register.results[0].tokenId,
  );

   // state check onpahload, nonces with username

  useEffect(() => {

    if (userLocation !== null && userLocation !== '') {
      // Z - unknown country

      getCountryData({
        variables: { isoCode: userLocation },
      }).then((res) => {
        setFieldValue(
          'country',
          parseInt(res.data.allCountries.edges[0].node.id),
        );
      });
    } else {
      setShowCountrySelection(true);
    }
  }, [userLocation]);

  useEffect(() => {
    setArtistId('');
    setWaitlistToken('');
    setWaitlistEmail('');
  }, [location]);

  const [toastId, setToastId] = useState<string>();
  const [artistId, setArtistId] = useState<string>('');
  const [waitlistToken, setWaitlistToken] = useState<string>('');
  const [waitlistEmail, setWaitlistEmail] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      country: 0,
      county: 0,
      tokenId: '',
      artist: artistID,
      song: songID,
      utm: utm,
    }),
    [],
  );

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: submitHandler,
    });

  const [getCountryData, { data: countryData, loading: countryLoading }] =
    useLazyQuery(GET_COUNTRY_CODE);


  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
    setShowConfirmPassword(!showPassword);
  };

  const confirmPasswordVisibilityHandler = () => {
    setShowPassword(!showConfirmPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };

  type OptionType = {
    value: string;
    label: string;
  };

  async function submitHandler() {
    toast.dismiss(toastId);

    try {
      await registerNewUser({
        email: values.email,
        username: values.userName,
        password: values.password,
        country: values.country,
        county: values.county || undefined,
        waitlistTokenId: waitlistToken || undefined,
        artist: artistID || undefined,
        song: songID || undefined,
        utm: JSON.stringify(utm),
      });
      (window as any).gtag('event', 'sign_up', (waitlistToken ? {method: 'waitlist'} : {}));
      navigate('/confirm-email', {
        state: {
          id: userId,
          email: values.email as string,
          tokenId: tokenId,
        },
      });
    } catch (e: any) {
      //console.log(e.data.message)
      if (e.data.message === 'County must be set for Country 244') {
        setGlobalBanner({
          title: 'Error: ',
          text: 'State must be set',
        });
      }
      if (e.data.message === 'Invalid artist' || e.data.message === 'Invalid song') {
        setGlobalBanner({
          title: 'Error: ',
          text: e.message,
        });
      }
      if (e.data.message.includes('User already exists')) {
        const toastId = notify({
          autoClose: false,
          customComponent: (
            <ToasterModal
              title="oops!"
              content="User with this username or email already exists"
            />
          ),
        });

        setToastId(toastId as string);
      }
      if (e.data.message.includes(ErrorsResponses.email)) {
        const toastId = notify({
          autoClose: false,
          customComponent: (
            <ToasterModal
              title="oops!"
              content="User with this email is already exist"
            />
          ),
        });

        setToastId(toastId as string);
      }
    }
  }

  useEffect(() => {
    if (searchParams.get('artist')) {
      const artistId = searchParams.get('artist');
      if (artistId) {
        setArtistId(artistId);
      }
    }
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    if (token) {
      setWaitlistToken(token);
    }
    if (email) {
      setFieldValue('email', email);
      setWaitlistEmail(email);
    }
  }, [waitlistToken, waitlistEmail, artistId]);

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer pageTitle={'Create Account | SongBits'}>
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={viewport.width < xs ? 'Let’s get you ' : 'let’s get you '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="backstage"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          <TextContent
            text="Create Account"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />

          <InputContainer>
            <Icon>
              <UserProfile />
            </Icon>
            <InputWithIcon
              value={values.userName}
              inputName={'username'}
              height={viewport.width < xs ? 65 : 70}
              type="text"
              onChange={handleChange('userName')}
              placeholder="Choose a Username"
              autoComplete="username"
              withBottomLine
              error={
                Boolean(errors.userName && touched.userName)
                  ? errors.userName
                  : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <Icon>
              <Email />
            </Icon>
            <InputWithIcon
              value={values.email}
              height={viewport.width < xs ? 65 : 70}
              type="email"
              onChange={handleChange('email')}
              placeholder="Your Email"
              autoComplete="email"
              inputName={'email'}
              withBottomLine
              error={
                Boolean(errors.email && touched.email)
                  ? errors.email
                  : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <Icon>
              <Lock />
            </Icon>
            <InputWithIcon
              value={values.password}
              height={viewport.width < xs ? 65 : 70}
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange('password')}
              placeholder="Password"
              inputName={'new-password'}
              autoComplete="new-password"
              withBottomLine
              errorPoistionBottom={
                viewport.width > 992 || viewport.width < 431 ? '-8px' : '2px'
              }
              error={
                Boolean(errors.password && touched.password)
                  ? errors.password
                  : undefined
              }
            />
            {/*values.password.length === 0 && (
              <GeneratePassword onClick={generatePasswordHandler}>
                Generate Strong?
              </GeneratePassword>
            )*/}
            <PasswordVisibility className={'new-password-visibility'} onClick={passwordVisibilityHandler}>
              {showPassword ? <EyeClose /> : <Eye />}
            </PasswordVisibility>
          </InputContainer>

          <InputContainer>
            <Icon>
              <Lock />
            </Icon>
            <InputWithIcon
              value={values.confirmPassword}
              height={viewport.width < xs ? 65 : 70}
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={handleChange('confirmPassword')}
              inputName={'new-password2'}
              autoComplete="new-password"
              placeholder="Repeat Password"
              withBottomLine={
                (values.country === 0 && !countryData && !countryLoading) ||
                showCountrySelection
              }
              error={
                Boolean(errors.confirmPassword && touched.confirmPassword)
                  ? errors.confirmPassword
                  : undefined
              }
            />
            <PasswordVisibility className={'new-password2-visibility'} onClick={confirmPasswordVisibilityHandler}>
              {showConfirmPassword ? <EyeClose /> : <Eye />}
            </PasswordVisibility>
          </InputContainer>

          {(values.country === 0 && !countryData && !countryLoading) ||
          showCountrySelection ? (
            <>
              <InputContainer>
                <Icon bottom={4}>
                  <World />
                </Icon>
                <DropdownContainer marginLeft={32}>
                  <DropDown
                    className="search-country"
                    options={countryCodeById}
                    value={countryCodeById.find(
                      (data) => data.value === values.country,
                    )}
                    styles={menuStyleFullWidthSignUp}
                    placeholder="Search country"
                    onChange={(option) => {
                      setShowCountrySelection(true);
                      const value = (option as OptionType).value;
                      setFieldValue('country', value);
                    }}
                  />
                </DropdownContainer>
              </InputContainer>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}>
                <Typography
                  text={
                    touched.country && !values.country
                      ? 'Country is required'
                      : ''
                  }
                  fontSize="fz12"
                  fontColor={theme.colors.yellow}
                />
              </div>

              {values.country === 244 && (
                <>
                  <InputContainer>
                    <Icon bottom={4}>
                      <World />
                    </Icon>
                    <DropdownContainer marginLeft={32} paddingTop={10}>
                      <DropDown
                        className="search-state"
                        options={stateCodeById}
                        placeholder={'Search state'}
                        value={stateCodeById.find(
                          (data) => data.value === values.county,
                        )}
                        styles={menuStyleFullWidthSignUp}
                        onChange={(option) => {
                          setFieldValue('county', (option as OptionType).value);
                        }}
                      />
                    </DropdownContainer>
                  </InputContainer>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                    }}>
                    <Typography
                      text={
                        touched.county && !values.county
                          ? 'State is required'
                          : ''
                      }
                      fontSize="fz12"
                      fontColor={theme.colors.yellow}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
            {values.country === 244 && (
                <>
                  <InputContainer>
                    <Icon bottom={4}>
                      <World />
                    </Icon>
                    <DropdownContainer marginLeft={32} paddingTop={10}>
                      <DropDown
                        className="search-state"
                        options={stateCodeById}
                        placeholder={'Search state'}
                        value={stateCodeById.find(
                          (data) => data.value === values.county,
                        )}
                        styles={menuStyleFullWidthSignUp}
                        onChange={(option) => {
                          setFieldValue('county', (option as OptionType).value);
                        }}
                      />
                    </DropdownContainer>
                  </InputContainer>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                    }}>
                    <Typography
                      text={
                        touched.county && !values.county
                          ? 'State is required'
                          : ''
                      }
                      fontSize="fz12"
                      fontColor={theme.colors.yellow}
                    />
                  </div>
                </>
              )}</>
          )}

          <TermsContainer>
            By creating an account, you agree to be bound by our{' '}
            <TermsLink className={'terms-link'} to="/legal/terms-conditions" target="_blank">
              terms and conditions.
            </TermsLink>
          </TermsContainer>

          <FirstButton
            className="register-button"
            height={45}
            width={170}
            borderRadius={50}
            isLoading={loadingRegister}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="create"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="account"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={handleSubmit}
          />

          <>
            <LoginContainer>
              Already got a SongBits account?{'  '}
              <LoginLink className={'login-link'} to="/login">Login now</LoginLink>
            </LoginContainer>
          </>
        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(CreateAccount);

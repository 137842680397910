import React, { FC } from 'react';

import { useStoreActions } from "store/store";

import { ExpressCheckoutElement } from "@stripe/react-stripe-js";
import { StripeExpressCheckoutElementConfirmEvent } from "@stripe/stripe-js";

interface ModalProps {
  stripe: any;
  elements: any;
  onReady: (arg0: any) => void;
  onSuccess: () => void;
  onCancel: (arg0?: any) => void;
  onError: (arg0: any) => void;
  onClick: (arg0?: any) => void;
}

const ExpressCheckout: FC<ModalProps> = ({
  stripe,
  elements,
  onReady,
  onSuccess,
  onCancel,
  onError,
  onClick
}) => {

  const rest = useStoreActions((actions) => actions.data.rest);

  return (
    <>
      <ExpressCheckoutElement 
        onConfirm={(expressCheckout: StripeExpressCheckoutElementConfirmEvent) => {
          const purchaseId = window.localStorage.getItem('purchaseId');
          const type = expressCheckout.expressPaymentType;
          const billingDetails = expressCheckout.billingDetails;
          rest({
            url: "/stripe/payments/token",
            data: {purchaseId, type, billingDetails},
          }).then((res: any) => {
            stripe.confirmPayment({
              elements,
              clientSecret: res.client_secret,
              confirmParams: {
                return_url: res.return_url,
              },
              redirect: "if_required"
            })
            .then(function(result: any) {

              if (result.error) {
                // Inform the customer that there's an error.
                onError(result.error);
              } else {
                onSuccess();
              }
            });
          });
        }} 
        onReady={onReady}
        onCancel={onCancel}
        onEscape={onCancel}
        onClick={onClick}
        options={{
        buttonHeight: 45,
        buttonTheme: {
          applePay: "white",
          googlePay: "white",
        },
        buttonType: {
          applePay: "buy",
          googlePay: "buy",
        },
        layout: {
          maxColumns: 1,
          overflow: "never",
        },
        paymentMethodOrder: ["apple_pay", "google_pay", "amazon_pay", "paypal", "link"],
        paymentMethods: {
          applePay: "always",
          googlePay: "always",
          amazonPay: "never",
          link: "never",
          paypal: "never"
        }
      }}
      />
    </>
  )
};

export default ExpressCheckout;

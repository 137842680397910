import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Select from 'react-select';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import theme from 'theme/theme';

import { md, sm, xlg, xs } from 'utils/constants';

export const Wrapper = styled.div`
  height: 100%;
  z-index: 2;

  padding: 100px 34px 0 34px;

  @media (min-width: ${sm}px) {
    padding: 135px 0 0 110px;
  }

  @media (min-width: ${md}px) {
    padding: 145px 0 0 197px;
  }

  @media (min-width: ${xlg}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const HeadingSection = styled.div`
  @media (min-width: 768px) {
    min-width: 700px;
  }
`;

export const HeadContainer = styled.div`
  width: available;
  flex-direction: column;
  padding: 0;
  margin-bottom: 32px;

  @media (min-width: ${sm}px) {
    display: flex;
    margin-bottom: 35px;
  }

  @media (min-width: ${md}px) {
    margin-bottom: 45px;
  }
`;

export const Form = styled.div`
  padding: 0;
  z-index: 10;

`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 70%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: ${md}px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 65%;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const TextContent = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  line-height: 24px;
  letter-spacing: -0.05em;
  margin-bottom: 16px;

  @media (min-width: ${sm}px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
`;


export const TextInfo = styled(Typography)`
  width: 100%;

  @media (min-width: ${sm}px) {
    width: 570px;
  }
`;


export const FirstButton = styled(Button)`
  padding: 0 30px;
  margin: 45px auto;

  @media (min-width: ${sm}px) {
    margin: 38px 0;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

export const AgreementText = styled(Typography)`
  letter-spacing: -0.03em;
  z-index: 9999;
  color: #fff;
  font-size: 10px;

  @media (min-width: 768px) {
    font-size: initial;
    width: 520px;
  }

  @media (min-width: 992px) {
    margin-left: 35px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div`
  position: absolute;
  bottom: 23px;
  left: 8px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;
    
    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export const PasswordVisibility = styled.div`
  position: absolute;
  right: 25px;
  bottom: 18px;

  z-index: 5;

  cursor: pointer;
`;

export const GeneratePassword = styled.div`
  font-size: 14px;
  color: ${theme.colors.yellow};
  z-index: 5;
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  right: 55px;

  cursor: pointer;
`;

export const StrapLineTypography = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 55px;
  line-height: 55px;
  letter-spacing: -0.03em;
  flex-shrink: 0;

  @media (min-width: ${xs}px) {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.055em;
  }

  @media (min-width: ${md}px) {
    font-size: 100px;
    line-height: 80px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const HeaderTitleExtraContainer = styled.div`
  display: flex;
`;

export const TermsLink = styled(Link)`
display: in
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const TermsContainer = styled.p`
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #fff;

  margin-top: 6px;
  margin-left: 35px;
  z-index: 9999;

  @media (min-width: ${sm}px) {
    width: 520px;
    font-size: 12px;
  }

  @media (min-width: ${md}px) {
    margin-top: 10px;
  }
`;


export const DropdownContainer = styled.div`
  display: flex;
  width: 98%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

export const DropDown = styled(Select)`
  width: 100%;
`;

import * as React from 'react';

import { IconProps } from '../common.types';

const ChainIcon = ({ width = 33, height = 33 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.9307 1.04827e-08C24.1194 -9.82267e-05 22.3075 0.690263 20.9283 2.06912L16.9988 5.9986C15.0403 7.95706 14.4806 10.7852 15.3042 13.2497L18.127 10.427C18.2449 9.61983 18.6013 8.84129 19.2214 8.22122L23.1509 4.29173C23.9171 3.52627 24.9247 3.14205 25.9307 3.14205C26.9366 3.14205 27.9435 3.52548 28.709 4.29173C30.2407 5.82423 30.2407 8.31656 28.709 9.84827L24.7795 13.7778C24.1594 14.3978 23.3817 14.7535 22.5738 14.8722L19.7494 17.6965C20.4787 17.9401 21.2381 18.0695 21.9997 18.0695C23.8112 18.0695 25.6228 17.3796 27.0021 16.0004L30.9316 12.0709C33.6901 9.31317 33.6893 4.82683 30.9316 2.06912C29.5523 0.690656 27.742 9.83262e-05 25.9307 1.04827e-08ZM21.1846 10.1982C20.7763 10.2104 20.3887 10.3811 20.104 10.6741L10.6732 20.1048C10.5224 20.2497 10.402 20.4232 10.319 20.6151C10.236 20.8071 10.1922 21.0137 10.1901 21.2228C10.1879 21.4319 10.2275 21.6393 10.3066 21.8329C10.3856 22.0265 10.5025 22.2024 10.6504 22.3503C10.7983 22.4982 10.9742 22.6151 11.1678 22.6941C11.3614 22.7732 11.5688 22.8128 11.7779 22.8106C11.987 22.8085 12.1936 22.7647 12.3856 22.6817C12.5775 22.5987 12.751 22.4783 12.8959 22.3275L22.3266 12.8967C22.5538 12.6759 22.7089 12.3917 22.7717 12.0812C22.8345 11.7707 22.8021 11.4486 22.6786 11.1569C22.5552 10.8652 22.3465 10.6175 22.0799 10.4465C21.8133 10.2754 21.5012 10.1889 21.1846 10.1982ZM11.3517 14.9474C9.42461 14.8493 7.4672 15.5308 5.99777 16.9996L2.06828 20.9291C-0.689428 23.6868 -0.689428 28.1747 2.06828 30.9324C3.44753 32.3109 5.25846 33 7.06917 33C8.87988 33 10.6908 32.3101 12.0701 30.9309L15.9995 27.0014C17.958 25.0429 18.5193 22.2148 17.6957 19.7503L14.8713 22.5746C14.7535 23.3817 14.397 24.1602 13.7769 24.7803L9.84744 28.7098C8.31572 30.2423 5.82261 30.2423 4.2909 28.7098C2.75919 27.1773 2.75919 24.685 4.2909 23.1533L8.22039 19.2238C8.84046 18.6037 9.619 18.2465 10.4261 18.1278L13.2489 15.305C12.6328 15.0991 11.9941 14.9801 11.3517 14.9474Z"
      fill="white"
    />
  </svg>
);

export default ChainIcon;

import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { sm } from 'utils/constants';

type ToggleProps = {
  radio?: string;
  setRadio: (value: string) => void;
};

const Radio: React.FC<ToggleProps> = ({ radio, setRadio }) => {
  return (
    <RadioInputs>
      <RadioInput>
        <RadioButton
          type="radio"
          name="radio"
          value="Fan/Collector"
          checked={radio === 'Fan/Collector'}
          onChange={(event) => setRadio(event.target.value)}
        />

        <RadioButtonLabel />
        <RadioButtonCheckedContent />

        <div onClick={() => setRadio('Fan/Collector')}>
          <RadioButtonText
            fontSize="fz18"
            text="Fan or Collector"
            fontColor={
              radio === 'Fan/Collector' ? theme.colors.white : theme.colors.gray
            }
          />
        </div>
      </RadioInput>

      <RadioInput isLast>
        <RadioButton
          type="radio"
          name="radio"
          value="Artist"
          checked={radio === 'Artist'}
          onChange={(event) => setRadio(event.target.value)}
        />

        <RadioButtonLabel />
        <RadioButtonCheckedContent />

        <div onClick={() => setRadio('Artist')}>
          <RadioButtonText
            fontSize="fz18"
            text="Artist or Label"
            fontColor={
              radio === 'Artist' ? theme.colors.white : theme.colors.gray
            }
          />
        </div>
      </RadioInput>
    </RadioInputs>
  );
};

const RadioInput = styled.div<{ isLast?: boolean }>`
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: ${(props) => (props.isLast ? 0 : '26px')};

  position: relative;

  box-sizing: border-box;
`;

const RadioButtonText = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  font-family: 'HKGrotesk-Light', 'sans-serif';

  margin-left: 10px;

  @media (min-width: ${sm}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const RadioButtonLabel = styled.label`
  width: 20px;
  height: 20px;

  position: absolute;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  border: 3px solid ${theme.colors.gray};

  cursor: pointer;
`;

const RadioButtonCheckedContent = styled.label`
  width: 20px;
  height: 20px;

  position: absolute;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  border: 3px solid ${theme.colors.gray};

  cursor: pointer;
`;

const RadioButton = styled.input`
  height: 20px;
  width: 20px;

  margin: 0;

  opacity: 0;
  z-index: 1;
  cursor: pointer;

  &:hover ~ ${RadioButtonLabel} {
    background: ${theme.colors.gray};

    &::after {
      display: block;
      width: 12px;
      height: 12px;
    }
  }

  &:checked ~ ${RadioButtonCheckedContent} {
    border: 3px solid ${theme.colors.yellow};
  }

  &:checked + ${RadioButtonLabel} {
    background: ${theme.colors.yellow};
    border: 5px solid ${theme.colors.black};

    &::after {
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

const RadioInputs = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 26px 0 20px 28px;
`;

export default Radio;

import { gql } from '@apollo/client';

export const GET_ARTIST_PROFILE_BY_USER_ID = gql`
  query getArtistProfileByUserId($userId: UUID!) {
    artistProfileByUserId(userId: $userId) {
      artistName
      intro
      userId
      slug
      userByUserId {
        location
        fileByAvatarId {
          url
        }
      }
      musicPlatforms
      socialMediaPlatforms
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query ArtistUserProfile($slug: String!) {
    userBySlug(slug: $slug) {
      id
      location
      myCollectionPublic
      slug
      username
      facebook
      instagram
      twitter
      youtube
      tiktok
      createdAt
      artistProfileByUserId {
        artistName
        intro
        slug
        intro
        slug
        socialMediaPlatforms
        musicPlatforms
        combinedFollowerCount
      }
      fileByAvatarId {
        url
      }
    }
  }
`;

export const GET_ARTIST_PROFILE = gql`
  query ArtistUserProfile($slug: String!, $userid: UUID!) {
    artistProfileBySlug(slug: $slug) {
      artistName
      userId
      intro
      slug
      socialMediaPlatforms
      musicPlatforms
      combinedFollowerCount
      userByUserId {
        location
        createdAt
        facebook
        instagram
        twitter
        youtube
        tiktok
        fileByAvatarId {
          url
        }
        userlikesByLikedUser(condition: { likedBy: $userid }) {
          nodes {
            id
          }
        }
      }
      currentDrops: songsByArtistId(
        filter: { soldBitsInverted: { greaterThan: 0 } }
      ) {
        nodes {
          slug
          title
          fileByCoverId {
            url
          }
          editionsCount
          audioLengthMs
          remainingBits
          soldBitsInverted
          soldBits
          releaseAt
        }
      }
      completedDrops: songsByArtistId(
        filter: { soldBitsInverted: { equalTo: 0 } }
      ) {
        nodes {
          slug
          title
          fileByCoverId {
            url
          }
          editionsCount
          audioLengthMs
          remainingBits
          soldBitsInverted
          soldBits
          releaseAt
        }
      }
    }
  }
`;

export const GET_DROPS = gql`
  query getDrops($userid: UUID!) {
    artistProfileByUserId(userId: $userid) {
      artistName
      slug
      intro
      userId
      userByUserId {
        location
        fileByAvatarId {
          url
        }
      }
      currentDrops: songsByArtistId(
        filter: { soldBitsInverted: { greaterThan: 0 } }
      ) {
        nodes {
          slug
          title
          fileByCoverId {
            url
          }
          editionsCount
          audioLengthMs
          remainingBits
          soldBitsInverted
          soldBits
          releaseAt
        }
      }
      completedDrops: songsByArtistId(
        filter: { soldBitsInverted: { equalTo: 0 } }
      ) {
        nodes {
          slug
          title
          fileByCoverId {
            url
          }
          editionsCount
          audioLengthMs
          remainingBits
          soldBitsInverted
          soldBits
          releaseAt
        }
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query GetProfile($slug: String!) {
    userBySlug(slug: $slug) {
      artistProfileByUserId {
        artistName
        combinedFollowerCount
        intro
        musicPlatforms
        slug
        socialMediaPlatforms
        songsByArtistId {
          nodes {
            slug
            title
            fileByCoverId {
              url
            }
            editionsCount
            audioLengthMs
            remainingBits
            soldBitsInverted
            soldBits
            releaseAt
            songType
          }
        }
        userId
      }
      createdAt
      facebook
      fileByAvatarId {
        url
      }
      id
      instagram
      location
      myCollectionPublic
      slug
      tiktok
      twitter
      userlikesByLikedUser {
        nodes {
          id
        }
      }
      username
      youtube
    }

    artistProfileBySlug(slug: $slug) {
      currentDrops: songsByArtistId(
        filter: { soldBitsInverted: { greaterThan: 0 } }
      ) {
        nodes {
          slug
          title
          fileByCoverId {
            url
          }
          editionsCount
          audioLengthMs
          remainingBits
          soldBitsInverted
          soldBits
          releaseAt
          songType
        }
      }
      completedDrops: songsByArtistId(
        filter: { soldBitsInverted: { equalTo: 0 } }
      ) {
        nodes {
          slug
          title
          fileByCoverId {
            url
          }
          editionsCount
          audioLengthMs
          remainingBits
          soldBitsInverted
          soldBits
          songType
          releaseAt
        }
      }
    }
  }
`;

export const GET_USER_EMAIL = gql`
  query UserEmail($userid: UUID!) {
    userById(id: $userid) {
      email
    }
  }
`;

export const GET_ALL_DROPS = gql`
  query GetAllDrops($userId: UUID!, ) {
    allSongs(filter: { artistId: { equalTo: $userId } }, orderBy: RELEASE_AT_DESC) {
      nodes {
        id
        slug
        title
        fileByCoverId {
          url
        }
        releaseAt
        soldBits
        soldBitsInverted
        audioLengthMs
        actualBitPrice
        remainingBits
      }
    }
  }
`;

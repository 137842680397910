import * as React from 'react';

import { IconProps } from '../common.types';

const CloseCircleIcon = ({ width = 30, height = 30 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group 954">
      <g id="Group 921">
        <g id="Group 921_2">
          <g id="Group 931">
            <circle
              id="Ellipse 6"
              cx="15"
              cy="15"
              r="14"
              fill="black"
              stroke="white"
              strokeWidth="2"
            />
            <path
              id="+"
              d="M20.1758 20.1761C19.6733 20.6786 18.8586 20.6786 18.3561 20.1761L9.45248 11.2725C8.94998 10.77 8.94998 9.95529 9.45248 9.45279C9.95498 8.95029 10.7697 8.95029 11.2722 9.45279L20.1758 18.3564C20.6783 18.8589 20.6783 19.6736 20.1758 20.1761ZM11.2722 20.1761C10.7697 20.6786 9.95498 20.6786 9.45248 20.1761C8.94998 19.6736 8.94998 18.8589 9.45248 18.3564L18.3561 9.45279C18.8586 8.95029 19.6733 8.95029 20.1758 9.45279C20.6783 9.95529 20.6783 10.77 20.1758 11.2725L11.2722 20.1761Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloseCircleIcon;

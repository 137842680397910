import * as React from 'react';

import { IconProps } from '../../common.types';

const PendingDeposit = ({ width = 30, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6136 14.5682L20.3864 16.1705L19.5341 17.6477L15.9091 15.5568V11.3636H17.6136V14.5682ZM25 14.7727C25 19.1705 21.4432 22.7273 17.0455 22.7273C12.6477 22.7273 9.09091 19.1705 9.09091 14.7727C9.09091 14.3864 9.125 14.0114 9.18182 13.6364H0V0H20.4545V7.59091C23.1364 8.875 25 11.6023 25 14.7727ZM9.86364 11.3636C10.0682 10.9545 10.2841 10.5682 10.5455 10.1932C10.4432 10.2273 10.3409 10.2273 10.2273 10.2273C8.34091 10.2273 6.81818 8.70455 6.81818 6.81818C6.81818 4.93182 8.34091 3.40909 10.2273 3.40909C12.1136 3.40909 13.6364 4.93182 13.6364 6.81818C13.6364 7.10227 13.5909 7.38636 13.5227 7.64773C14.5909 7.125 15.7841 6.81818 17.0455 6.81818C17.4318 6.81818 17.8068 6.85227 18.1818 6.90909V4.54545C17.5791 4.54545 17.001 4.30601 16.5748 3.87979C16.1485 3.45357 15.9091 2.87549 15.9091 2.27273H4.54545C4.54545 3.53409 3.53409 4.54545 2.27273 4.54545V9.09091C2.87549 9.09091 3.45357 9.33036 3.87979 9.75658C4.30601 10.1828 4.54545 10.7609 4.54545 11.3636H9.86364ZM22.7273 14.7727C22.7273 11.6364 20.1818 9.09091 17.0455 9.09091C13.9091 9.09091 11.3636 11.6364 11.3636 14.7727C11.3636 17.9091 13.9091 20.4545 17.0455 20.4545C20.1818 20.4545 22.7273 17.9091 22.7273 14.7727Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);

export default PendingDeposit;

import { useFormik } from 'formik';
import React, { useMemo, FC } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';
import Select from 'react-select';

import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { PaymentModalType } from 'modules/payments/types';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
});

const nicknameOptions = [
  { value: 'address nickname', label: 'address nickname' },
];

interface ModalProps {
  onChange: () => void;
  onPaymentComplete?: () => void;
  type: PaymentModalType;
  cost?: string;
}

const CryptoIncomingModal: FC<ModalProps> = ({ onChange }) => {
  const viewport = useViewport();

  const initialValues = useMemo(
    () => ({
      paymentMethod: '',
      depositAddress: '',
      cryptoChain: '',
      currency: 'USD',
    }),
    [],
  );

  const { values, handleChange, errors, touched } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: submitHandler,
    });

  async function submitHandler() {}

  function handleClose() {
    onChange();
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="crypto incoming"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      <DropdownContainer>
        <Select options={nicknameOptions} placeholder="Payment Method" />
      </DropdownContainer>

      <InputContainer>
        <InputWithIcon
          value={values.depositAddress}
          height={70}
          type="text"
          onChange={handleChange('depositAddress')}
          placeholder="Deposit Address"
          withBottomLine
          error={
            Boolean(errors.depositAddress && touched.depositAddress)
              ? errors.depositAddress
              : undefined
          }
        />
      </InputContainer>

      <InputContainer>
        <InputWithIcon
          value={values.cryptoChain}
          height={70}
          type="text"
          onChange={handleChange('cryptoChain')}
          placeholder="Crypto Chain"
          withBottomLine
          error={
            Boolean(errors.cryptoChain && touched.cryptoChain)
              ? errors.cryptoChain
              : undefined
          }
        />
      </InputContainer>

      <InputContainer>
        <InputWithIcon
          value={values.currency}
          height={70}
          type="text"
          onChange={handleChange('currency')}
          placeholder="Currency"
          withBottomLine
          error={
            Boolean(errors.currency && touched.currency)
              ? errors.currency
              : undefined
          }
        />
      </InputContainer>
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
`;

const InputWithIcon = styled(TextInput)`
  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export default CryptoIncomingModal;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { useStoreActions } from 'store/store';

import restService from 'services/rest.service';

import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../../modules/library/pages/components/ModalLayout/ModalLayout';
import InvestorType from './InvestorType';

import { FCAQuestionsResponseTypes } from '../types'


type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: (nextStep?: string) => void;
};


const InvestorTypeModal = ({ show, onClose, onContinue }: ModalProps) => {
  
  const { setFcaStep } = useStoreActions((state) => state.authentication);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<FCAQuestionsResponseTypes>();
  const [answer, setAnswer] = useState<any>('');

  useEffect(() => {
    
    if (show) {
      restService.fcaQuestions().then((res: FCAQuestionsResponseTypes) => {
        setData(res)
      })
    }
    setShowModal(show);
  }, [show]);

  async function submitHandler() {

    setLoading(true)

    if (!answer) {
      setError(true)
      setLoading(false)
      return;
    }

    const info = {
      answers: [
        {
          id: data?.questions[0].id,
          value: answer
        },
      ]
    }

    restService.submitFcaQuestions(info).then((res) => {
      res
      setLoading(false)
      setFcaStep(res.next)
      onContinue(res.next)
      setError(false)
    }).catch((error: any) => {
      error
      //console.log(error)
      setError(true)
      setLoading(false)
    })
    
  }

  return (
    <>
      {data ? <>
      <SBModal

        className={'investor-type'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        top={'10%'}
        height="664px"
        content={
          <ModalLayout
            title={'classification'}
            titleBottomMargin="15px"
            onClose={onClose}>
            <ContentContainer className={'investor-type-modal'}>
              <NormalText>
                {
                  data.questions[0].question.replaceAll('\\n', '\n')
                }
              </NormalText>

              {/*data.questions[0].answers.forEach((a, i) => {
                {console.log(i, a)}

                  <InvestorType 
                  key={i}
                  option={a.value}
                  label={a.label}
                  text={a.description}
                  value={answer}
                  setValue={(value) => {
                    setError(false)
                    setAnswer(value)
                  }}
                />
              })*/}

              <InvestorType 
                option={data.questions[0].answers[0].value}
                label={data.questions[0].answers[0].label}
                text={data.questions[0].answers[0].description}
                value={answer}
                setValue={(value) => {
                  setError(false)
                  setAnswer(value)
                }}
              />

              <InvestorType 
                option={data.questions[0].answers[1].value}
                label={data.questions[0].answers[1].label}
                text={data.questions[0].answers[1].description}
                value={answer}
                setValue={(value) => {
                  setError(false)
                  setAnswer(value)
                }}
              />

              <InvestorType 
                option={data.questions[0].answers[2].value}
                label={data.questions[0].answers[2].label}
                text={data.questions[0].answers[2].description}
                value={answer}
                setValue={(value) => {
                  setError(false)
                  setAnswer(value)
                }}
              />

              {error ? <ErrorText>Please select one of the options above</ErrorText> : <></>}

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <FirstButton
                  isLoading={loading}
                  className={'investor-type-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="confirm"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={submitHandler}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
      </> : <></>}
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  overflow-y: scroll;
  overflow-y: visible;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;


const ErrorText = styled.p`
  font-family: HKGrotesk-Regular;
  color: ${theme.colors.yellow};
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
  text-align: center;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  white-space: pre-line;
  margin-bottom: 20px;
  letter-spacing: -0.03em;
`;


const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;


export default InvestorTypeModal;

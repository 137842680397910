import { IconProps } from 'common/common.types';
import * as React from 'react';

const Visa = ({ width = 31, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.38462 0C1.06712 0 0 1.074 0 2.4V7.25859H3.60254C4.58619 7.25859 4.96251 8.18672 4.96251 8.18672L5.79853 12.4617C4.80891 9.25292 0.994385 7.69703 0 7.34063V21.6C0 22.926 1.06712 24 2.38462 24H28.6154C29.9329 24 31 22.926 31 21.6V2.4C31 1.074 29.9329 0 28.6154 0H2.38462ZM19.5171 7.04531C20.6688 7.04531 21.8458 7.50937 21.8458 7.50937L21.4429 9.59063C21.4429 9.59063 20.5245 8.98359 19.7197 8.98359C18.4844 8.98359 18.0523 9.38916 18.0523 9.93516C18.0523 11.0044 21.5011 11.1774 21.5011 13.575C21.4999 15.567 19.0278 16.9547 17.2489 16.9547C15.4676 16.9547 14.5476 16.4086 14.5476 16.4086L14.9784 14.4117C14.9784 14.4117 15.9274 14.9602 17.3653 14.9602C18.8008 14.9602 19.0001 14.3534 19.0001 14.093C19.0001 12.563 15.5559 12.971 15.5559 9.99375C15.5559 8.34735 16.9047 7.04531 19.5171 7.04531ZM8.37177 7.23516H11.0312L7.24932 16.6594H4.51074L2.51735 9.02813C2.51735 9.02813 4.61779 10.0987 5.91264 13.0195C5.96867 13.3747 6.09893 13.9453 6.09893 13.9453L8.37177 7.23516ZM12.0116 7.23516H14.5266L13.0246 16.6594H10.5398L12.0116 7.23516ZM25.5135 7.23516H28.1264L30.0499 16.6594H27.7887L27.5209 15.3164H24.412L23.902 16.6594H21.4336L25.5135 7.23516ZM26.452 9.95391L25.1013 13.5141H27.1599L26.452 9.95391Z"
      fill="white"
    />
  </svg>
);

export default Visa;

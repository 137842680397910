import * as React from 'react';

import { IconProps } from 'common/common.types';

const EyeWhiteIcon = ({
  width = 14,
  height = 10,
  fill = 'white',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.875 0C1.875 0 0 5 0 5C0 5 1.875 10 6.875 10C11.875 10 13.75 5 13.75 5C13.75 5 11.875 0 6.875 0ZM6.875 1.25C10.1725 1.25 11.8404 3.91693 12.3804 4.99756C11.8397 6.07068 10.1594 8.75 6.875 8.75C3.5775 8.75 1.90963 6.08307 1.36963 5.00244C1.91088 3.92932 3.59062 1.25 6.875 1.25ZM6.875 2.5C5.49437 2.5 4.375 3.61937 4.375 5C4.375 6.38063 5.49437 7.5 6.875 7.5C8.25563 7.5 9.375 6.38063 9.375 5C9.375 3.61937 8.25563 2.5 6.875 2.5ZM6.875 3.75C7.56563 3.75 8.125 4.30937 8.125 5C8.125 5.69063 7.56563 6.25 6.875 6.25C6.18437 6.25 5.625 5.69063 5.625 5C5.625 4.30937 6.18437 3.75 6.875 3.75Z"
      fill={fill}
    />
  </svg>
);

export default EyeWhiteIcon;

import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';

interface RowCheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const RowCheckbox: React.FC<RowCheckboxProps> = ({
  onChange,
  checked = false,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    if (onChange) onChange(isChecked);
  }, [isChecked]);

  return (
    <>
      <CheckboxLabel>
        <CheckboxInput
          type="checkbox"
          onChange={() => {
            setIsChecked(!isChecked);
          }}
        />
        <CheckboxContent className={`${isChecked && 'active'}`}>
          <Checked></Checked>
        </CheckboxContent>
      </CheckboxLabel>
    </>
  );
};

const CheckboxInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxLabel = styled.label``;

const CheckboxContent = styled.span`
  display: inline-block;
  height: 18px;
  width: 18px;
  background: black;
  border: 1px white solid;
  border-radius: 5px;
  margin-right: 15px;
  padding: 2px;
  transform: translateY(3px);

  &.active div {
    border-color: white;
    background: white;
  }
`;

const Checked = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3px;
`;

export default memo(RowCheckbox);

import React from 'react';
import styled from 'styled-components';
import { BeatLoader } from 'react-spinners';

const Loader = () => {
  return (
    <SpinnerWrapper>
      <BeatLoader color="white" loading={true} />
    </SpinnerWrapper>
  );
};

const SpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default Loader;

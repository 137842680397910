import * as React from 'react';

import { IconProps } from '../common.types';

const PlusButtonNoBorder = ({
  width = 15,
  height = 15,
  color = 'white',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.59992 12.68H7.32792V7.44798H12.5359V5.71998H7.32792V0.487976H5.59992V5.71998H0.36792V7.44798H5.59992V12.68Z"
      fill={color}
    />
  </svg>
);

export default PlusButtonNoBorder;

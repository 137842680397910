import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import ArrowRight from 'common/icons/ArrowRight.icon';
import SetupIncomplete from 'common/icons/SetupIncomplete.icon';
import SetupInProgress from 'common/icons/SetupInProgress.icon';
import SetupComplete from 'common/icons/SetupComplete.icon';

import { AccountSetupStepStates } from './types';
import { useNavigate } from 'react-router-dom';

type AccountLinkProps = {
  text: string;
  status: AccountSetupStepStates;
  link?: string; 
};

const AccountSetupLink: React.FC<AccountLinkProps> = ({ text, status, link}) => {
  const navigate = useNavigate();

  const renderStatusIcon = (s: AccountSetupStepStates) => {
    switch (s) {
      case AccountSetupStepStates.not_complete:
        return <SetupIncomplete />;
      case AccountSetupStepStates.inprogress:
        return <SetupInProgress />;
      case AccountSetupStepStates.complete:
        return <SetupComplete />;
    }
  };

  return (
    <LinkContainer onClick={() => link && navigate(link)}>
      <SetupStatus>{renderStatusIcon(status)}</SetupStatus>
      <LinkLabel text={text} />
      {status !== AccountSetupStepStates.complete && (
        <ArrowButton>
          <ArrowRight width={8} height={12} />
        </ArrowButton>
      )}
    </LinkContainer>
  );
};

const LinkContainer = styled.div`
  height: 40px;
  width: 100%;
  position: relative;
  cursor: pointer;

  padding-top: 25px;

  &:hover .text {
    color: ${theme.colors.yellow};
  }

  &:hover .button {
    background: ${theme.colors.yellow};
    color: ${theme.colors.black};
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  right: 5px;
  top: 27px;
`;

const SetupStatus = styled.div`
  position: absolute;
  left: 0;
`;

const LinkLabel = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  font-weight: 400;

  margin-left: 35px;
`;

export default AccountSetupLink;

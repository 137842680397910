import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import MyCollection from 'modules/account/pages/MyCollection';
import AccountSettings from './pages/AccountSettings';
import MyWallet from './pages/MyWallet';
import Transactions from './pages/Transactions';
import RoyaltyOverview from './pages/RoyaltyOverview';
import SongRoyalty from './pages/Royalty/SongRoyalty';
import SongRoyaltyOverview from './pages/SongRoyaltyOverview';
import RoyaltyHistory from './pages/RoyaltyHistory';

const AccountRouter = () => {
  return (
    <AccountRouterContainer>
      <Switch>
        <Route path="*" element={<Navigate to={'/account/settings'} />} />
        <Route path="settings" element={<AccountSettings />} />
        <Route path="wallet" element={<MyWallet />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="my-collection" element={<MyCollection />} />
        <Route path="royalty" element={<RoyaltyOverview />} />
        <Route path="royalty/history" element={<RoyaltyHistory />} />
        <Route path="royalty/songs" element={<SongRoyaltyOverview />} />
        <Route path="royalty/:song_slug" element={<SongRoyalty />} />
      </Switch>
    </AccountRouterContainer>
  );
};

const AccountRouterContainer = styled.div`
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
`;

export default AccountRouter;

import React, {FC} from 'react';
import styled from 'styled-components';

import Spacer from '../Spacer/Spacer';

interface Props {
  header?: boolean;
}

const DropsPlaceholder: FC<Props> = ({ header }) => {

  return (
    <>
      {header ? <><span
        className={
          'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-6'
        }>
        &nbsp;
      </span>
      <Spacer height={20} />
      </>
      : <></>}
      <DropsContainer>
        <Drop>
          <span
            className={
              'placeholder-wave placeholder fs-18lh-24 col-1'
            }>
            &nbsp;
          </span>
          <SongImg className={'placeholder-wave placeholder '} />
          <DropDetailsContainer>
            <span
              className={
                'placeholder-wave placeholder fs-18lh-24 col-7'
              }>
              &nbsp;
            </span>
            <Spacer height={5} />
            <DropDetails>
              <span
                className={
                  'placeholder-wave placeholder fs-18lh-24 col-2'
                }>
                &nbsp;
              </span>
              <span
                className={
                  'col-1'
                }>
                &nbsp;
              </span>
              <span
                className={
                  'placeholder-wave placeholder fs-18lh-24 col-2'
                }>
                &nbsp;
              </span>
              <span
                className={
                  'col-1'
                }>
                &nbsp;
              </span>
              <span
                className={
                  'placeholder-wave placeholder fs-18lh-24 col-2'
                }>
                &nbsp;
              </span>
            </DropDetails>
          </DropDetailsContainer>
        </Drop>
      </DropsContainer>
    </>
  );
};

const DropsContainer = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    width: 300px;
  }
`;

const Drop = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const DropDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 992px) {
    width: 350px;
  }
`;

const DropDetails = styled.div`
  font-family: 'HKGrotesk-Light';
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SongImg = styled.div`
  height: 40px;
  width: 60px;
  margin: 0 10px;
`;

export default DropsPlaceholder;

import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';

import { md, sm, xlg } from 'utils/constants';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;


  @media (min-width: ${xlg}px) {
    justify-content: center;
  }

  height: 100%;
`;

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: ${md}px) {
    min-width: 800px;
    margin-left: 130px;
  }
`;

export const HeadContainer = styled.div`
  height: auto;
  width: 100%;
  flex-direction: column;
  letter-spacing: -0.03em;
  padding: 31px 18px 0 53px;
  z-index: 1;

  @media (min-width: ${sm}px) {
    display: flex;
    padding: 48px 48px 0 48px;
  }


  @media (min-width: ${xlg}px) {

    padding-top: 0;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: ${md}px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: ${md}px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;

  @media (min-width: ${md}px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const LoadMoreButton = styled(Button)`
  margin-top: 10px;
  margin-left: -5px;
  padding: 0;
  align-self: start;
  z-index: 5;

  @media (min-width: ${md}px) {
    margin-top: 20px;
    flex-direction: row;
  }
`;

export const LoadMoreButtonContent = styled.div<{ paddingLeft: string }>`
  display: flex;
  flex-direction: column-reverse;

  padding-left: ${(props) => props.paddingLeft};

  gap: 10px;

  @media (min-width: ${md}px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 42px;
  line-height: 35px;
  letter-spacing: -0.038em;

  @media (min-width: ${sm}px) {
    font-size: 70px;
    line-height: 56px;
  }

  @media (min-width: ${md}px) {
    line-height: 80px;
    font-size: 100px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  margin-bottom: 34px;

  @media (min-width: ${md}px) {
    display: flex;
    margin-bottom: 60px;
  }
`;

export const CustomSpacer = styled.div<{ height: string }>`
  ${(props) => props.height && `height: ${props.height}px;`};
  width: 100%;

  @media (min-width: ${md}px) {
    height: 100px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 64px;
  margin-bottom: 10px;
  gap: 15px;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    justify-content: start;

    position: initial;
    gap: 0;
    padding-left: 48px;
  }

  @media (min-width: ${md}px) {
    // margin-top: 10px;
    padding-left: 185px;
  }
`;

import React, { FC } from 'react';
import styled from 'styled-components';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

import QR from 'common/icons/QR.icon';
import BottomLine from 'common/components/BottomLine/BottomLine';
import theme from 'theme/theme';

interface ModalProps {
  onUseAuthenticator: () => void;
}

const TwoFaAuthMethod: FC<ModalProps> = ({ onUseAuthenticator }) => {
  return (
    <CustomComponentContainer>
      <Spacer height={20} />

      <RegularText
        fontSize="fz18"
        text="Add an extra layer of security to your account to protect it from unauthorized access."
      />

      <Spacer height={30} />

      <RegularText fontSize="fz18" text="Choose method:" />

      <Spacer height={30} />

      <OptionRow
        className={'use-authenticator-app-button'}
        onClick={() => {
          onUseAuthenticator();
        }}>
        <QR width={30} height={30} />
        <RegularText fontSize="fz18" text="Use an authenticator app" />
      </OptionRow>

      <BottomLine />

      <Spacer height={30} />

      <RegularText
        fontSize="fz18"
        fontColor={theme.colors.gray}
        text="More options coming soon"
      />
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RegularText = styled(Typography)`
  letter-spacing: -0.03em;
  line-height: 24px;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;

  padding-left: 5px;

  margin-bottom: 15px;

  gap: 10px;
  cursor: pointer;
`;

export default TwoFaAuthMethod;

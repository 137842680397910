import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';

import TextContentContainer from 'common/components/TextContentContainer/TextContentContainer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import Banner from '../assets/show-me-who.jpg';
import MobileBanner from '../assets/show-me-who-mobile.jpg';

import { footerHeight, footerMobileHeight, xlg, xs } from 'utils/constants';

import {
  ButtonsContainer,
  ContentButton,
  FirstButton,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  LeftMarginButton,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContent,
  TextLink,
  TextLinkContainer,
} from './styled/ShowMeWho.styled';

const ShowMeWho = () => {
  const navigate = useNavigate();
  const viewport = useViewport();

  const contentContainerHeight = useMemo(
    () =>
      viewport.width < xs ? '100%' : viewport.width >= xlg ? '450px' : '320px',
    [viewport.width],
  );

  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  return (
    <PageContainer
      pageTitle="Show Me Who | SongBits"
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        <Image src={viewport.width < 576 ? MobileBanner : Banner} />
        <ImageGradient banner={viewport.width < 576 ? MobileBanner : Banner} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <StrapLineTypography
              text={viewport.width < 576 ? 'show ' : 'show me '}
              fontSize="fz100"
              fontWeight="bold"
              letterSpacing="-0.05em"
            />

            <div style={{ display: 'flex' }}>
              {viewport.width < 576 && (
                <StrapLineTypography
                  text="me "
                  fontSize="fz100"
                  fontWeight="bold"
                  letterSpacing="-0.05em"
                />
              )}
              <StrapLineTypography
                text="who"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.05em"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.white}
                letterSpacing="0.035em"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentContainer
            height={contentContainerHeight}
            altText="SongBits is open to all musical genres and languages. Solo singers to orchestras.">
            <TextContent
              text="We want our platform to be open to the world's musicians."
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />

            <TextContent
              text="Each artist wants to give fans something unique so they choose from a range of special perks to share with their most devoted fans - some artists even suggest a new type of perk for fans!"
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />

            <>
              <TextLinkContainer>
                Take a look at the <TextLink to="/library/hot-releases">latest releases</TextLink>...
              </TextLinkContainer>
            </>
          </TextContentContainer>
        </HeadingSection>

        <ButtonsContainer>
          <FirstButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="how"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/how');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="why"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/why');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            opacity={0.5}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="who"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/who');
            }}
          />
          <LeftMarginButton
          borderRadius={50}
          borderColor={theme.colors.white}
          label={
            <div style={{ display: 'flex' }}>
              <ContentButton
                text="show me"
                fontSize="fz16"
                fontWeight="bold"
              />
              &nbsp;
              <ContentButton
                text="songs"
                fontSize="fz16"
                fontColor={theme.colors.yellow}
                fontWeight="bold"
              />
            </div>
          }
          onClick={() => {
            navigateToHandler('/library/hot-releases');
          }}
        />
        </ButtonsContainer>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(ShowMeWho);

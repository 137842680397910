import React, { FC } from 'react';
import styled from 'styled-components';

import XIcon from 'common/icons/X.icon';

import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';

interface DropdownWithActionProps {
  data: {
    label: string;
    value: string | number;
  }[];

  currentItem: {
    label: string;
    value: string | number;
  };
  onSelect: (selectedItem: { label: string; value: string | number }) => void;
  onClose: () => void;
}

const DropdownWithAction: FC<DropdownWithActionProps> = ({
  data,
  onSelect,
  currentItem,
  onClose,
}) => {
  return (
    <Wrapper>
      <Dropdown data={data} currentItem={currentItem} onSelect={onSelect} />
      <CloseButton onClick={onClose}>
        <XIcon />
      </CloseButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;

  margin-left: -3px;

  cursor: pointer;

  @media (min-width: 768px) {
    margin-left: 5px;
  }

`;

export default DropdownWithAction;

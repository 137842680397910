import { useLocation } from 'react-router-dom';
import { UUIDv4 } from 'utils/routes-regex';

const customRouteBreadcrumbs = [
  {
    path: '/library/my-collection',
    breadcrumbs: ['My Account', 'My Collection'],
  },
  {
    path: '/account/settings',
    breadcrumbs: ['Home', 'Account Settings'],
  },
  {
    path: '/artist/settings',
    breadcrumbs: ['Home', 'Artist', 'Artist Settings'],
  },
  {
    path: '/artist/accounting',
    breadcrumbs: ['Home', 'Artist', 'Accounting'],
  },
  {
    path: '/artist/accounting/payout-method',
    breadcrumbs: ['Home', 'Artist', 'Accounting', 'Payout Method'],
  },
  {
    path: '/artist/accounting/transactions',
    breadcrumbs: ['Home', 'Artist', 'Accounting', 'Transactions'],
  },
  {
    path: '/legal/privacy-policy',
    breadcrumbs: ['Home', 'Legal'],
  },
  {
    path: '/legal/cookie-policy',
    breadcrumbs: ['Home', 'Legal'],
  },
  {
    path: '/library/hot-releases',
    breadcrumbs: ['library', 'Releases'],
  },
  {
    path: '/artist/dashboard',
    breadcrumbs: ['artist', 'Artist Dashboard'],
  },
];

function useBreadcrumbs(): [string[], any] {
  const location = useLocation();

  const hasCustomBreadcrumbs = customRouteBreadcrumbs.find(
    (r) => r.path === location.pathname,
  );

  const pathBreadcrumbs = location.pathname
    .split('/')
    .filter((breadcrumb) => breadcrumb !== '')
    .filter((breadcrumb) => !UUIDv4.test(breadcrumb)); // Remove any UUID params from breadcrumbs

  switch (pathBreadcrumbs[0]) {
    case 'account':
      pathBreadcrumbs.shift();

      pathBreadcrumbs.unshift('My Account');
      pathBreadcrumbs.unshift('Home');
      break;

    case 'library':
      break;

    default:
      pathBreadcrumbs.unshift('Home');
      break;
  }

  const breadcrumbs = hasCustomBreadcrumbs
    ? hasCustomBreadcrumbs.breadcrumbs
    : pathBreadcrumbs;

  return [breadcrumbs, hasCustomBreadcrumbs ? 0 : 1];
}

export default useBreadcrumbs;

import React from 'react';
import UserLocationsPlaceholder from './UserLocations';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';
import Spacer from '../Spacer/Spacer';

const InsightLocationPlaceholder = () => {
  const viewport = useViewport();
  return (
    <>
      {/* Dropdown */}
      <span
        style={{ borderRadius: '10px', width: '64%' }}
        className={
          'placeholder-wave placeholder fs-18 lh-44 bg-secondary col-8'
        }>
        &nbsp;
      </span>

      <UserLocationsPlaceholder />

      {/* top locations header */}
      <span
        className={
          'placeholder-wave placeholder fs-26 lh-30 bg-secondary col-3'
        }>
        &nbsp;
      </span>
      <Spacer height={25} />
      <span
        className={
          'placeholder-wave placeholder fs-20 lh-20 bg-secondary col-5'
        }>
        &nbsp;
      </span>

      <Spacer height={25} />

      <LocationsTablePlaceholder>
        {[...Array(2)].map((_, index) => (
          <div
            key={`location-placeholder-${index}`}
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'row',
              width: '100%',
            }}>
            <span
              key={`location-placeholder-${index}`}
              style={{ width: '20%' }}
              className={
                'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-2'
              }>
              &nbsp;
            </span>

            <span
              key={`location-placeholder-${index}`}
              style={{
                width: viewport.width < 576 ? '60%' : '31%',
                flexGrow: '1',
                marginRight: '10px',
              }}
              className={
                'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-4'
              }>
              &nbsp;
            </span>
          </div>
        ))}
      </LocationsTablePlaceholder>

      <Spacer height={15} />

      <span
        className={
          'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-3'
        }>
        &nbsp;
      </span>
    </>
  );
};

export const LocationsTablePlaceholder = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 5px;

  @media (min-width: 576px) {
    flex-direction: row;
    gap: initial;
  }
`;

export default InsightLocationPlaceholder;

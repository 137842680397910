import React, { FC, useState } from 'react';
import { useViewport } from 'use-viewport';
import ReactHowler from 'react-howler';
import styled from 'styled-components';

import AppleMusic from 'common/icons/AppleMusic.icon';
import SpotifyGrey from 'common/icons/SpotifyGrey.icon';
import Typography from 'common/components/Typography/Typography';
import PlayIcon from 'common/icons/Play.icon';
import PauseIcon from 'common/icons/Pause.icon';

import { MusicPlatformsTypes } from 'modules/artist/types';
import { ModalProps } from './index.type';

import {
  CloseButton,
  ModalContainer,
  ModalContentContainer,
  Title,
} from './index.styled';

import { IconContainer, PlatformName } from './styled/ListenNowModal.styled';
interface ListenNowProps extends ModalProps {
  musicLinks: MusicPlatformsTypes;
  songURL: string;
}

const ListenNowModal: FC<ListenNowProps> = ({
  onClose,
  musicLinks,
  songURL,
}) => {
  const viewport = useViewport();

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  return (
    <ModalContainer>
      <CloseButton onClick={() => onClose()}>X</CloseButton>
      <Title
        text="listen now"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz36'}
        letterSpacing="-0.03em"
      />

      <Typography text="Choose which platform to listen to the song" />

      <ReactHowler
        src={songURL}
        playing={isPlaying}
        onEnd={() => setIsPlaying(false)}
      />

      <ModalContentContainer
        flexDir="column"
        justifyContent="space-around"
        padding="60px 0 0 0">
        <IconsRow>
          {isPlaying ? (
            <IconContainer onClick={() => setIsPlaying((prev) => !prev)}>
              <PauseIcon width={60} height={60} opacity={1} />
              <PlatformName
                text="Pause Preview"
                letterSpacing="-0.03em"
                fontWeight="light"
              />
            </IconContainer>
          ) : (
            <IconContainer onClick={() => setIsPlaying((prev) => !prev)}>
              <PlayIcon width={60} height={60} opacity={1} />
              <PlatformName
                text="Play Preview"
                letterSpacing="-0.03em"
                fontWeight="light"
              />
            </IconContainer>
          )}
        </IconsRow>
        <IconsRow>
          <IconContainer href={musicLinks.spotify} target="_blank">
            <SpotifyGrey width={60} height={60} opacity={1} />
            <PlatformName
              text="Spotify"
              letterSpacing="-0.03em"
              fontWeight="light"
            />
          </IconContainer>
          <IconContainer href={musicLinks.applemusic} target="_blank">
            <AppleMusic width={60} height={60} opacity={1} />
            <PlatformName
              text="Apple Music"
              letterSpacing="-0.03em"
              fontWeight="light"
            />
          </IconContainer>
        </IconsRow>
      </ModalContentContainer>
    </ModalContainer>
  );
};

const IconsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  height: 93px;

  gap: 100px;

  &:first-child {
    margin-bottom: 30px;
  }
`;

export default ListenNowModal;

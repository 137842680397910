export function getSoldPercent(
  audioLengthMs: number,
  editions: number,
  remainingBits: number,
) {
  const totalLength = audioLengthMs * editions;
  const bitsSold = totalLength - remainingBits;

  const percentage = (bitsSold / totalLength) * 100;

  if (Number.isInteger(percentage)) {
    return percentage;
  } else {
    return percentage.toFixed(1);
  }
}

export function getRemainingPercent(
  audioLengthMs: number,
  editions: number,
  remainingBits: number,
) {
  const totalLength = audioLengthMs * editions;
  const bitsSold = totalLength - remainingBits;

  const percentage = (bitsSold / totalLength) * 100;

  if (Number.isInteger(percentage)) {
    return percentage;
  } else {
    return percentage.toFixed(1);
  }
}
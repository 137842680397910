import * as React from 'react';

import { IconProps } from '../common.types';

const Edit = ({ width = 18, height = 15 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5236 0C16.0971 0 15.6706 0.162865 15.3452 0.488281L13.3334 2.5L17.5001 6.66667L19.5119 4.65495C20.1627 4.00411 20.1627 2.94901 19.5119 2.29818L17.702 0.488281C17.3765 0.162865 16.95 0 16.5236 0ZM11.6668 4.16667L1.88315 13.9502C1.88315 13.9502 2.64796 13.8817 2.93297 14.1667C3.21797 14.4517 2.98336 16.3167 3.33336 16.6667C3.68336 17.0167 5.53662 16.7703 5.80245 17.0361C6.06829 17.302 6.04985 18.1169 6.04985 18.1169L15.8335 8.33333L11.6668 4.16667ZM0.83334 16.6667L0.0472009 18.8932C0.0162963 18.9811 0.000341532 19.0735 0 19.1667C0 19.3877 0.087798 19.5996 0.24408 19.7559C0.400361 19.9122 0.612324 20 0.83334 20C0.926484 19.9997 1.01891 19.9837 1.10678 19.9528C1.1095 19.9517 1.11221 19.9506 1.11492 19.9495L1.13608 19.943C1.13771 19.942 1.13934 19.9409 1.14096 19.9398L3.33336 19.1667L2.08335 17.9167L0.83334 16.6667Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export default Edit;

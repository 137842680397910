import React from 'react';
import styled from 'styled-components';

import Spacer from 'common/components/Spacer/Spacer';
import { sm } from 'utils/constants';

type QuickLinksProps = {
  noLinks?: number;
};

const QuickLinksPlaceholder = ({ noLinks = 3 }: QuickLinksProps) => {
  noLinks;
  return (
    <Container>
      <Spacer height={30} />
      <span
        className={
          'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-6'
        }>
        &nbsp;
      </span>
      <Spacer height={20} />

      {[...Array(noLinks)].map((_, index) => (
        <React.Fragment key={`quick-link-placeholder-${index}`}>
          <div
            style={{ display: 'flex' }}>
            <span className={'fs-18 lh-24 bg-secondary col-2'}>&nbsp;</span>
            <span
              className={
                'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-8'
              }>
              &nbsp;
            </span>
          </div>
          <Spacer height={20} />
        </React.Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: auto;

  @media (min-width: ${sm}px) {
    width: 200px;
  }
`;

export default QuickLinksPlaceholder;

import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { parse, isValid, isFuture, differenceInYears } from 'date-fns';
import { enGB } from 'date-fns/locale';
import TextInput from 'common/components/TextInput/TextInput';
import { useFormik } from 'formik';
import { useViewport } from 'use-viewport';
import * as Yup from 'yup';
import { useStoreActions } from 'store/store';

import restService from 'services/rest.service';

import { sm, xs } from 'utils/constants';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../../modules/library/pages/components/ModalLayout/ModalLayout';

import UserProfile from 'common/icons/UserProfile.icon';
import DateOfBirth from 'common/icons/DateOfBirth.icon';


const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Legal first name is required'),
  lastName: Yup.string().required('Legal last name is required'),
  dateOfBirth: Yup.string()
  .required('Date of Birth is required')
  .test({
      name: 'is-valid-date',
      test(value, ctx) {
        
        if (value) {
          
          if (value.toString().length === 14) {

            const parsedDate = parse(
              value.toString().replaceAll(' ', ''),
              'P',
              new Date(),
              {
                locale: enGB,
              },
            );
            const isValidDate = isValid(parsedDate);   

            if (isValidDate) {
              if (isFuture(parsedDate)) {
                return ctx.createError({
                  message: 'Date can not be in the future',
                });
              }
              if (differenceInYears(new Date(), parsedDate) > 100) {
                return ctx.createError({
                  message: 'You must be 100 years old or younger',
                });
              }

              if (differenceInYears(new Date(), parsedDate) > 18) {
                return true;
              } else {
                return ctx.createError({
                  message: 'You must be 18 years old or older',
                });
              }              
            } else {
              return ctx.createError({
                message: 'a valid date is required',
              });
            }
          } else {}
        }
        return ctx.createError({
            message: 'A valid date is required',
          });
        }
  }),
});

type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: (nextStep?: string) => void;
};

const PersonalInformationModal = ({ show, onClose, onContinue }: ModalProps) => {

  const viewport = useViewport();
  const { setFcaStep } = useStoreActions((state) => state.authentication);

  useEffect(() => {
      setShowModal(show);
  }, [show]);

  const [showModal, setShowModal] = useState<boolean>(false);  
  const [loading, setLoading] = useState<boolean>(false);  

  async function submitHandler() {

    setLoading(true)

    const dobSplit = values.dateOfBirth.replaceAll(' ', '').replaceAll('/', '-').split('-')

    const info = {
      first_name: values.firstName,
      last_name: values.lastName,
      date_of_birth: dobSplit[2] + '-' + dobSplit[1] + '-' + dobSplit[0]
    }

    restService.fcaContactInfo(info).then((res) => {
      res
      setLoading(false)
      setFcaStep(res.next)
      onContinue(res.next)
    }).catch((error: any) => {
      error
      //console.log(error)
      setLoading(false)
    })
    
  }

  const initialValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: submitHandler,
    });


  return (
    <>
      <SBModal
        className={'fca-personal-info'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout onClose={onClose} title={'personal info'} titleBottomMargin='15px'>
            <ContentContainer className={'personal-info-modal'}>
              <NormalText>
                {'In order to continue you need to\nprovide some personal details.'}
              </NormalText>

              <InputContainer>
                <Icon>
                  <UserProfile />
                </Icon>
                <InputWithIcon
                  value={values.firstName}
                  inputName={'firstName'}
                  height={viewport.width < xs ? 65 : 70}
                  type="text"
                  onChange={handleChange('firstName')}
                  placeholder="Legal First name"
                  withBottomLine
                  error={
                    Boolean(errors.firstName && touched.firstName)
                      ? errors.firstName
                      : undefined
                  }
                />
              </InputContainer>

              <InputContainer>
                <Icon>
                  <UserProfile />
                </Icon>
                <InputWithIcon
                  value={values.lastName}
                  inputName={'lastName'}
                  height={viewport.width < xs ? 65 : 70}
                  type="text"
                  onChange={handleChange('lastName')}
                  placeholder="Legal Last name"
                  withBottomLine
                  error={
                    Boolean(errors.lastName && touched.lastName)
                      ? errors.lastName
                      : undefined
                  }
                />
              </InputContainer>

              <InputContainer>
                <Icon>
                  <DateOfBirth />
                </Icon>
                <InputWithIcon
                  value={values.dateOfBirth}
                  inputName={'dateOfBirth'}
                  height={viewport.width < xs ? 65 : 70}
                  type="text"
                  inputMode="numeric"
                  onKeyUp={(inputData) => {

                    let val = inputData.currentTarget.value.toString() 
                    
                    if (inputData.code === 'Backspace') {

                        const valSpace = val.split('');
                        const valSpaceN = valSpace.slice(0, -1);
                        val = valSpaceN.join('');
                        
                      setFieldValue('dateOfBirth', val);
                      
                    } else if (inputData.code.startsWith('Digit')) {
                      let val =
                        inputData.currentTarget.value.toString() +
                        inputData.key;

                      const valSpace = val.split('');

                      if (
                        valSpace[valSpace.length - 1] == '/' ||
                        valSpace[valSpace.length] == '/'
                      ) {
                        //console.log('here');
                        const valSpaceN = valSpace.slice(0, -1);
                        val = valSpaceN.join('');
                        setFieldValue('dateOfBirth', val);
                        return;
                      } else if (val.length == 2 || val.length == 7) {
                        //console.log('here2');
                        setFieldValue('dateOfBirth', val + ' / ');
                        return;
                      } else if (val.length <= 14) {
                        setFieldValue('dateOfBirth', val);
                        return;
                      }
                    }

                  }}
                  onChange={() => {}}
                  placeholder="Date of Birth (DD/MM/YYYY)"
                  error={
                    Boolean(errors.dateOfBirth && touched.dateOfBirth)
                      ? errors.dateOfBirth
                      : undefined
                  }
                />
              </InputContainer>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}>
                <FirstButton
                  isLoading={loading}
                  className={'personal-info-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={handleSubmit}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  white-space: pre-line;
  letter-spacing: -0.03em;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default PersonalInformationModal;

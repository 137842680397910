import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import Email from 'common/icons/Email.icon';
import theme from 'theme/theme';
import { escToClose, getUserIdFromJWT } from 'utils/functions';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { CHANGE_EMAIL } from 'modules/auth/graphql/Mutations.graphql';
import {
  CHANGE_EMAIL_MUTATION,
  CHANGE_EMAIL_VARIABLES,
} from 'modules/auth/types';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
});

interface ModalProps {
  email: string;
  onChange: () => void;
}

const ChangeEmailModal: FC<ModalProps> = ({ email, onChange }) => {
  const id = getUserIdFromJWT();
  const viewport = useViewport();

  useEffect(() => {
    escToClose(onChange);
  }, []);

  
  const initialValues = {
    email: email,
  };

  async function submitHandler() {

    try {
      const response = await changeEmail();
      //console.log(response)
      if (!response.errors) {
        if (response.data) {
          errors.email = 'Email sent!'
          onChange();
        }
      }
    } catch (e: any) {
      e
      //console.log(e)
      if (e.message.includes('New email should differ from the current')) {
        errors.email = 'Email is the same as current email.'
      } else {
        errors.email = 'Sorry there was a problem. Please try again.'
      }
    }
  }


  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });


  const [changeEmail] = useMutation<
    CHANGE_EMAIL_MUTATION,
    CHANGE_EMAIL_VARIABLES
  >(CHANGE_EMAIL, {
    variables: {
      userId: id,
      email: values.email,
    },
  });

  function handleClose() {
    onChange();
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="change email"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <RegularText
        fontSize={viewport.width >= 576 ? 'fz18' : 'fz14'}
        text={"Insert your new email address below. A confirmation\nlink will be sent to your existing email for security"}
      />
      <Spacer height={viewport.width >= 576 ? 0 : 10} />
      <InputContainer>
        <Icon>
          <Email />
        </Icon>
        <InputWithIcon
          height={viewport.width >= 576 ? 70 : 50}
          type="email"
          withBottomLine
          value={values.email}
          placeholder="enter new email address"
          onChange={handleChange('email')}
          inputName='email'
          small={true}
          error={
            Boolean(errors.email && touched.email) ? errors.email : undefined
          }
        />
      </InputContainer>
      <Spacer height={viewport.width >= 576 ? 85 : 50} />
      <SubmitButton
        className='change-email-submit-button'
        width={145}
        borderRadius={50}
        borderColor={theme.colors.white}
        label={
          <SubmitButtonContent>
            <BoldText
              text="submit"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.white}
            />
            <BoldText
              text="update"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          </SubmitButtonContent>
        }
        onClick={handleSubmit}
      />
      {/* <Spacer height={40} /> */}
    </CustomComponentContainer>
  );
};

const SubmitButton = styled(Button)`
  padding: 8px 33px;
  margin-left: 60px;
  width: auto;

  @media (min-width: 576px) {
    margin-left: 0;
  }
`;

const SubmitButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: 28px;
  left: 10px;

`;

const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
    margin-top: -7px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const RegularText = styled(Typography)`
  letter-spacing: -0.03em;
  line-height: 24px;
  white-space: pre;


`;

export default ChangeEmailModal;

import * as React from 'react';

import { IconProps } from '../common.types';

const ExplicitLyrics = ({ width = 124, height = 21, color = "white" }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 125 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.86877 15.75H13.6938V13.65H9.15836V11.55H13.475V9.43537H9.15836V7.68537H13.6938V5.58537H6.86877V15.75Z"
      fill={color}
    />
    <path
      d="M14.3908 15.75H17.1325L19.2762 12.352L21.4491 15.75H24.1908L20.8658 10.7041L24.1908 5.58537H21.4637L19.2908 9.11453L17.1179 5.58537H14.3762L17.7012 10.7041L14.3908 15.75Z"
      fill={color}
    />
    <path
      d="M25.1264 15.75H27.4014V11.6666H28.6993C31.0181 11.6666 32.2285 10.602 32.2285 8.57495C32.2285 6.56245 31.0473 5.58537 28.6993 5.58537H25.1264V15.75ZM27.4014 9.56662V7.68537H28.466C29.4723 7.68537 29.9389 7.8312 29.9389 8.60412C29.9389 9.42078 29.3993 9.56662 28.466 9.56662H27.4014Z"
      fill={color}
    />
    <path
      d="M33.4862 15.75H39.9466V13.65H35.7758V5.58537H33.4862V15.75Z"
      fill={color}
    />
    <path d="M40.906 15.75H43.1956V5.58537H40.906V15.75Z" fill={color} />
    <path
      d="M49.6535 16.0125C51.914 16.0125 53.6202 14.8895 54.3348 12.8625L52.191 12.0458C51.841 13.227 50.9223 13.825 49.6535 13.825C47.889 13.825 46.8244 12.6729 46.8244 10.6458C46.8244 8.6187 47.8598 7.4812 49.6098 7.4812C50.8494 7.4812 51.7973 8.06453 52.191 9.21662L54.3056 8.38537C53.5619 6.46037 51.8848 5.2937 49.6098 5.2937C46.5619 5.2937 44.5348 7.42287 44.5348 10.675C44.5348 13.8979 46.5765 16.0125 49.6535 16.0125Z"
      fill={color}
    />
    <path d="M55.4324 15.75H57.722V5.58537H55.4324V15.75Z" fill={color} />
    <path
      d="M61.657 15.75H63.9757V7.68537H66.9362V5.58537H58.682V7.68537H61.657V15.75Z"
      fill={color}
    />
    <path
      d="M71.4399 15.75H77.9003V13.65H73.7295V5.58537H71.4399V15.75Z"
      fill={color}
    />
    <path
      d="M80.3142 15.75H82.6038V11.7395L86.41 5.58537H83.7121L81.4079 9.53745L79.1621 5.58537H76.4642L80.3142 11.7395V15.75Z"
      fill={color}
    />
    <path
      d="M95.2129 15.75L92.4858 11.4333C93.6233 10.9812 94.2504 10.0333 94.2504 8.58953C94.2504 6.7812 93.2879 5.58537 90.7212 5.58537H87.1483V15.75H89.4233V11.7104H89.6421H90.2108L92.5587 15.75H95.2129ZM89.4233 9.61037V7.68537H90.4879C91.2025 7.68537 91.9608 7.68537 91.9608 8.6187C91.9608 9.56662 91.2171 9.61037 90.4879 9.61037H89.4233Z"
      fill={color}
    />
    <path d="M96.1062 15.75H98.3958V5.58537H96.1062V15.75Z" fill={color} />
    <path
      d="M104.854 16.0125C107.114 16.0125 108.82 14.8895 109.535 12.8625L107.391 12.0458C107.041 13.227 106.122 13.825 104.854 13.825C103.089 13.825 102.025 12.6729 102.025 10.6458C102.025 8.6187 103.06 7.4812 104.81 7.4812C106.05 7.4812 106.997 8.06453 107.391 9.21662L109.506 8.38537C108.762 6.46037 107.085 5.2937 104.81 5.2937C101.762 5.2937 99.735 7.42287 99.735 10.675C99.735 13.8979 101.777 16.0125 104.854 16.0125Z"
      fill={color}
    />
    <path
      d="M113.724 15.9687C115.795 15.9687 117.399 14.6125 117.399 12.6C117.399 11.1562 116.699 10.427 114.512 9.5812C113.053 9.01245 112.66 8.66245 112.66 8.16662C112.66 7.68537 113.097 7.34995 113.622 7.34995C114.206 7.34995 114.701 7.72912 115.008 8.47287L116.903 7.29162C116.247 5.97912 115.11 5.32287 113.695 5.32287C111.697 5.32287 110.37 6.5187 110.37 8.21037C110.37 9.77078 111.158 10.5729 113.331 11.4479C114.658 11.9875 115.124 12.3375 115.124 12.8041C115.124 13.577 114.512 13.9416 113.768 13.9416C112.747 13.9416 112.018 13.3875 111.916 12.1916L109.641 12.7604C109.947 14.7 111.61 15.9687 113.724 15.9687Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.5 1.75H1.75V19.25H122.5V1.75ZM0 0V21H124.25V0H0Z"
      fill={color}
    />
  </svg>
);

export default ExplicitLyrics;

import React, { FC, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import BottomLine from 'common/components/BottomLine/BottomLine';
import { useFormik } from 'formik';
import { BeatLoader } from 'react-spinners';
import {
  HostedFields,
  HostedFieldSmall,
  HostedFieldLabel,
  HostedFieldError
 } from './styled/AddCardModal.styled';
 import {
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { StripeElementsStyles, StripeCVVResponse } from "utils/stripe";
//import { CVVEncryption } from 'utils/encryption';


import { useAppSelector } from 'hooks/reduxToolkit';

interface ModalProps {
  last4?: string;
  type?: string;
  onEncryptedCvv: (encryptedCvv: StripeCVVResponse) => void;
  onClose: () => void;
  purchaseExpired?: () => void;
  stripe: any;
  elements: any;
}

const CVVModal: FC<ModalProps> = ({
  onEncryptedCvv,
  onClose,
  last4,
  type,
  purchaseExpired,
  stripe,
  elements
}) => {
  const { expiryTime } = useAppSelector((state) => state.modalSlice);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      if (purchaseExpired) {
        purchaseExpired();
      }
    }
  }, []);

  const initialValues = useMemo(
    () => ({
      cvv: '',
    }),
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [cardFormCvvLoading, setCardFormCvvLoading] = useState<boolean>(true);
  const [cardFormCvvError, setCardFormCvvError] = useState<string | null>(null);
  const [cardFormCvvComplete, setCardFormCvvComplete] = useState<boolean>(false);
  const {
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  function loading_screen() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: 'inherit',
          marginTop: '30px',
        }}>
        <BeatLoader color="white" loading={true} />
      </div>
    );
  }

  async function submitHandler() {

    if (!cardFormCvvComplete) {
      if (cardFormCvvError === null) {
        setCardFormCvvError("Your card\'s cvv is required.");
      }
      return;
    }
    setLoading(true);
    setStripeLoading(true);
    const cardCVVElement = elements.getElement('cardCvc');
    const stripeCVVTokenData = await stripe.createToken('cvc_update', cardCVVElement);
    onEncryptedCvv(stripeCVVTokenData);
    setLoading(false);
  }

  const renderCardCvc = (name: string, label: string, errName: string) => {
    const error = cardFormCvvError;
    return (
      <>
        <HostedFieldSmall>
          <HostedFields className={`${name}-wrapper`}>
            <HostedFieldLabel htmlFor={name}>CardCVV</HostedFieldLabel>
            <CardCvcElement
                id={name}
                className="card-cvv-field"
                onBlur={() => logEvent(name, 'blur')}
                onChange={handlePayment_InputChange(name, label, errName)}
                onFocus={() => logEvent(name, 'focus')}
                onReady={() => logEvent(name, 'ready')}
                options={Object.assign({}, StripeElementsStyles, {placeholder: label})}
            />
          </HostedFields>
        </HostedFieldSmall>
        {error && <HostedFieldError className="card-cvv-field-error">{error}</HostedFieldError>}
        <div style={{width: 'calc(40% + 19px)', marginLeft: '-4px'}}>
          <BottomLine />
        </div>
      </>
    );
  }

  const handlePayment_InputChange = (name: string, label: string, errName: string) => (elementData: any) => {
    setCardFormCvvComplete(elementData.complete);
    if (!elementData.complete && !elementData.error) {
      setCardFormCvvError(`Your ${errName} is required.`);
    } else if (!elementData.complete && elementData.error) {
      setCardFormCvvError(elementData.error.message);
    } else if (elementData.complete) {
      setCardFormCvvError(null);
    }
  }

  const logEvent = (name: string, type: string) => {
    console.log("EVENT", name, type);
    if (type === "ready") {
      setCardFormCvvLoading(false);
    }
  }

  return (
    <CustomComponentContainer>
      <CloseButton
        onClick={() => {
          onClose();
        }}>
        X
      </CloseButton>

      <Spacer height={10} />
      <CVVHeadingText
        text={`Please enter your CVV number of your card ending ${
          last4 ? last4 : ''
        }`}></CVVHeadingText>
      <Spacer height={10} />
      <div style={{ display: cardFormCvvLoading ? "none" : "block", width: "100%" }}>
        {renderCardCvc('cardCvc', 'Enter CVV', 'card\'s cvv')}
        <Spacer height={25} />
        <WhiteButton
          label={type === 'addfunds' ? 'Submit' : 'Complete Purchase'}
          onClick={() => {
            stripeLoading === false && handleSubmit()
          }}
          height={45}
          borderRadius={50}
          fontSize={theme.fontSizes.fz16}
          isLoading={loading}
          borderColor={
            type === 'addfunds' ? theme.colors.white : theme.colors.yellow
          }
          bgColor={type === 'addfunds' ? theme.colors.white : theme.colors.yellow}
          labelColor={theme.colors.black}
        />
      </div>
      {cardFormCvvLoading && loading_screen()}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  width: 99%;

  @media (min-with: 768px) {
    margin: initial;
  }
`;

const CVVHeadingText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.03em;
`;

export default CVVModal;

import * as React from 'react';

import { IconProps } from '../common.types';

const SocialShare = ({ width = 25, height = 25 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.8333 0C43.4022 0 41.0706 0.965771 39.3515 2.68485C37.6324 4.40394 36.6667 6.73552 36.6667 9.16667C36.6708 9.81101 36.7428 10.4531 36.8815 11.0824L16.0685 21.4889C15.2101 20.5006 14.15 19.7076 12.9595 19.1633C11.769 18.619 10.4757 18.336 9.16667 18.3333C6.73552 18.3333 4.40394 19.2991 2.68485 21.0182C0.965771 22.7373 0 25.0688 0 27.5C0 29.9312 0.965771 32.2627 2.68485 33.9818C4.40394 35.7009 6.73552 36.6667 9.16667 36.6667C10.4761 36.6652 11.77 36.3832 12.9613 35.8397C14.1526 35.2961 15.2137 34.5037 16.073 33.5156L36.8726 43.9176C36.7368 44.5472 36.6678 45.1893 36.6667 45.8333C36.6667 48.2645 37.6324 50.5961 39.3515 52.3151C41.0706 54.0342 43.4022 55 45.8333 55C48.2645 55 50.5961 54.0342 52.3151 52.3151C54.0342 50.5961 55 48.2645 55 45.8333C55 43.4022 54.0342 41.0706 52.3151 39.3515C50.5961 37.6324 48.2645 36.6667 45.8333 36.6667C44.5239 36.6681 43.23 36.9501 42.0387 37.4937C40.8474 38.0372 39.7863 38.8297 38.927 39.8177L18.1274 29.4157C18.2632 28.7861 18.3322 28.144 18.3333 27.5C18.3289 26.8572 18.2569 26.2166 18.1185 25.5888L38.9315 15.1823C39.7903 16.1698 40.8506 16.9619 42.0411 17.5054C43.2316 18.0489 44.5246 18.3312 45.8333 18.3333C48.2645 18.3333 50.5961 17.3676 52.3151 15.6485C54.0342 13.9294 55 11.5978 55 9.16667C55 6.73552 54.0342 4.40394 52.3151 2.68485C50.5961 0.965771 48.2645 0 45.8333 0Z"
      fill="white"
    />
  </svg>
);

export default SocialShare;

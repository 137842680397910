import * as React from 'react';

import { IconProps } from '../common.types';

const Facebook = ({ width = 15, height = 15, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.8182 0H2.18182C0.976364 0 0 0.976364 0 2.18182V21.8182C0 23.0236 0.976364 24 2.18182 24H13.0909V14.1818H9.81818V10.9091H13.0909V9.15164C13.0909 5.82436 14.712 4.36364 17.4775 4.36364C18.8018 4.36364 19.5022 4.46182 19.8338 4.50655V7.63636H17.9476C16.7738 7.63636 16.3636 8.256 16.3636 9.51055V10.9091H19.8044L19.3375 14.1818H16.3636V24H21.8182C23.0236 24 24 23.0236 24 21.8182V2.18182C24 0.976364 23.0225 0 21.8182 0Z"
      fill="white"
      fillOpacity={opacity}
    />
  </svg>
);

export default Facebook;

export function checkStripePayError(
  response: Record<string, any>
) {
  let error_string = '';

  if (response.message) {
    error_string = response.message;
  }
  error_string = error_string.replace("You can provide payment_method_data or a new PaymentMethod to attempt to fulfill this PaymentIntent again.", "");
  error_string = error_string.replace(/PaymentMethod/g, "card");
  return error_string;
}
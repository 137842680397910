import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

import Typography from 'common/components/Typography/Typography';

import theme from 'theme/theme';
import PlayIcon from 'common/icons/Play.icon';
import { sm } from 'utils/constants';
import { useViewport } from 'use-viewport';

const EmptyCollection = () => {
  const viewport = useViewport();
  const navigate = useNavigate();

  return (
    <Container>
      <ContentContainer>
        <EmptyPlayer>
          <PlayIcon height={30} width={30} opacity={0.5} />
        </EmptyPlayer>
        <TextContainer>
          <Typography
            text={'purchases will'}
            fontWeight="light"
            fontColor={theme.colors.gray}
            fontSize={viewport.width > sm ? 'fz24' : 'fz18'}
          />
          <Typography
            text={'appear here'}
            fontWeight="light"
            fontColor={theme.colors.gray}
            fontSize={viewport.width > sm ? 'fz24' : 'fz18'}
          />
        </TextContainer>
      </ContentContainer>
      <Button onClick={() => navigate('/library/hot-releases')}>
        <Typography
          text={'browse latest releases'}
          fontWeight="bold"
          fontSize="fz16"
        />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 30px 0;
  gap: 10px;
`;

const Button = styled.button`
  width: 250px;
  height: 45px;

  background: transparent;

  border: 3px solid ${theme.colors.yellow};
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;

  margin-bottom: 48px;
`;

const EmptyPlayer = styled.div`
  width: 100px;
  height: 100px;

  border: 2px dashed ${theme.colors.gray};
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${sm}px) {
    width: 150px;
    height: 150px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    align-items: center;
  }
`;

export default EmptyCollection;

import React from 'react';

import { IconProps } from '../common.types';

const LogoSmallBeta = ({ width = 46, height = 40 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.536 22.0059C23.1442 22.0059 23.6372 21.5128 23.6372 20.9047L23.6372 1.1019C23.6372 0.493737 23.1442 0.00072427 22.536 0.000724281C21.9279 0.000724292 21.4349 0.493737 21.4349 1.1019L21.4349 20.9047C21.4349 21.5128 21.9279 22.0059 22.536 22.0059Z"
      fill="#FFD600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5004 22.0049C27.1085 22.0049 27.6016 21.5119 27.6016 20.9037L27.6016 7.68962C27.6016 7.08145 27.1085 6.58844 26.5004 6.58844C25.8922 6.58844 25.3992 7.08145 25.3992 7.68962L25.3992 20.9037C25.3992 21.5119 25.8922 22.0049 26.5004 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5719 22.0049C17.9637 22.0049 17.4707 21.5119 17.4707 20.9037L17.4707 1.10092C17.4707 0.492761 17.9637 -0.000252293 18.5719 -0.000252282C19.18 -0.000252271 19.6731 0.492761 19.6731 1.10092L19.6731 20.9037C19.6731 21.5119 19.18 22.0049 18.5719 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.9047 22.0049C31.5128 22.0049 32.0059 21.5119 32.0059 20.9037L32.0059 5.54232C32.0059 4.93416 31.5128 4.44115 30.9047 4.44115C30.2965 4.44115 29.8035 4.93416 29.8035 5.54232L29.8035 20.9037C29.8035 21.5119 30.2965 22.0049 30.9047 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1671 22.0049C13.5589 22.0049 13.0659 21.5119 13.0659 20.9037L13.0659 12.0943C13.0659 11.4862 13.5589 10.9931 14.1671 10.9931C14.7753 10.9931 15.2683 11.4862 15.2683 12.0943L15.2683 20.9037C15.2683 21.5119 14.7753 22.0049 14.1671 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.1996 22.0049C35.8078 22.0049 36.3008 21.5119 36.3008 20.9037L36.3008 13.1955C36.3008 12.5873 35.8078 12.0943 35.1996 12.0943C34.5914 12.0943 34.0984 12.5873 34.0984 13.1955L34.0984 20.9037C34.0984 21.5119 34.5914 22.0049 35.1996 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87266 22.0049C9.2645 22.0049 8.77148 21.5119 8.77148 20.9037L8.77149 7.76303C8.77149 7.15487 9.2645 6.66185 9.87266 6.66185C10.4808 6.66185 10.9738 7.15487 10.9738 7.76303L10.9738 20.9037C10.9738 21.5119 10.4808 22.0049 9.87266 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6044 22.0049C40.2126 22.0049 40.7056 21.5119 40.7056 20.9037L40.7056 9.89196C40.7056 9.2838 40.2126 8.79079 39.6044 8.79079C38.9962 8.79079 38.5032 9.2838 38.5032 9.89196L38.5032 20.9037C38.5032 21.5119 38.9962 22.0049 39.6044 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46787 22.0049C4.85971 22.0049 4.3667 21.5119 4.3667 20.9037L4.3667 18.7014C4.3667 18.0932 4.85971 17.6002 5.46787 17.6002C6.07604 17.6002 6.56905 18.0932 6.56905 18.7014L6.56905 20.9037C6.56905 21.5119 6.07604 22.0049 5.46787 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8988 22.0049C44.507 22.0049 45 21.5119 45 20.9037L45 16.499C45 15.8908 44.507 15.3978 43.8988 15.3978C43.2907 15.3978 42.7977 15.8908 42.7977 16.499L42.7977 20.9037C42.7977 21.5119 43.2907 22.0049 43.8988 22.0049Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.17344 22.0049C0.565278 22.0049 0.0722657 21.5119 0.0722657 20.9037L0.0722666 13.1955C0.0722666 12.5873 0.565279 12.0943 1.17344 12.0943C1.7816 12.0943 2.27462 12.5873 2.27462 13.1955L2.27461 20.9037C2.27461 21.5119 1.7816 22.0049 1.17344 22.0049Z"
      fill="white"
    />
    <rect
      x="0.5"
      y="25.2568"
      width="43.9918"
      height="14.0145"
      rx="7.00724"
      stroke="#FFD600"
    />
    <path
      d="M11.8346 36.083H14.8046C16.2246 36.083 17.1046 35.283 17.1046 33.993C17.1046 33.183 16.7746 32.613 16.0446 32.293C16.4746 31.993 16.7246 31.493 16.7246 30.883C16.7246 29.783 15.8646 29.113 14.4346 29.113H11.8346V36.083ZM13.4046 31.723V30.553H14.4346C14.8746 30.553 15.1546 30.713 15.1546 31.103C15.1546 31.533 14.8746 31.723 14.4346 31.723H13.4046ZM13.4046 34.643V33.193H14.6746C14.9146 33.193 15.5346 33.193 15.5346 33.923C15.5346 34.363 15.3046 34.643 14.6746 34.643H13.4046ZM17.726 36.083H22.406V34.643H19.296V33.203H22.256V31.753H19.296V30.553H22.406V29.113H17.726V36.083ZM24.6239 36.083H26.2139V30.553H28.2439V29.113H22.5839V30.553H24.6239V36.083ZM32.3065 36.083H34.0165L31.1265 29.113H29.9765L27.0765 36.083H28.7865L29.2365 34.903H31.8565L32.3065 36.083ZM29.7865 33.463L30.5565 31.453L31.3165 33.463H29.7865Z"
      fill="white"
    />
  </svg>
);

export default LogoSmallBeta;

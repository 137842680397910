import React from 'react';
import styled from 'styled-components';

import { SongDetails, SongInfo } from './ArtistBenefits';
import BenefitItemPlaceholder from './components/BenefitItem/BenefitItemPlaceholder';

const ArtistBenefitsPlaceholder = () => {
  const ArtistDetailsPlaceHolder = (
    <TextPlaceholder
      className={'placeholder-wave placeholder bg-secondary'}
      style={{
        width: 100,
      }}>
      &nbsp;
    </TextPlaceholder>
  );

  return (
    <div>
      <FirstColumn style={{ width: '100%' }}>
        <SongInfo>
          <Image className="placeholder-wave placeholder fs-18 lh-24 bg-secondary col-5" />
          <SongDetails>
            {ArtistDetailsPlaceHolder}
            {ArtistDetailsPlaceHolder}
          </SongDetails>
        </SongInfo>

        {[...Array(4)].map((_, index) => (
            <BenefitItemPlaceholder key={index} />
        ))}
      </FirstColumn>
    </div>
  );
};

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;

  @media (min-width: 768px) {
    margin-left: 30px;
  }
`;

const TextPlaceholder = styled.span`
  font-size: 12px;
  line-height: 12px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 19px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

const Image = styled.span`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 140px;
    height: 140px;
  }

  @media (min-width: 992px) {
    width: 160px;
    height: 160px;
  }
`;

export default ArtistBenefitsPlaceholder;

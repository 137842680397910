import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import { escToClose } from 'utils/functions';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

import theme from 'theme/theme';
import MetaMaskOutline from 'common/icons/MetaMaskOutline.icon';
import { ExportModalType, NFTOptionsModalType } from 'modules/account/types';
import EtherscanOutlinedIcon from 'common/icons/MyCollection/EtherscanOutlined.icon';

interface MyCollectionModalProps {
  onChange: () => void;
  onExport: (type: ExportModalType) => void;
  onViewDetails: (type: ExportModalType) => void;
  nft_link: string | null;
  metadata_link: string | undefined;
  type: NFTOptionsModalType;
  purchaseId: string;
  fileName: string;
  exportable: boolean;
  nftTokenId: string | number | null;
  nftImageTokenId: string | number | null;
  nft_image_exportable: boolean;
  nft_image_available: boolean;
  image_nft_link: string | undefined | null;
  image_metadata_link: string | undefined | null;
}

const NFTOptionsModal: FC<MyCollectionModalProps> = ({
  onChange,
  onExport,
  onViewDetails,
  nft_link,
  metadata_link,
  type,
  exportable,
  nftTokenId,
  nftImageTokenId,
  nft_image_exportable,
  nft_image_available,
  image_nft_link,
  image_metadata_link,
}) => {
  nft_image_available;

  const viewport = useViewport();

  const iconSize = viewport.width >= 576 ? 55 : 55;

  useEffect(escToClose(handleClose), []);

  function handleClose() {
    onChange();
  }

  const modalContent = () => {
    if (type === 'export') {
      return (
        <ButtonContainer>
          {metadata_link && !nft_link ? (
            <IconButton
              className="view-on-blockchain-button"
              label={
                <>
                  <EtherscanOutlinedIcon width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="view on the blockchain" fontSize="fz18" />
                </>
              }
              onClick={() => {
                window.open(metadata_link, '_blank');
              }}
            />
          ) : (
            <></>
          )}
          {metadata_link && nft_link ? (
            <IconButton
              className="view-on-blockchain-button"
              label={
                <>
                  <EtherscanOutlinedIcon width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="view on the blockchain" fontSize="fz18" />
                </>
              }
              onClick={() => {
                window.open(nft_link, '_blank');
              }}
            />
          ) : (
            <></>
          )}
          {exportable && !nftTokenId ? (
            <IconButton
              className="export-to-metamask-button"
              flexbasis={true}
              label={
                <>
                  <MetaMaskOutline width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="export to metamask" fontSize="fz16" />
                </>
              }
              onClick={() => {
                onExport('royalty');
              }}
            />
          ) : !exportable && nftTokenId ? (
            <IconButton
              className="view-export-details-button"
              flexbasis={true}
              label={
                <>
                  <MetaMaskOutline width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="view export details" fontSize="fz16" />
                </>
              }
              onClick={() => {
                onViewDetails('royalty');
              }}
            />
          ) : (
            <IconButton
              className="export-to-metamask-button"
              flexbasis={true}
              disabled={true}
              label={
                <>
                  <MetaMaskOutline width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="export to metamask" fontSize="fz16" />
                </>
              }
              onClick={() => {}}
            />
          )}
        </ButtonContainer>
      );
    } else {
      return (
        <ButtonContainer>
          {image_metadata_link && !image_nft_link ? (
            <IconButton
              className="view-on-blockchain-button"
              label={
                <>
                  <EtherscanOutlinedIcon width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="view on the blockchain" fontSize="fz16" />
                </>
              }
              onClick={() => {
                window.open(image_metadata_link, '_blank');
              }}
            />
          ) : (
            <></>
          )}
          {image_metadata_link && image_nft_link ? (
            <IconButton
              className="view-on-blockchain-button"
              label={
                <>
                  <EtherscanOutlinedIcon width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="view on the blockchain" fontSize="fz16" />
                </>
              }
              onClick={() => {
                window.open(image_nft_link, '_blank');
              }}
            />
          ) : (
            <></>
          )}
          {nft_image_exportable && !nftImageTokenId ? (
            <IconButton
              className="export-to-metamask-button"
              flexbasis={true}
              label={
                <>
                  <MetaMaskOutline width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="export to metamask" fontSize="fz16" />
                </>
              }
              onClick={() => {
                onExport('image');
              }}
            />
          ) : !nft_image_exportable && nftImageTokenId ? (
            <IconButton
              className="view-export-details-button"
              flexbasis={true}
              label={
                <>
                  <MetaMaskOutline width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="view export details" fontSize="fz16" />
                </>
              }
              onClick={() => {
                onViewDetails('image');
              }}
            />
          ) : (
            <IconButton
              className="export-to-metamask-button"
              flexbasis={true}
              disabled={true}
              label={
                <>
                  <MetaMaskOutline width={iconSize} height={iconSize} />{' '}
                  <IconLabel text="export to metamask" fontSize="fz16" />
                </>
              }
              onClick={() => {}}
            />
          )}
        </ButtonContainer>
      );
    }
  };

  return (
    <CustomComponentContainer id="nft-options-modal" className='nft-options-modal-content'>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="blockchain options"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />

      <Spacer height={20} />
      <Typography
        text="Choose what you would like to do"
        fontWeight="light"
        fontColor={theme.colors.white}
      />
      <Spacer height={50} />

      {modalContent()}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: 576px) {
    letter-spacing: -3px;
  }
`;

const IconLabel = styled(Typography)`
  font-size: 18px;

  @media (min-width: 576px) {
    font-size: 18px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
`;

const IconButton = styled(Button)<{ flexbasis?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 15px;
  font-size: 16px;
  padding: 0;

  :hover {
    background: transparent;
  }
`;

export default NFTOptionsModal;

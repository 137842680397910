import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import { md } from 'utils/constants';

export const WidthSpacer = styled.div<{ width: number; hideMobile?: boolean }>`
  display: ${(props) => (props.hideMobile ? 'none' : 'inline')};
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};

  @media (min-width: 576px) {
    display: inline;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  margin-left: 70px;
  justify-content: left;

  @media (min-width: 576px) {
    justify-content: center;
    margin-left: 0;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  padding-top: 40px;

  @media (min-width: 576px) {
    padding-top: 120px;
  }
`;

export const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 20px;

  margin-top: 28px;

  @media (min-width: ${md}px) {
    flex-direction: row;
    justify-content: center;

    padding: 0;
    margin-top: 0;
  }
`;

export const Details = styled.div`
  width: 100%;
`;

export const Header = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 38px;
  letter-spacing: -0.03em;

  @media (min-width: 576px) {
    font-size: 48px;
  }
`;

export const TransactionDetails = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
`;

export const YellowLine = styled.div`
  height: 1px;
  background: 1px linear-gradient(0.25turn, #ffd600, #ffd600, transparent);
  column-span: 3;
`;

import { action, thunk } from 'easy-peasy';

import { SSOModel } from './ssoModel.types';

import passkeyService from 'services/passkey.service';

export const sso: SSOModel = {
  prompted: false,
  userId: null,
  nonce: null,
  validated: false,
  setNonce: action((state, payload) => {
    state.nonce = payload.nonce;
    state.userId = payload.userId;
  }),
  setPrompted: action((state, payload) => {
    state.prompted = payload;
  }),
  setValidated: action((state, payload) => {
    state.validated = payload;
  }),
  setAuth: action((state, payload) => {
    state.auth = payload;
  }),
  createPasskey: thunk(async (actions) => {
    return await passkeyService.createPasskey().then(() => {
      actions.setValidated(true);
      return true;
    }).catch(error => {
      error
      actions.setValidated(false);
      //console.log("error", error);
      return false;
    });
  }),
  auth: null,
  verifyPasskey: thunk(async (actions) => {
    const verification = await passkeyService.verifyPasskey().catch(error => {
      error
      //console.log("error", error)
      actions.setValidated(false);
      return null;
    });
    if (verification) {
      //console.log("VALID VERIFICATION", verification);
      actions.setValidated(true);
      actions.setAuth(verification);
    }
  })
}

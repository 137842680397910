import * as React from 'react';

import { IconProps } from '../common.types';

const MinusCircleIcon = ({
  width = 25,
  height = 25,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="7.5" fill="white" />
    <path
      d="M7.06485 7.69445H7.69485H11.1449V7.06445H7.69485H7.06485H3.59985V7.69445H7.06485Z"
      fill="black"
    />
  </svg>
);

export default MinusCircleIcon;

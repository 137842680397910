import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import { lg, md, sm, xlg, xs } from 'utils/constants';
import theme from 'theme/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
`;

export const CustomSpacer = styled.div<{ height: string }>`
  ${(props) => props.height && `height: ${props.height}px;`};
  width: 100%;

  @media (min-width: ${xs}px) {
    height: 30px;
  }

  @media (min-width: ${md}px) {
    height: 29px;
  }

  @media (min-width: ${lg}px) {
    height: 60px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  @media (min-width: ${xs}px) {
    visibility: visible;
    height: 100%;
    width: auto;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  right: 0;
  width: 100%;
  height: 92%;
  margin-left: -1px;
  position: absolute;
  background: none;
  

  @media (min-width: ${xs}px) {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0.1));
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 90%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  width: 100%;
  max-height: fit-content;
  padding: 48px 36px 0;

  position: relative;

  flex: 1;
  z-index: 1;

  @media (min-width: ${sm}px) {
    padding: 48px 200px 0 48px;
  }

  @media (min-width: ${md}px) {
    padding-top: 95px;
    padding-left: 70px;
    width: 900px;
  }

  @media (min-width: ${xlg}px) {
    width: 1300px;
    padding-top: 0;
    padding-left: 70px;
    justify-content: center;
  }
`;

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: 0.02em;

  @media (min-width: ${sm}px) {
    min-width: 700px;
  }

  @media (min-width: ${md}px) {
    margin-left: 130px;
  }

  @media (min-width: ${xlg}px) {
    width: 1300px;
    padding-top: 0;
    padding-left: 70px;
  }
`;

export const HeaderTitle = styled.div`
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23.45px;
  color: white;

  position: relative;
  bottom: 20px;

  @media (min-width: ${sm}px) {
    bottom: 0px;
  }

  @media (min-width: ${md}px) {
    bottom: 44px;
  }
`;

export const HeaderTitleMain = styled.span`
  font-family: 'HKGrotesk-Black';
  font-weight: 900;
`;

export const HeaderTitleExtra = styled.span`
  font-family: 'HKGrotesk-Light';
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 52px;
  line-height: 55px;

  @media (min-width: ${sm}px) {
    font-size: 70px;
    line-height: 56px;
    letter-spacing: -0.038em;
  }

  @media (min-width: ${md}px) {
    line-height: 80px;
    font-size: 100px;
  letter-spacing: -0.038em;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${md}px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  font-family: 'HKGrotesk-Light';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  @media (min-width: ${md}px) {
    width: 668px;
    font-size: 24px;
  }
`;

export const TextContent = styled.p<{ color?: string }>`
  font-family: 'HKGrotesk-Regular';
  line-height: 22px;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : `${theme.colors.white}`)};

  @media (min-width: ${md}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const TextContentHeader = styled.div`
  font-size: 18px;

  @media (min-width: ${md}px) {
    font-size: 24px;
  }
`;

export const TextContentMain = styled.div`
  font-size: 14px;

  @media (min-width: ${md}px) {
    font-size: 18px;
  }
`;

export const DesktopSpacer = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

export const SpacerContainer = styled.div`
  display: none;
  //Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    display: block;
  }
`;

export const SpacerContainerMobile = styled.div`
  //Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;
  gap: 15px;

  @media (min-width: ${sm}px) {
    flex-direction: row;
    justify-content: start;

    position: initial;
    gap: 20px;
  }

  @media (min-width: ${md}px) {
    margin-top: 10px;
  }
`;

export const FirstButton = styled(Button)`
  padding: 0 20px;
`;

export const FirstTitilePart = styled.div`
  @media (min-width: 1200px) {
    white-space: nowrap;
  }
`;

export const CreateButton = styled.div`
  position: relative;
  right: 6px;
`;

export const ContactButton = styled(Button)<{
  border?: string;
  borderColor?: string;
}>`
  font-family: 'HKGrotesk-Black';

  width: 172.23px;
  min-width: 172.23px;

  background: #000;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : `${theme.colors.yellow}`};

  margin-bottom: 15px;
  padding: 0 35px;

  @media (min-width: ${sm}px) {
    margin-left: 0;
    margin-bottom: 0;
  }

  @media (min-width: ${xlg}px) {
    width: 220px;
    min-width: 220px;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';

  @media (min-width: ${xlg}px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

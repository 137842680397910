import * as React from 'react';

import { IconProps } from '../common.types';

const DateOfBirth = ({ width = 16, height = 19 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 19"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0 17.2188C0 18.2021 0.767857 19 1.71429 19H14.2857C15.2321 19 16 18.2021 16 17.2188V7.125H0V17.2188ZM11.4286 9.94531C11.4286 9.70039 11.6214 9.5 11.8571 9.5H13.2857C13.5214 9.5 13.7143 9.70039 13.7143 9.94531V11.4297C13.7143 11.6746 13.5214 11.875 13.2857 11.875H11.8571C11.6214 11.875 11.4286 11.6746 11.4286 11.4297V9.94531ZM11.4286 14.6953C11.4286 14.4504 11.6214 14.25 11.8571 14.25H13.2857C13.5214 14.25 13.7143 14.4504 13.7143 14.6953V16.1797C13.7143 16.4246 13.5214 16.625 13.2857 16.625H11.8571C11.6214 16.625 11.4286 16.4246 11.4286 16.1797V14.6953ZM6.85714 9.94531C6.85714 9.70039 7.05 9.5 7.28571 9.5H8.71429C8.95 9.5 9.14286 9.70039 9.14286 9.94531V11.4297C9.14286 11.6746 8.95 11.875 8.71429 11.875H7.28571C7.05 11.875 6.85714 11.6746 6.85714 11.4297V9.94531ZM6.85714 14.6953C6.85714 14.4504 7.05 14.25 7.28571 14.25H8.71429C8.95 14.25 9.14286 14.4504 9.14286 14.6953V16.1797C9.14286 16.4246 8.95 16.625 8.71429 16.625H7.28571C7.05 16.625 6.85714 16.4246 6.85714 16.1797V14.6953ZM2.28571 9.94531C2.28571 9.70039 2.47857 9.5 2.71429 9.5H4.14286C4.37857 9.5 4.57143 9.70039 4.57143 9.94531V11.4297C4.57143 11.6746 4.37857 11.875 4.14286 11.875H2.71429C2.47857 11.875 2.28571 11.6746 2.28571 11.4297V9.94531ZM2.28571 14.6953C2.28571 14.4504 2.47857 14.25 2.71429 14.25H4.14286C4.37857 14.25 4.57143 14.4504 4.57143 14.6953V16.1797C4.57143 16.4246 4.37857 16.625 4.14286 16.625H2.71429C2.47857 16.625 2.28571 16.4246 2.28571 16.1797V14.6953ZM14.2857 2.375H12.5714V0.59375C12.5714 0.267188 12.3143 0 12 0H10.8571C10.5429 0 10.2857 0.267188 10.2857 0.59375V2.375H5.71429V0.59375C5.71429 0.267188 5.45714 0 5.14286 0H4C3.68571 0 3.42857 0.267188 3.42857 0.59375V2.375H1.71429C0.767857 2.375 0 3.17285 0 4.15625V5.9375H16V4.15625C16 3.17285 15.2321 2.375 14.2857 2.375Z" fill="white"/>
  </svg>
);

export default DateOfBirth;

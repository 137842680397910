import React from 'react';
import DropCardPlaceholder from './DropCard';

const ArtistReleasesPlaceholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Dropdown */}
      <span
        style={{ borderRadius: '10px', marginBottom: '20px', width: '62%' }}
        className={
          'placeholder-wave placeholder fs-18 lh-44 bg-secondary col-8'
        }>
        &nbsp;
      </span>

      <div style={{ marginTop: '5px' }}>
        {[...Array(5)].map((_, index) => (
          <DropCardPlaceholder key={`drop-card-placeholder-${index}`} />
        ))}
      </div>
    </div>
  );
};

export default ArtistReleasesPlaceholder;

import React from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

const BottomLine = () => {
  return <Line />;
};

const Line = styled.div`
  height: 1px;
  width: 100%;

  opacity: 0.5;
  background-color: ${theme.colors.yellow};
  background: linear-gradient(
    to right,
    ${theme.colors.yellow},
    ${theme.colors.yellow},
    ${theme.colors.transparent}
  );
`;

export default BottomLine;

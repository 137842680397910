import * as React from 'react';

import { IconProps } from '../common.types';

const ArrowRight: React.FC<IconProps> = ({ height = 18, width = 10 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.90299 8.79998L0.551522 15.4539C0.0828926 15.9448 0.0828925 16.7408 0.551522 17.2318C1.02015 17.7227 1.77995 17.7227 2.24858 17.2318L9.44858 9.68891C9.91721 9.19797 9.91721 8.40199 9.44858 7.91104L2.24858 0.368184C1.77995 -0.122761 1.02015 -0.122762 0.551524 0.368184C0.0828939 0.859128 0.0828938 1.65511 0.551524 2.14605L6.90299 8.79998Z"
      fill="white"
    />
  </svg>
);

export default ArrowRight;

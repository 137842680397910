import * as React from 'react';

import { IconProps } from '../common.types';

const ArrowToDown = ({
  width = 20,
  height = 10,
  stroke = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    scale={0.5}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m17.533 1-7.211 7.543c-.583.61-1.528.61-2.11 0L1 1"
      stroke={stroke}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowToDown;

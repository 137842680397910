import Button from 'common/components/Button/Button';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import styled from 'styled-components';
import theme from 'theme/theme';

export const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 170px;
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  mobileHide?: boolean;
  thin?: boolean;
  fontWeight?: string;
}>`
  font-family: ${(props) =>
    (props.thin && 'HKGrotesk-Light') ||
    (props.fontWeight === 'bold' && 'HKGrotesk-Black') ||
    'HKGrotesk-Regular'};
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 16)}px;
  display: ${(props) => (props.mobileHide ? 'none' : 'block')};

  @media (min-width: 992px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : 24)}px;
    display: block;
  }
`;

export const SelectedInfo = styled.div`
  display: contents;
  width: 100%;
  justify-content: center;
  gap: 10px;
  line-height: 35px;
  display: flex-inline;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const SelectedInfoRow = styled.div`
  display: flex;
  gap: 10px;
  line-height: 14px;
  @media (min-width: 768px) {
    line-height: 35px;
  }
`;

export const PlayerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StrokesContainer = styled.div<{ highlight: boolean }>`
  height: 126px;
  width: 95%;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-wrap: no-wrap;
  padding: 8px 0px;
  overflow: inherit;
  padding-left: 20px;
  padding-right: 17px;
  justify-content: center;

  z-index: ${(props) => (props.highlight ? 10 : 'initial')};

  @media (min-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 768px) {
    height: 187px;
    padding-left: 35px;
    padding-right: 17px;
  }

  @media (min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 1440px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1920px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SlidersContainer = styled.div`
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 100%;
  right: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BitsSelectorContainer = styled.div`
  position: relative;
  width: 142px;
  height: 30px;
  background: #000000;
  border: 3px solid ${theme.colors.yellow};
  border-radius: 50px;
  transition: all 0.5s;
  z-index: 2;

  @media (min-width: 768px) {
    width: 205px;
    height: 50px;
  }
`;

export const BitSelector = styled.div`
  margin-top: 0;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-items: center;
  left: 50px;

  z-index: 11;

  @media (min-width: 768px) {
    margin-top: 8px;
    left: 140px;
  }
`;

export const BitsSelectorContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BitsSelectorInput = styled(TextInput)`
  background: #191500;
  width: 80px;
  height: 19px;
  border-radius: 50px 0px 0px 50px;
  color: ${theme.colors.white};
  padding-left: 10px;
  & > input {
    font-size: 18px;
  }
  & > input::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }

  @media (max-width: 567px) {
    height: 100%;
    padding-left: 5px;
  }

  @media (min-width: 768px) {
    width: 120px;
    height: 30px;
    & > input {
      height: 34px;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 2px;
    }
  }
`;

export const QuestionMarkButton = styled.div`
  margin-left: -2px;
  margin-top: 5px;

  @media (min-width: 768px) {
    margin-left: 0;
    margin-top: 3px;
  }

  @media (min-width: 992px) {
    margin-left: -7px;
    margin-top: 5px;
  }
`;

export const StrokeSpacer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1920px) {
    padding-left: 20px;
  }
`;

export const YellowPlayerStroke = styled.div`
  width: 7px;
  background-color: ${theme.colors.yellow};
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 10px;
  }
`;

export const WhitePlayerStroke = styled.div`
  width: 7px;
  background-color: ${theme.colors.white};
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 10px;
  }
`;

export const InfoButton = styled(Button)`
  transform: scale(0.7);
  padding: 0;

  @media (min-width: 768px) {
    transform: none;
  }
`;

export const CustomSpacer = styled.div`
  @media (max-height: 844px) {
    height: 10px;
  }

  @media (min-height: 845px) {
    height: 30px;
  }
`;

export const PreDeterminedButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const BuyButton = styled(FirstButton)`
  transform: translateX(-3px);
`;

export const PresetButton = styled(FirstButton)`
  width: 100px;
  height: 35px;
  min-width: unset;
  padding: unset;

  border-width: 1px;

  @media (min-width: 768px) {
    width: 170px;
    height: 45px;
    border-width: 3px;
  }
`;

export const PresetText = styled(Typography)`
  text-align: center;
  line-height: 22px;
  padding: 0 20px;
`;

import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import TextInput from 'common/components/TextInput/TextInput';
import { useFormik } from 'formik';
import { useViewport } from 'use-viewport';
import * as Yup from 'yup';

import { useStoreActions } from 'store/store';
import restService from 'services/rest.service';

import { sm, xs } from 'utils/constants';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../../modules/library/pages/components/ModalLayout/ModalLayout';

import AddressIcon from 'common/icons/AddressIcon.icon';

const VALIDATION_SCHEMA = Yup.object().shape({
  addressSearch: Yup.string().required('Address is required'),
  address_1: Yup.string().required('Address Line 1 is required'),
  address_2: Yup.string(),
  town: Yup.string().required('City is required'),
  county: Yup.string(),
  postcode: Yup.string().required('Postcode is required'),
});

type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: (nextStep?: string) => void;
};

const PersonalInformationModal = ({
  show,
  onClose,
  onContinue,
}: ModalProps) => {
  const viewport = useViewport();
  const { setFcaStep } = useStoreActions((state) => state.authentication);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [addressSelected, setAddressSelected] = useState<boolean>(false);

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: !addressSelected ? '30px' : '10px'
  };

  function getAddressComponentValue(
    place: google.maps.places.PlaceResult,
    types: string[],
  ) {
    const values: any = {};
    for (const type of types) {
      const value = place.address_components?.find((component) =>
        component.types.includes(type),
      )?.long_name;

      if (type) values[type] = value;
    }

    return values;
  }

  function handleAddressSearchSelected(place: google.maps.places.PlaceResult) {
    if (place.address_components) {
      // Set address
      const cityTypes = [
        'postal_town',
        'locality',
        'administrative_area_level_3',
      ];
      const types = [
        'street_number',
        'route',
        'postal_code',
        'administrative_area_level_2',
        ...cityTypes,
      ];

      const addressComponents = getAddressComponentValue(place, types);

      let address = addressComponents['street_number'];
      address = address
        ? address + ' ' + addressComponents['route']
        : addressComponents['route'];

      setFieldValue('address_1', address);

      //Set city according to address
      let city = '';
      for (const cityType of cityTypes) {
        if (typeof addressComponents[cityType] !== 'undefined') {
          city = addressComponents[cityType];
          break;
        }
      }
      if (city) setFieldValue('town', city);
      if (addressComponents['administrative_area_level_2'])
        setFieldValue(
          'county',
          addressComponents['administrative_area_level_2'],
        );

      //Set postal code accrding to address
      const postalCode = addressComponents['postal_code'];
      if (postalCode) setFieldValue('postcode', postalCode);

      //setFieldValue('addressSearch', '');
      setAddressSelected(true);
    }
  }

  async function submitHandler() {
    //console.log(errors)
    setLoading(true);

    const info: any = {
      address_1: values.address_1,
      town: values.town,
      county: values.county,
      postcode: values.postcode,
    };

    if (values.address_2) {
      info.address_2 = values.address_2;
    }

    restService
      .fcaContactInfoAddress(info)
      .then((res) => {
        res;
        //console.log(res)
        setLoading(false);
        setFcaStep(res.next)
        onContinue(res.next)
      })
      .catch((error: any) => {
        error
        //console.log(error);
        setLoading(false);
      });
  }

  const initialValues = useMemo(
    () => ({
      addressSearch: '',
      address_1: '',
      address_2: '',
      town: '',
      county: '',
      postcode: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: submitHandler,
    });

  return (
    <>
      <SBModal
        className={'fca-personal-info-address'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout onClose={onClose} title={'personal info'} titleBottomMargin='15px'>
            <ContentContainer className={'personal-info-address-modal'}>
              <NormalText>
                {'In order to continue please\n provide your address.'}
              </NormalText>

              {!addressSelected ? (
                <>
                  <InputContainer>
                    <Icon>
                      <AddressIcon />
                    </Icon>
                    <InputWithIcon
                      value={values.addressSearch}
                      inputName={'addressSearch'}
                      height={viewport.width < xs ? 65 : 70}
                      type="text"
                      onChange={handleChange('addressSearch')}
                      placeholder="Start typing your address"
                      withBottomLine
                      error={
                        Boolean(errors.addressSearch && touched.addressSearch)
                          ? errors.addressSearch
                          : undefined
                      }
                      autoComplete={'false'}
                      googleAutoComplete={{
                        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                        onPlaceSelected: (place) =>
                          handleAddressSearchSelected(place),
                        options: {
                          componentRestrictions: {
                            country: 'UK',
                          },
                          fields: ['address_components', 'geometry', 'name'],
                          types: ['address'],
                        },
                      }}
                    />
                  </InputContainer>
                </>
              ) : (
                <>
                  <InputContainer>
                    <TextInput
                      value={values.address_1}
                      inputName={'address_1'}
                      height={viewport.width < xs ? 65 : 70}
                      type="text"
                      onChange={handleChange('address_1')}
                      placeholder="Address line 1"
                      withBottomLine
                      error={
                        Boolean(errors.address_1 && touched.address_1)
                          ? errors.address_1
                          : undefined
                      }
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      value={values.address_2}
                      inputName={'address_2'}
                      height={viewport.width < xs ? 65 : 70}
                      type="text"
                      onChange={handleChange('address_2')}
                      placeholder="Address line 2"
                      withBottomLine
                      error={
                        Boolean(errors.address_2 && touched.address_2)
                          ? errors.address_2
                          : undefined
                      }
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      value={values.town}
                      inputName={'town'}
                      height={viewport.width < xs ? 65 : 70}
                      type="text"
                      onChange={handleChange('town')}
                      placeholder="Town/City"
                      withBottomLine
                      error={
                        Boolean(errors.town && touched.town)
                          ? errors.town
                          : undefined
                      }
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      value={values.county}
                      inputName={'county'}
                      height={viewport.width < xs ? 65 : 70}
                      type="text"
                      onChange={handleChange('county')}
                      placeholder="County"
                      withBottomLine
                      error={
                        Boolean(errors.county && touched.county)
                          ? errors.county
                          : undefined
                      }
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      value={values.postcode}
                      inputName={'postcode'}
                      height={viewport.width < xs ? 65 : 70}
                      type="text"
                      onChange={handleChange('postcode')}
                      placeholder="Postcode"
                      
                      error={
                        Boolean(errors.postcode && touched.postcode)
                          ? errors.postcode
                          : undefined
                      }
                    />
                  </InputContainer>
                </>
              )}
              <Row
                style={buttonStyle}>
                <FirstButton
                  isLoading={loading}
                  className={'personal-info-address-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={handleSubmit}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: ${xs}px) {
    height: 500px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  white-space: pre-line;
  letter-spacing: -0.03em;
`;

const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 20px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 25)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 36px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default PersonalInformationModal;

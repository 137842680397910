import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';

interface PaymentModalProps {
  isOpen: boolean;
  header: JSX.Element;
  failedText?: string;
  onClose: () => void;
  onRetryCard: () => void;
  onChangePaymentMethod?: () => void;
}

const CardPaymentFailed: FC<PaymentModalProps> = ({
  isOpen,
  header,
  failedText,
  onClose,
  onRetryCard,
  onChangePaymentMethod,
}) => {
  const viewport = useViewport();

  if (onChangePaymentMethod) {}

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag("event", "purchase_card_failed", {});
    }
  }, [isOpen])

  return (
    <SBModal
      className={'card-payment-failed'}
      isOpen={isOpen}
      width="434px"
      mobileWidth='100%'
      height="656px"
      top="10%"
      mobileTop='2%'
      withProceedingText={true}
      withFeeText={true}
      feeBottomPos="60px"
      withSettings={false}
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text="card payment"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          <Spacer height={30} />
          <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
            <FailedContainer>
              <TextContent
                className="card-payment-failed-text"
                fontSize="fz24"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                text="Payment Failed!"
              />
              {failedText ? (
                <TextContent
                  className="card-payment-fail-text"
                  fontSize="fz16"
                  fontWeight="regular"
                  fontColor={theme.colors.white}
                  text={failedText}
                />
              ) : (
                <></>
              )}
            </FailedContainer>
            {/*<FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.white}
              labelColor={theme.colors.yellow}
              label={
                <ButtonText
                  text="Change payment method"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
              }
              onClick={() => {
                onChangePaymentMethod();
              }}
            />*/}
            <FirstButton
              height={45}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text="Try Again"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={() => {
                // Show Card Payment modal with the details from this card
                // already pre-populated
                onRetryCard();
              }}
            />
          </PaymentRow>
          <Spacer height={30} />
          {/* <RemainingBits /> */}
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 60px;
  width: 300px;
  border-width: 1px;

  @media (min-width: 576px) {
    width: 350px;
    margin-left: -5px;
  }
`;

const PaymentRow = styled.div`
  gap: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

/*
const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;
*/

const FailedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;

export default memo(CardPaymentFailed);

import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';

interface SelectedUsersProps {
  selectedUsers: string[];
  style?: {
    leftOffset?: string;
  };
}

const SelectedUsers: FC<SelectedUsersProps> = ({ style, selectedUsers }) => {
  const navigate = useNavigate();
  const viewport = useViewport();

  return createPortal(
    <Wrapper leftOffset={style?.leftOffset}>
      {viewport.width < 768 && (
        <Typography
          text={`${selectedUsers.length} Users Selected`}
          fontSize="fz14"
        />
      )}
      <ButtonContainer>
        <Button
          label={
            <Typography
              text={'send message'}
              fontWeight="bold"
              fontSize="fz16"
            />
          }
          onClick={() => {
            navigate('/artist/insights/users/send-message', {
              state: { selected_users: selectedUsers },
            });
          }}
          borderColor={theme.colors.white}
          borderRadius={50}
          height={45}
          width={viewport.width >= 576 ? 200 : 143}
        />
        {viewport.width > 768 && (
          <Typography
            text={`${selectedUsers.length} Users Selected`}
            fontSize="fz14"
          />
        )}
      </ButtonContainer>
    </Wrapper>,
    document.body,
  );
};

const Wrapper = styled.div<{ leftOffset?: string }>`
  width: 100%;
  height: 112px;

  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  border-top: 4px ${theme.colors.white} solid;
  padding-top: 15px;
  background-color: black;

  @media (min-width: 768px) {
    border-top: 1px ${theme.colors.yellow} solid;
  }
`;

const ButtonContainer = styled.div<{ leftOffset?: string }>`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  ${(props) =>
    props.leftOffset
      ? `padding-left: ${props.leftOffset}`
      : 'justify-content: center'};
`;

export default SelectedUsers;

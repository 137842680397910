import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as R from 'ramda';

import geoService from 'services/geo.service';

import { getSoldPercent } from 'utils/calculation';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';
import DropsPlaceholder from 'common/components/Placeholder/Drops';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';

import Button from 'common/components/Button/Button';
import Caption from 'common/components/Caption/Caption';
import ImagePicker from 'common/components/ImagePicker/ImagePicker';
import Media from 'common/components/Media/Media';
//import Select from 'common/components/Select/Select';
import Spacer from 'common/components/Spacer/Spacer';
import Textarea from 'common/components/Textarea/Textarea';
import TextInput from 'common/components/TextInput/TextInput';
import { notify } from 'common/components/Toaster/Toaster';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';
//import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
//import Radio from 'modules/artist/pages/components/Radio/Radio';
//import Toggle from 'modules/artist/pages/components/Toggle/Toggle';
import theme from 'theme/theme';
import Drops from './components/Drops/Drops';
//import Tip from 'common/components/Tip/Tip';

import PageLayout from 'common/components/PageLayout/PageLayout';

//import arrow from '../assets/arrow-down-right.png';
//import whitePlus from '../assets/+_white.png';

import { getUserIdFromJWT } from 'utils/functions';
import { useStoreActions, useStoreState } from 'store/store';

//import { SELECTORS_DATA } from '../assets/MOCK_DATA'; // DROP_SONGS_DATA
import { UPDATE_ARTIST_PROFILE_BY_USER_ID } from '../graphql/Mutations.graphql';
import {
  GET_ARTIST_PROFILE_BY_USER_ID,
  GET_DROPS,
} from '../graphql/Queries.graphql';
import { UPDATE_USER_BY_ID } from 'modules/account/graphql/Mutations.graphql';

import { ArtistSettingsTypes } from './types'; // RightManagementTypes
import {
  GET_ARTIST_PROFILE_BY_USER_ID_QUERY,
  GET_ARTIST_PROFILE_BY_USER_ID_VARIABLES,
  UPDATE_ARTIST_PROFILE_BY_USER_ID_MUTATION,
  UPDATE_ARTIST_PROFILE_BY_USER_ID_VARIABLES,
} from '../types';
import {
  DropsDataType,
  UPDATE_USER_BY_ID_MUTATION,
  UPDATE_USER_BY_ID_VARIABLES,
} from 'modules/account/types';

import {
  //BottomLine,
  ButtonsRow,
  Details,
  ProfileImageContainer,
  //ToggleContainer,
  //WalletAddressDescription,
  WidthSpacer,
  //AddButton,
  //ArrowImg,
  ColumnContainer,
  //DropsContent,
  //SongContainer,
} from './styled/ArtistSettings.styled';
import { formatDropDate } from 'utils/drops';
import { footerHeight, footerMobileHeight } from 'utils/constants';

const VALIDATION_SCHEMA = Yup.object().shape({
  artistName: Yup.string().required('Artist Name is required'),
  intro: Yup.string().required('Introduction is required'),
});

const ArtistSettings = () => {
  const navigate = useNavigate();
  const viewport = useViewport();

  //const { walletAddress } = useStoreState((state) => state.wallet);

  const [toastId, setToastId] = useState<string>();
  const [usersCountry, setUsersCountry] = useState<string>('');
  const [artistSettingsData, setArtistSettingsData] =
    useState<ArtistSettingsTypes>();
  const [drops, setDrops] = useState<DropsDataType[] | []>([]);

  const userId = getUserIdFromJWT();

  // const navigateToArtistProfile = (slug: string) => navigate(`/${slug}`);

  const { data: artistData, loading: artistLoading } = useQuery<
    GET_ARTIST_PROFILE_BY_USER_ID_QUERY,
    GET_ARTIST_PROFILE_BY_USER_ID_VARIABLES
  >(GET_ARTIST_PROFILE_BY_USER_ID, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const { data: dropsData } = useQuery(GET_DROPS, {
    fetchPolicy: 'network-only',
    variables: { userid: userId },
  });

  const isEditingPreviewStore = useStoreState(
    (state) => state.preview.isEditingPreview,
  );
  const previewDataStore = useStoreState((state) => state.preview.previewData);

  const setPreviewUser = useStoreActions((state) => state.preview.setUser);
  const setPreviewData = useStoreActions(
    (state) => state.preview.setPreviewData,
  );
  const setReloadAfterPreviewStore = useStoreActions(
    (state) => state.preview.setReloadAfterPreview,
  );

  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );

  useEffect(() => {
    if (artistData) {
      const data = artistData.artistProfileByUserId;
      const artistProfileData: ArtistSettingsTypes | any = {
        artistName: data.artistName,
        intro: data.intro,
        isArtist: true,
        socialMedia: JSON.parse(data.socialMediaPlatforms),
        musicLinks: JSON.parse(data.musicPlatforms),
        musicPlatforms: JSON.parse(data.musicPlatforms),
        socialMediaPlatforms: JSON.parse(data.socialMediaPlatforms),
      };
      setArtistSettingsData(artistProfileData);
    }
  }, [artistData]);

  useEffect(() => {
    setMainFooterHidden(true);

    geoService.getUserIP().then((userIP) => {
      if (userIP)
        geoService.getUserCountry(userIP).then((countryResponse) => {
          if (countryResponse) {
            setUsersCountry(countryResponse.country);
          }
        });
    });

    return () => {
      setMainFooterHidden(false);
    };
  }, []);

  useEffect(() => {
    if (!dropsData) return;

    const data = dropsData.artistProfileByUserId;
    const dropsTemp: DropsDataType[] = [];

    data.currentDrops.nodes.forEach((t: any) => {
      let date = '';

      if (t.releaseAt) {
        date = formatDropDate(t.releaseAt);
      }

      let sold_percent = '';

      if (t) {
        sold_percent =
          getSoldPercent(
            t.audioLengthMs,
            t.editionsCount,
            t.soldBitsInverted,
          ).toString() + '%';
      } else {
        sold_percent = '0%';
      }

      dropsTemp.push({
        name: t.title + ' - ' + data.artistName,
        date: date,
        editions: t.editionsCount,
        sold: sold_percent,
        cover: t.fileByCoverId.url,
        status: 'current',
        link: '/' + data.slug + '/' + t.slug,
      });
    });

    data.completedDrops.nodes.forEach((t: any) => {
      let date = '';

      if (t.releaseAt) {
        date = formatDropDate(t.releaseAt);
      }

      let sold_percent = '';

      if (t) {
        sold_percent =
          getSoldPercent(
            t.audioLengthMs,
            t.editionsCount,
            t.soldBitsInverted,
          ).toString() + '%';
      } else {
        sold_percent = '0%';
      }

      dropsTemp.push({
        name: t.title + ' - ' + data.artistName,
        date: date,
        editions: t.editionsCount,
        sold: sold_percent,
        cover: t.fileByCoverId.url,
        status: 'completed',
        link: '/' + data.slug + '/' + t.slug,
      });
    });

    setDrops(dropsTemp);
  }, [dropsData]);

  const initialValues: ArtistSettingsTypes = isEditingPreviewStore
    ? previewDataStore
    : useMemo<ArtistSettingsTypes>(
        () => ({
          artistName: R.defaultTo(
            '',
            artistData?.artistProfileByUserId.artistName,
          ),
          location: R.defaultTo(
            '',
            artistData?.artistProfileByUserId.userByUserId.location,
          ),
          intro: R.defaultTo('', artistData?.artistProfileByUserId.intro),
          avatar: R.defaultTo(
            '',
            artistData?.artistProfileByUserId.userByUserId.fileByAvatarId?.url,
          ),
          avatarId: R.defaultTo(
            '',
            artistData?.artistProfileByUserId.userByUserId.fileByAvatarId?.id,
          ),
          isApplyForVerify: false,
          payoutType: 'USDC Stablecoin',
          publicWalletAddress: '',
          socialMedia: {},
          musicPlatforms: {
            spotify: '',
            applemusic: '',
            youtube: '',
            soundcloud: '',
          },
          rightManagement: {
            rightsSociety: '',
            publisher: '',
            coPublisher: '',
          },
        }),
        [artistData],
      );

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [updateArtistProfileByUserId, { loading: updateArtistLoading }] =
    useMutation<
      UPDATE_ARTIST_PROFILE_BY_USER_ID_MUTATION,
      UPDATE_ARTIST_PROFILE_BY_USER_ID_VARIABLES
    >(UPDATE_ARTIST_PROFILE_BY_USER_ID, {
      variables: {
        artistName: values.artistName,
        intro: values.intro,
        location: values.location,
        avatarId: values.avatarId || null,
        userId,
      },
    });

  const handleImage = (avatar: string, avatarId: string) => {
    setValues({ ...values, avatar, avatarId });
  };

  async function submitHandler() {
    toast.dismiss(toastId);

    updateArtistProfileByUserId()
      .then((response) => {
        if (!response.errors) {
          if (response.data) {
            setReloadAfterPreviewStore(true);
            navigate(`/${dropsData.artistProfileByUserId.slug}`, {});
          }
        }
      })
      .catch(() => {
        const toastId = notify({
          autoClose: false,
          customComponent: (
            <ToasterModal
              title="Something gone wrong!"
              content="Please try again later"
            />
          ),
        });
        setToastId(toastId as string);
      });
  }

  const quickLinks = useMemo(
    () => [
      {
        title: 'Artist Dashboard',
        link: '/artist/dashboard',
      },
      {
        title: 'Account Settings',
        link: '/account/settings',
      },
    ],
    [],
  );

  const [updateUserProfileLocation] = useMutation<
    UPDATE_USER_BY_ID_MUTATION,
    UPDATE_USER_BY_ID_VARIABLES
  >(UPDATE_USER_BY_ID, {
    variables: {
      id: userId,
      location: values.location,
    },
  });

  async function submitHandlerLocation() {
    try {
      await updateUserProfileLocation();
    } catch (error: any) {
      console.error(error);
    }
  }

  function handleLocationSelect(place: google.maps.places.PlaceResult) {
    const city = place.address_components?.find((component) =>
      component.types.includes('locality'),
    )?.long_name;

    const county = place.address_components?.find((component) =>
      component.types.includes('administrative_area_level_2'),
    )?.long_name;

    const country = place.address_components?.find((component) =>
      component.types.includes('country'),
    )?.short_name;

    let location_string = '';

    if (city) {
      location_string += city;
    }

    if (county) {
      if (location_string) {
        location_string += ', ';
        location_string += county;
      } else {
        location_string += county;
      }
    }

    if (country) {
      if (location_string) {
        location_string += ', ';
        location_string += country;
      } else {
        location_string += country;
      }
    }

    setFieldValue('location', location_string);

    submitHandlerLocation();
  }

  const contentSection = (
    <>
      <Details>
        {updateArtistLoading || artistLoading ? (
          <>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
          </>
        ) : (
          <>
            <TextInput
              value={values.artistName}
              height={70}
              type="text"
              withBottomLine
              placeholder="Artist Name [edit]"
              onChange={handleChange('artistName')}
              error={
                Boolean(errors.artistName && touched.artistName)
                  ? errors.artistName
                  : undefined
              }
            />
            <TextInput
              value={values.location}
              height={70}
              type="text"
              withBottomLine
              onChange={handleChange('location')}
              placeholder="Start typing location"
              error={
                Boolean(errors.location && touched.location)
                  ? errors.location
                  : undefined
              }
              googleAutoComplete={{
                apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                onPlaceSelected: (place) => handleLocationSelect(place),
                options: {
                  componentRestrictions: {
                    country: usersCountry,
                  },
                  types: ['locality', 'administrative_area_level_2', 'country'],
                },
              }}
            />
            <Spacer height={20} />
            <Caption text="Introduction" />
            <Spacer height={20} />
            <Textarea
              withBottomLine
              value={values.intro}
              setValue={handleChange('intro')}
              placeholder="Enter introduction text"
              error={
                Boolean(errors.intro && touched.intro)
                  ? errors.intro
                  : undefined
              }
            />
            <Spacer height={20} />
            <Media
              type="socialMedia"
              values={artistSettingsData?.socialMedia || {}}
              handleSetState={setFieldValue}
            />
            <Spacer height={20} />
            <Media
              type="musicPlatforms"
              values={artistSettingsData?.musicPlatforms || {}}
              handleSetState={setFieldValue}
            />
            <Spacer height={20} />
            {/*<ToggleContainer>
              <Typography
                fontSize="fz18"
                fontWeight="regular"
                text="Apply for Verified Artist"
                fontColor={theme.colors.white}
              />
              <Tip text={'tip'} />
              <Toggle
                field="isApplyForVerify"
                toggle={values.isApplyForVerify}
                setToggle={setFieldValue}
              />
            </ToggleContainer>
            <Spacer height={20} />
            
            <BottomLine />
            <Spacer height={20} />
            <Caption text="Rights management" tip="tip text" />
            <Spacer height={20} />
            {SELECTORS_DATA.map(({ placeholder, options, selector }, i) => (
              <div key={`Key for Selector container is - ${selector} - ${i}`}>
                <Select
                  values={options}
                  placeholder={placeholder}
                  selectedValue={
                    values.rightManagement?.[
                      selector as keyof RightManagementTypes
                    ]
                  }
                  onChange={(value) =>
                    setFieldValue(`rightManagement.${selector}`, value)
                  }
                />
                <Spacer height={20} />
              </div>
            ))}
            <Spacer height={20} />
            <BottomLine />
            <Spacer height={20} />
            <Caption text="Payout" tip="tip text" />
            <Spacer height={20} />
            <Radio
              radio={values.payoutType}
              setRadio={handleChange('payoutType')}
            />
            <Spacer height={20} />
            <TextInput
              withBorder
              width={300}
              height={50}
              type="text"
              borderColor={theme.colors.gray}
              placeholder="Public Wallet Address"
              value={R.defaultTo('', walletAddress)}
              onChange={() => {}}
              error={
                Boolean(
                  errors.publicWalletAddress && touched.publicWalletAddress,
                )
                  ? errors.publicWalletAddress
                  : undefined
              }
            />
            <WalletAddressDescription
              fontSize="fz9"
              fontColor={theme.colors.gray}
              text="Please check your wallet addres as an incorrect address could result in loss
              of funds withoud the ability to reclaim"
            />
            */}
            <Spacer height={50} />
            <ButtonsRow>
              <Button
                height={50}
                width={144}
                label="Preview"
                fontSize={16}
                borderRadius={50}
                bgColor={theme.colors.black}
                labelColor={theme.colors.white}
                borderColor={'rgba(255, 255, 255, 0.5)'}
                onClick={() => {
                  if (dropsData.artistProfileByUserId) {
                    setPreviewUser(dropsData.artistProfileByUserId.slug);
                    setPreviewData(values);
                    navigate('/artist/preview');
                  }
                }}
                isLoading={updateArtistLoading || artistLoading}
              />
              <Button
                height={50}
                width={144}
                label="Submit"
                fontSize={16}
                borderRadius={50}
                onClick={handleSubmit}
                bgColor={theme.colors.black}
                labelColor={theme.colors.white}
                borderColor={theme.colors.white}
                isLoading={updateArtistLoading || artistLoading}
              />
            </ButtonsRow>
          </>
        )}
      </Details>
      <WidthSpacer width={30} />
    </>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Artist Settings | SongBits'}>
      <PageLayout
        title="Artist Settings"
        sections={[
          {
            content: (
              <>
                <ProfileImageContainer>
                  {updateArtistLoading || artistLoading ? (
                    <ImagePickerPlaceholder></ImagePickerPlaceholder>
                  ) : (
                    <ImagePicker
                      type="avatar"
                      image={values.avatar}
                      handleImage={handleImage}
                    />
                  )}
                </ProfileImageContainer>
                <WidthSpacer width={30} />
              </>
            ),
          },
          {
            content: contentSection,
          },
          {
            content: (
              <>
                <ColumnContainer>
                  {updateArtistLoading || artistLoading ? (
                    <DropsPlaceholder />
                  ) : (
                    <Drops drops={drops} />
                  )}

                  {updateArtistLoading || artistLoading ? (
                    <>
                      <Spacer height={60} />
                      <DropsPlaceholder header={false} />
                    </>
                  ) : (
                    <>
                      {/*<SongContainer>
                      <ArrowImg src={arrow} />
                      <AddButton>
                        <img src={whitePlus} />
                      </AddButton>
                      <DropsContent text="Add New Song to Drop" />
                  </SongContainer>*/}
                    </>
                  )}

                  {updateArtistLoading ||
                  (artistLoading && viewport.width >= 767) ? (
                    <QuickLinksPlaceholder></QuickLinksPlaceholder>
                  ) : (
                    <>
                      {viewport.width <= 767 ? (
                        <></>
                      ) : (
                        <QuickLinks links={quickLinks} isLogout />
                      )}
                    </>
                  )}
                </ColumnContainer>
              </>
            ),
          },
        ]}
      />

    </PageContainer>
  );
};

export default ArtistSettings;

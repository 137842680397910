import * as React from 'react';

import { IconProps } from '../common.types';

const DownloadIcon = ({ width = 20, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4375 0H11.5625C12.082 0 12.5 0.417969 12.5 0.9375V7.5H15.9258C16.6211 7.5 16.9688 8.33984 16.4766 8.83203L10.5352 14.7773C10.2422 15.0703 9.76172 15.0703 9.46875 14.7773L3.51953 8.83203C3.02734 8.33984 3.375 7.5 4.07031 7.5H7.5V0.9375C7.5 0.417969 7.91797 0 8.4375 0ZM20 14.6875V19.0625C20 19.582 19.582 20 19.0625 20H0.9375C0.417969 20 0 19.582 0 19.0625V14.6875C0 14.168 0.417969 13.75 0.9375 13.75H6.66797L8.58203 15.6641C9.36719 16.4492 10.6328 16.4492 11.418 15.6641L13.332 13.75H19.0625C19.582 13.75 20 14.168 20 14.6875ZM15.1562 18.125C15.1562 17.6953 14.8047 17.3438 14.375 17.3438C13.9453 17.3438 13.5938 17.6953 13.5938 18.125C13.5938 18.5547 13.9453 18.9062 14.375 18.9062C14.8047 18.9062 15.1562 18.5547 15.1562 18.125ZM17.6562 18.125C17.6562 17.6953 17.3047 17.3438 16.875 17.3438C16.4453 17.3438 16.0938 17.6953 16.0938 18.125C16.0938 18.5547 16.4453 18.9062 16.875 18.9062C17.3047 18.9062 17.6562 18.5547 17.6562 18.125Z"
      fill="white"
    />
  </svg>
);

export default DownloadIcon;

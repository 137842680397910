import React from 'react';

import { IconProps } from '../common.types';

const Intercom = ({ width = 40, height = 40 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
      fill="#FFD600"
    />
    <path
      d="M26.4913 11.0527H13.5089C12.1524 11.0527 11.0527 12.1524 11.0527 13.5089V26.4913C11.0527 27.8478 12.1524 28.9475 13.5089 28.9475H26.4913C27.8478 28.9475 28.9475 27.8478 28.9475 26.4913V13.5089C28.9475 12.1524 27.8478 11.0527 26.4913 11.0527Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9729 22.3276V31.4984L23.1582 28.86L28.9729 22.3276Z"
      fill="black"
    />
    <path
      d="M14.7368 24.7368C18.2456 26.6082 21.7544 26.6082 25.2631 24.7368"
      stroke="#FFD600"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Intercom;

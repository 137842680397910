import Spacer from 'common/components/Spacer/Spacer';
import React from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

const RoyaltyBreakdownPlaceholder = () => {
  const viewport = useViewport();
  return (
    <Column textAlign="left">
      <Row style={{ justifyContent: 'space-between' }}>
        <span
          className={`placeholder-wave placeholder fs-24 lh-24 bg-secondary ${
            viewport.width < 576 ? 'col-6' : 'col-8'
          }`}>
          &nbsp;
        </span>
        <span
          className={
            'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-1'
          }>
          &nbsp;
        </span>
      </Row>
      <Spacer height={10} />
      <Column textAlign="left" paddingLeft={30}>
        <span
          className={
            'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-2'
          }>
          &nbsp;
        </span>
        <Spacer height={10} />
        {[...Array(5)].map((_, index) => (
          <div key={`royalty-row-${index}`}>
            <Row>
              <Column textAlign="left">
                <span
                  className={
                    'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-5'
                  }>
                  &nbsp;
                </span>
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-6'
                  }>
                  &nbsp;
                </span>
              </Column>
              <Column textAlign="left" paddingLeft={20}>
                <span
                  className={
                    'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-5'
                  }>
                  &nbsp;
                </span>
                <span
                  className={
                    'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-6'
                  }>
                  &nbsp;
                </span>
              </Column>
            </Row>
            <Spacer height={20} />
          </div>
        ))}
      </Column>
    </Column>
  );
};

const Column = styled.div<{ textAlign?: string; paddingLeft?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px` : ''};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

export default RoyaltyBreakdownPlaceholder;

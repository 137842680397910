export const currencyOptions = [{ value: 'USD', label: 'USD' }];

export const cryptoChainOptions = [
  { value: 'ALGO', label: 'ALGO' },
  { value: 'AVAX', label: 'AVAX' },
  //{ value: 'BTC', label: 'BTC' }, // circle error ?....
  { value: 'ETH', label: 'ETH' },
  { value: 'FLOW', label: 'FLOW' },
  { value: 'HBAR', label: 'HBAR' },
  { value: 'SOL', label: 'SOL' },
  { value: 'TRX', label: 'TRX' },
  { value: 'XLM', label: 'XLM' },
];

import Button from 'common/components/Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import { useViewport } from 'use-viewport';

interface ConfirmSendMessageProps {
  isOpen: boolean;
  numSelectedUsers: number;
  onClose: () => void;
  onSendTest: () => void;
  onSendEmail: () => void;
  onSchedule: () => void;
}

const ConfirmSendMessage: FC<ConfirmSendMessageProps> = ({
  isOpen,
  numSelectedUsers,
  onClose,
  onSendTest,
  onSendEmail,
  onSchedule,
}) => {
  const viewport = useViewport();

  return (
    <SBModal
      className={'send-message'}
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top="10%"
      mobileTop="2%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text="confirmation"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={50} />

          <Typography
            text={`Confirm you wish to send this email to ${numSelectedUsers} user(s)?`}
            fontColor={theme.colors.yellow}
            fontSize="fz24"
            fontWeight="bold"
          />

          <Spacer height={60} />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '5px',
            }}>
            <Spacer height={70} />
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              bgColor={theme.colors.black}
              labelColor={theme.colors.yellow}
              label={
                <TextContent
                  text="send test"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={onSendTest}
            />
            <Spacer height={20} />
            <FirstButton
              height={45}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text="send now"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={onSendEmail}
            />
            <div onClick={onSchedule} style={{ marginTop: '20px', cursor: 'pointer' }}>
              <Typography
                text="Schedule for later"
                fontSize="fz16"
                fontColor={theme.colors.white}
                fontWeight="bold"
              />
            </div>
          </div>
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 357px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

export default memo(ConfirmSendMessage);

import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { md, sm, xs } from 'utils/constants';
import { Link } from 'react-router-dom';

export const Container = styled.div<{ showFooter: boolean }>`
  ${(props) => (props.showFooter ? 'display: block;' : 'display: none;')}
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  position: fixed;
  bottom: 0;

  width: 100%;
  padding: 3px 10px 15px 15px;

  background-color: ${theme.colors.black};
  z-index: 10;

  @media (min-width: ${sm}px) {
    height: 100px;
    padding: 20px 90px;
    justify-content: space-between;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
`;

export const IconsContainer = styled.div`
  color: white;
  display: flex;
  margin: 20px 0;
  gap: 10px;

  @media (min-width: ${sm}px) {
    margin: 0;
    margin-right: 10px;
    gap: 10px;
  }

  @media (min-width: ${md}px) {
    margin-right: 105px;
  }
`;

export const FooterText = styled.p`
  font-family: HKGrotesk-Black;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.05em;

  @media (min-width: ${sm}px) {
    font-size: 20px;
  }
`;

export const CopyRightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 5px;
  width: 100%;
  text-align: center;

  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
`

export const CopyRightsLink = styled(Link)<{ fontSize?: string }>`
  color: #fff;
  ${(props) => (props.fontSize ? 'font-size: ' + props.fontSize +'px;' : 'font-size: 9px;')}
  text-decoration: none;
`

export const CopyRights = styled(Typography)`
  width: 100%;
  text-align: center;

  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;

  @media (min-width: ${md}px) {
    bottom: 10px;
  }
`;

export const CopyRightsAlt = styled(Typography)`
  text-align: center;
  padding-bottom: 15px;
  z-index: 1;

  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */

`;

export const LogoButton = styled(Button)`
  margin-right: 20px;

  @media (min-width: ${xs}px) {
    margin-right: 56px;
  }

  &:hover {
    background: transparent;
  }
`;

export const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 20px;
`;

export const LinkButton = styled(Button)`
  padding: 0;
`;

export const IconButton = styled(Button)`
  display: none;

  @media (min-width: ${sm}px) {
    display: inline;
  }
`;

export const IntercomContainer = styled(Button)`
  position: absolute;
  bottom: 38px;
  right: 20px;

  padding: 0;

  z-index: 2;

  &:hover {
    background-color: rgb(0, 0, 0, 0);
  }

  @media (min-width: ${sm}px) {
    bottom: 75px;
    right: 30px;
  }

  @media (min-width: ${md}px) {
    right: 100px;
  }
`;

import React from 'react';

import Spacer from '../Spacer/Spacer';
import ImagePickerPlaceholder from './ImagePicker';
import {
  DropContainer,
  InfoContainer,
} from 'modules/artist/pages/ArtistReleases/components/DropData';

const DropCardPlaceholder = () => {
  return (
    <DropContainer>
      <ImagePickerPlaceholder
        width={145}
        height={145}
        withTextPlaceholder={false}
      />

      <InfoContainer style={{ width: '100%' }}>
        <span className={'placeholder-wave placeholder fs-36 lh-36 col-7'}>
          &nbsp;
        </span>

        <Spacer height={10} />

        <span className={'placeholder-wave placeholder fs-24 lh-24 col-5'}>
          &nbsp;
        </span>

        <Spacer height={60} />

        <span className={'placeholder-wave placeholder fs-16 lh-16 col-2'}>
          &nbsp;
        </span>
        <Spacer height={10} />
        <span className={'placeholder-wave placeholder fs-14 lh-14 col-4'}>
          &nbsp;
        </span>
      </InfoContainer>
    </DropContainer>
  );
};

export default DropCardPlaceholder;

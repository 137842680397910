import styled from 'styled-components';

export const RangeBorderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-left: -2px;
  height: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectedAreaBorderTop = styled.div`
  position: absolute;
  width: 100%;
  top: -60px;
  background-color: #ffd600;
  height: 2px;

  @media (min-width: 576px) {
    width: 91vw;
    max-width: 92vw;
    margin-left: 3px;
  }

  @media (min-width: 768px) {
    top: -55.5px;
    height: 3px;
    margin-right: -7px;
  }

  @media (min-width: 1200px) {
    width: 92vw;
    max-width: 92vw;
    top: -80px;
    margin-right: -5px;
  }

  @media (min-width: 1440px) {
    width: 91vw;
  }

  @media (min-width: 1920px) {
    width: 95vw;
    max-width: 95vw;
    top: -80px;
  }
`;

export const SelectedAreaBorderBottom = styled.div`
  position: absolute;
  bottom: -70px;
  width: 100%;
  background-color: #ffd600;
  height: 2px;

  @media (min-width: 576px) {
    width: 91vw;
    max-width: 92vw;
    margin-left: 3px;
  }

  @media (min-width: 768px) {
    bottom: -122.5px;
    height: 3px;
    margin-right: -7px;
  }

  @media (min-width: 1200px) {
    width: 92vw;
    max-width: 92vw;
    bottom: -98px;
    margin-right: -5px;
  }

  @media (min-width: 1440px) {
    width: 91vw;
  }

  @media (min-width: 1920px) {
    width: 95vw;
    max-width: 95vw;
  }
`;

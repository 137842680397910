import * as React from 'react';

import { IconProps } from '../common.types';

const MinusButton = ({ width = 15, height = 15 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity={0.5} stroke="#fff">
      <circle cx={7.5} cy={7.5} r={6.5} strokeWidth={2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.737 7.5c0-.218.112-.395.251-.395h5.024c.139 0 .251.177.251.395 0 .218-.112.395-.251.395H4.988c-.139 0-.251-.177-.251-.395Z"
        fill="#fff"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default MinusButton;

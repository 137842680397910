import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import { useStoreState } from 'store/store';
import * as R from 'ramda';

import useBreadcrumbs from 'hooks/useBreadcrumbs';

import { LightText } from 'modules/account/pages/styled/MyWallet.styled';
import { BreadcrumbDiv } from 'common/layout/styled/Breadcrumbs.styled';
import { RouteButton } from '../Header.styled';
import theme from 'theme/theme';

const customBreadcrumbsNames = {
  FAQ: 'Common Questions',
  labels: 'Artist + Labels',
  settings: 'Account Settings',
};

interface HeaderBreadcrumbsProps {
  isArtistPage: boolean;
}

const HeaderBreadcrumbs: FC<HeaderBreadcrumbsProps> = ({ isArtistPage }) => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const [breadcrumbs, breadcrumbsIndex] = useBreadcrumbs();

  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  const customPageBreadcrumbs = useStoreState(
    (state) => state.location.breadcrumbs,
  );

  const navigateToHandler = (route: string) => {
    navigate(route.replace('Home', ''));
  };

  return (
    <>
      {(customPageBreadcrumbs.length === 0
        ? breadcrumbs
        : customPageBreadcrumbs
      ).map((el, index, array) => {
        if (index > 0 && viewport.width < 576) return;

        const targetArr = array.map((pathPart) =>
          typeof pathPart === 'object' ? pathPart.value : pathPart,
        );

        let routeTo = R.dropLast(targetArr.length - 1 - index, targetArr).join(
          '/',
        );

        const isLastItem =
          R.last(targetArr) === (typeof el === 'object' ? el.value : el);

        let lastName;
        if (typeof el === 'object') {
          lastName = el.label;
        } else {
          if (Object.keys(customBreadcrumbsNames).includes(el)) {
            lastName = (customBreadcrumbsNames as any)[el];
          } else {
            lastName = el.replaceAll('-', ' ');
          }
        }

        switch (el) {
          case 'Home':
            routeTo = '/';
            break;

          case 'My Account':
            routeTo = '/account/settings';
            break;

          case 'library':
            routeTo = '/library/hot-drops';
            break;

          default:
            break;
        }

        return (
          el && (
            <React.Fragment
              key={`Single key to header route is: ${el} - ${index}`}>
              {isLastItem ? (
                <RouteButton
                  label={
                    <LightText
                      text={lastName}
                      fontWeight="light"
                      fontSize="fz12"
                      fontColor={theme.colors.white}
                    />
                  }
                  disabled={isLastItem}
                  onClick={() => navigateToHandler(routeTo)}
                  bold={index === breadcrumbsIndex}
                />
              ) : (
                <>
                  <RouteButton
                    label={(typeof el === 'object' ? el.label : el).replaceAll(
                      '-',
                      ' ',
                    )}
                    disabled={isLastItem}
                    onClick={() => navigateToHandler(routeTo)}
                    bold={true}
                  />
                  <BreadcrumbDiv isArtistPage={isArtistPage} isAuth={isAuth}>
                    |
                  </BreadcrumbDiv>
                </>
              )}
            </React.Fragment>
          )
        );
      })}
    </>
  );
};

export default HeaderBreadcrumbs;

import React from 'react';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';

import theme from 'theme/theme';
import { sm } from 'utils/constants';
import WebHelmet from 'common/components/GlobalHelmet/GlobalHelmet';

type PageContainerProps = {
  bgColor?: string;
  bgBanner?: string;
  bgBannerPos?: string;
  bgBannerSize?: string;
  reduceFooter?: string;
  reduceFooterMobile?: string;
  pageTitle?: string;
  pageDescription?: string;
  children: React.ReactNode;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

const PageContainer = ({
  children,
  reduceFooter,
  reduceFooterMobile,
  bgBanner,
  bgBannerPos,
  bgBannerSize,
  bgColor = theme.colors.black,
  pageTitle,
  pageDescription,
  onScroll = () => {},
}: PageContainerProps) => {
  const viewport = useViewport();

  return (
    <Container
      bgColor={bgColor}
      bgBanner={bgBanner}
      bgBannerPos={bgBannerPos}
      bgBannerSize={bgBannerSize}
      reduceFooter={viewport.width < sm ? reduceFooterMobile : reduceFooter}
      onScroll={onScroll}>
      {pageTitle && !pageDescription && (
        <WebHelmet title={pageTitle} />
      ) }
      {pageTitle && pageDescription && (
        <WebHelmet title={pageTitle} description={pageDescription} />
      ) }
      {children}
    </Container>
  );
};

const Container = styled.div<{
  bgColor: string;
  bgBanner?: string;
  bgBannerPos?: string;
  bgBannerSize?: string;
  reduceFooter?: string;
}>`
  width: 100%;

  min-height: 100%;
  padding-bottom: ${(props) =>
    props.reduceFooter ? props.reduceFooter : '24px'};

  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;

  /* overflow-y: visible; */
  overflow-x: hidden;

  position: relative;

  ${(props) =>
    props.bgBanner
      ? `
          background-image:  url(${props.bgBanner});
          background-size: ${props.bgBannerSize ? props.bgBannerSize : 'cover'};
          background-repeat: no-repeat;
          background-size: cover;
          background-position: ${
            props.bgBannerPos ? props.bgBannerPos : 'center'
          };
          background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
          `
      : ''}

  background-color: ${(props) => props.bgColor};
  z-index: 0;
`;

export default PageContainer;

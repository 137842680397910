import styled from 'styled-components';

const DefaultButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export default DefaultButton;

import React from 'react';

import {
  ContentContainer,
  TextInputContainer,
} from 'modules/artist/pages/styled/ArtistAccounting.styled';

import Spacer from '../Spacer/Spacer';

const TextInputPlaceholder = () => {
  return (
    <>
      <ContentContainer>
        <div style={{ display: 'flex' }}>
          <span
            className={
              'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-4'
            }>
            &nbsp;
          </span>
          <span className={' fs-18 lh-24 col-6'}>&nbsp;</span>
          <span
            className={
              'placeholder-wave placeholder fs-18 lh-24 col-1'
            }>
            &nbsp;
          </span>
        </div>
        <Spacer height={15} />
        <TextInputContainer>
          <span
            className={
              'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-7'
            }>
            &nbsp;
          </span>
        </TextInputContainer>
      </ContentContainer>

      <Spacer height={15} />
      <span
        className={
          'placeholder-wave placeholder fs-02 lh-5 bg-secondary col-11'
        }>
        &nbsp;
      </span>
      <Spacer height={15} />
    </>
  );
};

export default TextInputPlaceholder;

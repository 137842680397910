import * as React from 'react';

import { IconProps } from '../common.types';

const ThreeDisksIcon = ({ width = 37, height = 27 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 37 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0253 26.0507C30.219 26.0507 36.0507 20.219 36.0507 13.0253C36.0507 5.83164 30.219 0 23.0253 0C15.8316 0 10 5.83164 10 13.0253C10 20.219 15.8316 26.0507 23.0253 26.0507ZM11.668 13.0267C11.668 6.75373 16.7539 1.66779 23.0269 1.66779C29.2998 1.66779 34.3858 6.75373 34.3858 13.0267C34.3858 19.2996 29.2998 24.3856 23.0269 24.3856C16.7539 24.3856 11.668 19.2996 11.668 13.0267ZM30.7233 7.53026C30.1156 6.68118 29.3724 5.93792 28.5233 5.33021L26.3251 8.40843C26.8343 8.77286 27.2807 9.2192 27.6451 9.72846L30.7233 7.53026ZM26.8132 13.0267C26.8132 10.9357 25.1178 9.24038 23.0269 9.24038C20.9359 9.24038 19.2406 10.9357 19.2406 13.0267C19.2406 15.1177 20.9359 16.813 23.0269 16.813C25.1178 16.813 26.8132 15.1177 26.8132 13.0267ZM22.3575 12.3573C22.535 12.1798 22.7758 12.0801 23.0269 12.0801C23.2779 12.0801 23.5187 12.1798 23.6962 12.3573C23.8737 12.5349 23.9734 12.7756 23.9734 13.0267C23.9734 13.2777 23.8737 13.5185 23.6962 13.696C23.5187 13.8735 23.2779 13.9733 23.0269 13.9733C22.7758 13.9733 22.535 13.8735 22.3575 13.696C22.18 13.5185 22.0803 13.2777 22.0803 13.0267C22.0803 12.7756 22.18 12.5349 22.3575 12.3573ZM19.7286 17.6449C19.2194 17.2805 18.773 16.8342 18.4086 16.3249L15.3304 18.5231C15.9381 19.3722 16.6814 20.1154 17.5304 20.7231L19.7286 17.6449Z"
      fill="white"
    />
    <path
      d="M19.5 24.9476C19.1179 24.9823 18.7308 25 18.3395 25C11.5246 25 6 19.6274 6 13C6 6.37258 11.5246 1 18.3395 1C18.9026 1 19.4569 1.03668 20 1.10772"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14.5 24.9476C14.1179 24.9823 13.7308 25 13.3395 25C6.52457 25 1 19.6274 1 13C1 6.37258 6.52457 1 13.3395 1C13.9026 1 14.4569 1.03668 15 1.10772"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ThreeDisksIcon;

import React from 'react';

import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { ILocationsData } from 'services/types';
import { xs } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

interface TopLocationsProps {
  locations: ILocationsData[];
  currentDrop: {
    value: string | number;
    label: string;
  };
  handleAllCountriesOnClick: () => void;
}

const TopLocations: React.FC<TopLocationsProps> = ({
  locations,
  currentDrop,
  handleAllCountriesOnClick,
}) => {
  const navigate = useNavigate();

  const top3Locations = React.useMemo(
    () =>
      [...locations]
        .sort((a, b) => Number(b.amount) - Number(a.amount))
        .slice(0, 3),
    [locations],
  );

  const handleCountryClick = (location: string) => {
    const queryParam: { [x: string]: any } = {
      country: location,
    };

    if (currentDrop && currentDrop?.value !== 'all') {
      queryParam.release = currentDrop.value;
    }
    navigate(`/artist/insights/users`, {
      state: queryParam,
    });
  };

  return (
    <Wrapper>
      <Typography
        text="top locations"
        fontColor={theme.colors.yellow}
        fontWeight="bold"
        fontSize="fz24"
        letterSpacing="-0.03em"
      />

      <LocationsContainer>
        {top3Locations.map((location, index) => (
          <LocationContainer key={`Top location ${index}`}>
            <img
              src={location.flag}
              alt={location.name}
              width={30}
              height={20}
            />
            &nbsp;&nbsp;&nbsp;
            <LinkButton
              onClick={() => {
                handleCountryClick(location.name);
              }}>
              <Typography
                text={`${index + 1}. ${location.name}`}
                fontWeight="light"
              />
            </LinkButton>
          </LocationContainer>
        ))}
      </LocationsContainer>

      <LinkButton onClick={handleAllCountriesOnClick}>
        <Typography
          text="View all countries"
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.yellow}
        />
      </LinkButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 34px;
`;

const LocationsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  margin: 25px 0;

  @media (min-width: ${xs}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const LocationContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  margin-bottom: 20px;

  p {
    white-space: nowrap;
  }

  @media (min-width: ${xs}px) {
    padding-right: 20px;
    margin: 0;

    p {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const LinkButton = styled.div`
  cursor: pointer;
`;

export default React.memo(TopLocations);

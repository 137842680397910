import styled from 'styled-components';
import theme from 'theme/theme';

import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';

import { sm, xs } from 'utils/constants';

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${xs}px) {
    width: 370px;
  }

  @media (min-width: ${sm}px) {
    width: 500px;
  }
`;

export const ContentContainer = styled.div<{ first?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-left: 6px;
  padding-right: 10px;

  ${(props) => (props.first ? '' : 'padding-top: 30px;')}
`;

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 18px;
  color: ${theme.colors.white};
`;

export const Content = styled.div`
  padding: 30px 0;
`;

export const PayoutDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-right: 20px;
`;

export const ChooseDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 20px;
  padding-right: 24px;
`;

export const DateDropdowns = styled.div`
  width: 200px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonBordered = styled.button`
  width: 90px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'HKGrotesk-Light', sans-serif;
  font-size: 18px;
  color: ${theme.colors.gray};

  background-color: transparent;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;

  cursor: pointer;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconContainer = styled.div<{ margin?: string }>`
  margin: ${(props) => (props.margin ? props.margin : 0)};

  cursor: pointer;
`;

export const BankDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-right: 20px;
`;

export const TransactionsButton = styled(Button)`
  font-family: 'HKGrotesk-Light', sans-serif;
  font-size: 18px;
  padding-left: 0;
  padding-right: 0;
`;

export const InlineButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const YellowButton = styled(Button)`
  color: #FFD600;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
`;



export const QuickLinksPlaceholderContainer = styled.div`
  width: auto;

  @media (min-width: ${sm}px) {
    width: 350px;
  }
`;


export const WalletAddressText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
`;
import React from 'react';
import Typography from 'common/components/Typography/Typography';
import styled from 'styled-components';
import theme from 'theme/theme';
import { md } from 'utils/constants';

const ComingSoonBadge = ({
  index,
  heading,
}: {
  index: number;
  heading: JSX.Element;
}) => {
  return (
    <ComingSoonContainer index={index}>
      <BadgeContainer>
        <Date
          text={'coming'}
          size={100}
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          index={index}
        />
        <Date
          text={'soon'}
          size={200}
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          index={index}
        />
      </BadgeContainer>

      <HeadingContainer>{heading}</HeadingContainer>
    </ComingSoonContainer>
  );
};

const ComingSoonContainer = styled.div<{ index: number }>`
  display: flex;
  align-items: start;
  gap: 1px;

  flex-direction: column-reverse;

  @media (min-width: 768px) {
    align-items: end;
    flex-direction: ${(props) =>
      props.index % 2 !== 0 ? 'initial' : 'row-reverse'};
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 10px;
  margin: auto;
  padding-bottom: 18px;

  @media (min-width: 576px) {
    flex-direction: column;
    gap: 3px;
  }
`;

const HeadingContainer = styled.div`
  transform: translateY(-10px);
`;

const Date = styled(Typography)<{
  index: number;
  letterSpacing?: string;
  size?: number;
}>`
  font-family: Roboto-Black, sans-serif;
  line-height: 60%;
  font-weight: 900;
  opacity: 0.5;
  font-style: normal;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '-3px'};
  text-align: start;
  font-size: 65px;

  @media (min-width: ${md}px) {
    font-size: ${(props) =>
      props.size ? props.size + 'px' : theme.fontSizes.fz100};
    letter-spacing: -5px;
  }
`;

export default ComingSoonBadge;

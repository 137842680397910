import React from 'react';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useStoreState } from 'store/store';
import { InstractionalStages } from 'typings/types';

import classnames from 'classnames';

import {
  RangeBorderContainer,
  SelectedAreaBorderBottom,
  SelectedAreaBorderTop,
} from './MultiRangeSlider.styled';

import './MultiRangeSlider.css';
import styled from 'styled-components';
import DragInstractionData from 'common/icons/DragInstractionData.icon';
import { lg, sm, xlg, xs } from 'utils/constants';
import BitsManualInstractionData from 'common/icons/BitsManualInstractionData.icon';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  leftSliderVal?: number;
  rightSliderVal?: number;
  onChange: ({
    min,
    max,
    leftSliderVal,
  }: {
    min: number;
    max: number;
    leftSliderVal: number | undefined;
  }) => void;
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
  min,
  max,
  leftSliderVal,
  rightSliderVal,
  onChange,
}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);

  const maximum = max;
  const leftSliderMax = max / 2 - 0.5;

  const sliderTrack = document.getElementById('sliderTrack');
  const rangeBorderTop = document.getElementById('areaBorderTop');
  const rangeBorderBottom = document.getElementById('areaBorderBottom');
  const sliderSelectedRange = document.getElementById('sliderSelectedRange');

  const instructionalStage = useStoreState((state) => state.instructions.stage);

  useEffect(() => {
    const updatePlayerData = () => {
      if (minValRef.current && maxValRef.current) {
        const minPercent = getPercent(+minValRef.current.value);
        const maxPercent = getPercent(+maxValRef.current.value);

        if (range.current) {
          range.current.style.width = `${maxPercent - minPercent}%`;
        }
      }
    };

    window.addEventListener('resize', updatePlayerData);
    sliderSelectedRange?.addEventListener('resize', updateBorderSize);

    return () => {
      window.removeEventListener('resize', updatePlayerData);
      sliderSelectedRange?.removeEventListener('resize', updateBorderSize);
    };
  }, [
    sliderTrack,
    sliderSelectedRange,
    rangeBorderTop,
    rangeBorderBottom,
    minValRef,
    maxValRef,
  ]);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  useEffect(() => updateBorderSize, []);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }

    updateBorderSize();
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }

    updateBorderSize();
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({
      min: minVal,
      max: maxVal,
      leftSliderVal: leftSliderVal,
    });
  }, [minVal, maxVal]);

  const handleLeftSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(+event.target.value, leftSliderMax);

    setMinVal(value);
    setMaxVal(Math.max(leftSliderMax - 1, maximum - value));
    event.target.value = value.toString();
  };

  const handleRightSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(+event.target.value, minVal + 1);

    setMaxVal(value);
    setMinVal(Math.min(maximum - value, leftSliderMax));

    event.target.value = value.toString();
  };

  const updateBorderSize = () => {
    if (
      sliderTrack &&
      sliderSelectedRange &&
      rangeBorderTop &&
      rangeBorderBottom
    ) {
      // Resize top and bottom borders of selected area

      const borderWidth = sliderSelectedRange.offsetWidth + 'px';
      rangeBorderTop.style.width = borderWidth;
      rangeBorderBottom.style.width = borderWidth;
    }
  };

  return (
    <div
      id="newSliderContainer"
      className={
        instructionalStage === InstractionalStages.bitsPicker
          ? 'container active'
          : 'container'
      }>
      <DragInstractionalContainer
        show={instructionalStage === InstractionalStages.bitsPicker}>
        <DragInstractionData />
      </DragInstractionalContainer>

      <BitsManualInstructionalContainer
        show={instructionalStage === InstractionalStages.bitsPicker}>
        <BitsManualInstractionData />
      </BitsManualInstructionalContainer>

      {/* Left Slider */}
      <input
        id="leftSlider"
        type="range"
        step={1}
        min={min}
        max={max}
        value={leftSliderVal}
        ref={minValRef}
        onChange={handleLeftSliderChange}
        className={classnames('thumb', 'thumb--zindex-1', 'left-thumb-adjust', {
          'thumb--zindex-5': minVal > max - 100,
        })}
      />
      {/* Right Slider */}
      <input
        id="rightSlider"
        type="range"
        step={1}
        min={min}
        max={max}
        value={rightSliderVal}
        ref={maxValRef}
        onChange={handleRightSliderChange}
        className="thumb thumb--zindex-1 right-thumb-adjust"
      />

      <div className="slider">
        <RangeBorderContainer>
          <SelectedAreaBorderTop id="areaBorderTop" />
          <SelectedAreaBorderBottom id="areaBorderBottom" />
        </RangeBorderContainer>

        <div id="sliderTrack" className="slider__track"></div>
        <div
          id="sliderSelectedRange"
          ref={range}
          className="slider__range"></div>
      </div>
    </div>
  );
};

const DragInstractionalContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  position: absolute;
  top: -120px;
  left: -20px;

  z-index: 11;

  @media (min-width: ${lg}px) {
    top: -140px;
    left: -40px;
  }
`;

const BitsManualInstructionalContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  position: absolute;
  top: 20px;
  left: 20%;

  z-index: 11;

  @media (min-width: ${xs}px) {
    top: 40px;
    left: 40%;
  }

  @media (min-width: ${sm}px) {
    top: 70px;
    left: 40%;
  }

  @media (min-width: ${lg}px) {
    top: 50px;
  }

  @media (min-width: ${xlg}px) {
    left: 45%;
  }
`;

export default MultiRangeSlider;

import { CSSObjectWithLabel } from "react-select";
import theme from "theme/theme";

export const menuStyleFullWidth = {
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    backgroundColor: 'black',
    border: `1px solid ${theme.colors.yellow}`,
    borderRadius: '20px',
    width: '100%',
    minHeight: '30px',
    height: '30px',
    paddingRight: '5%',
    paddingTop: '5px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    fontSize: '18px',
    boxShadow: "none",
    "&:focus": {
      borderColor: "#FFD600"
    },
    "&:hover": {
      borderColor: "#FFD600"
    }
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    backgroundColor: 'black',
    border: `2px solid ${theme.colors.yellow}`,
    borderRadius: '20px',
    zIndex: '1000000',
    width: '100%',
    maxHeight: "110px"
  }),
  menu: () => ({
    backgroundColor: 'transparent',
    color: 'white',
    height: '0px',
    width: '100%',
  }),
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    background: '#000',
    borderRadius: '20px',
    width: '100%',
    "&:hover": {
      background: "black",
      fontFamily: 'HKGrotesk-Black',
      fontWeight: "900"
    },
    "&:focus": {
      background: "black"
    }
  }),
  indicatorsContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    marginTop: '-8px',
    background: 'transparent',
    fontSize: '18px'
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#999999',
    fontFamily: 'HKGrotesk-Light',
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    fontFamily: 'HKGrotesk-Light',
    backgroundColor: 'black',
    background: 'black',
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    fontFamily: 'HKGrotesk-Light',
  }),
};

import BottomLine from 'common/components/BottomLine/BottomLine';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';
import Spacer from 'common/components/Spacer/Spacer';
import React from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

const RoyaltyOverviewPlaceholder = ({
  withImagePlaceholder = true,
}: {
  withImagePlaceholder?: boolean;
}) => {
  const viewport = useViewport();
  return (
    <RoyaltyOverviewContainer>
      {withImagePlaceholder && (
        <SongImageContainer>
          <ImagePickerPlaceholder
            width={150}
            height={150}
            withTextPlaceholder={false}
          />
        </SongImageContainer>
      )}
      {viewport.width < 576 && <Spacer height={20} />}
      <span
        className={
          'placeholder-wave placeholder fs-36 lh-36 bg-secondary col-6'
        }>
        &nbsp;
      </span>
      {viewport.width > 576 && <Spacer height={5} />}
      <span
        className={`placeholder-wave placeholder fs-36 lh-36 bg-secondary ${
          viewport.width < 576 ? 'col-8' : 'col-10'
        }`}>
        &nbsp;
      </span>
      <Spacer height={viewport.width < 576 ? 20 : 30} />
      <Row>
        <Column textAlign="left">
          <span
            className={`placeholder-wave placeholder fs-18 lh-18 bg-secondary ${
              viewport.width < 576 ? 'col-10' : 'col-12'
            }`}>
            &nbsp;
          </span>
          <span
            className={
              'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-8'
            }>
            &nbsp;
          </span>
        </Column>
        <Column textAlign="left" paddingLeft={50}>
          <span
            className={
              'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-8'
            }>
            &nbsp;
          </span>
          <span
            className={
              'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-7'
            }>
            &nbsp;
          </span>
        </Column>
      </Row>
      <Spacer height={viewport.width < 576 ? 25 : 40} />
      <BottomLine />
      <Spacer height={viewport.width < 576 ? 25 : 35} />
    </RoyaltyOverviewContainer>
  );
};

const RoyaltyOverviewContainer = styled.div`
  justify-content: center;

  @media (min-width: 576px) {
    display: block;
  }
`;

const Column = styled.div<{ textAlign?: string; paddingLeft?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px` : ''};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const SongImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: 576px) {
    justify-content: unset;
  }
`;

export default RoyaltyOverviewPlaceholder;

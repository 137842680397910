import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useViewport } from 'use-viewport';

import { useNavigate } from 'react-router';
import theme from 'theme/theme';
import styled from 'styled-components';
//import restService from 'services/rest.service';

import BottomLine from 'common/components/BottomLine/BottomLine';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import SBModal from 'common/components/Modal/SBModal';
import MyCollectionCardPlaceholder from 'common/components/Placeholder/MyCollectionCard';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import MyCollectionCard from './components/MyCollectionCard/MyCollectionCard';
import CardPaymentResultModal from 'modules/account/pages/components/Modals/CardPaymentResultModal';
import EmptyCollection from './components/EmptyCollection/EmptyCollection';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';

import { TransactionType } from '../../library/types';
import { sm, xs } from 'utils/constants';
import PageLayout from 'common/components/PageLayout/PageLayout';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import ReactHowler from 'react-howler';
import { useStoreActions, useStoreState, RestInterface } from 'store/store';

const quickLinks = [
  {
    title: 'My Wallet',
    link: '/account/wallet',
  },
  {
    title: 'Transaction History',
    link: '/account/transactions',
  },
  {
    title: 'Account Settings',
    link: '/account/settings',
  },
  {
    title: 'My Royalties',
    link: '/account/royalty',
  },
];

const MyCollection = () => {
  const viewport = useViewport();
  const location = useLocation();
  const { uuid, id } = useParams();
  const navigate = useNavigate();

  //const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const transactions = useStoreState((state) => state.data.my_collection);
  const rest = useStoreActions((actions) => actions.data.rest);
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTransaction, setCurrentTransaction] =
    useState<TransactionType>();

  const playerRef = useRef<ReactHowler>(null);

  const refreshTransactions = async () => {
    let config: RestInterface;
    if (id) {
      config = {
        url: `/purchase/collection/?purchaseId={id}`,
        parameters: {id},
        model: "my_collection",
      }
    } else {
      config = {
        url: `/purchase/collection/`,
        model: "my_collection",
      }
    }
    await rest(config).catch((error: any) => {
      console.error(error);
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    refreshTransactions();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/payment-status')) {
      setShowPaymentDetailsModal(true);
    }
  }, []);

  useEffect(() => {
    playerRef.current?.seek(0);
  }, [currentTransaction]);

  return (
    <PageContainer pageTitle={'My Collection | SongBits'}>
      <BreadcrumbsContainer hidden={viewport.width > xs}>
        <MobileBreadcrumbs />
      </BreadcrumbsContainer>

      <PageLayout
        title={'My Collection'}
        is2ColumnLayout
        padding={viewport.width < xs ? '20px 20px 0 20px' : '100px 20px 0 20px'}
        sections={[
          {
            content: (
              <>
                {loading ? (
                  <>
                    {[...Array(4)].map((_, index) => (
                      <MyCollectionCardPlaceholder
                        key={`card-placeholder-${index}`}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {transactions.length === 0 ? (
                      <EmptyCollection />
                    ) : (
                      <>
                        {currentTransaction && (
                          <ReactHowler
                            ref={playerRef}
                            src={currentTransaction.song_url}
                            playing={isPlaying}
                            onEnd={() => setIsPlaying(false)}
                            html5={true}
                          />
                        )}

                        {transactions.map((transaction, index) => (
                          <div
                            key={`Key for single list for purchase history is: ${transaction.id}`}>
                            <MyCollectionCard
                              songData={transaction}
                              reloadAPI={() => {
                                refreshTransactions();
                              }}
                              isPlaying={
                                currentTransaction?.id === transaction.id &&
                                isPlaying
                              }
                              onPause={() => setIsPlaying(false)}
                              onPlay={() => {
                                setCurrentTransaction(transaction);
                                setIsPlaying(true);
                              }}
                            />
                            {index < transactions.length - 1 ? (
                              <>
                                <Spacer height={19} />
                                <BottomLine />
                                <Spacer height={19} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}

                        <Button
                          onClick={() => navigate('/library/hot-releases')}>
                          <Typography
                            text={'browse latest releases'}
                            fontWeight="bold"
                            fontSize="fz16"
                          />
                        </Button>
                      </>
                    )}
                  </>
                )}
                <Spacer height={50} />
              </>
            ),
          },
          {
            content:
              viewport.width > sm ? (
                <QuickLinks links={quickLinks} isLogout />
              ) : null,
          },
        ]}
      />

      <SBModal
        className={'payment-details'}
        isOpen={showPaymentDetailsModal}
        content={
          <CardPaymentResultModal
            uuid={uuid}
            onChange={() => {
              setShowPaymentDetailsModal(false);
            }}
          />
        }
      />
    </PageContainer>
  );
};

const Button = styled.button`
  width: 250px;
  height: 45px;

  background: transparent;

  border: 3px solid ${theme.colors.yellow};
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
`;

export default WithRouteAnimation(MyCollection);

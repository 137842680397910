import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import styled from 'styled-components';

import '../assets/editorSkins/songbitsskin/skin/skin.css';
import '../assets/editorSkins/songbitsskin/skin/content.css';
import Button from 'common/components/Button/Button';
import { Input } from 'common/components/TextInput/TextInput.styled';
import { useViewport } from 'use-viewport';
import { useFormik } from 'formik';
import Yup from 'yup-extended';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { CreateMessageType } from 'services/types';
import restService from 'services/rest.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import ConfirmSendMessageModal from './MarketingTools/modals/ConfirmSendMessageModal';
import ScheduleMessage from './MarketingTools/modals/ScheduleMessage';
import { useStoreActions } from 'store/store';

const VALIDATION_SCHEMA = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  preview_line: Yup.string().required('Preview Line is required'),
  message_content: Yup.string().required('Message content is required'),
  recipients: Yup.object({
    users: Yup.array().of(Yup.string()),
    tests: Yup.array()
      .of(
        Yup.string().matches(
          EMAIL_VALIDATION_PATTERN,
          'Invalid email address! Email will require verification.',
        ),
      )
      .required(),
  }),
});

const SendMessage = () => {
  const { id } = useParams();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [showConfirmSendModal, setShowConfirmSendModal] =
    useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);

  const editorRef = useRef<any>(null);
  const viewport = useViewport();
  viewport;
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = useMemo(
    () => ({
      title: '',
      subject: '',
      preview_line: '',
      message_content: '',
      recipients: {
        users: [],
        tests: [],
      },
      status: 'draft',
    }),
    [],
  );

  useEffect(() => {
    if (location.state) {
      const { selected_users } = location.state as { selected_users: string[] };
      setFieldValue('recipients.users', selected_users);
    }
  }, []);

  useEffect(() => {
    if (id) {
      restService
        .getMessage(id)
        .then((res) => {
          setFieldValue('title', res.subject);
          setFieldValue('subject', res.subject);
          setFieldValue('message_content', res.message_content);
          setFieldValue('preview_line', res.preview_line);
          setFieldValue('recipients.users', res.recipients.users || ['']);
          setFieldValue('recipients.tests', res.recipients.tests || ['']);
        })
        .catch((error) => {
          //console.log('Error getting message preview... ', error);
          error;
        });
    }
  }, [id]);

  const { values, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: submitHandler,
    });

  function submitHandler() {}

  function closeModals() {
    setShowConfirmSendModal(false);
    setShowScheduleModal(false);
  }

  function saveDraft() {
    const messageData: CreateMessageType = {
      subject: values.subject,
      preview_line: values.subject,
      message_content: editorRef.current.getContent(),
      recipients: {
        users: values.recipients.users,
        tests: values.recipients.tests,
      },
    };

    if (id) {
      restService
        .updateMessage(id, messageData)
        .then(() => {
          closeModals();
          navigate('/artist/insights/users/message-list');
        })
        .catch((error) => {
          error;
          //console.log('Error editing existing draft and saving', error);
          closeModals();
        });
    } else {
      restService
        .createMessage(messageData)
        .then(() => {
          closeModals();
          navigate('/artist/insights/users/message-list');
        })
        .catch((error) => {
          //console.log('Error creating new draft and saving', error);
          error;
          closeModals();
        });
    }
  }

  /*
   * @param sendAt: date/time to schedule message for
   * e.g. “2024-01-01 10:00:00”
   * If omitted, sendAt will default to now
   */
  function sendMessage(sendAt?: string) {
    const messageData: CreateMessageType = {
      subject: values.subject,
      preview_line: values.subject,
      message_content: editorRef.current.getContent(),
      recipients: {
        users: values.recipients.users,
        tests: values.recipients.tests,
      },
    };

    if (sendAt) messageData.send_at = sendAt;

    if (id) {
      messageData.status = 'ready';

      restService
        .updateMessage(id, messageData)
        .then(() => {
          closeModals();
          navigate('/artist/insights/users/message-list');
        })
        .catch((error) => {
          error;
          closeModals();
        });
    } else {
      restService.createMessage(messageData).then((res) => {
        restService
          .updateMessage(res.id, { status: 'ready' })
          .then(() => {
            closeModals();
            navigate('/artist/insights/users/message-list');
          })
          .catch((error) => {
            error;
            closeModals();
          });
      });
    }
  }

  function sendTestMessage() {
    if (id) {
      restService
        .sendTestMessage(id)
        .then((res) => {
          res;
          setGlobalBanner({
            title: '',
            text: 'Test email has been sent',
          });
          closeModals();
        })
        .catch((error) => {
          closeModals();
          error;
        });
    } else {
      const messageData: CreateMessageType = {
        subject: values.subject,
        preview_line: values.subject,
        message_content: editorRef.current.getContent(),
        recipients: {
          users: values.recipients.users,
          tests: values.recipients.tests,
        },
      };

      restService.createMessage(messageData).then((res) => {
        restService
          .sendTestMessage(res.id)
          .then(() => {
            setGlobalBanner({
              title: '',
              text: 'Test email has been sent',
            });
            closeModals();
          })
          .catch((error) => {
            closeModals();
            error;
            //console.log(error);
          });
      });
    }
  }

  function onPreviewMessage() {
    const messageData: CreateMessageType = {
      subject: values.subject,
      preview_line: values.subject,
      message_content: editorRef.current.getContent(),
      recipients: {
        users: values.recipients.users,
        tests: values.recipients.tests,
      },
    };

    if (id) {
      restService.updateMessage(id, messageData).then(() => {
        navigate('/artist/insights/users/send-message/preview', {
          state: { id: id, from: 'send-message' },
        });
      });
    } else {
      restService
        .createMessage(messageData)
        .then((res) => {
          navigate('/artist/insights/users/send-message/preview', {
            state: { id: res.id, from: 'send-message' },
          });
        })
        .catch((error) => {
          error;
          //console.log('Failed to create message: ', error);
        });
    }
  }

  const firstColumn = (
    <div style={{ width: '100%' }}>
      <Typography
        text={`${values.recipients.users.length} users selected`}
        fontWeight="bold"
        fontColor={theme.colors.white}
      />

      <Spacer height={20} />

      <InputContainer>
        <InputRow>
          <Typography
            text="Title:"
            fontColor={theme.colors.white}
            fontWeight="regular500"
            fontSize="fz18"
          />
          <FieldInput
            name="title-input"
            placeholder="internal use only"
            value={values.title}
            onChange={handleChange('title')}
          />
        </InputRow>
        <InputRow>
          <Typography
            text="Subject:"
            fontColor={theme.colors.white}
            fontWeight="regular500"
            fontSize="fz18"
          />
          <FieldInput
            name="subject-input"
            placeholder="public message subject"
            value={values.subject}
            onChange={handleChange('subject')}
          />
        </InputRow>
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={
            values.message_content
              ? values.message_content
              : editorRef.current
              ? editorRef.current.getContent()
              : '<p>This is the initial content of the editor.</p>'
          }
          init={{
            height: 289,
            menubar: false,
            skin: false,
            content_css: '/skins/content/content.css',
            plugins: [],
            toolbar:
              'undo redo | ' +
              'bold italic | alignleft aligncenter ' +
              'alignright alignjustify | ' +
              'removeformat',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
        <InputRow>
          <Typography
            text="Send test email to:"
            fontColor={theme.colors.white}
            fontWeight="regular500"
            fontSize="fz18"
          />
          <FieldInput
            name="test-email-input"
            placeholder="example@example.com"
            value={values.recipients.tests[0]}
            width="329px"
            onChange={handleChange('recipients.tests[0]')}
          />
        </InputRow>
      </InputContainer>

      <ButtonContainer>
        <WhiteButton
          label={
            <Typography text="Preview" fontWeight="bold" fontSize="fz16" />
          }
          opacity={0.5}
          onClick={onPreviewMessage}
        />
        <WhiteButton
          label={
            <Typography text="save draft" fontWeight="bold" fontSize="fz16" />
          }
          opacity={0.5}
          onClick={saveDraft}
        />
        <WhiteButton
          label={<Typography text="send" fontWeight="bold" fontSize="fz16" />}
          onClick={() => setShowConfirmSendModal(true)}
        />
      </ButtonContainer>
    </div>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <PageLayout
        title={'compose message'}
        is1ColumnLayout
        width="100%"
        sections={[
          {
            content: firstColumn,
          },
        ]}
      />

      <ConfirmSendMessageModal
        isOpen={showConfirmSendModal}
        numSelectedUsers={values.recipients.users.length}
        onClose={() => setShowConfirmSendModal(false)}
        onSendTest={sendTestMessage}
        onSendEmail={() => {
          sendMessage(undefined);
        }}
        onSchedule={() => setShowScheduleModal(true)}
      />

      <ScheduleMessage
        isOpen={showScheduleModal}
        numSelectedUsers={values.recipients.users.length}
        onClose={() => setShowScheduleModal(false)}
        onSchedule={(sendAt: string) => {
          sendMessage(sendAt);
        }}
      />
    </PageContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;

  @media (min-width: 768px) {
    padding-left: unset;
    padding-right: unset;
  }
`;

const WhiteButton = styled(Button)<{ opacity?: number }>`
  border: 3px solid
    rgba(255, 255, 255, ${(props) => (props.opacity ? props.opacity : 1)});
  border-radius: 50px;
  font-size: 16px;
  padding: 5px 10px;
  width: 143px;
  height: 45px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
`;

const FieldInput = styled(Input)<{ width?: string }>`
  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px;
  width: 262px;
  height: 46px;
  padding-left: 15px;

  &::placeholder {
    color: white;
    opacity: 0.2;
    font-weight: 900;
  }

  @media (min-width: 768px) {
    width: ${(props) => (props.width ? props.width : '372px')};
  }
`;

export default SendMessage;

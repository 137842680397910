import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  title?: string;
  description?: string;
  language?: string;
}

const WebHelmet: FC<HelmetProps> = ({
  title = 'SongBits',
  description = 'A platform where you can buy shared ownership in songs directly from your favourite artists and earn royalties together with them for life.',
  language = 'en',
  children,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="UTF-8" />
      <meta name="description" content={description} />
      <meta name="language" content={language} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content="songbits, music" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      {/* SEO */}
      <meta name="description" content={description} />

      {/* OG tags */}
      <meta property="og:type" content="website" />
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />

      <meta content="%REACT_APP_SELF_URL%/social-seo.png" property="og:image" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta content="%PUBLIC_URL%/" property="og:url" />

      {/* Twitter */}
      <meta content={title} property="twitter:title" />
      <meta content={description} property="twitter:description" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta
        name="twitter:image"
        content="%REACT_APP_SELF_URL%/social-seo.png"
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />

      <meta
        httpEquiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />
      {children}
    </Helmet>
  );
};

export default WebHelmet;

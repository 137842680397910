export type GlobalBannerProps = {
  title: string;
  text: string;
};

export type MenuItemOptionsType = {
  color: string;
};
export interface IMenuItem {
  text: string;
  item: string | IMenuItem[];
  options?: MenuItemOptionsType;
}

export enum InstractionalStages {
  initial = 0,
  songInfo = 1,
  bitsPicker = 2,
}

export enum DropsType {
  CURRENT = 'current',
  COMPLETED = 'completed',
  RELEASE_IN_PROGRESS = 'release in progress',
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc'
}
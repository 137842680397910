import * as React from 'react';

import { IconProps } from '../common.types';

const Apple = ({ width = 18, height = 22, fill = '#FFD600' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3126 0.0110096C13.2658 -0.0412401 11.5815 0.0316345 10.1157 1.6225C8.64998 3.21199 8.87548 5.03523 8.90848 5.08198C8.94148 5.12873 10.9985 5.20161 12.3116 3.35224C13.6247 1.50288 13.3593 0.0646343 13.3126 0.0110096ZM17.8693 16.1438C17.8033 16.0118 14.6724 14.4471 14.9639 11.4386C15.2554 8.42872 17.2671 7.60372 17.2987 7.51435C17.3303 7.42497 16.4778 6.4281 15.5744 5.92348C14.9112 5.5677 14.1771 5.36387 13.4253 5.32673C13.2768 5.32261 12.7612 5.19611 11.7011 5.48623C11.0026 5.67736 9.42822 6.2961 8.9951 6.32085C8.5606 6.3456 7.26811 5.60311 5.87799 5.40648C4.98837 5.23461 4.04513 5.58661 3.37001 5.85748C2.69626 6.12698 1.41477 6.89422 0.51827 8.93334C-0.378225 10.9711 0.0906475 14.1996 0.426146 15.2033C0.761644 16.2057 1.28552 17.8488 2.17651 19.0478C2.96851 20.4008 4.019 21.3399 4.45763 21.6589C4.89625 21.9779 6.13374 22.1896 6.99174 21.751C7.68198 21.3275 8.92773 21.0842 9.41997 21.102C9.91085 21.1199 10.8788 21.3138 11.8702 21.8431C12.6553 22.114 13.3978 22.0013 14.1417 21.6988C14.8856 21.3949 15.9622 20.2427 17.2189 17.9065C17.6961 16.8203 17.9133 16.2332 17.8693 16.1438Z"
      fill={fill}
    />
    <path
      d="M13.3126 0.0110096C13.2658 -0.0412401 11.5815 0.0316345 10.1157 1.6225C8.64998 3.21199 8.87548 5.03523 8.90848 5.08198C8.94148 5.12873 10.9985 5.20161 12.3116 3.35224C13.6247 1.50288 13.3593 0.0646343 13.3126 0.0110096ZM17.8693 16.1438C17.8033 16.0118 14.6724 14.4471 14.9639 11.4386C15.2554 8.42872 17.2671 7.60372 17.2987 7.51435C17.3303 7.42497 16.4778 6.4281 15.5744 5.92348C14.9112 5.5677 14.1771 5.36387 13.4253 5.32673C13.2768 5.32261 12.7612 5.19611 11.7011 5.48623C11.0026 5.67736 9.42822 6.2961 8.9951 6.32085C8.5606 6.3456 7.26811 5.60311 5.87799 5.40648C4.98837 5.23461 4.04513 5.58661 3.37001 5.85748C2.69626 6.12698 1.41477 6.89422 0.51827 8.93334C-0.378225 10.9711 0.0906475 14.1996 0.426146 15.2033C0.761644 16.2057 1.28552 17.8488 2.17651 19.0478C2.96851 20.4008 4.019 21.3399 4.45763 21.6589C4.89625 21.9779 6.13374 22.1896 6.99174 21.751C7.68198 21.3275 8.92773 21.0842 9.41997 21.102C9.91085 21.1199 10.8788 21.3138 11.8702 21.8431C12.6553 22.114 13.3978 22.0013 14.1417 21.6988C14.8856 21.3949 15.9622 20.2427 17.2189 17.9065C17.6961 16.8203 17.9133 16.2332 17.8693 16.1438Z"
      fill={fill}
    />
  </svg>
);

export default Apple;

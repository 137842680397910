import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { getUserIdFromJWT } from 'utils/functions';

import { SocialButton, SocialShareContainer } from '../SongInfo.styled';
import SBModal from 'common/components/Modal/SBModal';
import SongProfileShareModal from '../../SongProfileShareModal/SongProfileShareModal';

import Heart from 'common/icons/Heart.icon';
import SocialShareIcon from 'common/icons/SocialShare.icon';

import {
  LIKE_SONG,
  UNLIKE_SONG,
} from 'modules/library/graphql/Mutations.graphql';

interface SocialShareSectionProps {
  show?: boolean;
  songData: any;
}

const SocialShareSection: React.FC<SocialShareSectionProps> = ({
  songData,
  show = true,
}) => {
  const userId = getUserIdFromJWT();

  const [isLiked, setIsLiked] = useState<string>(songData.songLiked);
  const [showSocialModal, setShowSocialModal] = useState<boolean>(false);

  const [socialTopPosition, setSocialTopPosition] = useState<string>('40%');

  const [likeSong] = useMutation(LIKE_SONG, {
    variables: { userid: userId, songid: songData.id },
  });
  const [unlikeSong] = useMutation(UNLIKE_SONG, {
    variables: { likeid: isLiked },
  });

  const likingHandle = () => {
    if (isLiked) {
      unlikeSong().then(() => {
        setIsLiked('');
      });
    } else {
      likeSong().then((data) => {
        setIsLiked(data.data.createSonglike.songlike.id);
      });
    }
  };

  useEffect(() => {
    if (showSocialModal) (window as any).gtag('event', 'buy_share', {});
  }, [showSocialModal]);

  if (!show) return null;

  return (
    <>
      <SocialShareContainer>
        <SocialButton
          label={<SocialShareIcon />}
          onClick={() => {
            setShowSocialModal(true);
          }}
        />
        <SocialButton
          label={
            isLiked ? (
              <Heart width={25} height={25} fill="red" />
            ) : (
              <Heart width={25} height={25} fill="white" />
            )
          }
          onClick={likingHandle}
        />
      </SocialShareContainer>

      <SBModal
        className={'share'}
        isOpen={showSocialModal}
        height="430px"
        top={socialTopPosition}
        content={
          <SongProfileShareModal
            imageLink={songData.artwork}
            onChange={() => {
              setShowSocialModal(false);
            }}
            changeModalPosition={(topPos) => {
              setSocialTopPosition(topPos);
            }}
          />
        }
      />
    </>
  );
};

export default SocialShareSection;

import React, { useMemo, useState } from 'react';
import { useViewport } from 'use-viewport';

import Accordion from 'common/components/Accordion/Accordion';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import ArrowToDown from 'common/icons/ArrowToDown.icon';
import PageContainer from 'common/layout/PageContainer';
import TextContentContainer from 'common/components/TextContentContainer/TextContentContainer';
import theme from 'theme/theme';

import Banner from '../assets/FAQ.jpg';

import { ContentButton } from './styled/common.styled';

import {
  FirstButton, LeftMarginButton
} from './styled/TellMeMore.styled';


import {
  Wrapper,
  CustomSpacer,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  LoadMoreButton,
  StrapLineTypography,
  StrapLineTypographyContainer,
  LoadMoreButtonContent,
  ButtonsContainer,
} from './styled/CommonQuestions.styled';

import { footerHeight, footerMobileHeight, xlg } from 'utils/constants';

import MOCK_COMMON_QUESTIONS_LIST from '../MOCK_COMMON_QUESTIONS_LIST';
import { useNavigate } from 'react-router-dom';

const CommonQuestions = () => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [isLoadMoreTouched, setIsLoadMoreTouched] = useState<boolean>(false);
  const [itemsLimit, setItemsLimit] = useState<number>(3);

  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  const loadMoreHandler = () => {
    setIsLoadMoreTouched(true);

    if (MOCK_COMMON_QUESTIONS_LIST.length <= itemsLimit) {
      setIsLoadMore(true);
    }
    if (MOCK_COMMON_QUESTIONS_LIST.length > itemsLimit) {
      setItemsLimit(itemsLimit + 3);
    }
  };

  /*const buttonsHeight = useMemo(
    () => (viewport.width < xlg ? 45 : 55),
    [viewport.width],
  );*/

  const spacerHeight = useMemo(
    () => (viewport.width < xlg ? 67 : 0),
    [viewport.width],
  );

  return (
    <PageContainer pageTitle='FAQ | SongBits'
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        <Image src={Banner} />
        <ImageGradient banner={Banner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          {spacerHeight ? <CustomSpacer height="67" /> : <></>}

          <HeadingSection>
            <StrapLineTypographyContainer>
              <StrapLineTypography
                text="i have a "
                fontSize="fz100"
                fontWeight="bold"
                letterSpacing="-0.05em"
              />
              <div style={{ display: 'flex' }}>
                <StrapLineTypography
                  text="question"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                  letterSpacing="-0.05em"
                />
                &nbsp;
                <StrapLineTypography
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                  letterSpacing="0.035em"
                />
                <StrapLineTypography
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                  letterSpacing="0.035em"
                />
                <StrapLineTypography
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  letterSpacing="0.035em"
                />
              </div>
            </StrapLineTypographyContainer>
            <TextContentContainer
              altText="This is radical. Naturally you have some questions. We’ll try to answer them here or contact our customer support"
              height={!isLoadMore ? '270px' : '270px'}
              useSpacer={false}>
              <Spacer height={10} />

              <Accordion
                data={MOCK_COMMON_QUESTIONS_LIST.slice(0, itemsLimit)}
              />
            </TextContentContainer>

            {!isLoadMore && (
              <LoadMoreButton
                label={
                  <LoadMoreButtonContent
                    paddingLeft={isLoadMoreTouched ? '10px' : '0px'}>
                    <ArrowToDown />
                    <Typography
                      text="load more"
                      fontSize="fz18"
                      fontColor={theme.colors.white}
                      fontWeight="regular"
                    />
                  </LoadMoreButtonContent>
                }
                onClick={loadMoreHandler}
              />
            )}
          </HeadingSection>
        </HeadContainer>
        <ButtonsContainer>
          <FirstButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="show me" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <ContentButton
                  text="why"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/why');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <ContentButton
                text="show me who"
                fontSize="fz16"
                fontWeight="bold"
              />
            }
            onClick={() => {
              navigateToHandler('/who');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="show me" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <ContentButton
                  text="how"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/how');
            }}
          />
        </ButtonsContainer>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(CommonQuestions);

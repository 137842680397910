import React, { FC, memo, useCallback, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import AddCardModal from 'modules/account/pages/components/Modals/AddCardModal';
import { CardSchema, PaymentModalType } from '../types';
import { useViewport } from 'use-viewport';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType } from 'store-persist/reducers/modalSlice';

import {
  Elements,
  ElementsConsumer
} from '@stripe/react-stripe-js';
import {
  loadStripe
} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY_GB || "");

interface PaymentModalProps {
  isOpen: boolean;
  type: PaymentModalType;
  changePaymentMethod?: () => void;
  onClose: () => void;
  onCardAdded: (card: CardSchema) => void;
  fromMyWallet?: boolean;
  cardId?: string;
  isFromPayment?: boolean;
}

const AddCard: FC<PaymentModalProps> = ({
  isOpen,
  type,
  onClose,
  onCardAdded,
  changePaymentMethod,
  fromMyWallet = false,
  cardId,
  isFromPayment = false,
}) => {
  const viewport = useViewport();
  const dispatch = useAppDispatch();
  const getTopPosition = useCallback(() => {
    if (
      viewport.width >= 1024 &&
      viewport.width < 1200 &&
      viewport.width > viewport.height
    ) {
      return '0%';
    } else {
      return '5%';
    }
  }, [viewport.width, viewport.height]);

  const getHeight = useCallback(() => {
    if (viewport.height < 850) {
      return '85%';
    } else {
      return '780px';
    }
  }, [viewport.width, viewport.height]);

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag('event', 'purchase_add_card', {});
      if (type === PaymentModalType.Quickflow) {
        dispatch(setModalType('AddCard'));
      }
    }
  }, [isOpen]);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <SBModal
          className={'add-card'}
            isOpen={isOpen}
            width="455px"
            mobileWidth="100%"
            height={getHeight()}
            top={getTopPosition()}
            mobileHeight="90%"
            mobileTop="2%"
            overflow={'scroll'}
            content={
              <AddCardModal
                stripe={stripe} elements={elements}
                type={type}
                changePaymentMethod={() => {
                  if (changePaymentMethod) {
                    changePaymentMethod();
                  }
                }}
                onChange={onClose}
                onCardAdded={onCardAdded}
                fromMyWallet={fromMyWallet}
                cardId={cardId}
                isFromPayment={isFromPayment}
              />
            }
          />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default memo(AddCard);

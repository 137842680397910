import Spacer from 'common/components/Spacer/Spacer';
import { Container } from 'modules/account/pages/RoyaltyOverview';

import React from 'react';

const RoyaltyDashboardOverview = () => {
  return (
    <Container>
      <span
        className={
          'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-7'
        }>
        &nbsp;
      </span>

      <Spacer height={5} />
      <span
        className={
          'placeholder-wave placeholder fs-24 lh-18 bg-secondary col-6'
        }>
        &nbsp;
      </span>

      <Spacer height={35} />
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '350px',
            gap: '10px',
            position: 'relative',
            marginBottom: '20px',
          }}>
          <span
            className={
              'placeholder-wave placeholder fs-60 lh-80 bg-secondary col-5'
            }>
            &nbsp;
          </span>
          <span
            className={
              'placeholder-wave placeholder fs-24 lh-24 bg-secondary col-5'
            }>
            &nbsp;
          </span>
          <span
            className={
              'placeholder-wave placeholder fs-14 lh-14 bg-secondary col-5'
            }>
            &nbsp;
          </span>

          <span
            className={
              'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-8'
            }>
            &nbsp;
          </span>
          <div
            style={{
              position: 'absolute',
              right: '20px',
              top: '10px',
              width: '50px',
              height: '50px',
            }}>
            <span
              className={
                'placeholder-wave placeholder fs-14 lh-20 bg-secondary col-5'
              }>
              &nbsp;
            </span>
          </div>
          <Spacer height={0} />
          <span
            style={{ minHeight: '2px', maxHeight: '2px' }}
            className={'placeholder-wave placeholder fs-1 bg-secondary col-12'}>
            &nbsp;
          </span>
        </div>
      ))}
    </Container>
  );
};

export default RoyaltyDashboardOverview;

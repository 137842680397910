import * as React from 'react';

import { IconProps } from '../common.types';

const VideoMessageIcon = ({ width = 29, height = 27 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 0C6.37962 0 0 5.319 0 11.88C0 15.5174 1.96576 18.7688 5.05473 20.9482C5.1336 22.6071 4.87271 24.8976 2.54984 25.9537C2.54831 25.9544 2.5471 25.9551 2.54556 25.9558C2.42615 26.001 2.32653 26.0859 2.26395 26.1958C2.20138 26.3057 2.17978 26.4337 2.2029 26.5576C2.22603 26.6816 2.29242 26.7936 2.39058 26.8744C2.48874 26.9552 2.61248 26.9996 2.74038 27C2.76472 27.0002 2.78903 26.9988 2.81318 26.9958C5.50048 26.9695 7.7779 25.5232 9.33658 24.0089C9.79586 23.5629 10.4414 23.3416 11.075 23.4626C12.0955 23.657 13.1582 23.76 14.25 23.76C22.1204 23.76 28.5 18.441 28.5 11.88C28.5 5.319 22.1204 0 14.25 0ZM8.76923 7.56H16.4423C17.0474 7.56 17.5385 8.04384 17.5385 8.63999V15.12C17.5385 15.7162 17.0474 16.2 16.4423 16.2H8.76923C8.16415 16.2 7.67308 15.7162 7.67308 15.12V8.63999C7.67308 8.04384 8.16415 7.56 8.76923 7.56ZM22.3641 8.65054C22.4682 8.63016 22.579 8.63795 22.681 8.68007C22.8859 8.76323 23.0192 8.96183 23.0192 9.17999V14.58C23.0192 14.7982 22.8859 14.9968 22.681 15.0799C22.6141 15.108 22.5413 15.12 22.4712 15.12C22.3287 15.12 22.1889 15.0644 22.0836 14.9618L19.8913 12.8018C19.7894 12.7003 19.7308 12.5636 19.7308 12.42V11.34C19.7308 11.1964 19.7894 11.0597 19.8913 10.9582L22.0836 8.7982C22.1626 8.72098 22.26 8.67093 22.3641 8.65054Z"
      fill="white"
    />
  </svg>
);

export default VideoMessageIcon;


import React from 'react';

import { IconProps } from '../common.types';

const InstagramSmallWhite = ({ width = 22, height = 18 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.99775 5.99872C7.34513 5.99872 5.99647 7.34738 5.99647 9C5.99647 10.6526 7.34513 12.0013 8.99775 12.0013C10.6504 12.0013 11.999 10.6526 11.999 9C11.999 7.34738 10.6504 5.99872 8.99775 5.99872ZM17.9993 9C17.9993 7.75716 18.0106 6.52558 17.9408 5.28499C17.871 3.84402 17.5423 2.56515 16.4886 1.51144C15.4326 0.455477 14.156 0.129006 12.715 0.0592092C11.4722 -0.0105879 10.2406 0.000669702 9 0.000669702C7.75716 0.000669702 6.52558 -0.0105879 5.28499 0.0592092C3.84402 0.129006 2.56515 0.457728 1.51144 1.51144C0.455477 2.5674 0.129006 3.84402 0.0592092 5.28499C-0.0105879 6.52783 0.000669702 7.75941 0.000669702 9C0.000669702 10.2406 -0.0105879 11.4744 0.0592092 12.715C0.129006 14.156 0.457729 15.4348 1.51144 16.4886C2.5674 17.5445 3.84402 17.871 5.28499 17.9408C6.52783 18.0106 7.75941 17.9993 9 17.9993C10.2428 17.9993 11.4744 18.0106 12.715 17.9408C14.156 17.871 15.4348 17.5423 16.4886 16.4886C17.5445 15.4326 17.871 14.156 17.9408 12.715C18.0128 11.4744 17.9993 10.2428 17.9993 9ZM8.99775 13.6179C6.44227 13.6179 4.37988 11.5555 4.37988 9C4.37988 6.44452 6.44227 4.38213 8.99775 4.38213C11.5532 4.38213 13.6156 6.44452 13.6156 9C13.6156 11.5555 11.5532 13.6179 8.99775 13.6179ZM13.8047 5.27148C13.2081 5.27148 12.7263 4.78966 12.7263 4.193C12.7263 3.59635 13.2081 3.11452 13.8047 3.11452C14.4014 3.11452 14.8832 3.59635 14.8832 4.193C14.8834 4.33468 14.8556 4.475 14.8015 4.60593C14.7474 4.73686 14.6679 4.85582 14.5677 4.956C14.4676 5.05618 14.3486 5.13561 14.2177 5.18975C14.0867 5.24388 13.9464 5.27166 13.8047 5.27148Z" fill="white" />
  </svg>
);

export default InstagramSmallWhite;

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import BottomLine from 'common/components/BottomLine/BottomLine';
import theme from 'theme/theme';

import { ILocationsData } from 'services/types';

interface TopLocationsProps {
  name: string;
  currentDrop?: {
    label: string;
    value: number | string;
  };
  locations: ILocationsData[];
}

const RegionsStatistics: React.FC<TopLocationsProps> = ({
  locations,
  name,
  currentDrop,
}) => {
  const navigate = useNavigate();

  const [locationsByContinent, setLocationsByContinent] = useState<
    ILocationsData[]
  >([]);
  const [pairedLocations, setPairedLocations] = useState<ILocationsData[][]>(
    [],
  );

  useEffect(() => {
    const filteredLocations = locations.filter(
      (location) => location.continent_name === name,
    );

    setLocationsByContinent(filteredLocations);
  }, [locations, name]);

  useEffect(() => {
    const tempPaired = [];

    let pair: ILocationsData[] = [];
    for (const location of locationsByContinent) {
      pair.push(location);
      if (pair.length == 2) {
        tempPaired.push(pair);
        pair = [];
      }
    }

    if (pair.length > 0) tempPaired.push(pair);

    setPairedLocations(tempPaired);
  }, [locationsByContinent]);

  const allUsersAmount = useMemo(
    () =>
      locationsByContinent.reduce((acc, curr) => acc + Number(curr.amount), 0),
    [locationsByContinent],
  );

  const handleCountryClick = (location: string) => {
    const queryParam: { [x: string]: any } = {
      country: location,
    };

    if (currentDrop && currentDrop?.value !== 'all') {
      queryParam.release = currentDrop.value;
    }
    navigate(`/artist/insights/users`, {
      state: queryParam,
    });
  };

  if (!name) return null;

  return (
    <Wrapper>
      <Typography
        text={name}
        fontWeight="bold"
        fontSize="fz24"
        letterSpacing="-0.03em"
      />

      <Spacer height={25} />

      <Typography
        text={`${allUsersAmount} users in this continent`}
        fontWeight="light"
        fontSize="fz18"
        letterSpacing="-0.03em"
      />

      <LocationsContainer>
        {pairedLocations.map(([location1, location2], index, arr) => (
          <>
            <LocationContainer key={`Region location ${index}`}>
              <ButtonWrapper onClick={() => handleCountryClick(location1.name)}>
                <Location key={`Region location ${index}_${location1.name}`}>
                  <img
                    src={location1.flag}
                    alt={location1.name}
                    width={30}
                    height={20}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Typography text={<>{location1.name}</>} />
                  &nbsp;
                  <Typography
                    text={`(${location1.amount})`}
                    fontSize={'fz10'}
                    fontWeight="light"
                  />
                </Location>
              </ButtonWrapper>

              {location2 && (
                <ButtonWrapper
                  onClick={() => handleCountryClick(location2.name)}>
                  <Location>
                    <img
                      src={location2.flag}
                      alt={location2.name}
                      width={30}
                      height={20}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Typography text={<>{location2.name}</>} />
                    &nbsp;
                    <Typography
                      text={`(${location2.amount})`}
                      fontSize={'fz10'}
                      fontWeight="light"
                    />
                  </Location>
                </ButtonWrapper>
              )}
            </LocationContainer>

            {arr.length - 1 !== index && (
              <BottomLine key={`region bottom line ${index}`} />
            )}
          </>
        ))}
      </LocationsContainer>

      <LinkButton
        onClick={() => {
          const currentContinent = locations.find(
            (item) => item.continent_name === name,
          );

          navigate('/artist/insights/countries', {
            state: {
              region: currentContinent?.continent_id,
            },
          });
        }}>
        <Typography
          text="List all countries"
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.yellow}
        />
      </LinkButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 34px;
`;

const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;

  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const LinkButton = styled.div`
  cursor: pointer;
`;

const ButtonWrapper = styled.button`
  display: block;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  @media (min-width: 576px) {
    display: initial;
  }
`;

export default React.memo(RegionsStatistics);

const MOCK_SONG_DATA = {
  artist: 'Dave Stewart',
  song: 'Sweet Dreams',
  styles: ['Rock', 'Indie'],
  date: 'Jan 1982',
  streams: 1.2,
  timeRemaining: '3m 37s',
  bitsTotal: 217000,
  coastPerBit: 5,
  numberOfEditions: 1,
  percentOfSold: 0,
  numberOfOwners: 0,
  numberOfStreams: 928,
  roiPerBit: 1.28,
  perseusRoiPerBit: 126,
  secondSelected: 330,
  coastOfSelected: 10.28,
  DAI: 1,
  tokenID: 0x54654,
  contractAddress: 654988,
  token: 'USDC',
  tokenStandard: 'ERC721',
  release_year: 2001
};

export default MOCK_SONG_DATA;

import * as React from 'react';

import { IconProps } from '../common.types';

const MetaMaskOutline = ({ width = 50, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 59 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.0794 44.7461H32.518L33.6217 49.9375H28.5518"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.7269 39.8134L39.1384 40.1371L32.518 44.7463L33.1018 17.0303L36.7365 8.00957L54.3904 1L57.2468 9.69698L54.6504 19.9521M56.7269 39.8134L53.2229 27.6336M56.7269 39.8134L53.5467 52.7288L40.5659 48.7049L33.6217 55H29.0793M54.6504 19.9521L54.9103 25.1436L53.2229 27.6336M54.6504 19.9521L56.9215 19.563M53.2229 27.6336L40.8258 23.9761M56.9215 22.4179L54.7872 22.6778M32.8312 29.8911L44.9151 29.622"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.829 39.9958L40.5658 48.7049M40.5658 48.7049L39.1383 40.1372L44.9151 29.622L40.8258 23.9761L33.1017 17.0303L54.3904 1M40.5658 48.7049L32.5179 44.7464"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7365 8.00928H29.0794M29.1675 44.7461H25.7289L24.6252 49.9375H28.5519M34.3681 36.6085L39.2114 34.8071L35.7545 33.0057L34.3681 36.6085Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5199 39.8134L19.1084 40.1371L25.7288 44.7463L25.1451 17.0303L21.5103 8.00957L3.85643 1L1 9.69698L3.59648 19.9521M1.5199 39.8134L5.02393 27.6336M1.5199 39.8134L4.70013 52.7288L17.681 48.7049L24.6252 55H29.1675M3.59648 19.9521L3.33652 25.1436L5.02393 27.6336M3.59648 19.9521L1.3238 19.563M5.02393 27.6336L17.421 23.9761M1.3238 22.4179L3.45966 22.6778M25.4157 29.8911L13.3317 29.622"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4179 39.9958L17.681 48.7049M17.681 48.7049L19.1085 40.1372L13.3318 29.622L17.4211 23.9761L25.1451 17.0303L3.85648 1M17.681 48.7049L25.7289 44.7464"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5104 8.00928H29.1675M23.8788 36.6085L19.0355 34.8071L22.4924 33.0057L23.8788 36.6085Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MetaMaskOutline;

import * as React from 'react';

import { IconProps } from '../common.types';

const CheckboxEmpty = ({ width = 24, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill="none" stroke="white"/>
  </svg>
);

export default CheckboxEmpty;

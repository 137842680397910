import * as React from 'react';

import { IconProps } from '../common.types';

const SocialMedia = ({ width = 52, height = 52 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0215 52.0431C40.3928 52.0431 52.0431 40.3928 52.0431 26.0215C52.0431 11.6502 40.3928 0 26.0215 0C11.6502 0 0 11.6502 0 26.0215C0 40.3928 11.6502 52.0431 26.0215 52.0431Z"
      fill="#FFD600"
    />
    <path
      d="M34.9062 13.0001C34.6209 13.0046 34.3491 13.1226 34.1503 13.3283C33.9516 13.5339 33.8422 13.8104 33.8461 14.097V14.5345L12.3078 23.2946V28.7049L17.3411 30.7507L16.9604 31.6553C16.269 33.2945 17.0442 35.2153 18.6746 35.9096C18.6753 35.9096 18.676 35.9096 18.6767 35.9096L21.2386 37.0001C22.87 37.6948 24.7838 36.9138 25.4747 35.2755L25.9122 34.2379L33.8461 37.465V37.9025C33.844 38.0459 33.8704 38.1883 33.9236 38.3213C33.9768 38.4544 34.0558 38.5755 34.156 38.6776C34.2562 38.7797 34.3756 38.8608 34.5073 38.9162C34.639 38.9715 34.7803 39 34.923 39C35.0657 39 35.207 38.9715 35.3387 38.9162C35.4703 38.8608 35.5897 38.7797 35.6899 38.6776C35.7901 38.5755 35.8691 38.4544 35.9223 38.3213C35.9755 38.1883 36.0019 38.0459 35.9999 37.9025V14.097C36.0019 13.9522 35.9749 13.8085 35.9206 13.6744C35.8663 13.5403 35.7858 13.4185 35.6838 13.3162C35.5818 13.2139 35.4604 13.1333 35.3269 13.079C35.1933 13.0247 35.0502 12.9979 34.9062 13.0001ZM9.0602 22.7388C8.77495 22.7432 8.50312 22.8612 8.30438 23.0669C8.10563 23.2725 7.9962 23.549 8.00011 23.8356V28.1639C7.99809 28.3073 8.02445 28.4496 8.07767 28.5827C8.13088 28.7158 8.20989 28.8369 8.31009 28.939C8.41029 29.0411 8.52969 29.1222 8.66135 29.1775C8.79301 29.2329 8.9343 29.2614 9.07702 29.2614C9.21974 29.2614 9.36104 29.2329 9.4927 29.1775C9.62436 29.1222 9.74376 29.0411 9.84396 28.939C9.94416 28.8369 10.0232 28.7158 10.0764 28.5827C10.1296 28.4496 10.156 28.3073 10.1539 28.1639V23.8356C10.1559 23.6908 10.129 23.5472 10.0747 23.413C10.0204 23.2789 9.93984 23.1571 9.83785 23.0548C9.73587 22.9526 9.61449 22.8719 9.48091 22.8176C9.34733 22.7633 9.20427 22.7365 9.0602 22.7388ZM19.3372 31.5644L23.914 33.4263L23.4913 34.4323C23.2539 34.995 22.6389 35.2452 22.0799 35.0071L19.5159 33.9166C18.9559 33.6781 18.7069 33.0602 18.9438 32.4985L19.3372 31.5644Z"
      fill="black"
    />
  </svg>
);

export default SocialMedia;

import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import { md, sm, xlg, xs } from 'utils/constants';

export const HeadingSection = styled.div`
  @media (min-width: ${sm}px) {
    min-width: 700px;
  }

  @media (min-width: ${md}px) {
    margin-left: 130px;
  }
`;

export const HeadContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 110px 34px 0 34px;
  z-index: 1;

  @media (min-width: ${xs}px) {
    padding: 100px 48px 0 40px;
  }

  @media (min-width: ${sm}px) {
    padding: 100px 48px 0 120px;
  }

  @media (min-width: ${md}px) {
    padding: 148px 0 0 65px;
  }

  @media (min-width: ${xlg}px) {
    padding-top: 0;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 60%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 55%;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const FirstButton = styled(Button)`
  font-family: 'HKGrotesk-Black';
  padding: 0 40px;
  margin: 38px auto 70px auto;

  @media (min-width: 768px) {
    margin: 45px 0 70px 0;
  }
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
}>`
  line-height: 24px;
  letter-spacing: -0.03em;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export const TextConfirm = styled(TextContent)`
  letter-spacing: -0.04em;
  margin-top: 20px;
  margin-left: 5px;

  @media (min-width: ${md}px) {
    letter-spacing: -0.03em;
  }
`;

export const TextContentConfirm = styled(TextContent)`
  font-family: 'HKGrotesk-Black';

  @media (min-width: ${sm}px) {
    font-size: 24px;
  }

  @media (min-width: ${md}px) {
    padding-left: 4px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 5px;
  left: 0;

  @media (min-width: ${md}px) {
    left: 10px;
  }
`;

export const ChangeEmailButton = styled(Button)`
  padding: 0;
  margin-top: 19px;

  &:hover {
    text-decoration: none;
    background-color: transparent;
  }
`;

export const StrapLineTypography = styled(Typography)`
  flex-shrink: 0;
  font-family: 'HKGrotesk-Black';
  font-size: 60px;
  line-height: 40px;

  display: inline;

  @media (min-width: 992px) {
    font-size: 100px;
    line-height: 80px;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  margin-bottom: 45px;
  display: inline-block;
  width: 100%;

  @media (min-width: ${sm}px) {
    display: flex;
  }
`;

export const HeadContent = styled(StrapLineTypography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.06em;
  flex-shrink: 0;

  width: fit-content;
  margin: 0;

  @media (min-width: ${sm}px) {
    letter-spacing: -0.05em;
  }
`;

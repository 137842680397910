import store from 'store/store';
import restService from "./rest.service";


//import Cookies from 'universal-cookie';
//const cookies = new Cookies(null, { path: '/' });

class PassKeyService {
  constructor() {}
  buffer = (obj: Record<string, ArrayBuffer>, key: string) => {
    try {
      return JSON.parse(new TextDecoder("utf-8").decode(obj[key]));
    } catch(e) {
      console.error("BUFFER ERROR", key, obj, e);
    }
  }
  generateNonce = async () => {
    const nonce = await restService.generatePasskeyNonce();
    store.getActions().sso.setNonce(nonce);
  }
  createPasskeyCredential = async () => {
    await this.generateNonce();
    const challengeBufferString = store.getState().sso.nonce;
    const userId = store.getState().authentication.auth.tokenData.user_id || '';
    const username = store.getState().authentication.auth.tokenData.username || '';
    const challengeBuffer = Uint8Array.from(
      challengeBufferString as string,
      (c) => c.charCodeAt(0)
    );
    const userIdBuffer = Uint8Array.from(userId, (c) => c.charCodeAt(0));
    return await navigator.credentials.create({
      publicKey: {
        challenge: challengeBuffer,
        rp: {
          name: process.env.REACT_APP_RP_NAME as string,
          id: process.env.REACT_APP_RP_DOMAIN,
        },
        user: {
          id: userIdBuffer,
          name: username,
          displayName: username,
        },
        // SUPPORT ALL PASSKEYS
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7,
          },
          {
            type: "public-key",
            alg: -35,
          },
          {
            type: "public-key",
            alg: -36,
          },
          {
            type: "public-key",
            alg: -257,
          },
          {
            type: "public-key",
            alg: -258,
          },
          {
            type: "public-key",
            alg: -259,
          },
          {
            type: "public-key",
            alg: -37,
          },
          {
            type: "public-key",
            alg: -38,
          },
          {
            type: "public-key",
            alg: -39,
          },
          {
            type: "public-key",
            alg: -8,
          },
        ],
        timeout: 15000,
        attestation: "direct",
      },
    }).then((publicKeyCredential: any) => {
      const response = this.buffer(publicKeyCredential.response, 'clientDataJSON');
      return response;
    });
  }
  getPasskeyCredentialOptions = () => {
    const challenge = store.getState().sso.nonce;
      if (challenge !== null) {
      const challengeBuffer = Uint8Array.from(challenge, (c) => c.charCodeAt(0));
      const publicKeyCredentialRequestOptions: PublicKeyCredentialRequestOptions = {
        challenge: challengeBuffer,
        rpId: process.env.REACT_APP_RP_DOMAIN,
        userVerification: "required",
        timeout: 60000,
      };
      return publicKeyCredentialRequestOptions;
    }
  }
  createPasskey = async () => {
    try {
      const credential = await this.createPasskeyCredential();
      await restService.validatePasskeyCreation(credential);
    } catch (error) {
      console.error(error);
      throw new Error("Error creating credential");
    }
    // after create, nonce is saved to local storage
    // on a new device, nonce will not be in local storage, password login required, then need to link chosen passkey to account??
  }
  verifyPasskey = async () => {
    try {
      const credentialOptions = this.getPasskeyCredentialOptions();
      return await navigator.credentials.get({
        publicKey: credentialOptions,
      }).then(async (credential: any) => {
        //console.log("RETRIVED CREDENTIAL", credential)
        const data = this.buffer(credential.response, 'clientDataJSON');
        return await restService.validatePasskeyAuthoriaztion(data);
      });
    } catch (error) {
      console.error(error);
      throw new Error("Error verifying credential");
    }
  }
}

const passkeyService = new PassKeyService;

export default passkeyService;
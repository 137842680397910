import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import User from 'common/icons/User.icon';
import theme from 'theme/theme';
import { escToClose, getUserIdFromJWT } from 'utils/functions';
import { CHANGE_USERNAME } from 'modules/auth/graphql/Mutations.graphql';
import {
  CHANGE_USERNAME_MUTATION,
  CHANGE_USERNAME_VARIABLES,
} from 'modules/auth/types';

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
  .min(6, 'Username too short. must be 6 chars')
  .required('Username is required')
  .matches(
    /^[a-zA-Z0-9]+$/,
    'Username cannot contain white space or special characters.',
  ),
});

interface ModalProps {
  username: string;
  onClose: () => void;
  onChange: () => void;
}

const ChangeUsernameModal: FC<ModalProps> = ({ username, onChange, onClose }) => {
  const id = getUserIdFromJWT();
  const viewport = useViewport();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    escToClose(onChange);
  }, []);


  async function submitHandler() {
    setLoading(true)

    try {
      const response = await changeUsername();
      //console.log('response')
      //console.log(response)
      if (!response.errors) {
        if (response.data) {
          onChange();
          setLoading(false)
        }
      }
    } catch (e: any) {
      e
      //console.log(e)
      if (e.message.includes('users_username_key')) {
        errors.username = 'Username is already taken. Please choose another.'
      } else {
        errors.username = 'Sorry there was a problem. Please try again.'
      }
      setLoading(false)
      
    }
  }

  const initialValues = {
    username: username,
  };

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [changeUsername] = useMutation<
    CHANGE_USERNAME_MUTATION,
    CHANGE_USERNAME_VARIABLES
  >(CHANGE_USERNAME, {
    variables: {
      userId: id,
      username: values.username,
    },
  });

  return (
    <CustomComponentContainer >
      <CloseButton onClick={onClose}>X</CloseButton>
      <BoldText
        text="change username"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <RegularText
        fontSize={viewport.width >= 576 ? 'fz18' : 'fz14'}
        text={"Enter your new username below."}
      />
      <Spacer height={viewport.width >= 576 ? 40 : 10} />
      <InputContainer>
        <Icon>
          <User />
        </Icon>
        <InputWithIcon
          height={viewport.width >= 576 ? 70 : 50}
          type="text"
          withBottomLine
          value={values.username}
          placeholder="enter new username"
          onChange={handleChange('username')}
          autoComplete="username"
          inputName='username'
          small={true}
          error={
            Boolean(errors.username && touched.username) ? errors.username : undefined
          }
        />
      </InputContainer>
      <Spacer height={viewport.width >= 576 ? 65 : 50} />
      <SubmitButton
        className='change-username-submit-button'
        width={145}
        borderRadius={50}
        height={45}
        borderColor={theme.colors.white}
        isLoading={loading}
        label={
          <SubmitButtonContent>
            <BoldText
              text="submit"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.white}
            />
            <BoldText
              text="update"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          </SubmitButtonContent>
        }
        onClick={handleSubmit}
      />
      {/* <Spacer height={40} /> */}
    </CustomComponentContainer>
  );
};

const SubmitButton = styled(Button)`
  padding: 8px 33px;
  margin-left: 60px;
  width: auto;

  @media (min-width: 576px) {
    margin-left: 0;
  }
`;

const SubmitButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: 28px;
  left: 10px;

`;

const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
    margin-top: -7px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const RegularText = styled(Typography)`
  letter-spacing: -0.03em;
  line-height: 24px;
  white-space: pre;


`;

export default ChangeUsernameModal;

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'store/store';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  const isReady = useStoreState((state) => state.authentication.isReady);
  const logout = useStoreActions((actions) => actions.authentication.logout);

  const [searchParams] = useSearchParams();

  const checkAuth = async (auth: boolean) => {
    const jwt = searchParams.get('jwt');
    if (jwt) {
      //console.log("JWT FOUND", jwt);
      //await refreshToken({refreshToken: jwt})
    }
    if (!auth) {
      logout();
    }
  }

  useEffect(() => {
    //setTimeout(() => {
      //setReady(true);
    //}, 50);
    if (!isReady) return;
    if (!isAuth) {
      checkAuth(isAuth)
    }



    return () => {
      //setReady(false);
    };
  }, [isAuth, isReady]);

  return isAuth ? (
    children
  ) : (
    <></>
  );
};

import React, { FC } from 'react';

import { IconProps } from 'common/common.types';

const FilterIcon: FC<IconProps> = ({
  width = 23,
  height = 20,
  fill = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.77778 16.5111L10 17.6222V10.3C9.99877 10.0085 9.88305 9.72918 9.67778 9.52222L2.47778 2.22222H19.7667L12.5778 9.52222C12.3725 9.72918 12.2568 10.0085 12.2556 10.3L12.2222 18.8889L14.4444 20V10.7556L21.7556 3.33333C22.048 3.03663 22.2151 2.63878 22.2222 2.22222V1.11111C22.2222 0.816426 22.1052 0.533811 21.8968 0.325437C21.6884 0.117063 21.4058 0 21.1111 0H1.11111C0.816426 0 0.533811 0.117063 0.325437 0.325437C0.117063 0.533811 0 0.816426 0 1.11111V2.22222C0.00710834 2.63878 0.174206 3.03663 0.466667 3.33333L7.77778 10.7556V16.5111Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilterIcon;

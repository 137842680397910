import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import theme from 'theme/theme';

import { sm, xs } from 'utils/constants';

export const Container = styled.div<{
  transparent?: boolean;
  fcaBanner?: boolean;
  display?: string;
}>`
  background-color: ${(props) =>
    !props.transparent ? theme.colors.black : 'rgba(0,0,0,0)'};

  justify-content: center;

  position: fixed;

  padding: 20px 20px 0 22px;

  width: 100%;
  height: 64px;

  display: ${(props) => (props.display ? props.display : 'flex')};
  align-items: center;

  transition: box-shadow 0.3s ease-in-out;
  transition: background-color 0.4s ease-in-out;

  z-index: 2;

  &.active {
    box-shadow: 0px 5px 70px 50px rgb(0 0 0 / 100%);
    transition: box-shadow 0.3s ease-in-out;
  }

  ${(props) => (props.fcaBanner ? 'top: 70px;' : '')}

  @media (min-width: ${sm}px) {
    height: 100px;
    padding: 48px 48px 28px 48px;
    ${(props) => (props.fcaBanner ? 'top: 20px;' : '')}
    
  }
`;

export const InfoSection = styled.div<{
  isArtistPage?: boolean;
}>`
  display: flex;
  align-items: center;

  position: absolute;
  left: 15px;
  top: 25px;

  @media (min-width: ${xs}px) {
    margin-left: 0px;
    z-index: 10;
  }

  @media (min-width: ${sm}px) {
    margin-top: 0;

    left: 48px;
    top: 48px;
  }
`;

export const LogoWrapper = styled.div<{ isAuth: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const ProfileIconContainer = styled.div`
  margin-right: 5px;

  @media (min-width: ${xs}px) {
    display: inline;
  }
`;

export const RouteButton = styled(Button)<{ bold?: boolean }>`
  font-family: ${(props) =>
    props.bold ? 'HKGrotesk-Black' : 'HKGrotesk-Light'};
  text-transform: capitalize;
  font-size: 12px;
  cursor: pointer;
  z-index: 5;

  padding-left: 0;
  padding-right: 0;

  :disabled {
    color: white;
    opacity: 1;
  }
`;

export const LogoButton = styled(Button)`
  :hover {
    background: transparent;
  }
`;

export const MenuButton = styled(Button)<{ showHamburger: boolean }>`
  // ${(props) => (props.showHamburger ? 'display: block;' : 'display: none;')}

  display: block;

  position: absolute;
  top: 25px;
  right: 19px;

  transform: translate(5px, 2px);
  z-index: 15;

  @media (min-width: ${sm}px) {
    top: 48px;
    right: 48px;
  }
`;

export const AccountButtons = styled.div<{
  showButtons?: boolean;
}>`
  ${(props) => (props.showButtons ? 'display: none;' : 'display: flex')};

  @media (min-width: ${sm}px) {
    display: flex;
    align-items: inherit;
  }
`;
export const ProfileIconButton = styled(Button)``;

export const BackButton = styled(Button)`
  z-index: 2;

  &:focus {
    background-color: ${theme.colors.black};
  }

  &:focus-visible {
    background-color: ${theme.colors.black};
  }

  &:active {
    background-color: ${theme.colors.black};
  }

  &:target {
    background-color: ${theme.colors.black};
  }

  &:hover {
    background-color: ${theme.colors.black};
  }
`;

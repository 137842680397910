import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

type TablePlaceholderProps = {
  withTitle: boolean;
  withHeadings?: boolean;
  withUserTableHeader?: boolean;
  isUserList?: boolean;
  isTableExpanded?: boolean;
  numHeadings?: number;
  numRows: number;
};

const TablePlaceholder: FC<TablePlaceholderProps> = ({
  withTitle = false,
  withHeadings = false,
  withUserTableHeader = false,
  isUserList = false,
  isTableExpanded = false,
  numHeadings = 3,
  numRows = 10,
}) => {
  const viewport = useViewport();

  return (
    <>
      {withTitle && (
        <span
          style={{ marginBottom: '25px' }}
          className={`placeholder-wave placeholder fs-18 lh-26 bg-secondary ${
            isUserList ? (!isTableExpanded ? 'col-3' : 'col-2') : 'col-5'
          }`}>
          &nbsp;
        </span>
      )}
      {withUserTableHeader && (
        <Container
          direction="row"
          style={{ justifyContent: 'space-between', paddingBottom: '35px' }}>
          <span
            className={`placeholder-wave placeholder fs-18 lh-24 bg-secondary ${
              isTableExpanded ? 'col-3' : 'col-5'
            }`}>
            &nbsp;
          </span>

          {viewport.width > 576 && (
            <span
              className={`placeholder-wave placeholder fs-18 lh-24 bg-secondary ${
                isTableExpanded ? 'col-2' : 'col-3'
              }`}>
              &nbsp;
            </span>
          )}
        </Container>
      )}
      {withHeadings && (
        <Container direction="row" style={{ justifyContent: 'space-between' }}>
          {[...Array(numHeadings)].map((_, index) => (
            <span
              key={`table-ph-heading-${index}`}
              style={{ width: '20%' }}
              className={
                'placeholder-wave placeholder fs-18 lh-20 bg-secondary col-3'
              }>
              &nbsp;
            </span>
          ))}
        </Container>
      )}
      {isUserList && (
        <Container
          direction="row"
          style={{
            justifyContent: 'space-between',
            marginBottom: '20px',
            width: '80%',
          }}>
          {[...Array(viewport.width < 576 ? 1 : isTableExpanded ? 5 : 2)].map(
            (_, index) => (
              <span
                key={`table-ph-heading-${index}`}
                style={{ width: '20%' }}
                className={
                  'placeholder-wave placeholder fs-18 lh-20 bg-secondary col-3'
                }>
                &nbsp;
              </span>
            ),
          )}
        </Container>
      )}
      <Container direction="column" gap={5}>
        {[...Array(numRows)].map((_, index) => (
          <>
            <span
              key={`palceholder_1_${index}`}
              className={`placeholder-wave placeholder fs-18 bg-secondary col-12 ${
                isUserList ? 'lh-28' : 'lh-20'
              } `}>
              &nbsp;
            </span>

            <span
              key={`palceholder_2_${index}`}
              style={{ color: 'red' }}
              className={`placeholder-wave placeholder fs-02 bg-secondary col-12 lh-2`}>
              &nbsp;
            </span>
          </>
        ))}
      </Container>
    </>
  );
};

const Container = styled.div<{ direction: string; gap?: number }>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => (props.gap ? props.gap : 25)}px;
  width: auto;
`;

export default TablePlaceholder;

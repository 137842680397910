import React from 'react';
import styled from 'styled-components';

import Tip from 'common/components/Tip/Tip';
import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';
import theme from 'theme/theme';
import PoundSign from 'common/icons/PoundSign.icon';

import { sm, xs } from 'utils/constants';

import { useViewport } from 'use-viewport';

const QuestionInput = ({
  text,
  tip,
  fontWeight,
  value,
  errors,
  touched,
  setValue,
}: {
  text: string;
  tip?: string;
  fontWeight?: 'bold' | 'regular';
  value: string;
  errors: any;
  touched: any;
  setValue: (value: string) => void;
}) => {
  const viewport = useViewport();
  return (
    <div>
      <Container>
        <Typography
          text={text}
          fontSize={viewport.width >= sm ? 'fz16' : 'fz14'}
          fontWeight={fontWeight ?? 'regular'}
          lineHeight="20px"
          fontColor={theme.colors.white}
        />
        {tip && (
          <div style={{ marginTop: '3px' }}>
            <Tip
              width={200}
              position="left"
              text={
                <div>
                  <Typography
                    text={tip}
                    fontSize={'fz14'}
                    lineHeight={'18px'}
                  />
                </div>
              }
            />
          </div>
        )}
      </Container>

      <InputContainer>
        <Icon>
          <PoundSign />
        </Icon>
        <InputWithIcon
          value={value}
          inputName={'questionFour'}
          height={viewport.width < xs ? 50 : 50}
          type="text"
          onChange={(inputData) => {
            setValue(inputData.target.value);
          }}
          placeholder="20,000"
          inputMode="numeric"
          errorPoistionBottom={'-10px'}
          errorPoistionLeft={'10px'}
          error={
            Boolean(errors.questionFour && touched.questionFour)
              ? errors.questionFour
              : undefined
          }
        />
      </InputContainer>
    </div>
  );
};

const Container = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 16px;
  left: 8px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default QuestionInput;

import React, { useState } from 'react';

import styled from 'styled-components';
import ConnectWalletButton from 'common/components/WalletButton/ConnectWalletButton';

import ConnectWalletFlow from './Wallet/ConnectWallet';

const ConnectWalletOutgoing = ({ refresh }: {refresh: () => void}) => {
  const [showConnectWallet, setShowConnectWallet] = useState<boolean>(false);

  return (
    <>
      <USDCCryptoWrapper>
        <ContentContainer>
          <ConnectWalletButton onClickHandler={() => {
            setShowConnectWallet(true)
          }}></ConnectWalletButton>
        </ContentContainer>

      </USDCCryptoWrapper>
      <ConnectWalletFlow
        isOpen={showConnectWallet}
        onSuccess={refresh}
        onClose={() => {
          setShowConnectWallet(false);
        }}></ConnectWalletFlow>
    </>
  );
};

const ContentContainer = styled.div<{ first?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const USDCCryptoWrapper = styled.div`
  margin-bottom: 10px;
`;

export default ConnectWalletOutgoing;

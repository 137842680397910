import * as React from 'react';

import { IconProps } from '../common.types';

const OpenYellow = ({ width = 18, height = 15 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.341 9.41619L3.32715 19.2188"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6693 17.2563L12.3406 9.41616L4.50047 9.74479"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.63636 0C1.63352 0 0 1.62939 0 3.62717V17.229L1.81818 15.4155V3.62717C1.81818 2.62828 2.63494 1.81358 3.63636 1.81358H16.3636C17.3651 1.81358 18.1818 2.62828 18.1818 3.62717V18.1358C18.1818 19.1347 17.3651 19.9494 16.3636 19.9494H6.36364L4.54545 21.763H16.3636C18.3665 21.763 20 20.1336 20 18.1358V3.62717C20 1.62939 18.3665 0 16.3636 0H3.63636Z"
      fill="#FFD600"
    />
  </svg>
);

export default OpenYellow;

import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { sm } from 'utils/constants';

export const BorderContainer = styled.div<{ isHamburgerOpen: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 100vh;

  background: linear-gradient(#000000, #000000) 50% 50% / calc(100% - 20px)
      calc(100% - 20px) no-repeat,
    linear-gradient(240deg, #ffd600 0%, #000000 45%);
  border-radius: 0px;
  padding: 10px 10px 0 0;
  box-sizing: content-box;

  overflow: hidden;

  position: fixed;
  top: 0;
  left: calc(-100% - 20px);

  transform: ${(props) => props.isHamburgerOpen && `translate(${100 + '%'})`};
  transition: all 0.3s linear;

  z-index: 10;

  @media (min-width: ${sm}px) {
    width: 516px;
    height: calc(100% - 10px);

    left: -526px;

    transform: ${(props) => props.isHamburgerOpen && `translate(526px)`};
  }
`;

export const Container = styled.div<{ isHamburgerOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 20px 38px 50px 47px;

  overflow: ${(props) => (props.isHamburgerOpen ? 'hidden' : 'auto')};

  scrollbar-color: ${theme.colors.yellow} transparent;
  background: ${theme.colors.black};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.colors.yellow};
    opacity: 0.5;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      border: 1px solid #ffffff;
    }
  }
`;

export const ProfileIconContainer = styled.div`
  cursor: pointer;
`;

export const NavButton = styled(Button)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  :hover {
    background: transparent;
  }
`;

export const TypographyMenu = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 85px;

  @media (min-width: 768px) {
    font-size: 100px;
  }
`;

export const Item = styled(Typography)<{
  isFirstElement: boolean;
  isLastElement: boolean;
}>`
  cursor: pointer;
  width: 75%;
  text-align: left;
  text-transform: capitalize;
  line-height: 48px;
  border-style: solid;
  padding-left: 5px;
  border-image: linear-gradient(
      to right,
      ${theme.colors.yellow},
      ${theme.colors.yellow},
      ${theme.colors.transparent}
    )
    0 0 1 0;
  border-width: ${(props) => (props.isLastElement ? 0 : '0.7px')};

  ${(props) =>
    props.isFirstElement
      ? `color: ${theme.colors.yellow};`
      : `color: ${theme.colors.white};`}

  :hover {
    ${(props) =>
      props.isFirstElement
        ? `color: ${theme.colors.white};`
        : `color: ${theme.colors.yellow};`}
  }

  @media (min-width: 1200px) {
    border-image: linear-gradient(
        to right,
        ${theme.colors.yellow},
        ${theme.colors.yellow},
        ${theme.colors.transparent}
      )
      0 0 1 0;
    border-width: ${(props) => (props.isLastElement ? 0 : '0.5px')};
  }
`;

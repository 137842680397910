import {
  TypographyFontSizes,
  TypographyFontWeight,
  TypographyFontFamily,
} from 'common/common.types';
import { useStoreState } from 'store/store';

import theme from 'theme/theme';

export const getFontSizes = (size: keyof typeof TypographyFontSizes) =>
  theme.fontSizes[size];

export const getFontWeights = (weight: keyof typeof TypographyFontWeight) =>
  theme.fontWeight[weight];

export const getFontFamily = (size: keyof typeof TypographyFontFamily) =>
  theme.fontFamily[size];

export const getUserIdFromJWT = () => {
  const token = useStoreState((state) => state.authentication.auth.accessToken);
  return token && JSON.parse(atob(token.split('.')[1])).user_id;
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const escToClose = (callback: () => void) => {
  const closeModal = (e: KeyboardEvent) => {
    if (typeof callback !== 'undefined') {
      if (e.key === 'Escape') callback();
    }
  };
  window.addEventListener('keyup', closeModal);
  return () => window.removeEventListener('keyup', closeModal);
};

export const getTopPosition = (defaultPercentage: string) => {
  if (
    window.innerWidth >= 1024 &&
    window.innerWidth < 1200 &&
    window.innerWidth > window.innerHeight
  ) {
    return '3%';
  } else {
    if (!defaultPercentage.includes('%')) defaultPercentage += '%';
    return defaultPercentage;
  }
};

export const roundDown = (number: number, decimals: number) => {
  decimals = decimals || 0;
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
};


export const roundNumberDown = (input_number: string)  => {
  const number = parseFloat(input_number);

  if (number < 0.1) {
   if (number > 0) {
     return number.toFixed(3);
   } else {
     return number.toFixed(2);
   }
    
  } else {
    return roundDown(number, 2).toFixed(2);
  }
}

export const isObject = (value: any) => {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value)
  );
}

export const sleep = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const randomString = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890';
  const charactersLength = characters.length;
  let password = '';
  for (let i = 0; i < length; ++i) {
    password += characters[Math.floor(Math.random() * charactersLength)];
  }
  return password;
}
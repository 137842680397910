import * as React from 'react';

import { IconProps } from '../common.types';

const MailIcon = ({ width = 30, height = 19, fill = '#FFD600' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.6469 0L13.7256 11.106C14.4402 11.7647 15.5608 11.7647 16.2744 11.106L28.3531 0H1.6469ZM0 1.37305V19H30V1.37305L17.7372 12.6481C16.969 13.3543 15.9846 13.7078 15 13.7078C14.0154 13.7078 13.031 13.3543 12.2628 12.6481L0 1.37305Z"
      fill={fill}
    />
  </svg>
);

export default MailIcon;

import { gql } from '@apollo/client';

export const VIEW_TRACKING = gql`
  mutation createViewTracking(
    $type: TrackingType!
    $viewedItem: UUID!
    $userId: UUID
  ) {
    createViewTracking(
      input: {
        viewTracking: { type: $type, viewedItem: $viewedItem, userId: $userId }
      }
    ) {
      clientMutationId
    }
  }
`;

export const LIKE_USER = gql`
  mutation LikeUser($likedBy: UUID!, $likedUser: UUID!) {
    createUserlike(
      input: { userlike: { likedBy: $likedBy, likedUser: $likedUser } }
    ) {
      clientMutationId
      userlike {
        id
      }
    }
  }
`;

export const UNLIKE_USER = gql`
  mutation UnlikeUser($likeid: UUID!) {
    deleteUserlikeById(input: { id: $likeid }) {
      clientMutationId
    }
  }
`;

export const LIKE_SONG = gql`
  mutation LikeSong($userid: UUID!, $songid: UUID!) {
    createSonglike(
      input: { songlike: { likedBy: $userid, likedSong: $songid } }
    ) {
      songlike {
        id
      }
    }
  }
`;

export const UNLIKE_SONG = gql`
  mutation UnlikeSong($likeid: UUID!) {
    deleteSonglikeById(input: { id: $likeid }) {
      clientMutationId
      deletedSonglikeId
    }
  }
`;

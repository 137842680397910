import React, { FC } from 'react';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Apple from 'common/icons/Apple.icon';
import GoogleIcon from 'common/icons/Google.Icon';

interface ModalProps {
  onBack: () => void;
  isMobile?: boolean;
}

const TwoFaHelp: FC<ModalProps> = ({ onBack, isMobile = false }) => {
  return (
    <CustomComponentContainer>
      <Spacer height={10} />
      <RegularText
        fontSize="fz18"
        text="An authenticator app provides a way to secure your account by providing you with a unique access code. "
      />
      <Spacer height={30} />

      {isMobile ? (
        <>
          <InputContainer>
            <IconContainer>
              <Apple fill={theme.colors.white} />
            </IconContainer>
            <Link
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank">
              Download Google Authenticator from the App Store
            </Link>
          </InputContainer>

          <Spacer height={20} />
          <InputContainer>
            <IconContainer>
              <GoogleIcon />
            </IconContainer>
            <Link
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US"
              target="_blank">
              Download Google Authenticator from the Play Store
            </Link>
          </InputContainer>
        </>
      ) : (
        <RegularText text="Search ‘Google Authenticator’ on your app store if you need to install it on your device and follow the instruction to add your SongBits account." />
      )}

      <Spacer height={40} />

      <FirstButton
        height={45}
        width={226}
        borderRadius={50}
        borderColor={theme.colors.white}
        label={
          <div style={{ display: 'flex' }}>
            <ContentButton text="go" fontSize="fz16" fontWeight="bold" />
            &nbsp;
            <ContentButton
              text="back"
              fontSize="fz16"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
            />
          </div>
        }
        onClick={onBack}
      />
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  position: relative;

  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const RegularText = styled(Typography)`
  letter-spacing: -0.03em;
  line-height: 24px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const FirstButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: ${theme.colors.yellow};

  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export default TwoFaHelp;

import { action } from 'easy-peasy';

import { LocationModel } from './locationModel.types';

export const location: LocationModel = {
  navigateTo: null,
  navigateState: null,
  navigate: action((state, payload) => {
    if (typeof payload === "string") {
      state.navigateTo = payload;
    } else if (typeof payload === "object") {
      state.navigateTo = payload.url;
      state.navigateState = payload.state || null;
    }
  }),
  setNavigateState: action((state, payload) => {
    state.navigateState = payload;
  }),
  clearNavigate: action((state) => {
    state.navigateTo = null;
    state.navigateState = null;
  }),
  previousUrl: '',
  setPreviousUrl: action((state, payload) => {
    state.previousUrl = payload;
  }),
  breadcrumbs: [],
  setBreadcrumbs: action((state, payload) => {
    state.breadcrumbs = payload;
  }),
};

import styled from 'styled-components';
import theme from 'theme/theme';

import { xs } from 'utils/constants';

export const BreadcrumbDiv = styled.div<{
  bold?: boolean;
  isArtistPage?: boolean;
  isAuth?: boolean;
}>`
  ${(props) => props.isAuth && !props.isArtistPage && 'display: none;'};
  font-family: ${(props) =>
    props.bold ? 'HKGrotesk-Black' : 'HKGrotesk-Light'};
  color: ${theme.colors.white};
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;

  @media (min-width: 576px) {
    display: initial;
  }
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  margin-top: 80px;
  padding-left: 15px;

  @media (min-width: ${xs}px) {
    display: none;
  }
`;

import * as React from 'react';

import { IconProps } from '../common.types';

const ArrowBothSidesIcon = ({ width = 27, height = 27 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9026 1.1993L13.9026 1.19937L13.9109 1.19885C13.9238 1.19804 13.9368 1.19804 13.9497 1.19885L13.9653 1.19982H13.9809H21.8003V9.0192H21.8002L21.8004 9.02731C21.8038 9.2357 21.695 9.42839 21.5133 9.53403L21.5133 9.53402L21.5101 9.5359C21.3316 9.64139 21.109 9.64139 20.9306 9.53589L20.9274 9.53403C20.7456 9.42839 20.6369 9.2357 20.6402 9.02731L20.6403 9.02731V9.0192V4.37857V3.17147L19.7868 4.02502L4.0255 19.7863L3.17195 20.6398H4.37906H9.01968V20.6399L9.02779 20.6398C9.23618 20.6364 9.42887 20.7451 9.53451 20.9269L9.5345 20.9269L9.53638 20.9301C9.64188 21.1085 9.64188 21.3311 9.53637 21.5096L9.53451 21.5128C9.42887 21.6945 9.23618 21.8033 9.02779 21.7999L9.02779 21.7998H9.01968H1.20031V13.9804H1.20036L1.20026 13.9734C1.19569 13.6506 1.4516 13.388 1.7705 13.3835L1.77062 13.3835C1.92889 13.3812 2.07927 13.4425 2.19035 13.5535C2.30143 13.6646 2.36264 13.815 2.36036 13.9733L2.36031 13.9733V13.9804V18.6211V19.8282L3.21386 18.9746L18.9751 3.21338L19.8287 2.35982H18.6216H13.9809H13.9695L13.958 2.36035C13.6357 2.37516 13.3645 2.12739 13.3498 1.80751C13.335 1.48518 13.5827 1.21399 13.9026 1.1993Z" stroke="white"/>
  </svg>
);

export default ArrowBothSidesIcon;

import React, { useState } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { md, xlg } from 'utils/constants';

type AccordionItemProps = {
  title: string;
  description: string;
};

const AccordionItem = ({ title, description }: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <QuestionItem onClick={handleClick}>
      <QuestionContainer>
        <QuestionText fontSize="fz14" letterSpacing="-0.03em" text={title} />
        <QuestionText
          fontSize="fz24"
          letterSpacing="-0.03em"
          text={isOpen ? '-' : '+'}
          withCursorPointer
        />
      </QuestionContainer>
      <DescriptionContainer
        style={{
          height: isOpen ? 'auto' : 0,
          margin: isOpen ? '20px auto' : 0,
        }}>
        {description}
      </DescriptionContainer>
      <BottomLine />
    </QuestionItem>
  );
};

const QuestionItem = styled.div`
  width: 100%;
  margin-top: 14px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  height: 0;

  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.white};

  transition: all 0.3s;
  overflow: auto;
  overflow-y: hidden;

  @media (min-width: ${md}px) {
    font-size: 18px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 20px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuestionText = styled(Typography)<{
  withCursorPointer?: boolean;
}>`
  line-height: 24px;
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};

  @media (min-width: ${md}px) {
    font-size: 18px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 20px;
  }
`;

const BottomLine = styled.div`
  bottom: 0;
  height: 1px;
  margin-top: 10px;
  width: 334px;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export default AccordionItem;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';

import { ClipLoader as Loader } from 'react-spinners';


type ModalProps = {
  show: boolean;
};

const KYCLoadingModal = ({ show }: ModalProps) => {

  useEffect(() => {
    setModal(show);
  }, [show]);

  const [
    showModal,
    setModal,
  ] = useState<boolean>(false);

  return (
    <>
      <SBModal
        className={'kyc-loading'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout title={'just a moment'}>
            <ContentContainer className={'kyc-loading-modal'}>

              <BoldYellowText>While we are reviewing</BoldYellowText>

              <NormalText>
                {"Shouldn't take too much longer."}
              </NormalText>

              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '80%', justifyContent: 'center', height: '100px'}}>
                <Loader color={theme.colors.yellow} loading={true} size={45} />
              </div>

            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 400px;
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
`;

export default KYCLoadingModal;

import React from 'react';

import { IconProps } from '../common.types';

const Delete = ({ width = 22, height = 22 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0ZM5.5 11C5.5 11.2501 5.59934 11.4899 5.77616 11.6667C5.95298 11.8435 6.1928 11.9429 6.44286 11.9429H15.5571C15.8072 11.9429 16.047 11.8435 16.2238 11.6667C16.4007 11.4899 16.5 11.2501 16.5 11C16.5 10.7499 16.4007 10.5101 16.2238 10.3333C16.047 10.1565 15.8072 10.0571 15.5571 10.0571H6.44286C6.31904 10.0571 6.19643 10.0815 6.08204 10.1289C5.96765 10.1763 5.86371 10.2457 5.77616 10.3333C5.6886 10.4209 5.61915 10.5248 5.57177 10.6392C5.52439 10.7536 5.5 10.8762 5.5 11Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export default Delete;

import React, { useEffect, useCallback, useMemo } from 'react';
import { useViewport } from 'use-viewport';
import { useStoreActions } from 'store/store';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import TextContentContainer from 'common/components/TextContentContainer/TextContentContainer';
import Spacer from 'common/components/Spacer/Spacer';
import theme from 'theme/theme';

import { useIntercom } from 'react-use-intercom';
import { ContactButton, ContentButton } from './styled/common.styled';


import {
  TextContent,
  ImageContainer,
  Image,
  ImageGradient,
  HeadingSection,
  StrapLineTypographyContainer,
  StrapLineTypography,
  HeadContainer,
  ButtonsContainer,
  TextLink,
  TextLinkContainer,
} from './styled/Company.styled';

import Banner from '../assets/company.jpg';
import MobileBanner from '../assets/company-mobile.jpg';

import { footerHeight, footerMobileHeight, xlg, xs } from 'utils/constants';


const Company = () => {
  const viewport = useViewport();

  const contentContainerHeight = useMemo(
    () =>
      viewport.width < xs ? '100%' : viewport.width >= xlg ? '450px' : '260px',
    [viewport.width],
  );

  const updateHeaderTransparence = useStoreActions(
    (actions) => actions.style.setHeaderTransparent,
  );

  const { show, boot } = useIntercom();

  function toggleIntercom() {
    boot();
    show();
  }

  useEffect(() => {
    updateHeaderTransparence(true);

    return () => {
      updateHeaderTransparence(false);
    };
  }, []);

  const buttonsHeight = useCallback(
    () => (viewport.width < xlg ? 45 : 55),
    [viewport.width],
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Company | SongBits'}>
      <ImageContainer>
        <Image src={viewport.width < 576 ? MobileBanner : Banner} />
        <ImageGradient banner={viewport.width < 576 ? MobileBanner : Banner} />
      </ImageContainer>

      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <StrapLineTypography
              text="where it all "
              fontSize="fz100"
              fontWeight="bold"
              letterSpacing="-0.05em"
            />

            <div style={{ display: 'flex' }}>
              <StrapLineTypography
                text="began"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.05em"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.white}
                letterSpacing="0.035em"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentContainer
            height={contentContainerHeight}
            altText="A music first company with over 50 years of heritage in music recording and publishing. "
            useSpacer={false}>
            <Spacer height={25} />
            <TextContent
              text="With a legacy of ‘firsts’ starting with the first 8 track recording studios in 1968. Trident Studios was based in Soho, London and recorded major artists such as The Beatles, Elton John, David Bowie,Queen, The Rolling Stones, Carly Simon, Lou Reed and many more and forms the heritage of the company."
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />
            <TextContent
              text="Fast forward 50 years and today, SongBits - the legacy continues with the first truly affordable music ownership marketplace and community for fans and collectors worldwide."
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />
            <TextContent
              text="Our founders and ambassadors include music industry professionals, Grammy Award winning and Rock and Roll Hall of Fame artists and management with decades of experience. "
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />
            <>
              <TextLinkContainer>
                Want to know more, get in touch or follow our company
                <TextLink target={'_blank'} href="https://www.linkedin.com/company/songbits">linkedin page</TextLink>.
              </TextLinkContainer>
            </>
          </TextContentContainer>
        </HeadingSection>
      </HeadContainer>

      <ButtonsContainer>
        <ContactButton
          height={buttonsHeight()}
          borderRadius={50}
          borderColor={theme.colors.yellow}
          onClick={() => {
            toggleIntercom();
          }}
          label={
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="contact"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="us"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            </div>
          }
        />
      </ButtonsContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(Company, true);

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useViewport } from 'use-viewport';

import restService from 'services/rest.service';

import TwoFaAuthMethod from './Authenticator/TwoFaAuthMethod';
import TwoFaSetup from './Authenticator/TwoFaSetup';
import TwoFaHelp from './Authenticator/TwoFaHelp';
import TwoFaManualEntry from './Authenticator/TwoFaManualEntry';
import TwoFaFirstAuth from './Authenticator/TwoFaFirstAuth';

import { sm } from 'utils/constants';

import { TwoFaStages } from './types';
import TwoFaAuthCode from './Authenticator/TwoFaAuthCode';

type ManualMFAData = {
  secret: string;
  issuer: string;
  label: string;
};

type MFAData = {
  authenticator_uri: string;
  manual: ManualMFAData;
  validated: boolean;
};

interface TwoFaStageSelectionProps {
  stage: TwoFaStages;
  onStageChange: (x: TwoFaStages) => void;
  onHide: () => void;
  onSuccess: () => void;
}

const TwoFaStageSelection: FC<TwoFaStageSelectionProps> = ({
  stage,
  onStageChange,
  onHide,
  onSuccess,
}) => {
  const viewport = useViewport();

  const isMobile = useMemo(() => viewport.width < sm, [viewport.width]);

  const [oneTimePasscode, setOneTimePassCode] = useState<string>('');
  const [qrData, setQrData] = useState<MFAData>({
    authenticator_uri: '',
    manual: {
      secret: '',
      issuer: '',
      label: '',
    },
    validated: false,
  });

  useEffect(() => {
    restService.get2FA().then((res) => {
      setQrData(res);
    });
  }, []);

  switch (stage) {
    case TwoFaStages.Method:
      return (
        <TwoFaAuthMethod
          onUseAuthenticator={() => onStageChange(TwoFaStages.Setup)}
        />
      );

    case TwoFaStages.Setup:
      return (
        <TwoFaSetup
          authenticator_uri={qrData.authenticator_uri}
          onNext={() => onStageChange(TwoFaStages.AuthCode)}
          onManualEntry={() => onStageChange(TwoFaStages.ManualEntry)}
          onHelp={() => {
            if (isMobile) {
              onStageChange(TwoFaStages.MobileHelp);
            } else {
              onStageChange(TwoFaStages.PcHelp);
            }
          }}
        />
      );

    case TwoFaStages.MobileHelp:
      return (
        <TwoFaHelp
          isMobile={isMobile}
          onBack={() => onStageChange(TwoFaStages.Setup)}
        />
      );

    case TwoFaStages.PcHelp:
      return (
        <TwoFaHelp
          isMobile={isMobile}
          onBack={() => onStageChange(TwoFaStages.Setup)}
        />
      );

    case TwoFaStages.AuthCode:
      return (
        <TwoFaAuthCode
          onCodeSubmit={(code: string) => {
            setOneTimePassCode(code);
            onStageChange(TwoFaStages.FirstTimeAuth);
          }}
        />
      );

    case TwoFaStages.ManualEntry:
      return (
        <TwoFaManualEntry
          manualData={qrData.manual}
          onNext={() => onStageChange(TwoFaStages.AuthCode)}
        />
      );

    case TwoFaStages.FirstTimeAuth:
      return (
        <TwoFaFirstAuth
          code={oneTimePasscode}
          onHide={() => {
            onHide();
            onSuccess();
          }}
        />
      );

    default:
      return (
        <TwoFaAuthMethod
          onUseAuthenticator={() => onStageChange(TwoFaStages.Setup)}
        />
      );
  }
};

export default TwoFaStageSelection;

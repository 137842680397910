import * as React from 'react';

import { IconProps } from '../common.types';

const ShareOutlinedIcon = ({ width = 27, height = 27 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M47.8343 14.7535L48.1829 14.5792L48.0989 14.1987C47.9275 13.4213 47.8385 12.628 47.8333 11.832C47.8337 8.82667 49.0277 5.94454 51.1528 3.81946C53.2782 1.69404 56.1609 0.5 59.1667 0.5C62.1725 0.5 65.0551 1.69404 67.1805 3.81946C69.306 5.94487 70.5 8.82755 70.5 11.8333C70.5 14.8391 69.306 17.7218 67.1805 19.8472C65.0553 21.9724 62.173 23.1665 59.1675 23.1667C57.5495 23.1641 55.9508 22.815 54.4789 22.143C53.007 21.4711 51.6961 20.4917 50.6343 19.2708L50.38 18.9785L50.0334 19.1517L23.1657 32.5856L22.8171 32.7599L22.9011 33.1405C23.072 33.9159 23.1611 34.7072 23.1667 35.5012C23.1651 36.2968 23.0798 37.0899 22.9121 37.8676L22.8304 38.2467L23.1772 38.4202L50.0276 51.8482L50.3742 52.0216L50.6285 51.7292C51.6909 50.5076 53.0027 49.5278 54.4756 48.8558C55.9485 48.1838 57.5483 47.8352 59.1672 47.8333C62.1728 47.8335 65.0553 49.0275 67.1805 51.1528C69.306 53.2782 70.5 56.1609 70.5 59.1667C70.5 62.1725 69.306 65.0551 67.1805 67.1805C65.0551 69.306 62.1725 70.5 59.1667 70.5C56.1609 70.5 53.2782 69.306 51.1528 67.1805C49.0276 65.0553 47.8336 62.173 47.8333 59.1676C47.8347 58.3713 47.9201 57.5774 48.0879 56.7991L48.1696 56.42L47.8228 56.2465L20.9724 42.8184L20.6258 42.6451L20.3715 42.9375C19.3091 44.1591 17.9973 45.1388 16.5244 45.8108C15.0515 46.4829 13.4517 46.8315 11.8328 46.8333C8.82718 46.8332 5.94474 45.6392 3.81946 43.5139C1.69404 41.3885 0.5 38.5058 0.5 35.5C0.5 32.4942 1.69404 29.6115 3.81946 27.4861C5.94484 25.3607 8.82748 24.1667 11.8332 24.1667C13.4514 24.1701 15.05 24.52 16.5216 25.1928C17.9935 25.8658 19.3042 26.8462 20.3655 28.068L20.6198 28.3608L20.9666 28.1874L47.8343 14.7535Z"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);

export default ShareOutlinedIcon;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'common/components/Typography/Typography';
import { TypographyFontSizes } from 'common/common.types';
import Button from 'common/components/Button/Button';
import TextInput from '../../../../common/components/TextInput/TextInput';


export const ColumnContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 100%;
`;

export const Details = styled.div`
  width: 340px;
  @media (min-width: 576px) {
    width: 491px;
  }
`;

export const AvailablePayments = styled.div`
  font-family: 'HKGrotesk-Regular', sans-serif;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 576px) {
    width: 80%;
  }
`;

export const EditCard = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  max-width: 90%;
  @media (min-width: 576px) {
    
    max-width: 296px;
  }
`;

export const BorderLineWhite = styled.div`
  bottom: 0;
  height: 1px;
  margin-top: 10px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.white};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.white},
    ${(props) => props.theme.colors.white},
    ${(props) => props.theme.colors.transparent}
  );
  @media (min-width: 576px) {
    width: 490px;
  }
`;

export const BorderLineYellow = styled.div`
  bottom: 0;
  height: 1px;
  margin-top: 10px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
  @media (min-width: 576px) {
    width: 100%;
  }
`;

export const TitleText = styled(Typography)`
  font-family: 'HKGrotesk-Black', sans-serif; ;
`;

export const LightText = styled(Typography)`
  font-family: 'HKGrotesk-Light', sans-serif;
  font-weight: 200;
`;

export const SmallText = styled(Typography)<{
  fontSize?: keyof typeof TypographyFontSizes;
}>`
  font-family: 'HKGrotesk-Light', sans-serif;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.replace('fz', '') + 'px' : '16px'};
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export const IconContainer = styled.div`
  width: 63px;
  display: flex;
  justify-content: center;
`;

export const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black', sans-serif;
  letter-spacing: -0.03em;
`;

export const NewCard = styled(Button)`
  font-family: HKGrotesk-Light, sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 4px;
  &:hover {
    background: transparent;
    opacity: 0.7;
  }
`;

export const CardButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 22px;
  margin-left: auto;
`;

export const IconButton = styled(Button)`
  &:hover {
    background: transparent;
  }
`;

export const TransactionLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${(props) => props.theme.colors.white};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  filter: ${(props) => (props.isActive ? 'grayscale(0)' : 'grayscale(1)')};
  font-size: 16px;
  cursor: pointer;
  font-weight: ${(props) => (props.isActive ? '900' : '400')};
  font-family: ${(props) => (props.isActive ? 'HKGrotesk-Black' : 'HKGrotesk-Regular')};

  @media (min-width: 576px) {
    font-size: 18px;
  }
`;
export const PaddingContainer = styled.div`
  padding: 0;
  @media (min-width: 576px) {
    padding: 0 0 0 26px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const Icon = styled.div`
  position: absolute;
  bottom: 28px;
  left: 4px;
`;

export const InputWithIcon = styled(TextInput)`

  & > input {
    padding-left: 28px;
    margin-top: -18px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

export const PayPalButton = styled(Button)`
  font-family: 'HKGrotesk-Black', sans-serif;
  padding: 5px 0;
  border-width: 1px;
  cursor: pointer;
  font-size: 8px;
  width: 170px !important;
  height: 30px !important;
`;

export const PayPalButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PayPalIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;
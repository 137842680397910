import * as React from 'react';

import { IconProps } from '../../common.types';

const Visa = ({ width = 32, height = 10.62 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 32 12"
    xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33663 0.421772L4.8013 7.98337C4.8013 7.98337 4.15733 4.78475 4.0936 4.38311C2.65022 1.08988 0.520386 1.27332 0.520386 1.27332L3.03062 11.042V11.0401H6.08248L10.3026 0.421772H7.33663ZM9.75321 11.042H12.5251L14.2012 0.421772H11.3945L9.75321 11.042ZM29.3707 0.421772H26.454L21.9066 11.042H24.6601L25.2278 9.52523H28.6997L28.9951 11.042H31.5179L29.3707 0.421772ZM25.9964 7.49677L27.5054 3.48329L28.2951 7.49677H25.9964ZM18.1335 3.51708C18.1335 2.932 18.6143 2.49658 19.993 2.49658C20.889 2.49658 21.9153 3.14731 21.9153 3.14731L22.3652 0.918025C22.3652 0.918025 21.0541 0.420807 19.7671 0.420807C16.8523 0.420807 15.3491 1.81495 15.3491 3.57984C15.3491 6.7717 19.1907 6.33434 19.1907 7.97371C19.1907 8.25466 18.9677 8.90443 17.3679 8.90443C15.7633 8.90443 14.7042 8.31645 14.7042 8.31645L14.2263 10.4559C14.2263 10.4559 15.2526 11.041 17.2356 11.041C19.2236 11.041 21.9809 9.55419 21.9809 7.41856C21.9809 4.84944 18.1335 4.66406 18.1335 3.51708Z" fill="#4169E1"/>
  </svg>
);

export default React.memo(Visa);

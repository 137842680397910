import * as React from 'react';

import { IconProps } from '../common.types';

const HeartLiked = ({ width = 30, height = 30 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.439 20.2c3.026 2.771 5.814 4.6 5.93 4.677a.753.753 0 0 0 .827 0c.117-.077 2.903-1.911 5.924-4.688 1.783-1.64 3.272-3.266 4.428-4.835 1.472-1.998 2.409-3.918 2.784-5.707a8.35 8.35 0 0 0 .19-1.94c-.039-2.059-.82-3.99-2.202-5.437C23.924.806 22.073 0 20.109 0a7.08 7.08 0 0 0-4.438 1.564 7.72 7.72 0 0 0-1.91 2.231 7.704 7.704 0 0 0-1.91-2.229A7.079 7.079 0 0 0 7.415.003c-1.963 0-3.813.806-5.21 2.269C.822 3.719.041 5.65.002 7.708A8.336 8.336 0 0 0 .199 9.68c.381 1.785 1.324 3.702 2.8 5.696 1.16 1.566 2.654 3.189 4.44 4.824Z"
      fill="red"
    />
  </svg>
);

export default HeartLiked;

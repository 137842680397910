import * as React from 'react';

import { IconProps } from '../common.types';

const SpotifyGrey = ({ width = 15, height = 15, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2959 0.510193C6.4433 0.510193 0.83667 6.11683 0.83667 12.9694C0.83667 19.8219 6.4433 25.4286 13.2959 25.4286C20.1484 25.4286 25.755 19.8219 25.755 12.9694C25.755 6.11683 20.1484 0.510193 13.2959 0.510193ZM18.2079 19.199C17.9795 19.199 17.8653 19.0848 17.6368 18.9706C15.9237 17.9416 13.7537 17.3716 11.4695 17.3716C10.2132 17.3716 8.84273 17.6 7.70064 17.8285C7.47222 17.8285 7.24381 17.9427 7.1296 17.9427C6.67276 17.9427 6.33013 17.6 6.33013 17.1432C6.33013 16.6864 6.55855 16.3437 7.01539 16.3437C8.50011 16.0011 9.98586 15.7727 11.5848 15.7727C14.2116 15.7727 16.61 16.4579 18.6658 17.6C18.8942 17.8285 19.1226 17.9427 19.1226 18.3995C18.8921 18.9705 18.5495 19.199 18.2079 19.199ZM19.4632 16.0021C19.2347 16.0021 19.0063 15.8879 18.7779 15.7737C16.8363 14.5174 14.0943 13.8322 11.1259 13.8322C9.64116 13.8322 8.26961 14.0606 7.24277 14.289C7.01435 14.4032 6.90014 14.4032 6.67172 14.4032C6.10068 14.4032 5.75701 13.9464 5.75701 13.4885C5.75701 12.9175 5.98543 12.689 6.55647 12.4596C7.92698 12.117 9.29853 11.7743 11.2401 11.7743C14.4379 11.7743 17.4074 12.5738 19.921 14.0585C20.2637 14.2869 20.4921 14.6296 20.4921 14.9732C20.3768 15.5443 20.0352 16.0021 19.4632 16.0021ZM20.9479 12.3464C20.7195 12.3464 20.6052 12.2322 20.2626 12.118C17.9784 10.8617 14.6664 10.0622 11.4685 10.0622C9.86958 10.0622 8.15644 10.2907 6.67068 10.6333C6.44227 10.6333 6.32806 10.7475 5.98543 10.7475C5.30017 10.8627 4.84334 10.2907 4.84334 9.60643C4.84334 8.92222 5.18596 8.57751 5.6428 8.46434C7.47119 8.00647 9.41274 7.77805 11.4685 7.77805C15.009 7.77805 18.6637 8.57751 21.52 10.0622C21.8626 10.2907 22.2052 10.6333 22.2052 11.2043C22.091 11.8896 21.6331 12.3464 20.9479 12.3464Z"
      fill="white"
      fillOpacity={opacity}
    />
  </svg>
);

export default SpotifyGrey;

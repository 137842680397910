import * as React from 'react';

import { IconProps } from '../common.types';

const ProfileRectangleIcon = ({ width = 27, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4186 0H1.8314C0.819549 0 0 0.800337 0 1.78846V21.4615C0 22.4497 0.819549 23.25 1.8314 23.25H24.4186C25.4305 23.25 26.25 22.4497 26.25 21.4615V1.78846C26.25 0.800337 25.4305 0 24.4186 0ZM5.79942 21.4615C5.79942 16.6559 10.887 17.7558 11.4969 15.7939V14.4213C11.2222 14.2755 10.2332 13.274 10.1508 12.4915C9.93471 12.4736 9.59499 12.2617 9.49517 11.422C9.44115 10.9713 9.65542 10.7183 9.78453 10.6387C9.78453 10.6387 9.46221 9.91255 9.46221 9.1909C9.46221 7.04564 10.5354 5.36538 13.125 5.36538C14.5224 5.36538 14.9564 6.17019 14.9564 6.17019C16.2063 6.17019 16.7878 7.60186 16.7878 9.19001C16.7878 9.9823 16.4655 10.6378 16.4655 10.6378C16.5955 10.7174 16.8089 10.9713 16.7548 11.4211C16.655 12.2599 16.3153 12.4727 16.0992 12.4906C16.0168 13.2731 15.0278 14.2755 14.7531 14.4204V15.793C15.363 17.7558 20.4506 16.6559 20.4506 21.4615H5.79942Z"
      fill="white"
    />
  </svg>
);

export default ProfileRectangleIcon;

import * as React from 'react';

import { IconProps } from '../common.types';

const PlusCircleIcon = ({
  width = 25,
  height = 25,
  fill = '#FFD600',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12.5" r="12.5" fill={fill} />
    <path
      d="M11.7748 18.5998H12.8248V12.8248H18.5748V11.7748H12.8248V5.99976H11.7748V11.7748H5.99976V12.8248H11.7748V18.5998Z"
      fill="black"
    />
  </svg>
);

export default PlusCircleIcon;

import React, { useState, FC } from 'react';
import styled from 'styled-components';

import TwoFaModal from './TwoFaModal';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import theme from 'theme/theme';

import SBModal from 'common/components/Modal/SBModal';
import TwoFaRecoverModal from './TwoFaRecoverModal';

interface ComponentProps {
  mfaEnabled: boolean;
  onUpdate: () => void;
  refreshAccountSettings: () => void;
}

const TwoFA: FC<ComponentProps> = ({
  mfaEnabled,
  onUpdate,
  refreshAccountSettings,
}) => {
  const [showTwoFaEnableModal, setShowTwoFaEnableModal] =
    useState<boolean>(false);
  const [showTwoFaRecoverModal, setShowTwoFaRecoverModal] =
    useState<boolean>(false);

  return (
    <Container>
      <Spacer height={20} />
      <EmailButtonContainer>
        {!mfaEnabled ? (
          <>
            <Typography
              text={'Not enabled'}
              fontSize="fz18"
              fontColor={theme.colors.white}
            />
            <EnableButton
              className={'enable-mfa-button'}
              disabled={mfaEnabled}
              label={'enable'}
              onClick={() => setShowTwoFaEnableModal(true)}
            />
          </>
        ) : (
          <FormColumn>
            <FormRow>
              <Typography
                text={'Enabled'}
                fontSize="fz18"
                fontColor={theme.colors.white}
              />
              <EnableButton
                className={'remove-mfa-button'}
                disabled={!mfaEnabled}
                label={'recover/disable'}
                onClick={() => setShowTwoFaRecoverModal(true)}
              />
            </FormRow>
          </FormColumn>
        )}
      </EmailButtonContainer>

      <SBModal
        className={'setup-2fa'}
        isOpen={showTwoFaEnableModal}
        width="519px"
        height="529px"
        top="22%"
        content={
          <TwoFaModal
            onSuccess={onUpdate}
            onHide={() => {
              setShowTwoFaEnableModal(false);
            }}
          />
        }
      />

      <SBModal
        className={'recover-2fa'}
        isOpen={showTwoFaRecoverModal}
        width="519px"
        height="429px"
        top="22%"
        content={
          <TwoFaRecoverModal
            onHide={() => {
              refreshAccountSettings();
              setShowTwoFaRecoverModal(false);
            }}
          />
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;

const EmailButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EnableButton = styled(Button)`
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.yellow};
`;

const FormColumn = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default TwoFA;

import React, { FC, useEffect, useRef, useState } from 'react';
import { useViewport } from 'use-viewport';
import ReactHowler from 'react-howler';
import styled from 'styled-components';

import { SongPoster } from '../SongInfo.styled';
import NextSongIcon from 'common/icons/NextSong.icon';
import PlayIcon from 'common/icons/Play.icon';
import StopIcon from 'common/icons/Stop.icon';
import { sm } from 'utils/constants';

interface ImagePlayerProps {
  srcImage: string;
  songUrl: string;
}

const ImagePlayer: FC<ImagePlayerProps> = ({ srcImage, songUrl }) => {
  const viewport = useViewport();

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const playerRef = useRef<ReactHowler>(null);

  function backJumpHandle() {
    if (playerRef.current) {
      if (playerRef.current.seek() && playerRef.current.seek() > 30) {
        playerRef.current.seek(playerRef.current.seek() - 30);
      } else {
        playerRef.current.seek(0);
      }
    }
  }

  useEffect(() => {
    if (isPlaying) (window as any).gtag('event', 'buy_song_play', {});
  }, [isPlaying]);

  return (
    <PlayerContainer>
      <ReactHowler
        ref={playerRef}
        src={songUrl}
        playing={isPlaying}
        onEnd={() => setIsPlaying(false)}
      />

      <SongPoster playing={isPlaying} src={srcImage} alt="Song image" />
      <PlayerControlsContainer>
        {!isPlaying ? (
          <ButtonWrapper onClick={() => setIsPlaying(true)}>
            <PlayIcon width={viewport.width < sm ? 20 : 27} />
          </ButtonWrapper>
        ) : (
          <PlayingButtonsContainer>
            <ButtonWrapper onClick={backJumpHandle}>
              <NextSongIcon width={viewport.width < sm ? 18 : 25} />
            </ButtonWrapper>
            <ButtonWrapper onClick={() => setIsPlaying(false)}>
              <StopIcon width={viewport.width < sm ? 13 : 25} />
            </ButtonWrapper>
          </PlayingButtonsContainer>
        )}
      </PlayerControlsContainer>
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  position: relative;
  width: 70px;
  height: 70px;

  @media (min-width: ${sm}px) {
    height: 150px;
    width: 150px;
  }
`;

const PlayerControlsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
`;

const ButtonWrapper = styled.button<{ rotate?: string }>`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;

  ${(props) => props.rotate && `transform: rotate(${props.rotate}deg);`}
`;

const PlayingButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;

  position: relative;
  right: 14px;

  @media (min-width: ${sm}px) {
    right: 15px;
  }
`;

export default ImagePlayer;

import * as React from 'react';

import { IconProps } from '../../common.types';

const Mastercard = ({ width = 24, height = 14 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 23 14"
    xmlns="http://www.w3.org/2000/svg">
      <path d="M7.36294 13.8072C10.9658 13.8072 13.8866 10.8864 13.8866 7.28353C13.8866 3.68062 10.9658 0.759888 7.36294 0.759888C3.76003 0.759888 0.839294 3.68062 0.839294 7.28353C0.839294 10.8864 3.76003 13.8072 7.36294 13.8072Z" fill="#CF1928"/>
<path d="M15.799 13.8072C19.4019 13.8072 22.3226 10.8864 22.3226 7.28353C22.3226 3.68062 19.4019 0.759888 15.799 0.759888C12.1961 0.759888 9.27533 3.68062 9.27533 7.28353C9.27533 10.8864 12.1961 13.8072 15.799 13.8072Z" fill="url(#paint0_linear_18428_82)"/>
<defs>
<linearGradient id="paint0_linear_18428_82" x1="9.27533" y1="7.28353" x2="22.3233" y2="7.28353" gradientUnits="userSpaceOnUse">
<stop stopColor="#FEDE00"/>
<stop offset="1" stopColor="#FFD000"/>
</linearGradient>
</defs>
  </svg>
);

export default React.memo(Mastercard);

import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import { CardSchema, PaymentModalType } from '../types';
import CardPaymentModal from 'modules/account/pages/components/Modals/CardPaymentModal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType } from 'store-persist/reducers/modalSlice';

interface CardPaymentModalProps {
  isOpen: boolean;
  type: PaymentModalType;
  mostRecentCard: CardSchema;
  paymentAmount: string;
  onClose: () => void;
  onPaymentComplete: (id: string) => void;
  onPaymentFail: () => void;
  onAddCard: () => void;
}

const CardPayment: FC<CardPaymentModalProps> = ({
  isOpen,
  mostRecentCard,
  paymentAmount,
  onClose,
  onPaymentComplete,
  onPaymentFail,
  onAddCard,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(setModalType('CardPayment'));
    }
  }, [isOpen]);

  return (
    <SBModal
      className={'card-payment'}
      isOpen={isOpen}
      width="434px"
      height='640px'
      mobileWidth='100%'
      top="10%"
      mobileTop='2%'
      withProceedingText={true}
      content={
        <CardPaymentModal
          type={PaymentModalType.Quickflow}
          card={mostRecentCard}
          cost={paymentAmount}
          onClose={() => {
            onClose();
          }}
          onAddCard={onAddCard}
          onPaymentComplete={onPaymentComplete}
          onPaymentFail={onPaymentFail}
        />
      }
    />
  );
};

export default memo(CardPayment);

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import restService from 'services/rest.service';
import { useViewport } from 'use-viewport';

import Caption from 'common/components/Caption/Caption';
import Media from 'common/components/Media/Media';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
import Earth from 'common/icons/Earth.Icon';
import Drops from 'modules/artist/pages/components/Drops/Drops';
import MyCollection from 'modules/artist/pages/components/Drops/MyCollection';
import theme from 'theme/theme';

import SignInModal from './components/Modals/SignInModal';
import { MyCollectionDataType } from 'modules/account/types';
import { UserDataType, DropsDataType } from 'modules/account/types';

import { GET_PROFILE } from '../../artist/graphql/Queries.graphql';
import HeartLiked from 'common/icons/HeartLiked.icon';
import Heart from 'common/icons/Heart.icon';
import SBModal from 'common/components/Modal/SBModal';
import SongProfileShareModal from 'modules/library/pages/components/SongProfileShareModal/SongProfileShareModal';
import { getSoldPercent } from 'utils/calculation';
import SocialShareIcon from 'common/icons/SocialShare.icon';
import {
  BoldText,
  BottomLine,
  Container,
  CustomSpacer,
  DataFieldContainer,
  Details,
  DetailsContainer,
  Ellipsis,
  EllipsisWrapper,
  Image,
  ImageContainer,
  Intro,
  LightText,
  NoImage,
  SocialButton,
  SocialShareContainer,
  WidthSpacer,
  PageContent,
  TitleRowContainer,
  ColumnContainer,
  ArtistName,
  MemeberSince,
} from './styled/ArtistProfile.styled';
import {
  LIKE_USER,
  UNLIKE_USER,
  VIEW_TRACKING,
} from 'modules/library/graphql/Mutations.graphql';
import {
  VIEW_TRACKING_MUTATION,
  VIEW_TRACKING_VARIABLES,
} from 'modules/library/types';
import { getUserIdFromJWT } from 'utils/functions';
import ErrorPage from 'modules/prereg/pages/ErrorPage';
import { ArtistSettingsTypes } from '../../artist/pages/types';
import { useStoreActions, useStoreState } from 'store/store';
import Alert from 'common/components/Alert/Alert';

const ArtistProfile = () => {
  const [userData, setUserData] = useState<UserDataType>();
  const [myCollection, setMyCollection] = useState<MyCollectionDataType[]>([]);
  const [showUnauthModal, setShowUnauthModal] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<string>('');
  const [showSocialModal, setShowSocialModal] = useState<boolean>(false);

  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );
  const [socialTopPosition, setSocialTopPosition] = useState<string>('40%');

  const viewport = useViewport();
  const userId = getUserIdFromJWT();

  // Get user profile from slug
  const { user } = useParams();

  const { data: profile } = useQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { slug: user, userid: userId },
  });

  const [likeUser] = useMutation(LIKE_USER, {
    variables: { likedBy: userId, likedUser: userData ? userData.id : '' },
  });

  const [unlikeUser] = useMutation(UNLIKE_USER, {
    variables: { likeid: isLiked },
  });

  const [createViewTracking] = useMutation<
    VIEW_TRACKING_MUTATION,
    VIEW_TRACKING_VARIABLES
  >(VIEW_TRACKING);

  const isEditingPreviewStore = useStoreState(
    (state) => state.preview.isEditingPreview,
  );

  const reloadAfterPreviewStore = useStoreState(
    (state) => state.preview.reloadAfterPreview,
  );

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  function formatDate(date: string) {
    const values = date.split('T')[0].split('-');

    const new_date = new Date(
      parseInt(values[0].substring(0)),
      parseInt(values[1]) - 1,
      parseInt(values[2]),
    );

    return (
      values[2] +
      ' ' +
      new_date.toLocaleString('default', { month: 'short' }) +
      ' ' +
      values[0].substring(0)
    );
  }
  function parseDate(date: string) {
    const d = new Date(date).toDateString().split(' ');
    return `${d[1]} ${d[3]}`;
  }

  useEffect(() => {
    if (userData) {
      setBreadcrumbs([
        { value: 'Home', label: 'Home' },
        { value: user || '', label: userData.artistName },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [userData]);

  useEffect(() => {
    if (profile) {
      const userData = profile.userBySlug;
      if (!userData) return;

      const artistData = profile.userBySlug?.artistProfileByUserId;
      let createdAt = '';

      if (userData.createdAt) {
        createdAt = parseDate(profile.userBySlug.createdAt);
      }

      // Artist Profile
      if (profile.artistProfileBySlug) {
        const dropData = profile.artistProfileBySlug;

        const Drops: DropsDataType[] = [];

        dropData.currentDrops.nodes.forEach((t: any) => {
          let date = '';

          if (t.releaseAt) {
            date = formatDate(t.releaseAt);
          }

          const totalBits = t.audioLengthMs;
          let soldBitsInverted = t.soldBitsInverted;

          let status = 'current';
          if (t.slug === 'drop') {
            soldBitsInverted = 0;
            status = 'completed';
          }

          let sold_percent = '';

          if (t) {
            sold_percent =
              getSoldPercent(
                totalBits,
                t.editionsCount,
                soldBitsInverted,
              ).toString() + '%';
          } else {
            sold_percent = '0%';
          }

          Drops.push({
            name: t.title + ' - ' + artistData.artistName,
            date: date,
            editions: t.editionsCount,
            sold: sold_percent,
            cover: t.fileByCoverId.url,
            status: status,
            link: '/' + artistData.slug + '/' + t.slug,
            songType: t.songType,
          });
        });

        dropData.completedDrops.nodes.forEach((t: any) => {
          let date = '';

          if (t.releaseAt) {
            date = formatDate(t.releaseAt);
          }

          let sold_percent = '';

          if (t) {
            sold_percent =
              getSoldPercent(
                t.audioLengthMs,
                t.editionsCount,
                t.soldBitsInverted,
              ).toString() + '%';
          } else {
            sold_percent = '0%';
          }

          Drops.push({
            name: t.title + ' - ' + artistData.artistName,
            date: date,
            editions: t.editionsCount,
            sold: sold_percent,
            cover: t.fileByCoverId.url,
            status: 'completed',
            link: '/' + artistData.slug + '/' + t.slug,
            songType: t.songType,
          });
        });

        let userLiked = '';

        try {
          userLiked = userData.userlikesByLikedUser.nodes[0].id;
        } catch {}

        const artistProfileData: ArtistSettingsTypes | any = {
          artistName: artistData.artistName,
          avatar: userData.fileByAvatarId ? userData.fileByAvatarId.url : '',
          intro: artistData.intro,
          location: userData.location,
          isArtist: true,
          createdAt: createdAt,
          socialMedia: JSON.parse(artistData.socialMediaPlatforms),
          musicLinks: JSON.parse(artistData.musicPlatforms),
          combinedFollowerCount: artistData.combinedFollowerCount,
          musicPlatforms: JSON.parse(artistData.musicPlatforms),
          socialMediaPlatforms: JSON.parse(artistData.socialMediaPlatforms),
          Drops: Drops,
          MyCollection: [],
          myCollectionPublic: userData.myCollectionPublic,
          userLiked: userLiked,
        };
        setUserData(artistProfileData);

        setIsLiked(userLiked);

        createViewTracking({
          variables: {
            type: 'ARTIST',
            viewedItem: artistData.userId,
            userId: userId,
          },
        });
      }
      // User profile
      else {
        const MyCollection: MyCollectionDataType[] = [];

        if (isAuthenticated) {
          if (userData.myCollectionPublic) {
            restService
              .userMyCollection(userData.id)
              .then((res) => {
                res.forEach((purchase: any) => {
                  MyCollection.push({
                    name: purchase.title + ' - ' + purchase.artist_name,
                    date: purchase.purchasedate,
                    noBits: purchase.number_of_bits,
                    cover: purchase.file_url,
                    blockexplorer: purchase.transactionid,
                    link: '/' + purchase.artistslug + '/' + purchase.songslug,
                  });
                });
                setMyCollection(MyCollection);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        } else {
          userData.myCollectionPublic = false;
        }

        const userProfileData = {
          artistName: userData.username,
          id: userData.id,
          avatar: userData.fileByAvatarId ? userData.fileByAvatarId.url : '',
          location: userData.location,
          intro: '',
          slug: userData.slug,
          isArtist: false,
          createdAt: createdAt,
          socialMedia: {
            facebook: String(userData.facebook),
            instagram: String(userData.instagram),
            twitter: String(userData.twitter),
            youtube: String(userData.youtube),
            tiktok: String(userData.tiktok),
          },
          Drops: [],
          MyCollection: MyCollection,
          myCollectionPublic: userData.myCollectionPublic,
        };
        setUserData(userProfileData);

        createViewTracking({
          variables: {
            type: 'USER',
            viewedItem: userData.id,
            userId: userId,
          },
        });
      }
    }
  }, [profile]);

  const likingHandle = () => {
    if (isAuthenticated) {
      if (!isLiked) {
        likeUser().then((data) => {
          setIsLiked(data.data.createUserlike.userlike.id);
        });
      } else {
        unlikeUser().then(() => {
          setIsLiked('');
        });
      }
    } else {
      setShowUnauthModal(true);
    }
  };

  return (
    <>
      {(isEditingPreviewStore || reloadAfterPreviewStore) && (
        <Alert
          title="Profile Updated!"
          content="Your changes are now live!"
          seconds={5}
        />
      )}
      {userData ? (
        <PageContainer
          pageTitle={userData.artistName + ' | SongBits'}
          pageDescription={
            userData.artistName +
            ' on SongBits. A platform where you can buy shared ownership in songs directly from your favourite artists and earn royalties together with them for life.'
          }
          reduceFooter="24">
          <PageContent>
            <Container>
              <TitleRowContainer>
                <ColumnContainer>
                  <CustomSpacer height={10} />
                  <ArtistName
                    text={userData.artistName}
                    fontColor={theme.colors.yellow}
                  />
                  <MemeberSince>
                    <Typography
                      fontSize="fz9"
                      fontWeight="regular"
                      text={'Member since ' + userData.createdAt}
                      fontColor={theme.colors.gray}
                    />
                  </MemeberSince>
                </ColumnContainer>
                <SocialShareContainer left={viewport.width - 120}>
                  {userData.isArtist && (
                    <SocialButton
                      label={isLiked ? <HeartLiked /> : <Heart />}
                      onClick={likingHandle}
                    />
                  )}
                  <EllipsisWrapper>
                    <Ellipsis
                      label={<SocialShareIcon></SocialShareIcon>}
                      onClick={() => {
                        setShowSocialModal(true);
                      }}
                      fontSize={44}
                    />
                  </EllipsisWrapper>
                </SocialShareContainer>
              </TitleRowContainer>
              <ImageContainer className="artist-image">
                {userData.avatar ? (
                  <>
                    <Image src={userData.avatar} alt="image" />
                  </>
                ) : (
                  <NoImage
                    fontSize="fz18"
                    text="no photo"
                    fontColor={theme.colors.yellow}
                  />
                )}
              </ImageContainer>
              <DetailsContainer>
                <Details>
                  <BoldText
                    size={24}
                    fontWeight="bold"
                    text={`${userData.isArtist ? 'Artist Profile' : 'Profile'}`}
                    fontColor={theme.colors.white}
                  />
                  <CustomSpacer height={20} hideMobile={true} />
                  <DataFieldContainer>
                    {viewport.width >= 576 && <Earth height={20} width={20} />}
                    <WidthSpacer width={10} />
                    <LightText
                      fontSize="fz18"
                      fontWeight="regular"
                      text={userData.location}
                      fontColor={theme.colors.white}
                    />
                    <BottomLine />
                  </DataFieldContainer>
                  {userData.isArtist && (
                    <>
                      <CustomSpacer height={30} />

                      <WidthSpacer width={10} />
                      <Caption text="Introduction" />

                      <DataFieldContainer>
                        {userData.intro.split('\\n').map((block, key) => (
                          <Intro
                            key={key}
                            fontSize="fz18"
                            text={block.replace('\\n', '').replace('\n', '')}
                            fontWeight="regular"
                            fontColor={theme.colors.white}
                          />
                        ))}

                        <BottomLine />
                      </DataFieldContainer>
                    </>
                  )}
                  <CustomSpacer height={20} />
                  <Media
                    type="socialMedia"
                    caption="Socials"
                    values={userData.socialMedia}
                    isFollowersCount={userData.isArtist}
                    followerCount={userData.combinedFollowerCount}
                    hideBottomLine={!userData.isArtist}
                    artistIcons={userData.isArtist}
                  />
                  <CustomSpacer height={20} />
                  {userData.isArtist && (
                    <Media
                      values={userData.musicLinks || {}}
                      type="musicPlatforms"
                      caption="Listen"
                      hideBottomLine={true}
                      artistIcons={userData.isArtist}
                    />
                  )}
                </Details>
                <WidthSpacer width={30} />
                <CustomSpacer height={30} />
                {userData.isArtist && (
                  <Drops showLinkToAllDrops={false} drops={userData.Drops} />
                )}
                {!userData.isArtist && userData.myCollectionPublic && (
                  <MyCollection drops={myCollection} />
                )}
              </DetailsContainer>
              )
            </Container>
          </PageContent>
          {/* Social Share Modal */}
          <SBModal
            className={'social-share'}
            isOpen={showSocialModal}
            height={'430px'}
            width={viewport.width < 576 ? '346px' : '434px'}
            top={socialTopPosition}
            content={
              <SongProfileShareModal
                imageLink={userData.avatar}
                onChange={() => {
                  setShowSocialModal(false);
                }}
                changeModalPosition={(topPos) => {
                  setSocialTopPosition(topPos);
                }}
              />
            }
          />

          <SBModal
            className={'sign-in'}
            isOpen={showUnauthModal}
            height="310px"
            top="35%"
            content={
              <SignInModal
                onClose={() => {
                  setShowUnauthModal(false);
                }}
              />
            }
          />
        </PageContainer>
      ) : (
        <>{!userData && profile ? <ErrorPage /> : <></>}</>
      )}
    </>
  );
};

export default ArtistProfile;

import React, { FC } from 'react';

import { IconProps } from 'common/common.types';

const CopyIcon: FC<IconProps> = ({ width = 16, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7521 0H2.98554C2.88895 0 2.80992 0.0803571 2.80992 0.178571V1.42857C2.80992 1.52679 2.88895 1.60714 2.98554 1.60714H13.874V16.9643C13.874 17.0625 13.953 17.1429 14.0496 17.1429H15.2789C15.3755 17.1429 15.4545 17.0625 15.4545 16.9643V0.714286C15.4545 0.319196 15.1406 0 14.7521 0ZM11.9421 2.85714H0.702479C0.31392 2.85714 0 3.17634 0 3.57143V15.4174C0 15.6071 0.0746383 15.7879 0.206353 15.9219L4.01072 19.7902C4.05901 19.8393 4.11389 19.8795 4.17317 19.9129V19.9554H4.26537C4.3422 19.9844 4.42342 20 4.50684 20H11.9421C12.3307 20 12.6446 19.6808 12.6446 19.2857V3.57143C12.6446 3.17634 12.3307 2.85714 11.9421 2.85714ZM4.17097 17.683L2.28086 15.7589H4.17097V17.683ZM11.064 18.3929H5.57593V15.2232C5.57593 14.7299 5.18298 14.3304 4.69783 14.3304H1.58058V4.46429H11.064V18.3929Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default CopyIcon;

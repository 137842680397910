import PlusButtonNoBorder from 'common/icons/PlusButtonNoBorder.icon';
import React from 'react';
import styled from 'styled-components';

import theme from 'theme/theme';

import { md } from 'utils/constants';

type ImagePickerProps = {
  image?: string;
  text: string;
  withPlus?: boolean;
  imageClickHandler: () => void;
  noImageClickHandler: () => void;
};

const ArtistImage: React.FC<ImagePickerProps> = ({
  image,
  text,
  withPlus = true,
  imageClickHandler,
  noImageClickHandler,
}) => {
  return (
    <>
      {image ? (
        <ImageContainer>
          <Image src={image} alt="image" />

          <Label
            htmlFor="img"
            image={!!image}
            className="label"
            onClick={imageClickHandler}>
            {text}
          </Label>
          {/* {withPlus && (
            <PlusContainer>
              <PlusButtonNoBorder width={19} height={19} color="#FFD600" />
            </PlusContainer>
          )} */}
        </ImageContainer>
      ) : (
        <ImagePickerContainer>
          <Label htmlFor="img" className="label" onClick={noImageClickHandler}>
            {text}
          </Label>
          {withPlus && (
            <PlusContainer>
              <PlusButtonNoBorder width={19} height={19} color="#FFD600" />
            </PlusContainer>
          )}
        </ImagePickerContainer>
      )}
    </>
  );
};

const PlusContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  height: min-content;
`;

const Image = styled.img`
  width: 132px;
  height: 132px;
  border-radius: 10px;

  @media (min-width: ${md}px) {
    width: 150px;
    height: 150px;
  }
`;

const Label = styled.label<{ image?: boolean }>`
  width: 100%;

  position: absolute;
  bottom: -30px;

  cursor: pointer;

  color: ${theme.colors.white};
  font-size: 16px;
  text-align: center;
  font-weight: 400;

  @media (min-width: ${md}px) {
    font-size: 18px;
  }
`;

const ImagePickerContainer = styled.div`
  width: 132px;
  height: 132px;
  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  &:hover .plus {
    color: ${theme.colors.black};
    background: ${theme.colors.yellow};
  }

  &:hover .label {
    background: none;
    color: ${theme.colors.yellow};
  }

  @media (min-width: ${md}px) {
    width: 150px;
    height: 150px;
  }
`;

export default ArtistImage;

import * as React from 'react';

import { IconProps } from '../common.types';

const PauseIcon = ({ width = 24, height = 46 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 3L21 43"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <path
      d="M3 3L3 43"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);

export default PauseIcon;

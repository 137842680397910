import Button from 'common/components/Button/Button';
import styled from 'styled-components';
import theme from 'theme/theme';

export const DropsContainer = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: 992px) {
    width: 350px;
  }
`;

export const Drop = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Pipe = styled.p`
  color: ${theme.colors.white};
  padding: 0 10px;
`;

export const DropDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 992px) {
    width: 350px;
  }
`;

export const DropDetails = styled.div`
  font-family: 'HKGrotesk-Light';
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DropButton = styled(Button)`
  padding: 0;
  cursor: pointer;

  &:hover {
    background: transparent;
  }

  &:hover .songName {
    color: ${theme.colors.yellow};
  }

  @media (min-width: 992px) {
    width: inherit;
  }

  @media (min-width: 1200px) {
    width: 450px;
  }
`;

export const DropTypeContainer = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -1.2px;
  width: 100%;
  height: min-content;
`;

export const ButtonWrapper = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import theme from 'theme/theme';

type TextContentProps = {
  children: React.ReactNode;
  altText: string;
  height?: string;
  useSpacer?: boolean;
};

const TextContentContainer: React.FC<TextContentProps> = ({
  children,
  altText,
  height,
  useSpacer = true,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <TextContainer height={height}>
        <div style={{ transform: 'scaleX(-1)', width: '470' }}>
          <TextContentAlt
            text={altText}
            fontSize="fz18"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />
          <div style={{ display: useSpacer ? 'block' : 'none' }}>
            <Spacer height={30} />
          </div>
          {children}
        </div>
      </TextContainer>
    </div>
  );
};

const TextContainer = styled.div<{
  height?: string;
}>`
  font-family: 'HKGrotesk-Regular';
  letter-spacing: auto;

  max-height: ${(props) => (props.height ? `${props.height}` : '330px')};
  margin-left: -30px;
  padding-right: 25px;
  
  scrollbar-color: ${theme.colors.yellow50} transparent;
  overflow-y: auto;
  transform: scaleX(-1);

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.colors.yellow50};
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      border: 1px solid #ffffff;
    }
  }

  @media (min-width: 768px) {
    ${(props) =>
      props.height ? `max-height: ${props.height};` : 'max-height: 500px;'};
    width: 500px;
  }

  @media (min-width: 992px) {
    ${(props) =>
      props.height ? `max-height: ${props.height};` : 'max-height: 50vh;'};
  }
`;

const TextContentAlt = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.03em;

  padding-right: 20px;

  @media (min-width: 408px) {
    padding-right: 35px;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    padding-right: 0;
  }
`;

export default TextContentContainer;
import axios from "axios";

import { handleErrors } from "./axios.service";

import { countreResponse } from "./types";

const axiosInstance = axios.create({
    baseURL: "https://get.geojs.io"
});

class GeoService {
    async getUserIP() {
        try {
            const { data } = await axiosInstance.get<string>('/v1/ip');
            return data;
        } catch (error) {
            handleErrors(error);
        }
    }

    async getUserCountry(ip: string) {
        try {
            const { data } = await axiosInstance.get<countreResponse>(`/v1/ip/country/${ip}.json`);
            return data;
        } catch (error) {
            handleErrors(error);
        }
    }
}

export default new GeoService;
export const termsData = `

<body lang=EN-GB style='word-wrap:break-word'>

<p><b>Last Updated: September 23, 2024</b></p>
<div class=WordSection1>

<p class=MsoNormal><a name="_Hlk174372140"><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>These sale terms (&quot;<b>Terms</b>&quot;) are
entered into between (1) the relevant <b>ARTIST </b>(also<b> </b>&quot;<b>we</b>&quot;,
&quot;<b>our</b>&quot;, &quot;<b>us</b>&quot;) and (2) you, i.e. the individual
person placing an order to purchase Bit(s) (&quot;<b>you</b>&quot; and &quot;<b>your</b>&quot;,
for short); and govern the terms and conditions upon which you purchase certain
rights in and to Bit(s) and Content in respect of the Recording (as such terms
are defined below). </span></a></p>

<p class=MsoNormal><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We
enter into this agreement, acting through our disclosed agent, </span><b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>SONGBITS LIMITED</span></b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, a company registered in
England and Wales with company registration number 13838925, whose company
registered address is Level 3 207 Regent Street, London, United Kingdom, W1B
3HH</span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. </span></p>

<p class=MsoNormal><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>These
Terms use terms with special meanings. Where such a term is used, it is
capitalised or when it is first used, it is shown in bold and/or its meaning is
explained in the <b>Glossary</b> at the end of these Terms (where rules
explaining how the Agreement shall be interpreted).</span></p>

<p class=MsoNormal><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>

<p class=MsoNormal><b><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>IMPORTANT
NOTICE:</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:1.0cm;text-indent:0cm'><span
style='font-size:11.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>By
placing an order for Bits on the Platform, you confirm that you have read and
accept these Terms and that you understand your right to receive a fraction of
the available streaming royalties from the Recording, your rights in and to the
Content, how your royalty payment is calculated (based upon that percentage) as
set out in the relevant Release Page for the Recording and how it will be
distributed to you. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0cm;text-indent:0cm'><span
style='font-size:11.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Your
attention is particularly drawn to the exclusions and limitations of liability
at clause </span></b><span
lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif'>‎</span><b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>7</span></b><b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.</span></b></p>

<p class=MsoListParagraph style='margin-left:1.0cm;text-indent:0cm'><span
style='font-size:11.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You
should print a copy of these Terms for future reference.</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt'><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>

<p class=Level1 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref246841802"><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>CONTRACT</span></span></a><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>
FORMATION</span></span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332012798"></a><a
name="_Ref332019834"></a><a name="_Ref244917339"><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>When you
place an order for Bits on the Platform, </span></a><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>you are offering to purchase such Bits from us
on these Terms. The agreement for the purchase of Bits by you from us will be
upon and subject to these Terms and will be formed when we accept the order by
either (i) providing you with the relevant Bits in accordance with <b>clause ‎2</b>.
or (ii) communicating our acceptance of the order, and this will be the &quot;<b>Agreement</b>.&quot;
</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Each
contract formed from any such order is an individual agreement separate from
any other agreement that may be entered into for other Bits with other Fans or
with you regarding other orders. </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>These
Terms are the only terms and conditions under which we will supply Bits to you
and will apply to the exclusion of all other terms and conditions save where
otherwise expressly set out herein.</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref172203836"></a><a name="_NN7"></a><b><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span class=Level1asHeadingtext><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>PROVISION OF BITS</span></span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Following
the conclusion of your purchase, we, acting through SongBits as our Agent, will
record your rights in and to the applicable Bits (and the right to receive
streaming revenue derived from the Recording under these Terms) and the Content
by recording your rights and ownership through a smart contract (in an
immutable blockchain).</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref244921647"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Provision
of the Bits is a service and will, in almost all cases, be instantaneous
(following confirmation that your order has been accepted). You confirm that
you are aware that you will not have the right to cancel any such purchase once
this agreement has been formed and the transaction to purchase the Bits has
concluded. </span></a></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref171954691"><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>BITS RIGHTS</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>For
illustrative and creative purposes, we may indicate the purchase of your Bits
as being a bar or other representation of the Recording. Still, the ownership
of the Content (including the Recording) shall always remain with the Artist <a
name="_Hlk172215857">(in respect to which all such rights are reserved to the
Artist and/or the applicable third-party rights holder)</a>. </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>The
purchase of a Bit grants you (i) ownership of all personal property rights to
that Bit and, if applicable, associated Artwork or digital assets (i.e. the
right to sell or otherwise dispose of such Bit) and (ii) the right to receive a
designated fractional share of our Royalty from the relevant Recording, such
fractional share is calculated as the number of Bits of a Recording purchased
divided by the total number of Bits of such Recording (evidenced by the
associated smart contract recording your purchase of Bits) (defined as &quot;<b>Your</b>
<b>Fractional Share</b>&quot;). </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You agree
that ownership of the Bit does not include ownership of the intellectual
property rights in the Content (in respect of which all such rights are
reserved to the Artist and/or the applicable third-party rights holder). </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We grant
you a worldwide, non-exclusive, non-transferable, royalty-free license to use,
copy, and display the Recordings associated with the Bit solely for the
following purposes: (i) for your own personal, non-commercial use and (ii) as
part of a third-party digital asset service associated with the purchased Bits
upon and subject to the applicable terms and conditions relating to its use and
the digital asset in question.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref172203532"><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular";
font-weight:normal'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>You may resell, gift and otherwise transfer
ownership of each of your purchased Bits, as a whole only, to third parties, if
and only when there is a facility made available by SongBits, as our Agent, on
the Platform (and you may only re-sell the Bits, together and conjunction with
any associated Content via the Platform – the reasons for this is so we can
ensure the corresponding rights are sold along with.)</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Our
rights contained in <b>clause ‎3.</b> will survive the expiry or termination of
this Agreement however it arises.</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref531496829"></a><a name="_Ref531496860"></a><a name="_Ref531496924"></a><a
name="_Ref3708226"></a><a name="_Ref332016995"><span class=Level1asHeadingtext><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>PRICE AND PAYMENT</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=Level1asHeadingtext><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>The price per Bit is set out on the Platform for each
Recording when you make an order. Using the Platform, you will pay our Agent
(as a disclosed collection agent for us)</span></span><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'> the price for the <span
class=Level1asHeadingtext>number of Bits you purchase</span>. </span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref342393100"><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>PAYMENT OF FAN ROYALTIES</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span class=Level1asHeadingtext><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular";font-weight:normal'>5.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We
shall be responsible for and shall pay you </span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Your Fractional Share of the
Royalty <span class=Level1asHeadingtext>that we receive in U.S. Dollars (or
other agreed currency) </span></span><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>30 days after receipt from the streaming services and after
deducting any applicable fees, transaction fees, or other payment processing
fees. Any sums we receive in foreign currency will be converted to U.S. Dollars
(or other agreed currency) at either the same rate we received them or the
current spot exchange rate at the transaction time.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span class=Level1asHeadingtext><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular";font-weight:normal'>5.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Our
payment of </span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Your
Fractional Share of the Royalty <span class=Level1asHeadingtext>is conditional
on you maintaining an account on the Platform and complying with the SongBits
Terms of Service regarding such an account, including, in particular, the
obligation to maintain an account with our Agent’s third-party payment
processor.</span></span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span class=Level1asHeadingtext><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>WARRANT</span></span><span class=Level1asHeadingtext><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>IES</span></span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Each
Party warrants, represents, and undertakes to the other that (i) it has total
capacity and authority to enter into and to perform these Terms; and (ii) once
this agreement is formed, it will constitute its legal, valid and binding
obligations.</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref531496834"></a><a name="_Ref531496865"></a><a name="_Ref531496929"></a><a
name="_Ref3708231"></a><a name="_Ref332027468"></a><a name="_NN10"></a><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>EXCLUSIONS AND LIMITATIONS OF LIABILITY</span></span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref229821861"></a><a
name="_Ref108003488"></a><a name="_NN221"></a><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>We will
have no Liability to you for any</span><a name="_Ref323217127"></a><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> indirect, consequential or
special loss</span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>
subject always to <span class=CrossReference>clause </span></span><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>7.2</span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332034552"></a><a
name="_Ref289783624"></a><a name="_NN224"></a><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Nothing
in these Terms will operate to exclude or restrict our Liability</span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>: (i) that cannot be excluded
or restricted in respect of death or personal injury resulting from negligence,
(ii) for fraud or fraudulent misrepresentation, or (iii) for any matter for
which we are not permitted by law to exclude or limit, or to attempt to exclude
or limit, our liability.<a name="_Ref307303229"></a></span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref531496835"></a><a name="_Ref531496866"></a><a name="_Ref531496930"></a><a
name="_Ref3708232"></a><a name="_Ref531496836"></a><a name="_Ref531496867"></a><a
name="_Ref531496931"></a><a name="_Ref3708233"><span class=Level1asHeadingtext><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>YOUR OBLIGATIONS</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref437334767"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You will</span></a><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>: (i) <a name="_Ref437334717">provide
us with all such information and assistance as we may reasonably require from
time to time to perform our obligations or exercise our rights under</a> these
Terms; (ii) obtain such licences, permits, consents and authorisations as may
be required for the purchase of Bits; and (iii) promptly give us written notice
of any dispute you are involved in and any claim or complaint made against you
in respect of Bits.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref246829629"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>You will
comply with our instructions and provide such cooperation and assistance as we
may request in connection with (i) any corrective action initiated by or
involving us to address actual or potential issues relating to Bits or (ii) any
notification to and/or investigation by a regulatory authority concerning
actual or potential defects, safety or compliance issues relating to Bits.</span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref246829651"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Notwithstanding
any other term of these Terms, we will not be in breach of these Terms, to the
extent our failure to perform or delay or defect in the performance of its
obligations under these Terms arises as a result of (i) any breach by you of
your obligations contained in this Agreement, (ii) us relying on any incomplete
or inaccurate data provided by a third party, or (iii) </span></a><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>us complying with any
instruction or request by you.</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref437271823"><b><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span class=Level1asHeadingtext><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>TERM AND TERMINATION</span></span></a><a name="_NN19"></a><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'><a name="_Toc314669536"></a></span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref242694280"></a><a
name="_NN20"></a><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>9.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Unless and until terminated
in accordance with its terms, these Terms shall last for so long as you remain
an owner and rights holder in and to Bits and/or any Content.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>If you</span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> commit a material breach of
these Terms, we may terminate this agreement by giving you not less than seven
days’ written notice to that effect.<a name="_Ref242694331"> </a></span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>If your
account on the Platform is terminated due to a breach of the SongBits Terms of
Service governing such account, this agreement will be terminated immediately.
You may have the right to appeal such termination by contacting our Agent. If,
following such appeal, your account is reinstated, then the Agreement will be
reinstated from the date of such termination. </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332035525"></a><a
name=longformclauseterminationforinsolvency></a><a name="_NN22"></a><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Following
the expiry or termination of th</span><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>ese Terms, all your rights to receive further royalty payments
under these Terms and your rights in and to the Bits and/or the Content will
automatically revert to us (or the applicable third-party licensor) – and in
such event, your right to receive any royalty payments under these Terms may
cease. We may re-sell your Bits and any associated Content after re-listing
them for sale on the Platform through our Agent (or, in our sole discretion, we
may cancel such rights and re-issue Bits and corresponding Content by way of
substitute for (or in addition to) your Bits and Content).</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332012830"></a><a
name="_Ref171940524"><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>NOTICE</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332018084"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Subject
to <span class=CrossReference>clause </span></span></a><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>10.3</span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, any notice given under or
in connection with these Terms will be </span><a name="_Ref473019958"></a><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>sent by e-mail</span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>. Our e-mail address is on
our profile on the Platform, and your e-mail address is associated with your
profile on the Platform.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref444781819"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Any
notice given will be deemed to have been served when sending the e-mail.</span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332018043"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>This <span
class=CrossReference>clause </span></span></a><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>10</span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> will not apply to the
service of any proceedings or other documents in a legal action to which the
Civil Procedure Rules apply.</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref332035722"><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=Level1asHeadingtext><span style='font-size:
11.0pt;font-family:"HKGrotesk-Regular"'>GENERAL</span></span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332018151"></a><a
name="_NN671"></a><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>11.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>These Terms constitute the
entire agreement between the parties and supersede any prior agreement or
arrangement </span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>with
respect to its subject matter. Nothing in <span class=CrossReference>clause </span></span><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>11.1</span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'> will be interpreted or
construed as limiting or excluding the liability of any person for fraud or
fraudulent misrepresentation.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref437272347"></a><a
name="_Ref437334792"><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>11.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>If any term of these Terms
(including any exclusion from, or limitation of, liability set out in <span
class=CrossReference>clause </span></span></a><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>7</span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>) is found by any court or
body or authority of competent jurisdiction to be illegal, unlawful, void or
unenforceable, such term will be deemed to be severed from these Terms, and
this will not affect the remainder of these Terms which will continue in full
force and effect.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>11.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>In the
event of any conflict between these Terms and the SongBits Terms of Service,
the former shall prevail to the extent such terms relate to the sale of the
Bits and the provision of the Content. </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_NN812"></a><a name="_NN675"></a><a
name="_NN35"></a><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>11.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Each party agrees that it is
an independent contractor entering into these Terms as principal and not as
agent for or for the benefit of any other person. The Agent is not a party to
these Terms. </span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>11.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Other
than as expressly stated otherwise herein, the parties do not intend that any
term of these Terms will be enforceable under the Contracts (Rights of Third
Parties) Act 1999 by any person.</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>11.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>As set
out in <b>clause </b></span><b><span
lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif'>‎</span></b><b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>3.5</span></b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, you are not entitled to
assign, transfer, charge, hold on trust for any person, or deal in any other
manner with any of your rights under<a name="_Ref246834620"> these Terms.</a></span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm;page-break-after:avoid'><a
name="_Ref501886507"></a><a name="_Ref509377387"></a><a name="_Ref518721188"><b><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span class=Level1asHeadingtext><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>GOVERNING LAW AND JURISDICTION</span></span></a><a
name="_NN681"></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name=Jurisdiction1><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>These
Terms and any non-contractual obligations arising out of or in connection with
it will be governed by the law of England and Wales.</span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref332104085"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Subject
to <span class=CrossReference>clause </span></span></a><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>12.3</span></span><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, the courts of England and
Wales have exclusive jurisdiction to determine any dispute arising out of or in
connection with these Terms (including any non-contractual obligations).</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref171950586"><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>12.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Either
party may seek interim injunctive relief or any other interim measure of
protection in any court of competent jurisdiction.</span></a></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>12.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>Subject
to </span><span class=CrossReference><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>clause </span></span><span
class=CrossReference><span lang=AR-SA style='font-size:11.0pt;font-family:"Times New Roman",serif;
font-weight:normal'>‎</span></span><span class=CrossReference><span
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>12.3</span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, each party
waives any objection to and agrees to submit to the jurisdiction of the courts
of England and Wales. Each party agrees that a judgment or order of any such
court is binding upon it and may be enforced against it in the courts of any
other jurisdiction.</span></p>

<p class=Level1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><a name="_Ref531496825"></a><a
name="_Ref531496856"></a><a name="_Ref531496920"></a><a name="_Ref3708222"><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>13.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
class=Level1asHeadingtext><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>GLOSSARY
AND INTERPRETATION</span></span></a></p>

<p class=Body1 style='margin:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>&nbsp;</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>13.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>In these
Terms, the following words and expressions have the following meanings and, in
addition, any words and expressions defined in any clause will have the same
meaning when used in any other clause:</span></p>

<p class=Level2 style='margin:0cm;text-indent:0cm'><span style='font-size:11.0pt;
font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=576
 style='margin-left:1.0cm;border-collapse:collapse;border:none'>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Agent&quot; or
  &quot;SongBits&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><b><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>SONGBITS LIMITED</span></b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>, a company registered in
  England and Wales with company registration number 13838925 whose company
  registered address is Level 3 207 Regent Street, London, United Kingdom, W1B
  3HH</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Artist&quot; </span></b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>(also referred to as<b> </b>&quot;<b>we</b>&quot;,
  &quot;<b>our</b>&quot;<b> </b>or &quot;<b>us</b>&quot;)</span></p>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>the person who submits a Recording to the
  Platform </span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Artwork&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>digital artwork relating to the Recordings
  and the Artist</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Bits&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin-bottom:0cm'><span style='font-size:11.0pt;
  font-family:"HKGrotesk-Regular"'>the right to a bit of the Recording (but <a
  name="_Hlk174521830">this shall, for the avoidance of doubt, not attach to
  any particular note or part of any Recording, only a percentage of the
  relevant right to receive a specific corresponding fraction of music
  streaming royalties of such Recording </a>in accordance with these Bits Sale
  Terms)</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Content&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>Recordings and Artwork, but excluding any
  and all mediums of traditional and/or physical mediums of product
  distribution now known or to be known or invented in the future including
  cassette tape(s), DAT (digital audio tape(s)), CD(s) (compact discs) and
  vinyl</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Liability&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>liability arising out of or in connection
  with this Agreement, whether in contract, tort, misrepresentation,
  restitution, under statute or otherwise contained in this Agreement and/or
  arising from a breach of, or a failure to perform or defect or delay in
  performance of, any of a party’s obligations under this Agreement, in each
  case howsoever caused including if caused by negligence or if caused by a
  deliberate and/or repudiatory breach by that party</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Platform&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>our Agent’s platform known as the SongBits
  platform or any other replacement as we may inform you from time to time</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Release Page&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>means the page set out on the Platform which
  details, in respect of each Recording: (i) how a Royalty is calculated, and
  (ii) how much of a percentage of the relevant right to receive revenue is
  attributed to each Bit </span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Royalty&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>the digital streaming revenue the Artist is
  entitled to earn <span class=Level1asHeadingtext>from the streaming services
  which are directly attributable to our Agent’s exploitation of the relevant
  Rec</span>ording (note that we may not be the only entity or person entitled
  to receive streaming revenue from exploitation of the Recording, so this
  royalty is limited only to our respective share of royalty payments derived
  from the Recording)</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;Recording&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>the licensed master recordings of a song or
  track by the Artist as specified in your order</span></p>
  <p class=Body style='margin:0cm;text-indent:-.1pt'><span style='font-size:
  11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=210 valign=top style='width:157.3pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body align=left style='margin:0cm;text-align:left;text-indent:-.1pt'><b><span
  style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&quot;SongBits Terms of
  Service&quot;</span></b></p>
  </td>
  <td width=367 valign=top style='width:275.05pt;border:none;padding:0cm 5.65pt 0cm 5.65pt'>
  <p class=Body style='margin-bottom:0cm'><span style='font-size:11.0pt;
  font-family:"HKGrotesk-Regular"'>means those terms which apply to the use of the Platform
  (and which are available from time to time on the Platform)</span></p>
  <p class=Body style='margin-bottom:0cm'><span style='font-size:11.0pt;
  font-family:"HKGrotesk-Regular"'>&nbsp;</span></p>
  </td>
 </tr>
</table>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>&nbsp;</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>13.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>all
headings are for ease of reference only and will not affect the construction or
interpretation of these Terms;</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>13.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>references
to a &quot;<b>person</b>&quot; include any individual, body corporate,
association, partnership, firm, trust, organisation, joint venture, government,
local or municipal authority, governmental or supra-governmental agency or
department, state or agency of the state or any other entity (in each case
whether or not having separate legal personality);</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>13.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>any words
following the words &quot;<b>include</b>&quot;, &quot;<b>includes</b>&quot;,
&quot;<b>including</b>&quot;, &quot;<b>in particular</b>&quot; or any similar
words or expressions will be construed without limitation and accordingly will
not limit the meaning of the words preceding them and the ejusdem generis rule
will not apply;</span></p>

<p class=Level2 style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;text-indent:0cm'><span style='font-size:11.0pt;font-family:
"HKGrotesk-Regular"'>13.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>references
to &quot;<b>in writing</b>&quot; or &quot;<b>written</b>&quot; include e-mail
but no other electronic messaging methods.</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-US
style='font-size:11.0pt;font-family:"HKGrotesk-Regular"'>&nbsp;</span></b></p>

<p class=MsoNormal>&nbsp;</p>

`

import React, { useState } from 'react';
import styled from 'styled-components';

import { sm } from 'utils/constants';
import QuickLinksMenu from 'common/layout/QuickLinksMenu';
import QuickLinkIcon from 'common/icons/QuickLinks.icon';

type Link = { title: string; link: string };

type QuickLinksProps = {
  links: Link[];
  loading: boolean;
  isLogout?: boolean;
};

const QuickLinksMobile = ({ links, loading, isLogout = false }: QuickLinksProps) => {
  const [isQuickLinksOpen, setQuickLinksOpen] = useState<boolean>(false);

  if (loading) {
    return (
      <>
        <span
          className={
            'placeholder-wave placeholder fs-18 lh-18 bg-secondary col-6'
          }
          style={{ marginBottom: '15px' }}>
          &nbsp;
        </span>
      </>
    );
  }
  return (
    <>
      <Container>
        <QuickLinkButton
          onClick={() => {
            setQuickLinksOpen(!isQuickLinksOpen);
          }}>
          <QuickLinkIcon></QuickLinkIcon> quick links
        </QuickLinkButton>
      </Container>
      <QuickLinksMenu
        links={links}
        isLogout={isLogout}
        isQuickLinksOpen={isQuickLinksOpen}
        setQuickLinksOpen={setQuickLinksOpen}
      />
    </>
  );
};

const Container = styled.div`
  width: auto;
  display: block;

  @media (min-width: ${sm}px) {
    display: none;
  }
`;

const QuickLinkButton = styled.button`
    font-family: 'HKGrotesk-Regular';
    font-size: 18px;
    color: #FFF;
    background #000;
    border: none;
    cursor: pointer;
    margin-bottom: 30px;
`;

export default QuickLinksMobile;

import { useEffect } from 'react';
import { useStoreActions } from 'store/store';

export function useHeaderTransparentSwitching(useSwitch: boolean) {
  const setHeaderTransparent = useStoreActions(
    (state) => state.style.setHeaderTransparent,
  );
  useEffect(() => {
    const routerContainer = document.getElementById('router-container');
    const handleScroll = () => {
      const scrollTop = routerContainer?.scrollTop;
      setHeaderTransparent(scrollTop === 0);
    };

    if (useSwitch) {
      setHeaderTransparent(true);
      routerContainer?.addEventListener('scroll', handleScroll);
    } else {
      setHeaderTransparent(false);
    }

    return () => {
      setHeaderTransparent(false);
      routerContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [useSwitch]);
}

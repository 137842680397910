import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Tip from 'common/components/Tip/Tip';
import { md } from 'utils/constants';

type AccountLinkProps = {
  value: string;
  desc: string;
  analytic?: string | JSX.Element;
  padding?: number;
  tooltipText?: string
};

const StatContainer: React.FC<AccountLinkProps> = ({
  value,
  desc,
  analytic,
  padding,
  tooltipText
}) => {
  return (
    <StatisticsContainer padding={padding}>
      <StatValue text={value} />
      <StatDesc text={desc} />
      <StatAnalytic text={analytic ? analytic : ''} />
      {/* <InfoIcon>
        <QuestionMark />
      </InfoIcon> */}

      {tooltipText ? <>
      <InfoIcon>
        <Tip
          text={
            <div>
              <Typography
                text={tooltipText}
                fontSize={'fz16'}
                lineHeight={'20px'}
              />
            </div>
          }
        />
      </InfoIcon>
      </> : <></>}
    </StatisticsContainer>
  );
};

const StatisticsContainer = styled.div<{ padding?: number }>`
  width: 100%;
  position: relative;

  padding-top: 10px;
  padding-left: 20px;
  margin-bottom: 10px;

  ${(props) => (props.padding ? `padding-bottom: ${props.padding}px` : '')};

  @media (min-width: ${md}px) {
    width: 334px;
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: unset;
    margin-bottom: ${(props) =>
      props.padding ? `${props.padding}px` : `unset`};
  }
`;

const StatValue = styled(Typography)`
  color: ${theme.colors.yellow};
  font-family: 'HKGrotesk-Black';
  font-size: 48px;
  font-weight: 900;
  line-height: 60px;

  padding-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

const StatDesc = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  font-size: 18px;
  line-height: 18px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

const StatAnalytic = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 14px;
  font-weight: 900;
  line-height: 35px;
`;

const InfoIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 17px;
  width: auto;
`;

export default StatContainer;

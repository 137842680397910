import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik,  } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

//import Tip from 'common/components/Tip/Tip';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';
import { notify } from 'common/components/Toaster/Toaster';
import restService from 'services/rest.service';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Email from 'common/icons/Email.icon';
import UserProfile from 'common/icons/UserProfile.icon';
import PageContainer from 'common/layout/PageContainer';
import Radio from 'modules/auth/components/waitlist/Radio';
import theme from 'theme/theme';
import Caption from 'common/components/Caption/Caption';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import AccountBanner from '../assets/create-account.jpg';

import {
  ContentButton,
  FirstButton,
  Form,
  HeadContainer,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  Wrapper,
  HeaderTitleExtraContainer,
  //BottomLine,
  UserTypeContainer,
  ErrorTypography,
  UserTypeTitleContainer,
  //TipContainer,
  TermsContainer,
} from './styled/JoinWaitlist.styled';


import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';
//import WalletPublic from 'common/icons/WalletPublic.icon';
//import Typography from 'common/components/Typography/Typography';

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  userType: Yup.string().required('User type is required'),
  walletAddress: Yup.string()
    .min(26, 'code is too short. must be 26 - 36 chars')
    .max(36, 'code is too long. must be 26 - 36 chars'),
});

const JoinWaitlist = () => {
  const navigate = useNavigate();
  const viewport = useViewport();

  const [toastId, setToastId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      userType: '',
      walletAddress: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {
    toast.dismiss(toastId);
    setLoading(true);

    const data = Object.assign({}, values);
    
    if (values.userType === 'Artist') {
      data.userType = 'artist';
    }
    if (values.userType === 'Fan/Collector') {
      data.userType = 'fan';
    }

    restService
      .waitlist(data)
      .then((res) => {
        res;
        navigate('/join-waitlist/thanks');
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.error === 'Error: Email already exists' || error.response.data && error.response.data.error === 'User Already Exists' || error.response.data && error.response.data.error === "Already In Waitlist") {
          errors.email = "Looks like that email is already in use."
        } else {
          const toastId = notify({
            autoClose: false,
            customComponent: (
              <ToasterModal
                title="oops!"
                content="Something went wrong. Please try again."
              />
            ),
          });
          setToastId(toastId as string);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer pageTitle='Join Waitlist | SongBits'>
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={'join the '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="waitlist"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                &nbsp;&nbsp;
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          <InputContainer>
            <Icon>
              <UserProfile />
            </Icon>
            <InputWithIcon
              value={values.name}
              inputName={'name'}
              height={viewport.width < xs ? 65 : 70}
              type="text"
              onChange={handleChange('name')}
              placeholder="Name"
              autoComplete="name"
              withBottomLine
              error={
                Boolean(errors.name && touched.name) ? errors.name : undefined
              }
            />
          </InputContainer>

          <InputContainer>
            <Icon>
              <Email />
            </Icon>
            <InputWithIcon
              value={values.email}
              height={viewport.width < xs ? 65 : 70}
              type="email"
              onChange={(e) => {
                handleChange(e)
              }}
              placeholder="Your Email"
              autoComplete="email"
              inputName={'email'}
              withBottomLine
              error={
                Boolean(errors.email && touched.email)
                  ? errors.email
                  : undefined
              }
            />
          </InputContainer>

          <UserTypeContainer>
            <Icon top="20">
              <UserProfile />
            </Icon>

            <UserTypeTitleContainer>
              <Caption text="I'm a" />
            </UserTypeTitleContainer>

            <Radio
              radio={values.userType}
              setRadio={handleChange('userType')}
            />
            {errors.userType && touched.userType && (
              <ErrorTypography
                text={'User type is required'}
                fontSize="fz10"
                fontColor={theme.colors.yellow}
              />
            )}
          </UserTypeContainer>

          {/*<BottomLine />

          <InputContainer padding="0 70px 0 0">
            <Icon>
              <WalletPublic />
            </Icon>
            <InputWithIcon
              value={values.walletAddress}
              height={viewport.width < xs ? 65 : 70}
              type="text"
              onChange={handleChange('walletAddress')}
              placeholder="Public Wallet Address (Optional)"
              inputName={'walletAddress'}
              error={
                Boolean(errors.walletAddress && touched.walletAddress)
                  ? errors.walletAddress
                  : undefined
              }
            />
            <TipContainer>
              <Tip
                text={
                  <div>
                    <Typography
                      text={
                        'If you have a Crypto wallet, feel free to add the public address here to be whitelisted.'
                      }
                      fontSize={'fz14'}
                      lineHeight={'18px'}
                    />
                  </div>
                }
                width={210}
              />
            </TipContainer>
              </InputContainer>*/}


          <TermsContainer>
          I agree to receiving information on the forthcoming releases by email and other promotional material.
          </TermsContainer>

          <FirstButton
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.white}
            isLoading={loading}
            disabled={loading}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="join" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <ContentButton
                  text="now"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={handleSubmit}
          />
        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(JoinWaitlist);

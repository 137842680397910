import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Typography from 'common/components/Typography/Typography';
import BottomLine from 'common/components/BottomLine/BottomLine';
import Spacer from 'common/components/Spacer/Spacer';
import OpenYellow from 'common/icons/OpenYellow.icon';

import theme from 'theme/theme';
import { ISongRoyaltyOverviewData } from 'typings/account';
import { MONTHS } from 'utils/constants';

interface SongRoyaltyCardProps {
  royalty: ISongRoyaltyOverviewData;
  bottomLine: boolean;
}

function formatDate(date: string) {
  const d = new Date(date);
  return d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + d.getFullYear();
}


const SongRoyaltyCard: FC<SongRoyaltyCardProps> = ({ royalty, bottomLine }) => {
  return (
    <Container>
      <ContentContainer>
        <SongInfoContainer>
          <Image src={royalty.song_image} />
          <div>
            <SongName
              fontColor={theme.colors.yellow}
              text={royalty.song_name}
              fontSize="fz36"
              fontWeight="bold"
              lineHeight="28px"
              letterSpacing="-0.05em"
            />
            <ArtistName
              text={royalty.artist_name}
              fontSize="fz36"
              fontWeight="light"
              lineHeight="28px"
            />
            <Spacer height={20} />
            <RoyaltyContainer show={true}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  text="Purchase Date"
                  fontSize="fz14"
                  fontWeight="light"
                  lineHeight="24px"
                />
                <Typography
                  text={formatDate(royalty.purchase_date)}
                  fontSize="fz14"
                  fontWeight="bold"
                  lineHeight="24px"
                />
                <Spacer height={20} />
                {royalty.royalty_period_month ? (
                  <>
                    <Typography
                      text={`${royalty.royalty_period_month} streams`}
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <ValuePercentContainer>
                      <Typography
                        text={`${Number(royalty.royalty_period_streams).toLocaleString()}`}
                        fontSize="fz14"
                        fontWeight="bold"
                        lineHeight="24px"
                      />
                      <Typography
                        text={`(${royalty.royalty_period_streams_diff})`}
                        fontSize="fz14"
                        fontWeight="light"
                        lineHeight="24px"
                      />
                    </ValuePercentContainer>
                    <Spacer height={20} />
                    <Typography
                      text="Royalty per Bit"
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <Typography
                      text={`$${royalty.royalty_period_per_bit}`}
                      fontSize="fz14"
                      fontWeight="bold"
                      lineHeight="24px"
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      text={`Streams`}
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <ValuePercentContainer>
                      <Typography
                        text={'awaiting data'}
                        fontSize="fz14"
                        fontWeight="bold"
                        lineHeight="24px"
                      />
                    </ValuePercentContainer>

                    <Spacer height={20} />
                    <Typography
                      text="Royalty per Bit"
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <Typography
                      text={`awaiting data`}
                      fontSize="fz14"
                      fontWeight="bold"
                      lineHeight="24px"
                    />
                  </>
                )}
              </div>
              <div>
                <Typography
                  text="bits held (% of song)"
                  fontSize="fz14"
                  fontWeight="light"
                  lineHeight="24px"
                />
                <ValuePercentContainer>
                  <Typography
                    text={`${Number(royalty.no_bits).toLocaleString()}`}
                    fontSize="fz14"
                    fontWeight="bold"
                    lineHeight="24px"
                  />
                  <Typography
                    text={`(${royalty.no_bits_percent})`}
                    fontSize="fz14"
                    fontWeight="light"
                    lineHeight="24px"
                  />
                </ValuePercentContainer>
                <Spacer height={20} />

                {royalty.royalty_period_month ? (
                  <>
                    <Typography
                      text="Total streams"
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <ValuePercentContainer>
                      <Typography
                        text={`${Number(royalty.total_streams).toLocaleString()}`}
                        fontSize="fz14"
                        fontWeight="bold"
                        lineHeight="24px"
                      />
                      <Typography
                        text={`(${royalty.total_streams_diff})`}
                        fontSize="fz14"
                        fontWeight="light"
                        lineHeight="24px"
                      />
                    </ValuePercentContainer>
                    <Spacer height={20} />
                    <Typography
                      text={`${royalty.royalty_period_month} Royalty`}
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <Typography
                      text={`$${royalty.royalty_period_total}`}
                      fontSize="fz14"
                      fontWeight="bold"
                      lineHeight="24px"
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      text="Total streams"
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <ValuePercentContainer>
                      <Typography
                        text={`awaiting data`}
                        fontSize="fz14"
                        fontWeight="bold"
                        lineHeight="24px"
                      />
                    </ValuePercentContainer>
                    <Spacer height={20} />
                    <Typography
                      text={'Royalty'}
                      fontSize="fz14"
                      fontWeight="light"
                      lineHeight="24px"
                    />
                    <Typography
                      text={`awaiting data`}
                      fontSize="fz14"
                      fontWeight="bold"
                      lineHeight="24px"
                    />
                  </>
                )}
              </div>
            </RoyaltyContainer>

            <Spacer height={25} />
            
            {royalty.royalty_period_month ? <><SongLink
              to={`${
                royalty.song_slug
                  ? `/account/royalty/${royalty.song_slug}`
                  : ''
              }`}>
              <OpenYellow width={20} height={20} />
              <Typography
                text="View song royalty detail"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.05em"
              />
            </SongLink></> : <></>}
          </div>
        </SongInfoContainer>
      </ContentContainer>
      <Spacer height={25} />
      {bottomLine && <BottomLine />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  max-width: 550px;
`;

const SongInfoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 17px;
`;

const SongName = styled(Typography)`
  font-size: 24px;
  line-height: 28px;

  @media (min-width: 992px) {
    font-size: 36px;
    line-height: 40px;
  }
`

const ArtistName = styled(Typography)`
  font-size: 24px;
  line-height: 28px;

  @media (min-width: 992px) {
    font-size: 36px;
    line-height: 40px;
  }
`

const Image = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 10px;

  @media (min-width: 992px) {
    width: 150px;
    height: 150px;
    border-radius: 20px;
  }
`;

const RoyaltyContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: flex-start;
  gap: 50px;
`;

const SongLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
`;

const ValuePercentContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export default SongRoyaltyCard;

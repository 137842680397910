import * as React from 'react';

import { IconProps } from '../common.types';

const Clock = ({ width = 12, height = 12 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM8.36384 7.8442L7.9808 8.36652C7.97247 8.37789 7.96198 8.38751 7.94992 8.39481C7.93786 8.40211 7.92447 8.40696 7.91054 8.40908C7.8966 8.41119 7.88238 8.41054 7.86869 8.40714C7.85501 8.40374 7.84213 8.39768 7.8308 8.38929L5.61562 6.77411C5.60182 6.7642 5.59061 6.75112 5.58292 6.73597C5.57522 6.72082 5.57129 6.70404 5.57143 6.68705V3C5.57143 2.94107 5.61964 2.89286 5.67857 2.89286H6.32277C6.3817 2.89286 6.42991 2.94107 6.42991 3V6.31473L8.33973 7.69554C8.38795 7.72902 8.39866 7.79598 8.36384 7.8442Z"
      fill="white"
    />
  </svg>
);

export default Clock;

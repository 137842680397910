import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface AlertProps {
  title: string;
  content: string;
  seconds: number;
}

const Alert: FC<AlertProps> = ({ title, content, seconds }) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHidden(true);
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <AlertContainer hidden={isHidden}>
      <Title>{title}</Title>
      &nbsp;
      <Content>{content}</Content>
    </AlertContainer>
  );
};

const AlertContainer = styled.div<{ hidden: boolean }>`
  width: 100vw;
  height: 100px;

  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;

  background: #ffd600;
  z-index: 100;
`;

const Title = styled.p`
  font-family: 'HKGrotesk-Black';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
`;

const Content = styled.p`
  font-family: 'HKGrotesk-Light';
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
`;

export default Alert;

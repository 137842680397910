import Typography from 'common/components/Typography/Typography';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme/theme';

interface UsersTableLayoutProps {
  title: string;
}

const UsersTableLayout: FC<UsersTableLayoutProps> = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Typography
        text={title}
        fontColor={theme.colors.yellow}
        fontSize="fz24"
        fontWeight="bold"
        letterSpacing='-0.03em'
      />

      <TableContainer>{children}</TableContainer>

      <LinkButton onClick={() => navigate('/artist/insights/users')}>
        <Typography text="View all users" 
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.yellow} />
      </LinkButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 34px;
  margin-top: 20px;
`;

const TableContainer = styled.div`
  margin: 15px 0;
`;

const LinkButton = styled.div`
  cursor: pointer;
`;

export default UsersTableLayout;

import { DropsDataType } from 'modules/account/types';
import { DropsType } from 'typings/types';
import { getSoldPercent } from 'utils/calculation';

export function formatDropDate(date: string) {
  const values = date.split('T')[0].split('-');

  const new_date = new Date(
    parseInt(values[0].substring(0)),
    parseInt(values[1]) - 1,
    parseInt(values[2]),
  );

  return (
    values[2] +
    ' ' +
    new_date.toLocaleString('default', { month: 'short' }) +
    ' ' +
    values[0].substring(0)
  );
}

type DropResponseType = {
  title: string;
  slug: string;
  artist_slug: string;
  release_at: string;
  audio_length_ms: number;
  remaining_bits: number;
  sold_bits_inverted: number,
  editions_count: number;
  image: string;
  song_id: string;
};

export function extractDrops(drops: DropResponseType[]) {
  const resultDrops: DropsDataType[] = drops.map((drop: DropResponseType) => {
    return {
      name: drop.title,
      date: formatDropDate(drop.release_at),
      editions: drop.editions_count.toString(),
      sold: getSoldPercent(
        drop.audio_length_ms,
        1,
        drop.sold_bits_inverted
      ).toString() + '%',
      cover: drop.image,
      status: drop.sold_bits_inverted === 0 ? DropsType.COMPLETED : DropsType.CURRENT,
      link: '/' + drop.artist_slug + '/' + drop.slug,
      dropSummaryLink: '/artist/releases/summary?id=' + drop.song_id,
    };
  });

  return resultDrops;
}

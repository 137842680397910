import * as React from 'react';

import { IconProps } from '../common.types';

const TikTok = ({ height = 18, width = 15 }: IconProps) => (
  <svg
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7561 4.22222C13.8587 4.02488 13.0469 3.54832 12.4371 2.86093C11.8273 2.17354 11.451 1.31066 11.3621 0.396115V0H8.25622V12.3066C8.25685 12.8526 8.08565 13.385 7.76688 13.8284C7.4481 14.2718 6.99791 14.6036 6.48006 14.7769C5.96222 14.9501 5.40299 14.9561 4.88158 14.7939C4.36016 14.6316 3.90301 14.3095 3.57487 13.873C3.28089 13.4867 3.10087 13.0258 3.05517 12.5424C3.00948 12.0591 3.09993 11.5726 3.31629 11.138C3.53266 10.7034 3.86631 10.338 4.27951 10.0831C4.69272 9.82828 5.16899 9.69411 5.65447 9.6958C5.92296 9.69427 6.19012 9.73373 6.4467 9.81284V6.66193C6.14811 6.62495 5.84722 6.60991 5.54644 6.61691C4.4385 6.64652 3.36323 6.99857 2.45232 7.62995C1.5414 8.26133 0.834376 9.14464 0.417813 10.1717C0.00125022 11.1988 -0.106767 12.3251 0.10699 13.4126C0.320746 14.5001 0.846998 15.5017 1.6213 16.2947C2.41487 17.1015 3.43037 17.6542 4.53877 17.8826C5.64717 18.1109 6.79844 18.0047 7.84629 17.5772C8.89415 17.1498 9.79128 16.4205 10.4237 15.482C11.0561 14.5435 11.3953 13.4382 11.3981 12.3066V6.00474C12.6508 6.89955 14.1529 7.37822 15.6924 7.37313V4.31225C15.39 4.31349 15.0883 4.28332 14.7921 4.22222H14.7561Z"
      fill="white"
    />
  </svg>
);

export default TikTok;

import * as Yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";
import { isAddress } from "web3-utils";

Yup.addMethod<Yup.StringSchema>(Yup.string, 'isWalletAddress', function (errorMessage) {
  return this.test(`test-address-type`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value && isAddress(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    isWalletAddress(errorMessage: string): StringSchema<TType, TContext>;
  }
}

export default Yup;

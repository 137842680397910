import React, { FC, useEffect, useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import styled from 'styled-components';

import { useViewport } from 'use-viewport';
import { useLocation } from 'react-router-dom';

import { escToClose } from 'utils/functions';

import InstgaramShareModal from './components/InstgaramShareModal/InstgaramShareModal';
import ShareModalLayout from './components/ShareModalLayout/ShareModalLayout';

import Facebook from 'common/icons/Facebook.icon';
import Twitter from 'common/icons/Twitter.icon';
import Instagram from 'common/icons/Instagram.icon';

import { xs } from 'utils/constants';
import defaultInstagramShareImage from '../../../assets/default-instagram-share-image.png';
import ChainIcon from 'common/icons/Chain.icon';
import UrlShareModal from './components/UrlShareModal/UrlShareModal';

interface ModalProps {
  onChange: () => void;
  changeModalPosition?: (value: string) => void;
  imageLink?: string;
  shareUrl?: string;
}

const SongProfileShareModal: FC<ModalProps> = ({
  onChange: onClose,
  changeModalPosition,
  imageLink,
  shareUrl,
}) => {
  const viewport = useViewport();
  const location = useLocation();

  const iconsSize = viewport.width >= xs ? 55 : 34;
  const shareURL = 'https://songbits.com' + (shareUrl || location.pathname).replace('/bits', '');

  const [showShareInstagram, setShowShareInstagram] = useState<boolean>(false);
  const [showShareUrl, setShowShareUrl] = useState<boolean>(false);

  const [modalType, setModalType] = useState<string>('root');

  useEffect(escToClose(onClose), []);

  function handleClose() {
    changeModalPosition && changeModalPosition('40%');
    if (showShareInstagram) return setShowShareInstagram(false);
    if (showShareUrl) return setShowShareUrl(false);

    onClose();
  }

  return (
    <ShareModalLayout onClose={handleClose} contentClassname={`${modalType}-share-modal-content`}>
      {!showShareInstagram && !showShareUrl && (
        <ButtonContainer>
          <FacebookShareButton 
            url={shareURL} 
            className={'facebook-share-button'} 
          >
            <Facebook height={iconsSize} width={iconsSize} opacity={1} />
          </FacebookShareButton>

          <ShareButton
            onClick={() => {
              changeModalPosition &&
                changeModalPosition(viewport.width < xs ? '25%' : '30%');
              setShowShareInstagram(true);
              setModalType('instagram')
            }}
            className={'instagram-share-button'}>
            <Instagram height={iconsSize} width={iconsSize} opacity={1} />
          </ShareButton>

          <TwitterShareButton 
            url={shareURL} 
            className={'x-share-button'}
          >
            <Twitter height={iconsSize + 7} width={iconsSize + 7} opacity={1} />
          </TwitterShareButton>

          <ShareButton
            onClick={() => {
              changeModalPosition &&
                changeModalPosition(viewport.width < xs ? '25%' : '30%');

              setShowShareUrl(true);
              setModalType('url')
            }}
            className={'url-share-button'}>
            <ChainIcon height={iconsSize} width={iconsSize} />
          </ShareButton>
        </ButtonContainer>
      )}

      {showShareInstagram && (
        <InstgaramShareModal
          shareUrl={shareURL}
          imageLink={imageLink || defaultInstagramShareImage}
        />
      )}

      {showShareUrl && <UrlShareModal shareUrl={shareURL} />}
    </ShareModalLayout>
  );
};

const ShareButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  transform: scale(1);
  gap: 0px;

  @media (min-width: 576px) {
    transform: none;
    gap: 0px;
  }
`;

export default SongProfileShareModal;

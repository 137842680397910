import React, { FC, useRef, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import styled from 'styled-components';

import { useClickOutside } from 'hooks/useClickOutside';

import Typography from 'common/components/Typography/Typography';
import ArrowToDown from 'common/icons/ArrowToDown.icon';
import theme from 'theme/theme';

interface DropdownProps {
  data: { label: string; value: string | number }[];
  currentItem: { label: string; value: string | number };
  onSelect?: (selectedItem: { label: string; value: string | number }) => void;
  width?: number;
}

const Dropdown: FC<DropdownProps> = ({
  data,
  currentItem,
  onSelect = () => {},
  width,
}) => {
  const [show, setShow] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setShow(false));

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton
        width={width}
        onClick={() => {
          setShow((prev) => !prev);
        }}>
        <Typography text={currentItem.label} fontWeight="light" />
        <ArrowToDownIcon show={show}>
          <ArrowToDown />
        </ArrowToDownIcon>
      </DropdownButton>

      {show && (
        <DropdownList>
          {data.map((item, index) => (
            <DropdownItem
              key={`dropdown_list_item_${index}${uuid4()}`}
              onClick={() => {
                onSelect(item);
                setShow(false);
              }}>
              <Typography text={item.label} fontWeight="light" />
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

const ArrowToDownIcon = styled.div<{ show: boolean }>`
  transform: rotate(${(props) => (props.show ? '180deg' : '0deg')});
`;

const DropdownButton = styled.button<{ width?: number }>`
  width: 257px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 10px;

  background-color: ${theme.colors.black};

  border: 1px solid #ffd600;
  border-radius: 10px;

  @media (min-width: 768px) {
    width: ${(props) => (props.width ? props.width : 309)}px;
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownList = styled.div`
  position: absolute;
  overflow-y: scroll;
  max-height: 240px;

  width: 309px;

  display: flex;
  flex-direction: column;

  background-color: ${theme.colors.black};

  z-index: 10;
`;

const DropdownItem = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 10px 10px;

  border: 1px ${theme.colors.black} solid;
  background-color: ${theme.colors.black};

  &:hover {
    border: 1px ${theme.colors.yellow} solid;
  }
`;

export default React.memo(Dropdown);

import React, { FC, useMemo, memo } from 'react';

import UsersTableLayout from '../../ArtistAnalytics/components/layout/UsersTableLayout/UsersTableLayout';
import Table from 'common/components/Table/Table';
import { IUserByActivity } from 'services/types';
import { useViewport } from 'use-viewport';
import ProfileIcon from 'common/icons/Profile.icon';

interface UsersByActivityProps {
  data: IUserByActivity[];
}

const UsersByActivity: FC<UsersByActivityProps> = ({ data }) => {
  const viewport = useViewport();

  const tableColumns = [
    'user',
    viewport.width < 576 ? 'no of purchases' : '# of purchases',
    'last activity',
  ];

  const formatUsernameAndIcon = (
    username: string,
    slug: string,
    avatar: string | null,
  ) => {
    return (
      <div
        style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}
        onClick={() => {
          window.open(`${window.location.origin}/${slug}`, '_blank');
        }}>
        {avatar ? (
          <img
            src={avatar}
            width={25}
            height={25}
            style={{ borderRadius: '50%' }}
          />
        ) : (
          <ProfileIcon width={25} />
        )}
        <span style={{ cursor: 'pointer' }}>{username}</span>
      </div>
    );
  };

  const usersByActivity = useMemo(
    () =>
      data
        .sort(
          (a, b) =>
            Number(b.number_of_purchases.replaceAll(',', '')) -
            Number(a.number_of_purchases.replaceAll(',', '')),
        )
        .slice(0, 3)
        .map((user) => [
          formatUsernameAndIcon(user.username, user.slug, user.avatar),
          user.number_of_purchases,
          user.most_recent_activity,
        ]),
    [data],
  );

  return (
    <UsersTableLayout title="top users by activity">
      <Table
        columns={tableColumns}
        data={usersByActivity}
        style={{
          tableLayout: 'fixed',
        }}
      />
    </UsersTableLayout>
  );
};

export default memo(UsersByActivity);

import React, { useMemo, FC, useState, useEffect } from 'react';
import { useViewport } from 'use-viewport';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import BottomLine from 'common/components/BottomLine/BottomLine';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { CountryCodes } from 'utils/country-codes';
import { USDistricts } from 'utils/us-districts';
import restService from 'services/rest.service';
import { useStoreActions, useStoreState } from 'store/store';
import { checkStripePayError } from 'utils/stripe-errors';

import {
  BoldText,
  CloseButton,
  ContentButton,
  CustomComponentContainer,
  DropDown,
  DropdownContainer,
  FirstButton,
  InputContainer,
  InputWithIcon,
  LightText,
  TextContent,
  WhiteButton,
  ThreeDSDiv,
  HostedFields,
  HostedFieldSmall,
  HostedFieldLabel,
  HostedFieldError
} from './styled/AddCardModal.styled';

import {
  CardSchema,
  PaymentModalType,
} from 'modules/payments/types';
import { BeatLoader } from 'react-spinners';

import 'modules/account/pages/styles/AddressAutoComplete.css';
import { menuStyleFullWidth } from 'common/styles/DropdownStylingFullWidth';
import { ColumnContainer, InlineContainer } from '../../styled/MyWallet.styled';
import { CanadianDistricts } from 'utils/canadian-districts';


import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import { StripeElementsStyles } from "utils/stripe";

const VALIDATION_SCHEMA = Yup.object().shape({
  cardNickname: Yup.string().required('Your card nickname is required.'),
  nameOnCard: Yup.string().required('Your name on card is required.'),
  addressSearch: Yup.string().required('Your card\'s billing address is required.'),
  line1: Yup.string().required('Your card\'s billing address line 1 is required.'),
  line2: Yup.string(),
  city: Yup.string().required('Your card\'s billing address city is required.'),
  district: Yup.string(),
  zipCode: Yup.string().required('Your card\'s billing address zip code is required.'),
  country: Yup.string().required('Your card\'s billing address country is required.'),
});

interface AddCardStatus {
  status: 'active' | 'failed' | 'reset';
}

interface ModalProps {
  onChange: () => void;
  onCardAdded: (card: CardSchema) => void;
  changePaymentMethod?: () => void;
  type: PaymentModalType;
  cardId?: string; // Used when adding card from MyWallet to delete previous card
  fromMyWallet?: boolean;
  stripe: any;
  elements: any;
  isFromPayment?: boolean;
}

type OptionType = {
  value: string;
  label: string;
};

const AddCardModal: FC<ModalProps> = ({
  onChange,
  onCardAdded,
  changePaymentMethod,
  type,
  cardId,
  fromMyWallet,
  stripe,
  elements,
  isFromPayment = false,
}) => {
  const viewport = useViewport();

  const blockReload = useStoreActions(
    (actions) => actions.websocket.blockReload
  )

  const initialValues = useMemo(
    () => ({
      addressSearch: '',
      cardNickname: 'My Card',
      nameOnCard: '',
      line1: '',
      line2: '',
      city: '',
      district: '',
      zipCode: '',
      country: '',
    }),
    [],
  );

  const {
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const userLocation = useStoreState((state) => state.authentication.location);
  const [showDistrictField, setShowDistrictField] = useState<boolean>(false);
  const [showDistrictError, setDistrictError] = useState<boolean>(false);
  const [failedReasonText, setFailedReasonText] = useState<string>('');

  const [addressSelected, setAddressSelected] = useState<boolean>(false);
  const [isAwaitingCardConfirmation, setIsAwaitingCardConfirmation] =
    useState<boolean>(false);
  const [addCardComplete, setAddCardComplete] = useState<AddCardStatus>();
  const [threeDSLink, set3DSLink] = useState<string>();
  const [show3dsFrame, setShow3dsFrame] = useState<boolean>(false);
  const [canShowCloseButton, setCanShowCloseButton] = useState<boolean>(true);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [cardFormLoading, setCardFormLoading] = useState<boolean>(true);
  const [cardFormPanLoading, setCardFormPanLoading] = useState<boolean>(true);
  const [cardFormExpiryLoading, setCardFormExpiryLoading] = useState<boolean>(true);
  const [cardFormCvvLoading, setCardFormCvvLoading] = useState<boolean>(true);
  const [cardFormPanError, setCardFormPanError] = useState<string | null>(null);
  const [cardFormExpiryError, setCardFormExpiryError] = useState<string | null>(null);
  const [cardFormCvvError, setCardFormCvvError] = useState<string | null>(null);
  const [cardFormPanComplete, setCardFormPanComplete] = useState<boolean>(false);
  const [cardFormExpiryComplete, setCardFormExpiryComplete] = useState<boolean>(false);
  const [cardFormCvvComplete, setCardFormCvvComplete] = useState<boolean>(false);
  const [newCard, setNewCard] = useState<CardSchema>({
    cardId: '',
    nickname: '',
    last4: '',
  });
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const threeDSecureTrigger = useStoreState(
    (actions) => actions.purchase.threeDSecure,
  );
  const resetThreeDSecureTrigger = useStoreActions(
    (actions) => actions.purchase.resetThreeDSecure,
  );

  const setAddCard = useStoreActions(
    (actions) => actions.data.setAddCard,
  );

  const rest = useStoreActions(
    (actions) => actions.data.rest,
  );

  useEffect(() => {
    if (cardFormPanLoading === false && cardFormExpiryLoading === false && cardFormCvvLoading === false) {
      setCardFormLoading(false);
    }
  }, [cardFormPanLoading, cardFormExpiryLoading, cardFormCvvLoading]);

  useEffect(() => {
    if (userLocation) {
      setFieldValue('country', userLocation);

      if (userLocation === 'US' || userLocation === 'CA') {
        setShowDistrictField(true);
      } else {
        setShowDistrictField(false);
      }
    }

    //
    const reactModal = Array.from(
      window.document.getElementsByClassName('ReactModal__Content '),
    )[0];
    reactModal.addEventListener('scroll', () => {
      const autocompleteHTMLElements = Array.from(
        window.document.getElementsByClassName('pac-container'),
      );
      autocompleteHTMLElements.forEach((element) => {
        element.setAttribute('style', 'display: none;');
      });
    });

    if (!isFromPayment) {
      blockReload(true);
    }
  }, []);

  async function submitHandler() {
    setShowValidationErrors(false);
    setDistrictError(false);

    if (!cardFormPanComplete && cardFormPanError === null) {
      setCardFormPanError("Your card number is required.");
    }
    if (!cardFormExpiryComplete && cardFormExpiryError === null) {
      setCardFormExpiryError("Your card\'s expiry date is required.");
    }
    if (!cardFormCvvComplete && cardFormCvvError === null) {
      setCardFormCvvError("Your card\'s cvv is required.");
    }
    const card_data_complete = cardFormPanComplete && cardFormExpiryComplete && cardFormCvvComplete;

    if (errors || !card_data_complete) {
      const modal = document.getElementById('SBModal');
      if (modal) {
        modal.scrollTop = 0;
      }
    }

    if (values.country === 'US' || values.country === 'CA') {
      if (!values.district) {
        errors.district = 'District is required';
        setDistrictError(true);
      }
    }

    if (Object.keys(errors).length !== 0 || !card_data_complete) {
      setShowValidationErrors(true);
      return;
    }

    setCanShowCloseButton(false);
    encryptCardDetails();
  }

  function deleteCard(cardId: string) {
    restService
      .deleteCard(cardId)
      .then((res) => {
        res;
        setIsAwaitingCardConfirmation(false);
        onCardAdded({
          cardId: '',
          nickname: '',
          last4: '',
          verification_type: '',
        });
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to delete card: ',
          text: error.message,
        });
      });
  }

  function handleClose() {
    if (!isFromPayment) {
      blockReload(false);
    }
    onChange();
  }

  function getAddressComponentValue(
    place: google.maps.places.PlaceResult,
    types: string[],
  ) {
    const values: any = {};
    for (const type of types) {
      const value = place.address_components?.find((component) =>
        component.types.includes(type),
      )?.long_name;

      if (type) values[type] = value;
    }

    return values;
  }

  function handleAddressSearchSelected(place: google.maps.places.PlaceResult) {
    if (place.address_components) {
      //Set address
      const cityTypes = [
        'postal_town',
        'locality',
        'administrative_area_level_3',
      ];
      const types = ['street_number', 'route', 'postal_code', ...cityTypes];

      const addressComponents = getAddressComponentValue(place, types);

      let address = addressComponents['street_number'];
      address = address
        ? address + ' ' + addressComponents['route']
        : addressComponents['route'];
      //setFieldTouched('line1')
      setFieldValue('line1', address);
      setTimeout(() => {
        setFieldTouched('line1');
      }, 50);

      //Set city according to address
      let city = '';
      for (const cityType of cityTypes) {
        if (typeof addressComponents[cityType] !== 'undefined') {
          city = addressComponents[cityType];
          break;
        }
      }
      if (city) {
        //setFieldTouched('city')
        setFieldValue('city', city);
        setTimeout(() => {
          setFieldTouched('city');
        }, 50);
      }

      //Set postal code accrding to address
      const postalCode = addressComponents['postal_code'];
      if (postalCode) {
        //setFieldTouched('zipCode')
        setFieldValue('zipCode', postalCode);
        setTimeout(() => {
          setFieldTouched('zipCode');
        }, 50);
      }

      setAddressSelected(true);
    }
  }

  useEffect(() => {
    if (threeDSecureTrigger && threeDSecureTrigger === 'card') {
      setShow3dsFrame(false);
      setIsAwaitingCardConfirmation(true);

      startPolling();

      resetThreeDSecureTrigger();
    }
  }, [threeDSecureTrigger]);

  const startPolling = () => {
    const pollCardId = window.localStorage.getItem('cardId') || '';
    let poll: NodeJS.Timer;
    // First couple of api pokes will fail without the delay
    setTimeout(() => {
      poll = setInterval(() => {
        restService
          .getCardsList(pollCardId)
          .then((res) => {
            setCanShowCloseButton(true);
            if (res.status === 'active') {
              const newCard: CardSchema = {
                cardId: res.cardid,
                nickname: res.nickname,
                last4: res.last4,
                verification_type: res.verification_type,
              };

              setNewCard(newCard);

              if (fromMyWallet && cardId) {
                deleteCard(cardId);
              } else if (fromMyWallet) {
                setIsAwaitingCardConfirmation(false);
                if (!isFromPayment) {
                  blockReload(false);
                }
                onCardAdded({
                  cardId: '',
                  nickname: '',
                  last4: '',
                  verification_type: '',
                });
              }

              clearInterval(poll);

              if (fromMyWallet) {
                (window as any).gtag('event', 'wallet_card_added', {});
              } else {
                (window as any).gtag('event', 'purchase_card_added', {});
              }
              onCardAdded(newCard);
              if (!isFromPayment) {
                blockReload(false);
              }
            } else if (res.status === 'failed') {
              setAddCardComplete({ status: 'failed' });
              setIsAwaitingCardConfirmation(false);
              onAddCardError(res);
              clearInterval(poll);
            }
          })
          .catch((error) => {
            error;
            setCanShowCloseButton(true);
            clearInterval(poll);
            setIsAwaitingCardConfirmation(false);
            setAddCardComplete({ status: 'failed' });
            setGlobalBanner({
              title: 'Failed to add card: ',
              text: 'Unknown Network Error',
            });
          });
      }, 3000);
    }, 2000);
  }

  const generateCard = async (payload: any) => {
    const savedCardData = await payload.stripe.createPaymentMethod({
      type: "card",
      card: payload.card,
      billing_details: payload.billingDetails
    });
    setIsAwaitingCardConfirmation(true);
    setStripeLoading(false);
    
    const addCardResponse = await rest({
      url: "/stripe/card",
      model: "add_card",
      data: Object.assign(savedCardData.paymentMethod, {nickname: values.cardNickname}),
    });
    
    if (addCardResponse.status === "pending") {
      if (addCardResponse.redirect_url) {
        set3DSLink(addCardResponse.redirect_url);
        setShow3dsFrame(true);
        window.localStorage.setItem('cardId', addCardResponse.cardid);
      }
    } else if (addCardResponse.status === "active") {
      const newCard: CardSchema = {
        cardId: addCardResponse.cardid,
        nickname: addCardResponse.nickname,
        last4: addCardResponse.last4,
        verification_type: addCardResponse.verification_type,
      };

      setNewCard(newCard);
      setCanShowCloseButton(true);

      if (fromMyWallet && cardId) {
        deleteCard(cardId);
      } else if (fromMyWallet) {
        setIsAwaitingCardConfirmation(false);
        if (!isFromPayment) {
          blockReload(false);
        }
        onCardAdded({
          cardId: '',
          nickname: '',
          last4: '',
          verification_type: '',
        });
      }

      if (fromMyWallet) {
        (window as any).gtag('event', 'wallet_card_added', {});
      } else {
        (window as any).gtag('event', 'purchase_card_added', {});
      }
      onCardAdded(newCard);
      if (!isFromPayment) {
        blockReload(false);
      }
    } else if (addCardResponse.status === 'failed') {
      setCanShowCloseButton(true);
      setAddCardComplete({ status: 'failed' });
      setIsAwaitingCardConfirmation(false);
      onAddCardError(addCardResponse);
    }
  }

  function encryptCardDetails() {
    setStripeLoading(true);
    const billingDetails = {
      name: values.nameOnCard,
      address: {
          city: values.city,
          country: values.country,
          line1: values.line1,
          postal_code: values.zipCode,
          state: values.district
      }
    };
    const cardElement = elements.getElement('cardNumber');
    generateCard({
      stripe: stripe,
      card: cardElement,
      billingDetails: billingDetails
    });
  }

  const onAddCardError = (res: any) => {
    let error_string = '';
    error_string = checkStripePayError(res);
    //console.error("PAY ERROR", error_string);
    setFailedReasonText(error_string);
    setAddCard(res);
  };

  function loading_screen() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: 'inherit',
          marginTop: '180px',
        }}>
        <BeatLoader color="white" loading={isAwaitingCardConfirmation || cardFormLoading} />
      </div>
    );
  }

  function card_failed_screen() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          marginTop: '160px',
          textAlign: 'center',
          width: '100%'
        }}>
        <Typography
          fontWeight="bold"
          fontSize="fz24"
          fontColor={type == PaymentModalType.Normal ? theme.colors.white : theme.colors.yellow}
          text="Failed to Add Card"
        />
        {failedReasonText ? (
          <>
            {type == PaymentModalType.Normal && failedReasonText == 'Unsupported Card.\nRetry using Apple Pay / Google Pay.' ? <>
            <Typography fontColor="white" text={'Unsupported Card.'} />
            </> : <>
            <Typography fontColor="white" text={failedReasonText} />
            </>}
          </>
        ) : (
          <></>
        )}
        <Spacer height={20} />
        {type == PaymentModalType.Normal ? (
          <>
            <WhiteButton
              label="Try Again"
              onClick={() => {
                setAddCardComplete({ status: 'reset' });
              }}
              bgColor={theme.colors.white}
              labelColor={theme.colors.black}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
            <WhiteButton
              label="Close"
              onClick={() => {
                handleClose();
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          </>
        ) : (
          <>
            <WhiteButton
              label="Try Again"
              onClick={() => {
                setAddCardComplete({ status: 'reset' });
              }}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              borderRadius={50}
              height={45}
            />
            <WhiteButton
              label="Change payment method"
              onClick={() => {
                if (changePaymentMethod) {
                  changePaymentMethod();
                }
              }}
              labelColor={theme.colors.yellow}
              borderColor={theme.colors.yellow}
              borderRadius={50}
              height={45}
            />
          </>
        )}
      </div>
    );
  }

  function three_d_secure_window() {
    return (
      <ThreeDSDiv>
        <iframe
          id="3ds-frame"
          className="threeds-redirect-frame"
          src={threeDSLink}
          width={'100%'}
          height={400}></iframe>
      </ThreeDSDiv>
    );
  }

  const renderCardNumber = (name: string, label: string, errName: string) => {
    const error = cardFormPanError;
    return (
        <>
          <HostedFields className={`${name}-wrapper`}>
            <HostedFieldLabel htmlFor={name}>CardNumber</HostedFieldLabel>
            <CardNumberElement
              id={name}
              className="card-number-field"
              onBlur={() => logEvent(name, 'blur')}
              onChange={handlePayment_InputChange(name, label, errName)}
              onFocus={() => logEvent(name, 'focus')}
              onReady={() => logEvent(name, 'ready')}
              options={Object.assign({}, StripeElementsStyles, {placeholder: label})}
            />
          </HostedFields>
          {error && <HostedFieldError className="card-number-field-error">{error}</HostedFieldError>}
          <div style={{width: 'calc(100% + 19px)', marginLeft: '-4px'}}>
            <BottomLine />
          </div>
        </>
    );
  }
  const renderCardExpiryDate = (name: string, label: string, errName: string) => {
    const error = cardFormExpiryError;
    return (
      <>
        
          <HostedFields className={`${name}-wrapper`}>
            <HostedFieldLabel htmlFor={name}>CardExpiryDate</HostedFieldLabel>
            <CardExpiryElement
                id={name}
                className="card-exp-field"
                onBlur={() => logEvent(name, 'blur')}
                onChange={handlePayment_InputChange(name, label, errName)}
                onFocus={() => logEvent(name, 'focus')}
                onReady={() => logEvent(name, 'ready')}
                options={Object.assign({}, StripeElementsStyles, {placeholder: label})}
            />
          </HostedFields>
        {error && <HostedFieldError className="card-exp-field-error">{error}</HostedFieldError>}
        <div style={{width: 'calc(100% + 19px)', marginLeft: '-4px'}}>
          <BottomLine />
        </div>
      </>
    );
  }
  const renderCardCvc = (name: string, label: string, errName: string) => {
    const error = cardFormCvvError;
    return (
      <>
        <HostedFieldSmall>
          <HostedFields className={`${name}-wrapper`}>
            <HostedFieldLabel htmlFor={name}>CardCVV</HostedFieldLabel>
            <CardCvcElement
                id={name}
                className="card-cvv-field"
                onBlur={() => logEvent(name, 'blur')}
                onChange={handlePayment_InputChange(name, label, errName)}
                onFocus={() => logEvent(name, 'focus')}
                onReady={() => logEvent(name, 'ready')}
                options={Object.assign({}, StripeElementsStyles, {placeholder: label})}
            />
          </HostedFields>
        </HostedFieldSmall>
        {error && <HostedFieldError className="card-cvv-field-error">{error}</HostedFieldError>}
        <div style={{width: 'calc(100% + 19px)', marginLeft: '-4px'}}>
          <BottomLine />
        </div>
      </>
    );
  }

  const handlePayment_InputChange = (name: string, label: string, errName: string) => (elementData: any) => {
    if (name === "cardNumber") {
      setCardFormPanComplete(elementData.complete);
    } else if (name === "cardExpiry") {
      setCardFormExpiryComplete(elementData.complete);
    } else if (name === "cardCvc") {
      setCardFormCvvComplete(elementData.complete);
    }
    if (!elementData.complete && !elementData.error) {
        if (name === "cardNumber") {
          setCardFormPanError(`Your ${errName} is required.`);
        } else if (name === "cardExpiry") {
          setCardFormExpiryError(`Your ${errName} is required.`);
        } else if (name === "cardCvc") {
          setCardFormCvvError(`Your ${errName} is required.`);
        }
    } else if (!elementData.complete && elementData.error) {
      if (name === "cardNumber") {
        setCardFormPanError(elementData.error.message);
      } else if (name === "cardExpiry") {
        setCardFormExpiryError(elementData.error.message);
      } else if (name === "cardCvc") {
        setCardFormCvvError(elementData.error.message);
      }
    } else if (elementData.complete) {
      if (name === "cardNumber") {
        setCardFormPanError(null);
      } else if (name === "cardExpiry") {
        setCardFormExpiryError(null);
      } else if (name === "cardCvc") {
        setCardFormCvvError(null);
      }
    }
  }

  const logEvent = (name: string, type: string) => {
    
    if (type === "ready") {
      if (name === "cardNumber") {
        setCardFormPanLoading(false);
      }
      if (name === "cardExpiry") {
        setCardFormExpiryLoading(false);
      }
      if (name === "cardCvc") {
        setCardFormCvvLoading(false);
      }
    }
  }

  function add_card_form() {
    return (
      <>
        <div style={{ display: cardFormLoading ? "none" : "block", width: "100%" }}>
          <TextContent
            text="Enter new card details"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />
          {/*<InputContainer>
            <InputWithIcon
              className="card-nickname-field"
              inputName="card-nickname-input"
              height={70}
              type="text"
              placeholder="Give this Card a Nickname"
              value={values.cardNickname}
              tabIndex={0}
              withBottomLine
              onChange={handleChange('cardNickname')}
              autoComplete="off"
              autoFocus={true}
              error={
                (showValidationErrors || touched.cardNickname) &&
                errors.cardNickname
                  ? errors.cardNickname
                  : undefined
              }
            />
            </InputContainer>*/}
          <InputContainer>
            <InputWithIcon
              className="card-name-field"
              inputName="card-name-input"
              height={70}
              type="text"
              placeholder="Name on card"
              value={values.nameOnCard}
              tabIndex={0}
              withBottomLine
              autoComplete="cc-name"
              onChange={handleChange('nameOnCard')}
              error={
                (showValidationErrors || touched.nameOnCard) && errors.nameOnCard
                  ? errors.nameOnCard
                  : undefined
              }
            />
          </InputContainer>
          {renderCardNumber('cardNumber', 'Card Number', 'card number')}
          <div style={{'width': '100%', display: 'flex', flexDirection: 'row'}}>
            <div style={{'width': '100%'}}>
            {renderCardExpiryDate('cardExpiry', 'Expiry (MM / YY)', 'card\'s expiry date')}
            </div>
            <div style={{'width': '100%'}}>
            {renderCardCvc('cardCvc', 'CVV', 'card\'s cvv')}
            </div>
          </div>
          {!addressSelected && (
            <InputContainer>
              <InputWithIcon
                height={70}
                type="search"
                placeholder="Start typing your address"
                value={values.addressSearch}
                tabIndex={0}
                inputName={'addressSearch'}
                withBottomLine
                autoComplete="off"
                onChange={handleChange('addressSearch')}
                error={
                  (showValidationErrors || touched.addressSearch) &&
                  errors.addressSearch &&
                  !addressSelected
                    ? errors.addressSearch
                    : undefined
                }
                googleAutoComplete={{
                  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                  onPlaceSelected: (place) => handleAddressSearchSelected(place),
                  options: {
                    componentRestrictions: {
                      country: values.country,
                    },
                    fields: ['address_components', 'geometry', 'name'],
                    types: ['address'],
                  },
                }}
              />
            </InputContainer>
          )}
          {addressSelected ? (
            <ColumnContainer>
              <InputContainer>
                <InputWithIcon
                  className="address-line-1"
                  inputName="address1-input"
                  height={70}
                  type="text"
                  placeholder="Address Line 1"
                  value={values.line1}
                  tabIndex={0}
                  withBottomLine
                  onChange={handleChange('line1')}
                  error={
                    (showValidationErrors || touched.line1) && errors.line1
                      ? errors.line1
                      : undefined
                  }
                />
              </InputContainer>
              <InputContainer>
                <InputWithIcon
                  className="address-line-2"
                  inputName="address2-input"
                  height={70}
                  type="text"
                  placeholder="Address Line 2"
                  value={values.line2}
                  tabIndex={0}
                  onChange={handleChange('line2')}
                  withBottomLine
                />
              </InputContainer>
              <InputContainer>
                <InputWithIcon
                  className="address-city"
                  inputName="address-city-input"
                  height={70}
                  type="text"
                  placeholder="City"
                  value={values.city}
                  tabIndex={0}
                  withBottomLine
                  onChange={handleChange('city')}
                  error={
                    (showValidationErrors || touched.city) && errors.city
                      ? errors.city
                      : undefined
                  }
                />
              </InputContainer>
              {showDistrictField && (
                <div style={{ width: '90%' }}>
                  <DropdownContainer>
                    <DropDown
                      options={
                        values.country === 'US' ? USDistricts : CanadianDistricts
                      }
                      placeholder={
                        values.country === 'US'
                          ? 'Type State'
                          : 'Type District/State'
                      }
                      styles={menuStyleFullWidth}
                      onChange={(option) => {
                        setFieldValue('district', (option as OptionType).value);
                      }}
                    />
                  </DropdownContainer>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                    }}>
                    <Typography
                      text={
                        (showValidationErrors || touched.district) &&
                        showDistrictError &&
                        !values.district
                          ? 'District is required'
                          : ''
                      }
                      fontSize="fz12"
                      fontColor={theme.colors.yellow}
                    />
                  </div>
                </div>
              )}
              <InputContainer>
                <InputWithIcon
                  className="address-zipcode"
                  inputName="address-zipcode-input"
                  height={70}
                  type="text"
                  placeholder={
                    values.country === 'UK' || values.country === 'GB'
                      ? 'Postcode'
                      : 'Zip Code'
                  }
                  value={values.zipCode}
                  tabIndex={0}
                  withBottomLine
                  onChange={handleChange('zipCode')}
                  error={
                    (showValidationErrors || touched.zipCode) && errors.zipCode
                      ? errors.zipCode
                      : undefined
                  }
                />
              </InputContainer>
            </ColumnContainer>
          ) : (
            <></>
          )}
          {!addressSelected ? (
            <div style={{ marginTop: '10px' }}>
              <Button
                className="manual-address-button"
                label={
                  <Typography
                    text="Add address manually"
                    fontColor={theme.colors.yellow}
                    fontWeight="regular"
                    letterSpacing="-0.03em"
                  />
                }
                onClick={() => {
                  setFieldValue('addressSearch', 'abc');
                  setAddressSelected(true);
                }}
              />
            </div>
          ) : (
            <>
              {/*<>
                <div style={{ marginTop: '10px' }}>
                  <Button
                    label={
                      <Typography
                        text="Search for address"
                        fontColor={theme.colors.yellow}
                        fontWeight="regular"
                        letterSpacing="-0.03em"
                      />
                    }
                    onClick={() => {
                      setFieldValue('addressSearch', '');
                      setAddressSelected(false);
                    }}
                  />
                </div>
                  </>*/}
            </>
          )}

          {values.country ? (
            <ColumnContainer>
              <Spacer height={20} />

              <InlineContainer>
                <LightText
                  fontColor="white"
                  text={'Country: ' + `${values.country}`}
                />
                <Button
                  label={
                    <Typography
                      text="Change"
                      fontColor={theme.colors.yellow}
                      fontWeight="regular"
                      letterSpacing="-0.03em"
                    />
                  }
                  onClick={() => {
                    setFieldValue('country', null);
                    setFieldValue('addressSearch', '');
                    setAddressSelected(false);      
                  }}
                />
              </InlineContainer>
            </ColumnContainer>
          ) : (
            <ColumnContainer>
              <DropdownContainer>
                <DropDown
                  id='address-search-country'
                  className="address-search-country"
                  name='address-search-country'
                  options={CountryCodes}
                  value={CountryCodes.find(
                    (data) => data.label === values.country,
                  )}
                  styles={menuStyleFullWidth}
                  placeholder="Search country"
                  onChange={(option) => {
                    const value = (option as OptionType).value;

                    setFieldValue('country', value);

                    if (value === 'US' || value === 'CA') {
                      setShowDistrictField(true);
                      if (!values.district) {
                        setDistrictError(true);
                      }
                    } else {
                      setShowDistrictField(false);
                    }
                  }}
                />
              </DropdownContainer>
            </ColumnContainer>
          )}

          <Spacer height={20} />
          {type !== PaymentModalType.AddFunds ? (
            <FirstButton
              className="add-card-button"
              height={45}
              width={170}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton text="add" fontSize="fz16" fontWeight="bold" />
                  &nbsp;
                  <ContentButton
                    text="card"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => {
                stripeLoading === false && submitHandler()
              }}
            />
          ) : (
            <WhiteButton
              label="save Card"
              onClick={submitHandler}
              height={45}
              width={170}
              borderRadius={50}
              borderColor={theme.colors.white}
              bgColor={theme.colors.white}
              labelColor={theme.colors.black}
            />
          )}
        </div>
        {cardFormLoading && loading_screen()}
      </>
    );
  }

  function getModalContent() {
    if (show3dsFrame) {
      return three_d_secure_window();
    } else if (isAwaitingCardConfirmation) {
      return loading_screen();
    } else if (addCardComplete && addCardComplete.status === 'failed') {
      return card_failed_screen();
    } else if (
      addCardComplete &&
      addCardComplete.status === 'active' &&
      type === PaymentModalType.Quickflow
    ) {
      // Show Add funds modal
      onCardAdded(newCard);
      return;
    } else {
      return add_card_form();
    }
  }

  return (
    <CustomComponentContainer id="addCardModalContainer">
      {canShowCloseButton && (
        <CloseButton onClick={handleClose} id="add-card-close-button">
          X
        </CloseButton>
      )}
      <BoldText
        text={
          type === PaymentModalType.AddFunds ? 'edit card' : 'add a new card'
        }
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      {getModalContent()}
    </CustomComponentContainer>
  );
};

export default AddCardModal;

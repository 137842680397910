import React, { useEffect, useState } from 'react';

import restService from 'services/rest.service';

import StartModal from './StartModal';
import FailedModal from './FailedModal';
import FailedMultipleModal from './FailedMultipleModal';
import SuccessModal from './SuccessModal';
import QuestionModal from './QuestionModal';

import { FCAQuestionsResponseTypes, FCAQuestionsTypes, FCAAnswersTypes } from '../types'

type ModalProps = {
  show: boolean;
  onClose: () => void;
  onContinue: (nextStep?: string) => void;
};

const AppropriatenessAssessment = ({ show, onContinue, onClose }: ModalProps) => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [failedMultiple, setFailedMultiple] = useState<boolean>(false);
  const [data, setData] = useState<FCAQuestionsResponseTypes>();
  const [question, setQuestion] = useState<FCAQuestionsTypes>();
  const [answers, setAnswers] = useState<FCAAnswersTypes[]>([]);

  function handleClose() {
    onClose()
  }

  async function getQuestions () {

    restService.fcaQuestions().then((res: FCAQuestionsResponseTypes) => {
      setData(res)
      setAnswers([])
    })
    
  }

  useEffect(() => {

    if (show) {
      restService.fcaQuestions().then((res: FCAQuestionsResponseTypes) => {
        setData(res)
        setAnswers([])
      })
      setShowModal(show);
    }

  }, [show]);

  return (
    <>
      <StartModal 
        show={showModal}
        onContinue={() => {
          setQuestion(data?.questions[0])
        }} 
        onClose={handleClose}
      />

      {data && question ? <>
        <QuestionModal 
          show={showModal} 
          question={question}
          questionNo={(data?.questions.findIndex(x => x.id === question.id) + 1)}
          totalQuestions={data?.questions.length} 
          answers={answers}
          onClose={handleClose}
          onContinue={(answer) => {
            answers.push(answer)
            setQuestion(data?.questions[data?.questions.findIndex(x => x.id === question.id) + 1])
          }}
          onSuccess={() => {
            setData(undefined)
            setQuestion(undefined)
            setSuccess(true)            
          }} 
          onFailed={(multiple) => {
            setData(undefined)
            setQuestion(undefined)
            if (multiple) {
              setFailedMultiple(true)
            } else {
            setFailed(true)
          }
          }}
        />
      </> : <></>}

      <SuccessModal show={success} onClose={handleClose} onContinue={(nextStep) => {
        setSuccess(false)
        onContinue(nextStep)
      }} />

      <FailedModal show={failed} onClose={handleClose} onContinue={(nextStep) => {
        getQuestions()
        nextStep
        setFailed(false)
      }}  />

      <FailedMultipleModal show={failedMultiple} onClose={handleClose} />

    </>
  );
};

export default AppropriatenessAssessment;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';
import theme from 'theme/theme';
import { md, sm, xlg, xs } from 'utils/constants';

export const ErrorMessage = styled.p`
  color: #ffffff;
  text-decoration: none;
  white-space: pre-wrap;
`;

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  font-weight: 900;
  letter-spacing: -0.03em;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 100px 36px 0 34px;
  z-index: 3;

  @media (min-width: ${sm}px) {
    padding: 130px 50px 0;
  }

  @media (min-width: ${md}px) {
    padding: 135px 0 0 193px;
  }

  @media (min-width: ${xlg}px) {
    padding-top: 0;
    justify-content: center;
    height: 100%;
  }
`;

export const Form = styled.div`
  padding: 32px 0 0 0;
  z-index: 10;

  @media (min-width: ${md}px) {
    width: 480px;
    padding-left: 5px;
  }
`;

export const ImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: hidden;
  height: 100%;

  @media (min-width: 992px) {
    visibility: visible;
  }
`;

export const ImageGradient = styled.div<{
  banner: string;
}>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0)
    ),
    ${(props) => props.banner && `url(${props.banner})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;

  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      #000000,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    width: 300px;
  }
`;

export const TextContent = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;

  margin-bottom: 16px;

  @media (min-width: ${md}px) {
    font-size: 24px;
  }
`;

export const FirstButton = styled(Button)`
  padding: 0 60px;
  margin: 38px auto 0 auto;

  @media (min-width: ${md}px) {
    margin: 28px 0 0 0;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

export const InputContainer = styled.div`
  position: relative;

  @media (min-width: ${xs}px) {
    max-width: 335px;
  }
`;

export const Icon = styled.div`
  left: 8px;
  bottom: 23px;
  position: absolute;

  @media (min-width: ${sm}px) {
    bottom: 25px;
  }
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
  }
  & > label {
    font-size: 14px;
    letter-spacing: -0.035em;

    margin-top: 2px;
    padding-left: 22px;
  }

  & > input:focus + label {
    font-size: 0px;
  }
  & > input:valid + label {
    font-size: 0px;
  }
  & > div:nth-child(4) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > label {
      font-size: 18px;
      margin-top: 2px;
    }
  }
`;

export const PasswordVisibility = styled.div`
  position: absolute;
  right: 25px;
  bottom: 20px;

  cursor: pointer;
  z-index: 5;

  @media (min-width: ${xs}px) {
    right: 17px;
  }
`;

export const ForgotPasswordButton = styled(Button)`
  font-size: 10px;
  line-height: 24px;
  letter-spacing: -0.03em;

  margin-top: 19px;
  margin-left: 25px;

  @media (min-width: ${xs}px) {
    font-size: 12px;
    margin-top: 25px;
  }
`;

export const ConfirmationBanner = styled.div`
  background-color: ${theme.colors.yellow};
  width: 100vw;
  height: 100px;
  position: absolute;
  top: 0;
  z-index: 10;
  text-align: center;
  padding-top: 40px;
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 60px;
  letter-spacing: -0.03em;

  @media (min-width: ${md}px) {
    font-size: 100px;
    letter-spacing: -0.057em;
  }
`;

export const StrapLineTypographyContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const HeadContent = styled(StrapLineTypography)`
  flex-shrink: 0;
`;


export const LoginLink = styled(Link)`
display: in
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const LoginContainer = styled.p`
  font-size: 13px;
  margin-top: 40px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #fff;
  text-align: center;
  display: flex;
  z-index: 9999;
  justify-content: center;
  gap: 5px;

  @media (min-width: ${sm}px) {
    font-size: 13px;
  }

  @media (min-width: ${md}px) {
    margin-top: 30px;
    font-size: 16px;
    justify-content: left;
  }
`;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';

import PageLayout from 'common/components/PageLayout/PageLayout';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';

import SongRoyaltyCard from './components/SongRoyaltyCard/SongRoyaltyCard';

import { md, sm } from 'utils/constants';

import { useStoreActions } from 'store/store';
import restService from 'services/rest.service';
import { ISongRoyaltyOverviewData } from 'typings/account';

import { useNavigate } from 'react-router-dom';
import Button from 'common/components/Button/Button';
import SongRoyaltyOverviewPlaceholder from 'common/components/Placeholder/Royalty/SongRoyaltyOverview';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

const quickLinks = [
  { title: 'Account Settings', link: '/account/settings' },
  {
    title: 'My Wallet',
    link: '/account/wallet',
  },
  {
    title: 'My Collection',
    link: '/library/my-collection',
  },
  { title: 'Transaction History', link: '/account/transactions' },
  { title: 'Royalty Overview', link: '/account/royalty' },
  {
    title: 'Song Royalty History',
    link: '/account/royalty/history',
  },
];

const SongRoyaltyOverview = () => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const [royaltyData, setRoyaltyData] = useState<ISongRoyaltyOverviewData[]>();

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  useEffect(() => {
    restService
      .getSongsRoyaltyOverview()
      .then((res) => {
        const data = res;
        data.total_bits = parseInt(res.total_bits);
        data.total_purchase = parseFloat(res.total_purchase);
        data.royalty_period = parseFloat(res.royalty_period);
        data.total_royalty = parseFloat(res.total_royalty);
        data.withdraw_royalty = parseFloat(res.withdraw_royalty);
        setRoyaltyData(data);
      })
      .catch((error) => {
        error;
      });

      setBreadcrumbs([
        { value: 'Home', label: 'Home' },
        { value: 'account', label: 'My Account' },
        { value: 'royalty' || '', label: 'Royalty' },
        { value: 'songs' || '', label: 'Songs' },
      ]);
      return () => {
        setBreadcrumbs([]);
      };
  }, []);

  return (
    <PageContainer pageTitle={'Song Royalty Overview | SongBits'}>
      <BreadcrumbsContainer>
        {viewport.width < 576 && <MobileBreadcrumbs />}
      </BreadcrumbsContainer>
      <PageLayout
        padding={
          viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
        }
        title="Song Royalty Overview"
        is2ColumnLayout={true}
        sections={[
          {
            content: (
              <>
                {!royaltyData ? (
                  <SongRoyaltyOverviewPlaceholder />
                ) : (
                  <>
                    {royaltyData && royaltyData.length === 0 ? (
                      <>
                        <Typography
                          text="You have not bought any SongBits yet."
                          fontWeight="light"
                          fontSize="fz18"
                          lineHeight="28px"
                          letterSpacing="0.17px"
                        />
                        <Typography
                          text={
                            <>
                              Check out the{' '}
                              <span
                                onClick={() =>
                                  navigate('/library/hot-releases')
                                }
                                style={{
                                  color: theme.colors.yellow,
                                  cursor: 'pointer',
                                }}>
                                latest releases
                              </span>
                            </>
                          }
                          fontWeight="light"
                          fontSize="fz18"
                          lineHeight="28px"
                          letterSpacing="0.17px"
                        />
                      </>
                    ) : (
                      <SongBitsContainer>
                        {royaltyData.sort((a, b) => new Date(b.purchase_date) > new Date(a.purchase_date) ? -1 : 1).map((purchaseData, index) => (
                          <SongRoyaltyCard
                            key={`Royalty Purchase ${index}`}
                            royalty={purchaseData}
                            bottomLine={index !== royaltyData.length - 1}
                          />
                        ))}
                      </SongBitsContainer>
                    )}
                  </>
                )}
              </>
            ),
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                }}>
                {!royaltyData ? (
                  <QuickLinksPlaceholder />
                ) : (
                  <>
                    {viewport.width > 767 && <QuickLinks links={quickLinks} />}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 488px;

  @media (max-width: ${md}px) {
    width: 100%;
  }
`;

export const MoneyContainer = styled.div`
  width: 100%;

  @media (max-width: ${sm}px) {
    padding-left: 24px;
  }
`;

export const PeriodTitleContainer = styled.div`
  width: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${sm}px) {
    width: 123px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SongBitsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
`;

export const Tooltip = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 17px;
`;

export const WithdrawButton = styled(Button)`
  border: 3px solid gray;
  border-radius: 50px;
`;

export default SongRoyaltyOverview;

import React from 'react';

import PageContainer from 'common/layout/PageContainer';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

import { Container } from './styled/PlaceholderScreen.styled';

type PlaceholderScreenProps = {
  name: string;
};

const PlaceholderScreen = ({ name }: PlaceholderScreenProps) => (
  <PageContainer>
    <Container>
      <Typography
        text={`${name + ' screen'}`}
        fontSize="fz24"
        fontWeight="bold"
        letterSpacing="-0.03em"
      />
      <Spacer height={20} />
      <Typography
        text="coming soon"
        fontSize="fz18"
        fontWeight="bold"
        letterSpacing="-0.03em"
      />
    </Container>
  </PageContainer>
);

export default PlaceholderScreen;

/** Styles **/
export const xs = 576;
export const sm = 768;
export const md = 992;
export const lg = 1200;
export const xlg = 1920;

export const footerHeight = 100;
export const footerMobileHeight = 56;

/** Ethereum Mainnet **/
// export const NETWORK_CHAIN_ID = 1;
// export const NETWORK_CHAIN_NAME = 'mainnet';
// export const NFT_ADDRESS = "";

/** Rinkeby **/
export const NETWORK_CHAIN_ID = 4;
export const NETWORK_CHAIN_NAME = 'rinkeby';
export const NFT_ADDRESS = '0xf620AA96E5c771Aabb1f8E0cFcc92fd0dC0f67f7';
export const NFT_SCAN_ADDRESS = 'https://explorer.palm.io/tx/';
export const INFURA_PROJECT_ID = 'b819bcc658c8461688052d79f3b61474';

export const NETWORK_CHAINS = {
  1: {
    name: 'Ethereum Mainnet',
    currency: 'ETH',
    rpc: 'https://mainnet.infura.io/v3/d54bee202dc4438aa804196be6e27890',
  },
  11155111: {
    name: 'Sepolia',
    currency: 'ETH',
    rpc: 'https://sepolia.infura.io/v3/d54bee202dc4438aa804196be6e27890',
  },
  11297108109: {
    name: 'Palm',
    currency: 'PALM',
    rpc: 'https://palm-mainnet.infura.io/v3/d54bee202dc4438aa804196be6e27890',
  },
  11297108099: {
    name: 'Palm Testnet',
    currency: 'PALM',
    rpc: 'https://palm-testnet.infura.io/v3/e504875614714d3aac7061d4a197b190',
  },
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/** Palm Mainnet **/
// export const NETWORK_CHAIN_ID = 1;
// export const NETWORK_CHAIN_NAME = 'mainnet';
// export const NFT_ADDRESS = "";

/** Palm Testnet **/
// export const NETWORK_CHAIN_ID = 1;
// export const NETWORK_CHAIN_NAME = 'mainnet';
// export const NFT_ADDRESS = "";
